import { PlaidLinkHookResult } from "../withPlaidLinkToken";
import { usePlaidItemLinkTokenQuery } from "@apollo/ops";

const POLL_INTERVAL = 29 * 60 * 1000; // 29 minutes

/**
 * Fetch a Plaid Link token for updating an existing Plaid Item.
 *
 * @remarks
 * Ideally this hook would examine `plaidItem.linkToken.expiration` field an refresh
 * as expiration nears, instead we just poll ever 29 minutes, which is the minimum
 * expiration time currently stated in the Plaid docs, this should more than suffice
 * for now.
 *
 * This component currently doesn't handle error scenarios.
 */
export const usePlaidItemLinkToken = (
  plaidItemId: number
): PlaidLinkHookResult => {
  const { data, error, loading } = usePlaidItemLinkTokenQuery({
    fetchPolicy: "no-cache",
    pollInterval: POLL_INTERVAL,
    variables: {
      id: plaidItemId,
    },
  });

  return {
    loading,
    error,
    plaidLinkToken: data?.plaidItem.linkToken.linkToken,
  };
};
