import React from "react";
import { Link, Redirect, RouteComponentProps, Router } from "@reach/router";
import { compose } from "lodash/fp";
import { withAuthentication } from "../shared/auth";
import {
  withRetailer,
  WithRetailerInjectedProps,
} from "@hoc/WithRetailer/WithRetailer";
import { StoreRouter } from "./StoreRouter/StoreRouter";
import { SidebarLayoutV2 } from "@ui/Layouts/SidebarLayoutV2";
import { RetailerAppProvider, useRetailerApp } from "./RetailerAppProvider";
import { DirectBeerSvg } from "@components/DirectBeerSvg";
import { SidebarNavigationV2 } from "@components/ui/SidebarNavigation/SidebarNavigationV2";
import { RetailerSidebarBottomNav } from "./RetailerSidebarBottomNav";
import {
  CollectionIcon,
  CreditCardIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { RetailerTransactions } from "./TransactionsPage/RetailerTransactions";
import { StoresPage } from "./StoresPage/StoresPage";
import { useRedirectToShopify } from "@hooks/useRedirectToShopify";

type RetailerAppProps = {
  basePath: string;
} & RouteComponentProps<{ retailerId: string }> &
  WithRetailerInjectedProps;

function EnhancedRetailerApp({ retailer }: RetailerAppProps) {
  return (
    <RetailerAppProvider organizationId={retailer.id}>
      <Router className="h-full">
        <RetailerOrganizationRouter default retailer={retailer} />
        <StoreRouter path="stores/:storeIdParam/*" />
      </Router>
    </RetailerAppProvider>
  );
}

function RetailerOrganizationRouter({
  retailer,
}: RouteComponentProps & WithRetailerInjectedProps) {
  return (
    <SidebarLayoutV2
      mobileSidebar={<Sidebar />}
      mobileLogo={
        <Link to="/" className="flex items-center">
          <DirectBeerSvg fill="#fff" width={40} height={40} />
        </Link>
      }
      desktopSidebar={<Sidebar />}
    >
      <Router>
        <Redirect from="/" to="stores" />
        <StoresPage path="stores" />
        <RetailerTransactions path="payments" organizationId={retailer.id} />
      </Router>
    </SidebarLayoutV2>
  );
}

function Sidebar() {
  const {
    organization: retailer,
    organizationPath: retailerPath,
    organizationId: retailerId,
  } = useRetailerApp();
  const { onClick } = useRedirectToShopify({
    path: "/",
    organizationId: retailerId,
  });

  return (
    <div className="flex-1 flex flex-col min-h-0 bg-skin-primary overflow-y-auto">
      <div className="flex-1 flex flex-col pt-3 pb-4">
        <Link to={retailerPath("/")} className="flex items-center">
          <div className="flex items-center flex-shrink-0 px-4">
            <DirectBeerSvg fill="#fff" width={33} height={33} />

            <div className={`ml-3 font-bold text-xl tracking-wide text-white`}>
              Direct Beer
            </div>
          </div>
        </Link>
        <div className="mt-5 flex-1">
          <div className="mt-1 px-2">
            <div className="text-skin-base relative w-full pl-3 pr-10 py-2 text-left sm:text-sm">
              <div className="truncate leading-snug text-skin-base">
                <div className="text-xs truncate">Organization</div>
                <div className="truncate text-sm font-semibold">
                  {retailer.name}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <SidebarNavigationV2
              elements={[
                {
                  name: "Beer Shop",
                  onClick,
                  icon: ShoppingCartIcon,
                },
                {
                  name: "Stores",
                  path: retailerPath("stores"),
                  icon: CollectionIcon,
                },
                {
                  name: "Payment History",
                  path: retailerPath("payments"),
                  icon: CreditCardIcon,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 border-t border-white border-opacity-20 py-4">
        <RetailerSidebarBottomNav />
      </div>
    </div>
  );
}

export const RetailerApp = compose(
  withAuthentication(),
  withRetailer
)(EnhancedRetailerApp);
