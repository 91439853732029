import React, { ReactElement } from "react";
import { AddPackageType } from "./AddPackageType";
import { Dialog, useDialog } from "@components/ui/Dialog";
import { AddPackageTypeMutation } from "@apollo/ops";

type AddPackageTypeRenderProps = {
  open: () => void;
};

type AddPackageTypeModalProps = {
  render: (props: AddPackageTypeRenderProps) => ReactElement;
  onAdd: (data: AddPackageTypeMutation) => void;
  title?: string;
};

export function AddPackageTypeModal({
  title = "New Package Type",
  onAdd,
  render,
}: AddPackageTypeModalProps) {
  const { open, close, getDialogProps } = useDialog();

  const handleOnAdd = (data: AddPackageTypeMutation) => {
    onAdd(data);
    close();
  };

  return (
    <>
      {render({ open })}
      <Dialog {...getDialogProps()} title={title}>
        <AddPackageType onAdd={handleOnAdd} />
      </Dialog>
    </>
  );
}
