import React from "react";
import {
  ProducerPurchaseOrderOverviewQuery,
  useProducerPurchaseOrderShippingQuery,
} from "@apollo/ops";
import { Card, CardBody } from "@components/ui/Card/Card";
import { Button } from "@ui/Button";
import { LoadingPage } from "@components/LoadingPage";
import { ShippingAddressCard } from "./ShippingAddressCard";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { onExportFileClick } from "@util/files";
import { DIRECT_BEER_API_URI } from "@config";
import { ProducerPurchaseOrderPackageSummaryTable } from "./ProducerPurchaseOrderPackageSummaryTable/ProducerPurchaseOrderPackageSummaryTable";
import { ShowTrackingInformation } from "../TrackingInformation/ShowTrackingInformation";
import { RouteComponentProps } from "@reach/router";
import { Page } from "@ui/Page";
import { ProducerPurchaseOrderPageHeading } from "../ProducerPurchaseOrderPageHeading";
import { DeliveryPartnerCard } from "./DeliveryPartnerCard";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";

interface ProducerPurchaseOrderShippingPageProps extends RouteComponentProps {
  orderId: number;
  orderSummary: ProducerPurchaseOrderOverviewQuery["order"];
}

export function ProducerPurchaseOrderShippingPage({
  orderId,
  orderSummary,
}: ProducerPurchaseOrderShippingPageProps) {
  const { data } = useProducerPurchaseOrderShippingQuery({
    variables: {
      orderId,
    },
  });

  const breadCrumbs = {
    items: buildBreadcrumbs("Purchase Orders", `#${orderSummary.number}`),
  };

  if (data && orderSummary) {
    const { order } = data;
    const { trackingInformation, shippingAddress, seller } = order;

    const onGenerateLabelsPdfClick = () => {
      const pdfUrl = `${DIRECT_BEER_API_URI}order/${orderId}/pdf?labels=true`;
      const fileName = `${data.order.number}-Labels.pdf`;

      onExportFileClick({ url: pdfUrl, fileName });
    };

    return (
      <div key={order.id}>
        <Page
          heading={
            <ProducerPurchaseOrderPageHeading
              page="Shipping"
              order={orderSummary}
              breadCrumbs={breadCrumbs}
              actions={
                <Button onClick={() => onGenerateLabelsPdfClick()}>
                  Download Shipping Labels
                </Button>
              }
            />
          }
        >
          <div className="grid mt-4 laptop:grid-cols-3 gap-5">
            <div>
              <ShippingAddressCard
                disabled={true}
                shippingAddress={seller.address}
                cardTitle={"Origin Address"}
              />
            </div>
            <div>
              <ShippingAddressCard
                disabled={true}
                shippingAddress={shippingAddress}
                cardTitle={"Destination Address"}
              />
            </div>
            <Card headerProps={{ title: "Shipment Tracking", actions: [] }}>
              {trackingInformation ? (
                <ShowTrackingInformation
                  trackingInformation={trackingInformation}
                />
              ) : (
                <CardBody>No tracking information yet</CardBody>
              )}
            </Card>
          </div>
          <div className="grid laptop:grid-cols-3 gap-5 mt-8">
            <div>
              <DeliveryPartnerCard
                region={orderSummary.regionV2}
                deliveryPartner={orderSummary.regionV2.deliveryPartner}
              />
            </div>
          </div>
          <div className="mt-8">
            <SectionHeading title="Package Summary" />
            <ProducerPurchaseOrderPackageSummaryTable
              packageSummary={order.packageSummary}
            />
          </div>
        </Page>
      </div>
    );
  }

  return <LoadingPage />;
}
