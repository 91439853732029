import React from "react";
import { useAdminOrderDocumentsQuery } from "@apollo/ops";
import { Button } from "@ui/Button";
import { useDialog } from "@ui/Dialog";
import { AddOrderDocumentDialog } from "./AddOrderDocumentDialog";
import { OrderDocumentsTable } from "../../../../components/OrderDocument/OrderDocumentsTable";
import { ErrorAlert } from "@components/ui/Alert";
import { Page } from "@components/ui/Page";
import { LoadingPage } from "@components/LoadingPage";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { OrderPODDocumentForm } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderRetailerOrdersPage/OrderPODDocumentForm";

export function AdminOrderDocumentsPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const addDialog = useDialog();
  const { data, error } = useAdminOrderDocumentsQuery({
    variables: {
      orderId,
    },
  });

  if (error) {
    return <ErrorAlert title="An error occurred" />;
  }

  if (data && orderSummary) {
    const { order } = data;
    const hasPOD = order.documents?.some(
      (doc) => doc.documentType === "proof_of_delivery"
    );

    const tableColumns = hasPOD ? "laptop:col-span-4" : "laptop:col-span-3";

    return (
      <Page
        heading={
          <AdminOrderPageHeading
            page="Documents"
            order={orderSummary}
            actions={
              <Button onClick={addDialog.open} size="sm">
                Add document
              </Button>
            }
          />
        }
      >
        <div className="grid gap-6 grid-flow-col-dense laptop:grid-cols-4">
          <div className={tableColumns}>
            {order.documents.length > 0 && (
              <OrderDocumentsTable documents={data.order.documents} />
            )}
            {order.documents.length === 0 && (
              <h3 className="mt-6 text-sm text-center font-medium text-gray-900">
                No documents
              </h3>
            )}
          </div>
          <div className="laptop:col-span-1">
            {order.__typename === "RetailerSalesOrder" && !hasPOD && (
              <OrderPODDocumentForm
                orderId={data.order.id}
                number={data.order.number}
              />
            )}
          </div>
        </div>
        <AddOrderDocumentDialog dialog={addDialog} orderId={orderId} />
      </Page>
    );
  }

  return <LoadingPage />;
}
