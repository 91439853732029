import React from "react";
import { SidebarNavigationV2 } from "@components/ui/SidebarNavigation/SidebarNavigationV2";
import { useAuth } from "@shared/auth";

export function RetailerSidebarBottomNav() {
  const { logout } = useAuth();

  return (
    <SidebarNavigationV2
      elements={[
        {
          name: "FAQ",
          path: "/faq",
        },
        {
          name: "Privacy policy",
          path: "/privacy-policy",
        },
        {
          name: "Terms of service",
          path: "/terms-of-service",
        },
        {
          name: "Logout",
          onClick: logout,
        },
      ]}
    />
  );
}
