import React from "react";
import {
  useCollectPlatformFeeDataQuery,
  useCollectPlatformFeeMutation,
  useMarkPlatformFeePaidMutation,
} from "@apollo/ops";
import { LoadingIcon } from "@components/ui/Spinner";
import { ConfirmTransfer } from "@admin/components/ConfirmTransfer";
import { ManuallyCollectPlatformFee } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminPlatformFeesPage/ManuallyCollectPlatformFee/ManuallyCollectPlatformFee";

type CollectPlatformFeeProps = {
  orderId: number;
};

export function CollectPlatformFee({ orderId }: CollectPlatformFeeProps) {
  const { data, loading, error } = useCollectPlatformFeeDataQuery({
    variables: {
      orderId,
    },
  });

  const [sendPayment] = useCollectPlatformFeeMutation({
    variables: {
      orderId,
    },
  });

  const [markAsPaid] = useMarkPlatformFeePaidMutation();

  if (loading && !data) {
    return <LoadingIcon />;
  }

  if (data) {
    const { producerPurchaseOrder: order } = data;

    if (order.__typename === "ProducerPurchaseOrder") {
      return (
        <>
          <ManuallyCollectPlatformFee
            ledger={order.platformFeeLedger}
            validationResult={order.canCollectPlatformFee}
            label="Mark as paid externally"
            orderId={orderId}
            onSubmit={markAsPaid}
          />
          &nbsp;
          <ConfirmTransfer
            ledger={order.platformFeeLedger}
            transferProposal={order.collectPlatformFeeProposal}
            validationResult={order.canCollectPlatformFee}
            label="Collect platform fee"
            onSubmit={sendPayment}
          />
        </>
      );
    } else {
      throw Error(`Can use CollectPlatformFee with ${order.__typename} order`);
    }
  }

  if (error) {
    return <div>Failed to load payment data.</div>;
  }

  return null;
}
