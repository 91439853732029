import React from "react";
import { RouteComponentProps } from "@reach/router";
import Client from "../prismic-config";
import Prismic from "@prismicio/client";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { useEffect, useState } from "react";
import { SimplePage, PageHeading } from "@components/ui/Page";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "../shared/types/Severity";

export function PrivacyPolicyPage(_: RouteComponentProps) {
  const [privacyPolicy, setPrivacyPolicy] = useState<RichTextBlock[]>([]);
  const [privacyPolicyTitle, setPrivacyPolicyTitle] = useState("");

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "directbeerprivacy")
      );

      if (response?.results[0]?.data) {
        const {
          privacy_policy_title,
          privacy_policy_content,
        } = response.results[0].data;
        setPrivacyPolicyTitle(privacy_policy_title[0].text);
        setPrivacyPolicy(privacy_policy_content);
      } else {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "Oh no!",
          detail:
            "We were unable to retrieve our privacy policy. Check back later.",
        });
      }
    };

    fetchPrivacyPolicy();
  }, []);

  return (
    <SimplePage heading={<PageHeading title={privacyPolicyTitle} />}>
      <RichText render={privacyPolicy} />
    </SimplePage>
  );
}
