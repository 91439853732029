import React from "react";
import { Link, LinkGetProps } from "@reach/router";
import ctl from "@netlify/classnames-template-literals";

export type NavItem = {
  name: string;
  path: string;
};

export type NavGroup = {
  title: string;
  items: Array<NavItem>;
};

export type VerticalNavProps = {
  items: Array<NavItem | NavGroup>;
};

/**
 * Component based of Tailwind UI.
 *
 * @see https://tailwindui.com/components/application-ui/navigation/vertical-navigation
 */
export function VerticalNav({ items }: VerticalNavProps) {
  return (
    <nav aria-label="Sidebar">
      <div className="space-y-1">
        {items.map((itemOrGroup) =>
          "path" in itemOrGroup ? (
            <NavLink key={itemOrGroup.path} item={itemOrGroup} />
          ) : (
            <div className="pt-8">
              {itemOrGroup.title && (
                <h3 className="px-4 mb-1 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                  {itemOrGroup.title}
                </h3>
              )}
              {itemOrGroup.items.map((item) => (
                <NavLink key={item.path} item={item} />
              ))}
            </div>
          )
        )}
      </div>
    </nav>
  );
}

type NavLinkOptions = {
  item: NavItem;
};

function NavLink({ item }: NavLinkOptions) {
  const getProps = ({ isCurrent }: LinkGetProps) => ({
    className: navLinkClasses({ isCurrent }),
  });

  return (
    <Link to={item.path} getProps={getProps}>
      <span className="truncate">{item.name}</span>
    </Link>
  );
}

type NavLinkClassessOptions = {
  isCurrent: boolean;
};

const navLinkClasses = ({ isCurrent }: NavLinkClassessOptions) =>
  ctl(`
    flex
    items-center
    px-4
    py-2
    text-sm
    font-medium
    rounded-md
    ${
      isCurrent
        ? ctl(`
        text-brand-600
      `)
        : ctl(`
      text-gray-600
      hover:text-brand-600
      `)
    }
  `);
