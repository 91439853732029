import React from "react";
import { Dialog, DialogProps } from "@ui/Dialog";
import { NewAuthorizeOnDemandTransfer } from "./AuthorizeOnDemandTransfer";
import { AuthorizeOnDemandTransfersCopy } from "../BankAccountConstants";

type NewAuthorizeOnDemandTransferProps = {
  bankAccountId: number;
  dialogProps: Omit<DialogProps, "children">;
};

export function NewAuthorizeOnDemandTransferDialog({
  bankAccountId,
  dialogProps,
}: NewAuthorizeOnDemandTransferProps) {
  return (
    <Dialog {...dialogProps} width="lg" title="Authorize on-demand transfers">
      <p className="mb-4">{AuthorizeOnDemandTransfersCopy}</p>
      <NewAuthorizeOnDemandTransfer
        bankAccountId={bankAccountId}
        onAuthorize={dialogProps.onClose}
      />
    </Dialog>
  );
}
