import React, { FormEvent } from "react";
import { useRunDwollaSandboxSimulationsMutation } from "@apollo/ops";
import { ActionPanel } from "@components/ui/ActionPanel";
import { Button } from "@components/ui/Button";

export const DwollaSandboxSimulationsCard = () => {
  const [
    runDwollaSandboxSimulationsMutation,
    runDwollaSandboxSimulationsMutationResult,
  ] = useRunDwollaSandboxSimulationsMutation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    runDwollaSandboxSimulationsMutation();
  };

  return (
    <ActionPanel
      title="Run Dwolla Simulations"
      description="Runs last 500 transfers in sandbox"
      action={
        <form onSubmit={handleSubmit} className="w-0 flex-1 flex">
          <Button
            type="submit"
            disabled={runDwollaSandboxSimulationsMutationResult.loading}
          >
            Run Simulations
          </Button>
        </form>
      }
    />
  );
};
