import React from "react";
import { Controller, NestedValue, useForm } from "react-hook-form";
import { omit } from "lodash";
import { FileGenerator, FileInput } from "@components/FileUpload/FileInput";
import { Button } from "@components/ui/Button";
import { Calendar } from "@ui";
import classNames from "classnames";
import { DateTime } from "luxon";
import { toast } from "@util/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@util/i18n";
import { useAddPodDocumentMutation } from "@apollo/gen/generatedOperations";

type OrderPODDocumentFormData = {
  remoteFiles: NestedValue<Array<FileGenerator>>;
  deliveryDate: Date;
};

export type OrderPODDocumentSubmitData = {
  remoteFileId: number;
  deliveryDate: Date;
};

type OrderPODDocumentFormProps = {
  orderId: number;
  number: string;
};

export function OrderPODDocumentForm({
  orderId,
  number,
}: OrderPODDocumentFormProps) {
  const [addPODDocument] = useAddPodDocumentMutation();
  const onSubmit = async (formData: OrderPODDocumentSubmitData) => {
    const name = `${number}_proof_of_delivery.pdf`;
    try {
      await addPODDocument({
        variables: {
          data: {
            ...formData,
            name,
            orderId,
          },
        },
      });

      toast.current?.show({
        severity: MessageSeverity.success,
        detail: `Successfully upload POD: ${name}`,
      });
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<OrderPODDocumentFormData>({
    mode: "onSubmit",
    defaultValues: {
      remoteFiles: [],
      deliveryDate: DateTime.now().plus({ days: -1 }).toJSDate(),
    },
  });

  const wrappedOnSubmit = handleSubmit(async (formData) => {
    const remoteFileId = await formData.remoteFiles[0].getFileId();
    await onSubmit({
      remoteFileId,
      ...omit(formData, "remoteFiles"),
    });
  });

  return (
    <form onSubmit={wrappedOnSubmit}>
      <div className="grid grid-cols-1 gap-3">
        <div>
          <Controller
            name="remoteFiles"
            control={control}
            defaultValue={[]}
            rules={{
              validate: {
                exactlyOneFile: (files) =>
                  files.length === 1 || "One file is required",
              },
            }}
            render={({ field }) => (
              <FileInput
                label="Upload Proof of Delivery"
                error={errors.remoteFiles?.message}
                onChange={field.onChange}
                accept={"application/pdf, image/png, image/gif, image/jpeg"}
                onBlur={field.onBlur}
              />
            )}
          />
        </div>
        <div>
          <label
            htmlFor="deliveryDate"
            className="block text-sm font-medium text-gray-700"
          >
            Delivery Date
          </label>
          <div className="mt-1">
            <Controller
              name="deliveryDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  style={{ width: "100%" }}
                  value={value}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  monthNavigator
                  appendTo="self"
                  placeholder="Delivery date"
                  className={classNames({
                    "border-red-300": errors.deliveryDate,
                  })}
                />
              )}
            />
          </div>
        </div>
        <div>
          <Button type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
}
