import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import React from "react";
import {
  ProductFulfillmentExclusionReason,
  useProductFulfillmentExclusionReasonsQuery,
} from "@apollo/gen/generatedOperations";

export type ProductFulfillmentExclusionReasonDropdownProps = {
  value?: Pick<ProductFulfillmentExclusionReason, "id"> | null;
  onChange: (e: DropdownChangeParams) => void;
  disabled: boolean;
};

export function ProductFulfillmentExclusionReasonDropdown({
  value,
  onChange,
  disabled,
}: ProductFulfillmentExclusionReasonDropdownProps) {
  const { data } = useProductFulfillmentExclusionReasonsQuery({
    //cache-first to prevent every instance of this component from making a request to the server
    //We also call this at a higher level in the component tree with network-only policy
    //when viewing pre-sale products to populate the local cache with the latest exclusion reasons
    fetchPolicy: "cache-first",
  });

  if (!data) return null;

  return (
    <Dropdown
      className="ml-4"
      options={data.ProductFulfillmentExclusionReasons}
      value={value}
      onChange={onChange}
      placeholder="Exclusion Reason"
      disabled={disabled}
    />
  );
}
