import { Dialog } from "@components/ui/Dialog";
import Joi from "joi";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { LabeledInput } from "@components/ui/Input";
import { Button } from "@components/ui/Button";

export interface AddressFormData {
  address1: string;
  address2?: string;
  name: string;
  company?: string;
  city: string;
  state: string;
  zip: string;
  phone?: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  defaultValues?: AddressFormData;
  onSubmit: (data: AddressFormData) => void | Promise<void>;
}

const formSchema = Joi.object<AddressFormData>({
  address1: Joi.string().required(),
  address2: Joi.string().allow(""),
  name: Joi.string().required(),
  company: Joi.string().allow(""),
  city: Joi.string().required(),
  state: Joi.string().required(),
  zip: Joi.string().regex(/\d{5}/).required(),
  phone: Joi.string().allow(""),
});

export const AddressModal: FC<Props> = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit,
}) => {
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isValidating },
    register,
  } = useForm<AddressFormData>({
    defaultValues,
    mode: "onSubmit",
    resolver: joiResolver(formSchema),
  });

  const wrappedOnClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <Dialog title="Update Address" isOpen={isOpen} onClose={wrappedOnClose}>
      <form
        data-testid="AddressForm"
        onSubmit={handleSubmit(onSubmit, console.error)}
        className="grid grid-cols-1 gap-y-5"
      >
        <LabeledInput
          label="Name"
          inputProps={{
            error: errors.name?.message,
            "data-testid": "AddressFormName",
            ...register("name"),
          }}
        />
        <LabeledInput
          label="Company"
          inputProps={{
            error: errors.company?.message,
            ...register("company"),
          }}
        />
        <LabeledInput
          label="Address Line 1"
          inputProps={{
            error: errors.address1?.message,
            ...register("address1"),
          }}
        />
        <LabeledInput
          label="Address Line 2"
          inputProps={{
            error: errors.address2?.message,
            ...register("address2"),
          }}
        />
        <LabeledInput
          label="City"
          inputProps={{
            error: errors.city?.message,
            ...register("city"),
          }}
        />
        <LabeledInput
          label="State"
          inputProps={{
            error: errors.state?.message,
            ...register("state"),
          }}
        />
        <LabeledInput
          label="Zip Code"
          inputProps={{
            error: errors.zip?.message,
            ...register("zip"),
          }}
        />
        <LabeledInput
          label="Phone Number"
          inputProps={{
            error: errors.phone?.message,
            ...register("phone"),
          }}
        />
        <div>
          <Button
            data-testid="AddressSubmitButton"
            type="submit"
            disabled={isSubmitting || isValidating}
          >
            Submit
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
