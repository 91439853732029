import React, { ElementType, MouseEventHandler } from "react";
import { Disclosure } from "@headlessui/react";
import classnames from "classnames";
import { Link, LinkGetProps } from "@reach/router";

export type SidebarNavigationSection = {
  name: string;
  children: SidebarNavigationItem[];
  icon?: ElementType;
};

export type SidebarNavigationItem =
  | {
      name: string;
      path: string;
      icon?: ElementType;
      exactMatch?: boolean;
    }
  | NavButton;

type NavButton = {
  name: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon?: ElementType;
  exactMatch?: boolean;
};

export type SidebarNavigationElement =
  | SidebarNavigationItem
  | SidebarNavigationSection;

export type SidebarNavigationProps = {
  elements: SidebarNavigationElement[];
};

export function SidebarNavigationV2({ elements }: SidebarNavigationProps) {
  return (
    <nav className="px-2 space-y-1" aria-label="Sidebar">
      {elements.map((element) =>
        "children" in element ? (
          <div key={element.name}>
            <NavigationSection element={element} />
          </div>
        ) : (
          <div key={element.name}>
            <NavigationLink element={element} />
          </div>
        )
      )}
    </nav>
  );
}

type NavigationLinkOptions = {
  element: SidebarNavigationItem;
};

export function NavigationLink({ element }: NavigationLinkOptions) {
  const getProps = ({ isPartiallyCurrent, isCurrent }: LinkGetProps) => ({
    className: classnames(
      (element.exactMatch ? isCurrent : isPartiallyCurrent)
        ? "bg-white bg-opacity-20 text-skin-base skin-link"
        : "skin-link",
      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
    ),
  });

  if ("path" in element) {
    return (
      <Link to={element.path} getProps={getProps}>
        {element.icon && (
          <element.icon
            className={
              "text-skin-base text-opacity-50 group-hover:text-opacity-100 mr-3 h-6 w-6"
            }
            aria-hidden="true"
          />
        )}
        {element.name}
      </Link>
    );
  } else {
    return (
      <button
        onClick={element.onClick}
        className="skin-link group flex items-center px-2 py-2 text-sm font-medium rounded-md"
      >
        {element.icon && (
          <element.icon
            className={
              "text-skin-base text-opacity-50 group-hover:text-opacity-100 mr-3 h-6 w-6"
            }
            aria-hidden="true"
          />
        )}

        {element.name}
      </button>
    );
  }
}

type NavigationSectionOptions = {
  element: SidebarNavigationSection;
};

function NavigationSection({ element }: NavigationSectionOptions) {
  return (
    <Disclosure as="div" key={element.name} className="space-y-1">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classnames(
              "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group w-full flex items-center pl-2 pr-1 py-2 font-medium rounded-md focus:outline-none focus:ring-2"
            )}
          >
            {element.icon && (
              <element.icon
                className={
                  "text-skin-base text-opacity-50 group-hover:text-opacity-100 mr-3 h-6 w-6"
                }
                aria-hidden="true"
              />
            )}
            {element.name}
            <svg
              className={classnames(
                "text-skin-base text-opacity-50 group-hover:text-opacity-100 mr-3 h-6 w-6",
                open && `rotate-90`,
                "ml-auto h-5 w-5 transform transition-colors ease-in-out duration-150"
              )}
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
            </svg>
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1">
            {element.children.map((child) => (
              <NavigationLink key={child.name} element={child} />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
