import React from "react";
import { Dialog, UseDialogResult } from "@ui/Dialog";
import { useAddPreSaleOrderMutation } from "@apollo/ops";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { Controller, useForm } from "react-hook-form";
import { AllRetailersAutocomplete } from "@components/Retailer/RetailerAutocomplete/RetailerAutocomplete";
import { Button } from "@ui/Button";

type AddPreSaleOrderDialogProps = {
  preSaleId: number;
  dialog: UseDialogResult;
};

export function AddPreSaleOrderDialog({
  preSaleId,
  dialog,
}: AddPreSaleOrderDialogProps) {
  const { getDialogProps } = dialog;
  const [addPreSaleOrder] = useAddPreSaleOrderMutation();

  const handleSubmit = async (formData: AddPreSaleOrderFormData) => {
    try {
      const result = await addPreSaleOrder({
        variables: {
          ...formData,
          preSaleId,
        },
      });

      if (result.data) {
        dialog.close();
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <Dialog {...getDialogProps()} title="New Document">
      <AddPreSaleOrderForm onSubmit={handleSubmit} />
    </Dialog>
  );
}

type AddPreSaleOrderFormData = {
  storeId: number;
};

type AddPreSaleOrderProps = {
  onSubmit: (data: AddPreSaleOrderFormData) => void;
};

export const AddPreSaleOrderForm = ({ onSubmit }: AddPreSaleOrderProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<AddPreSaleOrderFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-5">
        <Controller
          name="storeId"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <AllRetailersAutocomplete
              label="Select a store"
              onSelect={onChange}
              onBlur={onBlur}
              selectedId={value}
              error={errors.storeId?.message}
            />
          )}
        />
      </div>
      <div className={"mt-5"}>
        <Button type="submit" disabled={isSubmitting} kind="secondary">
          Add order
        </Button>
      </div>
    </form>
  );
};
