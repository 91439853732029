import React, { PropsWithChildren } from "react";
import { Spinner } from "@shared/components/Spinner";
import { Overlay } from "./SpinnerOverlayStyle";

interface SpinnerOverlayProps {
  spin: boolean;
}
const SpinnerOverlay: React.FC<PropsWithChildren<SpinnerOverlayProps>> = ({
  children,
  spin = false,
}) => {
  return (
    <div style={{ position: "relative" }}>
      {children}
      <Overlay visible={spin}>
        <span>
          <Spinner />
        </span>
      </Overlay>
    </div>
  );
};

export default SpinnerOverlay;
