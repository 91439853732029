import React from "react";
import { ApolloError } from "@apollo/client";
import { ComponentType } from "react";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { LoadingIcon } from "@components/ui/Spinner";

export interface PlaidLinkHookResult {
  loading: boolean;
  error?: ApolloError;
  plaidLinkToken?: string;
}

export type PlaidLinkHook = (...args: any) => PlaidLinkHookResult;

export interface WithPlaidLinkTokenOptions<TProps, TOptions> {
  useHook: PlaidLinkHook;
  mapPropsToHookOptions?: (props: TProps) => TOptions;
}

export type WithPlaidLinkTokenInjectedProps = {
  plaidLinkToken: string;
};

/**
 * @remarks
 * There actually isn't any Plaid Link token specific logic in here and there's
 * a lot similarity between this and the withQuery hook. This could likely be
 * refactored to use withQuery (withQuery was built with a page in mind, but
 * with some work could be generic to conditionally rendering an isolated part
 * of a page).
 */
export function withPlaidLinkToken<TProps, TOptions = never>({
  useHook,
  mapPropsToHookOptions,
}: WithPlaidLinkTokenOptions<TProps, TOptions>) {
  return (
    Component: ComponentType<TProps & WithPlaidLinkTokenInjectedProps>
  ) => {
    const WithPlaidLinkToken = (props: TProps) => {
      const hookOptions = mapPropsToHookOptions
        ? mapPropsToHookOptions(props)
        : undefined;
      const { plaidLinkToken, error, loading } = useHook(hookOptions);

      // TODO refactor when holistic error handling is introduced
      if (error) {
        console.error(error);
        if (error.message) {
          toast.current?.show({
            severity: MessageSeverity.error,
            summary: "Error",
            detail: error.message,
          });
        }
      }

      if (loading || !plaidLinkToken) {
        // tabIndex is added to ensure a focusable element always exists
        return (
          <div tabIndex={0}>
            <LoadingIcon />
          </div>
        );
      }

      return <Component {...props} plaidLinkToken={plaidLinkToken} />;
    };

    return WithPlaidLinkToken;
  };
}
