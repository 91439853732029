import { LedgerStatus } from "@apollo/ops";
import { Badge, BadgeProps, Color } from "@ui/Badge";

type LedgerStatusBadgeProps = Partial<BadgeProps> & {
  status: LedgerStatus;
};

export function LedgerStatusBadge({ status, ...rest }: LedgerStatusBadgeProps) {
  return Badge({ ...getLedgerStatusBadgeProps(status), ...rest });
}

function getLedgerStatusBadgeProps(
  status: LedgerStatus
): { label: string; color: Color } {
  switch (status) {
    case LedgerStatus.NotPaid:
      return { label: "NOT PAID", color: "gray" as Color };
    case LedgerStatus.PartiallyPaid:
      return { label: "PARTIAL PAID", color: "yellow" as Color };
    case LedgerStatus.PendingPaid:
      return { label: "PENDING PAID", color: "blue" as Color };
    case LedgerStatus.Paid:
      return { label: "PAID", color: "green" as Color };
  }
}
