import React from "react";
import {
  AutocompleteRetailerFragment,
  useAutocompleteRetailersQuery,
} from "@apollo/ops";
import {
  Autocomplete,
  buildMatchSorterFilter,
  ItemState,
} from "@ui/Autocomplete";
import classnames from "classnames";

export type AllRetailerAutocompleteProps = {
  onSelect: (retailerId: number | null) => void;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
};

export function AllRetailersAutocomplete(props: AllRetailerAutocompleteProps) {
  const { data } = useAutocompleteRetailersQuery();

  if (data) {
    return <RetailersAutocomplete {...props} retailers={data.retailers} />;
  }

  return null;
}

export type RetailersAutocompleteProps = {
  onSelect: (retailerId: number | null) => void;
  retailers: Array<AutocompleteRetailerFragment>;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
};

export function RetailersAutocomplete({
  label,
  error,
  selectedId,
  retailers,
  onSelect,
  onBlur,
}: RetailersAutocompleteProps) {
  const handleSelect = (
    selectedRetailer?: AutocompleteRetailerFragment | null
  ) => {
    const id = selectedRetailer ? selectedRetailer.id : null;
    onSelect(id);
  };

  const selectedRetailer =
    retailers.find((item) => item.id === selectedId) ?? null;

  return (
    <Autocomplete
      label={label}
      error={error}
      hideToggleButton={true}
      placeholder="Search retailers"
      onSelect={handleSelect}
      onClickDelete={() => handleSelect(null)}
      onBlur={onBlur}
      items={retailers}
      selectedItem={selectedRetailer}
      renderItem={renderRetailer}
      itemToString={retailerToString}
      filterItems={filterRetailers}
    />
  );
}

const filterRetailers = buildMatchSorterFilter<AutocompleteRetailerFragment>({
  keys: [
    (retailer) => retailer.displayName,
    (retailer) => retailer.regionV2.friendlyName,
  ],
});

const retailerToString = (
  retailer: AutocompleteRetailerFragment | null
): string => (retailer ? retailer.displayName : "");

const renderRetailer = (
  retailer: AutocompleteRetailerFragment,
  { highlighted, selected }: ItemState
) => {
  return (
    <div className="text-sm">
      <p
        className={classnames(
          "text-xs",
          selected ? "font-bold" : "font-medium",
          highlighted ? "text-white" : "text-gray-900"
        )}
      >
        {retailer.regionV2.friendlyName}
      </p>
      <p
        className={classnames(
          selected ? "font-bold" : "font-medium",
          highlighted ? "text-white" : "text-gray-900"
        )}
      >
        {retailer.displayName}
      </p>
    </div>
  );
};
