import React, { ComponentType } from "react";

export const withHookUntyped = (useHook: (...args: any) => any) => {
  return (WrappedCmp: ComponentType<any>) => {
    const ViewAndHookGlueEnhancer = (props: any) => {
      const hookProps = useHook(props);
      return <WrappedCmp {...props} {...hookProps} />;
    };
    return ViewAndHookGlueEnhancer;
  };
};
export default withHookUntyped;

export interface WithHookOptions<THookResult> {
  useHook: (...args: any) => THookResult;
}

export function withHook<TProps, THookResult>({
  useHook,
}: WithHookOptions<THookResult>) {
  return (Component: ComponentType<TProps>) => {
    const WithHook = (props: TProps) => {
      const hookProps = useHook(props);
      return <Component {...props} {...hookProps} />;
    };

    return WithHook;
  };
}
