import React, { useMemo } from "react";
import { Table } from "@ui/Table";
import { CellProps, Column, TableInstance, useTable } from "react-table";
import { formatAsDate } from "@utilities/formatDate";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { ProducerOfferTableRowData } from "./ProducerOffersPage";
import { formatToDollars } from "@utilities/currency";
import { ProducerOfferTableDataFragment } from "@apollo/ops";
import { ProducerProductDisplay } from "@components/Product/ProducerProductDisplay";

type ProducerOffersTableProps = {
  data: Array<ProducerOfferTableRowData>;
  pagination?: UseOffsetPaginationResult;
};

export function ProducerOffersTable({
  data,
  pagination,
}: ProducerOffersTableProps) {
  const columns: Array<Column<ProducerOfferTableRowData>> = useMemo(
    () => [
      {
        Header: "Launch Date",
        Cell: ({ value }) => formatAsDate(value),
        accessor: "launchDate",
        width: 120,
      },
      {
        Header: "Region",
        accessor: "regionFriendlyName",
        Cell: ({ value }) => value,
        width: 120,
      },
      {
        Header: "Product",
        Cell: ({
          row,
        }: CellProps<ProducerOfferTableDataFragment, "string">) => (
          <ProducerProductDisplay product={row.original.product} />
        ),
        accessor: "product",
        width: 140,
      },
      {
        Header: "Platform Price",
        Cell: ({ value }) => formatToDollars({ cents: value }),
        accessor: "platformPrice",
        width: 80,
      },
      {
        Header: "Retail Wholesale Price",
        Cell: ({ value }) => formatToDollars({ cents: value }),
        accessor: "wholesalePrice",
        width: 80,
      },
      {
        Header: "Quantity",
        Cell: ({ value }) => value,
        accessor: "quantity",
        width: 80,
      },
      {
        Header: "Earliest Pickup",
        Cell: ({ value }) => value && formatAsDate(value),
        accessor: "earliestPickup",
        width: 80,
      },
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<ProducerOfferTableRowData>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
