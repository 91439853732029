import {
  DeliveryAgingReportResultFragment,
  ServiceLevelTarget,
} from "@apollo/ops";
import { AdminOrderLink } from "@components/Order/Order";
import { AdminProducerLink } from "@components/Producer/Producer";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { Table } from "@ui/Table";
import { formatAsDate } from "@utilities/formatDate";
import React, { useMemo } from "react";
import { CellProps, Column, TableInstance, useTable } from "react-table";
import { DeliveryProgressBar } from "@components/ProducerPurchaseOrder/DeliveryProgressBar";
import {
  DeliveryAge,
  DeliveryAgeFromFreight,
} from "@components/ProducerPurchaseOrder/DeliveryAge";
import { FreightShipmentStatusBadge } from "@components/TrackingInformation/FreightShipmentStatusBadge";

type BuildColumnsOptions = {
  deliverySLT: ServiceLevelTarget;
  deliveryStepSLT: ServiceLevelTarget;
};

export const buildColumns = ({
  deliverySLT,
  deliveryStepSLT,
}: BuildColumnsOptions): Array<Column<DeliveryAgingReportResultFragment>> => [
  {
    Header: "Placed on",
    Cell: ({ value }: CellProps<DeliveryAgingReportResultFragment, "string">) =>
      formatAsDate(new Date(value)),
    accessor: "placedOn",
    width: 50,
  },
  {
    Header: "Order",
    Cell: ({ row }: CellProps<DeliveryAgingReportResultFragment, "string">) => (
      <>
        <AdminOrderLink order={row.original} />
        {row.original.invoice?.externalInvoiceId && (
          <div className="text-muted">
            {row.original.invoice?.externalInvoiceId}
          </div>
        )}
        <div className="text-sm text-muted">
          {row.original.regionV2.friendlyName}
        </div>
      </>
    ),
    accessor: "number",
    disableSortBy: true,
    id: "number",
    width: 120,
  },
  {
    Header: "Brewery",
    Cell: ({ row }: CellProps<DeliveryAgingReportResultFragment>) => (
      <AdminProducerLink producer={row.original.seller} />
    ),
    accessor: (order) => order.seller.displayName,
    id: "seller",
  },
  {
    Header: "Freight",
    Cell: FreightStatusCell,
  },
  {
    Header: "Age from freight",
    Cell: ({ row }: CellProps<DeliveryAgingReportResultFragment, number>) => (
      <div className="w-20">
        <DeliveryAgeFromFreight
          freightDeliveredAt={
            row.original.trackingInformation?.deliveredAt ?? null
          }
          serviceLevelTarget={deliveryStepSLT}
        />
      </div>
    ),
    id: "ageFromFreight",
    width: 90,
  },
  {
    Header: "Delivery",
    Cell: ({ row }: CellProps<DeliveryAgingReportResultFragment, number>) => (
      <div className="laptop:w-64">
        <DeliveryProgressBar order={row.original} />
      </div>
    ),
    id: "progress",
  },
  {
    Header: "Age",
    Cell: ({ row }: CellProps<DeliveryAgingReportResultFragment, number>) => (
      <div className="w-20">
        <DeliveryAge
          placedOn={row.original.placedOn}
          serviceLevelTarget={deliverySLT}
        />
      </div>
    ),
    id: "age",
    width: 90,
  },
];

type DeliveryAgingReportTableProps = {
  data: Array<DeliveryAgingReportResultFragment>;
  pagination?: UseOffsetPaginationResult;
  deliverySLT: ServiceLevelTarget;
  deliveryStepSLT: ServiceLevelTarget;
};

export function DeliveryAgingReportTable({
  data,
  pagination,
  deliverySLT,
  deliveryStepSLT,
}: DeliveryAgingReportTableProps) {
  const columns = useMemo(
    () => buildColumns({ deliverySLT, deliveryStepSLT }),
    [deliverySLT, deliveryStepSLT]
  );

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<DeliveryAgingReportResultFragment>;

  return <Table instance={tableInstance} pagination={pagination} align="top" />;
}

function FreightStatusCell({
  row,
}: CellProps<DeliveryAgingReportResultFragment, number>) {
  const { trackingInformation } = row.original;

  return (
    <>
      <FreightShipmentStatusBadge
        status={trackingInformation?.status ?? null}
      />
      {trackingInformation?.estimatedArrivalDate &&
        !trackingInformation?.deliveredAt && (
          <div className="text-gray-500 mt-1.5 ml-2 text-sm">
            Estimated to arrive{" "}
            {formatAsDate(trackingInformation.estimatedArrivalDate)}
          </div>
        )}
      {trackingInformation?.deliveredAt && (
        <div className="text-gray-500 mt-1.5 ml-2 text-sm">
          Delivered {formatAsDate(trackingInformation.deliveredAt)}
        </div>
      )}
    </>
  );
}
