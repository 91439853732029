import { useGetShopifyUrlWithLoginTokenLazyQuery } from "@apollo/ops";

declare const window: Window;

interface IRedirectToShopify {
  path?: string;
  organizationId: number;
}

export const useRedirectToShopify = ({
  path,
  organizationId,
}: IRedirectToShopify) => {
  const shopifyPath = path ? path : null;
  const [
    fetchToken,
    { loading, error },
  ] = useGetShopifyUrlWithLoginTokenLazyQuery({
    variables: { path: shopifyPath, organizationId },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const onClick = () => {
    fetchToken().then(({ data }) => {
      if (data && data.getShopifyUrlWithLoginToken?.shopifyUrlWithLoginToken) {
        window.location.href =
          data.getShopifyUrlWithLoginToken.shopifyUrlWithLoginToken;
      }
    });
  };

  return {
    onClick,
    loading,
    error,
  };
};
