import { StateV2, useStatesQuery } from "@apollo/gen/generatedOperations";

//Named "useGeographicalStates" to clearly disambiguate from
//React's "useState" hook
//Shows only states where we have a Region set up, per product requirements
export const useGeographicalStates = (): Array<
  Pick<StateV2, "id" | "name">
> => {
  const { data } = useStatesQuery();
  return data?.statesWhereRegionExists ?? [];
};
