import {
  DwollaCustomerBeneficialOwnerCertificationStatus,
  DwollaCustomerVerificationStatus,
} from "@apollo/ops";
import { MessageSeverity, Severity } from "@shared/types/Severity";

export const getUserFriendlyDwollaStatusMessages = (
  dwollaCustomerStatus: DwollaCustomerVerificationStatus
): {
  status: string;
  message: string | null;
  messageSeverity: MessageSeverity | null;
  severity: Severity;
} => {
  switch (dwollaCustomerStatus) {
    case DwollaCustomerVerificationStatus.Document:
      return {
        status: "Pending Verification",
        message:
          "We are getting your identity verified, check back in 1-2 business days to view the result",
        messageSeverity: MessageSeverity.warn,
        severity: Severity.info,
      };
    case DwollaCustomerVerificationStatus.Suspended:
      return {
        status: "Suspended",
        message:
          "Your business verification failed. Please Contact Direct.Beer at support@direct.beer for assistance",
        messageSeverity: MessageSeverity.error,
        severity: Severity.danger,
      };
    case DwollaCustomerVerificationStatus.Verified:
      return {
        status: "Verified",
        message: null,
        messageSeverity: null,
        severity: Severity.success,
      };
    case DwollaCustomerVerificationStatus.Unverified:
      return {
        status: "Unverified",
        message: null,
        messageSeverity: null,
        severity: Severity.success,
      };
    default:
      throw new Error(
        `There is not a message declared for the dwolla status that you sent: ${dwollaCustomerStatus}`
      );
  }
};

export type BeneficialOwnerStatusDisplayData = {
  status: string;
  message: string | null;
  messageSeverity: MessageSeverity | null;
  severity: Severity;
};

export const beneficialOwnerStatusDisplayData = (
  status: DwollaCustomerBeneficialOwnerCertificationStatus
): BeneficialOwnerStatusDisplayData => {
  switch (status) {
    case DwollaCustomerBeneficialOwnerCertificationStatus.Uncertified:
      return {
        status: "Uncertified",
        message:
          "You are currently uncertified. Please contact Direct.Beer at support@direct.beer for assistance",
        messageSeverity: MessageSeverity.warn,
        severity: Severity.info,
      };
    case DwollaCustomerBeneficialOwnerCertificationStatus.Recertify:
      return {
        status: "Recertify",
        message:
          "Your beneficial ownership certification needs more information. Please contact Direct.Beer at support@direct.beer for assistance",
        messageSeverity: MessageSeverity.error,
        severity: Severity.danger,
      };
    case DwollaCustomerBeneficialOwnerCertificationStatus.Certified:
      return {
        status: "Certified",
        message: null,
        messageSeverity: null,
        severity: Severity.success,
      };
    default:
      throw new Error(
        `There is not a message declared for the dwolla status that you sent: ${status}`
      );
  }
};
