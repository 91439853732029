import React from "react";
import {
  AdminOfferWindowSearchDocument,
  AdminOfferWindowSearchQuery,
  AdminOfferWindowSearchQueryVariables,
} from "@apollo/ops";
import { navigate, RouteComponentProps } from "@reach/router";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { Page, PageHeading } from "@components/ui/Page";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import {
  AdminOfferWindowsFilter,
  AdminOfferWindowsFilterFormData,
} from "./AdminOfferWindowsFilters";
import { AdminOfferWindowsTable } from "./AdminOfferWindowsTable";
import { DateTime } from "luxon";
import { AddOfferWindowButton } from "./AddOfferWindow/AddOfferWindow";
import { adminOfferWindowPath } from "@routes";
import { ErrorAlert } from "@components/ui/Alert";

export function AdminOfferWindowsPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    regionId: NumberParam,
    before: StringParam,
    after: StringParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    AdminOfferWindowSearchQuery,
    AdminOfferWindowSearchQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        filters,
      },
    },
    query: AdminOfferWindowSearchDocument,
    mapDataToTotal({ searchOfferWindows }): number {
      return searchOfferWindows?.total ?? 0;
    },
  });

  const { data, error } = queryResult;

  const breadcrumbProps = {
    items: buildBreadcrumbs("Offer Windows"),
  };

  const updateFilters = ({
    regionId,
    dateRange,
  }: AdminOfferWindowsFilterFormData) => {
    pagination.reset();

    const after = dateRange?.after
      ? DateTime.fromJSDate(dateRange.after).startOf("day").toISO()
      : null;

    const before = dateRange?.before
      ? DateTime.fromJSDate(dateRange.before).endOf("day").toISO()
      : null;

    setFilters({
      regionId,
      before,
      after,
    });
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Offer Windows"
          breadcrumbProps={breadcrumbProps}
          actions={
            <AddOfferWindowButton
              onAdd={(data) =>
                navigate(adminOfferWindowPath(data.addOfferWindow.id))
              }
            />
          }
        />
      }
    >
      {error && <ErrorAlert title="Failed to load offer windows" />}
      {data && (
        <>
          <div className="mb-3">
            <AdminOfferWindowsFilter
              onFilter={updateFilters}
              defaultValues={mapQueryParamsToDefaultValues(filters)}
            />
          </div>

          <AdminOfferWindowsTable
            data={data?.searchOfferWindows.results}
            pagination={pagination}
          />
        </>
      )}
    </Page>
  );
}

type QueryParamsData = {
  regionId: number | null | undefined;
  before: string | null | undefined;
  after: string | null | undefined;
};

function mapQueryParamsToDefaultValues({
  regionId,
  before,
  after,
}: QueryParamsData): Partial<AdminOfferWindowsFilterFormData> {
  const dateRange =
    before && after
      ? {
          before: new Date(before),
          after: new Date(after),
        }
      : undefined;

  return {
    regionId: regionId ?? null,
    dateRange,
  };
}
