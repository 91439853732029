import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import {
  StorePreSaleOrderCancelledItemFragment,
  StorePreSaleOrderItemFragment,
  useStorePreSaleOrderQuery,
} from "@apollo/ops";
import { StoreRouterParams } from "../../StoreRouterParam";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { formatAmountInCents } from "@utilities/currency";
import { CalendarIcon } from "@heroicons/react/solid";
import { formatAsDate } from "@utilities/formatDate";
import { LoadingPage } from "@components/LoadingPage";
import { Table } from "@components/ui/Table";
import { CellProps, Column, TableInstance, useTable } from "react-table";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { CancelledAlert } from "@components/Order/CancelledAlert";
import { WarningAlert } from "../../../../components/ui";

type StorePreSaleOrderPageProps = RouteComponentProps<
  StoreRouterParams & {
    orderIdParam: string;
  }
>;

export function StorePreSaleOrderPage({
  orderIdParam,
}: StorePreSaleOrderPageProps) {
  if (orderIdParam === undefined) {
    return <RedirectToNotFound />;
  }

  const parsedOrderId = parseInt(orderIdParam);
  if (isNaN(parsedOrderId)) {
    return <RedirectToNotFound />;
  }

  return <SanitizedStorePreSaleOrderPage orderId={parsedOrderId} />;
}

type SanitizedStorePreSaleOrderPageProps = {
  orderId: number;
};

function SanitizedStorePreSaleOrderPage({
  orderId,
}: SanitizedStorePreSaleOrderPageProps) {
  const { data, loading, error } = useStorePreSaleOrderQuery({
    variables: {
      orderId,
    },
  });

  if (data) {
    const { preSaleOrder } = data;
    const breadcrumbProps = {
      items: buildBreadcrumbs("Pre-Sale Orders", `#${preSaleOrder.number}`),
    };
    const metaProps = {
      items: [
        {
          icon: CalendarIcon,
          text: formatAsDate(preSaleOrder.placedOn),
        },
      ],
    };

    return (
      <Page
        heading={
          <PageHeading
            title={`#${preSaleOrder.number}`}
            breadcrumbProps={breadcrumbProps}
            metaProps={metaProps}
          />
        }
      >
        {preSaleOrder.cancelledAt && (
          <div className="mb-5">
            <CancelledAlert cancelledAt={preSaleOrder.cancelledAt} />
          </div>
        )}
        <div>
          <div className="text-primary-600 uppercase text-sm font-bold tracking-wide">
            Thank you!
          </div>
          <div className="text-gray-600">
            Your pre-sale order has been placed. This is not an invoice.
            Payments will be initiated from your bank account based on final
            deliveries and separate invoices will be provided.
          </div>
        </div>
        <div className="mt-5">
          <div className="grid gap-3 laptop:grid-cols-3">
            <div>
              <div className="text-sm font-semibold">Order total</div>
              <div>{formatAmountInCents(preSaleOrder.amountInCents)}</div>
              <div className="text-sm text-gray-700 leading-snug">
                This is the total of all items ordered in the pre-sale.
              </div>
            </div>
            <div>
              <div className="text-sm font-semibold">Cancelled total</div>
              <div>{formatAmountInCents(preSaleOrder.cancelledTotal)}</div>
              <div className="text-sm text-gray-700 leading-snug">
                This is the total of all cancelled items.
              </div>
            </div>
            <div>
              <div className="text-sm font-semibold">
                Estimated fulfillment total
              </div>
              <div>
                {formatAmountInCents(preSaleOrder.estimatedFulfillmentTotal)}
              </div>
              <div className="text-sm text-gray-700 leading-snug">
                This is the estimated total of all products that will be
                fulfilled from this pre-sale order.
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <OrderItemsTable data={preSaleOrder.items} />
        </div>
        {preSaleOrder.cancelledItems.length > 0 && (
          <div className="mt-5">
            <WarningAlert
              title="Some items in your order can not be fulfilled"
              description="The following items will not be delivered and you will not be charged for them. We apologize for any inconvenience"
            />
            <div className="mt-5">
              <CancelledItemsTable data={preSaleOrder.cancelledItems} />
            </div>
          </div>
        )}
        <div className="mt-10">
          <SectionHeading title="Products in this order" />
          <ProductsInThisOrder data={preSaleOrder.items} />
        </div>
      </Page>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  throw error || new Error("Failed to load page");
}

type TableProps = {
  data: Array<StorePreSaleOrderItemFragment>;
};

function OrderItemsTable({ data }: TableProps) {
  const columns: Array<Column<StorePreSaleOrderItemFragment>> = useMemo(
    () => [
      {
        Header: "Product",
        Cell: ({ row }) => {
          const { product } = row.original;
          return (
            <div className="inline-flex">
              <div className="h-[60px] w-[60px] mr-4">
                {product.productImage?.thumbnailUrl ? (
                  <img
                    src={product.productImage.thumbnailUrl}
                    className="h-full w-full object-contain"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center text-xs muted"></div>
                )}
              </div>
              <div className="leading-tight">
                <div className="font-medium">{product.name}</div>
                <div>{product.producer.displayName}</div>
                <div className="text-sm text-muted">
                  {product.packageType?.displayName}{" "}
                  {product.packageType && product.packageDate
                    ? "packaged on " + formatAsDate(product.packageDate)
                    : ""}
                </div>
              </div>
            </div>
          );
        },
        accessor: "product",
      },
      {
        Header: "Price",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "price",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Item total",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "total",
      },
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<StorePreSaleOrderItemFragment>;

  return <Table instance={tableInstance} />;
}

function ProductsInThisOrder({ data }: TableProps) {
  if (data.length === 0) {
    return <div>No items.</div>;
  }

  return (
    <div className="divide-y border">
      {data.map((item) => (
        <div key={item.product.id} className="bg-white">
          <div className="tablet:flex p-4">
            <div className="tablet:flex-none w-full tablet:w-56 tablet:h-56 tablet:mr-6">
              {item.product.productImage?.rawUrl ? (
                <img
                  src={item.product.productImage.rawUrl}
                  className="w-full max-h-full object-contain"
                />
              ) : (
                <div className="w-full h-full bg-gray-200 flex items-center justify-center text-xs muted"></div>
              )}
            </div>
            <div className="mt-3 px-2 tablet:flex-1 tablet:mt-0 tablet:px-0">
              <div className="leading-tight">
                <div className="font-bold text-lg">{item.product.name}</div>
                <div>{item.product.producer.displayName}</div>
              </div>
              <div className="mt-2 font-medium text-sm">
                {item.product.packageType.displayName}
              </div>
              {item.product.description && (
                <div className="mt-3 text-gray-600 text-sm">
                  {item.product.description}
                </div>
              )}
              <div className="mt-5">
                <div className="font-medium text-sm mb-2">Highlights</div>
                <ol className="text-gray-600 list-disc list-inside text-sm">
                  {item.product.abv && <li>{item.product.abv}% ABV</li>}
                  {item.product.rating && <li>{item.product.rating} rating</li>}
                  {item.product.packageDate && (
                    <li>
                      packaged on {formatAsDate(item.product.packageDate)}
                    </li>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

type CancelledItemsTableProps = {
  data: Array<StorePreSaleOrderCancelledItemFragment>;
};

function CancelledItemsTable({ data }: CancelledItemsTableProps) {
  const columns: Array<
    Column<StorePreSaleOrderCancelledItemFragment>
  > = useMemo(
    () => [
      {
        Header: "Product",
        Cell: ({ row }: CellProps<StorePreSaleOrderCancelledItemFragment>) => {
          const { product } = row.original.item;
          return (
            <div className="inline-flex">
              <div className="h-[60px] w-[60px] mr-4">
                {product.productImage?.thumbnailUrl ? (
                  <img
                    src={product.productImage.thumbnailUrl}
                    className="h-full w-full object-contain"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center text-xs muted"></div>
                )}
              </div>
              <div className="leading-tight">
                <div className="font-medium">{product.name}</div>
                <div>{product.producer.displayName}</div>
                <div className="text-sm text-muted">
                  {product.packageType?.displayName}{" "}
                  {product.packageType && product.packageDate
                    ? "packaged on " + formatAsDate(product.packageDate)
                    : ""}
                </div>
              </div>
            </div>
          );
        },
        accessor: ({ item }) => item.product,
      },
      {
        Header: "Price",
        Cell: ({ value }: { value: number }) => formatAmountInCents(value),
        accessor: ({ item }) => item.price,
      },
      {
        Header: "Quantity",
        accessor: ({ item }) => item.quantity,
      },
      {
        Header: "Item total",
        Cell: ({ value }: { value: number }) => formatAmountInCents(value),
        accessor: ({ item }) => item.total,
      },
      {
        Header: "Reason",
        accessor: ({ exclusionReason }) =>
          exclusionReason?.label ?? "No reason provided",
      },
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<StorePreSaleOrderCancelledItemFragment>;

  return <Table instance={tableInstance} />;
}
