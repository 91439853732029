import React from "react";
import {
  useSendAdjustmentsPaymentDataQuery,
  useSendAdjustmentsPaymentMutation,
} from "@apollo/ops";
import { LoadingIcon } from "@components/ui/Spinner";
import { ConfirmTransfer } from "@admin/components/ConfirmTransfer";

type SendAdjustmentsPaymentProps = {
  orderId: number;
};

export function SendAdjustmentsPayment({
  orderId,
}: SendAdjustmentsPaymentProps) {
  const { data, loading, error } = useSendAdjustmentsPaymentDataQuery({
    variables: {
      orderId,
    },
  });

  const [sendPayment] = useSendAdjustmentsPaymentMutation({
    variables: {
      orderId,
    },
  });

  if (loading && !data) {
    return <LoadingIcon />;
  }

  if (data) {
    const { producerPurchaseOrder: order } = data;

    if (order.__typename === "ProducerPurchaseOrder") {
      return (
        <ConfirmTransfer
          ledger={order.adjustmentPaymentsLedger}
          transferProposal={order.adjustmentsPaymentProposal}
          validationResult={order.canSendAdjustmentsPayment}
          label="Send adjustments payment"
          onSubmit={sendPayment}
        />
      );
    } else {
      throw Error(
        `Can use SendAdjustmentsPayment with ${order.__typename} order`
      );
    }
  }

  if (error) {
    return <div>Failed to load payment data.</div>;
  }

  return null;
}
