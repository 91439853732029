/* eslint-disable @typescript-eslint/ban-types */
import { Node } from "@react-types/shared";
import { ComboBoxProps } from "@react-types/combobox";
import React, {
  ElementType,
  Fragment,
  Key,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Item, Section } from "@react-stately/collections";
import { useComboBoxState } from "@react-stately/combobox";
import { useComboBox } from "@react-aria/combobox";
import { useListBox, useListBoxSection, useOption } from "@react-aria/listbox";
import { ListState } from "@react-stately/list";
import { navigate, useLocation } from "@reach/router";
import { Dialog, Transition } from "@headlessui/react";
import { trim } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import { ShoppingCartIcon, TruckIcon, UserIcon } from "@heroicons/react/solid";
import {
  FreightShipmentSearchResultFragment,
  LogisticsPartnerSearchResultFragment,
  ProducerPurchaseOrderSearchResultFragment,
  ProducerSearchResultFragment,
  RetailerSalesOrderSearchResultFragment,
  RetailerSearchResultFragment,
  useAdminGlobalSearchLazyQuery,
} from "@apollo/ops";
import {
  adminLogisticsPartnerPath,
  adminOrderPath,
  adminOrderShippingPath,
  adminProducerPath,
  adminRetailerPath,
} from "@routes";
import ctl from "@netlify/classnames-template-literals";
import {
  AdminGlobalSearchContext,
  useAdminGlobalSearch,
} from "./AdminGlobalSearchContext";

export { useAdminGlobalSearch };

type AdminGlobalSearchProps = {
  children: ReactNode;
};

export function AdminGlobalSearch({ children }: AdminGlobalSearchProps) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  // Bind hotkeys
  useHotkeys("ctrl+k, cmd+k", (e) => {
    /**
     * In Chrome, ctrl+k opens up the "Search Everywhere" feaure in the address
     * bar. This prevents it from opening and covering our search everywhere.
     */
    e.preventDefault();

    setOpen(true);
  });

  /**
   * Close when the page navigates. Since this hook doesn't trigger until
   * after the page has transitioned, we are opting to call the close function
   * explicitly so that the transition looks snappier.
   */
  useEffect(() => {
    setOpen(false);
  }, [setOpen, location]);

  const handleSelect = (path: string) => {
    setOpen(false);
    navigate(path);
  };

  const launch = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const contextValue = useMemo(
    () => ({
      launch,
    }),
    [launch]
  );

  return (
    <AdminGlobalSearchContext.Provider value={contextValue}>
      {children}

      <Transition.Root show={open} as={Fragment} unmount={true}>
        <Dialog
          as="div"
          className="fixed z-50 pt-16 flex items-start justify-center inset-0 tablet:pt-24"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
            enterTo="opacity-100 translate-y-0 tablet:scale-100"
          >
            <div className="relative transform transition-all max-w-2xl w-full px-4">
              <GlobalSearchBox onSelect={handleSelect} />
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </AdminGlobalSearchContext.Provider>
  );
}

type GlobalSearchProps = {
  onSelect: (path: string) => void;
};

export function GlobalSearchBox({ onSelect }: GlobalSearchProps) {
  const [selectionMade, setSelectionMade] = useState(false);
  const [input, setInput] = useState("");

  const [search, { data, previousData }] = useAdminGlobalSearchLazyQuery();

  const handleInputChange = (value: string) => {
    // Don't update the input if we've already made a selection
    if (selectionMade) {
      return;
    }
    setInput(value);
  };

  const handleSelectionChange = (key: Key) => {
    setSelectionMade(true);
    onSelect(key as string);
  };

  useEffect(() => {
    search({
      variables: {
        query: trim(input),
      },
    });
  }, [input, search, selectionMade]);

  const results = (data ?? previousData)?.globalSearch;

  /**
   * Transform the GraphQL result into a react-stately Collection compliant
   * data shape.
   *
   * @see https://react-spectrum.adobe.com/react-stately/collections.html#sections-1
   */
  const items = [
    {
      name: "Brewery Purchase Orders",
      items: results?.producerPurchaseOrders ?? [],
    },
    {
      name: "Retailer Sales Orders",
      items: results?.retailerSalesOrders ?? [],
    },
    {
      name: "Logistics Partners",
      items: results?.logisticsPartners ?? [],
    },
    {
      name: "Freight Shipments",
      items: results?.freightShipments ?? [],
    },
    {
      name: "Producers",
      items: results?.producers ?? [],
    },
    {
      name: "Retailers",
      items: results?.retailers ?? [],
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden p-4">
      <SearchComboBox
        label="Search"
        items={items}
        placeholder="Find anything..."
        inputValue={input}
        onInputChange={handleInputChange}
        onSelectionChange={handleSelectionChange}
      >
        {(section) => (
          <Section
            key={section.name}
            title={section.name}
            items={section.items}
          >
            {(item) => (
              <Item {...searchResultItemProps(item)}>
                {({ isFocused }: OptionRenderProps) =>
                  searchResultComponent({ result: item, isFocused })
                }
              </Item>
            )}
          </Section>
        )}
      </SearchComboBox>
      {results && results.totalResults === 0 && (
        <div className="p-16 text-center">
          <h2 className="text-gray-900 font-semibold mb-2">No results found</h2>
          <p className="text-sm">
            We didn&apos;t find anything, try searching something else.
          </p>
        </div>
      )}
    </div>
  );
}

type SearchResult =
  | ProducerPurchaseOrderSearchResultFragment
  | RetailerSalesOrderSearchResultFragment
  | FreightShipmentSearchResultFragment
  | ProducerSearchResultFragment
  | LogisticsPartnerSearchResultFragment
  | RetailerSearchResultFragment;

function searchResultItemProps(result: SearchResult) {
  switch (result.__typename) {
    case "ProducerPurchaseOrder":
      return {
        key: adminOrderPath(result.id),
        textValue: `Brewery PO #${result.number}`,
      };
    case "RetailerSalesOrder":
      return {
        key: adminOrderPath(result.id),
        textValue: `Retailer Sales order #${result.number}`,
      };
    case "LogisticsPartner":
      return {
        key: adminLogisticsPartnerPath(result.id),
        textValue: `Logistics Partner #${result.id}`,
      };
    case "TrackingInformation":
      return {
        key: adminOrderShippingPath(result.orderId),
        textValue: `Freight Shipment #${result.trackingNumber}`,
      };
    case "Producer":
      return {
        key: adminProducerPath(result.id),
        textValue: `Producer ${result.displayName}`,
      };
    case "Retailer":
      return {
        key: adminRetailerPath(result.id),
        textValue: `Retailer ${result.displayName}`,
      };
  }
}

type SearchResultComponentOptions = {
  result: SearchResult;
  isFocused: boolean;
};

function searchResultComponent({
  result,
  ...rest
}: SearchResultComponentOptions) {
  switch (result.__typename) {
    case "ProducerPurchaseOrder":
      return <ProducerPurchaseOrderResult result={result} {...rest} />;
    case "RetailerSalesOrder":
      return <RetailerSalesOrderResult result={result} {...rest} />;
    case "LogisticsPartner":
      return <LogisticsPartnersResult result={result} {...rest} />;
    case "TrackingInformation":
      return <FreightShipmentResult result={result} {...rest} />;
    case "Producer":
      return <ProducerResult result={result} {...rest} />;
    case "Retailer":
      return <RetailerResult result={result} {...rest} />;
  }
}

type ResultProps = {
  icon: ElementType;
  isFocused?: boolean;
  children: ReactNode;
};

function Result({ icon: Icon, children, isFocused = false }: ResultProps) {
  return (
    <div className="flex items-center">
      <div className="mr-4">
        <Icon className={resultIconClasses({ isFocused })} />
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
}

type ProducerPurchaseOrderResultProps = OptionRenderProps & {
  result: ProducerPurchaseOrderSearchResultFragment;
};

function ProducerPurchaseOrderResult({
  result: order,
  isFocused,
}: ProducerPurchaseOrderResultProps) {
  return (
    <Result icon={ShoppingCartIcon} isFocused={isFocused}>
      <div aria-label={order.number}>{order.number}</div>
      <div className="flex text-xs space-x-4">
        <div>{order.seller.displayName}</div>
        <div>{order.regionV2.friendlyName}</div>
      </div>
    </Result>
  );
}

type RetailerSalesOrderrResultProps = OptionRenderProps & {
  result: RetailerSalesOrderSearchResultFragment;
};

function RetailerSalesOrderResult({
  result: order,
  isFocused,
}: RetailerSalesOrderrResultProps) {
  return (
    <Result icon={ShoppingCartIcon} isFocused={isFocused}>
      <div aria-label={order.number}>{order.number}</div>
      <div className="flex text-xs space-x-4">
        <div className="flex-none">
          {order.producerPurchaseOrder.seller.displayName}
        </div>
        <div className="flex-none">
          {order.producerPurchaseOrder.regionV2.friendlyName}
        </div>
        <div className="truncate">{order.buyer.displayName}</div>
      </div>
    </Result>
  );
}

type LogisticsPartnerResultProps = OptionRenderProps & {
  result: LogisticsPartnerSearchResultFragment;
};

function LogisticsPartnersResult({
  result: logisticsPartner,
  isFocused,
}: LogisticsPartnerResultProps) {
  return (
    <Result icon={TruckIcon} isFocused={isFocused}>
      <div aria-label={logisticsPartner.id.toString()}>
        {logisticsPartner.id}
      </div>
      <div className="flex text-xs space-x-4">
        <div className="flex-none">{logisticsPartner.displayName}</div>
      </div>
    </Result>
  );
}

type FreightShipmentResultProps = OptionRenderProps & {
  result: FreightShipmentSearchResultFragment;
};

function FreightShipmentResult({
  result: shipment,
  isFocused,
}: FreightShipmentResultProps) {
  return (
    <Result icon={TruckIcon} isFocused={isFocused}>
      <div aria-label={shipment.trackingNumber || "No tracking number"}>
        {shipment.trackingNumber}
      </div>
    </Result>
  );
}

type ProducerResultProps = OptionRenderProps & {
  result: ProducerSearchResultFragment;
};

function ProducerResult({ result: producer, isFocused }: ProducerResultProps) {
  return (
    <Result icon={UserIcon} isFocused={isFocused}>
      <div aria-label={producer.displayName}>{producer.displayName}</div>
      <div className="flex text-xs space-x-4">
        <div className="flex-none">
          {producer.address?.city}, {producer.address?.state}
        </div>
      </div>
    </Result>
  );
}

type RetailerResultProps = OptionRenderProps & {
  result: RetailerSearchResultFragment;
};

function RetailerResult({ result: retailer, isFocused }: RetailerResultProps) {
  return (
    <Result icon={UserIcon} isFocused={isFocused}>
      <div aria-label={retailer.displayName}>{retailer.displayName}</div>
      <div className="flex text-xs space-x-4">
        <div className="flex-none">
          {retailer.address?.city}, {retailer.address?.state}
        </div>
      </div>
    </Result>
  );
}

type SearchSection = {
  name: string;
  items: Array<SearchResult>;
};

function SearchComboBox(props: ComboBoxProps<SearchSection>) {
  const state = useComboBoxState({
    ...props,

    /**
     * Since we always show to listbox menu, menuTrigger is set to focus so
     * the combo box state changes to open immediately.
     */
    menuTrigger: "focus",
  });

  // Setup refs and get props for child elements.
  const inputRef = React.useRef<HTMLInputElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const listBoxRef = React.useRef<HTMLUListElement>(null);

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      popoverRef,
      inputRef,
      listBoxRef,
    },
    state
  );

  const { listBoxProps: useListBoxProps } = useListBox(
    { ...listBoxProps, autoFocus: "first" },
    state,
    listBoxRef
  );

  return (
    <div>
      <label {...labelProps} className="sr-only">
        {props.label}
      </label>
      <div>
        <form>
          <input
            {...inputProps}
            ref={inputRef}
            type="text"
            className="w-full border-b outline-none bg-transparent placeholder-opacity-60	 text-gray-600 text-base tablet:text-sm placeholder-gray-500 focus:outline-none focus:ring-0 border-0 focus:border-brand-600"
          />
        </form>
        <div ref={popoverRef}>
          <ul
            {...useListBoxProps}
            ref={listBoxRef}
            className="mt-5 max-h-96 overflow-y-auto space-y-5"
          >
            {[...state.collection].map((item) => (
              <ResultsListBoxSection
                key={item.key}
                section={item}
                state={state}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

type ResultsListBoxSectionProps = {
  section: Node<SearchSection>;
  state: ListState<SearchSection>;
};

function ResultsListBoxSection({ section, state }: ResultsListBoxSectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });

  const childNodes = [...section.childNodes];

  if (childNodes.length === 0) {
    return null;
  }

  return (
    <li {...itemProps}>
      {section.rendered && (
        <div
          {...headingProps}
          className="text-sm text-gray-500 font-semibold mb-3"
        >
          {section.rendered}
        </div>
      )}
      <ul {...groupProps} className="space-y-2">
        {[...section.childNodes].map((node) => (
          <Option key={node.key} item={node} state={state} />
        ))}
      </ul>
    </li>
  );
}

type OptionProps = {
  item: Node<SearchSection>;
  state: ListState<SearchSection>;
};

type OptionRenderProps = {
  isFocused: boolean;
};

/**
 * @remarks
 * Note the usage of calling item.rendered, allowing us to pass a render
 * function
 */
function Option({ item, state }: OptionProps) {
  // Get props for the option element
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isSelected, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  );

  return (
    <li
      {...optionProps}
      ref={ref}
      className={optionClasses({ isSelected, isFocused })}
    >
      {typeof item.rendered === "function" && item.rendered({ isFocused })}
    </li>
  );
}

type OptionClassesOptions = {
  isSelected: boolean;
  isFocused: boolean;
};

const optionClasses = ({ isFocused }: OptionClassesOptions) =>
  ctl(`
    flex
    items-center
    group
    px-4
    py-3
    outline-none
    cursor-pointer
    w-full
    ${isFocused ? "bg-brand-600 text-white" : "bg-gray-50 text-gray-600"}
  `);

type ResultIconClassesOptions = {
  isFocused: boolean;
};

const resultIconClasses = ({ isFocused }: ResultIconClassesOptions) =>
  ctl(`
      w-5
      h-5
      ${isFocused ? "text-white" : "text-gray-400"}
    `);
