import React from "react";
import {
  AdminUpdateLicenseMutation,
  useAdminUpdateLicenseMutation,
  useAdminLicenseQuery,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { LicenseForm, LicenseFormData } from "../LicenseForm";
import { useErrorHandler } from "react-error-boundary";
import { LoadingIcon } from "@components/ui/Spinner";

type UpdateLicenseProps = {
  onUpdate?: (data: AdminUpdateLicenseMutation) => void;
  licenseId: number;
};

export function UpdateLicense({ licenseId, onUpdate }: UpdateLicenseProps) {
  const [updateLicenseAndCurrentPeriod] = useAdminUpdateLicenseMutation();

  const { data, error, loading } = useAdminLicenseQuery({
    variables: {
      licenseId,
    },
  });

  let defaultValues;

  if (data?.license) {
    /*
     * Dates are coming back out strings from the API, so we lift them
     * into Date objects before sending as default values
     */
    defaultValues = {
      ...data.license,
      stateV2Id: data.license.stateV2.id,
      startDate: new Date(data.license.currentLicensePeriod.startDate),
      endDate: new Date(data.license.currentLicensePeriod.endDate),
    };
  }

  useErrorHandler(error);

  const handleSubmit = async ({
    title,
    number,
    stateV2Id,
    documentUrl,
    startDate,
    endDate,
  }: LicenseFormData) => {
    try {
      const result = await updateLicenseAndCurrentPeriod({
        variables: {
          licenseId,
          data: {
            title,
            number,
            stateV2Id,
            documentUrl,
            startDate,
            endDate,
          },
        },
      });

      if (result.data && onUpdate) {
        onUpdate(result.data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Updated license ${title}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  if (loading) return <LoadingIcon />;

  return (
    <LicenseForm
      submitText="Save"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    />
  );
}
