import React from "react";
import { Button } from "../../ui/Button";
import { useDialog } from "@hooks/useDialog";
import { UpdateRetailerDialog } from "./UpdateRetailerDialog";

type UpdateRetailerModalProps = {
  retailerId: number;
};

export function UpdateRetailerModal({ retailerId }: UpdateRetailerModalProps) {
  const updateRetailerDialog = useDialog();

  return (
    <>
      <Button onClick={updateRetailerDialog.show}>Edit</Button>

      <UpdateRetailerDialog
        retailerId={retailerId}
        dialogProps={updateRetailerDialog.getDialogProps()}
      />
    </>
  );
}
