import CreateDwollaCustomerView from "./CreateDwollaCustomerView";
import useCreateDwollaCustomerEnhancer, {
  CreateDwollaCustomerEnhancerOptions,
} from "./CreateDwollaCustomerEnhancer";
import withHookUntyped from "@hoc/withHook";
import { compose } from "lodash/fp";
import React from "react";
type CreateDwollaCustomerProps = CreateDwollaCustomerEnhancerOptions;

export const CreateDwollaCustomer: React.ComponentType<CreateDwollaCustomerProps> = compose(
  withHookUntyped(useCreateDwollaCustomerEnhancer)
)(CreateDwollaCustomerView);
