import React from "react";
import { Button } from "@ui/Button";
import { useAdminDeleteDistributorMutation } from "@apollo/ops";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { useNavigate } from "@reach/router";
import { adminDistributorsPath } from "@routes";

type DeleteDistributorButtonProps = {
  distributorId: number;
};

export function DeleteDistributorButton({
  distributorId,
}: DeleteDistributorButtonProps) {
  const [deleteDistributor] = useAdminDeleteDistributorMutation();
  const dialog = useDialog();
  const navigate = useNavigate();

  const onConfirm = async () => {
    try {
      const result = await deleteDistributor({
        variables: {
          id: distributorId,
        },
      });

      if (result.data) {
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Successfully deleted ${result.data.deleteDistributor.name}`,
        });
        navigate(adminDistributorsPath);
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <>
      <Button destructive kind="secondary" onClick={dialog.open} size="sm">
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this distributor?"
        description="WARNING: Deleting this distributor is non-reversible"
        onConfirm={onConfirm}
        confirmText="Yes, delete this distributor"
        dialog={dialog}
      />
    </>
  );
}
