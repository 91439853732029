import React from "react";
import { Spinner } from "@shared/components/Spinner";
import { useLoadDwollaLib } from "@hooks/useLoadDwollaLib";
import { handleDwollaDropInError } from "../../../errors/DwollaDropInErrors";

export type CertifyBeneficialOwnerSuccessResult = {
  status: "certified" | "recertify" | "uncertified";
  _links: {
    self: {
      href: string;
      "resource-type": string;
      type: string;
    };
  };
};

export interface CreateBeneficialOwnersProps {
  dwollaCustomerId: string;
  onSuccess: (result: CertifyBeneficialOwnerSuccessResult) => Promise<void>;
}

export const CreateBeneficialOwners = ({
  dwollaCustomerId,
  onSuccess,
}: CreateBeneficialOwnersProps) => {
  const handleDwollaConfigError = (e: any) => {
    handleDwollaDropInError({ error: e });
  };

  const { ready, error } = useLoadDwollaLib({
    success: onSuccess,
    error: handleDwollaConfigError,
  });

  if (error) {
    throw error;
  }

  if (!ready && !error) {
    return <Spinner />;
  }

  return <dwolla-beneficial-owners customerId={dwollaCustomerId} />;
};
