import { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { WelcomeView } from "./WelcomeView";
import { compose } from "lodash/fp";
import { withHook } from "@hoc/withHook";
import {
  useWelcomeEnhancer,
  WelcomeEnhancerProps,
  WelcomeEnhancerResult,
} from "./WelcomeEnhancer";
import { WelcomeViewProps } from "./WelcomeView";
type WelcomeProps = Omit<
  WelcomeViewProps & WelcomeEnhancerProps,
  keyof WelcomeEnhancerResult
>;
export const Welcome: FC<WelcomeProps> = compose(
  withHook<WelcomeEnhancerProps, WelcomeEnhancerResult>({
    useHook: useWelcomeEnhancer,
  }),
  withErrorBoundary
)(WelcomeView);
