import React from "react";
import { formatAmountInCents } from "@utilities/currency";
import { TableSummary } from "@ui/TableSummary";
import { AdminPreSaleOrderItemsQuery } from "@apollo/gen/generatedOperations";

type AdminOrderItemsTotalsProps = {
  order: AdminPreSaleOrderItemsQuery["order"];
};

export function AdminPreSaleOrderItemsTotals({
  order,
}: AdminOrderItemsTotalsProps) {
  const orderTotals: Record<string, string> = {
    "Order Item Total": formatAmountInCents(order.amountInCents),
    "Final Order Total": formatAmountInCents(order.finalOrderTotalInCents),
  };

  return <TableSummary rows={orderTotals} />;
}
