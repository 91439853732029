import React from "react";
import { AdminPackagesQuery, AdminPackagesDocument } from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { RouteComponentProps } from "@reach/router";
import { useErrorHandler } from "react-error-boundary";
import { LoadingIcon } from "@components/ui/Spinner";
import { PackageTypesTable } from "@components/PackageType/PackageTypesTable";
import { AddPackageTypeModal } from "@components/PackageType/AddPackageType/AddPackageTypeModal";
import { Button } from "@components/ui/Button";

type WrappedProps = WithQueryComponentProps<
  RouteComponentProps,
  AdminPackagesQuery
>;

function Packages({ query }: WrappedProps) {
  const { data: results, loading, error } = query;

  useErrorHandler(error);

  const data = results.productPackageTypes ? results.productPackageTypes : [];

  return (
    <Page
      heading={
        <PageHeading
          title="Package Types"
          actions={
            <AddPackageTypeModal
              onAdd={() => query.refetch()}
              render={({ open }) => (
                <Button onClick={open}>Add a Package Type</Button>
              )}
            />
          }
        />
      }
    >
      <div className="mt-10">
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="mt-8 card">
            {data.length === 0 ? (
              <>
                <p>No Package Types</p>
                <br />
              </>
            ) : null}

            {data.length > 0 && (
              <PackageTypesTable
                packageTypes={data}
                onDelete={() => query.refetch()}
              />
            )}
          </div>
        )}
      </div>
    </Page>
  );
}

export const AdminPackagesPage = withQuery<
  RouteComponentProps,
  AdminPackagesQuery
>(AdminPackagesDocument)(Packages);
