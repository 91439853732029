import { useStoresPageQuery } from "@apollo/ops";
import { LoadingPage } from "@components/LoadingPage";
import { AddStoreDialog } from "@components/Store/AddStoreDialog";
import { Button } from "@components/ui/Button";
import { Page, PageHeading } from "@components/ui/Page";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link, RouteComponentProps } from "@reach/router";
import React from "react";
import { useRetailerApp } from "../RetailerAppProvider";

type StoresPageProps = RouteComponentProps;

export function StoresPage(_: StoresPageProps) {
  const { organizationId, organizationPath } = useRetailerApp();
  const { data, loading, error } = useStoresPageQuery({
    variables: {
      organizationId: organizationId,
    },
  });

  if (data) {
    return (
      <Page
        heading={
          <PageHeading
            title="Stores"
            actions={
              <AddStoreDialog
                organizationId={organizationId}
                render={({ open }) => (
                  <Button onClick={open}>Add another store</Button>
                )}
              />
            }
          />
        }
      >
        <div className="mb-5 text-gray-700">
          <p>
            Each store represents a unique retail location that can place orders
            and receive beer.
          </p>
          <p>
            Click on a store to view orders, manage payment information, and
            more.
          </p>
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {data.organization.locations.map((location) => (
              <li key={location.id}>
                <Link
                  to={organizationPath(`stores/${location.id}`)}
                  className="block hover:bg-gray-50"
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex-1">
                      <div className="font-bold">{location.name}</div>
                      <div className="text-gray-500 text-sm">
                        {location.regionV2.friendlyName}
                      </div>
                    </div>
                    <div className="flex items-center text-gray-400">
                      <div className="mr-3 hidden tablet:block">
                        manage store
                      </div>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Page>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  throw error;
}
