import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
export const Spinner = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <ProgressSpinner />
  </div>
);
