import React from "react";
import { ErrorAlert } from "@ui";
import { formatAsDate } from "@utilities/formatDate";

type CancelledAlertProps = {
  cancelledAt: Date;
};

export function CancelledAlert({ cancelledAt }: CancelledAlertProps) {
  return (
    <ErrorAlert title={`Order cancelled on ${formatAsDate(cancelledAt)}`} />
  );
}
