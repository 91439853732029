import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  AdminProducerLicensesQuery,
  AdminProducerLicensesDocument,
  AdminProducerLicensesQueryVariables,
} from "@apollo/ops";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { LicenseTable } from "@components/License/LicenseTableView";
import { LoadingIcon } from "@components/ui/Spinner";
import { useErrorHandler } from "react-error-boundary";
import { PageHeading } from "@components/ui/Page";
import { AddLicenseModal } from "@components/License/AddLicense/AddLicenseModal";

interface AdminProducerLicensesProps extends RouteComponentProps {
  parsedProducerId: number;
}

type WrappedProps = WithQueryComponentProps<
  AdminProducerLicensesProps,
  AdminProducerLicensesQuery,
  AdminProducerLicensesQueryVariables
>;

export function AdminProducerLicensesWithQuery({ query }: WrappedProps) {
  const { data: results, loading, error } = query;

  useErrorHandler(error);

  const data = results?.producer?.licenses ? results.producer.licenses : [];
  const producerId = results.producer.id;

  return (
    <div className="mt-8">
      <PageHeading
        title="Licenses"
        actions={
          <AddLicenseModal
            onAdd={() => query.refetch()}
            producerId={producerId}
          />
        }
      />

      <div className="mt-10">
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="mt-8 card">
            {data.length === 0 ? (
              <>
                <p>No Associated Licenses</p>
                <br />
              </>
            ) : null}

            {data.length > 0 && (
              <LicenseTable
                licenses={data}
                onUpdate={() => query.refetch()}
                onDelete={() => query.refetch()}
                onRenew={() => query.refetch()}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const AdminProducerLicenses = withQuery<
  AdminProducerLicensesProps,
  AdminProducerLicensesQuery,
  AdminProducerLicensesQueryVariables
>(AdminProducerLicensesDocument, {
  mapPropsToOptions: (props) => ({
    variables: { producerId: props.parsedProducerId },
  }),
})(AdminProducerLicensesWithQuery);
