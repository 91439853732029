import React from "react";
import { Dialog, UseDialogResult } from "@ui/Dialog";
import {
  AdminOfferWindowOfferFragment,
  UpdateOfferMutation,
  useUpdateOfferMutation,
} from "@apollo/ops";
import { defaultErrorHandler } from "@utilities/toast";
import { UpdateOfferForm, UpdateOfferSubmitData } from "./UpdateOfferForm";

type UpdateOfferDialogProps = {
  dialog: UseDialogResult;
  offer: AdminOfferWindowOfferFragment;
  onUpdate?: (data: UpdateOfferMutation) => void;
};

export function UpdateOfferDialog({
  dialog,
  offer,
  onUpdate,
}: UpdateOfferDialogProps) {
  const { getDialogProps } = dialog;
  const [updateOffer] = useUpdateOfferMutation();

  const handleSubmit = async (data: UpdateOfferSubmitData) => {
    try {
      const result = await updateOffer({
        variables: {
          id: offer.id,
          data,
        },
      });

      if (result.data) {
        dialog.close();
        onUpdate && onUpdate(result.data);
      }
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <Dialog
      {...getDialogProps()}
      title={`Update ${offer.product.name} offer`}
      width="xl"
    >
      <UpdateOfferForm key={offer.id} onSubmit={handleSubmit} offer={offer} />
    </Dialog>
  );
}
