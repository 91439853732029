import { useProducerDashboardDataQuery } from "@apollo/gen/generatedOperations";
import { RouteComponentProps } from "@reach/router";
import { LoadingIcon } from "@ui/Spinner";
import React from "react";
import { ProducerSalesByRegion } from "./ProducerSalesByRegion";
import { ProducerPreSaleUnitsSold } from "./ProducerPreSaleUnitsSold";
import { ProducerSalesOrderUnitsFulfilled } from "./ProducerSalesOrderUnitsFulfilled/ProducerSalesOrderUnitsFulfilled";
import { Page, PageHeading } from "@ui/Page";

export function ProducerDirectBeerDashboard({
  producerId,
}: RouteComponentProps & { producerId: number }) {
  const { data, error } = useProducerDashboardDataQuery({
    variables: {
      producerId,
    },
  });

  if (error) throw error;

  return data ? (
    <Page heading={<PageHeading title={"Brewery Dashboard - Direct Beer"} />}>
      <div className="grid grid-cols-1 laptop:grid-cols-2 gap-2 laptop:gap-4 mb-8">
        <div className="h-80 tablet:h-auto laptop:h-[420px]">
          <ProducerPreSaleUnitsSold producerId={producerId} />
        </div>
        <div className="h-80 tablet:h-auto laptop:h-[420px]">
          <ProducerSalesOrderUnitsFulfilled producerId={producerId} />
        </div>
      </div>
      <div>
        <h3>Sales by Region</h3>
        <ProducerSalesByRegion producerId={producerId} />
      </div>
    </Page>
  ) : (
    <LoadingIcon />
  );
}
