
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Business": [
      "BeverageAdvisors",
      "Distributor",
      "LogisticsPartner",
      "Producer",
      "Retailer"
    ],
    "CloudinaryImage": [
      "ProductImage"
    ],
    "FulfillmentOrder": [
      "DistributorPurchaseOrder",
      "ProducerPurchaseOrder",
      "RetailerSalesOrder"
    ],
    "FundsAccount": [
      "BalanceAccount",
      "BankAccount",
      "ExternalFundsAccount"
    ],
    "OnboardingStep": [
      "BankAccountOnboardingStep",
      "BeneficialOwnerOnboardingStep",
      "CompleteOnboardingStep",
      "DwollaOnboardingStep"
    ],
    "Order": [
      "DistributorPurchaseOrder",
      "PreSaleOrder",
      "ProducerPurchaseOrder",
      "RetailerSalesOrder"
    ]
  }
};
      export default result;
    