import React, { useEffect, useMemo } from "react";
import { Button } from "../../../components/ui/Button";
import { Centered } from "../../../components/Centered";
import { ROWS_IN_DATA_TABLES } from "../../../constants/ViewConstants";
import { Tooltip } from "primereact/tooltip";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import {
  AdminFundsTransferFieldsFragment,
  FundsAccountType,
  FundsTransferAccountFragment,
  FundsTransferStatus,
  useSyncFundsTransferMutation,
} from "@apollo/ops";
import { useAuth } from "@shared/auth";
import { formatAmountInCents } from "@utilities/currency";
import { RefreshIcon } from "@heroicons/react/outline";
import { toast } from "@utilities/toast";
import classnames from "classnames";
import { Table } from "@ui/Table";
import { Column, useTable } from "react-table";

export interface TransactionTableProps {
  transactions: AdminFundsTransferFieldsFragment[];
  offset: number;
  setOffset: (offset: number) => void;
  retailer?: boolean;
  admin?: boolean;
}

export function AdminTransactionsTableBase({
  transactions,
  offset,
  setOffset,
  retailer = false,
  admin = false,
}: TransactionTableProps) {
  const { user } = useAuth();
  const [syncFundsTransaction, { loading, error }] =
    useSyncFundsTransferMutation();
  useEffect(() => {
    if (error) {
      toast.current?.show({
        severity: "error",
        summary:
          "An error ocurred while syncing transfer with Dwolla. Please contact a developer.",
      });
    }
  }, [error]);

  const businessEntityIds =
    user?.organization?.businesses.map((business) => business.id) || [];

  const accountBelongsToUser = (fundAccount: FundsTransferAccountFragment) => {
    return businessEntityIds.includes(fundAccount.business.id);
  };

  const formatDate = (rowData: AdminFundsTransferFieldsFragment) => {
    const date = new Date(rowData.createdAt);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const renderActions = (rowData: AdminFundsTransferFieldsFragment) => {
    return (
      <>
        <Tooltip target=".refreshIcon" />
        <span
          className="refreshIcon"
          data-pr-tooltip="Sync with Dwolla"
          data-pr-position="left"
        >
          <RefreshIcon
            className={classnames({ link: !loading })}
            width={30}
            onClick={() =>
              syncFundsTransaction({
                variables: { fundsTransferId: rowData.id },
              })
            }
          />
        </span>
      </>
    );
  };

  const formatAmount = (rowData: AdminFundsTransferFieldsFragment) => {
    const userOwnsSource = accountBelongsToUser(rowData.source);
    const userOwnsDestination = accountBelongsToUser(rowData.destination);

    let directionCharacter = "";
    if (userOwnsSource && !userOwnsDestination) {
      directionCharacter = "-";
    }

    if (!userOwnsSource && userOwnsDestination) {
      directionCharacter = "+";
    }

    return `${directionCharacter}${formatAmountInCents(rowData.amount)}`;
  };

  const formatFundName = (
    rowData: AdminFundsTransferFieldsFragment,
    props: { header: string; field: string }
  ) => {
    const fund =
      props.field === "source" ? rowData.source : rowData.destination;

    if (!accountBelongsToUser(fund) && !admin) {
      return fund.business.displayName;
    }

    const accountDesignation =
      retailer || admin ? `${fund.business.displayName} -` : "Your";
    const accountType =
      fund.type === FundsAccountType.Balance
        ? "Balance"
        : FundsAccountType.External
        ? "External"
        : "Bank";

    return `${accountDesignation} ${accountType}`;
  };

  const renderStatus = (rowModel: AdminFundsTransferFieldsFragment) => {
    const isTransactionInError = [
      FundsTransferStatus.Failed,
      FundsTransferStatus.Rejected,
    ].includes(rowModel.status);

    const constructErrorMsgFromDwolla = (
      rowModel: AdminFundsTransferFieldsFragment
    ) => {
      if (rowModel.status === FundsTransferStatus.Rejected) {
        return rowModel.settlementRejectionResponse
          ? JSON.stringify(rowModel.settlementRejectionResponse)
          : "No error msg provided";
      }
      return rowModel.dwollaTransfers
        .map((dwollaTransfer) => {
          if (dwollaTransfer.failureCode) {
            return `Error code (${dwollaTransfer.failureCode}): ${dwollaTransfer.failureExplanation} - "${dwollaTransfer.failureDescription}"`;
          }
          return "";
        })
        .join(", ");
    };
    let errorMsg = "";
    if (isTransactionInError) {
      errorMsg = constructErrorMsgFromDwolla(rowModel);
    }

    return (
      <>
        <Tooltip target=".transfer-status" />
        <span
          className={classnames({
            "transfer-in-error": isTransactionInError,
            link: isTransactionInError,
            "transfer-status": true,
          })}
          data-pr-tooltip={errorMsg}
          data-pr-position="left"
        >
          {rowModel.status}
        </span>
      </>
    );
  };
  const columns: Array<Column<AdminFundsTransferFieldsFragment>> = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row }) => formatDate(row.original),
      },
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Brewery Invoice #",
        accessor: "breweryInvoiceNumber",
      },
      {
        Header: "Retailer Invoice #",
        accessor: "retailerInvoiceNumber",
      },
      {
        Header: "Amount ($)",
        accessor: "amount",
        Cell: ({ row }) => formatAmount(row.original),
      },
      {
        Header: "To",
        accessor: "destination",
        Cell: ({ row }) =>
          formatFundName(row.original, { field: "destination", header: "To" }),
      },

      {
        Header: "From",
        accessor: "source",
        Cell: ({ row }) =>
          formatFundName(row.original, { field: "source", header: "To" }),
      },
      {
        Header: "Statement Descriptor",
        accessor: "statementDescriptor",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => renderStatus(row.original),
      },
      {
        Header: " ",
        Cell: ({
          row,
        }: {
          row: { original: AdminFundsTransferFieldsFragment };
        }) => renderActions(row.original),
      },
    ],
    [loading]
  );
  const table = useTable({ columns, data: transactions });

  return (
    <div>
      {transactions && transactions.length >= 0 && <Table instance={table} />}
      <Centered>
        <div className="m-4">
          <Button
            type="button"
            className={offset === 0 ? "invisible" : ""}
            onClick={() => setOffset(offset - 1)}
            size="sm"
            kind="secondary"
          >
            Previous
          </Button>
          <span className="m-3">{offset + 1}</span>
          <Button
            type="button"
            className={
              transactions.length < ROWS_IN_DATA_TABLES ? "invisible" : ""
            }
            onClick={() => setOffset(offset + 1)}
            size="sm"
            kind="secondary"
          >
            Next
          </Button>
        </div>
      </Centered>
    </div>
  );
}

const AdminTransactionsTable = withErrorBoundary(AdminTransactionsTableBase);
export { AdminTransactionsTable };
