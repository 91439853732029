import React, { FC } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { AdminOrderRouter } from "./AdminOrderRouter/AdminOrderRouter";

const OrdersContainer: FC<RouteComponentProps> = () => {
  return (
    <Router>
      <Redirect from="/" to="/admin/purchase-orders" />
      <AdminOrderRouter path=":orderIdParam/*" />
    </Router>
  );
};

export default OrdersContainer;
