import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import ctl from "@netlify/classnames-template-literals";
import { XIcon } from "@heroicons/react/outline";

type UseDialogOptions = {
  isOpenDefault?: boolean;
};

export type UseDialogResult = {
  open: () => void;
  close: () => void;
  getDialogProps: () => {
    isOpen: boolean;
    onClose: () => void;
  };
};

export function useDialog(userOptions?: UseDialogOptions) {
  const options = {
    isOpenDefault: false,
    ...userOptions,
  };

  const [isOpen, setIsOpen] = useState(options.isOpenDefault);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const getDialogProps = useCallback(
    () => ({
      isOpen,
      onClose: close,
    }),
    [isOpen, close]
  );

  return useMemo(
    () => ({
      open,
      close,
      getDialogProps,
    }),
    [open, close, getDialogProps]
  );
}

type DialogWidth = "sm" | "md" | "lg" | "xl" | "2xl";

export type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  width?: DialogWidth;
  title?: string;
  description?: string;
  noBody?: boolean;
  children: ReactNode;
};

export function Dialog({
  children,
  isOpen,
  onClose,
  title,
  description,
  width = "md",
  noBody = false,
}: DialogProps) {
  const MaybeBody = noBody ? React.Fragment : Dialog.Body;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HeadlessDialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center tablet:block tablet:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the dialog contents. */}
          <span
            className="hidden tablet:inline-block tablet:align-middle tablet:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
            enterTo="opacity-100 translate-y-0 tablet:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
            leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          >
            <div className={dialogContentClasses({ width })}>
              <MaybeBody>
                <div className="hidden tablet:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {(title || description) && (
                  <div className="mb-4">
                    {title && <Dialog.Title>{title}</Dialog.Title>}
                    {description && (
                      <Dialog.Description>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{description}</p>
                        </div>
                      </Dialog.Description>
                    )}
                  </div>
                )}
                {children}
              </MaybeBody>
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
}

/**
 * These should be used for accessiblity reasons.
 *
 * <Dialog.Title>Your Title Here</Dialog.Title>
 *
 * See https://headlessui.dev/react/dialog#using-the-title-and-description-components
 */
type DialogTitleProps = {
  children: ReactNode;
  className?: string;
};

function DialogTitle({
  children,
  className = "text-lg leading-6 font-medium text-gray-900",
}: DialogTitleProps) {
  return (
    <HeadlessDialog.Title as="h3" className={className}>
      {children}
    </HeadlessDialog.Title>
  );
}

Dialog.Title = DialogTitle;
Dialog.Description = HeadlessDialog.Description;

type DialogBodyProps = {
  children: ReactNode;
};

function DialogBody({ children }: DialogBodyProps) {
  return <div className="px-4 pt-5 pb-4 tablet:p-6">{children}</div>;
}

Dialog.Body = DialogBody;

type DialogContentClassesOptions = {
  width: DialogWidth;
};

function dialogContentClasses({ width }: DialogContentClassesOptions) {
  return ctl(`
    inline-block
    align-bottom
    bg-white
    rounded-lg
    text-left
    overflow-visible
    shadow-xl
    transform
    transition-all
    tablet:my-8
    tablet:align-middle
    tablet:w-full
    ${widthClasses[width]}
  `);
}

const widthClasses = {
  sm: "tablet:max-w-sm",
  md: "tablet:max-w-md",
  lg: "tablet:max-w-lg",
  xl: "tablet:max-w-xl",
  "2xl": "tablet:max-w-2xl",
};
