import React from "react";
import { RouteComponentProps } from "@reach/router";
import Prismic from "@prismicio/client";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { useEffect, useState } from "react";
import Client from "../prismic-config";
import { SimplePage, PageHeading } from "@components/ui/Page";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "../shared/types/Severity";

export function FAQPage(_: RouteComponentProps) {
  const [FAQ, setFAQ] = useState<RichTextBlock[]>([]);
  const [FAQTitle, setFAQTitle] = useState("");

  useEffect(() => {
    const fetchFAQ = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "directbeerfaq")
      );

      if (response?.results[0]?.data) {
        const { faq_title, faq_content } = response.results[0].data;
        setFAQTitle(faq_title[0].text);
        setFAQ(faq_content);
      } else {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "Oh no!",
          detail: "We are unable to retrieve our FAQ. Check back later.",
        });
      }
    };

    fetchFAQ();
  }, []);

  return (
    <SimplePage heading={<PageHeading title={FAQTitle} />}>
      <RichText render={FAQ} />
    </SimplePage>
  );
}
