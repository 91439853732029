import React from "react";
import { RouteComponentProps } from "@reach/router";
import { NumberParam, useQueryParams } from "use-query-params";
import {
  PaymentAgingReportQuery,
  PaymentAgingReportDocument,
  PaymentAgingReportQueryVariables,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import {
  PaymentAgingReportFilters,
  FilterData,
} from "./PaymentAgingReportFilters";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { PaymentAgingReportTable } from "./PaymentAgingReportTable";

export function PaymentAgingReportPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    regionId: NumberParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    PaymentAgingReportQuery,
    PaymentAgingReportQueryVariables
  >({
    options: {
      fetchPolicy: "cache-and-network",
      variables: {
        filters,
      },
    },
    paginationOptions: {
      pageSize: 50,
    },
    query: PaymentAgingReportDocument,
    mapDataToTotal({ paymentAgingReport }) {
      return paymentAgingReport.total;
    },
  });

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  const { data: currentData, previousData } = queryResult;
  const data = currentData ?? previousData;

  const breadcrumbProps = {
    items: buildBreadcrumbs("Brewery Purchase Orders"),
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Payment Aging Report"
          breadcrumbProps={breadcrumbProps}
        />
      }
    >
      <div className="mb-3">
        <PaymentAgingReportFilters
          onFilter={updateFilters}
          defaultValues={filters}
        />
      </div>
      {data && (
        <PaymentAgingReportTable
          data={data.paymentAgingReport.results}
          pagination={pagination}
        />
      )}
    </Page>
  );
}
