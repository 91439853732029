import React from "react";
import { AdminAddFirstRetailerLocationMutation } from "@apollo/ops";
import { Button } from "@ui/Button";
import { useDialog } from "@hooks/useDialog";
import { AddRetailerDialog } from "./AddRetailerDialog";

type AddRetailerModalProps = {
  onAdd?: (data: AdminAddFirstRetailerLocationMutation) => void;
};

export function AddRetailerModal({ onAdd }: AddRetailerModalProps) {
  const addRetailerDialog = useDialog();

  return (
    <>
      <Button onClick={addRetailerDialog.show} size="sm">
        Add Retailer
      </Button>
      <AddRetailerDialog
        onAdd={onAdd}
        dialogProps={addRetailerDialog.getDialogProps()}
      />
    </>
  );
}
