import React from "react";
import {
  AdminLogisticsPartnersDocument,
  useAdminAddOrganizationAndLogisticsPartnerMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { defaultErrorHandler, toast } from "@utilities/toast";
import {
  LogisticsPartnerForm,
  LogisticsPartnerFormData,
} from "./LogisticsPartnerForm";

type AddLogisticsPartnerProps = {
  onAdd?: () => void;
};

export function AddLogisticsPartner({ onAdd }: AddLogisticsPartnerProps) {
  const [
    addLogisticsPartner,
  ] = useAdminAddOrganizationAndLogisticsPartnerMutation({
    refetchQueries: [
      {
        query: AdminLogisticsPartnersDocument,
      },
    ],
    onError: defaultErrorHandler,
  });

  const handleSubmit = async ({ name }: LogisticsPartnerFormData) => {
    try {
      const result = await addLogisticsPartner({
        variables: {
          data: {
            name,
          },
        },
      });

      if (result.data && onAdd) {
        onAdd();
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Add logistics partner ${name}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return <LogisticsPartnerForm submitText="Add" onSubmit={handleSubmit} />;
}
