import React from "react";
import { SidebarLayout, SidebarLayoutProps } from "@ui/Layouts/SidebarLayout";
import { LogoutButton } from "@ui/Sidebar/LogoutButton";
import { useAdminGlobalSearch } from "./AdminGlobalSearch/AdminGlobalSearch";
import { SearchIcon } from "@heroicons/react/outline";

export function AdminLayout(props: SidebarLayoutProps) {
  return (
    <SidebarLayout bottom={<Bottom />} {...props}>
      {props.children}
    </SidebarLayout>
  );
}

function Bottom() {
  const { launch } = useAdminGlobalSearch();

  return (
    <div className="space-y-2 w-full">
      <div>
        <button
          onClick={launch}
          className="link-white w-full py-1 rounded-sm flex items-center justify-between"
        >
          <div className="flex items-center space-x-2">
            <SearchIcon className="w-5 h-5 flex-none" />
            <span className="flex-none">Find anything</span>
          </div>
          <span className="hidden tablet:block gray-400 text-sm leading-5 py-0.5 px-1.5 border border-gray-300 rounded-md">
            <span className="sr-only">Press </span>
            {isMacintosh ? (
              <kbd className="font-sans">
                <abbr title="Command" className="no-underline">
                  ⌘
                </abbr>
              </kbd>
            ) : (
              <kbd className="font-sans">
                <abbr title="Control" className="no-underline mr-1">
                  Ctl
                </abbr>
              </kbd>
            )}
            <span className="sr-only"> and </span>
            <kbd className="font-sans">K</kbd>
            <span className="sr-only"> to search</span>
          </span>
        </button>
      </div>
      <LogoutButton />
    </div>
  );
}

const isMacintosh = window.navigator.userAgent.indexOf("Mac") != -1;
