import React, { ReactNode } from "react";

export interface ActionPanelProps {
  title: string;
  description: string;
  action: ReactNode;
}

export function ActionPanel({ title, description, action }: ActionPanelProps) {
  return (
    <div className="bg-white shadow tablet:rounded-lg">
      <div className="px-4 py-5 tablet:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <div className="mt-2 text-sm text-gray-500">
          <p>{description}</p>
        </div>
        <div className="mt-5">{action}</div>
      </div>
    </div>
  );
}
