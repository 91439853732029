import React, { useState } from "react";
import { Button } from "@components/ui/Button";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import {
  AdminAdjustmentItemsDisplayTable,
  AdminAdjustmentItemsTableRowData,
} from "./AdminAdjustmentItemsDisplayTable";
import { AdminAdjustmentItemsEditTable } from "./AdminAdjustmentItemsEditTable";
import {
  AdminAdjustmentItemFragment,
  AdminOrderItemFragment,
} from "@apollo/ops";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { formatAmountInCents } from "@utilities/currency";

export interface AdminAdjustmentItemsTableProps {
  orderId: number;
  orderItems: Array<AdminOrderItemFragment>;
  adjustmentItems: Array<AdminAdjustmentItemFragment>;
  canEdit: boolean;
  displayWholesalePricing: boolean;
}

/**
 * Component to that allows to view and edit in a tabular way the adjustment
 * items of an order.
 *
 */
export function AdminAdjustmentItemsTableCmp({
  orderId,
  adjustmentItems,
  orderItems,
  canEdit,
  displayWholesalePricing,
}: AdminAdjustmentItemsTableProps) {
  //When editing == false, we see a read only version of the adjustment items,
  //and when editing == true, input fields appear and allow the user to edit
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  return (
    <>
      <SectionHeading
        title="Adjustments"
        actions={
          canEdit && (
            <Button onClick={toggleEditing} size="sm">
              {editing ? "Cancel" : "Edit"}
            </Button>
          )
        }
      />
      <div>
        {canEdit && editing ? (
          <AdminAdjustmentItemsEditTable
            adjustmentItems={adjustmentItems}
            orderId={orderId}
            orderItems={orderItems}
            onFinish={toggleEditing}
          />
        ) : (
          <AdminAdjustmentItemsDisplayTable
            adjustmentItems={mapAdjustmentItemsToTableData({
              adjustmentItems,
              displayWholesalePricing,
            })}
          />
        )}
      </div>
    </>
  );
}

export const AdminAdjustmentItemsTable = withErrorBoundary(
  AdminAdjustmentItemsTableCmp
);

function mapAdjustmentItemsToTableData({
  adjustmentItems,
  displayWholesalePricing,
}: {
  adjustmentItems: Array<AdminAdjustmentItemFragment>;
  displayWholesalePricing: boolean;
}): Array<AdminAdjustmentItemsTableRowData> {
  return adjustmentItems.map((adjustmentItem) => ({
    product: adjustmentItem.orderItem.product,
    units: adjustmentItem.units,
    adjustmentReason: adjustmentItem.adjustmentReason.label,
    notes: adjustmentItem.notes,
    pricePerUnit: formatAmountInCents(
      displayWholesalePricing
        ? adjustmentItem.orderItem.wholesalePricePerUnit
        : adjustmentItem.orderItem.pricePerUnit
    ),
    total: formatAmountInCents(
      displayWholesalePricing
        ? adjustmentItem.wholesaleTotal
        : adjustmentItem.total
    ),
  }));
}
