import React from "react";
import { Button } from "@ui/Button";
import { useDialog } from "@hooks/useDialog";
import { AddImportJob } from "./AddImportJob";
import { Dialog } from "primereact/dialog";

type AddImportJobModalProps = {
  onAdd?: () => void;
};

export function AddImportJobModal({ onAdd }: AddImportJobModalProps) {
  const addImportJobDialog = useDialog();

  return (
    <>
      <Button onClick={addImportJobDialog.show} size="sm">
        Bulk Import
      </Button>
      <Dialog
        {...addImportJobDialog.getDialogProps()}
        header="Bulk Import Pre-Sales"
        className="p-fluid w-full laptop:max-w-screen-lg"
        modal
      >
        <AddImportJob
          onAdd={() => {
            addImportJobDialog.hide();
            if (onAdd) onAdd();
          }}
        />
      </Dialog>
    </>
  );
}
