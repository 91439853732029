import React, { FC, useEffect, useState } from "react";
import {
  navigate,
  Redirect,
  redirectTo,
  RouteComponentProps,
  Router,
} from "@reach/router";
import { CognitoUser } from "@aws-amplify/auth";
import { SignIn, SignInFormData } from "./SignIn";
import { SignUp, SignUpFormData } from "./SignUp";
import { ForgotPasswordFormData, ForgotPassword } from "./ForgotPassword";
import {
  ConfirmForgotPassword,
  ConfirmForgotPasswordFormData,
} from "./ConfirmForgotPassword";
import { ConfirmSignUp, ConfirmSignUpFormData } from "./ConfirmSignUp";
import { NewPassword, NewPasswordFormData } from "./NewPassword";
import { LoginLayout } from "./LoginLayout";
import {
  authPaths,
  confirmForgotPasswordPath,
  confirmSignUpPath,
  forgotPasswordPath,
  newPasswordPath,
  notFoundPath,
  signInPath,
} from "@routes";
import { ErrorBoundary } from "@components/ErrorBoundary";
import { useAuth } from "../shared/auth";
import { Auth } from "aws-amplify";

const useRedirectAfterSignIn = () => {
  const { user, afterSignInLocation } = useAuth();
  if (user) {
    redirectTo(afterSignInLocation || "/");
  }

  /**
   * After a user is signed in, we redirect them
   */
  useEffect(() => {
    if (user) {
      redirectTo(afterSignInLocation || "/");
    }
  }, [user, afterSignInLocation]);
};

/**
 * These handlers are used to authenticate a user using the @aws-amplify library.
 * Since the documentation is lacking, their implmentation draws on the code in the
 * @aws-amplify/ui-components library.
 */
const useAmplifyAuth = () => {
  const { login } = useAuth();
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [userPassword, setUserPassword] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();

  /**
   * @remarks
   * Given our Cognito setup doesn't require users to confirm their accounts
   */
  const checkAndLogin = (user: CognitoUser) => {
    login(user);
  };

  const handleSignIn = async ({ email, password }: SignInFormData) => {
    try {
      const user = await Auth.signIn(email, password);

      // We currently only support one challenge
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setCognitoUser(user);
        setUserEmail(email);
        navigate(newPasswordPath);
      } else {
        checkAndLogin(user);
      }
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        setUserEmail(email);
        navigate(confirmSignUpPath);
      } else if (error.code === "PasswordResetRequiredException") {
        setUserEmail(email);
        navigate(forgotPasswordPath);
      }

      throw error;
    }
  };

  const handleSignUp = async ({ email, password, name }: SignUpFormData) => {
    const data = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
      },
    });

    if (data.userConfirmed) {
      await handleSignIn({ email, password });
    } else {
      setUserEmail(email);
      setUserPassword(password);
      navigate(confirmSignUpPath);
    }
  };

  const handleConfirmSignUp = async ({
    email,
    code,
  }: ConfirmSignUpFormData) => {
    const confirmSignUpResult = await Auth.confirmSignUp(email, code);

    // Confirmation failed, manual intervention required
    if (!confirmSignUpResult) {
      throw new Error();
    }

    // Automatically sign in we already have the password
    if (userPassword) {
      await handleSignIn({ email, password: userPassword });
    } else {
      navigate(signInPath);
    }
  };

  const handleForgot = async ({ email }: ForgotPasswordFormData) => {
    await Auth.forgotPassword(email);
    setUserEmail(email);
    navigate(confirmForgotPasswordPath);
  };

  const handleConfirmForgotPassword = async ({
    email,
    password,
    code,
  }: ConfirmForgotPasswordFormData) => {
    await Auth.forgotPasswordSubmit(email, code, password);
    await handleSignIn({ email, password });
  };

  const handleNewPassword = async ({ password }: NewPasswordFormData) => {
    const user = await Auth.completeNewPassword(cognitoUser, password);
    checkAndLogin(user);
  };

  return {
    handleSignIn,
    handleSignUp,
    handleForgot,
    handleConfirmForgotPassword,
    handleConfirmSignUp,
    handleNewPassword,
    email: userEmail,
  };
};

const AuthApp: FC<RouteComponentProps> = () => {
  useRedirectAfterSignIn();

  const {
    handleSignIn,
    handleSignUp,
    handleForgot,
    handleConfirmForgotPassword,
    handleConfirmSignUp,
    handleNewPassword,
    email,
  } = useAmplifyAuth();

  return (
    <LoginLayout>
      <ErrorBoundary>
        <Router>
          <SignIn
            path={authPaths.signIn}
            onSignIn={handleSignIn}
            email={email}
          />
          <SignUp path={authPaths.signUp} onSignUp={handleSignUp} />
          <ConfirmSignUp
            path={authPaths.confirmSignUp}
            email={email}
            onConfirmSignUp={handleConfirmSignUp}
          />
          <ForgotPassword
            path={authPaths.forgotPassword}
            email={email}
            onForgot={handleForgot}
          />
          <ConfirmForgotPassword
            path={authPaths.confirmForgotPassword}
            email={email}
            onConfirm={handleConfirmForgotPassword}
          />
          <NewPassword
            path={authPaths.newPassword}
            onNewPassword={handleNewPassword}
          />
          <SignIn
            path={authPaths.acceptInvitation}
            hideSignUp={true}
            onSignIn={handleSignIn}
          />
          <Redirect from="*" to={notFoundPath} />
        </Router>
      </ErrorBoundary>
    </LoginLayout>
  );
};

export default AuthApp;
