import React from "react";
import { compose } from "lodash/fp";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { useUserContext, withAuthentication } from "@shared/auth";
import { signUpPath } from "@routes";
import { RouteComponentProps, useNavigate, redirectTo } from "@reach/router";
import { RegisterRetailer } from "./RegisterRetailer";
import { SimplePage, PageHeading } from "@components/ui/Page";

type RegisterRetailerPageProps = {
  /**
   * Where to redirect the user after they've registered.
   */
  redirectPath?: string;
} & RouteComponentProps;

function RegisterRetailerPageBase({
  redirectPath = "/",
}: RegisterRetailerPageProps) {
  const navigate = useNavigate();
  const user = useUserContext();

  /**
   * We don't support users registering multiple organizations, if they already
   * have a registered organization redirect.
   */
  if (user.organizations.length > 0) {
    redirectTo(redirectPath);
  }

  return (
    <div className="tablet:mx-auto tablet:w-full tablet:max-w-lg">
      <SimplePage heading={<PageHeading title="Register your business" />}>
        <RegisterRetailer onRegister={() => navigate(redirectPath)} />
      </SimplePage>
    </div>
  );
}

export const RegisterRetailerPage = compose(
  withAuthentication({ redirectPath: signUpPath }),
  withErrorBoundary
)(RegisterRetailerPageBase);
