import React from "react";
import { Toggle } from "@ui/Toggle";
import {
  PresaleProductDisplayFragment,
  ProductFulfillmentExclusionReason,
  useUpdatePreSaleProductFulfillmentExclusionMutation,
} from "@apollo/ops";
import { ProductFulfillmentExclusionReasonDropdown } from "@components/PreSaleProductsTable/PreSaleProductFulfillmentToggle/ProductFulfillmentExclusionReasonDropdown/ProductFulfillmentExclusionReasonDropdown";
import { Controller, useForm } from "react-hook-form";

type PreSaleProductFulfillmentToggleProps = {
  preSaleProduct: PresaleProductDisplayFragment;
};

type PreSaleProductFulfillmentToggleFormData = {
  includeInFulfillment: boolean;
  exclusionReason?: Pick<
    ProductFulfillmentExclusionReason,
    "id" | "label"
  > | null;
};

export function PreSaleProductFulfillmentToggle({
  preSaleProduct,
}: PreSaleProductFulfillmentToggleProps) {
  const [
    updateFulfillmentExclusion,
  ] = useUpdatePreSaleProductFulfillmentExclusionMutation();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PreSaleProductFulfillmentToggleFormData>({
    defaultValues: {
      includeInFulfillment: !preSaleProduct.excludeFromFulfillment,
      exclusionReason: preSaleProduct.productFulfillmentExclusionReason,
    },
  });

  const submit = handleSubmit(async (formData) => {
    const exclusionReasonId = formData.includeInFulfillment
      ? undefined
      : formData.exclusionReason?.id;

    await updateFulfillmentExclusion({
      variables: {
        data: {
          preSaleId: preSaleProduct.preSaleId,
          productId: preSaleProduct.product.id,
          excludeFromFulfillment: !formData.includeInFulfillment,
          exclusionReasonId: exclusionReasonId,
        },
      },
    });
  });

  const includeInFulfillment = watch("includeInFulfillment");

  return (
    <form>
      <div className="flex items-center h-10 w-60">
        <Controller
          name="includeInFulfillment"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Toggle
              enabled={value}
              onChange={(includeInFulfillment) => {
                if (!includeInFulfillment) setValue("exclusionReason", null);
                onChange(includeInFulfillment);

                //Note: we could also handle submitting by watching the form values and using an effect callback
                //that submits the form. Would need to make sure to not submit on initial render using
                //something like https://medium.com/swlh/prevent-useeffects-callback-firing-during-initial-render-the-armchair-critic-f71bc0e03536
                submit();
              }}
              disabled={isSubmitting}
            />
          )}
        />
        {!includeInFulfillment && (
          <Controller
            name="exclusionReason"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ProductFulfillmentExclusionReasonDropdown
                value={value}
                onChange={(value) => {
                  onChange(value);
                  submit();
                }}
                disabled={isSubmitting}
              />
            )}
          />
        )}
      </div>
    </form>
  );
}
