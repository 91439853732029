import React from "react";
import { Button } from "@ui/Button";
import { useAdminDeleteProducerMutation } from "@apollo/ops";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { useNavigate } from "@reach/router";
import { adminProducersPath } from "@routes";

type DeleteProducerButtonProps = {
  producerId: number;
};

export function DeleteProducerButton({
  producerId,
}: DeleteProducerButtonProps) {
  const [deleteProducer] = useAdminDeleteProducerMutation();
  const dialog = useDialog();
  const navigate = useNavigate();

  const onConfirm = async () => {
    try {
      const result = await deleteProducer({
        variables: {
          id: producerId,
        },
      });

      if (result.data) {
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Successfully deleted ${result.data.deleteProducer.name}`,
        });
        navigate(adminProducersPath);
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <>
      <Button destructive kind="secondary" onClick={dialog.open} size="sm">
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this producer?"
        description="WARNING: Deleting this producer is non-reversible"
        onConfirm={onConfirm}
        confirmText="Yes, delete this producer"
        dialog={dialog}
      />
    </>
  );
}
