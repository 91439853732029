import React from "react";
import { DescriptionListItem } from "@ui/DescriptionList";
import { AdminRegionDetailsFragment } from "@apollo/gen/generatedOperations";
import { RouteComponentProps } from "@reach/router";

interface AdminRegionDetailsProps extends RouteComponentProps {
  region: AdminRegionDetailsFragment;
}

export function AdminRegionDetails(props: AdminRegionDetailsProps) {
  const { region } = props;
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-4">
      <div className="sm:col-span-1">
        <DescriptionListItem term="Name" description={region.friendlyName} />
      </div>
      <div className="sm:col-span-1">
        <DescriptionListItem
          term="Abbreviation"
          description={region.abbreviation}
        />
      </div>
      <div className="sm:col-span-1">
        <DescriptionListItem term="State" description={region.state.name} />
      </div>
      <div className="sm:col-span-1">
        <DescriptionListItem
          term="Shopify Tag"
          description={region.shopifyIdentifier}
        />
      </div>
    </dl>
  );
}
