import { Badge, BadgeProps, Color } from "@ui/Badge";

type PreSaleOrderStatusBadgeProps = Partial<BadgeProps> & {
  cancelledAt: Date | null;
};

/**
 * This is a UI only status that indicates if the order has been cancelled or
 * not.
 */
export function PreSaleOrderStatusBadge({
  cancelledAt,
  ...rest
}: PreSaleOrderStatusBadgeProps) {
  return Badge({ ...getPreSaleOrderStatusBadgeProps(cancelledAt), ...rest });
}

function getPreSaleOrderStatusBadgeProps(
  cancelledAt: Date | null
): { label: string; color: Color } {
  if (cancelledAt !== null) {
    return { label: "cancelled", color: "red" as Color };
  } else {
    return { label: "placed", color: "green" as Color };
  }
}
