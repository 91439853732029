import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "@ui/Button";
import { Calendar } from "@ui";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";
import { defaultErrorHandler } from "@utilities/toast";

export type ProducerOffersFilterFormData = {
  producerId: number;
  regionId?: number | null;
  number?: string | null;
  dateRange?: {
    before: Date;
    after: Date;
  } | null;
};

type ProducerOffersFilterProps = {
  onFilter: (data: ProducerOffersFilterFormData) => void | Promise<void>;
  defaultValues?: Partial<ProducerOffersFilterFormData>;
};

export function ProducerOffersFilter({
  onFilter,
  defaultValues,
}: ProducerOffersFilterProps) {
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<ProducerOffersFilterFormData>({
    defaultValues,
  });

  const wrappedOnSubmit = async (data: ProducerOffersFilterFormData) => {
    try {
      await onFilter(data);
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)} autoComplete="off">
      <div className="grid laptop:grid-cols-3 gap-3 items-end">
        <div>
          <Controller
            name="regionId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RegionsDropdown onChange={onChange} value={value} />
            )}
          />
        </div>
        <div>
          <Controller
            name="dateRange"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Calendar
                selectionMode="range"
                placeholder="Filter by date range"
                value={value ? Object.values(value) : []}
                showButtonBar
                onChange={(e) => {
                  const dates = e.value as Date[];
                  if (dates && dates.length == 2) {
                    onChange({ after: dates[0], before: dates[1] });
                  } else {
                    onChange(null);
                  }
                }}
              />
            )}
          />
        </div>
        <div>
          <Button type="submit" disabled={isSubmitting} kind="secondary">
            Filter
          </Button>
        </div>
      </div>
    </form>
  );
}
