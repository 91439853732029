import React, { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { QueryResult } from "@apollo/client";
import {
  RetailerTransactionsQuery,
  useRetailerTransactionsQuery,
} from "@apollo/ops";
import { LoadingIcon } from "@components/ui/Spinner";
import { PageHeading, Page } from "@components/ui/Page";
import { RetailerTransactionsTable } from "./RetailerTransactionsTable";
import { RouteComponentProps } from "@reach/router";

type UseRetailerTransactionsResult = {
  query: QueryResult<RetailerTransactionsQuery>;
  monthFilter: string;
  setMonthFilter: (monthFilter: string) => void;
};

const useRetailerTransactions = (
  organizationId: number
): UseRetailerTransactionsResult => {
  const [monthFilter, setMonthFilter] = useState("");

  const query = useRetailerTransactionsQuery({
    variables: {
      organizationEntityId: organizationId,
      monthFilter,
    },
  });

  return {
    query,
    monthFilter,
    setMonthFilter,
  };
};

type RetailerProps = {
  organizationId: number;
} & RouteComponentProps;

export function RetailerTransactions({ organizationId }: RetailerProps) {
  const { query } = useRetailerTransactions(organizationId);

  const { data: results, loading, error } = query;

  useErrorHandler(error);

  const data = results?.organization?.fundsTransfers
    ? results.organization.fundsTransfers
    : [];

  return (
    <Page heading={<PageHeading title="Payments" />}>
      {loading && !data ? (
        <LoadingIcon />
      ) : (
        <div className="mt-8 card">
          {data.length === 0 ? (
            <>
              <p>No transactions</p>
              <br />
            </>
          ) : null}

          {data.length > 0 && (
            <RetailerTransactionsTable fundsTransfers={data} />
          )}
        </div>
      )}
    </Page>
  );
}
