import React, { createContext, ReactNode, useContext, useState } from "react";
import { uniqueId } from "lodash";
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from "@components/Dialog/ConfirmDialog";
import { useDialog } from "@ui/Dialog";

type ConfirmOptions = Omit<ConfirmDialogProps, "dialog">;

type DialoggerContextValue = {
  confirm: (options: ConfirmOptions) => void;
};

const DialoggerContext = createContext<DialoggerContextValue>(
  {} as DialoggerContextValue
);

type DialoggerProviderProps = {
  children: ReactNode;
};

type DialoggerStateValue = {
  id: string;
  options: {
    type: "confirm";
    props: ConfirmOptions;
  };
};

/**
 * The DialoggerProvider can be used to provide children with a simple API to
 * launch a dialog. For things that are more than a simple confirmation dialog,
 * you'll likely want to manage it yourself.
 */
export function DialoggerProvider({ children }: DialoggerProviderProps) {
  const dialogInstance = useDialog();
  const [dialog, setDialog] = useState<DialoggerStateValue | null>(null);

  const confirm = (props: ConfirmOptions) => {
    setDialog({
      id: uniqueId(),
      options: {
        type: "confirm",
        props,
      },
    });

    dialogInstance.open();
  };

  return (
    <DialoggerContext.Provider value={{ confirm }}>
      {children}
      {dialog !== null && (
        <ConfirmDialog {...dialog.options.props} dialog={dialogInstance} />
      )}
    </DialoggerContext.Provider>
  );
}

export const useDialogger = () => useContext(DialoggerContext);
