/**
 * This file contains types and objects that can be used to add styling props
 * to components and used to build class strings.
 *
 * TailwindCSS scans our source files to detect used classes and builds a
 * stylesheet only using classes it finds. Because of this, we don't dynamically
 * build our class (e.g. `align-${value}`) but always use complete class names.
 * @see https://tailwindcss.com/docs/content-configuration#class-detection-in-depth
 */

export type VerticalAlignment =
  | "top"
  | "middle"
  | "bottom"
  | "baseline"
  | "super"
  | "sub";

export const verticalAlignClasses: Record<VerticalAlignment, string> = {
  top: "align-top",
  middle: "align-middle",
  bottom: "align-bottom",
  baseline: "align-baseline",
  super: "align-super",
  sub: "align-sub",
};
