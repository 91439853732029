import React, { useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { useForm } from "react-hook-form";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";
import { Card, CardBody } from "@components/ui/Card/Card";
import { signInPath } from "@routes";

export type ForgotPasswordFormData = {
  email: string;
};

type ForgotPasswordProps = {
  email?: string;
  onForgot: (data: ForgotPasswordFormData) => Promise<void>;
} & RouteComponentProps;

export function ForgotPassword({ email, onForgot }: ForgotPasswordProps) {
  const [error, setError] = useState<string | null>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({
    mode: "onBlur",
    defaultValues: {
      email,
    },
  });

  const onSubmit = async (data: ForgotPasswordFormData) => {
    setError(null);

    try {
      onForgot(data);
      reset();
    } catch (error) {
      if (error.message) {
        setError(error.message);
      } else {
        // TODO report this error somewhere
        setError("An unknown error occurred, please reach out to support.");
      }
    }
  };

  return (
    <Card title="Reset your password">
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <Input
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                  })}
                  error={errors.email?.message}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <div>
              <Button size="lg" type="submit" disabled={false}>
                Send code
              </Button>
            </div>
            <div className="ml-6 text-sm">
              Know your password?{" "}
              <Link to={signInPath} className="link">
                Sign in
              </Link>
            </div>
          </div>
        </form>
        {error && <div className="text-red-600 mt-6">{error}</div>}
      </CardBody>
    </Card>
  );
}
