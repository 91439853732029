import React, { FormEvent } from "react";
import { ActionPanel } from "@components/ui/ActionPanel";
import { Button } from "@components/ui/Button";

export interface SandboxAddHookResult {
  onSubmit: () => void;
  disabled: boolean;
}

export type SandboxAddHook = () => SandboxAddHookResult;

export interface SandboxAddCardProps {
  title: string;
  description: string;
  addHook: SandboxAddHook;
}

export function SandboxAddCard({
  title,
  description,
  addHook,
}: SandboxAddCardProps) {
  const { onSubmit, disabled } = addHook();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <ActionPanel
      title={title}
      description={description}
      action={
        <form onSubmit={handleSubmit} className="w-0 flex-1 flex">
          <Button type="submit" disabled={disabled}>
            Create
          </Button>
        </form>
      }
    />
  );
}
