import React from "react";
import { ApolloError, isApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import { Toast, ToastMessage } from "primereact/toast";
import { MessageSeverity } from "@shared/types/Severity";

export const toast = React.createRef<Toast>();

/**
 * This type represents the ValidationError class in the backend.  It'd be able
 * to share one interface between the frontend and backend for this.
 */
type ValidationError = {
  code: "Validation";
  checks: Array<{
    description: string;
    passed: boolean;
    metadata?: Record<string, unknown>;
  }> | null;
};

function mapErrorToToast(error: GraphQLError): ToastMessage {
  /**
   * Internal server errors will have the code `INTERNAL_SERVER_ERROR`.
   *
   * @see https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
   */
  if (error.extensions?.code === "INTERNAL_SERVER_ERROR") {
    if (error.extensions?.exception?.name === "ValidationError") {
      const validationError = error.extensions.exception as ValidationError;
      const failedChecks = validationError.checks?.filter(
        (check) => !check.passed
      );

      return {
        severity: MessageSeverity.error,
        summary:
          "A validation error occurred" +
          (error.message ? `: ${error.message}` : ""),
        detail: failedChecks ? (
          <div className="text-sm">
            The following checks failed:
            <ul className="list-disc list-inside text-sm">
              {failedChecks.map((f, index) => (
                <li key={index}>{f.description}</li>
              ))}
            </ul>
          </div>
        ) : null,
        life: 10 * 1000,
      };
    }
  }

  return {
    severity: MessageSeverity.error,
    summary: "Error",
    detail: error.message,
  };
}

export function toastError(error: Error | ApolloError): void {
  if (isApolloError(error)) {
    const toastMessages = error.graphQLErrors.map(mapErrorToToast);

    toast.current?.show(toastMessages);
  } else {
    toast.current?.show({
      severity: MessageSeverity.error,
      summary: "Error",
      detail: error.message,
    });
  }
}

export function defaultErrorHandler(error: unknown) {
  if (error instanceof Error) {
    toastError(error);
  } else {
    toast.current?.show({
      severity: MessageSeverity.error,
      summary: "An unknown error ocurred",
    });
  }
}

type ToastSuccessOptions = {
  summary?: string;
  detail?: string;
};

export function toastSuccess(options: ToastSuccessOptions) {
  toast.current?.show({
    severity: MessageSeverity.success,
    ...options,
  });
}
