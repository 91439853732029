import React, {
  createContext,
  useEffect,
  ComponentType,
  useContext,
  FunctionComponent,
} from "react";
import { useLocation, redirectTo } from "@reach/router";
import { IUser } from "./AuthContext";
import { useAuth } from "./useAuth";
import { signInPath } from "@routes";

export type User = IUser;

const UserContext = createContext<User>(null!);

export const useUserContext = () => useContext(UserContext);

type WithAuthenticationOptions = {
  redirectPath?: string;
};

/*
 * This higher-order component is used to guarantee that an authenticated
 * user will be in scope and available via the useUser hook for all components
 * under its render tree.
 */
export function withAuthentication<TProps>(
  options?: WithAuthenticationOptions
) {
  const { redirectPath } = {
    redirectPath: signInPath,
    ...options,
  };

  return (Component: ComponentType<TProps>): FunctionComponent<TProps> => {
    const WithAuthentication = (props: TProps) => {
      const { setAfterSignInLocation, isAuthenticating, user } = useAuth();
      const location = useLocation();

      useEffect(() => {
        if (!isAuthenticating && !user) {
          setAfterSignInLocation(location.pathname);
          redirectTo(redirectPath);
        }
      }, [user, isAuthenticating, location.pathname, setAfterSignInLocation]);

      if (user) {
        return (
          <UserContext.Provider value={user}>
            <Component {...props} />
          </UserContext.Provider>
        );
      }

      // Waiting for authentication to complete. No-op for now, maybe in the
      // future we'll want to do something else.
      return null;
    };

    return WithAuthentication;
  };
}
