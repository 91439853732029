import { useGetShopifyUrlWithLoginTokenQuery } from "@apollo/ops";
import { useErrorHandler } from "react-error-boundary";

declare const window: Window;

type RedirectToShopifyProps = { organizationId: number };

export function RedirectToShopify({ organizationId }: RedirectToShopifyProps) {
  const { data, error } = useGetShopifyUrlWithLoginTokenQuery({
    fetchPolicy: "no-cache",
    variables: { path: null, organizationId },
  });

  const bubbleErrorToErrorBoundary = useErrorHandler();

  if (error) {
    bubbleErrorToErrorBoundary(error);
    return null;
  }

  if (data?.getShopifyUrlWithLoginToken?.shopifyUrlWithLoginToken) {
    window.location.href =
      data.getShopifyUrlWithLoginToken.shopifyUrlWithLoginToken;
  }

  return null;
}
