import React, { ReactNode } from "react";
import ctl from "@netlify/classnames-template-literals";

type BalancesProps = {
  data: BalancesData;
};

export type BalancesData = Array<BalanceSection>;

type BalanceSection = {
  title?: string;
  balances: Array<Balance>;
};

type Balance = {
  label: ReactNode;
  value: ReactNode;
  summary?: boolean;
};

export function Balances({ data }: BalancesProps) {
  return (
    <div className="divide-y divide-gray-200 space-y-2">
      {data.map((section, index) => (
        <div key={index} className="pt-2">
          {section.title && (
            <div className="my-1.5 mx-3 text-xs font-bold text-gray-500 ">
              {section.title}
            </div>
          )}
          {section.balances.map((balances, index) => (
            <div
              key={index}
              className={balancesClasses({ summary: balances.summary })}
            >
              <div>{balances.label}</div>
              <div>{balances.value}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

type BalanceClassesOptions = {
  summary?: boolean;
};

const balancesClasses = ({ summary = false }: BalanceClassesOptions) =>
  ctl(`
    my-1
    mx-5
    flex
    items-center
    justify-between
    ${summary && "font-bold"}
  `);
