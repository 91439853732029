import React, { useMemo } from "react";
import {
  useTable,
  Column,
  useFilters,
  UseFiltersInstanceProps,
  TableInstance,
  useSortBy,
} from "react-table";
import {
  FundsTransferAccountFragment,
  RetailerFundsTransferFieldsFragment,
} from "@apollo/ops";
import { Input } from "@components/ui/Input";
import { Table } from "@components/ui/Table";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";

type RetailerTransactionsTableProps = {
  fundsTransfers: RetailerFundsTransferFieldsFragment[];
};

export function RetailerTransactionsTable({
  fundsTransfers,
}: RetailerTransactionsTableProps) {
  const columns = useMemo<Column<RetailerFundsTransferFieldsFragment>[]>(
    () => [
      {
        Header: "Date Initiated",
        Cell: ({ value }) => formatAsDate(new Date(value)),
        accessor: "createdAt",
      },
      {
        Header: "Retailer Invoice #",
        accessor: "retailerInvoiceNumber",
      },
      {
        Header: "Amount",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "amount",
      },
      {
        Header: "To",
        Cell: ToCell,
        accessor: "destination",
      },
      {
        Header: "Bank Account",
        Cell: FundsAccountCell,
        accessor: "source",
      },
      {
        Header: "Statement Descriptor",
        accessor: "statementDescriptor",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: fundsTransfers,
    },
    useFilters,
    useSortBy
  ) as TableInstance<RetailerFundsTransferFieldsFragment> &
    UseFiltersInstanceProps<RetailerFundsTransferFieldsFragment>;

  const { setFilter } = tableInstance;

  return (
    <>
      <div className="mb-1 w-48">
        <Input
          type="text"
          onChange={(e) => setFilter("retailerInvoiceNumber", e.target.value)}
          placeholder="Search for an invoice"
        />
      </div>

      <Table instance={tableInstance} />
    </>
  );
}

type ToCellProps = {
  value: FundsTransferAccountFragment;
};

function ToCell({ value: fundsAccount }: ToCellProps) {
  return <div>{fundsAccount.business.displayName}</div>;
}

type FundsAccountCellProps = {
  value: FundsTransferAccountFragment;
};

function FundsAccountCell({ value: fundsAccount }: FundsAccountCellProps) {
  return <div>{fundsAccount.label}</div>;
}
