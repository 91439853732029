import React from "react";
import { RouteComponentProps } from "@reach/router";
import { NumberParam, useQueryParams } from "use-query-params";
import {
  RetailerSalesOrderDeliveryAgingReportQuery,
  RetailerSalesOrderDeliveryAgingReportDocument,
  RetailerSalesOrderDeliveryAgingReportQueryVariables,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import {
  DeliveryAgingReportFilters,
  FilterData,
} from "./DeliveryAgingReportFilters";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { DeliveryAgingReportTable } from "./DeliveryAgingReportTable";
import { LoadingIcon } from "@components/ui/Spinner";

export function RetailerSalesOrderDeliveryAgingReportPage(
  _props: RouteComponentProps
) {
  const [filters, setFilters] = useQueryParams({
    regionId: NumberParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    RetailerSalesOrderDeliveryAgingReportQuery,
    RetailerSalesOrderDeliveryAgingReportQueryVariables
  >({
    options: {
      variables: {
        filters,
      },
    },
    /**
     * We set a high pagination level here since this report will be used to
     * find retail orders and add proof-of-delivery information.
     *
     * Reminder to keep the query as minimal as possible here.
     */
    paginationOptions: {
      pageSize: 100,
    },
    query: RetailerSalesOrderDeliveryAgingReportDocument,
    mapDataToTotal({ retailerSalesOrderQueries }) {
      return retailerSalesOrderQueries.deliveryAgingReport.total;
    },
  });

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  const { data: currentData, previousData, loading } = queryResult;

  const breadcrumbProps = {
    items: buildBreadcrumbs("Retail Sales Orders"),
  };

  const data = currentData ?? previousData;

  return (
    <Page
      heading={
        <PageHeading
          title="Delivery Aging Report"
          breadcrumbProps={breadcrumbProps}
        />
      }
    >
      <div className="mb-3">
        <DeliveryAgingReportFilters
          onFilter={updateFilters}
          defaultValues={filters}
        />
      </div>
      {data && (
        <DeliveryAgingReportTable
          data={data.retailerSalesOrderQueries.deliveryAgingReport.results}
          deliverySLT={data.deliverySLT}
          deliveryStepSLT={data.deliveryStepSLT}
          pagination={pagination}
        />
      )}
      {!data && loading && (
        <div className="mt-8">
          <LoadingIcon />
        </div>
      )}
    </Page>
  );
}
