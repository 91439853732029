import {
  OnboardingDocument,
  useAddBankAccountViaPlaidMutation,
} from "@apollo/gen/generatedOperations";
import { PlaidLinkOnSuccess, usePlaidLink } from "react-plaid-link";
import { defaultErrorHandler } from "@util/toast";
import { PLAID_CLIENT_NAME, PLAID_ENVIRONMENT, PLAID_PRODUCTS } from "@config";

interface useAddBankAccountDialogProps {
  /**
   * The id of the BusinessEntity to which the BankAccount that we want to create will belong to.
   */
  businessId: number;
  /**
   * A Plaid link token for the Plaid Link component (see more info here: https://plaid.com/docs/link/)
   */
  plaidLinkToken: string;
  /**
   * Callback function called after we created the BankAccount successfully.
   */
  onBankAccountAdded?: (bankAccountId: number) => void;
}

/**
 * This hook has reusable logic to use the Plaid Link dialog and create a BankAccount in the backend with the Plaid
 * Item created by that dialog.
 */
export const useAddBankAccountDialog = ({
  businessId,
  plaidLinkToken,
  onBankAccountAdded,
}: useAddBankAccountDialogProps) => {
  const [addBankAccount, result] = useAddBankAccountViaPlaidMutation();

  const onSuccess: PlaidLinkOnSuccess = async (plaidPublicToken, metadata) => {
    try {
      const response = await addBankAccount({
        variables: {
          businessId,
          plaidPublicToken,
          selectedAccountId: metadata.accounts[0].id,
        },
        refetchQueries: [
          {
            query: OnboardingDocument,
            variables: { id: businessId },
          },
        ],
      });
      const bankAccount = response.data?.addBankAccountViaPlaid;
      if (onBankAccountAdded && bankAccount) {
        onBankAccountAdded(bankAccount.id);
      }
    } catch (error) {
      console.error(error);
      defaultErrorHandler(error);
    }
  };

  const { open: openPlaid, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess,
    env: PLAID_ENVIRONMENT,
    product: PLAID_PRODUCTS,
    clientName: PLAID_CLIENT_NAME,
  });

  return { addBankAccount: openPlaid, ready, loading: result.loading, result };
};
