import React from "react";
import { Toggle } from "@ui/Toggle";
import {
  AdminPreSaleProducerSummaryDisplayFragment,
  ProductFulfillmentExclusionReason,
  useUpdatePreSaleProducerFulfillmentExclusionMutation,
} from "@apollo/ops";
import { ProductFulfillmentExclusionReasonDropdown } from "@components/PreSaleProductsTable/PreSaleProductFulfillmentToggle/ProductFulfillmentExclusionReasonDropdown/ProductFulfillmentExclusionReasonDropdown";
import { Controller, useForm } from "react-hook-form";
import { Switch } from "@headlessui/react";

type PreSaleProducerFulfillmentToggleProps = {
  producerId: AdminPreSaleProducerSummaryDisplayFragment["producerId"];
  isExcluded: boolean;
  preSaleId: number;
  exclusionReason: ProductFulfillmentExclusionReason | null;
};

type PreSaleProductFulfillmentToggleFormData = {
  includeInFulfillment: boolean;
  exclusionReason: Pick<ProductFulfillmentExclusionReason, "id"> | null;
};

export function PreSaleProducerFulfillmentToggle({
  producerId,
  preSaleId,
  isExcluded,
  exclusionReason,
}: PreSaleProducerFulfillmentToggleProps) {
  const [
    updateFulfillmentExclusion,
  ] = useUpdatePreSaleProducerFulfillmentExclusionMutation();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PreSaleProductFulfillmentToggleFormData>({
    defaultValues: {
      includeInFulfillment: !isExcluded,
      exclusionReason,
    },
  });

  const submit = handleSubmit(async (formData) => {
    const exclusionReasonId = formData.includeInFulfillment
      ? undefined
      : formData.exclusionReason?.id;

    await updateFulfillmentExclusion({
      variables: {
        data: {
          preSaleId: preSaleId,
          producerId,
          excludeFromFulfillment: !formData.includeInFulfillment,
          exclusionReasonId: exclusionReasonId,
        },
      },
    });
  });

  const includeInFulfillment = watch("includeInFulfillment");

  return (
    <form>
      <div className="flex items-center h-10">
        <Controller
          name="includeInFulfillment"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch.Group>
              <Switch.Label className="p-2 text-gray-900 font-medium">
                Include in Fulfillment?
              </Switch.Label>
              <Toggle
                enabled={value}
                onChange={(includeInFulfillment) => {
                  if (!includeInFulfillment) setValue("exclusionReason", null);
                  onChange(includeInFulfillment);

                  //Note: we could also handle submitting by watching the form values and using an effect callback
                  //that submits the form. Would need to make sure to not submit on initial render using
                  //something like https://medium.com/swlh/prevent-useeffects-callback-firing-during-initial-render-the-armchair-critic-f71bc0e03536
                  submit();
                }}
                disabled={isSubmitting}
              />
            </Switch.Group>
          )}
        />
        {!includeInFulfillment && (
          <Controller
            name="exclusionReason"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ProductFulfillmentExclusionReasonDropdown
                value={value}
                onChange={(value) => {
                  onChange(value);
                  submit();
                }}
                disabled={isSubmitting}
              />
            )}
          />
        )}
      </div>
    </form>
  );
}
