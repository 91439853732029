import { useCallback, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { ApolloError } from "@apollo/client";
import {
  AdminFundsTransferFieldsFragment,
  useAdminTransactionsQuery,
} from "@apollo/ops";

export interface TransactionsEnhancerResult {
  data: Array<AdminFundsTransferFieldsFragment> | undefined;
  loading: boolean;
  limit: number;
  setLimit: (limit: number) => void;
  offset: number;
  setOffset: (offset: number) => void;
  monthFilter: string;
  setMonthFilter: (monthFilter: string) => void;
  refetchTransfers: () => void;
}

export const useTransactionsEnhancer = () => {
  const {
    TransactionsData,
    error: errorQuerying,
    loading,
    limit,
    setLimit,
    offset,
    setOffset,
    monthFilter,
    setMonthFilter,
    refetch,
  } = useReadTransactions();
  const onFilter = (monthFilter: string) => {
    setMonthFilter(monthFilter);
    setOffset(0);
  };
  const setMonthFilterHandler = useCallback(
    (month) => {
      setMonthFilter(month);
      setOffset(0);
    },
    [setMonthFilter, setOffset]
  );
  useErrorHandler(errorQuerying);

  return {
    data: TransactionsData,
    loading: loading,
    limit,
    setLimit,
    offset,
    setOffset,
    monthFilter,
    setMonthFilter: setMonthFilterHandler,
    onFilter,
    refetchTransfers: refetch,
  };
};

const useReadTransactions = (): {
  TransactionsData: Array<AdminFundsTransferFieldsFragment>;
  error: ApolloError | undefined;
  loading: boolean;
  limit: number;
  setLimit: (limit: number) => void;
  offset: number;
  setOffset: (offset: number) => void;
  monthFilter: string;
  setMonthFilter: (monthFilter: string) => void;
  refetch: () => void;
} => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [monthFilter, setMonthFilter] = useState("");

  const { data, loading, error, refetch } = useAdminTransactionsQuery({
    variables: {
      limit,
      offset,
      monthFilter,
    },
    pollInterval: 3 * 1000,
  });

  useErrorHandler(error);

  const TransactionsData = data?.fundsTransfers ? data.fundsTransfers : [];

  return {
    TransactionsData,
    error,
    loading,
    limit,
    setLimit,
    offset,
    setOffset,
    monthFilter,
    setMonthFilter,
    refetch,
  };
};
