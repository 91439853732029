import React from "react";
import { Button } from "@ui/Button";
import { useDialog } from "@ui/Dialog";
import { AddPreSaleOrderDialog } from "./AddPreSaleOrderDialog";

type AddPreSaleOrderButtonProps = {
  preSaleId: number;
};

export function AddPreSaleOrderButton({
  preSaleId,
}: AddPreSaleOrderButtonProps) {
  const dialog = useDialog();

  return (
    <>
      <Button kind="secondary" onClick={dialog.open}>
        Add order
      </Button>
      <AddPreSaleOrderDialog dialog={dialog} preSaleId={preSaleId} />
    </>
  );
}
