import React, { useMemo } from "react";
import { CellProps, Column, useSortBy, useTable } from "react-table";
import { Table } from "@ui/Table";
import {
  ProducerPurchaseOrderItemFragment,
  ProductDisplayFragment,
} from "@apollo/ops";
import { Card } from "@ui/Card/Card";

export type ProducerPurchaseOrderItemsTableRowData = {
  product: ProductDisplayFragment;
  price: string;
  quantity: number;
  total: string;
  orderId: number;
};

export type ProducerPurchaseOrderItemsDisplayTableProps = {
  orderItems: Array<ProducerPurchaseOrderItemsTableRowData>;
};

export function ProducerPurchaseOrderItemsDisplayTable({
  orderItems,
}: ProducerPurchaseOrderItemsDisplayTableProps) {
  const columns: Array<
    Column<ProducerPurchaseOrderItemsTableRowData>
  > = useMemo(() => {
    return [
      {
        Header: "Product",
        Cell: ({
          row,
        }: CellProps<ProducerPurchaseOrderItemFragment, "string">) =>
          row.original.product.name,
        accessor: "product",
        width: 140,
      },
      {
        Header: "Price",
        accessor: "price",
        width: 140,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        width: 140,
      },
      {
        Header: "Total",
        accessor: "total",
        width: 140,
      },
    ];
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data: orderItems,
    },
    useSortBy
  );

  return (
    <div className="mt-5">
      <Card title="Order Items">
        <Table instance={tableInstance} />
      </Card>
    </div>
  );
}
