import React from "react";
import { Link } from "@reach/router";
import { adminOfferWindowPath } from "@routes";

type AdminOfferWindowLinkProps = {
  offerWindow: {
    id: number;
  };
};

export function AdminOfferWindowLink({
  offerWindow,
}: AdminOfferWindowLinkProps) {
  return (
    <Link className="underline" to={adminOfferWindowPath(offerWindow.id)}>
      #{offerWindow.id}
    </Link>
  );
}
