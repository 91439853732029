import React, { useMemo } from "react";
import {
  useTable,
  Column,
  useFilters,
  UseFiltersInstanceProps,
  TableInstance,
  useSortBy,
} from "react-table";
import {
  FundsTransferAccountFragment,
  ProducerFundsTransferFieldsFragment,
} from "@apollo/ops";
import { Input } from "@components/ui/Input";
import { Table } from "@components/ui/Table";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";

type ProducerTransactionsTableProps = {
  fundsTransfers: ProducerFundsTransferFieldsFragment[];
};

export function ProducerTransactionsTable({
  fundsTransfers,
}: ProducerTransactionsTableProps) {
  const columns = useMemo<Column<ProducerFundsTransferFieldsFragment>[]>(
    () => [
      {
        Header: "Date Initiated",
        Cell: ({ value }) => formatAsDate(new Date(value)),
        accessor: "createdAt",
      },
      {
        Header: (
          <>
            Brewery <span className="whitespace-nowrap">Invoice #</span>
          </>
        ),
        accessor: "breweryInvoiceNumber",
      },
      {
        Header: "Amount",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "amount",
      },
      {
        Header: "From",
        Cell: ToCell,
        accessor: "source",
      },
      {
        Header: "To",
        Cell: ToCell,
        accessor: "destination",
      },
      {
        Header: "Statement Descriptor",
        accessor: "statementDescriptor",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: fundsTransfers,
    },
    useFilters,
    useSortBy
  ) as TableInstance<ProducerFundsTransferFieldsFragment> &
    UseFiltersInstanceProps<ProducerFundsTransferFieldsFragment>;

  const { setFilter } = tableInstance;

  return (
    <>
      <div className="mb-1 w-48">
        <Input
          type="text"
          onChange={(e) => setFilter("breweryInvoiceNumber", e.target.value)}
          placeholder="Search for an invoice"
        />
      </div>

      <Table instance={tableInstance} />
    </>
  );
}

type ToCellProps = {
  value: FundsTransferAccountFragment;
};

function ToCell({ value: fundsAccount }: ToCellProps) {
  return (
    <>
      <div>{fundsAccount.business.displayName}</div>
      <div>{fundsAccount.label}</div>
    </>
  );
}
