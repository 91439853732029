import { Business, RegionV2 } from "@apollo/ops";
import React, { FC } from "react";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";
import { Card, CardBody } from "@ui/Card/Card";

interface Props {
  deliveryPartner: Pick<Business, "displayName"> | null;
  region: Pick<RegionV2, "id" | "friendlyName">;
  cardTitle?: string;
}

export const DeliveryPartnerCard: FC<Props> = ({
  deliveryPartner,
  region,
  cardTitle = "Delivery Partner",
}) => {
  return (
    <Card data-testid="DeliveryPartnerCard" title={cardTitle}>
      <CardBody>
        {deliveryPartner ? (
          <DescriptionListInCard>
            <LeftAlignedTerm
              term="Name"
              definition={deliveryPartner.displayName}
            />
          </DescriptionListInCard>
        ) : (
          <div>No delivery partner assigned to {region.friendlyName}</div>
        )}
      </CardBody>
    </Card>
  );
};
