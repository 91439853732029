import React, { ReactNode } from "react";
import { Link, LinkProps, LinkGetProps } from "@reach/router";
import { Disclosure } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import Logo from "@images/directBeerLogo.png";
import IconLogo from "@images/tavourIcon.png";

export interface StackedLayoutProps {
  children: ReactNode;
  navElements?: NavElement[];
  navRight?: ReactNode;
  footer?: ReactNode;
  homePath?: string;
  gray?: boolean;
}

export function StackedLayout({
  homePath = "/",
  navRight,
  navElements,
  children,
  footer,
  gray,
}: StackedLayoutProps) {
  const classes = classnames(
    "min-h-screen h-full flex flex-col",
    gray ? "bg-gray-100" : "bg-white"
  );

  return (
    <div className={classes}>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="max-w-screen-2xl mx-auto px-2 tablet:px-6 laptop:px-8">
              <div className="relative flex justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center tablet:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center tablet:items-stretch tablet:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to={homePath}>
                      <img
                        className="block laptop:hidden h-8 w-auto"
                        src={IconLogo}
                        alt="Direct Beer"
                      />
                      <img
                        className="hidden laptop:block h-10 w-auto"
                        src={Logo}
                        alt="Direct Beer"
                      />
                    </Link>
                  </div>

                  {navElements && (
                    <div className="hidden tablet:-my-px tablet:ml-6 tablet:flex tablet:space-x-8">
                      {navElements.map(({ icon, label, path }, index) => (
                        <NavLink to={path} key={index}>
                          {icon}
                          {label}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
                {navRight && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 tablet:static tablet:inset-auto tablet:ml-6 tablet:pr-0">
                    {navRight}
                  </div>
                )}
              </div>
            </div>

            <Disclosure.Panel className="tablet:hidden">
              <div className="pt-2 pb-4 space-y-1">
                {navElements &&
                  navElements.map(({ icon, label, path }, index) => (
                    <MobileNavLink to={path} key={index}>
                      {icon}
                      {label}
                    </MobileNavLink>
                  ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="flex-1 flex flex-col overflow-y-auto">
        <div className="flex-1 border-t border-gray-200 py-10">{children}</div>
        <div className="flex-none">{footer}</div>
      </div>
    </div>
  );
}

export type NavElement = {
  icon?: string;
  label: string;
  path: string;
};

const navLinkClasses = ({ isCurrent }: { isCurrent: boolean }) =>
  classnames(
    "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
    isCurrent
      ? "border-brand-500 text-gray-900"
      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
  );

function NavLink<TState>(props: Omit<LinkProps<TState>, "ref">) {
  const getProps = ({ isCurrent }: LinkGetProps) => ({
    className: navLinkClasses({ isCurrent }),
  });

  return <Link {...props} getProps={getProps} />;
}

const mobileNavLinkClasses = ({ isCurrent }: { isCurrent: boolean }) =>
  classnames(
    "block pl-3 pr-4 py-2 border-l-4 text-base font-medium",
    isCurrent
      ? "bg-brand-50 border-brand-500 text-brand-700"
      : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
  );

function MobileNavLink<TState>(props: Omit<LinkProps<TState>, "ref">) {
  const getProps = ({ isCurrent }: LinkGetProps) => ({
    className: mobileNavLinkClasses({ isCurrent }),
  });

  return <Link {...props} getProps={getProps} />;
}
