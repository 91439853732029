import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";
import { Card, CardBody, Footer } from "@components/ui/Card/Card";
import { toastError } from "@utilities/toast";
import { FromFundsAccountAutocomplete } from "./FromFundsAccountAutocomplete";
import { ToFundsAccountAutocomplete } from "./ToFundsAccountAutocomplete";
import {
  FundsTransferFieldsFragment,
  useAddFundsTransferMutation,
} from "@apollo/ops";
import { RouteComponentProps } from "@reach/router";
import { toCents } from "@utilities/currency";

const requiredRule = {
  value: true,
  message: "This field is required.",
};

/**
 * Constraints derived from Dwolla addenda object
 * See https://docs.dwolla.com/#addenda-object
 */
const statementDescriptorMaxLength = 80;
const statementDescriptorRegex = /^[\w\s\-.*()+?[\],;:#&='~!]+$/i;

/**
 * Constraint based on Dwolla transfer limits of $20k.
 */
const amountMax = 20000;

type InitateTransferFormData = {
  sourceFundsAccountId: number;
  destinationFundsAccountId: number;
  statementDescriptor: string;
  amount: number;
  breweryInvoiceNumber: string;
  retailerInvoiceNumber: string;
};

interface InitiateTransferProps extends RouteComponentProps {
  onInitiateTransfer: (transfer: FundsTransferFieldsFragment) => unknown;
}

export function InitiateTransfer({
  onInitiateTransfer,
}: InitiateTransferProps) {
  const [
    addFundsTransfer,
    addFundsTransferResult,
  ] = useAddFundsTransferMutation({
    onCompleted(data) {
      onInitiateTransfer && onInitiateTransfer(data.addFundsTransfer);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { touchedFields, errors },
    reset,
    setValue,
    clearErrors,
  } = useForm<InitateTransferFormData>({
    mode: "onChange",
  });

  const sourceFundsAccountId = watch("sourceFundsAccountId");
  const breweryInvoiceNumber = watch("breweryInvoiceNumber");
  const retailerInvoiceNumber = watch("retailerInvoiceNumber");

  /**
   * The statement descriptor should always be set to the concatenation
   * of the brewery + retailer invoice numbers.
   */
  useEffect(() => {
    setValue(
      "statementDescriptor",
      `${breweryInvoiceNumber ?? ""}::${retailerInvoiceNumber ?? ""}`
    );
  });

  /**
   * Trigger revalidation of the destinationFundsAccountId input when
   * sourceFundsAccountId changes and the destination input has been touched.
   */
  useEffect(() => {
    if (touchedFields.destinationFundsAccountId) {
      trigger("destinationFundsAccountId");
    }
  }, [trigger, sourceFundsAccountId, touchedFields.destinationFundsAccountId]);

  const onSubmit = async (data: InitateTransferFormData) => {
    try {
      await addFundsTransfer({
        variables: {
          data: {
            sourceId: data.sourceFundsAccountId,
            destinationId: data.destinationFundsAccountId,
            amountInCents: toCents(data.amount),
            statementDescriptor: data.statementDescriptor,
            breweryInvoiceNumber: data.breweryInvoiceNumber,
            retailerInvoiceNumber: data.retailerInvoiceNumber,
          },
        },
      });

      reset();

      //These fields should not change on submit,
      //so set them back to what they were before submit
      setValue("breweryInvoiceNumber", data.breweryInvoiceNumber);
      setValue("destinationFundsAccountId", data.destinationFundsAccountId);
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  const clearAll = () => {
    clearErrors();
    reset();
  };

  return (
    <Card title="Initiate Transfer">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <div className="grid grid-cols-1 gap-4 tablet:grid-cols-6">
            <div className="tablet:col-span-3">
              <label
                htmlFor="breweryInvoiceNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Brewery Invoice #
              </label>
              <div className="mt-1">
                <Input
                  {...register("breweryInvoiceNumber", {
                    required: requiredRule,
                  })}
                  error={errors.breweryInvoiceNumber?.message}
                />
              </div>
            </div>
            <div className="tablet:col-span-3">
              <label
                htmlFor="retailerInvoiceNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Retailer Invoice #
              </label>
              <div className="mt-1">
                <Input
                  {...register("retailerInvoiceNumber", {
                    required: requiredRule,
                  })}
                  error={errors.retailerInvoiceNumber?.message}
                />
              </div>
            </div>
            <div className="tablet:col-span-3 mb-1">
              <Controller
                name="sourceFundsAccountId"
                control={control}
                rules={{
                  required: requiredRule,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FromFundsAccountAutocomplete
                    label="From"
                    onSelect={onChange}
                    onBlur={onBlur}
                    selectedId={value}
                    error={errors.sourceFundsAccountId?.message}
                  />
                )}
              />
            </div>
            <div className="tablet:col-span-3 mb-1">
              <Controller
                name="destinationFundsAccountId"
                control={control}
                rules={{
                  required: requiredRule,
                  validate: (value) =>
                    value !== sourceFundsAccountId ||
                    `Must be different than "from" account`,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <ToFundsAccountAutocomplete
                    label="To"
                    selectedId={value}
                    onSelect={onChange}
                    onBlur={onBlur}
                    error={errors.destinationFundsAccountId?.message}
                  />
                )}
              />
            </div>
            <div className="tablet:col-span-2">
              <label
                htmlFor="amount"
                className="block text-sm font-medium text-gray-700"
              >
                Amount
              </label>
              <div className="mt-1">
                <Input
                  inlineLead="$"
                  {...register("amount", {
                    required: requiredRule,
                    min: {
                      value: 0.01,
                      message: "Must be at least $0.01",
                    },
                    max: {
                      value: amountMax,
                      message: `Must be less than ${amountMax}`,
                    },
                  })}
                  type="number"
                  step=".01"
                  error={errors.amount?.message}
                />
              </div>
            </div>
            <div className="tablet:col-span-4">
              <label
                htmlFor="statement_descriptor"
                className="block text-sm font-medium text-gray-700"
              >
                Statement Descriptor
              </label>
              <div className="mt-1">
                <Input
                  {...register("statementDescriptor", {
                    required: requiredRule,
                    pattern: {
                      value: statementDescriptorRegex,
                      message: `Acceptable characters are: a-Z, 0-9, and special characters - _ . ~ ! * ' ( ) ; : @ & = + $ , / ? % # [ ].`,
                    },
                    maxLength: {
                      value: statementDescriptorMaxLength,
                      message: `Must be less than ${statementDescriptorMaxLength} characters`,
                    },
                  })}
                  readOnly
                  tabIndex={-1}
                  error={errors.statementDescriptor?.message}
                />
              </div>
            </div>
          </div>
        </CardBody>
        <Footer className="text-right">
          <Button
            size="lg"
            disabled={addFundsTransferResult.loading}
            className="mr-5"
            kind="secondary"
            type="button"
            onClick={clearAll}
          >
            Clear
          </Button>
          <Button
            size="lg"
            type="submit"
            disabled={addFundsTransferResult.loading}
          >
            Submit
          </Button>
        </Footer>
      </form>
    </Card>
  );
}
