import { toast } from "@utilities/toast";
import { FormEvent, useCallback, useState } from "react";
import { AnySchema } from "joi";
import { InMemoryCache, MutationHookOptions } from "@apollo/client";
import { get } from "lodash";
import { MessageSeverity } from "../shared/types/Severity";
type ErrorMessage = string;
export interface FormHookResult {
  onSubmit: () => Promise<void>;
  onChange: (e: FormEvent<Element> | any) => void;
  formValues: Record<string, any>;
  loading: boolean;
  mutationError: any;
  validationResults: { [name: string]: ErrorMessage[] } | null;
}

export interface FormHookOptions {
  useMutationHook: () => any;
  initialFormValues?: Record<string, any>;
  joiValidationSchema?: AnySchema;
  onSuccess: (mutationResult: any) => any;
  mutationProps?: MutationHookOptions;
  getMutationVars?: (formVals: Record<string, any>) => Record<string, any>;
  updateCache?: (cache: InMemoryCache, backendResponse: any) => void;
}

export const useFormHook = ({
  useMutationHook,
  initialFormValues = {},
  joiValidationSchema,
  onSuccess,
  mutationProps = {},
  getMutationVars = (formVals: Record<string, any>) => formVals,
  updateCache,
}: FormHookOptions): FormHookResult => {
  const mutationHookProps = useMutationHook();
  const [mutate, { loading, error: mutationError }] = mutationHookProps;
  const [formValues, setFormValues] = useState<any>(initialFormValues);

  const [validationResults, setValidationResults] = useState<{
    [name: string]: ErrorMessage[];
  } | null>(null);

  const onChange = (e: InputEvent) => {
    if (!e.target) {
      return;
    }

    const name = get(e, "target.name");
    const val =
      get(e, "target.type") === "checkbox"
        ? (e.target as any).checked
        : (e.target as any).value;
    setFormValues((formValues: Record<string, any>) => {
      return { ...formValues, [name]: val };
    });
  };

  const onSubmit = useCallback(async () => {
    const validationResults = joiValidationSchema
      ? joiValidationSchema.validate(formValues)
      : null;

    if (validationResults) {
      const validationResultsReport = validationResults?.error?.details.reduce<
        Record<string, ErrorMessage[]>
      >((acc, validationError): any => {
        if (validationError.path[0] in acc) {
          acc[validationError.path[0]].push(validationError.message);
        } else {
          acc[validationError.path[0]] = [validationError.message];
        }
        return acc;
      }, {});

      setValidationResults(
        validationResultsReport as Record<string, ErrorMessage[]>
      );
    }
    if (validationResults && validationResults.error) {
      return;
    }
    try {
      const mutationResult = await mutate({
        variables: getMutationVars(formValues),
        update: (cache: InMemoryCache, backendResult: any) => {
          if (updateCache) {
            updateCache(cache, backendResult);
          }
        },
        ...mutationProps,
      });
      if (onSuccess) {
        onSuccess(mutationResult);
      }
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: "Error",
        detail: error.message,
      });
    }
  }, [
    joiValidationSchema,
    formValues,
    mutate,
    getMutationVars,
    mutationProps,
    onSuccess,
    updateCache,
  ]);

  return {
    onSubmit,
    onChange,
    formValues,
    loading,
    mutationError,
    validationResults,
  };
};

export default useFormHook;
