import { useCallback, useEffect, useMemo, useState } from "react";
import { ApolloError, MutationUpdaterFn } from "@apollo/client";
import {
  BankAccount as BankAccountType,
  BankAccountFieldsFragment,
  BusinessActiveBankAccountsDocument,
  BusinessActiveBankAccountsQuery,
  RemoveBankAccountMutation,
  useBusinessActiveBankAccountsQuery,
  useRemoveBankAccountMutation,
} from "@apollo/ops";
import { useDialogger } from "@components/Dialogger";
import { MessageSeverity } from "@shared/types/Severity";
import {
  BackendErrorCodes,
  getBackendErrorCode,
  getMessageToUserFromBackendError,
} from "@utilities/i18n";
import { toast } from "@utilities/toast";
import { BankAccountStatusFragment } from "../types/BankAccount";

export const useReadBusinessBankAccounts = (
  businessEntityId: number
): {
  bankAccountsData: Array<BankAccountStatusFragment>;
  error: ApolloError | undefined;
  loading: boolean;
} => {
  const { data, loading, error } = useBusinessActiveBankAccountsQuery({
    variables: {
      businessEntityId: businessEntityId,
    },
  });

  const bankAccountsData = useMemo(() => {
    return data?.business.bankAccount ? [data.business.bankAccount] : [];
  }, [data?.business]);

  return { bankAccountsData, error, loading };
};

export const useRemoveOrganizationBankAccount = (
  businessEntityId: number
): {
  onRemove: (
    bankAccount: BankAccountType,
    skipDialog?: boolean,
    onDone?: () => void
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const updateCache: MutationUpdaterFn<RemoveBankAccountMutation> = (cache) => {
    const oldVals = cache.readQuery<
      BusinessActiveBankAccountsQuery,
      { businessEntityId: number }
    >({
      query: BusinessActiveBankAccountsDocument,
      variables: {
        businessEntityId,
      },
    });
    cache.writeQuery({
      query: BusinessActiveBankAccountsDocument,
      variables: {
        businessEntityId,
      },
      data: {
        business: {
          ...(oldVals ? oldVals.business : {}),
          id: businessEntityId,
          bankAccount: null,
        },
      },
    });
  };
  const { onRemove, loading, error } = useRemoveBankAccount({ updateCache });

  return { onRemove, loading, error };
};

export const useRemoveBankAccount = ({
  updateCache = undefined,
}: {
  updateCache?: MutationUpdaterFn<RemoveBankAccountMutation> | undefined;
}) => {
  const [
    removeBankAccount,
    { loading, error },
  ] = useRemoveBankAccountMutation();
  const { confirm } = useDialogger();

  const onRemove = useCallback(
    (
      bankAccount: {
        id: number;
        mask: string;
        institutionName: string | null;
        name: string | null;
      },
      skipDialog?: boolean,
      onDone?: () => void
    ) => {
      const remove = async () => {
        try {
          await removeBankAccount({
            variables: {
              id: bankAccount.id,
              force: false,
            },
            update: updateCache,
          });
          toast.current?.show({
            severity: MessageSeverity.success,
            summary: "Success",
            detail: "Bank account removed successfully",
          });
          if (onDone) {
            onDone();
          }
        } catch (e) {
          console.error(e);
          const errorCode = getBackendErrorCode(e);
          //The design showed a very specific popup when error failed because of bankaccount having pending transfers.
          //This is why I handle this as a special case.
          if (errorCode === BackendErrorCodes.BusinessRule) {
            showErrorDeleting(e.graphQLErrors[0].message);
            return;
          }
          const message = getMessageToUserFromBackendError(e);
          toast.current?.show({
            severity: MessageSeverity.error,
            summary: "Error removing bank account",
            detail: message,
          });
        }
      };

      if (skipDialog) {
        remove();
        return;
      }

      const showErrorDeleting = (msg: string) =>
        confirm({
          title: "Removal failed",
          description: msg,
          hideCancel: true,
        });

      confirm({
        onConfirm: remove,
        destructive: true,
        title: "Remove bank account",
        description: "Are you sure you want to remove the bank account?",
        confirmText: "Remove bank account",
      });
    },
    [removeBankAccount, updateCache, confirm]
  );
  return { onRemove, loading, error };
};

interface BankAccountManualConfirmResult {
  bankAccount: BankAccountFieldsFragment | BankAccountType | null;
  isShowingManualConfirmationModal: boolean;
  onAdd: (bankAccount: BankAccountFieldsFragment) => void;
  closeManualConfirmationModal: () => void;
  //This callback is after the user has Authorized the use of a bank account by click on a button "I authorize"
  onAuthorize: (bankAccount: BankAccountType) => void;
}

interface BankAccountManualConfirmOptions {
  bankAccountsData: Array<BankAccountStatusFragment>;
}

export const useBankAccountManualConfirm = ({
  bankAccountsData,
}: BankAccountManualConfirmOptions): BankAccountManualConfirmResult => {
  const [bankAccount, setBankAccount] = useState<
    BankAccountFieldsFragment | BankAccountType | null
  >(null);

  const [
    isShowingManualConfirmationModal,
    setIsShowingManualConfirmationModal,
  ] = useState(false);

  const onAdd = (bankAccount: BankAccountFieldsFragment) => {
    if (!bankAccount.hasOnDemandAuthorization) {
      setBankAccount(bankAccount);
      setIsShowingManualConfirmationModal(true);
    }
  };

  const closeManualConfirmationModal = useCallback(() => {
    setIsShowingManualConfirmationModal(false);
  }, [setIsShowingManualConfirmationModal]);

  const onAuthorize = useCallback(
    (bankAccount: BankAccountType) => {
      setBankAccount(bankAccount);
      setIsShowingManualConfirmationModal(true);
    },
    [setIsShowingManualConfirmationModal, setBankAccount]
  );

  // When manual confirmation is done, another component updates Apollo cache. I listen to changes in Apollo data and
  // Close the confirmation dialog.
  useEffect(() => {
    if (bankAccount?.id) {
      const updatedBankAccount = bankAccountsData.find(
        (b: BankAccountStatusFragment) => b.id === bankAccount.id
      );
      if (updatedBankAccount?.hasOnDemandAuthorization) {
        setIsShowingManualConfirmationModal(false);
        setBankAccount(null);
      }
    }
  }, [bankAccount?.id, bankAccountsData]);
  return {
    bankAccount,
    isShowingManualConfirmationModal,
    onAdd,
    closeManualConfirmationModal,
    onAuthorize,
  };
};
