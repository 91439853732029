import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "@ui/Button";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { AmountInput } from "@components/Input/AmountInput";
import { WarningAlert } from "@components/ui/Alert";
import { formatAmountInCents, toCents, toDollars } from "@utilities/currency";

export type UpdatePlatformFeeSubmitData = {
  /**
   * The platformFeeOverride can be removed by submitting a null value
   */
  platformFeeOverride: number | null;
};

type UpdatePlatformFeeFormData = {
  platformFeeOverride: string;
};

type UpdatePlatformFeeFormProps = {
  onSubmit: (data: UpdatePlatformFeeSubmitData) => void | Promise<void>;
  defaultValues?: Partial<UpdatePlatformFeeSubmitData>;
  platformFeeCalculated: number;
  platformFeeOverride: number | null;
};

export function UpdatePlatformFeeForm({
  onSubmit,
  defaultValues = {},
  platformFeeCalculated,
  platformFeeOverride,
}: UpdatePlatformFeeFormProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<UpdatePlatformFeeFormData>({
    defaultValues: {
      platformFeeOverride: defaultValues.platformFeeOverride
        ? toDollars(defaultValues.platformFeeOverride).toString()
        : "",
    },
  });

  const wrappedOnSubmit = async (data: UpdatePlatformFeeFormData) => {
    const submitData = {
      platformFeeOverride:
        data.platformFeeOverride === ""
          ? null
          : toCents(data.platformFeeOverride),
    };

    try {
      await onSubmit(submitData);
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setValue("platformFeeOverride", "");
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        {platformFeeOverride && (
          <WarningAlert
            title="Platform fee overridde"
            description={`The calculated platform fee of ${formatAmountInCents(
              platformFeeCalculated
            )} has been manually overridden.`}
          >
            <Button kind="secondary" onClick={handleClear}>
              Clear override
            </Button>
          </WarningAlert>
        )}
        <AmountInput
          register={register}
          name="platformFeeOverride"
          label="Platform Fee"
          required={false}
          placeholder={toDollars(platformFeeCalculated).toString()}
          error={errors.platformFeeOverride?.message}
        />
        <div>
          <Button size="lg" type="submit" disabled={isSubmitting}>
            Update platform fee
          </Button>
        </div>
      </div>
    </form>
  );
}
