import React from "react";
import { formatAmountInCents } from "@utilities/currency";
import { TableSummary } from "@ui/TableSummary";
import { ProducerPurchaseOrderItemsQuery } from "@apollo/gen/generatedOperations";

type ProducerPurchaseOrderItemsTotalsProps = {
  order: ProducerPurchaseOrderItemsQuery["order"];
};

export function ProducerPurchaseOrderItemsTotals({
  order,
}: ProducerPurchaseOrderItemsTotalsProps) {
  const orderTotals: Record<string, string> = {
    "Order Total": formatAmountInCents(order.amountInCents),
  };

  return <TableSummary rows={orderTotals} />;
}
