import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { DropdownProps } from "primereact/dropdown";
import styled from "styled-components";
import { FieldError } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon, XCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";

export type DropdownOption =
  | string
  | { label: ReactNode; value: string | number };
interface DropDownCmpProps extends Omit<DropdownProps, "onChange"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => any;
  fieldLabel?: string;
  error?: FieldError;
  options: DropdownOption[];
  value: string;
  className?: string;
}
export const DropdownCmp: FC<DropDownCmpProps> = ({
  options,
  value,
  onChange,
  fieldLabel = "",
  className,
  placeholder,
  disabled = false,
}) => {
  const [selected, setSelected] = useState<DropdownOption | undefined>("");
  const onClear = () => {
    setSelected("");
    onChange(undefined);
  };
  const onChangeHandler = (newVal: DropdownOption) => {
    setSelected(newVal);

    if (onChange) {
      const returnVal = typeof newVal === "string" ? newVal : newVal.value;
      onChange(returnVal);
    }
  };
  useEffect(() => {
    const selectedOption = options?.find((option) => {
      const optionVal = typeof option === "string" ? option : option.value;
      return optionVal === value;
    });
    setSelected(selectedOption);
  }, [value, options]);

  const selectedLabel =
    typeof selected === "object" ? selected.label : selected || null;

  return (
    <Listbox value={selected} onChange={onChangeHandler} disabled={disabled}>
      {({ open }) => (
        <>
          {fieldLabel && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {fieldLabel}
            </Listbox.Label>
          )}
          <div className={`relative`}>
            <Listbox.Button
              className={`bg-white relative w-full border rounded-sm shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-blue-500 tablet:text-sm ${className} ${
                className?.includes("border-red") ? "" : "border-gray-300"
              }`}
            >
              {selectedLabel ? (
                <div className="flex items-center justify-between">
                  <div className="truncate overflow-ellipsis">
                    {selectedLabel}
                  </div>
                  {disabled ? null : (
                    <XCircleIcon
                      className="w-4 h-4 flex-none item cursor-pointer mx-1 text-gray-400"
                      onClick={onClear}
                    />
                  )}
                </div>
              ) : (
                <span className="text-gray-400">{placeholder}</span>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none tablet:text-sm"
              >
                {options &&
                  options.map((option) => {
                    const val =
                      typeof option === "string" ? option : option.value;
                    const label =
                      typeof option === "string" ? option : option.label;

                    return (
                      <Listbox.Option
                        key={val}
                        className={({ active }) =>
                          classNames(
                            active ? "text-white bg-blue-500" : "text-gray-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {label}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export const Dropdown = styled(DropdownCmp)`
  min-height: 36px;
`;
