import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Button, ButtonProps } from "./Button";
import { RefreshIcon } from "@heroicons/react/solid";

type RefreshButtonProps = ButtonProps & {
  loading: boolean;
};

export function RefreshButton({
  loading,
  children,
  ...rest
}: RefreshButtonProps) {
  return (
    <Button disabled={loading} {...rest}>
      <RefreshIcon className={refreshIconClasses(loading)} />{" "}
      {children && <div className="ml-3">{children}</div>}
    </Button>
  );
}

const refreshIconClasses = (loading: boolean) =>
  ctl(`
    w-6
    h-6
    ${loading && "animate-spin"}
 `);
