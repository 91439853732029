import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  AdminLogisticsPartnerDocument,
  AdminLogisticsPartnerQuery,
  AdminLogisticsPartnerQueryVariables,
} from "@apollo/ops";
import { AdminBusinessDetailsCard } from "@components/Business/BusinessDetailsCard";
import { Page, PageHeading } from "@components/ui/Page";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { UpdateLogisticsPartnerModal } from "@components/LogisticsPartner/UpdateLogisticsPartnerModal";
import { InviteUserToOrganizationModal } from "../../components/InviteUserToOrganizationModal/InviteUserToOrganizationModal";
import { organizationPath } from "@routes";
import { ButtonLink } from "@components/ui/Button";
import { DeleteLogisticsPartnerButton } from "./DeleteLogisticsPartnerButton";

interface LogisticsPartnerProps extends RouteComponentProps {
  logisticsPartnerId?: string;
}

type WrappedProps = WithQueryComponentProps<
  LogisticsPartnerProps,
  AdminLogisticsPartnerQuery,
  AdminLogisticsPartnerQueryVariables
>;

function AdminLogisticsPartner({ query }: WrappedProps) {
  const { logisticsPartner } = query?.data || null;

  return (
    logisticsPartner && (
      <Page
        heading={
          <PageHeading
            title={logisticsPartner.displayName}
            actions={
              <>
                {logisticsPartner.canDelete && (
                  <DeleteLogisticsPartnerButton
                    logisticsPartnerId={logisticsPartner.id}
                  />
                )}
                <ButtonLink
                  to={"/" + organizationPath(logisticsPartner.organization)}
                  kind="secondary"
                  size="sm"
                >
                  View as customer
                </ButtonLink>
                <InviteUserToOrganizationModal
                  organization={logisticsPartner.organization}
                  buttonProps={{ kind: "secondary" }}
                />
                <UpdateLogisticsPartnerModal
                  logisticsPartnerId={logisticsPartner.organization.id}
                />
              </>
            }
          />
        }
      >
        <div className="mt-8">
          <div className="space-y-4">
            <AdminBusinessDetailsCard
              businessEntityId={logisticsPartner.id}
              fein={null}
              address={logisticsPartner.address}
            />
          </div>
        </div>
      </Page>
    )
  );
}

export const AdminLogisticsPartnerPage = withQuery<
  LogisticsPartnerProps,
  AdminLogisticsPartnerQuery,
  AdminLogisticsPartnerQueryVariables
>(AdminLogisticsPartnerDocument, {
  mapPropsToOptions: (props) => ({
    variables: { logisticsPartnerId: parseInt(props.logisticsPartnerId!) },
  }),
})(AdminLogisticsPartner);
