import React from "react";
import {
  useAdminAddFirstRetailerLocationMutation,
  AdminAddFirstRetailerLocationMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { RetailerForm, RetailerFormData } from "./AddRetailerForm";

type AddRetailerProps = {
  onAdd?: (data: AdminAddFirstRetailerLocationMutation) => void;
};

export function AddRetailer({ onAdd }: AddRetailerProps) {
  const [addFirstRetailerLocation] = useAdminAddFirstRetailerLocationMutation();

  const handleSubmit = async ({
    orgName,
    locationName,
    region,
    forceDwollaBusinessCustomer,
  }: RetailerFormData) => {
    try {
      const result = await addFirstRetailerLocation({
        variables: {
          organizationName: orgName,
          locationName: locationName,
          region,
          forceDwollaBusinessCustomer,
        },
      });

      if (result.data && onAdd) {
        onAdd(result.data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Added retailer ${orgName}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return <RetailerForm submitText="Add" onSubmit={handleSubmit} />;
}
