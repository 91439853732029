import React from "react";
import { useAdminPreSaleProducerSummaryQuery } from "@apollo/gen/generatedOperations";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";
import { Card, Footer } from "@ui/Card/Card";
import { LoadingIcon } from "@ui/Spinner";
import { PreSaleProducerFulfillmentToggle } from "@components/PreSaleProductsTable/PreSaleProductFulfillmentToggle/ProductFulfillmentExclusionReasonDropdown/Producer/PreSaleProducerFulfillmentToggle";

export const AdminPreSaleProducerSummaryTable = ({
  preSaleId,
}: {
  preSaleId: number;
}) => {
  const { data, error } = useAdminPreSaleProducerSummaryQuery({
    variables: {
      preSaleId,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    throw new Error();
  }

  if (data) {
    const {
      preSale: { preSaleProducerSummary },
    } = data;

    return (
      data && (
        <>
          {preSaleProducerSummary.map((producerData) => (
            <Card
              key={producerData.producerId}
              title={producerData.producer}
              className={"mb-8"}
            >
              <DescriptionListInCard>
                <dl>
                  {producerData.packageTypes.map((packageType) => (
                    <LeftAlignedTerm
                      key={packageType.packageType}
                      term={packageType.packageType}
                      definition={packageType.count}
                    />
                  ))}
                  <LeftAlignedTerm
                    term="Total Units"
                    definition={producerData.totalUnits}
                  />
                </dl>
                <Footer>
                  <PreSaleProducerFulfillmentToggle
                    producerId={producerData.producerId}
                    preSaleId={preSaleId}
                    exclusionReason={producerData.exclusionReason}
                    key={producerData.producer}
                    isExcluded={producerData.isExcluded}
                  />
                </Footer>
              </DescriptionListInCard>
            </Card>
          ))}
        </>
      )
    );
  } else {
    return <LoadingIcon />;
  }
};
