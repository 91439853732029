import React from "react";
import classNames from "classnames";
import { Dropdown } from "@ui/Dropdown";
import { useRegions } from "@hooks/useRegions/useRegions";
import { FieldError } from "react-hook-form";

type RegionsDropdownProps = {
  onChange: (value: number | null) => unknown;
  value?: number | null;
  error?: FieldError;
};

export function RegionsDropdown({
  onChange,
  value,
  error,
}: RegionsDropdownProps) {
  const regions = useRegions()?.regions ?? [];

  //Values in the dropdown are converted to and from strings because it doesn't support numbers
  return (
    <Dropdown
      style={{ width: "100%" }}
      value={value?.toString() || ""}
      options={regions.map((region) => ({
        value: region.id.toString(),
        label: region.friendlyName,
      }))}
      onChange={(e) => {
        if (typeof e === "undefined") {
          onChange(null);
        } else {
          onChange(Number(e));
        }
      }}
      className={classNames({
        "border-red-300": error,
        "border-1": error,
        "text-red-600": error,
      })}
      placeholder="Select a region"
    />
  );
}
