import React from "react";
import classNames from "classnames";
import { Dropdown } from "@ui/Dropdown";
import { FieldError } from "react-hook-form";
import { useGeographicalStates } from "@hooks/useStates/useGeographicalStates";

type GeographicalStatesDropdownProps = {
  onChange: (value: number | null) => unknown;
  value?: number | null;
  error?: FieldError;
};

export function GeographicalStatesDropdown({
  onChange,
  value,
  error,
}: GeographicalStatesDropdownProps) {
  const geographicalStates = useGeographicalStates();

  //Values in the dropdown are converted to and from strings because it doesn't support numbers
  return (
    <Dropdown
      style={{ width: "100%" }}
      value={value?.toString() || ""}
      options={geographicalStates.map((state) => ({
        value: state.id.toString(),
        label: state.name,
      }))}
      onChange={(e) => {
        if (typeof e === "undefined") {
          onChange(null);
        } else {
          onChange(Number(e));
        }
      }}
      className={classNames({
        "border-red-300": error,
        "border-1": error,
        "text-red-600": error,
      })}
      placeholder="Select a State"
    />
  );
}
