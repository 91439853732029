import React from "react";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import {
  LeftAlignedTerm,
  DescriptionListInCard,
} from "@components/ui/DescriptionList";
import { PreSaleDisplayFragment } from "@apollo/ops";
import { formatAsDate } from "@utilities/formatDate";
import { Card } from "@components/ui/Card/Card";
interface PreSaleInfoCmpProps {
  preSale: PreSaleDisplayFragment;
  className: string;
}
const PreSaleInfoCmp = ({ preSale, className }: PreSaleInfoCmpProps) => {
  return (
    <Card className={className}>
      <DescriptionListInCard>
        <LeftAlignedTerm term="ID" definition={preSale.number} />
        <LeftAlignedTerm
          term="Region"
          definition={preSale.regionV2.friendlyName}
        />
        <LeftAlignedTerm
          term={"Start"}
          definition={formatAsDate(preSale.start)}
        />
        <LeftAlignedTerm term="End" definition={formatAsDate(preSale.end)} />
        <LeftAlignedTerm term="# Orders" definition={preSale.orderCount} />
      </DescriptionListInCard>
    </Card>
  );
};

export const PreSaleInfo = withErrorBoundary(PreSaleInfoCmp);
