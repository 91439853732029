import React from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { adminPaths } from "@routes";
import { AdminPreSalesPage } from "./AdminPreSalesPage/AdminPreSalesPage";
import { AdminPreSaleRouter } from "./AdminPreSaleRouter/AdminPreSaleRouter";
import { AdminPreSaleImportJobsContainer } from "./AdminPreSaleImportJobsContainer/AdminPreSaleImportJobsContainer";
import { AdminOrderRouter } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderRouter";

function AdminPreSalesContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminPreSalesPage path="/" />
      <AdminPreSaleRouter path="/:preSaleIdParam/*" />
      <AdminOrderRouter path="/:preSaleIdParam/orders/:orderIdParam/*" />
      <AdminPreSaleImportJobsContainer
        path={`${adminPaths.preSales.importJobs.root}/*`}
      />

      <Redirect from="*" to="/not-found" />
    </Router>
  );
}

export default AdminPreSalesContainer;
