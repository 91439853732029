import React from "react";

type TableSummaryProps = {
  rows: Record<string, string>;
};

/**
 * Component to show a summary of a table.
 * @returns
 */
export const TableSummary = ({ rows }: TableSummaryProps) => (
  <div className="flex flex-row-reverse">
    <table>
      <tbody>
        {Object.keys(rows).map((key) => {
          return (
            <tr key={key}>
              <td className="text-right px-4 font-bold">{key}</td>
              <td>{rows[key]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);
