import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { useProducerBalanceAccountQuery } from "@apollo/ops";
import { formatAmountInCents } from "@utilities/currency";

type ProducerBalanceProps = {
  businessId: number;
};

export function ProducerBalance({ businessId }: ProducerBalanceProps) {
  const { data, loading, error } = useProducerBalanceAccountQuery({
    variables: {
      businessEntityId: businessId,
    },
  });

  useErrorHandler(error);

  if (
    loading ||
    data?.business.balanceAccount?.balance === null ||
    data?.business.balanceAccount?.balance === undefined
  ) {
    return null;
  }

  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 tablet:grid-cols-3">
      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden tablet:p-6">
        <dt className="text-sm font-medium text-gray-500 truncate">
          Your Account Balance
        </dt>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">
          {formatAmountInCents(data.business.balanceAccount.balance)}
        </dd>
      </div>
    </dl>
  );
}
