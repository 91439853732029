import React from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { AdminProducersPage } from "./AdminProducersPage/AdminProducersPage";
import { AdminProducerPage } from "./AdminProducerPage/AdminProducerPage";

function AdminProducersContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminProducersPage path="/" />
      <AdminProducerPage path=":producerId/*" />
      <Redirect from="*" to="/not-found" />
    </Router>
  );
}

export default AdminProducersContainer;
