import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { AdminRegionsPage } from "./AdminRegionsPage/AdminRegionsPage";
import { AdminRegionPage } from "./AdminRegionPage/AdminRegionPage";

function AdminRegionsContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminRegionsPage path="/" />
      <AdminRegionPage path="/:regionId/*" />
      <Redirect from="*" to="/not-found" />
    </Router>
  );
}

export default AdminRegionsContainer;
