import React, { FC } from "react";
import {
  CellProps,
  Column,
  TableInstance,
  useFlexLayout,
  useSortBy,
  useTable,
} from "react-table";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";
import { Table } from "@ui/Table";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { FundsTransferDetailsFragment } from "@apollo/ops";
import { DwollaTransferStatusBadge } from "@components/Order/OrderTransferStatusBadge";

export interface FundsTransferTableProps {
  fundsTransfers: FundsTransferDetailsFragment[];
}

const formatFundName = (
  fundsTransfer: FundsTransferDetailsFragment,
  field: string
) => {
  const fund =
    field === "source" ? fundsTransfer.source : fundsTransfer.destination;
  const accountDesignation = fund.business.displayName;

  // only show the label for the retailer's account a.k.a. the source account
  // they're always the source since this view shows payments only
  return field === "source"
    ? `${accountDesignation}: ${fund.label}`
    : accountDesignation;
};

const columns: Array<Column<FundsTransferDetailsFragment>> = [
  {
    Header: "Date",
    Cell: ({ value }) => formatAsDate(new Date(value)),
    accessor: "createdAt",
    width: 60,
  },
  {
    Header: "Order ID",
    accessor: "orderId",
    width: 60,
  },
  {
    Header: "From",
    accessor: (fundsTransfer) => formatFundName(fundsTransfer, "source"),
  },
  {
    Header: "To",
    accessor: (fundsTransfer) => formatFundName(fundsTransfer, "destination"),
  },
  {
    Header: "Status",
    Cell: ({ row }: CellProps<FundsTransferDetailsFragment, "string">) => (
      <DwollaTransferStatusBadge status={row.original.status} />
    ),
    accessor: "status",
  },
  {
    Header: "Amount",
    Cell: ({ value }: CellProps<FundsTransferDetailsFragment, number>) =>
      formatAmountInCents(value),
    accessor: "amount",
  },
];

export const StoreSalesOrderFundsTransferTableCmp: FC<FundsTransferTableProps> = ({
  fundsTransfers,
}: FundsTransferTableProps) => {
  const tableInstance = useTable(
    {
      columns,
      data: fundsTransfers,
    },
    useFlexLayout,
    useSortBy
  ) as TableInstance<FundsTransferDetailsFragment>;

  return <Table instance={tableInstance} />;
};

export const StoreSalesorderFundsTransferTable = withErrorBoundary(
  StoreSalesOrderFundsTransferTableCmp
);
