/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { createContext, useContext } from "react";

export interface IAdminGlobalSearchContext {
  launch: () => void;
}

/*
 * The Typescript non-null assertion operator "!" here prevents us from having
 * to a assign a bogus default implementation.
 */
export const AdminGlobalSearchContext = createContext<IAdminGlobalSearchContext>(
  null!
);

export const useAdminGlobalSearch = () => useContext(AdminGlobalSearchContext);
