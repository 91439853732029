import { ProducersFilterData } from "@apollo/gen/generatedOperations";
import { Controller, useForm } from "react-hook-form";
import { AllProducersAutocomplete } from "@components/Producer/ProducerAutocomplete/ProducerAutocomplete";
import { Button } from "@ui/Button";
import React from "react";

export type FilterData = ProducersFilterData;

type AdminProducersFilterProps = {
  onFilter: (data: FilterData) => void | Promise<void>;
  defaultValues?: Partial<FilterData>;
  submitText?: string;
};

export function AdminProducersFilter({
  onFilter,
  defaultValues,
}: AdminProducersFilterProps) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FilterData>({
    defaultValues,
  });

  const wrappedOnSubmit = async ({ name, ...data }: FilterData) => {
    try {
      await onFilter({
        name: name ? name : null,
        ...data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)} autoComplete="off">
      <div className="grid laptop:grid-cols-3 gap-3 items-end">
        <div>
          <Controller
            name="producerId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <AllProducersAutocomplete
                label=""
                onSelect={onChange}
                onBlur={onBlur}
                selectedId={value}
                error={errors.producerId?.message}
              />
            )}
          />
        </div>
        <div>
          <Button type="submit" disabled={isSubmitting} kind="secondary">
            Filter
          </Button>
        </div>
      </div>
    </form>
  );
}
