import React from "react";
import { Card } from "@components/ui/Card/Card";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";

type AdminBreweryDetailsCard = {
  globalBreweryId: number | null;
  rating: number | null;
};

export function AdminBreweryDetailsCard({
  globalBreweryId,
  rating,
}: AdminBreweryDetailsCard) {
  return (
    <Card title="Brewery Information">
      <DescriptionListInCard>
        <LeftAlignedTerm
          term="Global Brewery ID"
          definition={globalBreweryId ? globalBreweryId : "No Brewery ID Added"}
        />
        <LeftAlignedTerm
          term="Rating"
          definition={rating ? rating : "No rating Added"}
        />
      </DescriptionListInCard>
    </Card>
  );
}
