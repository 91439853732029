import React from "react";
import { RouteComponentProps } from "@reach/router";
import { NumberParam, useQueryParams } from "use-query-params";
import {
  AdminRetailersQuery,
  AdminRetailersDocument,
  AdminRetailersQueryVariables,
} from "@apollo/ops";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { AdminRetailersFilter, FilterData } from "./AdminRetailersFilter";
import { Page, PageHeading } from "@components/ui/Page";
import { AdminRetailersTable } from "./AdminRetailersTable";
import { AddRetailerModal } from "@components/Retailer/AddRetailer/AddRetailerModal";

export function AdminRetailersPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    retailerId: NumberParam,
    regionId: NumberParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    AdminRetailersQuery,
    AdminRetailersQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        filters,
      },
    },
    query: AdminRetailersDocument,
    mapDataToTotal({ paginatedRetailers }) {
      return paginatedRetailers.total;
    },
  });

  const { data } = queryResult;

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Retailers"
          actions={<AddRetailerModal onAdd={() => queryResult.refetch()} />}
        />
      }
    >
      <div className="mb-3">
        <AdminRetailersFilter
          onFilter={updateFilters}
          defaultValues={filters}
        />
      </div>
      {data && (
        <AdminRetailersTable
          data={data.paginatedRetailers.results}
          pagination={pagination}
        />
      )}
    </Page>
  );
}
