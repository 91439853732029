import React, { useMemo } from "react";
import { Column, TableInstance, useSortBy, useTable } from "react-table";
import { Table } from "@ui/Table";
import {
  AdminRegionsDocument,
  AdminRegionsQuery,
  AdminRegionsTableRowDataFragment,
} from "@apollo/ops";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { RouteComponentProps } from "@reach/router";
import { useErrorHandler } from "react-error-boundary";
import { AdminProducerRegionLink } from "@admin/components/AdminRegionLink";
import { Label } from "@components/ui/Input";
import { InformationCircleIcon } from "@heroicons/react/solid";

interface AdminProducerRegionsPageProps extends RouteComponentProps {
  parsedProducerId: number;
}

type WrappedProps = WithQueryComponentProps<
  AdminProducerRegionsPageProps,
  AdminRegionsQuery
>;

function AdminProducerRegionsPageBase({
  parsedProducerId,
  query,
}: WrappedProps) {
  const { data, error } = query;

  useErrorHandler(error);

  return (
    <div className="mt-8">
      <Label className="mb-8">
        <InformationCircleIcon
          className="h-5 w-5 mr-1 inline"
          aria-hidden="true"
        />
        Any platform fees you configure here will override the global values
      </Label>
      {data && (
        <RegionsTable producerId={parsedProducerId} regions={data.regions} />
      )}
    </div>
  );
}

type RegionsTableProps = {
  producerId: number;
  regions: Array<AdminRegionsTableRowDataFragment>;
};

function RegionsTable({ producerId, regions }: RegionsTableProps) {
  const columns: Array<Column<AdminRegionsTableRowDataFragment>> = useMemo(
    () => [
      {
        Header: "Name",
        Cell: ({ row }) => (
          <>
            <AdminProducerRegionLink
              producerId={producerId}
              region={row.original}
            />{" "}
            <span>(ID: {row.original.id})</span>
          </>
        ),
        accessor: "friendlyName",
      },
      {
        Header: "State",
        accessor: (row) => row.state.name,
        id: "state",
      },
    ],
    [producerId]
  );

  const tableInstance = useTable(
    {
      columns,
      data: regions,
    },
    useSortBy
  ) as TableInstance<AdminRegionsTableRowDataFragment>;

  return <Table instance={tableInstance} />;
}

export const AdminProducerRegionsPage = withQuery<
  AdminProducerRegionsPageProps,
  AdminRegionsQuery
>(AdminRegionsDocument)(AdminProducerRegionsPageBase);
