import { useAuth } from "@shared/auth";
import React from "react";

export function LogoutButton() {
  const { logout } = useAuth();

  return (
    <button
      type="button"
      className="w-full text-left py-1 link-white"
      onClick={logout}
    >
      Sign out
    </button>
  );
}
