import React from "react";
import {
  OrderType,
  useAdminOrderItemsQuery,
  useAdminPreSaleQuery,
} from "@apollo/ops";
import { Page } from "@components/ui/Page";
import { LoadingPage } from "@components/LoadingPage";
import { AdminOrderPageHeading } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderPageHeading";
import { AdminOrderRouteProps } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderRouteProps";
import { AdminPreSaleOrderItemsTable } from "./AdminPreSaleOrderItemsTable/AdminPreSaleOrderItemsTable";
import { AdminPreSaleOrderItemsTotals } from "@admin/AdminPreSalesContainer/AdminPreSaleRouter/AdminPreSalePage/AdminPreSaleOrders/AdminPreSaleOrderItemsPage/AdminPreSaleOrderItemsTotals";
import { isNil } from "lodash";

export function AdminPreSaleOrderItemsPage({
  orderId,
  orderSummary,
  preSaleId,
}: AdminOrderRouteProps) {
  if (isNil(preSaleId)) throw new Error("No Pre-Sale ID present in URL.");

  const { data, error } = useAdminOrderItemsQuery({
    variables: {
      orderId: orderId,
    },
  });

  const { data: preSaleData, error: preSaleError } = useAdminPreSaleQuery({
    variables: {
      id: preSaleId,
    },
  });

  if (error || preSaleError) {
    return null;
  }

  if (data && orderSummary && preSaleData) {
    const { order } = data;
    const { preSale } = preSaleData;
    const { items, id } = order;

    return (
      <Page
        heading={<AdminOrderPageHeading page="Items" order={orderSummary} />}
      >
        <div>
          <AdminPreSaleOrderItemsTable
            orderId={id}
            orderItems={items}
            preSale={preSale}
            editable={order.enabledFeatures.canEditOrderItems}
            canDelete={
              order.enabledFeatures.canDeleteOrderItems &&
              order.type === OrderType.ProducerPurchaseOrder
            }
            orderType={order.type}
          />

          <div className="mt-8 flex justify-end px-6">
            <AdminPreSaleOrderItemsTotals order={order} />
          </div>
        </div>
      </Page>
    );
  }

  return <LoadingPage />;
}
