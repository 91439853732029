import React, { FC } from "react";
import { Severity } from "../shared/types/Severity";
import { Tag } from "primereact/tag";

const severityToIcon: {
  [K in Severity]: string;
} = {
  success: "pi-check",
  info: "pi-info-circle",
  warn: "pi-exclamation-triangle",
  danger: "pi pi-times",
};

interface IconTagProps {
  severity: Severity | undefined;
  value: string;
  className?: string;
}

export const IconTag: FC<IconTagProps> = ({
  severity,
  value,
  className = "",
}) => {
  return (
    <Tag
      severity={severity}
      value={value}
      icon={severity ? `pi ${severityToIcon[severity]}` : ""}
      className={`px-2.5 py-1.5 ${className}`}
    />
  );
};
