import React from "react";
import { ProgressBar } from "@ui";

type DeliveryProgressBarProps = {
  order: {
    deliveredRetailOrdersCount: number;
    retailOrdersCount: number;
  };
};

export function DeliveryProgressBar({ order }: DeliveryProgressBarProps) {
  return (
    <div>
      <ProgressBar
        percent={order.deliveredRetailOrdersCount / order.retailOrdersCount}
      />
      <div className="pl-3 pt-1 text-gray-500 text-sm">
        {order.deliveredRetailOrdersCount} / {order.retailOrdersCount} orders
        delivered
      </div>
    </div>
  );
}
