import { from, ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { DIRECT_BEER_GRAPHQL_URI } from "../config";
import { getAuthToken } from "../amplify";
import introspection from "./generatedPossibleTypes";

const retryLink = new RetryLink();

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await getAuthToken();

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        ...headers,
      },
    };
  } catch (error) {
    return {};
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: DIRECT_BEER_GRAPHQL_URI,
});

const link = from([retryLink, authLink, errorLink, httpLink]);

export const cache = new InMemoryCache({
  possibleTypes: introspection.possibleTypes,
  typePolicies: {
    DistributorProducerAssignment: {
      // Short for always preferring incoming over existing data.
      merge: false,
    },
  },
});

export const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: import.meta.env.DEV,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
    },
  },
});
