export class DwollaErrorToUser extends Error {
  constructor(message: string) {
    super(message);
    this.name = "DwollaErrorToUser";
  }
}

export class DwollaErrorToBackend extends Error {
  constructor(message: string) {
    super(message);
    this.name = "DwollaErrorToBackend";
  }
}
