import React, { FormEvent, useMemo } from "react";
import { Panel } from "primereact/panel";
import { Button } from "@components/ui/Button";
import { useDwollaWebhooksLazyQuery, DwollaWebhook } from "@apollo/ops";
import { Table } from "@ui/Table";
import { Column, useTable } from "react-table";
const TableWrapper = ({ items }: { items: DwollaWebhook[] }) => {
  const columnNames: Array<keyof DwollaWebhook> = [
    "id",
    "topic",
    "status",
    "resourceId",
    "resourceType",
    "createdAt",
    "receivedAt",
    "updatedAt",
  ];

  const data = items.map((i) => ({
    ...i,
    createdAt: new Date(parseInt(i.createdAt, 10)).toLocaleString("en-us"),
    updatedAt: new Date(parseInt(i.updatedAt, 10)).toLocaleString("en-us"),
    receivedAt: new Date(parseInt(i.receivedAt, 10)).toLocaleString("en-us"),
  }));

  const columns = useMemo<Array<Column<DwollaWebhook>>>(
    () =>
      columnNames.map((colName) => ({
        Header: colName,
        accessor: colName,
        uppercase: false,
      })),
    []
  );
  const table = useTable({
    columns,
    data,
  });
  return (
    <div className={"mt-8 tablet:mx-6 laptop:mx-8"}>
      <Table instance={table} upperCaseHeaders={false} />
    </div>
  );
};
export const DwollaWebhooksTable = () => {
  const [loadDwollaWebhooks, { loading, data }] = useDwollaWebhooksLazyQuery();
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    loadDwollaWebhooks();
  };
  return (
    <Panel header="Webhooks">
      <form onSubmit={handleSubmit} className="flex-1 flex">
        <Button type="submit" disabled={loading}>
          Load Webhooks
        </Button>
      </form>
      <TableWrapper items={data?.dwollaWebhooks || ([] as DwollaWebhook[])} />
    </Panel>
  );
};
