import React from "react";
import classNames from "classnames";
import { Dropdown } from "@ui/Dropdown";
import { usePackageTypes } from "@hooks/usePackageTypes/usePackageTypes";
import { FieldError } from "react-hook-form";

type PackageTypesDropdownProps = {
  onChange: (value: number | null) => unknown;
  value?: number | null;
  error?: FieldError;
  disabled?: boolean;
};

export function PackageTypesDropdown({
  onChange,
  value,
  error,
  disabled = false,
}: PackageTypesDropdownProps) {
  const packageTypes = usePackageTypes()?.productPackageTypes ?? [];

  //Values in the dropdown are converted to and from strings because it doesn't support numbers
  return (
    <Dropdown
      style={{ width: "100%" }}
      value={value?.toString() || ""}
      disabled={disabled}
      options={packageTypes.map((packageType) => ({
        value: packageType.id.toString(),
        label: packageType.displayName,
      }))}
      onChange={(e) => {
        if (typeof e === "undefined") {
          onChange(null);
        } else {
          onChange(Number(e));
        }
      }}
      className={classNames({
        "border-red-300": error,
        "border-1": error,
        "text-red-600": error,
        "bg-gray-50": disabled,
      })}
      placeholder="Select a package type"
    />
  );
}
