import React from "react";
import { IconTag } from "@components/IconTag";
import { MessageSeverity, Severity } from "@shared/types/Severity";
import { WarningAlert } from "@components/ui/Alert";

interface FinancialAccountStatusProps {
  status: string;
  message: string | null;
  messageSeverity: MessageSeverity | null;
  statusSeverity?: Severity;
}

export function FinancialAccountStatus({
  status,
  statusSeverity,
  message,
}: FinancialAccountStatusProps) {
  return (
    <div>
      <IconTag severity={statusSeverity} value={status} />
      {message && (
        <div className="mt-3">
          <WarningAlert>
            <div className="text-sm">{message}</div>
          </WarningAlert>
        </div>
      )}
    </div>
  );
}

export default FinancialAccountStatus;
