import React from "react";
import { OnboardingBankAccountStepFieldsFragment } from "@apollo/ops";
import { OnboardingAuthorizeOnDemandTransfer } from "./OnboardingAuthorizeOnDemandTransfer";
import { OnboardingAddBankAccount } from "./OnboardingAddBankAccount";

export interface BankAccountStepProps {
  businessEntityId: number;
  step: OnboardingBankAccountStepFieldsFragment;
}

function BankAccountStep({ businessEntityId, step }: BankAccountStepProps) {
  if (!step.bankAccount) {
    return <OnboardingAddBankAccount businessId={businessEntityId} />;
  }

  if (!step.bankAccount.hasOnDemandAuthorization) {
    return (
      <OnboardingAuthorizeOnDemandTransfer
        bankAccountId={step.bankAccount.id}
      />
    );
  }

  // This step is complete, there's nothing else to do.
  return null;
}

export default BankAccountStep;
