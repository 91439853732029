import React from "react";
import {
  useResetSkipBeneficialOwnersStepMutation,
  DwollaCustomerBeneficialOwnerCertificationStatus,
  DwollaCustomer,
  OnboardingDocument,
} from "@apollo/ops";
import { Button } from "../../../components/ui/Button";
import { IconTag } from "../../../components/IconTag";
import { Severity } from "../../../shared/types/Severity";
import { toastError } from "@utilities/toast";
import { WarningAlert } from "@components/ui/Alert";

const UncertifiedSummary = ({ businessId }: { businessId: number }) => {
  const [reset] = useResetSkipBeneficialOwnersStepMutation({
    variables: {
      businessId,
    },
    refetchQueries: [
      {
        query: OnboardingDocument,
        variables: { id: businessId },
      },
    ],
  });

  const handleRevisit = async () => {
    try {
      await reset();
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  return (
    <WarningAlert
      title="Finish beneficial owner certification"
      description="You will need to complete beneficial owners certification in order to
    finish onboarding."
    >
      <Button onClick={handleRevisit} kind="secondary">
        Finish certification
      </Button>
    </WarningAlert>
  );
};

export const BeneficialOwnerSummary = ({
  dwollaCustomer,
  businessId,
}: {
  dwollaCustomer: Pick<
    DwollaCustomer,
    | "requiresBeneficialOwnershipCertification"
    | "beneficialOwnerCertificationStatus"
  >;
  businessId: number;
}) => {
  /**
   * If the customer type doesn't require beneficial ownership certification
   * we don't want to show them anything here.
   */
  if (dwollaCustomer.requiresBeneficialOwnershipCertification === false) {
    return null;
  }

  return (
    <div className="py-4 tablet:py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4">
      <dt className="font-medium text-gray-500">Beneficial Ownership</dt>
      <dd className="mt-1 tablet:mt-0 tablet:col-span-2">
        {dwollaCustomer.beneficialOwnerCertificationStatus ===
        DwollaCustomerBeneficialOwnerCertificationStatus.Certified ? (
          <IconTag severity={Severity.success} value="Certified" />
        ) : (
          <UncertifiedSummary businessId={businessId} />
        )}
      </dd>
    </div>
  );
};
