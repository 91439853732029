import React, { useState } from "react";
import { AdminRenewLicenseMutation } from "@apollo/ops";
import { Button } from "../../ui/Button";
import { Dialog } from "primereact/dialog";
import { RenewLicense } from "./RenewLicense";

type RenewLicenseModalProps = {
  onRenew: (data: AdminRenewLicenseMutation) => void;
  licenseId: number;
};

export function RenewLicenseModal({
  licenseId,
  onRenew,
}: RenewLicenseModalProps) {
  const [visible, setVisible] = useState(false);

  const handleOnRenew = (data: AdminRenewLicenseMutation) => {
    onRenew(data);
    setVisible(false);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>Renew</Button>
      <Dialog
        visible={visible}
        header="Renew License"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <RenewLicense onRenew={handleOnRenew} licenseId={licenseId} />
      </Dialog>
    </>
  );
}
