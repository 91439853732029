import { PlaidLinkHookResult } from "../withPlaidLinkToken";
import { usePlaidLinkTokenQuery } from "@apollo/ops";

const POLL_INTERVAL = 29 * 60 * 1000; // 29 minutes

/**
 * Ideally this hook would examine `plaidItem.linkToken.expiration` field an refresh
 * as expiration nears, instead we just poll ever 29 minutes, which is the minimum
 * expiration time currently stated in the Plaid docs, this should more than suffice
 * for now.
 */
export const usePlaidLinkToken = (): PlaidLinkHookResult => {
  const { data, error, loading } = usePlaidLinkTokenQuery({
    fetchPolicy: "no-cache",
    pollInterval: POLL_INTERVAL,
  });

  return {
    loading,
    error,
    plaidLinkToken: data?.plaidLinkToken.linkToken,
  };
};
