import React from "react";

type ProgressBarProps = {
  percent: number;
};

export function ProgressBar({ percent }: ProgressBarProps) {
  const width = isNaN(percent) ? 0 : percent * 100 + "%";

  return (
    <div className="w-full h-6 bg-gray-200 rounded-full">
      <div
        className="h-6 bg-green-600 rounded-full"
        style={{
          width,
        }}
      ></div>
    </div>
  );
}
