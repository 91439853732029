import React from "react";
import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";
import { FormButton } from "../../FormButton";
import {
  usePlaidLinkToken,
  withPlaidLinkToken,
  WithPlaidLinkTokenInjectedProps,
} from "../../plaid/withPlaidLinkToken";
import {
  BankAccountFieldsFragment,
  BusinessActiveBankAccountsDocument,
  BusinessActiveBankAccountsQuery,
  BusinessActiveBankAccountsQueryVariables,
  useAddBankAccountViaPlaidMutation,
} from "@apollo/ops";
import {
  PLAID_CLIENT_NAME,
  PLAID_ENVIRONMENT,
  PLAID_PRODUCTS,
} from "../../../config";
import { defaultErrorHandler } from "@utilities/toast";

type NewAddBankAccountProps = {
  businessId: number;
  onAdd?: (bankAccount: BankAccountFieldsFragment) => void;
};

type BaseNewAddBankAccountProps = NewAddBankAccountProps &
  WithPlaidLinkTokenInjectedProps;

function BaseNewAddBankAccountProps({
  businessId,
  plaidLinkToken,
  onAdd,
}: BaseNewAddBankAccountProps) {
  const [addBankAccount, result] = useAddBankAccountViaPlaidMutation();

  const onSuccess: PlaidLinkOnSuccess = async (plaidPublicToken, metadata) => {
    try {
      const { data } = await addBankAccount({
        variables: {
          businessId,
          plaidPublicToken,
          selectedAccountId: metadata.accounts[0].id,
        },
        update: (cache, { data }) => {
          const oldVals = cache.readQuery<
            BusinessActiveBankAccountsQuery,
            BusinessActiveBankAccountsQueryVariables
          >({
            query: BusinessActiveBankAccountsDocument,
            variables: {
              businessEntityId: businessId,
            },
          });

          if (data && oldVals) {
            cache.writeQuery({
              query: BusinessActiveBankAccountsDocument,
              variables: {
                businessEntityId: businessId,
              },
              data: {
                ...oldVals,
                business: {
                  ...oldVals?.business,
                  bankAccount: data?.addBankAccountViaPlaid,
                },
              },
            });
          }
        },
      });

      if (data && onAdd) {
        onAdd(data.addBankAccountViaPlaid);
      }
    } catch (error) {
      console.error(error);
      defaultErrorHandler(error);
    }
  };

  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess,
    env: PLAID_ENVIRONMENT,
    product: PLAID_PRODUCTS,
    clientName: PLAID_CLIENT_NAME,
  });

  return (
    <FormButton
      disabled={!ready || result.loading}
      onSubmit={() => open()}
      size="xl"
    >
      Add bank account
    </FormButton>
  );
}

export const NewAddBankAccount = withPlaidLinkToken<NewAddBankAccountProps>({
  useHook: usePlaidLinkToken,
})(BaseNewAddBankAccountProps);
