import React from "react";
import { AdminProducerDistributionQuery } from "@apollo/ops";
import { LeftAlignedTerm } from "@components/ui/DescriptionList";
import { AdminProducerDistributorAutocomplete } from "./AdminProducerDistributorAutocomplete";
import { useRegions } from "@hooks/useRegions/useRegions";

export const AdminProducerDistributionRegionRows: React.FC<{
  results: AdminProducerDistributionQuery;
}> = ({ results }) => {
  const regions = useRegions();

  if (!regions) return null;

  return (
    <>
      {regions.regions.map((region) => {
        const { distributorAssignments, id: producerId } = results?.producer;

        const value = distributorAssignments.find(
          ({ regionV2: distribRegion }) => distribRegion.id === region.id
        );

        return (
          <LeftAlignedTerm
            term={region.friendlyName}
            definition={
              <AdminProducerDistributorAutocomplete
                regionId={region.id}
                selectedDistributorId={value?.distributorId}
                producerId={producerId}
              />
            }
            key={region.id}
          />
        );
      })}
    </>
  );
};
