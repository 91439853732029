import { useErrorHandler } from "react-error-boundary";
import { useCompleteOnboardingMutation } from "@apollo/ops";
export interface WelcomeEnhancerResult {
  loading: boolean;
  onContinueClick: () => void;
}

export interface WelcomeEnhancerProps {
  businessEntityId: number;
}

export const useWelcomeEnhancer = ({
  businessEntityId,
}: WelcomeEnhancerProps): WelcomeEnhancerResult => {
  const bubbleToErrorBoundary = useErrorHandler();
  const [completeOnboarding, { loading }] = useCompleteOnboardingMutation();

  const onContinueClick = async () => {
    try {
      await completeOnboarding({
        variables: {
          businessEntityId,
        },
      });
    } catch (e) {
      bubbleToErrorBoundary(e);
    }
  };

  return { loading, onContinueClick };
};
