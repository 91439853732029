import {
  AdminProducerPurchaseOrderTableRowFragment,
  ProducerPurchaseOrdersSortInput,
  SortDirection,
} from "@apollo/gen/generatedOperations";
import {
  Column,
  SortingRule,
  TableInstance,
  useFlexLayout,
  useSortBy,
  useTable,
} from "react-table";
import { Table } from "@ui/Table";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import React, { useEffect } from "react";

type PurchaseOrdersPaginatedTableProps = {
  columns: Array<Column<AdminProducerPurchaseOrderTableRowFragment>>;
  data: Array<AdminProducerPurchaseOrderTableRowFragment>;
  pagination?: UseOffsetPaginationResult;
  isShowingComponentOrders?: boolean;
  onChangeSort: (sortData: ProducerPurchaseOrdersSortInput) => void;
  initialSortBy: Array<SortingRule<AdminProducerPurchaseOrderTableRowFragment>>;
};

export function PurchaseOrdersPaginatedTable({
  columns,
  data,
  pagination,
  initialSortBy,
  onChangeSort,
}: PurchaseOrdersPaginatedTableProps) {
  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useFlexLayout,
    useSortBy
  ) as TableInstance<Partial<AdminProducerPurchaseOrderTableRowFragment>>;

  const {
    state: { sortBy },
  } = tableInstance;

  useEffect(() => {
    if (sortBy[0]?.id) {
      onChangeSort({
        columnName: sortBy[0].id,
        sortDirection: sortBy[0].desc ? SortDirection.Desc : SortDirection.Asc,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  return <Table instance={tableInstance} pagination={pagination} />;
}
