import React, { ReactNode, useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { DirectBeerSvg } from "@components/DirectBeerSvg";
import {
  SidebarNavigation,
  SidebarNavigationProps,
  SidebarNavigationClasses,
  sidebarNavigationStyles,
} from "../SidebarNavigation/SidebarNavigation";

type SidebarClasses = {
  bg: string;
  border: string;
  sidebarBorder: string;
  focusRing?: string;
  sidebarNavigation: SidebarNavigationClasses;
};

const sidebarBrandClasses: SidebarClasses = {
  bg: "bg-brand-600",
  border: "border-brand-700",
  sidebarBorder: "border-transparent",
  sidebarNavigation: sidebarNavigationStyles.brand,
};

const sidebarWhiteClasses: SidebarClasses = {
  bg: "bg-white",
  border: "border-gray-200",
  sidebarBorder: "border-gray-200",
  focusRing: "focus:ring-primary-500",
  sidebarNavigation: sidebarNavigationStyles.white,
};

const sidebarIndigoClasses: SidebarClasses = {
  bg: "bg-indigo-700",
  border: "border-indigo-800",
  sidebarBorder: "",
  sidebarNavigation: sidebarNavigationStyles.indigo,
};

export type SidebarLayoutProps = {
  children: ReactNode;
  kind?: "white" | "brand" | "indigo";
  homePath?: string;
  bottom?: ReactNode;
  gray?: boolean;
  sidebarNavigationProps: Omit<SidebarNavigationProps, "classes"> &
    Partial<SidebarNavigationProps>;
} & RouteComponentProps;

export function SidebarLayout({
  children,
  sidebarNavigationProps,
  kind = "white",
  homePath = "/",
  bottom,
  gray,
}: SidebarLayoutProps) {
  const [isOpen, setIsOpen] = useState(false);

  const rootClasses = classnames(
    "h-screen overflow-hidden flex",
    gray && "bg-gray-100"
  );

  let classes;

  switch (kind) {
    case "white":
      classes = sidebarWhiteClasses;
      break;
    case "brand":
      classes = sidebarBrandClasses;
      break;
    case "indigo":
      classes = sidebarIndigoClasses;
      break;
  }

  return (
    <div className={rootClasses}>
      {/* Off-canvas menu for mobile */}
      <div
        className={classnames(
          "fixed inset-0 flex z-40 laptop:hidden",
          !isOpen && "hidden"
        )}
        role="dialog"
        aria-modal="true"
      >
        <Transition
          show={isOpen}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 bg-gray-600 bg-opacity-75"
          aria-hidden="true"
        />
        <Transition
          show={isOpen}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className={`relative flex-1 flex flex-col max-w-xs w-full ${classes.bg}`}
        >
          <Transition
            show={isOpen}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute top-0 right-0 -mr-12 pt-2"
          >
            <button
              onClick={() => setIsOpen(false)}
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Close sidebar</span>
              <XIcon
                className={classnames(
                  "h-6 w-6 text-white",
                  !isOpen && "hidden"
                )}
                aria-hidden={!isOpen}
              />
            </button>
          </Transition>
          <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div className="flex-shrink-0 flex items-center px-4">
              <Link to={homePath} className="flex items-center">
                <DirectBeerSvg />

                <div className="ml-3 font-bold text-2xl text-white">
                  Direct Beer
                </div>
              </Link>
            </div>
            <div className="mt-5">
              <SidebarNavigation
                classes={classes.sidebarNavigation}
                {...sidebarNavigationProps}
              />
            </div>
          </div>

          {bottom && (
            <div
              className={`flex-shrink-0 flex border-t ${classes.border} p-4`}
            >
              {bottom}
            </div>
          )}
        </Transition>
        <div className="flex-shrink-0 w-14">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </div>
      {/* Static sidebar for desktop */}
      <div className="hidden laptop:flex laptop:flex-shrink-0">
        <div className="flex flex-col w-56">
          {/* Sidebar component */}
          <div
            className={`flex flex-col h-0 flex-1 border-r ${classes.sidebarBorder} ${classes.bg}`}
          >
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <Link to={homePath} className="flex items-center">
                  <DirectBeerSvg fill="#fff" width={32} height={32} />

                  <div className={`ml-3 font-bold text-2xl text-white`}>
                    Direct Beer
                  </div>
                </Link>
              </div>
              <div className="mt-5">
                <SidebarNavigation
                  classes={classes.sidebarNavigation}
                  {...sidebarNavigationProps}
                />
              </div>
            </div>
            {bottom && (
              <div
                className={`flex-shrink-0 flex border-t ${classes.border} text-white p-4`}
              >
                {bottom}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="laptop:hidden pl-1 pt-1 tablet:pl-3 tablet:pt-3">
          <button
            onClick={() => setIsOpen(true)}
            className={classnames(
              classes.focusRing,
              "-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset"
            )}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon
              className={classnames("h-6 w-6", isOpen && "hidden")}
              aria-hidden={isOpen}
            />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
}
