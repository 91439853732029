import React from "react";
import {
  AdminUpdateProducerMutation,
  useAdminUpdateProducerMutation,
  useUpdateProducerDataQuery,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { BreweryForm, BreweryFormInputData } from "./BreweryForm";
import { parseInt } from "lodash";

type UpdateBreweryProps = {
  breweryId: number;
  onUpdate: (data: AdminUpdateProducerMutation) => void;
};

export function UpdateBrewery({ breweryId, onUpdate }: UpdateBreweryProps) {
  const { data } = useUpdateProducerDataQuery({
    variables: {
      producerId: breweryId,
    },
  });

  const [updateProducer] = useAdminUpdateProducerMutation();

  if (!data) {
    return null;
  }

  const handleSubmit = async ({
    name,
    globalBreweryId,
    fein,
    rating,
  }: BreweryFormInputData) => {
    try {
      const { data } = await updateProducer({
        variables: {
          id: breweryId,
          data: {
            name,
            globalBreweryId: parseInt(globalBreweryId),
            rating: parseFloat(rating),
            fein,
          },
        },
      });

      if (data && onUpdate) {
        onUpdate(data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Updated brewery ${name}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <BreweryForm
      submitText="Update"
      onSubmit={handleSubmit}
      defaultValues={data.producer}
    />
  );
}
