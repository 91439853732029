const dateFormatter = new Intl.DateTimeFormat("en-US");

//Note: this uses the client browser's timezone.
//If we ever need to use a specific timezone, the timeZone parameter can do that.
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_options
const dateTimeFormatterOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short", //Intentionally show the timezone for clarity to users
};

const dateTimeFormatter = new Intl.DateTimeFormat(
  "en-US",
  dateTimeFormatterOptions
);

/**
 * Utility function for formatting dates (without the time component) in a standard way for display in the UI.
 *
 * This displays the date in the client timezone.
 * @see formatAsDateFromString
 * @see formatAsDateWithTime
 * @see formatAsDateWithTimeFromString
 */
export const formatAsDate = (date: Date | string) => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return dateFormatter.format(date as Date);
};

/**
 * Same as formatAsDate, but takes in a string and attempts to parse it into a Date.
 *
 * Intended usage is to pass in a string like 2021-06-18T20:26:03.418Z including the timezone.
 * @see formatAsDate
 * @see formatAsDateWithTime
 * @see formatAsDateWithTimeFromString
 */
export const formatAsDateFromString = (date: string) =>
  formatAsDate(new Date(date));

/**
 * Utility function for formatting dates (with the time component) in a standard way for display in the UI.
 *
 * This displays the date and time in the client timezone.
 * @see formatAsDateWithTimeFromString
 * @see formatAsDate
 * @see formatAsDateFromString
 */
export const formatAsDateWithTime = (date: Date) =>
  dateTimeFormatter.format(date);

/**
 * Same as formatAsDateWithTime, but takes in a string and attempts to parse it into a Date.
 *
 * Intended usage is to pass in a string like 2021-06-18T20:26:03.418Z including the timezone.
 * @see formatAsDateWithTime
 * @see formatAsDate
 * @see formatAsDateFromString
 */
export const formatAsDateWithTimeFromString = (date: string) =>
  formatAsDateWithTime(new Date(date));
