import React from "react";
import { AdminProductDetailsFragment } from "@apollo/ops";
import { Card } from "@ui/Card/Card";

type ProductImageCard = {
  product: AdminProductDetailsFragment;
};

export function ProductImageCard({ product }: ProductImageCard) {
  return (
    <Card title="Product Image">
      <img
        className="h-48 m-6 object-contain"
        src={product.productImage?.rawUrl}
      />
    </Card>
  );
}
