import React, { FC, useState } from "react";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { OfferSearchFilters, useProductQuery } from "@apollo/ops";
import { Button } from "@components/ui/Button";
import { OfferBackendAutocomplete } from "@ui/Autocomplete/OfferBackendAutocomplete/OfferBackendAutocomplete";
import { ProductDisplayFragmentWithOfferId } from "@admin/AdminPreSalesContainer/AdminPreSaleRouter/AdminPreSalePage/AdminPreSaleOrders/AdminPreSaleOrderItemsPage/AdminPreSaleOrderItemsTable/AdminPreSaleOrderItemsEditTable";

interface AddProductDialogProps {
  onSelect: (productId: ProductDisplayFragmentWithOfferId) => unknown;
  onClose: () => unknown;
  filters: OfferSearchFilters;
}
//Let the user choose a product by providing an autocomplete
const AddProductDialogCmp: FC<AddProductDialogProps> = ({
  onSelect,
  onClose,
  filters,
}) => {
  const [
    selectedProduct,
    setSelectedProduct,
  ] = useState<ProductDisplayFragmentWithOfferId>();

  const { data: productDisplayFragment, error } = useProductQuery({
    variables: {
      id: selectedProduct?.id as number,
    },
    skip: !selectedProduct,
  });

  if (error) {
    throw error;
  }
  return (
    <>
      <OfferBackendAutocomplete
        selectedId={selectedProduct?.id}
        onSelect={setSelectedProduct}
        placeholder={"Type at least 3 characters in the Product's Name"}
        filters={filters}
      />
      <Button
        className="mr-1"
        disabled={!productDisplayFragment}
        onClick={() => {
          if (productDisplayFragment && selectedProduct) {
            onSelect({
              ...productDisplayFragment.product,
              offerId: selectedProduct.offerId,
            });
          }

          onClose();
        }}
      >
        Ok
      </Button>
    </>
  );
};

export const AddProductDialog = withErrorBoundary(AddProductDialogCmp);
