import React from "react";
import {
  AdminAddProducerMutation,
  AdminProducersDocument,
  useAdminAddProducerMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { BreweryForm, BreweryFormInputData } from "./BreweryForm";

type AddBreweryProps = {
  onAdd?: (data: AdminAddProducerMutation) => void;
};

export function AddBrewery({ onAdd }: AddBreweryProps) {
  const [addProducer] = useAdminAddProducerMutation({
    refetchQueries: [
      {
        query: AdminProducersDocument,
      },
    ],
  });

  const handleSubmit = async ({
    name,
    globalBreweryId,
    fein,
    rating,
  }: BreweryFormInputData) => {
    try {
      const result = await addProducer({
        variables: {
          data: {
            name,
            globalBreweryId: parseInt(globalBreweryId),
            fein,
            rating: parseFloat(rating),
          },
        },
      });

      if (result.data && onAdd) {
        onAdd(result.data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Added brewery ${name}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return <BreweryForm submitText="Add" onSubmit={handleSubmit} />;
}
