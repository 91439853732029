import { createContext } from "react";
import { CognitoUser } from "@aws-amplify/auth";
import { Business, BusinessType } from "@apollo/ops";

export interface IUser {
  id: number;
  isGlobalAdmin: boolean;
  organization: IOrganization | null;
  organizations: IOrganization[];
}

export interface IOrganization {
  id: number;
  name: string;
  businessType: BusinessType;
  businesses: Array<Partial<Business>>;
}

export interface IAuthContext {
  afterSignInLocation?: string;
  setAfterSignInLocation: (location: string) => void;
  isAuthenticating: boolean;
  user: IUser | null;
  logout: () => void;
  login: (user: CognitoUser) => Promise<void>;
}

/*
 * The Typescript non-null assertion operator "!" here let's assign the
 * non-nullable type IAuthContext to the context value without having to
 * provide a bogus/useless implementation. At runtime we will always provide
 * a value via AutoContext.Provider.
 */
export const AuthContext = createContext<IAuthContext>(null!);
