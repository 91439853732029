export const AddBankAccountCopy = `Add a bank account to receive payment. To instantly verify your
ownership, you’ll be asked to log into your financial institution and
select an account via Plaid. All information will be handled by Plaid,
our third party service provider, to ensure secure handling of your
data.`;

export const AuthorizeOnDemandTransfersCopy = `I agree that future payments to Direct.Beer will be processed by the
Dwolla payment system for the selected bank account above. In order to
cancel this authorization, I will change my payment settings within my
Direct Beer account.`;
