import React from "react";
import { RouteComponentProps } from "@reach/router";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { ProducerPurchaseOrderContainer } from "./ProducerPurchaseOrderContainer";

export type OrderRouteParams = {
  orderIdParam: string;
};

export function ProducerPurchaseOrderRouter({
  orderIdParam,
}: RouteComponentProps<OrderRouteParams>) {
  const parsedOrderId = Number(orderIdParam);
  if (orderIdParam === undefined || isNaN(parsedOrderId)) {
    return <RedirectToNotFound />;
  }

  return <ProducerPurchaseOrderContainer orderId={parsedOrderId} />;
}
