import React from "react";
import { Redirect } from "@reach/router";
import { useRedirectToOrganizationQuery } from "@apollo/ops";
import { organizationPath } from "@routes";

type RedirectToOrganizationProps = {
  organizationId: number;
};

export function RedirectToOrganization({
  organizationId,
}: RedirectToOrganizationProps) {
  const { data } = useRedirectToOrganizationQuery({
    variables: {
      organizationId,
    },
  });

  if (!data) {
    return null;
  }

  return <Redirect to={`/${organizationPath(data.organization)}`} />;
}
