import React from "react";
import ctl from "@netlify/classnames-template-literals";

export type Color =
  | "gray"
  | "red"
  | "yellow"
  | "green"
  | "blue"
  | "indigo"
  | "purple"
  | "pink";

type Size = "sm" | "base" | "lg";

export type BadgeProps = {
  label: string;
  color: Color;
  size?: Size;
};

export function Badge({ label, color, size = "base" }: BadgeProps) {
  return <span className={badgeClasses({ color, size })}>{label}</span>;
}

type BadgeClassesOptions = {
  color: Color;
  size: Size;
};

const badgeClasses = ({ color, size }: BadgeClassesOptions): string =>
  ctl(`
    inline-flex
    items-center
    font-medium
    uppercase
    truncate
    ${badgeColorClasses[color]}
    ${badgeSizeClasses[size]}
  `);

const badgeColorClasses: Record<Color, string> = {
  blue: "bg-blue-100 text-blue-800",
  gray: "bg-blue-100 text-blue-800",
  red: "bg-red-100 text-red-800",
  yellow: "bg-yellow-100 text-yellow-800",
  green: "bg-green-100 text-green-800",
  indigo: "bg-indigo-100 text-indigo-800",
  purple: "bg-purple-100 text-purple-800",
  pink: "bg-pink-100 text-pink-800",
};

const badgeSizeClasses: Record<Size, string> = {
  sm: "px-1.5 text-xs rounded-full",
  base: "px-2.5 py-0.5 text-xs rounded-full",
  lg: "px-3 py-0.5 text-sm rounded-full",
};
