import { useCallback, useState } from "react";
import {
  DwollaCustomer,
  DwollaCustomerType,
  OnboardingDocument,
  useUpsertDwollaCustomerMutation,
} from "@apollo/ops";
import { DwollaErrorToUser, DwollaErrorToBackend } from "@errors/DwollaErrors";
import { handleDwollaDropInError } from "@errors/DwollaDropInErrors";
import Bugsnag from "@bugsnag/browser";
export interface DwollaResourceCreated {
  location?: string;
  code?: string;
  _embedded?: {
    errors?: {
      message: string;
      code: string;
      path: string;
    }[];
  };
}
export interface DwollaResourceUpdated {
  id: string;
}

export type onSubmitResponseFromDwolla =
  | DwollaResourceCreated
  | DwollaResourceUpdated;
export interface CreateDwollaCustomerEnhancerOptions {
  businessEntityId: number;
  dwollaCustomer: Pick<DwollaCustomer, "id"> | null;
  dwollaCustomerType: DwollaCustomerType;
  onDocumentsResource: () => Promise<void>;
}

const useCreateDwollaCustomerEnhancer = ({
  businessEntityId,
  dwollaCustomer = null,
  dwollaCustomerType,
  onDocumentsResource,
}: CreateDwollaCustomerEnhancerOptions) => {
  const [
    upsertDwollaCustomer,
    {
      loading: creatingCustomerInBackend,
      error: errorCreatingCustomerInBackend,
    },
  ] = useUpsertDwollaCustomerMutation();

  const [dwollaError, setDwollaError] = useState<any>(null);
  /**
   * Throws an error if a the response with Dwolla has an error, void otherwise.
   *
   * It will throw a string with the message error if it is a message that we want to show to he user.
   * It will return an instance of Error if it's a message that we don't want to show to the user.
   * @param dwollaResponse
   */
  const validateResponseHasNoError = (
    dwollaResponse: onSubmitResponseFromDwolla
  ): void => {
    if (!("id" in dwollaResponse)) {
      if (dwollaResponse?._embedded?.errors) {
        console.error(dwollaResponse?._embedded?.errors);
      }
      if (dwollaResponse?.code?.includes("ValidationError")) {
        throw new DwollaErrorToUser(
          "Dwolla response error: " + JSON.stringify(dwollaResponse)
        );
      }

      if (
        !("id" in dwollaResponse) &&
        dwollaResponse?.code?.includes("Error")
      ) {
        throw new DwollaErrorToBackend(
          "Dwolla response error: " + JSON.stringify(dwollaResponse)
        );
      }
    }
  };

  const getResourceInfo = (
    onSubmitDwollaResponse: onSubmitResponseFromDwolla
  ): { resourceId: string; resourceType: string } => {
    const dwollaCustomerWasUpdated = "id" in onSubmitDwollaResponse;
    if (dwollaCustomerWasUpdated) {
      const dwollaResourceUpdated = onSubmitDwollaResponse as DwollaResourceUpdated;
      return {
        resourceId: dwollaResourceUpdated.id,
        resourceType: "customers",
      };
    } else {
      const dwollaResourceCreated = onSubmitDwollaResponse as DwollaResourceCreated;
      const restResourceUrl = dwollaResourceCreated.location?.split("/");
      return {
        resourceId: restResourceUrl!.pop()!,
        resourceType: restResourceUrl!.pop()!,
      };
    }
  };
  /**
   * This is the callback that Dwolla drop-in components reach a success state.
   * This function may be called multiple times, once when a customer resource
   * is created in Dwolla, and again when a document resource is created. We
   * check the resource url to determine which event we are responding to.
   *
   * @param location - The customer resource url. The last part of path is the customerID.
   * @example
   * One value https://api-sandbox.dwolla.com/documents/e912ed05-9b56-4871-9401-127d3119f3dc
   */
  const onSubmit = useCallback(
    async (onSubmitDwollaResponse: onSubmitResponseFromDwolla) => {
      try {
        Bugsnag.leaveBreadcrumb("Received response from Dwolla drop-in", {
          businessEntityId,
          response: onSubmitDwollaResponse,
        });

        validateResponseHasNoError(onSubmitDwollaResponse);

        const { resourceId, resourceType } = getResourceInfo(
          onSubmitDwollaResponse
        );

        if (!resourceId || !resourceType) {
          throw new DwollaErrorToBackend(
            "Unexpected format returned from dwolla's onSuccess callback"
          );
        }

        const upsertDwollaCustomerInOurDB = async (customerId: string) => {
          await upsertDwollaCustomer({
            variables: {
              customerId,
              businessEntityId,
            },
            refetchQueries: [
              {
                query: OnboardingDocument,
                variables: { id: businessEntityId },
              },
            ],
          });
        };

        if (resourceType === "documents") {
          if (dwollaCustomer?.id) {
            await onDocumentsResource();
          } else {
            throw new DwollaErrorToBackend("Missing Dwolla Customer");
          }
        } else if (resourceType === "customers") {
          await upsertDwollaCustomerInOurDB(resourceId);
        } else {
          throw new DwollaErrorToBackend(
            `Unknown Dwolla resource type ${resourceType}`
          );
        }
      } catch (err) {
        handleDwollaDropInError({ error: err, setError: setDwollaError });
      }
    },
    [
      upsertDwollaCustomer,
      businessEntityId,
      dwollaCustomer?.id,
      onDocumentsResource,
    ]
  );

  return {
    onSubmit,
    error: dwollaError || errorCreatingCustomerInBackend,
    dwollaCustomer,
    dwollaCustomerType,
    communicatingWithBackend: creatingCustomerInBackend,
  };
};

export default useCreateDwollaCustomerEnhancer;
