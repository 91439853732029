import React from "react";
import { Button } from "@components/ui/Button";
import { Card } from "@components/ui/Card/Card";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";
import { PencilAltIcon } from "@heroicons/react/outline";
import { formatAmountInCents } from "@utilities/currency";
import { UpdateDistributorDetailsCardModal } from "@components/Distributor/UpdateDistributorDetailsCardModal";
import { useDialog } from "@hooks/useDialog";

type DistributorDetailsCardProps = {
  feePerPackageInCents: number;
  distributorId: number;
};

export function DistributorDetailsCard({
  feePerPackageInCents,
  distributorId,
}: DistributorDetailsCardProps) {
  const distributorDetailsModal = useDialog();

  return (
    <>
      <Card
        headerProps={{
          title: "Distributor Information",
          actions: (
            <Button kind="tertiary" onClick={distributorDetailsModal.show}>
              <PencilAltIcon className="h-6 w-6" />
            </Button>
          ),
        }}
      >
        <DescriptionListInCard>
          <LeftAlignedTerm
            term="Fee Per Package"
            definition={formatAmountInCents(feePerPackageInCents)}
          />
        </DescriptionListInCard>
      </Card>
      <UpdateDistributorDetailsCardModal
        isOpen={distributorDetailsModal.isOpen}
        onClose={distributorDetailsModal.hide}
        feePerPackageInCents={feePerPackageInCents}
        distributorId={distributorId}
      />
    </>
  );
}
