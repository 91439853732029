import React, { useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { useForm } from "react-hook-form";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";
import { Card, CardBody } from "@components/ui/Card/Card";
import { signInPath } from "@routes";

export type SignUpFormData = {
  email: string;
  password: string;
  name: string;
};

type SignUpProps = {
  onSignUp: (data: SignUpFormData) => Promise<void>;
} & RouteComponentProps;

export function SignUp({ onSignUp }: SignUpProps) {
  const [error, setError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SignUpFormData>({
    mode: "onBlur",
  });

  const onSubmit = async (data: SignUpFormData) => {
    setError(null);

    try {
      await onSignUp(data);
      reset();
    } catch (error) {
      if (error.message) {
        setError(error.message);
      } else {
        setError("An unknown error occurred, please reach out to support.");
      }
    }
  };

  return (
    <Card title="Create an account">
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <Input
                  type="text"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                  })}
                  error={errors.name?.message}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <Input
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                  })}
                  error={errors.email?.message}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 text-xs text-gray-500">
                Password must be 12 characters and contain at least one
                uppercase, lowercase, number, and symbol.
              </div>
              <div className="mt-1">
                <Input
                  type="password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                    minLength: {
                      value: 12,
                      message: "Password must have at least 12 characters",
                    },
                  })}
                  error={errors.password?.message}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <div>
              <Button size="lg" type="submit" disabled={false}>
                Sign up
              </Button>
            </div>
            <div className="ml-6 text-sm">
              Have an account?{" "}
              <Link to={signInPath} className="link">
                Sign in
              </Link>
            </div>
          </div>
        </form>
        {error && <div className="mt-6 text-red-600">{error}</div>}
      </CardBody>
    </Card>
  );
}
