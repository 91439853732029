import React from "react";
import { RouteComponentProps } from "@reach/router";
import { NumberParam, useQueryParams, StringParam } from "use-query-params";
import {
  AdminProductsQuery,
  AdminProductsDocument,
  AdminProductsQueryVariables,
} from "@apollo/ops";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { AdminProductsFilter, FilterData } from "./AdminProductsFilter";
import { Page, PageHeading } from "@components/ui/Page";
import { AdminProductsTable } from "./AdminProductsTable";
import { AddProductDialog } from "../AddProduct/AddProductDialog";

export function AdminProductsPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    producerId: NumberParam,
    name: StringParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    AdminProductsQuery,
    AdminProductsQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        filters,
      },
    },
    query: AdminProductsDocument,
    mapDataToTotal({ searchProducts }) {
      return searchProducts.total;
    },
  });

  const { data } = queryResult;

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Products"
          actions={<AddProductDialog onAdd={() => queryResult.refetch()} />}
        />
      }
    >
      <div className="mb-3">
        <AdminProductsFilter onFilter={updateFilters} defaultValues={filters} />
      </div>
      {data && (
        <AdminProductsTable
          data={data.searchProducts.results}
          pagination={pagination}
        />
      )}
    </Page>
  );
}
