import React, { ReactElement } from "react";

type WellProps = {
  children: ReactElement;
};

export function Well({ children }: WellProps) {
  return (
    <div className="bg-gray-50 tablet:rounded-lg">
      <div className="px-4 py-5 tablet:p-6">{children}</div>
    </div>
  );
}
