import React from "react";
import { AdminProductQuery, useAddProductMutation } from "@apollo/ops";
import { Button } from "@ui/Button";
import { Dialog, useDialog } from "@ui/Dialog";
import { CloneProductFormData, ProductForm } from "../ProductForm";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { toCents } from "@util/currency";
import _ from "lodash";

type CloneProductDialogProps = {
  product: AdminProductQuery["product"];
};

export function CloneProductDialog({ product }: CloneProductDialogProps) {
  const { open, close, getDialogProps } = useDialog();
  const [addProductMutation] = useAddProductMutation();

  const handleSubmit = async ({
    abv,
    rating,
    breweryNetPrice,
    ...data
  }: CloneProductFormData) => {
    try {
      if (!product.productImage?.id) {
        return toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: `Only products with images may be cloned`,
        });
      }
      const result = await addProductMutation({
        variables: {
          data: {
            cloudinaryImageId: product.productImage.id,
            abv: _.isNil(abv) ? null : parseFloat(abv),
            rating: _.isNil(rating) ? null : parseFloat(rating),
            breweryNetPrice: _.isNil(breweryNetPrice)
              ? null
              : toCents(breweryNetPrice),
            ...data,
          },
        },
      });
      if (result.data) {
        close();

        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Cloned Product ${product.name}`,
        });
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <>
      <Button kind="primary" onClick={open} size="sm">
        Clone
      </Button>
      <Dialog {...getDialogProps()} title={`Clone ${product.name}`} width="lg">
        <ProductForm
          onSubmit={handleSubmit}
          product={product}
          isCloning={true}
        />
      </Dialog>
    </>
  );
}
