import React, { Suspense } from "react";

import { FileInputProps } from "./FileInputTypes";
export * from "./FileInputTypes";

const FileInputHeavy = React.lazy(() => import("./FileInputHeavy"));

export function FileInput(props: FileInputProps) {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <FileInputHeavy {...props} />
      </Suspense>
    </div>
  );
}
