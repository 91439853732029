import React from "react";
import { AddPackageTypeMutation, useAddPackageTypeMutation } from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { AddPackageTypeForm } from "./AddPackageTypeForm";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { AddPackageTypeFormData } from "./AddPackageTypeForm";

type AddPackageTypeProps = {
  onAdd?: (data: AddPackageTypeMutation) => void;
};

export function AddPackageType({ onAdd }: AddPackageTypeProps) {
  const [addPackageType] = useAddPackageTypeMutation();

  const handleSubmit = async ({
    containerVolume,
    weight,
    units,
    ...data
  }: AddPackageTypeFormData) => {
    try {
      const { data: resultData } = await addPackageType({
        variables: {
          data: {
            containerVolume: parseFloat(containerVolume),
            weight: parseFloat(weight),
            units: parseInt(units),
            ...data,
          },
        },
      });

      if (resultData && onAdd) {
        onAdd(resultData);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: "Added Package Type",
      });
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return <AddPackageTypeForm onSubmit={handleSubmit} />;
}
