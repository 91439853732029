import {
  Carrier,
  Maybe,
  TrackingInformationDetailsFragment,
  useAllCarriersQuery,
} from "@apollo/ops";
import { AmountInput } from "@components/Input/AmountInput";
import { Button } from "@components/ui/Button";
import { Dialog } from "@components/ui/Dialog";
import { LabeledInput } from "@components/ui/Input";
import { MessageSeverity } from "@shared/types/Severity";
import { toCents, toDollars } from "@utilities/currency";
import { toast } from "@utilities/toast";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

export interface TrackingInfoFormData {
  carrierId: number;
  trackingNumber: Maybe<string>;
  trackingLink?: string | null;
  estimatedPickupDate?: Date;
  estimatedArrivalDate?: Date;
  estimatedCostInCents?: number;
  pickedUpAt?: Date;
  deliveredAt?: Date;
  cancelledAt?: Date;
  palletCount?: string;
  actualCostInCents?: number;
  freightTrailerNumber?: string | null;
  freightInvoiceNumber?: string | null;
}

export const buildMutationDataFromForm = (formData: TrackingInfoFormData) => {
  return {
    carrierId: formData.carrierId,
    trackingNumber: formData.trackingNumber,
    trackingLink: formData.trackingLink,
    estimatedPickupDate: formData.estimatedPickupDate,
    estimatedArrivalDate: formData.estimatedArrivalDate,
    estimatedCostInCents: formData.estimatedCostInCents
      ? toCents(formData.estimatedCostInCents)
      : null,
    palletCount: formData.palletCount ? parseInt(formData.palletCount) : null,
    actualCostInCents: formData.actualCostInCents
      ? toCents(formData.actualCostInCents)
      : null,
    freightTrailerNumber: formData.freightTrailerNumber,
    freightInvoiceNumber: formData.freightInvoiceNumber,
    deliveredAt: formData.deliveredAt,
    pickedUpAt: formData.pickedUpAt,
    cancelledAt: formData.cancelledAt,
  };
};

interface FormBodyProps {
  onSubmit: (data: TrackingInfoFormData) => Promise<void> | void;
  defaultValues?: TrackingInfoFormData;
  isLoading: boolean;
  allCarriers: Carrier[];
}

/**
 * Need to prevent bubbling of the onMouseDown event of the calendar panel
 * However, this container should exist outside of the current document flow so
 * the overlay isn't clipped by a parent element.
 *
 * If we don't stop propagation, the Dialog component will detect the event,
 * see that the target is not a child of the Dialog, and close the modal.
 */
const calendarPortal = document.createElement("div");
calendarPortal.addEventListener("mousedown", (e) => e.stopPropagation());
document.body.append(calendarPortal);

const FormBody: FC<FormBodyProps> = ({
  onSubmit,
  defaultValues,
  isLoading,
  allCarriers,
}) => {
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<TrackingInfoFormData>({
    defaultValues,
    mode: "onSubmit",
  });

  const wrappedSubmit = async (data: TrackingInfoFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (e) {
      if (e.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: e.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedSubmit)}>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label
            htmlFor="carrier"
            className="block text-sm font-medium text-gray-700"
          >
            Carrier (required)
          </label>
          <div className="mt-1">
            <Controller
              name="carrierId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Carrier is required",
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Dropdown
                    onChange={(e) => onChange(e.value)}
                    onMouseDown={(e) => e.stopPropagation()}
                    value={value}
                    options={allCarriers.map((carrier) => ({
                      label: carrier.displayName,
                      value: carrier.id,
                    }))}
                    className="flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm"
                    placeholder="Please choose a carrier"
                  />
                );
              }}
            />
            {errors.carrierId?.message && (
              <small className="text-sm text-red-600">
                {errors.carrierId.message}
              </small>
            )}
          </div>
        </div>
        <LabeledInput
          label="Tracking Number"
          inputProps={{
            type: "text",
            id: "trackingNumber",
            className: "flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm",
            error: errors.trackingNumber?.message,
            ...register("trackingNumber"),
          }}
        />
        <div className="col-span-2">
          <LabeledInput
            label="Tracking Link"
            inputProps={{
              type: "text",
              id: "trackingLink",
              className: "flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm",
              error: errors.trackingLink?.message,
              ...register("trackingLink"),
            }}
          />
        </div>
        <div>
          <label
            htmlFor="estimatedPickupDate"
            className="block text-sm font-medium text-gray-700"
          >
            Estimated Pick Up Date
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Calendar
                    value={value ? new Date(value) : undefined}
                    onChange={(e) => onChange(e.value)}
                    monthNavigator
                    placeholder="Select a Pick Up Date"
                    className="flex-1 w-full min-w-0 h-10"
                    appendTo={calendarPortal}
                  />
                );
              }}
              name="estimatedPickupDate"
            />
            {errors.estimatedPickupDate?.message && (
              <small className="text-sm text-red-600">
                {errors.estimatedPickupDate.message}
              </small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="estimatedArrivalDate"
            className="block text-sm font-medium text-gray-700"
          >
            Estimated Arrival Date
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Calendar
                    value={value ? new Date(value) : undefined}
                    onChange={(e) => onChange(e.value)}
                    monthNavigator
                    placeholder="Select an Arrival Date"
                    className="flex-1 w-full min-w-0 h-10"
                    appendTo={calendarPortal}
                  />
                );
              }}
              name="estimatedArrivalDate"
            />
            {errors.estimatedArrivalDate?.message && (
              <small className="text-sm text-red-600">
                {errors.estimatedArrivalDate.message}
              </small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="pickedUpAt"
            className="block text-sm font-medium text-gray-700"
          >
            Picked up at
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Calendar
                    value={value ? new Date(value) : undefined}
                    onChange={(e) => onChange(e.value)}
                    monthNavigator
                    placeholder="Select date picked up"
                    className="flex-1 w-full min-w-0 h-10"
                    appendTo={calendarPortal}
                  />
                );
              }}
              name="pickedUpAt"
            />
            {errors.pickedUpAt?.message && (
              <small className="text-sm text-red-600">
                {errors.pickedUpAt.message}
              </small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="deliveredAt"
            className="block text-sm font-medium text-gray-700"
          >
            Delivered at
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Calendar
                    value={value ? new Date(value) : undefined}
                    onChange={(e) => onChange(e.value)}
                    monthNavigator
                    placeholder="Select a delivery date"
                    className="flex-1 w-full min-w-0 h-10"
                    appendTo={calendarPortal}
                  />
                );
              }}
              name="deliveredAt"
            />
            {errors.deliveredAt?.message && (
              <small className="text-sm text-red-600">
                {errors.deliveredAt.message}
              </small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="cancelledAt"
            className="block text-sm font-medium text-gray-700"
          >
            Cancelled at
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Calendar
                    value={value ? new Date(value) : undefined}
                    onChange={(e) => onChange(e.value)}
                    monthNavigator
                    placeholder="Select cancellation date"
                    className="flex-1 w-full min-w-0 h-10"
                    appendTo={calendarPortal}
                  />
                );
              }}
              name="cancelledAt"
            />
            {errors.pickedUpAt?.message && (
              <small className="text-sm text-red-600">
                {errors.pickedUpAt.message}
              </small>
            )}
          </div>
        </div>
        <LabeledInput
          label="Pallet Count"
          inputProps={{
            type: "number",
            id: "palletCount",
            className: "flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm",
            error: errors.palletCount?.message,
            ...register("palletCount"),
          }}
        />
        <AmountInput
          label="Estimated Shipping Cost"
          name="estimatedCostInCents"
          required={false}
          register={register}
          className="flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm"
          error={errors.estimatedCostInCents?.message}
        />
        <AmountInput
          label="Actual Shipping Cost"
          name="actualCostInCents"
          required={false}
          register={register}
          className="flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm"
          error={errors.actualCostInCents?.message}
        />
        <LabeledInput
          label="Freight Trailer #"
          inputProps={{
            type: "text",
            id: "freightTrailerNumber",
            className: "flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm",
            error: errors.freightTrailerNumber?.message,
            ...register("freightTrailerNumber"),
          }}
        />
        <LabeledInput
          label="Freight Invoice #"
          inputProps={{
            type: "text",
            id: "freightInvoiceNumber",
            className: "flex-1 w-full min-w-0 h-10 tablet:text-sm rounded-sm",
            error: errors.freightInvoiceNumber?.message,
            ...register("freightInvoiceNumber"),
          }}
        />
      </div>
      <div className="mt-5">
        <Button type="submit" disabled={isLoading}>
          Save
        </Button>
      </div>
    </form>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: TrackingInfoFormData) => Promise<void> | void;
  trackingInformation?: TrackingInformationDetailsFragment;
  isLoading: boolean;
}

export const TrackingInformationModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  trackingInformation,
  isLoading,
}) => {
  const trackingInformationWithCarrierId = trackingInformation && {
    ...trackingInformation,
    carrierId: trackingInformation.carrier.id,
    palletCount: trackingInformation.palletCount
      ? trackingInformation.palletCount.toString()
      : undefined,
    estimatedCostInCents: trackingInformation.estimatedCostInCents
      ? toDollars(trackingInformation.estimatedCostInCents)
      : undefined,
    actualCostInCents: trackingInformation.actualCostInCents
      ? toDollars(trackingInformation.actualCostInCents)
      : undefined,
  };

  const { data, loading: loadingAllCarriers } = useAllCarriersQuery();
  const allCarriers = data?.allCarriers ?? [];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Shipping Information"
      width="xl"
    >
      <FormBody
        onSubmit={onSubmit}
        defaultValues={trackingInformationWithCarrierId}
        isLoading={isLoading || loadingAllCarriers}
        allCarriers={allCarriers}
      />
    </Dialog>
  );
};
