import React, { ReactNode, HTMLAttributes } from "react";
import ctl from "@netlify/classnames-template-literals";

export interface SectionHeadingProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  actions?: ReactNode;
}

export function SectionHeading({
  title,
  description,
  actions,
  className,
}: SectionHeadingProps) {
  return (
    <div className={classes(className)}>
      <div className="tablet:w-0 tablet:flex-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        {description && (
          <p className="mt-2 max-w-4xl text-sm text-gray-500">{description}</p>
        )}
      </div>
      {actions && <div className="mt-3 tablet:mt-0 tablet:ml-4">{actions}</div>}
    </div>
  );
}

const classes = (className?: string) =>
  ctl(`
    ${className}
    pb-3
    mb-5
    border-b
    border-gray-200
    tablet:flex
    tablet:items-center
    tablet:justify-between
  `);
