import React, { FC } from "react";
import { BankAccount as BankAccountType } from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { Button } from "@components/ui/Button";
import { useDialog } from "@ui/Dialog";
import { AddAndAuthorizeBankAccountDialog } from "@components/BankAccount/AddAndAuthorizeBankAccount/AddAndAuthorizeBankAccountDialog";
import { BankAccountsTable } from "./BankAccountsTable";
import { BankAccountReVerifyProvider } from "./BankAccountReVerify/BankAccountReVerifyProvider";
import {
  BankAccountReVerifyContext,
  bankAccountReVerifyContextDefaultVal,
} from "./BankAccountReVerify/BankAccountReVerifyContext";
import { useUserContext } from "@shared/auth";

export interface BankAccountsViewProps {
  businessEntityId: number;
  data: Array<BankAccountType>;
  loading: boolean;
  onRemove: (
    bankAccount: BankAccountType,
    skipDialog?: boolean,
    onDone?: () => void
  ) => void;
  bankAccount: BankAccountType;
}

export const BankAccountsView: FC<BankAccountsViewProps> = ({
  data = [],
  businessEntityId,
  loading,
  onRemove,
}) => {
  const addDialog = useDialog();
  const user = useUserContext();
  return (
    <Page
      heading={
        <PageHeading
          title="Bank Accounts"
          actions={
            !loading &&
            data.length < 1 && (
              <Button onClick={addDialog.open}>Add bank account</Button>
            )
          }
        />
      }
    >
      <div className="mt-8 card">
        {!data || data.length === 0 ? (
          <>
            <p>You don&apos;t have any bank accounts.</p>
            <br />
          </>
        ) : null}
        {/*
        Since admins don't belong to an organization, and using Plaid API requires an
        organization id, we are not rendering BankAccountReVerifyProvider component for admins, since it will
        make requests to Plaid with a null organization id and will result in an error. Due to admins inability
        to use Plaid, they are not able to add bank accounts or re-verify them (which is just deleting the bank
        account and adding it again). This is expected and desired for security reasons.
        */}
        {user.isGlobalAdmin ? (
          <BankAccountReVerifyContext.Provider
            value={bankAccountReVerifyContextDefaultVal}
          >
            {data && data.length > 0 && (
              <BankAccountsTable
                data={data}
                onRemove={onRemove}
                loading={loading}
              />
            )}
          </BankAccountReVerifyContext.Provider>
        ) : (
          <BankAccountReVerifyProvider businessEntityId={businessEntityId}>
            {data && data.length > 0 && (
              <BankAccountsTable
                data={data}
                onRemove={onRemove}
                loading={loading}
              />
            )}
          </BankAccountReVerifyProvider>
        )}
        <AddAndAuthorizeBankAccountDialog
          businessId={businessEntityId}
          dialogProps={addDialog.getDialogProps()}
        />
      </div>
    </Page>
  );
};
