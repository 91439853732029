import React from "react";
import { Spinner } from "@shared/components/Spinner";
import { useLoadDwollaLib } from "@hooks/useLoadDwollaLib";
import { Message } from "primereact/message";
import FinancialAccountStatus from "../../../OrganizationApp/OnboardingPage/FinancialAccountStatus/FinancialAccountStatus";
import {
  DwollaCustomer,
  DwollaCustomerType,
  DwollaCustomerVerificationStatus,
} from "@apollo/ops";
import { getUserFriendlyDwollaStatusMessages } from "../dwolla";
import SpinnerOverlay from "../../SpinnerOverlay/SpinnerOverlay";
import { PoweredByDwollaComponent } from "../PoweredByDwollaComponent";

export interface CreateCustomerContainerProps {
  onSubmit: (response: any) => any;
  onErrorConfiguringDwolla: (errr: any) => any;
  error: Error;
  communicatingWithBackend: boolean;
  dwollaCustomer?: DwollaCustomer;
  dwollaCustomerType: DwollaCustomerType;
}

function CreateCustomerContainer({
  onSubmit,
  onErrorConfiguringDwolla,
  error,
  communicatingWithBackend,
  dwollaCustomer,
  dwollaCustomerType,
}: CreateCustomerContainerProps) {
  if (error) {
    return <Message severity={"error"} text={error.message} />;
  }

  if (
    dwollaCustomer &&
    dwollaCustomer.status !== DwollaCustomerVerificationStatus.Document &&
    dwollaCustomer.status !== DwollaCustomerVerificationStatus.Retry
  ) {
    const {
      status,
      message,
      severity,
      messageSeverity,
    } = getUserFriendlyDwollaStatusMessages(dwollaCustomer.status);
    return (
      <>
        {dwollaCustomer.status !==
          DwollaCustomerVerificationStatus.Suspended && (
          <h1 className="font-normal text-5xl mb-12">We are almost there.</h1>
        )}
        <h2 className="font-extrabold text-2xl">Business Information</h2>

        <FinancialAccountStatus
          messageSeverity={messageSeverity}
          statusSeverity={severity}
          status={status}
          message={message}
        />
      </>
    );
  }

  return (
    <CreateCustomer
      onSubmit={onSubmit}
      onErrorConfiguringDwolla={onErrorConfiguringDwolla}
      customer={dwollaCustomer}
      customerType={dwollaCustomerType}
      communicatingWithBackend={communicatingWithBackend}
    />
  );
}

export interface CreateCustomerProps {
  onSubmit: (response: any) => any;
  onErrorConfiguringDwolla: (errr: any) => any;
  customer?: DwollaCustomer;
  customerType: DwollaCustomerType;
  communicatingWithBackend: boolean;
}

/**
 * Create Verified Customer drop-in component
 *
 * @remarks
 * This components assumes that dwolla-components.js has been loaded, configured,
 * and is available in the global scope.
 */
const CreateCustomer: React.FC<CreateCustomerProps> = ({
  onSubmit,
  onErrorConfiguringDwolla,
  customer,
  customerType,
  communicatingWithBackend,
}) => {
  const { ready, error } = useLoadDwollaLib({
    success: onSubmit,
    error: onErrorConfiguringDwolla,
  });

  if (error) {
    //Upper error boundary will catch.
    throw error;
  }

  if (!ready && !error) {
    return <Spinner />;
  }

  switch (customerType) {
    /**
     * The dwolla-business-vcr component optionally takes a customerId prop that
     * enables a business verification to be resumed. The component will render
     * the proper form depending on the customer's verification status (i.e.
     * retry, document).
     *
     * If customerId is undefined, the component will start from the beginning
     * and a new customer will be created.
     */
    case DwollaCustomerType.Business: {
      return (
        <SpinnerOverlay spin={communicatingWithBackend}>
          <h2 className="font-extrabold text-2xl mb-8 text-center">
            Verify Your Business!
          </h2>
          <p className="mb-8 text-center">
            Welcome to Direct Beer! The information captured during this process
            is to verify your business and the identity of your controller with
            our third payment provider, Dwolla. When identifying your business,
            you will want to use the address associated with licensing
            documents.
          </p>
          <dwolla-business-vcr
            privacy="/privacy-policy"
            terms="/terms-of-service"
            customerId={customer?.id}
          ></dwolla-business-vcr>
          <PoweredByDwollaComponent />
        </SpinnerOverlay>
      );
    }
    case DwollaCustomerType.Unverified:
      return (
        <SpinnerOverlay spin={communicatingWithBackend}>
          <dwolla-customer-create
            privacy="/privacy-policy"
            terms="/terms-of-service"
          ></dwolla-customer-create>
          <PoweredByDwollaComponent />
        </SpinnerOverlay>
      );
    default:
      throw new Error(`Unsupported DwollaCustomerType ${customerType}`);
  }
};

export default CreateCustomerContainer;
