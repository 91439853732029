import React from "react";
import { PresaleProductDisplayFragment } from "@apollo/ops";
import { Table } from "@ui/Table";
import {
  Cell,
  CellProps,
  Column,
  TableInstance,
  useSortBy,
  useTable,
} from "react-table";
import { RouteComponentProps } from "@reach/router";
import { formatAmountInCents } from "@utilities/currency";
import SpinnerOverlay from "@components/SpinnerOverlay/SpinnerOverlay";
import { AdminProductLink } from "@components/Product/Product";
import { PreSaleProductFulfillmentToggle } from "@components/PreSaleProductsTable/PreSaleProductFulfillmentToggle/ProductFulfillmentExclusionReasonDropdown/Product/PreSaleProductFulfillmentToggle";

const columns: Array<Column<PresaleProductDisplayFragment>> = [
  {
    Header: "Product",
    Cell: ({ row }: CellProps<PresaleProductDisplayFragment, "string">) => (
      <AdminProductLink product={row.original.product} />
    ),
    accessor: (model) => model.product.name,
    width: 140,
  },
  {
    Header: "Package",
    accessor: (model) => model.product.packageType.displayName,
    width: 140,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    width: 140,
  },
  {
    Header: "Total",
    Cell: ({ value: totalInCents }) =>
      formatAmountInCents(totalInCents as number),
    accessor: "totalInCents",
    width: 140,
  },
  {
    Header: "Include in Fulfillment",
    Cell: (cell: Cell<PresaleProductDisplayFragment>) => {
      const preSaleProduct: PresaleProductDisplayFragment = cell.row.original;
      return (
        <PreSaleProductFulfillmentToggle preSaleProduct={preSaleProduct} />
      );
    },
    accessor: "excludeFromFulfillment",
    width: 400,
  },
];

interface ProductsTableProps extends RouteComponentProps {
  data: Array<PresaleProductDisplayFragment>;
  error?: Error;
  loading?: boolean;
}

export function PreSaleProductsTable({
  data,
  error,
  loading,
}: ProductsTableProps) {
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy
  ) as TableInstance<PresaleProductDisplayFragment>;
  if (error) {
    throw error;
  }

  return (
    <SpinnerOverlay spin={loading || false}>
      <Table instance={tableInstance} />
    </SpinnerOverlay>
  );
}
