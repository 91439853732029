import React from "react";
import { Link } from "@ui/Link";
import { adminTransferPath } from "@routes";

type AdminFundsTransferLinkProps = {
  fundsTransfer: {
    id: number;
  };
};

export function AdminFundsTransferLink({
  fundsTransfer,
}: AdminFundsTransferLinkProps) {
  return (
    <Link to={adminTransferPath(fundsTransfer.id)}>{fundsTransfer.id}</Link>
  );
}
