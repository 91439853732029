import React from "react";
import { RouteComponentProps } from "@reach/router";
import { SimplePage, PageHeading } from "@components/ui/Page";

export function UnauthorizedPage(_: RouteComponentProps) {
  return (
    <SimplePage heading={<PageHeading title="Unauthorized" />}>
      <div className="-mt-4">You are unauthorized to view this resource.</div>
    </SimplePage>
  );
}
