import React, { useMemo } from "react";
import { CellProps, Column, useTable, useSortBy } from "react-table";
import { Table } from "@ui/Table";
import { Maybe, ProductDisplayFragment } from "@apollo/ops";
import { AdminProductLink } from "@components/Product/Product";

export type AdminAdjustmentItemsTableRowData = {
  product: ProductDisplayFragment;
  pricePerUnit: string;
  units: number;
  adjustmentReason: string;
  notes: Maybe<string>;
  total: string;
};

export type AdminAdjustmentItemsDisplayTableProps = {
  adjustmentItems: Array<AdminAdjustmentItemsTableRowData>;
};

export function AdminAdjustmentItemsDisplayTable({
  adjustmentItems,
}: AdminAdjustmentItemsDisplayTableProps) {
  const columns: Array<Column<AdminAdjustmentItemsTableRowData>> = useMemo(
    () => [
      {
        Header: "Product",
        Cell: ({ value }: CellProps<AdminAdjustmentItemsTableRowData>) => (
          <AdminProductLink product={value} />
        ),
        accessor: (adjustmentItem) => adjustmentItem.product,
        width: 140,
      },
      {
        Header: "Price / Unit",
        accessor: "pricePerUnit",
        width: 140,
      },
      {
        Header: "Units",
        accessor: "units",
        width: 140,
      },
      {
        Header: "Reason",
        accessor: "adjustmentReason",
        width: 140,
      },
      {
        Header: "Notes",
        accessor: "notes",
        width: 140,
      },
      {
        Header: "Total",
        accessor: "total",
        width: 140,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: adjustmentItems,
    },
    useSortBy
  );

  return <Table instance={tableInstance} />;
}
