import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { Calendar } from "primereact/calendar";
import classNames from "classnames";
import { GeographicalStatesDropdown } from "@components/State/GeographicalStatesDropdown";

export type LicenseFormData = {
  title: string;
  number: string;
  startDate: Date;
  endDate: Date;
  documentUrl: string | null;
  stateV2Id: number;
};

type LicenseFormProps = {
  onSubmit: (data: LicenseFormData) => void | Promise<void>;
  defaultValues?: Partial<LicenseFormData>;
  submitText?: string;
};

export function LicenseForm({
  onSubmit,
  defaultValues = {},
  submitText = "Save",
}: LicenseFormProps) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<LicenseFormData>({
    defaultValues,
  });

  const wrappedOnSubmit = async (data: LicenseFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <div className="mb-4">
          <label
            htmlFor="state"
            className="block text-sm font-medium text-gray-700"
          >
            State
          </label>
          <div className="mt-1">
            <Controller
              name="stateV2Id"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: "State is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <GeographicalStatesDropdown
                  value={value}
                  onChange={onChange}
                  error={errors.stateV2Id}
                />
              )}
            />
            {errors.stateV2Id && (
              <small className="text-sm text-red-600">State is required</small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            License
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("title", {
                required: {
                  value: true,
                  message: "License is required",
                },
              })}
              id="title"
              placeholder="License Title"
              error={errors.title?.message}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="number"
            className="block text-sm font-medium text-gray-700"
          >
            License #
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("number", {
                required: {
                  value: true,
                  message: "License # is required",
                },
              })}
              id="number"
              placeholder="License #"
              error={errors.number?.message}
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="startDate"
            className="block text-sm font-medium text-gray-700"
          >
            Issue Date
          </label>
          <div className="mt-1">
            <Controller
              name="startDate"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: "Issue Date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  value={value}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  monthNavigator
                  placeholder="Pick the Issue Date"
                  className={classNames({
                    "border-red-300": errors.startDate,
                  })}
                />
              )}
            />
            {errors.startDate && (
              <small className="text-sm text-red-600">
                Issue Date is required
              </small>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="endDate"
            className="block text-sm font-medium text-gray-700"
          >
            Expiration Date
          </label>
          <div className="mt-1">
            <Controller
              name="endDate"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: "Expiration Date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  value={value}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  monthNavigator
                  placeholder="Pick the Expiration Date"
                  className={classNames({
                    "border-red-300": errors.endDate,
                  })}
                />
              )}
            />
            {errors.endDate && (
              <small className="text-sm text-red-600">
                Expiration Date is required
              </small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="documentUrl"
            className="block text-sm font-medium text-gray-700"
          >
            Document
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("documentUrl", {
                required: false,
              })}
              id="documentUrl"
              placeholder="Link to document"
              error={errors.documentUrl?.message}
            />
          </div>
        </div>
        <div className="mt-2">
          <Button size="lg" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  );
}
