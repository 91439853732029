import React from "react";
import {
  ProductDisplayFragment,
  ProductsFilterData,
  useProductAutocompleteLazyQuery,
} from "@apollo/gen/generatedOperations";
import {
  BackendAutcompleteProps,
  BackendAutocomplete,
} from "@ui/Autocomplete/BackendAutocomplete";
import { AdminProductLink } from "@components/Product/Product";
import { trim } from "lodash";
interface ProductBackendAutcompleteProps
  extends Omit<
    BackendAutcompleteProps<ProductDisplayFragment>,
    "data" | "error" | "refetch" | "renderItem"
  > {
  filters?: Partial<ProductsFilterData>;
}

export function ProductBackendAutocomplete({
  filters,
  ...props
}: ProductBackendAutcompleteProps) {
  const [
    search,
    { data, error, previousData },
  ] = useProductAutocompleteLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleQueryChange = ({ query }: { query: string }) => {
    search({
      variables: {
        filters: {
          ...filters,
          name: trim(query),
        },
      },
    });
  };

  const results = (data ?? previousData)?.searchProducts.results ?? [];

  return (
    <BackendAutocomplete
      data={results}
      error={error}
      refetch={handleQueryChange}
      {...props}
      renderItem={renderItem}
    />
  );
}

const renderItem = (product: ProductDisplayFragment) => {
  return <AdminProductLink product={product} />;
};
