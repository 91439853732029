import React from "react";
import { LedgerDetailsFragment } from "@apollo/ops";
import { formatToDollars } from "@utilities/currency";
import { Balances, BalancesData } from "@components/Balances";

type LedgerDetailsProps = {
  transferType?: "payment" | "deposit";
  ledger: LedgerDetailsFragment;
};

export function LedgerDetails({
  transferType = "payment",
  ledger,
}: LedgerDetailsProps) {
  const balancesData: BalancesData = [
    {
      balances: [
        {
          label: `Total due`,
          value: formatToDollars({
            cents: Number(ledger.due),
          }),
          summary: true,
        },
      ],
    },
    {
      title: transferType === "payment" ? "Payments" : "Bank deposits",
      balances: [
        {
          label: "Pending",
          value: formatToDollars({
            cents: Number(ledger.pendingTotal),
          }),
        },
        {
          label: "Completed",
          value: formatToDollars({
            cents: Number(ledger.completedTotal),
          }),
        },
        {
          label: "Total payments",
          value: formatToDollars({
            cents: Number(ledger.total),
          }),
          summary: true,
        },
      ],
    },
    {
      balances: [
        {
          label: `Balance`,
          value: formatToDollars({
            cents: ledger.balance,
          }),
          summary: true,
        },
      ],
    },
  ];

  return <Balances data={balancesData} />;
}
