import React from "react";
import {
  useSendDistributorPaymentDataQuery,
  useSendDistributorPaymentMutation,
} from "@apollo/ops";
import { LoadingIcon } from "@components/ui/Spinner";
import { ConfirmTransfer } from "@admin/components/ConfirmTransfer";

type SendDistributorPaymentProps = {
  orderId: number;
};

export function SendDistributorPayment({
  orderId,
}: SendDistributorPaymentProps) {
  const { data, loading, error } = useSendDistributorPaymentDataQuery({
    variables: {
      orderId,
    },
  });

  const [sendPayment] = useSendDistributorPaymentMutation({
    variables: {
      orderId,
    },
  });

  if (loading && !data) {
    return <LoadingIcon />;
  }

  if (data) {
    const { order } = data;

    if (
      order.__typename === "ProducerPurchaseOrder" &&
      order.distributorPaymentsLedger
    ) {
      return (
        <ConfirmTransfer
          ledger={order.distributorPaymentsLedger}
          transferProposal={order.distributorPaymentProposal}
          validationResult={order.canSendDistributorPayment}
          label="Send distributor payment"
          onSubmit={sendPayment}
        />
      );
    } else {
      throw Error(
        `Can use SendDistributorPayment with ${order.__typename} order`
      );
    }
  }

  if (error) {
    return <div>Failed to load payment data.</div>;
  }

  return null;
}
