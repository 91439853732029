import React, { MouseEventHandler, useMemo } from "react";
import { CellProps, Column, useTable } from "react-table";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { Table } from "@ui/Table";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import {
  AdminAdjustmentItemFragment,
  AdminOrderItemFragment,
  useAdminOrderItemsUpdateMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";
import styled from "styled-components";
import { TrashIcon } from "@heroicons/react/outline";
import { AdjustmentReasonDropdown } from "@components/AdjustmentReasonDropdown";

const TEN_SECONDS = 100000;
type AdjustmentItemField = AdminAdjustmentItemFragment;

type AdjustmentItemsFormData = {
  adjustmentItems: Array<AdjustmentItemField>;
};

type FieldArray = FieldArrayWithId<
  { adjustmentItems: Array<AdjustmentItemField> },
  "adjustmentItems",
  "id"
>;

export interface AdjustmentItemsEditableTableProps {
  adjustmentItems: Array<AdminAdjustmentItemFragment>;
  orderId: number;
  onFinish: () => void;
  orderItems: Array<AdminOrderItemFragment>;
}

/**
 * Component to that allows to view and edit in a tabular way the adjustment items of an order.
 *
 */
export function AdjustmentItemsEditingTableCmp({
  orderId,
  adjustmentItems,
  orderItems,
  onFinish,
}: AdjustmentItemsEditableTableProps) {
  const tableDefaultValue = {
    adjustmentItems,
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<AdjustmentItemsFormData>({
    defaultValues: tableDefaultValue,
  });

  const [updateOrder, { loading }] = useAdminOrderItemsUpdateMutation({
    onCompleted: () => {
      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "Adjustment items updated succesfully",
      });
      onFinish();
    },
    onError: (e) => {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(e),
      });
    },
  });
  /**
   * The useFieldArray hook helps work with an input of dynamic lists.
   *
   * See https://react-hook-form.com/api/usefieldarray/
   */
  const { fields, append, remove } = useFieldArray({
    control,
    name: "adjustmentItems",
  });

  /**
   * By default, the useFieldArray fields are uncontrolled, so changes to the
   * inputs aren't reflected in the fields array. This makes the entire field
   * array controlled so values are preserved.
   *
   * To see test the difference: add item, enter input values, click submit
   * Without this the values will appear to be cleared, with this they will persist.
   */
  const watchItemsArray = watch("adjustmentItems");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchItemsArray[index],
    };
  });

  const columns = useMemo<Array<Column<FieldArray>>>(
    () => [
      {
        Header: "Brewery",
        accessor: (adjustmentItem) =>
          adjustmentItem.orderItem?.product.producer.displayName,
      },
      {
        Header: "Product",
        accessor: "productId",
        Cell: ({ row }) => {
          const options = orderItems.map((orderItem) => ({
            label: orderItem.product.name,
            value: orderItem.productId,
          }));
          return (
            <Dropdown
              value={row.original.productId}
              options={options}
              {...register(`adjustmentItems.${row.index}.productId` as const, {
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            >
              {orderItems.map((orderItem) => (
                <option key={orderItem.productId} value={orderItem.productId}>
                  {orderItem.product.name}
                </option>
              ))}
            </Dropdown>
          );
        },
      },
      {
        Header: "Package",
        accessor: (adjustmentItem) => {
          const displayName =
            adjustmentItem.orderItem?.product.packageType.displayName;
          return <span className="break-words">{displayName}</span>;
        },
      },
      {
        Header: "Units",
        accessor: "units",
        Cell: ({ row }) => (
          <Input
            width={120}
            key={row.original.id}
            type="number"
            {...register(`adjustmentItems.${row.index}.units` as const, {
              required: {
                value: true,
                message: "This field is required",
              },
              min: {
                value: 1,
                message: "Quantity must be at least 1",
              },
            })}
            error={errors.adjustmentItems?.[row.index]?.units?.message}
            defaultValue={row.original.units}
          />
        ),
        width: 200,
      },
      {
        Header: "Reason",
        accessor: "adjustmentReasonId",
        Cell: ({ row }) => {
          return (
            <AdjustmentReasonDropdown
              className="w-full"
              value={row.original.adjustmentReasonId}
              {...register(
                `adjustmentItems.${row.index}.adjustmentReasonId` as const,
                {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }
              )}
            />
          );
        },
      },
      {
        Header: "Notes",
        accessor: "notes",
        Cell: ({ row }) => (
          <Input
            key={row.original.id}
            {...register(`adjustmentItems.${row.index}.notes` as const)}
            defaultValue={row.original.notes || ""}
          />
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: CellProps<AdjustmentItemField, undefined>) => (
          <TrashIcon
            onClick={() => remove(row.index)}
            width={20}
            className={"link"}
          />
        ),
      },
    ],
    [orderItems, register, errors.adjustmentItems, remove]
  );

  const tableInstance = useTable({
    columns,
    data: controlledFields as FieldArray[],
  });
  const handleAddItem: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    append({
      __typename: "AdjustmentItem",
    });
  };

  const onFormSubmit = handleSubmit(async (data) => {
    const formattedBackendData = {
      adjustmentItems: data.adjustmentItems.map((adjustmentItem) => ({
        id: adjustmentItem.id || undefined,
        productId: Number(adjustmentItem.productId),
        adjustmentReasonId: Number(adjustmentItem.adjustmentReasonId),
        units: Number(adjustmentItem.units),
        notes: adjustmentItem.notes || null,
      })),
    };
    try {
      await updateOrder({
        variables: {
          id: orderId,
          data: formattedBackendData,
        },
      });
    } catch (e) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(e),
        life: TEN_SECONDS,
      });
    }
  });

  return (
    <form onSubmit={onFormSubmit} className="clear-both">
      <StyleToReduceTableCellPaddings>
        <Table instance={tableInstance} />
      </StyleToReduceTableCellPaddings>

      <div className="mt-3 flex justify-between">
        <Button onClick={handleAddItem} disabled={loading}>
          Add adjustment
        </Button>
        <Button type="submit" disabled={loading}>
          Submit
        </Button>
      </div>
    </form>
  );
}

const StyleToReduceTableCellPaddings = styled.div`
  th {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  td {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
`;

export const AdminAdjustmentItemsEditTable = withErrorBoundary(
  AdjustmentItemsEditingTableCmp
);
