import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import { PreSaleInfo } from "./PreSaleInfo";
import { LoadingPage } from "@components/LoadingPage";
import { useAdminPreSaleQuery } from "@apollo/ops";
import { formatAsDate } from "@utilities/formatDate";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { CalendarIcon, LocationMarkerIcon } from "@heroicons/react/solid";

export function AdminPreSalePage({
  preSaleIdParam,
}: RouteComponentProps<{ preSaleIdParam: number }>) {
  const { data, error } = useAdminPreSaleQuery({
    variables: {
      id: Number(preSaleIdParam),
    },
  });

  if (data) {
    const { preSale } = data;

    const breadcrumbProps = {
      items: buildBreadcrumbs("Pre-Sales", `Pre-Sale #${preSaleIdParam}`),
    };

    const metaProps = {
      items: [
        {
          icon: LocationMarkerIcon,
          text: preSale.regionV2.friendlyName,
        },
        {
          icon: CalendarIcon,
          text:
            formatAsDate(preSale.start) + " to " + formatAsDate(preSale.end),
        },
      ],
    };

    return (
      <Page
        heading={
          <PageHeading
            title={"Pre-Sale #" + data.preSale.id}
            breadcrumbProps={breadcrumbProps}
            metaProps={metaProps}
          />
        }
      >
        <PreSaleInfo preSale={data.preSale} error={error} className={"mb-8"} />
      </Page>
    );
  }

  return <LoadingPage />;
}
