import React, { useState } from "react";
import { AddDistributor } from "./AddDistributor";
import { Button } from "../ui/Button";
import { Dialog } from "primereact/dialog";

export function AddDistributorModal() {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)} size="sm">
        Add Distributor
      </Button>
      <Dialog
        visible={visible}
        header="Add distributor"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <AddDistributor onAdd={() => setVisible(false)} />
      </Dialog>
    </>
  );
}
