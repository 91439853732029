import { FundsTransferStatus, FundsTransferStatusType } from "@apollo/ops";
import { Badge, BadgeProps, Color } from "@ui/Badge";
import React from "react";

type OrderPaymentStatusBadgeProps = Partial<BadgeProps> & {
  status: FundsTransferStatusType;
};

type OrderBalanceTransferStatusBadgeProps = Partial<BadgeProps> & {
  status: FundsTransferStatusType;
};

type DwollaTransferStatusBadgeProps = Partial<BadgeProps> & {
  status: FundsTransferStatus;
};

// Maps the raw transfer statuses to our higher-level status type badges
function getFundsTransferBadgeProps(paymentStatus: FundsTransferStatusType) {
  switch (paymentStatus) {
    case FundsTransferStatusType.Paid:
      return { label: "PAID", color: "green" as Color };
    case FundsTransferStatusType.Pending:
      return { label: "PENDING", color: "blue" as Color };
    case FundsTransferStatusType.Incomplete:
      return { label: "INCOMPLETE", color: "yellow" as Color };
    case FundsTransferStatusType.NotPaid:
      return { label: "NOT PAID", color: "gray" as Color };
  }
}

// Maps the raw dwolla transfer statuses to badges
function getDwollaTransferBadgeProps(transferStatus: FundsTransferStatus) {
  switch (transferStatus) {
    case FundsTransferStatus.Initiated:
      return { label: "INITIATED", color: "blue" as Color };
    case FundsTransferStatus.Pending:
      return { label: "PENDING", color: "blue" as Color };
    case FundsTransferStatus.Processed:
      return { label: "PROCESSED", color: "green" as Color };
    case FundsTransferStatus.Cancelled:
      return { label: "CANCELLED", color: "gray" as Color };
    case FundsTransferStatus.Rejected:
      return { label: "REJECTED", color: "red" as Color };
    case FundsTransferStatus.Failed:
      return { label: "FAILED", color: "red" as Color };
  }
}

export function OrderPaymentStatusBadge({
  status,
  ...rest
}: OrderPaymentStatusBadgeProps) {
  return Badge({ ...getFundsTransferBadgeProps(status), ...rest });
}

export function OrderBalanceTransferStatusBadge({
  status,
  ...rest
}: OrderBalanceTransferStatusBadgeProps) {
  return Badge({ ...getFundsTransferBadgeProps(status), ...rest });
}

export function DwollaTransferStatusBadge({
  status,
  ...rest
}: DwollaTransferStatusBadgeProps) {
  return Badge({ ...getDwollaTransferBadgeProps(status), ...rest });
}

export function ProducerPOStatusesBadges({
  producerPOBadge,
  distributorPOBadge,
}: {
  producerPOBadge: React.ReactNode;
  distributorPOBadge: React.ReactNode;
}) {
  let distributorStatusComponents = null;
  if (distributorPOBadge) {
    distributorStatusComponents = (
      <>
        <div className="text-xs mt-2.5 mb-0.5 text-gray-500">
          Distributor PO
        </div>
        {distributorPOBadge}
      </>
    );
  }

  return (
    <>
      {producerPOBadge}
      {distributorStatusComponents}
    </>
  );
}
