import React from "react";
import { ProgressBar } from "@ui";

type PaymentProgressBarProps = {
  order: {
    paidRetailOrdersCount: number;
    retailOrdersCount: number;
  };
};

/**
 * Visualizes the progress on retailer payments.
 */
export function PaymentProgressBar({ order }: PaymentProgressBarProps) {
  return (
    <div>
      <ProgressBar
        percent={order.paidRetailOrdersCount / order.retailOrdersCount}
      />
      <div className="pl-3 pt-1 text-gray-500 text-sm">
        {order.paidRetailOrdersCount} / {order.retailOrdersCount} orders paid
      </div>
    </div>
  );
}
