import React, { FC, useState } from "react";
import { Address } from "@apollo/ops";
import { Button, ButtonProps } from "@components/ui/Button";
import { Card, CardBody } from "@components/ui/Card/Card";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";
import { addressToShippingAddressForm } from "@utilities/formatAddress";
import { AddressFormData, AddressModal } from "./AddressModal";

interface Props {
  existingAddress: Address | null;
  email?: string | null;
  mutationFunc?: (address: AddressFormData) => Promise<void>;
  cardTitle: string;
  disabled: boolean;
}

export const AddressCard: FC<Props> = ({
  existingAddress,
  email = null,
  mutationFunc,
  cardTitle,
  disabled = false,
}) => {
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const openAddressModal = () => setIsAddressModalOpen(true);
  const closeAddressModal = () => setIsAddressModalOpen(false);

  const cardHeaderProps = {
    title: cardTitle,
    actions: (
      <Button
        data-testid="EditAddressButton"
        kind="tertiary"
        className={!existingAddress || disabled ? "invisible" : ""}
        onClick={openAddressModal}
      >
        {" "}
        Edit{" "}
      </Button>
    ),
  };

  const onAddressSubmit = async (data: AddressFormData) => {
    mutationFunc && (await mutationFunc(data));
    closeAddressModal();
  };
  const componentWhenNoAddress = disabled ? (
    "Address not in record"
  ) : (
    <ShippingAddressCreate onClick={openAddressModal} />
  );
  return (
    <>
      <Card headerProps={cardHeaderProps}>
        <CardBody>
          {existingAddress ? (
            <ShippingAddressDetails address={existingAddress} email={email} />
          ) : (
            componentWhenNoAddress
          )}
        </CardBody>
      </Card>
      <AddressModal
        isOpen={isAddressModalOpen}
        onClose={closeAddressModal}
        onSubmit={onAddressSubmit}
        defaultValues={addressToShippingAddressForm(existingAddress)}
      />
    </>
  );
};

interface DetailsProps {
  address: Address;
  email: string | null;
}

const ShippingAddressDetails: FC<DetailsProps> = ({ address, email }) => {
  return (
    <DescriptionListInCard>
      <LeftAlignedTerm term="Name" definition={address.name} />
      <LeftAlignedTerm term="Company" definition={address.company} />
      <LeftAlignedTerm term="Address 1" definition={address.address1} />
      <LeftAlignedTerm term="Address 2" definition={address.address2} />
      <LeftAlignedTerm term="City" definition={address.city} />
      <LeftAlignedTerm term="State" definition={address.state} />
      <LeftAlignedTerm term="Zip" definition={address.zip} />
      <LeftAlignedTerm term="Phone" definition={address.phone} />
      <LeftAlignedTerm term="Email" definition={email} hidden={!email} />
    </DescriptionListInCard>
  );
};

const ShippingAddressCreate: FC<ButtonProps> = (props) => {
  return (
    <Button data-testid="CreateShippingAddressButton" {...props}>
      Add Address
    </Button>
  );
};
