import React, { useMemo } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { Table } from "@ui/Table";
import { ProducerPurchaseOrderPackageSummaryTableDataFragment } from "@apollo/ops";

export type ProducerPurchaseOrderPackageSummaryTableProps = {
  packageSummary: Array<ProducerPurchaseOrderPackageSummaryTableDataFragment>;
};

export function ProducerPurchaseOrderPackageSummaryTable({
  packageSummary,
}: ProducerPurchaseOrderPackageSummaryTableProps) {
  const columns: Array<
    Column<ProducerPurchaseOrderPackageSummaryTableDataFragment>
  > = useMemo(() => {
    return [
      {
        Header: "Package",
        accessor: ({ packageType }) => packageType.displayName,
        width: 140,
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        width: 140,
      },
      {
        Header: "Total Weight",
        Cell: ({ value }) => `${value}lb`,
        accessor: "totalWeight",
        width: 140,
      },
    ];
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data: packageSummary,
    },
    useSortBy
  );

  return <Table instance={tableInstance} />;
}
