import React, { useState } from "react";
import { Address, useUpdateBusinessAddressMutation } from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import {
  addressToDisplayString,
  addressToShippingAddressForm,
} from "@utilities/formatAddress";
import { toast } from "@utilities/toast";
import { Button } from "@components/ui/Button";
import { Card } from "@components/ui/Card/Card";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";
import { PencilAltIcon } from "@heroicons/react/outline";
import { AddressFormData, AddressModal } from "../Address/AddressModal";

type AdminBusinessDetailsCardProps = {
  businessEntityId: number;
  fein: string | null;
  address: Address | null;
};

export function AdminBusinessDetailsCard({
  businessEntityId,
  fein,
  address,
}: AdminBusinessDetailsCardProps) {
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const openAddressModal = () => setIsAddressModalOpen(true);
  const closeAddressModal = () => setIsAddressModalOpen(false);

  const [updateBusinessAddress] = useUpdateBusinessAddressMutation();

  const onAddressSubmit = async (addressData: AddressFormData) => {
    try {
      const { data } = await updateBusinessAddress({
        variables: {
          businessEntityId,
          data: {
            ...addressData,
          },
        },
      });

      if (data) {
        closeAddressModal();
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Updated shipping address`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <>
      <Card
        headerProps={{
          title: "Business Information",
          actions: (
            <Button kind="tertiary" onClick={openAddressModal}>
              <PencilAltIcon className="h-6 w-6" />
            </Button>
          ),
        }}
      >
        <DescriptionListInCard>
          <LeftAlignedTerm
            term="Federal Employer Identification Number"
            definition={fein ? fein : "No FEIN Added"}
          />
          <LeftAlignedTerm
            term="Shipping Address"
            definition={
              address ? addressToDisplayString(address) : "No Address Added"
            }
          />
        </DescriptionListInCard>
      </Card>
      <AddressModal
        isOpen={isAddressModalOpen}
        onClose={closeAddressModal}
        onSubmit={onAddressSubmit}
        defaultValues={addressToShippingAddressForm(address)}
      />
    </>
  );
}
