import React from "react";
import {
  AdminProducerPurchaseOrderTableRowFragment,
  ProducerPurchaseOrdersSortInput,
  ProducerPurchaseOrderTableRowFragment,
} from "@apollo/ops";
import { CellProps, Column, SortingRule } from "react-table";
import { formatAmountInCents } from "@util/currency";
import { formatAsDate } from "@util/formatDate";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { PurchaseOrdersPaginatedTable } from "@components/Producer/ProducerPurchaseOrdersTable";
import { Link } from "@ui/Link";
import { producerOrderPath } from "@routes";

export const columns = (
  producerId: number
): Array<Column<AdminProducerPurchaseOrderTableRowFragment>> => [
  {
    Header: "Date",
    Cell: ({
      value,
    }: CellProps<ProducerPurchaseOrderTableRowFragment, "string">) =>
      formatAsDate(new Date(value)),
    accessor: "createdAt",
    width: 80,
  },
  {
    Header: "Region",
    accessor: (order) => order.preSale?.regionV2.friendlyName,
    id: "region",
    width: 120,
  },
  {
    Header: "Purchase Order",
    Cell: ({
      row,
    }: CellProps<ProducerPurchaseOrderTableRowFragment, "string">) => (
      <Link to={producerOrderPath(row.original.id)(producerId)}>
        {row.original.number}
      </Link>
    ),
    accessor: "number",
    disableSortBy: true,
    id: "number",
    width: 140,
  },
  {
    Header: "Invoice #",
    id: "externalInvoiceId",
    accessor: (order) => order.invoice?.externalInvoiceId,
    disableSortBy: true,
    width: 120,
  },
  {
    Header: "Invoice Total",
    Cell: ({ value }: { value: number | undefined }) =>
      value ? formatAmountInCents(value) : null,
    accessor: (order) => order.invoice?.externalAmountInCents,
    id: "invoiceTotal",
    width: 100,
  },
  {
    Header: "Order Total",
    Cell: ({
      value,
    }: CellProps<ProducerPurchaseOrderTableRowFragment, number>) =>
      formatAmountInCents(value),
    accessor: "amountInCents",
    id: "orderTotal",
    width: 100,
  },
];

type PurchaseOrdersPaginatedTableProps = {
  producerId: number;
  data: Array<AdminProducerPurchaseOrderTableRowFragment>;
  pagination?: UseOffsetPaginationResult;
  isShowingComponentOrders?: boolean;
  onChangeSort: (sortData: ProducerPurchaseOrdersSortInput) => void;
  initialSortBy: Array<SortingRule<ProducerPurchaseOrderTableRowFragment>>;
};

export function ProducerPurchaseOrdersPaginatedTable({
  producerId,
  data,
  pagination,
  initialSortBy,
  onChangeSort,
}: PurchaseOrdersPaginatedTableProps) {
  return (
    <PurchaseOrdersPaginatedTable
      columns={columns(producerId)}
      data={data}
      pagination={pagination}
      initialSortBy={initialSortBy}
      onChangeSort={onChangeSort}
    />
  );
}
