import React from "react";
import {
  RegisterRetailerMutation,
  useRegisterRetailerMutation,
  WhoAmIDocument,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import {
  RegisterRetailerForm,
  RegisterRetailerFormData,
} from "./RegisterRetailerForm";
import { omit } from "lodash";

type RegisterRetailerProps = {
  onRegister?: (data: RegisterRetailerMutation) => void;
};

export function RegisterRetailer({ onRegister }: RegisterRetailerProps) {
  const [registerRetailer] = useRegisterRetailerMutation();
  const handleSubmit = async (data: RegisterRetailerFormData) => {
    try {
      const result = await registerRetailer({
        variables: {
          data: omit(data, ["agreeToToSAndPP", "agreeToLicensed"]),
        },
        refetchQueries: [
          {
            query: WhoAmIDocument,
          },
        ],

        /**
         * Await the refetch queries to ensure that user information
         * is updated before calling the onRegister callback.
         */
        awaitRefetchQueries: true,
      });

      if (result.data && onRegister) {
        onRegister(result.data);
      }
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return <RegisterRetailerForm onSubmit={handleSubmit} />;
}
