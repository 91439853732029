import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { NumberParam, useQueryParams } from "use-query-params";
import {
  AdminPurchaseOrdersQuery,
  AdminPurchaseOrdersDocument,
  AdminPurchaseOrdersQueryVariables,
  ProducerPurchaseOrdersSortInput,
  SortDirection,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { AdminPurchaseOrdersPaginatedTable } from "@admin/components/AdminProducerPurchaseOrdersTable/AdminProducerPurchaseOrdersTable";
import {
  AdminPurchaseOrdersFilter,
  FilterData,
} from "./AdminPurchaseOrdersFilter";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";

export function AdminPurchaseOrdersPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    producerId: NumberParam,
    regionId: NumberParam,
  });

  const [sort, setSort] = useState<ProducerPurchaseOrdersSortInput>({
    columnName: "createdAt",
    sortDirection: SortDirection.Desc,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    AdminPurchaseOrdersQuery,
    AdminPurchaseOrdersQueryVariables
  >({
    options: {
      variables: {
        filters,
        sort,
      },
    },
    query: AdminPurchaseOrdersDocument,
    mapDataToTotal({ paginatedProducerPurchaseOrders }) {
      return paginatedProducerPurchaseOrders.total;
    },
  });

  const { data, previousData } = queryResult;

  const breadcrumbProps = {
    items: buildBreadcrumbs("Brewery Purchase Orders"),
  };

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  const onChangeSort = (sortData: ProducerPurchaseOrdersSortInput) => {
    if (sortData) {
      setSort(sortData);
    }
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Purchase Orders"
          breadcrumbProps={breadcrumbProps}
        />
      }
    >
      <div className="mb-3">
        <AdminPurchaseOrdersFilter
          onFilter={updateFilters}
          defaultValues={filters}
        />
      </div>
      {(data || previousData) && (
        <AdminPurchaseOrdersPaginatedTable
          data={
            data?.paginatedProducerPurchaseOrders.results ??
            previousData?.paginatedProducerPurchaseOrders.results ??
            []
          }
          pagination={pagination}
          onChangeSort={onChangeSort}
          initialSortBy={[
            {
              id: sort.columnName,
              desc: sort.sortDirection === SortDirection.Desc,
            },
          ]}
        />
      )}
    </Page>
  );
}
