import React, { useEffect, useCallback } from "react";
import { Card, CardBody } from "@components/ui/Card/Card";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";
import {
  AdminDwollaCustomerDetailsFragment,
  useAdminSyncDwollaCustomerMutation,
} from "@apollo/ops";
import { Button } from "@components/ui/Button/Button";
import { toast } from "@utilities/toast";
import { RefreshIcon } from "@heroicons/react/outline";
import ctl from "@netlify/classnames-template-literals";
import {
  BeneficialOwnershipCertificationStatusBadge,
  DwollaCustomerVerificationStatusBadge,
  displayCustomerType,
} from "@components/dwolla/DwollaCustomer";

export interface AdminDwollaCustomerCardProps {
  customer: AdminDwollaCustomerDetailsFragment | null;
}

export function AdminDwollaCustomerCard({
  customer,
}: AdminDwollaCustomerCardProps) {
  const [
    syncDwollaCustomer,
    { loading, error },
  ] = useAdminSyncDwollaCustomerMutation();

  const onSyncDwollaCustomer = useCallback(() => {
    if (customer) {
      syncDwollaCustomer({
        variables: {
          dwollaCustomerId: customer.id,
        },
      });
    }
  }, [syncDwollaCustomer, customer]);

  useEffect(() => {
    if (error) {
      toast.current?.show({
        detail: error.message,
        summary: "Something went wrong",
        severity: "error",
      });
    }
  }, [error]);

  return (
    <Card
      headerProps={{
        title: "Dwolla Customer",
        actions: (
          <Button
            disabled={loading}
            kind="tertiary"
            onClick={onSyncDwollaCustomer}
          >
            <RefreshIcon className={refreshIconClasses(loading)} />
          </Button>
        ),
      }}
    >
      {customer ? (
        <DescriptionListInCard>
          <LeftAlignedTerm term="Customer ID" definition={customer.id} />
          <LeftAlignedTerm
            term="Customer Type"
            definition={displayCustomerType(customer.type)}
          />
          <LeftAlignedTerm
            term="Verification Status"
            definition={
              <DwollaCustomerVerificationStatusBadge status={customer.status} />
            }
          />
          {customer.requiresBeneficialOwnershipCertification && (
            <LeftAlignedTerm
              term="Beneficial Ownership Certification Status"
              definition={
                <BeneficialOwnershipCertificationStatusBadge
                  status={customer.beneficialOwnerCertificationStatus}
                />
              }
            />
          )}
        </DescriptionListInCard>
      ) : (
        <CardBody>
          <span className="text-muted">No account</span>
        </CardBody>
      )}
    </Card>
  );
}

const refreshIconClasses = (loading: boolean) =>
  ctl(`
    w-6
    h-6
    ${loading && "animate-spin"}
 `);
