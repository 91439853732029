import React, { createContext, ReactNode, useContext } from "react";
import { useRetailerAppRootQuery } from "@apollo/ops";
import { retailerPath, retailerStorePath } from "@routes";
import { navigate } from "@reach/router";
import { BreadcrumbProps, buildBreadcrumbs } from "@components/ui/Breadcrumbs";

type Location = {
  id: number;
  name: string;
};

type RetailerAppContextValue = {
  organizationId: number;
  organization: {
    id: number;
    name: string;
    locations: Location[];
  };
  setCurrentStore: (id: number | null) => void;
  organizationPath: (path: string) => string;
  organizationBreadcrumbs: (...labels: string[]) => BreadcrumbProps;
};

const RetailerAppContext = createContext({} as RetailerAppContextValue);

export const useRetailerApp = () => useContext(RetailerAppContext);

type RetailerAppProviderProps = {
  organizationId: number;
  children: ReactNode;
};

export function RetailerAppProvider({
  children,
  organizationId,
}: RetailerAppProviderProps) {
  const { data, error, loading } = useRetailerAppRootQuery({
    variables: {
      organizationId,
    },
  });

  if (data) {
    const value = {
      organizationId,
      organization: data.organization,
      setCurrentStore: (storeId: number | null) => {
        if (storeId) {
          navigate(retailerStorePath(organizationId, storeId));
        } else {
          navigate(retailerPath(organizationId));
        }
      },
      organizationPath: (path: string) =>
        retailerPath(organizationId) + `/${path}`,
      organizationBreadcrumbs: (...labels: string[]) => ({
        items: buildBreadcrumbs(data.organization.name, ...labels),
      }),
    };

    return (
      <RetailerAppContext.Provider value={value}>
        {children}
      </RetailerAppContext.Provider>
    );
  }

  // loading should be super quick here, don't worry about a loading indicator since
  // it'll create a flash effect on the screen
  if (loading) {
    return null;
  }

  // let our error boundary handle any error
  if (error) {
    throw error;
  }

  // loading || data || error should always be true
  throw new Error("Unable to load data.");
}
