import React, { FC } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { withAuthorization } from "../shared/auth";
import {
  AdjustmentsIcon,
  ArchiveIcon,
  BeakerIcon,
  CashIcon,
  ChartBarIcon,
  ShoppingCartIcon,
  SpeakerphoneIcon,
  TruckIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { APP_ENV } from "../config";
import { AdminLayout } from "./AdminLayout";
import { SidebarNavigationElement } from "@components/ui/SidebarNavigation/SidebarNavigation";
import { ErrorBoundary } from "@components/ErrorBoundary";
import DistributorsContainer from "./AdminDistributorsContainer/AdminDistributorsContainer";
import PreSalesContainer from "./AdminPreSalesContainer/AdminPreSalesContainer";
import ProducersContainer from "./AdminProducersContainer/AdminProducersContainer";
import RetailersContainer from "./AdminRetailersContainer/AdminRetailersContainer";
import LogisticsPartnersContainer from "./AdminLogisticsPartnersContainer/AdminLogisticsPartnersContainer";
import TransfersContainer from "./AdminTransfersContainer/AdminTransfersContainer";
import PurchaseOrders from "./AdminPurchaseOrdersContainer/AdminPurchaseOrdersRouter";
import OrdersContainer from "./AdminOrdersContainer/AdminOrdersContainer";
import { AdminMerchandisingContainer } from "./AdminMerchandisingRouter/AdminMerchandisingRouter";
import AdminGlobalConfigRouter from "./AdminGlobalConfigRouter/AdminGlobalConfigRouter";
import Sandbox from "./SandboxContainer/SandboxContainer";
import { AdminDashboardPage } from "./AdminDashboardPage/AdminDashboardPage";
import { AdminGlobalSearch } from "./AdminGlobalSearch/AdminGlobalSearch";
import {
  adminGlobalAdminsPath,
  adminOfferWindowsPath,
  adminPackagesPath,
  adminPaths,
  adminProductsPath,
  adminPurchaseOrdersPath,
  adminRegionsPath,
} from "@routes";
import AdminShipmentsPage from "@admin/AdminShipmentsPage/AdminShipmentsPage";

const navElements: SidebarNavigationElement[] = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: ChartBarIcon,
  },
  {
    name: "Customers",
    icon: UserGroupIcon,
    children: [
      {
        path: "/admin/producers",
        name: "Breweries",
      },
      {
        path: "/admin/distributors",
        name: "Distributors",
      },
      {
        path: "/admin/retailers",
        name: "Retailers",
      },
      {
        path: "/admin/logistics-partners",
        name: "Logistics Partners",
      },
    ],
  },
  {
    path: "/admin/pre-sales",
    name: "Pre-Sales",
    icon: ArchiveIcon,
  },
  {
    path: adminPurchaseOrdersPath(),
    name: "Purchase Orders",
    icon: ShoppingCartIcon,
  },
  {
    name: "Merchandising",
    icon: SpeakerphoneIcon,
    children: [
      {
        path: adminOfferWindowsPath,
        name: "Offers",
      },
      {
        path: adminProductsPath,
        name: "Products",
      },
      {
        path: adminPackagesPath,
        name: "Packages",
      },
    ],
  },
  {
    name: "Global Config",
    icon: AdjustmentsIcon,
    children: [
      {
        path: adminRegionsPath,
        name: "Regions",
      },
      {
        path: adminGlobalAdminsPath,
        name: "Admins",
      },
    ],
  },
  {
    path: "/admin/transfers",
    name: "Transfers",
    icon: CashIcon,
  },
  {
    path: adminPaths.shipments.root,
    name: "Shipments",
    icon: TruckIcon,
  },
];

if (APP_ENV !== "production") {
  navElements.push({
    path: "/admin/sandbox",
    name: "Sandbox",
    icon: BeakerIcon,
  });
}

const AdminApp: FC<RouteComponentProps> = () => {
  return (
    <AdminGlobalSearch>
      <AdminLayout
        sidebarNavigationProps={{
          elements: navElements,
        }}
        gray
        kind="brand"
      >
        <ErrorBoundary>
          <Router>
            <Redirect from="/" to="dashboard" />
            <AdminDashboardPage path="dashboard/*" />
            <ProducersContainer path="producers/*" />
            <DistributorsContainer path="distributors/*" />
            <AdminMerchandisingContainer
              path={`${adminPaths.merchandising.root}/*`}
            />
            <AdminGlobalConfigRouter
              path={`${adminPaths.globalConfig.root}/*`}
            />
            <PurchaseOrders path={`${adminPaths.purchaseOrders.root}/*`} />
            <OrdersContainer path={`${adminPaths.orders.root}/*`} />
            <PreSalesContainer path={`${adminPaths.preSales.root}/*`} />
            <RetailersContainer path="retailers/*" />
            <LogisticsPartnersContainer path="logistics-partners/*" />
            <TransfersContainer path="transfers/*" />
            <AdminShipmentsPage path="shipments" />
            <Sandbox path="sandbox/*" />
            <Redirect from="*" to="/not-found" />
          </Router>
        </ErrorBoundary>
      </AdminLayout>
    </AdminGlobalSearch>
  );
};

export default withAuthorization<RouteComponentProps>({ isGlobalAdmin: true })(
  AdminApp
);
