export enum Severity {
  "info" = "info",
  "success" = "success",
  "danger" = "danger",
  "warn" = "warn",
}
export enum MessageSeverity {
  "info" = "info",
  "success" = "success",
  "error" = "error",
  "warn" = "warn",
}
