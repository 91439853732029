import React from "react";
import { Page } from "@ui/Page";
import { LoadingIcon } from "@ui/Spinner";

export function LoadingPage() {
  return (
    <Page>
      <div className="flex items-center align-middle h-24">
        <LoadingIcon />
      </div>
    </Page>
  );
}
