import React from "react";
import {
  ProducerOfferSearchDocument,
  ProducerOfferSearchQuery,
  ProducerOfferSearchQueryVariables,
  ProducerOfferTableDataFragment,
  ProductDisplayFragment,
} from "@apollo/ops";
import { RouteComponentProps } from "@reach/router";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { Page, PageHeading } from "@components/ui/Page";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import {
  ProducerOffersFilter,
  ProducerOffersFilterFormData,
} from "./ProducerOffersFilters";
import { ProducerOffersTable } from "./ProducerOffersTable";
import { DateTime } from "luxon";
import { ErrorAlert } from "@components/ui/Alert";

type ProducerProps = {
  businessEntityId: number;
} & RouteComponentProps;

export function ProducerOffersPage({ businessEntityId }: ProducerProps) {
  const [filters, setFilters] = useQueryParams({
    producerId: withDefault(NumberParam, businessEntityId),
    regionId: NumberParam,
    before: StringParam,
    after: StringParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    ProducerOfferSearchQuery,
    ProducerOfferSearchQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        filters,
      },
    },
    query: ProducerOfferSearchDocument,
    mapDataToTotal({ searchOffers }): number {
      return searchOffers?.total ?? 0;
    },
  });

  const { data, error } = queryResult;

  const updateFilters = ({
    regionId,
    dateRange,
  }: ProducerOffersFilterFormData) => {
    pagination.reset();

    const after = dateRange?.after
      ? DateTime.fromJSDate(dateRange.after).startOf("day").toISO()
      : null;

    const before = dateRange?.before
      ? DateTime.fromJSDate(dateRange.before).endOf("day").toISO()
      : null;

    setFilters({
      regionId,
      before,
      after,
    });
  };

  return (
    <Page heading={<PageHeading title="Offers" />}>
      {error && <ErrorAlert title="Failed to load offers" />}
      {data && (
        <>
          <div className="mb-3">
            <ProducerOffersFilter
              onFilter={updateFilters}
              defaultValues={mapQueryParamsToDefaultValues(filters)}
            />
          </div>

          <ProducerOffersTable
            data={mapOffersToTableData(data?.searchOffers.results)}
            pagination={pagination}
          />
        </>
      )}
    </Page>
  );
}

type QueryParamsData = {
  producerId: number;
  regionId: number | null | undefined;
  before: string | null | undefined;
  after: string | null | undefined;
};

function mapQueryParamsToDefaultValues({
  producerId,
  regionId,
  before,
  after,
}: QueryParamsData): Partial<ProducerOffersFilterFormData> {
  const dateRange =
    before && after
      ? {
          after: new Date(after),
          before: new Date(before),
        }
      : undefined;

  return {
    producerId: producerId,
    regionId: regionId ?? null,
    dateRange,
  };
}

export type ProducerOfferTableRowData = {
  platformPrice: number;
  wholesalePrice: number;
  quantity: number;
  product: ProductDisplayFragment;
  launchDate: Date;
  regionFriendlyName: string;
  earliestPickup?: Date;
};

function mapOffersToTableData(
  offersData: Array<ProducerOfferTableDataFragment>
): Array<ProducerOfferTableRowData> {
  return offersData.map((offerData) => ({
    wholesalePrice: offerData.wholesalePrice,
    platformPrice: offerData.platformPrice,
    quantity: offerData.quantity,
    product: offerData.product,
    launchDate: offerData.offerWindow.start,
    regionFriendlyName: offerData.offerWindow.regionV2.friendlyName,
    earliestPickup: offerData.earliestPickup,
  }));
}
