import React, { useState } from "react";
import { UpdateLogisticsPartner } from "./UpdateLogisticsPartner";
import { Button } from "@ui/Button";
import { Dialog } from "primereact/dialog";

type UpdateLogisticsPartnerModalProps = {
  logisticsPartnerId: number;
};

export function UpdateLogisticsPartnerModal({
  logisticsPartnerId,
}: UpdateLogisticsPartnerModalProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)}>Edit</Button>
      <Dialog
        visible={visible}
        header="Edit"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <UpdateLogisticsPartner
          logisticsPartnerId={logisticsPartnerId}
          onUpdate={() => setVisible(false)}
        />
      </Dialog>
    </>
  );
}
