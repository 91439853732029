import { useNavigate } from "@reach/router";
import {
  useAddSandboxRetailerMutation,
  SandboxRetailerType,
} from "@apollo/ops";
import { onboardingPath } from "@routes";

export const useAddSandboxRetailer = (retailerType: SandboxRetailerType) => {
  const navigate = useNavigate();

  const [
    addSandboxRetailer,
    addSandboxRetailerResult,
  ] = useAddSandboxRetailerMutation();

  const handleAddNewRetailer = async () => {
    const { data } = await addSandboxRetailer({
      variables: {
        type: retailerType,
      },
    });

    if (data) {
      navigate(onboardingPath(data.addSandboxRetailer.id));
    }
  };

  return {
    onSubmit: handleAddNewRetailer,
    disabled: addSandboxRetailerResult.loading,
  };
};

export const makeAddSandboxRetailerHook = (
  retailerType: SandboxRetailerType
) => () => useAddSandboxRetailer(retailerType);
