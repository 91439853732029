import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import { SandboxAddCard } from "./SandboxAddCard";
import items from "./items";
import { DwollaWebhooksTable } from "./tables";
import { DwollaSandboxSimulationsCard } from "./mutations";

function SandboxContainter(_: RouteComponentProps) {
  return (
    <Page
      heading={
        <PageHeading
          title="Sandbox Scaffolding"
          subtitle="Debug workflows by creating resources in preset states"
        />
      }
    >
      <div className="mt-8">
        <ul className="grid grid-cols-1 gap-6 tablet:grid-cols-2 laptop:grid-cols-3">
          {items.map((item) => (
            <SandboxAddCard key={item.title} {...item} />
          ))}
        </ul>
      </div>
      <div className="mt-8">
        <PageHeading
          title="Sandbox Data Model Views"
          subtitle="View Data from Back-end"
        />
        <div className="mt-8">
          <DwollaWebhooksTable />
        </div>
      </div>
      <div className="mt-8">
        <PageHeading
          title="Misc Mutations"
          subtitle="Non-workflow-scaffolding muations"
        />
        <div className="mt-8">
          <DwollaSandboxSimulationsCard />
        </div>
      </div>
    </Page>
  );
}

export default SandboxContainter;
