import React, { useState } from "react";
import {
  AdminProducerPurchaseOrderTableRowFragment,
  ProducerPurchaseOrdersDocument,
  ProducerPurchaseOrdersQuery,
  ProducerPurchaseOrdersQueryVariables,
  ProducerPurchaseOrdersSortInput,
  SortDirection,
} from "@apollo/ops";
import { RouteComponentProps } from "@reach/router";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { Page, PageHeading } from "@ui/Page";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { DateTime } from "luxon";
import { ErrorAlert } from "@ui/Alert";
import {
  ProducerPurchaseOrdersFilter,
  ProducerPurchaseOrdersFilterFormData,
} from "./ProducerPurchaseOrdersFilters";
import { ProducerPurchaseOrdersPaginatedTable } from "./ProducerPurchaseOrdersTable";
import { ProducerOffersFilterFormData } from "../../ProducerOffers/ProducerOffersFilters";

type ProducerProps = {
  businessEntityId: number;
} & RouteComponentProps;

export function ProducerPurchaseOrdersPage({
  businessEntityId,
}: ProducerProps) {
  const [filters, setFilters] = useQueryParams({
    producerId: withDefault(NumberParam, businessEntityId),
    regionId: NumberParam,
    number: StringParam,
    before: StringParam,
    after: StringParam,
  });

  const [sort, setSort] = useState<ProducerPurchaseOrdersSortInput>({
    columnName: "number",
    sortDirection: SortDirection.Desc,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    ProducerPurchaseOrdersQuery,
    ProducerPurchaseOrdersQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        id: businessEntityId,
        filters,
        sort,
      },
    },
    query: ProducerPurchaseOrdersDocument,
    mapDataToTotal({ producer }): number {
      return producer?.purchaseOrders?.total ?? 0;
    },
  });

  const { data, error } = queryResult;

  const updateFilters = ({
    number,
    regionId,
    dateRange,
  }: ProducerPurchaseOrdersFilterFormData) => {
    pagination.reset();

    const after = dateRange?.after
      ? DateTime.fromJSDate(dateRange.after).startOf("day").toISO()
      : null;

    const before = dateRange?.before
      ? DateTime.fromJSDate(dateRange.before).endOf("day").toISO()
      : null;

    setFilters({
      number,
      regionId,
      before,
      after,
    });
  };

  const onChangeSort = (sortData: ProducerPurchaseOrdersSortInput) => {
    if (sortData) {
      setSort(sortData);
    }
  };

  return (
    <Page heading={<PageHeading title="Purchase Orders" />}>
      {error && <ErrorAlert title="Failed to load orders" />}
      {data && (
        <>
          <div className="mb-3">
            <ProducerPurchaseOrdersFilter
              producerId={businessEntityId}
              onFilter={updateFilters}
              defaultValues={mapQueryParamsToDefaultValues(filters)}
            />
          </div>
          <ProducerPurchaseOrdersPaginatedTable
            producerId={data?.producer.organizationId}
            data={
              (data?.producer.purchaseOrders
                .results as unknown) as AdminProducerPurchaseOrderTableRowFragment[]
            }
            onChangeSort={onChangeSort}
            initialSortBy={[
              {
                id: sort.columnName,
                desc: sort.sortDirection === SortDirection.Desc,
              },
            ]}
            pagination={pagination}
          />
        </>
      )}
    </Page>
  );
}

type QueryParamsData = {
  producerId: number;
  regionId: number | null | undefined;
  before: string | null | undefined;
  after: string | null | undefined;
  number: string | null | undefined;
};

function mapQueryParamsToDefaultValues({
  producerId,
  regionId,
  before,
  after,
  number,
}: QueryParamsData): Partial<ProducerOffersFilterFormData> {
  const dateRange =
    before && after
      ? {
          after: new Date(after),
          before: new Date(before),
        }
      : undefined;

  return {
    producerId: producerId,
    regionId: regionId ?? null,
    dateRange,
    number: number ?? null,
  };
}
