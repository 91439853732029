import React, { ReactNode, useEffect } from "react";
import { usePage } from "./PageProvider";

type PageContentProps = {
  children: ReactNode;
};

export function PageContent({ children }: PageContentProps) {
  return (
    <div className="max-w-screen-2xl mx-auto px-4 tablet:px-6 laptop:px-8">
      {children}
    </div>
  );
}

export interface PageProps {
  children: ReactNode;
  heading?: ReactNode;
}

export function Page({ heading, children }: PageProps) {
  const { onView } = usePage();

  useEffect(() => {
    onView && onView();
  }, [onView]);

  return (
    <div className="pb-6">
      {heading && (
        <header className="w-full bg-white border-gray-200 border-b py-4 mb-6 sticky top-0 z-10">
          <PageContent>{heading}</PageContent>
        </header>
      )}
      <main>
        <PageContent>{children}</PageContent>
      </main>
    </div>
  );
}
