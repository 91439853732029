import React, { ElementType } from "react";

type PageHeadingMetaItem = {
  icon: ElementType;
  text: string;
};

export type PageHeadingMetaProps = {
  items: Array<PageHeadingMetaItem>;
};

/**
 * Page Heading meta component based off Tailwind UI
 * @see https://tailwindui.com/components/application-ui/headings/page-headings
 */
export function PageHeadingMeta({ items }: PageHeadingMetaProps) {
  return (
    <div className="flex flex-col tablet:flex-row tablet:flex-wrap tablet:mt-0 tablet:space-x-6">
      {items.map((item, index) => (
        <div
          key={index}
          className="mt-2 flex items-center text-sm text-gray-500"
        >
          <item.icon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {item.text}
        </div>
      ))}
    </div>
  );
}
