import React, { useState } from "react";
import { InviteUser } from "./InviteUser";
import { Button, ButtonProps } from "@components/ui/Button";
import { Dialog } from "primereact/dialog";

type InviteUserToOrganizationModalProps = {
  organization: {
    id: number;
    name: string;
  };
  buttonProps?: Partial<ButtonProps>;
};

export function InviteUserToOrganizationModal({
  organization,
  buttonProps = {},
}: InviteUserToOrganizationModalProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button {...buttonProps} onClick={() => setVisible(true)} size="sm">
        Invite user
      </Button>
      <Dialog
        visible={visible}
        header={`Invite user to ${organization.name}`}
        modal
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
      >
        <InviteUser
          organizationId={organization.id}
          onInvite={() => {
            setVisible(false);
          }}
        />
      </Dialog>
    </>
  );
}
