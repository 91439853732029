import React, { useState } from "react";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { Button } from "@components/ui/Button";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { AdminOrderItemFragment, PreSale } from "@apollo/ops";
import { formatAmountInCents } from "@utilities/currency";
import { AdminPreSaleOrderItemsEditTable } from "./AdminPreSaleOrderItemsEditTable";
import { AdminPreSaleOrderItemsDisplayTable } from "./AdminPreSaleOrderItemsDisplayTable";
import { AdminOrderItemsTableRowData } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderItemsPage/AdminOrderItemsTable/AdminOrderItemsDisplayTable";

export interface OrderItemsEditableTableProp {
  orderId: number;
  orderItems: Array<AdminOrderItemFragment>;
  preSale: PreSale;
  displayWholesalePricing: boolean;
  className?: string;
  editable?: boolean;
  filterProducerId?: number;
}

/**
 * Component to that allows to view and edit in a tabular way the order items of an order.
 *
 * @returns
 */
function AdminOrderItemsTableCmp({
  orderId,
  orderItems,
  preSale,
  className = "",
  editable = true,
  filterProducerId = undefined,
}: OrderItemsEditableTableProp) {
  //When editing == false, we see a read only version of the order items,
  //and when editing == true, input fields appear and allow the user to edit
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  return (
    <>
      <SectionHeading
        title="Items"
        actions={
          editable && (
            <Button onClick={toggleEditing} size="sm">
              {editing ? "Cancel" : "Edit"}
            </Button>
          )
        }
      />
      <div className={`clear-both ${className}`}>
        {editing ? (
          <AdminPreSaleOrderItemsEditTable
            orderItems={orderItems}
            orderId={orderId}
            preSale={preSale}
            filterProducerId={filterProducerId}
            onFinish={toggleEditing}
          />
        ) : (
          <AdminPreSaleOrderItemsDisplayTable
            orderItems={mapOrderItemsToTableData({
              orderItems,
            })}
          />
        )}
      </div>
    </>
  );
}

export const AdminPreSaleOrderItemsTable = withErrorBoundary(
  AdminOrderItemsTableCmp
);

function mapOrderItemsToTableData({
  orderItems,
}: {
  orderItems: Array<AdminOrderItemFragment>;
}): Array<AdminOrderItemsTableRowData> {
  return orderItems.map((orderItem) => ({
    finalQuantity: orderItem.finalQuantityDisplay,
    price: formatAmountInCents(orderItem.price),
    finalTotal: formatAmountInCents(orderItem.finalTotal),
    product: orderItem.product,
    quantity: orderItem.quantity,
    total: formatAmountInCents(orderItem.total),
    orderId: orderItem.orderId,
    offerId: orderItem.offerId,
    hasAdjustmentItems: orderItem.hasAdjustmentItems,
  }));
}
