import React, { useMemo } from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { AdminOfferWindowRouteParams } from "./AdminOfferWindowRouteProps";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { SidenavLayout } from "@ui/Layouts/SidenavLayout";
import { SecondaryNav } from "@components/SecondaryNav/SecondaryNav";
import { AdminOfferWindowOffersPage } from "./AdminOfferWindowOffersPage/AdminOfferWindowOffersPage";

export function AdminOfferWindowRouter({
  offerWindowIdParam,
}: RouteComponentProps<AdminOfferWindowRouteParams>) {
  if (offerWindowIdParam === undefined) {
    return <RedirectToNotFound />;
  }

  const parsedOfferWindowId = parseInt(offerWindowIdParam);
  if (isNaN(parsedOfferWindowId)) {
    return <RedirectToNotFound />;
  }

  return (
    <SanitizedAdminOfferWindowRouter offerWindowId={parsedOfferWindowId} />
  );
}

type SanatizedAdminOfferWindowRouterProps = {
  offerWindowId: number;
};

function SanitizedAdminOfferWindowRouter({
  offerWindowId,
}: SanatizedAdminOfferWindowRouterProps) {
  /**
   * Use a relative path in the `path` property
   */
  const navItems = useMemo(
    () => [
      {
        name: "Offers",
        path: ".",
      },
    ],
    []
  );

  return (
    <SidenavLayout
      nav={
        <SecondaryNav
          resourceType="Offer Window"
          resourceIdentifier={"#" + offerWindowId}
          items={navItems}
        />
      }
    >
      <Router>
        <AdminOfferWindowOffersPage path="/" offerWindowId={offerWindowId} />
      </Router>
    </SidenavLayout>
  );
}
