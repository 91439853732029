import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { defaultErrorHandler } from "@utilities/toast";
import { Button } from "@ui/Button";
import { Label } from "@components/ui/Input";
import { ProductBackendAutocomplete } from "@ui/Autocomplete/ProductBackendAutocomplete/ProductBackendAutocomplete";
import { OfferQuantityInput } from "@components/Offer/OfferInputs";
import { Calendar } from "primereact/calendar";
import { useCanOfferProductLazyQuery } from "@apollo/gen/generatedOperations";
import { ValidationResult } from "@components/ValidationResult/ValidationResult";

export type AddOfferSubmitData = {
  productId: number;
  quantity: number;
  earliestPickup?: Date;
};

type AddOfferFormProps = {
  onSubmit: (data: AddOfferSubmitData) => void | Promise<void>;
  offerWindowId: number;
};

type OfferFormData = {
  productId: number;
  quantity: string;
  earliestPickup?: Date;
};

export function AddOfferForm({ onSubmit, offerWindowId }: AddOfferFormProps) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<OfferFormData>();

  const wrappedOnSubmit: SubmitHandler<OfferFormData> = async (data) => {
    try {
      await onSubmit(toSubmitData(data));
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  const productId = watch("productId");
  const [
    canOfferProduct,
    { data: productValidationResult, loading: fetchingProductValidation },
  ] = useCanOfferProductLazyQuery({
    variables: {
      productId,
      offerWindowId,
    },
    onError: defaultErrorHandler,
  });

  useEffect(() => {
    if (productId) {
      canOfferProduct();
    }
  }, [productId]);

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid gap-4">
        <div>
          <Label>Product</Label>
          <div className="mt-1">
            <Controller
              name="productId"
              control={control}
              rules={{
                required: "Region is required",
              }}
              render={({ field: { onChange, value } }) => (
                <ProductBackendAutocomplete
                  onSelect={({ id }) => onChange(id)}
                  selectedId={value}
                />
              )}
            />
          </div>
        </div>
        {productValidationResult && (
          <ValidationResult
            validationResult={productValidationResult.canOfferProduct}
          />
        )}
        <div className="grid grid-cols-2 gap-4">
          <OfferQuantityInput
            register={register}
            fieldName="quantity"
            error={errors.quantity}
          />
        </div>

        <div className="mt-5">
          <div className="mt-1">
            <Label>Earliest Pickup Date</Label>
            <Controller
              name="earliestPickup"
              defaultValue={null}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  value={value}
                  appendTo="self"
                  onChange={({ value }) => onChange(value)}
                  monthNavigator
                  minDate={new Date()}
                  showIcon={true}
                />
              )}
            />
          </div>
        </div>
      </div>

      <Button
        size="lg"
        type="submit"
        disabled={isSubmitting || fetchingProductValidation}
        className="mt-5"
      >
        Add offer
      </Button>
    </form>
  );
}

function toSubmitData(formData: OfferFormData): AddOfferSubmitData {
  return {
    productId: formData.productId,
    quantity: parseInt(formData.quantity),
    earliestPickup: formData.earliestPickup,
  };
}
