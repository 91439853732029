import React from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { Input } from "@ui/Input";

type ProducerPurchaseOrdersInputProps = {
  producerId: number;
  onChange: (value: string | null) => unknown;
  value?: string | null;
  error?: FieldError;
};

export function ProducerPurchaseOrdersInput({
  onChange,
  value,
  error,
}: ProducerPurchaseOrdersInputProps) {
  return (
    <Input
      style={{ width: "100%" }}
      value={value || ""}
      onChange={(e) => {
        if (typeof e === "undefined") {
          onChange(null);
        } else {
          onChange(e.target.value);
        }
      }}
      className={classNames(
        {
          "border-red-300": error,
          "border-1": error,
          "text-red-600": error,
        },
        "block w-full tablet:text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 border-gray-300"
      )}
      placeholder="Search for an order"
    />
  );
}
