import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import {
  StoreSalesOrderItemFragment,
  useStoreSalesOrderQuery,
} from "@apollo/ops";
import { StoreRouterParams } from "../../StoreRouterParam";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { formatAmountInCents } from "@utilities/currency";
import { CalendarIcon, CurrencyDollarIcon } from "@heroicons/react/solid";
import { formatAsDate } from "@utilities/formatDate";
import { LoadingPage } from "@components/LoadingPage";
import { Table } from "@components/ui/Table";
import { Column, TableInstance, useTable } from "react-table";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { CancelledAlert } from "@components/Order/CancelledAlert";
import { StoreSalesOrderItemsTotals } from "./StoreSalesOrderItemsTotals";
import { StoreSalesOrderPayments } from "./StoreSalesOrderPaymentsSection/StoreSalesOrderPayments";

type StoreSalesOrderPageProps = RouteComponentProps<
  StoreRouterParams & {
    orderIdParam: string;
  }
>;

export function StoreSalesOrderPage({
  orderIdParam,
}: StoreSalesOrderPageProps) {
  if (orderIdParam === undefined) {
    return <RedirectToNotFound />;
  }

  const parsedOrderId = parseInt(orderIdParam);
  if (isNaN(parsedOrderId)) {
    return <RedirectToNotFound />;
  }

  return <SanitizedStoreSalesOrderPage orderId={parsedOrderId} />;
}

type SanitizedStoreSalesOrderPageProps = {
  orderId: number;
};

function SanitizedStoreSalesOrderPage({
  orderId,
}: SanitizedStoreSalesOrderPageProps) {
  const { data, loading, error } = useStoreSalesOrderQuery({
    variables: {
      orderId,
    },
  });

  if (data) {
    const { retailerSalesOrder } = data;
    const breadcrumbProps = {
      items: buildBreadcrumbs("Sales Orders", `#${retailerSalesOrder.number}`),
    };
    const metaProps = {
      items: [
        {
          icon: CalendarIcon,
          text: formatAsDate(retailerSalesOrder.placedOn),
        },
        {
          icon: CurrencyDollarIcon,
          text: formatAmountInCents(retailerSalesOrder.finalOrderTotalInCents),
        },
      ],
    };

    return (
      <Page
        heading={
          <PageHeading
            title={`Sales Order #${retailerSalesOrder.number}`}
            breadcrumbProps={breadcrumbProps}
            metaProps={metaProps}
          />
        }
      >
        {retailerSalesOrder.cancelledAt && (
          <div className="mb-5">
            <CancelledAlert cancelledAt={retailerSalesOrder.cancelledAt} />
          </div>
        )}

        <div className="mt-5">
          <div className="text-sm">Note</div>
          <div className="text-sm text-gray-700 leading-snug">
            Payments will be initiated from your bank account after a shipment
            has been delivered.
          </div>
        </div>
        <div className="mt-5">
          <OrderItemsTable data={retailerSalesOrder.items} />
        </div>
        <div className="mt-5">
          <StoreSalesOrderItemsTotals order={retailerSalesOrder} />
        </div>
        <div className="mt-5">
          <StoreSalesOrderPayments order={retailerSalesOrder} />
        </div>

        <div className="mt-10">
          <SectionHeading title="Products in this order" />
          <ProductsInThisOrder data={retailerSalesOrder.items} />
        </div>
      </Page>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  throw error || new Error("Failed to load page");
}

type TableProps = {
  data: Array<StoreSalesOrderItemFragment>;
};

function OrderItemsTable({ data }: TableProps) {
  const columns: Array<Column<StoreSalesOrderItemFragment>> = useMemo(
    () => [
      {
        Header: "Product",
        Cell: ({ row }) => {
          const { product } = row.original;
          return (
            <div className="inline-flex">
              <div className="h-[60px] w-[60px] mr-4">
                {product.productImage?.thumbnailUrl ? (
                  <img
                    src={product.productImage.thumbnailUrl}
                    className="h-full w-full object-contain"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center text-xs muted"></div>
                )}
              </div>
              <div className="leading-tight">
                <div className="font-medium">{product.name}</div>
                <div>{product.producer.displayName}</div>
                <div className="text-sm text-muted">
                  {product.packageType?.displayName}{" "}
                  {product.packageType && product.packageDate
                    ? "packaged on " + formatAsDate(product.packageDate)
                    : ""}
                </div>
              </div>
            </div>
          );
        },
        accessor: "product",
      },
      {
        Header: "Price",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "price",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Total",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "total",
      },
      {
        Header: "Final Quantity",
        accessor: "finalQuantity",
      },
      {
        Header: "Final Total",
        Cell: ({ value }) => (value ? formatAmountInCents(value) : null),
        accessor: "finalTotal",
      },
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<StoreSalesOrderItemFragment>;

  return <Table instance={tableInstance} />;
}

function ProductsInThisOrder({ data }: TableProps) {
  if (data.length === 0) {
    return <div>No items.</div>;
  }

  return (
    <div className="divide-y border">
      {data.map((item) => (
        <div key={item.product.id} className="bg-white">
          <div className="tablet:flex p-4">
            <div className="tablet:flex-none w-full tablet:w-56 tablet:h-56 tablet:mr-6">
              {item.product.productImage?.rawUrl ? (
                <img
                  src={item.product.productImage.rawUrl}
                  className="w-full max-h-full object-contain"
                />
              ) : (
                <div className="w-full h-full bg-gray-200 flex items-center justify-center text-xs muted"></div>
              )}
            </div>
            <div className="mt-3 px-2 tablet:flex-1 tablet:mt-0 tablet:px-0">
              <div className="leading-tight">
                <div className="font-bold text-lg">{item.product.name}</div>
                <div>{item.product.producer.displayName}</div>
              </div>
              <div className="mt-2 font-medium text-sm">
                {item.product.packageType.displayName}
              </div>
              {item.product.description && (
                <div className="mt-3 text-gray-600 text-sm">
                  {item.product.description}
                </div>
              )}
              <div className="mt-5">
                <div className="font-medium text-sm mb-2">Highlights</div>
                <ol className="text-gray-600 list-disc list-inside text-sm">
                  {item.product.abv && <li>{item.product.abv}% ABV</li>}
                  {item.product.rating && <li>{item.product.rating} rating</li>}
                  {item.product.packageDate && (
                    <li>
                      packaged on {formatAsDate(item.product.packageDate)}
                    </li>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
