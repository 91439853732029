import React, { createContext, ReactNode, useContext } from "react";
import { useRetailerApp } from "../RetailerAppProvider";

type StoreRouterContextValue = {
  storeId: number;
  storePath: (path: string) => string;
};

const StoreRouterContext = createContext({} as StoreRouterContextValue);

export const useStore = () => useContext(StoreRouterContext);

type RetailerAppProviderProps = {
  storeId: number;
  children: ReactNode;
};

export function StoreRouterProvider({
  children,
  storeId,
}: RetailerAppProviderProps) {
  const { organizationPath } = useRetailerApp();

  return (
    <StoreRouterContext.Provider
      value={{
        storeId,
        storePath: (path: string) =>
          organizationPath(`stores/${storeId}/${path}`),
      }}
    >
      {children}
    </StoreRouterContext.Provider>
  );
}
