/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-key */
import React from "react";
import { TableInstance } from "react-table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowNarrowUpIcon,
  ArrowNarrowDownIcon,
} from "@heroicons/react/solid";
import ctl from "@netlify/classnames-template-literals";
import { RefreshIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { verticalAlignClasses, VerticalAlignment } from "@ui/classes";

type TableProps<TData extends object> = {
  instance: TableInstance<TData>;
  pagination?: PaginationProps;
  align?: VerticalAlignment;
  upperCaseHeaders?: boolean;
};

export function Table<TData extends object>({
  instance,
  pagination,
  align = "top",
  upperCaseHeaders = true,
}: TableProps<TData>) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto tablet:-mx-6 laptop:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full tablet:px-6 laptop:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 laptop:rounded-sm">
            <TableTable
              instance={instance}
              align={align}
              upperCaseHeaders={upperCaseHeaders}
            />
            {pagination && <Pagination {...pagination} />}
          </div>
        </div>
      </div>
    </div>
  );
}

type TableTableProps<TData extends object> = {
  instance: TableInstance<TData>;
  align: VerticalAlignment;
  upperCaseHeaders?: boolean;
};

function TableTable<TData extends object = {}>({
  instance,
  align,
  upperCaseHeaders,
}: TableTableProps<TData>) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups,
  } = instance;

  const sortableHeaderClassNames = {
    underline: true,
    "px-6": true,
    "py-3": true,
    "text-left": true,
    "text-xs": true,
    "font-medium": true,
    "text-gray-500": true,
    "tracking-wider": true,
    uppercase: upperCaseHeaders,
  };
  const nonSortableHeaderClassNames = {
    "px-6": true,
    "py-3": true,
    "text-left": true,
    "text-xs": true,
    "font-medium": true,
    "text-gray-500": true,
    uppercase: upperCaseHeaders,
    "tracking-wider": true,
  };
  return (
    <table
      {...getTableProps()}
      className="min-w-full divide-y divide-gray-200 w-full"
    >
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps?.())}
                scope="col"
                className={
                  column.canSort
                    ? classnames(sortableHeaderClassNames)
                    : classnames(nonSortableHeaderClassNames)
                }
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <ArrowNarrowDownIcon
                        className="inline h-3"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowNarrowUpIcon
                        className="inline h-3"
                        aria-hidden="true"
                      />
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className="bg-white divide-y divide-gray-200"
      >
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={cellClasses({ align })}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

type PaginationProps = {
  page: number;
  pageSize: number;
  pageCount: number;
  totalCount: number | undefined;
  canNextPage: boolean;
  canPreviousPage: boolean;
  goToPage: (index: number) => unknown;
  nextPage: () => unknown;
  previousPage: () => unknown;
  resultsStart: number;
  resultsEnd: number;
  loading?: boolean;
};

function Pagination({
  totalCount,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  resultsStart,
  resultsEnd,
  loading = false,
}: PaginationProps) {
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 tablet:px-6">
      <div className="flex-1 flex justify-between tablet:hidden">
        <button
          disabled={!canPreviousPage}
          onClick={previousPage}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          disabled={!canNextPage}
          onClick={nextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden tablet:flex-1 tablet:flex tablet:items-center tablet:justify-between">
        <div className="flex align-middle space-x-3">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{resultsStart}</span> to{" "}
            <span className="font-medium">{resultsEnd}</span> of{" "}
            <span className="font-medium">{totalCount ?? "?"}</span> results
          </p>
          {loading && (
            <RefreshIcon className="h-5 w-5 animate-spin text-gray-500" />
          )}
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              disabled={!canPreviousPage}
              onClick={previousPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              disabled={!canNextPage}
              onClick={nextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

type CellClassesOptions = {
  align: VerticalAlignment;
};

const cellClasses = ({ align }: CellClassesOptions) =>
  ctl(`
    px-6
    py-4
    text-sm
    text-gray-900
    whitespace-pre-line
    ${verticalAlignClasses[align]}
  `);
