import { useNavigate } from "@reach/router";
import {
  useAddSandboxProducerMutation,
  SandboxProducerType,
} from "@apollo/ops";
import { onboardingPath } from "@routes";

export const useAddSandboxProducer = (producerType: SandboxProducerType) => {
  const navigate = useNavigate();

  const [
    addSandboxProducer,
    addSandboxProducerResult,
  ] = useAddSandboxProducerMutation();

  const handleAddNewProducer = async () => {
    const { data } = await addSandboxProducer({
      variables: {
        type: producerType,
      },
    });

    if (data) {
      navigate(onboardingPath(data.addSandboxProducer.id));
    }
  };

  return {
    onSubmit: handleAddNewProducer,
    disabled: addSandboxProducerResult.loading,
  };
};

export const makeAddSandboxProducerHook = (
  producerType: SandboxProducerType
) => () => useAddSandboxProducer(producerType);
