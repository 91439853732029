import React from "react";
import { formatAmountInCents } from "@utilities/currency";
import { TableSummary } from "@ui/TableSummary";
import { AdminOrderItemsQuery } from "@apollo/gen/generatedOperations";

type AdminOrderItemsTotalsProps = {
  order: AdminOrderItemsQuery["order"];
};

export function AdminOrderItemsTotals({ order }: AdminOrderItemsTotalsProps) {
  const orderTotals: Record<string, string> = {
    "Order Item Total": formatAmountInCents(order.amountInCents),
    "Adjustments Total": formatAmountInCents(order.adjustmentTotalInCents, {
      oppositeSign: true,
    }),
    "Final Order Total": formatAmountInCents(order.finalOrderTotalInCents),
  };

  if (order.__typename === "DistributorPurchaseOrder") {
    orderTotals["Distributor Fee"] = formatAmountInCents(order.distributorFee);
  }

  return <TableSummary rows={orderTotals} />;
}
