import React, { FC } from "react";
import { Page, PageHeading } from "@components/ui/Page";
import { TransactionsEnhancerResult } from "../TransactionsEnhancer";
import { AdminTransactionsTable } from "./AdminTransactionsTable";
import SpinnerOverlay from "@components/SpinnerOverlay/SpinnerOverlay";
import { AdminFundsTransferFieldsFragment } from "@apollo/ops";
import { InitiateTransfer } from "../InitiateTransfer/InitiateTransfer";
import { AdminTransactionsFilters } from "@admin/AdminTransfersContainer/AdminTransactions/AdminTransactionsFilters";

export const TransactionsView: FC<TransactionsEnhancerResult> = ({
  data = [] as AdminFundsTransferFieldsFragment[],
  loading,
  offset,
  setOffset,
  monthFilter,
  setMonthFilter,
  refetchTransfers,
}) => {
  return (
    <Page heading={<PageHeading title="Transfers" />}>
      <InitiateTransfer
        path="/"
        onInitiateTransfer={() => refetchTransfers()}
      />

      <SpinnerOverlay
        spin={loading && (!data || data.length === 0) ? true : false}
      >
        {!loading ? (
          <div className="mt-8 card">
            {!loading && !monthFilter && (!data || data.length === 0) ? (
              <>
                <p>No Transaction History Available</p>
                <br />
              </>
            ) : null}

            <div className="mb-3">
              <AdminTransactionsFilters
                onFilter={setMonthFilter}
                defaultValues={{ date: new Date(monthFilter) }}
              />
            </div>
            {data && (data.length > 0 || monthFilter) && (
              <AdminTransactionsTable
                transactions={data}
                offset={offset}
                setOffset={setOffset}
                admin={true}
              />
            )}
          </div>
        ) : null}
      </SpinnerOverlay>
    </Page>
  );
};
