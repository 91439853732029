import React from "react";
import { IconTag } from "@components/IconTag";
import { WarningAlert } from "@components/ui/Alert";
import { PlaidVerificationStatus as PlaidVerificationStatusEnum } from "@apollo/ops";
import { Severity } from "@shared/types/Severity";
import { UNKOWN_ERROR } from "@utilities/i18n";
import { UpdatePlaidItem } from "@components/plaid/UpdatePlaidItem/UpdatePlaidItem";

export const displayVerificationStatus = (
  verificationStatus: PlaidVerificationStatusEnum | null | undefined
) => {
  switch (verificationStatus) {
    case null: // null indicates the Plaid Item was instantly verified, see PlaidVerificationStatus type documentation
    case PlaidVerificationStatusEnum.ManuallyVerified:
    case PlaidVerificationStatusEnum.AutomaticallyVerified:
      return "Verified";
    case PlaidVerificationStatusEnum.PendingAutomaticVerification:
      return "Pending automatic verification";
    case PlaidVerificationStatusEnum.PendingManualVerification:
      return "Pending manual verification";
    case PlaidVerificationStatusEnum.VerificationExpired:
      return "Verification expired";
    case PlaidVerificationStatusEnum.VerificationFailed:
      return "Verification failed";
    case undefined:
      return UNKOWN_ERROR;
  }
};

export const verificationStatusSeverity = (
  verificationStatus: PlaidVerificationStatusEnum | null | undefined
) => {
  switch (verificationStatus) {
    case null: // null indicates the Plaid Item was instantly verified, see PlaidVerificationStatus type documentation
    case PlaidVerificationStatusEnum.ManuallyVerified:
    case PlaidVerificationStatusEnum.AutomaticallyVerified:
      return Severity.success;
    case PlaidVerificationStatusEnum.PendingAutomaticVerification:
    case PlaidVerificationStatusEnum.PendingManualVerification:
      return Severity.warn;
    case PlaidVerificationStatusEnum.VerificationExpired:
    case PlaidVerificationStatusEnum.VerificationFailed:
    case undefined:
      return Severity.danger;
  }
};

export interface VerificationStatusTagProps {
  verificationStatus: PlaidVerificationStatusEnum | null | undefined;
}

export const VerificationStatusTag = ({
  verificationStatus,
}: VerificationStatusTagProps) => (
  <IconTag
    severity={verificationStatusSeverity(verificationStatus)}
    value={displayVerificationStatus(verificationStatus)}
  />
);

export interface PlaidVerificationStatusProps {
  plaidItem: {
    id: number;
    verificationStatus: PlaidVerificationStatusEnum | null;
  };
}

const PENDING_MANUAL_VERIFICATION_MESSAGE = `Within 1 to 2 business days of adding the account, two micro transactions
   should appear on your bank account statement.`;

const PENDING_AUTOMATIC_VERIFICATION_MESSAGE = `Automatic verification is in progress. Check back in a couple days.`;
const VERIFICATION_FAILED =
  "Your bank account verification failed. Please try again with a different account.";
interface PlaidItemStatusAlertProps {
  plaidItem: {
    id: number;
    verificationStatus: PlaidVerificationStatusEnum | null;
  };
}

/**
 * Display an alert for any pending actions required on a Plaid Item. If there
 * are no actions, return null and display nothing.
 *
 * @param status - PlaidItem Account verificationStatus
 */
export function PlaidItemStatusAlert({
  plaidItem,
}: PlaidItemStatusAlertProps & { className?: string }) {
  switch (plaidItem.verificationStatus) {
    case PlaidVerificationStatusEnum.PendingAutomaticVerification:
      return (
        <WarningAlert
          title="Pending automatic verification"
          description={PENDING_AUTOMATIC_VERIFICATION_MESSAGE}
        />
      );
    case PlaidVerificationStatusEnum.PendingManualVerification:
      return (
        <WarningAlert
          title="Pending micro-deposit verification"
          description={PENDING_MANUAL_VERIFICATION_MESSAGE}
        >
          <UpdatePlaidItem plaidItemId={plaidItem.id} />
        </WarningAlert>
      );
    case PlaidVerificationStatusEnum.VerificationFailed:
      return (
        <WarningAlert
          title="Verification failed"
          description={VERIFICATION_FAILED}
        />
      );
    default:
      return null;
  }
}
