import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "@ui/Button";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@util/toast";
import { LabeledInput } from "@ui/Input";
import { getMessageToUserFromBackendError } from "@util/i18n";
import { DwollaCustomerBillingInfoFragment } from "@apollo/gen/generatedOperations";

export type UpdateDwollaCustomerFormData = {
  id: string;
  email: string;
};

export type CloneProductFormData = {
  name: string;
  producerId: number;
  packageTypeId: number;
  packageDate: Date;
  abv: string;
  description?: string;
  style: string;
  rating: string;
  breweryNetPrice: number;
};

type DwollaCustomerFormProp = {
  dwollaCustomer: DwollaCustomerBillingInfoFragment;
  onSubmit: (data: UpdateDwollaCustomerFormData) => void | Promise<void>;
};

export function DwollaCustomerForm({
  onSubmit,
  dwollaCustomer,
}: DwollaCustomerFormProp) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<DwollaCustomerBillingInfoFragment>({
    defaultValues: {
      email: dwollaCustomer.email,
      id: dwollaCustomer.id,
    },
  });

  const wrappedOnSubmit = handleSubmit(async (data) => {
    try {
      await onSubmit(data);
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: getMessageToUserFromBackendError(error),
        });
      }
    }
  });

  return (
    <form onSubmit={wrappedOnSubmit} autoComplete="off">
      <div className="grid laptop:grid-cols-2 gap-4">
        <div className="laptop:col-span-2 mb-1">
          <LabeledInput
            label="Email"
            inputProps={{
              type: "email",
              error: errors.email?.message,
              ...register("email", {
                required: {
                  value: true,
                  message: "Email is required",
                },
              }),
            }}
          />
        </div>
      </div>
      <div>
        <Button size="lg" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
}
