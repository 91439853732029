import React from "react";
import { compose } from "lodash/fp";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import {
  CashIcon,
  CreditCardIcon,
  DocumentTextIcon,
  LibraryIcon,
  ShoppingCartIcon,
  TagIcon,
} from "@heroicons/react/outline";
import { withOnboardingComplete } from "@hoc/withOnboardingComplete";
import {
  withBrewery,
  WithBreweryInjectedProps,
} from "@hoc/WithBrewery/WithBrewery";
import { withAuthentication } from "@shared/auth";
import { SidebarLayoutWithLogout } from "@ui/Layouts/SidebarLayoutWithLogout";
import { ProducerTransactions } from "./ProducerTransactions/ProducerTransactions";
import { BankAccounts } from "../OrganizationApp/BankAccounts";
import { SidebarNavigationElement } from "@components/ui/SidebarNavigation/SidebarNavigation";
import { ProducerLicenses } from "./ProducerLicenses/ProducerLicenses";
import { ProducerOffersPage } from "./ProducerOffers/ProducerOffersPage";
import { ProducerPurchaseOrdersPage } from "./ProducerPurchaseOrders/ProducerPurchaseOrders/ProducerPurchaseOrdersPage";
import { producerPath } from "@routes";
import { BillingPage } from "@components/BillingPage/BillingPage";
import { ProducerPurchaseOrderRouter } from "./ProducerPurchaseOrders/ProducerPurchaseOrder/ProducerPurchaseOrderRouter";
import { ProducerDirectBeerDashboard } from "./ProducerDashboard/ProducerDirectBeerDashboard";

type ProducerAppProps = RouteComponentProps<{ breweryId?: string }> &
  WithBreweryInjectedProps;

const getNavElements = (basePath: string): SidebarNavigationElement[] => [
  {
    path: `${basePath}/transactions`,
    name: "Transactions",
    icon: CashIcon,
  },
  {
    path: `${basePath}/bank-accounts`,
    name: "Bank Accounts",
    icon: LibraryIcon,
  },
  {
    path: `${basePath}/licenses`,
    name: "Licenses",
    icon: DocumentTextIcon,
  },
  {
    path: `${basePath}/offers`,
    name: "Offers",
    icon: TagIcon,
  },
  {
    path: `${basePath}/billing`,
    name: "Billing",
    icon: CreditCardIcon,
  },
  {
    path: `${basePath}/orders`,
    name: "Purchase Orders",
    icon: ShoppingCartIcon,
  },
  {
    name: "Dashboards",
    children: [
      {
        name: "Direct Beer",
        path: `${basePath}/dashboard/direct-beer`,
      },
    ],
    icon: LibraryIcon,
  },
];

function ProducerAppBase({ brewery }: ProducerAppProps) {
  const businessEntityId = brewery.businesses[0].id;
  return (
    <SidebarLayoutWithLogout
      sidebarNavigationProps={{
        elements: getNavElements(producerPath(brewery.id)),
      }}
      gray
      kind="indigo"
    >
      <Router>
        <Redirect from="/" to="transactions" />
        <ProducerTransactions
          path="transactions"
          businessEntityId={businessEntityId}
        />
        <BankAccounts
          path="bank-accounts"
          businessEntityId={businessEntityId}
        />
        <ProducerLicenses path="licenses" businessEntityId={businessEntityId} />
        <ProducerOffersPage path="offers" businessEntityId={businessEntityId} />
        <BillingPage path="billing" organizationId={brewery.id} />
        <ProducerPurchaseOrdersPage
          path="orders"
          businessEntityId={businessEntityId}
        />
        <ProducerPurchaseOrderRouter path="orders/:orderIdParam/*" />
        <ProducerDirectBeerDashboard
          path="dashboard/direct-beer"
          producerId={businessEntityId}
        />
        <Redirect from="*" to="/not-found" />
      </Router>
    </SidebarLayoutWithLogout>
  );
}

export const ProducerApp = compose(
  withAuthentication(),
  withBrewery,
  withOnboardingComplete<ProducerAppProps>({
    getBusinessIdFromProps: (props) => props.brewery.businesses[0].id,
  })
)(ProducerAppBase);
