import Bugsnag from "@bugsnag/browser";
import { DwollaErrorToBackend } from "./DwollaErrors";

export const handleDwollaDropInError = ({
  error,
  setError,
}: {
  error: any;
  setError?: (e: any) => void;
}) => {
  // The dwolla drop in component mostly shows the error to the user within the component
  // We should allow the component to show the error and notify bugsnag just in case theres
  // an error that doesn't get shown to the user
  if (error instanceof DwollaErrorToBackend) {
    Bugsnag.notify(error, function (event) {
      event.severity = "error";
    });
    if (setError) {
      setError(new Error("An error has ocurred. Please contact support."));
    }
  } else {
    Bugsnag.notify(error, function (event) {
      event.severity = "info";
    });
  }

  return;
};
