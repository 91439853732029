import React from "react";
import { AdminOrderSummaryFragment } from "@apollo/ops";
import { Card, CardBody } from "@ui/Card/Card";
import { StackedTerm } from "@ui/DescriptionList";
import { formatAmountInCents, formatToDollars } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";

type AdminOrderSummaryCardProps = {
  order: AdminOrderSummaryFragment;
};

export function AdminOrderSummaryCard({ order }: AdminOrderSummaryCardProps) {
  const externalTotal = order.invoice?.externalAmountInCents
    ? formatAmountInCents(order.invoice?.externalAmountInCents)
    : "";

  return (
    <Card title="Summary">
      <CardBody>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 tablet:grid-cols-2">
          <div className="col-span-2">
            <StackedTerm
              term="Date"
              definition={formatAsDate(new Date(order.createdAt))}
            />
          </div>
          <StackedTerm term="Seller" definition={order.seller.displayName} />
          <StackedTerm term="Buyer" definition={order.buyerName} />
          <StackedTerm
            term="Order Total"
            definition={formatAmountInCents(order.amountInCents)}
          />
          <StackedTerm
            term="Payment Total"
            definition={formatAmountInCents(order.paymentsLedger.total)}
          />
          <StackedTerm
            term="Delivered at"
            definition={formatAsDate(order.deliveredAt)}
            hidden={!order.deliveredAt}
          />
          <StackedTerm term="Invoice Total" definition={externalTotal} />
          <StackedTerm
            term="Invoice #"
            definition={order.invoice?.externalInvoiceId}
          />
          {order.__typename === "DistributorPurchaseOrder" && (
            <StackedTerm
              term="Distributor Fee"
              definition={formatAmountInCents(order.distributorFee)}
            />
          )}
          {order.__typename === "ProducerPurchaseOrder" && (
            <>
              <StackedTerm
                term="Platform Fee"
                definition={formatToDollars({
                  cents: Number(order.platformFee),
                })}
              />

              <StackedTerm
                term={"Earliest Pickup"}
                definition={
                  order.earliestPickup
                    ? formatAsDate(order.earliestPickup)
                    : "No pickup dates set"
                }
              />
            </>
          )}
        </dl>
      </CardBody>
    </Card>
  );
}
