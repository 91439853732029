import React from "react";
import { Button } from "@components/ui/Button";
interface FormFooterProps {
  onSubmit: (props: any) => any;
  onCancel: (props: any) => any;
  submitText?: string;
  cancelText?: string;
}
const FormFooter = ({
  submitText = "Save",
  cancelText = "Cancel",
  onSubmit,
  onCancel,
}: FormFooterProps) => (
  <div className={"p-dialog-footer"}>
    <Button onClick={onCancel} kind="secondary">
      {cancelText}
    </Button>
    <Button onClick={onSubmit}>{submitText}</Button>
  </div>
);

export default FormFooter;
