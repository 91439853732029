import {
  AdminProducerPurchaseOrderTableRowFragment,
  ProducerPurchaseOrdersSortInput,
} from "@apollo/ops";
import { AdminOrderLink } from "@components/Order/Order";
import { AdminProducerLink } from "@components/Producer/Producer";
import { PurchaseOrdersPaginatedTable } from "@components/Producer/ProducerPurchaseOrdersTable";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { Table } from "@ui/Table";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";
import React from "react";
import {
  CellProps,
  Column,
  SortingRule,
  TableInstance,
  useSortBy,
  useTable,
} from "react-table";
import { ProducerOrderStatusTable } from "../ProducerPurchaseOrder/ProducerOrderStatusTable";

export const columns: Array<
  Column<AdminProducerPurchaseOrderTableRowFragment>
> = [
  {
    Header: "Date",
    Cell: ({
      value,
    }: CellProps<AdminProducerPurchaseOrderTableRowFragment, "string">) =>
      formatAsDate(new Date(value)),
    accessor: "createdAt",
    width: 90,
  },
  {
    Header: "Order Number",
    Cell: ({
      row,
    }: CellProps<AdminProducerPurchaseOrderTableRowFragment, "string">) => (
      <>
        <AdminOrderLink order={row.original} />
        {row.original.invoice?.externalInvoiceId && (
          <div className="text-muted">
            {row.original.invoice?.externalInvoiceId}
          </div>
        )}
        <div className="text-sm text-muted">
          {row.original.preSale?.regionV2.friendlyName}
        </div>
      </>
    ),
    accessor: "number",
    disableSortBy: true,
    id: "number",
    width: 120,
  },
  {
    Header: "Brewery",
    Cell: ({ row }: CellProps<AdminProducerPurchaseOrderTableRowFragment>) => (
      <AdminProducerLink producer={row.original.seller} />
    ),
    accessor: (order) => order.seller.displayName,
    id: "seller",
    width: 120,
  },
  {
    Header: "Status",
    Cell: ({ row }: CellProps<AdminProducerPurchaseOrderTableRowFragment>) => (
      <ProducerOrderStatusTable order={row.original} />
    ),
    width: 190,
    disableSortBy: true,
  },
  {
    Header: "Est. Arrival",
    Cell: ({
      value,
    }: CellProps<AdminProducerPurchaseOrderTableRowFragment, "string">) => {
      return value ? formatAsDate(new Date(value)) : "N/A";
    },
    accessor: (order) => order.trackingInformation?.estimatedArrivalDate,
    id: "estimatedArrivalDate",
    width: 120,
  },
  {
    Header: "Total",
    Cell: ({
      row,
    }: CellProps<AdminProducerPurchaseOrderTableRowFragment, number>) =>
      row.original.invoice?.externalAmountInCents
        ? formatAmountInCents(row.original.invoice?.externalAmountInCents)
        : formatAmountInCents(row.original.amountInCents),
    id: "total",
    width: 100,
  },
];

type AdminPurchaseOrdersTableProps = {
  data: Array<AdminProducerPurchaseOrderTableRowFragment>;
};

export function AdminPurchaseOrdersTable({
  data,
}: AdminPurchaseOrdersTableProps) {
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy
  ) as TableInstance<AdminProducerPurchaseOrderTableRowFragment>;

  return <Table instance={tableInstance} />;
}

type AdminPurchaseOrdersPaginatedTableProps = {
  data: Array<AdminProducerPurchaseOrderTableRowFragment>;
  pagination?: UseOffsetPaginationResult;
  isShowingComponentOrders?: boolean;
  onChangeSort: (sortData: ProducerPurchaseOrdersSortInput) => void;
  initialSortBy: Array<SortingRule<AdminProducerPurchaseOrderTableRowFragment>>;
};

export function AdminPurchaseOrdersPaginatedTable({
  data,
  pagination,
  initialSortBy,
  onChangeSort,
}: AdminPurchaseOrdersPaginatedTableProps) {
  return (
    <PurchaseOrdersPaginatedTable
      columns={columns}
      data={data}
      pagination={pagination}
      initialSortBy={initialSortBy}
      onChangeSort={onChangeSort}
    />
  );
}
