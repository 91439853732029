import React, { FormEvent } from "react";
import { Button, ButtonProps as BaseButtonProps } from "./ui/Button";

export interface FormButtonProps extends ButtonProps {
  onSubmit: () => Promise<void>;
}

export type ButtonProps = Omit<BaseButtonProps, "onSubmit">;

/**
 * Useful for rendering single-click forms with native support for submitting
 * via the keyboard.
 */
export function FormButton({ onSubmit, ...rest }: FormButtonProps) {
  const handleSubmit = (e: FormEvent<Element>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Button type="submit" {...rest} />
    </form>
  );
}
