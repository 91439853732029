import React from "react";
import {
  AdminRetailerDetailsDocument,
  AdminRetailerDetailsQuery,
  AdminRetailerDetailsQueryVariables,
} from "@apollo/ops";
import { AdminDwollaCustomerCard } from "@admin/components/AdminDwollaCustomerCard";
import { AdminBankAccountDetailsCard } from "@components/BankAccount/BankAccount";
import { AdminBusinessDetailsCard } from "@components/Business/BusinessDetailsCard";
import { AdminRetailerDetailsCard } from "./AdminRetailerDetailsCard";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { RouteComponentProps } from "@reach/router";

interface AdminRetailerDetailsProps extends RouteComponentProps {
  parsedRetailerId: number;
}

type WrappedProps = WithQueryComponentProps<
  AdminRetailerDetailsProps,
  AdminRetailerDetailsQuery,
  AdminRetailerDetailsQueryVariables
>;

export function AdminRetailerDetailsWithQuery({ query }: WrappedProps) {
  const { retailer } = query.data;
  return (
    <div className="mt-8">
      <div className="space-y-4">
        <AdminRetailerDetailsCard retailer={retailer} />
        <AdminBusinessDetailsCard
          businessEntityId={retailer.id}
          fein={null}
          address={retailer.address}
        />
        <AdminDwollaCustomerCard customer={retailer.dwollaCustomer} />
        <AdminBankAccountDetailsCard bankAccount={retailer.bankAccount} />
      </div>
    </div>
  );
}

export const AdminRetailerDetails = withQuery<
  AdminRetailerDetailsProps,
  AdminRetailerDetailsQuery,
  AdminRetailerDetailsQueryVariables
>(AdminRetailerDetailsDocument, {
  mapPropsToOptions: (props) => ({
    variables: { retailerId: props.parsedRetailerId },
  }),
})(AdminRetailerDetailsWithQuery);
