import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import {
  StorePreSaleOrdersQuery,
  StorePreSaleOrdersQueryVariables,
  StorePreSaleOrdersDocument,
  StorePreSaleOrderSummaryFragment,
} from "@apollo/ops";
import { StoreRouterParams } from "../../StoreRouterParam";
import { useStore } from "../../StoreRouterProvider";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";

import { Table } from "@ui/Table";
import { Column, TableInstance, useTable, CellProps } from "react-table";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { formatAsDate } from "@utilities/formatDate";
import { formatAmountInCents } from "@utilities/currency";
import { Link } from "@components/ui/Link";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { LoadingPage } from "@components/LoadingPage";
import { InfoAlert } from "@components/ui";
import { PreSaleOrderStatusBadge } from "@components/PreSaleOrder/PreSaleOrderStatus";

type StoreSettingsProps = RouteComponentProps<StoreRouterParams>;

export function StorePreSaleOrdersPage(_: StoreSettingsProps) {
  const breadcrumbProps = {
    items: buildBreadcrumbs("Pre-Sale Orders"),
  };
  const { storeId } = useStore();

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    StorePreSaleOrdersQuery,
    StorePreSaleOrdersQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        storeId,
      },
    },
    query: StorePreSaleOrdersDocument,
    mapDataToTotal({ retailer }): number {
      return retailer.preSaleOrders?.total ?? 0;
    },
  });

  const { data, loading, error } = queryResult;

  if (data) {
    const { retailer } = data;

    return (
      <Page
        heading={
          <PageHeading
            title="Pre-Sale Orders"
            breadcrumbProps={breadcrumbProps}
          />
        }
      >
        <div className="mb-3">
          <InfoAlert title="Don't see your order?">
            Pre-sale orders will show up in the system on Thursday or Friday of
            the week you placed your order.
          </InfoAlert>
        </div>
        {data.retailer.preSaleOrders.results.length > 0 ? (
          <OrdersTable
            data={retailer.preSaleOrders.results}
            pagination={pagination}
          />
        ) : (
          <div>No pre-sale orders</div>
        )}
      </Page>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  throw error || new Error("Failed to load page");
}

type TableProps = {
  data: Array<StorePreSaleOrderSummaryFragment>;
  pagination?: UseOffsetPaginationResult;
};

function OrdersTable({ data, pagination }: TableProps) {
  const columns: Array<Column<StorePreSaleOrderSummaryFragment>> = useMemo(
    () => [
      {
        Header: "Placed on",
        Cell: ({
          value,
        }: CellProps<StorePreSaleOrderSummaryFragment, "string">) =>
          formatAsDate(value),
        accessor: "placedOn",
      },
      {
        Header: "Number",
        Cell: ({
          value,
          row,
        }: CellProps<StorePreSaleOrderSummaryFragment, "string">) => (
          <Link to={`./${row.original.id}`}>{value}</Link>
        ),
        accessor: "number",
      },
      {
        Header: "Total",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "finalOrderTotalInCents",
      },
      {
        Header: "Status",
        Cell: ({ value }) => <PreSaleOrderStatusBadge cancelledAt={value} />,
        accessor: "cancelledAt",
      },
    ],
    []
  );
  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<StorePreSaleOrderSummaryFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
