import React from "react";
import { useAdjustmentReasonsQuery } from "@apollo/ops";
import { Dropdown, DropdownProps } from "primereact/dropdown";

/**
 * Dropdown that list all adjustment reasons created in the DB.
 * @returns
 */
export const AdjustmentReasonDropdown = (props: DropdownProps) => {
  const { data: adjustmentReasons, error } = useAdjustmentReasonsQuery();
  if (error) {
    throw error;
  }
  const options = adjustmentReasons?.adjustmentReasons.map((adjReason) => ({
    label: adjReason.label,
    value: adjReason.id,
  }));
  return <Dropdown options={options} {...props} />;
};
