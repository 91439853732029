import React, { FC } from "react";
import { Button } from "@ui/Button";
import { OrderDocumentSummaryFragment } from "@apollo/gen/generatedOperations";

export interface Props {
  docInfo: Pick<OrderDocumentSummaryFragment, "name" | "fileUrl">;
}
export const DownloadDocument: FC<Props> = ({ docInfo }) => {
  const downloadDoc = () => {
    const downloadLink = document.createElement("a");

    downloadLink.href = docInfo.fileUrl;
    downloadLink.click();
  };
  return (
    <div className={"w-full ml-auto mr-auto flex flex-col items-center"}>
      <Button className={"mb-8"} onClick={downloadDoc} kind={"secondary"}>
        Download
      </Button>
      <span>{docInfo.name}</span>
    </div>
  );
};
