import React, { ComponentType, FunctionComponent } from "react";
import { useRetailerQuery, RetailerFieldsFragment } from "@apollo/ops";

type WithRetailerProps = {
  retailerId?: string;
};

export type WithRetailerInjectedProps = {
  retailer: RetailerFieldsFragment;
};

export function withRetailer<TProps extends WithRetailerProps>(
  WrappedCmp: ComponentType<TProps & WithRetailerInjectedProps>
): FunctionComponent<TProps> {
  const WithRetailer = (props: TProps) => {
    // This component should always be rendered under a path with a :retailerId param
    if (props.retailerId === undefined) {
      throw new Error("Missing retailerId");
    }

    const retailerId = parseInt(props.retailerId);

    /**
     * This is expected to hit the cache.
     */
    const { data, error } = useRetailerQuery({
      variables: {
        retailerId,
      },
    });

    // TODO handle elegantly
    if (error) {
      throw new Error("Couldn't load retailer");
    }

    // TODO what should be shown here?
    if (!data) {
      return null;
    }

    return <WrappedCmp {...props} retailer={data.organization} />;
  };

  return WithRetailer;
}
