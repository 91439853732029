import { DwollaCustomerCard } from "@components/dwolla/DwollaCustomer/DwollaCustomerCard";
import { useBillingInfoQuery } from "@apollo/gen/generatedOperations";
import { Page } from "@ui/Page";
import { defaultErrorHandler } from "@util/toast";
import React from "react";
import { RouteComponentProps } from "@reach/router";

interface BillingPageProps extends RouteComponentProps {
  organizationId: number;
}

export function BillingPage({ organizationId }: BillingPageProps) {
  const { data: dwollaCustomer } = useBillingInfoQuery({
    onError: defaultErrorHandler,
    variables: {
      organizationId,
    },
  });
  return (
    <Page heading={"Billing"}>
      {dwollaCustomer?.organization.businesses[0].dwollaCustomer && (
        <DwollaCustomerCard
          dwollaCustomer={
            dwollaCustomer.organization.businesses[0].dwollaCustomer
          }
        />
      )}
    </Page>
  );
}
