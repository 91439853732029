import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { useAuth } from "../shared/auth";
import { PublicApp } from "../PublicApp/PublicApp";
import AuthApp from "../AuthApp/AuthApp";
import AdminApp from "../AdminApp/AdminApp";
import { ProducerApp } from "../ProducerApp/ProducerApp";
import { DistributorApp } from "../DistributorApp/DistributorApp";
import { RetailerApp } from "../RetailerApp/RetailerApp";
import OnboardingApp from "../OrganizationApp/OnboardingPage/OnboardingPage";
import AppLauncher from "./AppLauncher";
import { authAppBase, retailerAppBase } from "@routes";

function RootApp(_: RouteComponentProps) {
  const { isAuthenticating } = useAuth();

  if (isAuthenticating) {
    return null;
  }

  return (
    <Router className="h-full">
      <AppLauncher path="/" />
      <AuthApp path={`${authAppBase}/*`} />
      <AdminApp path="/admin/*" />
      <ProducerApp path="/producer/:breweryId/*" />
      <DistributorApp path="/distributor/:distributorId/*" />
      <RetailerApp path={`${retailerAppBase}/:retailerId/*`} />
      <OnboardingApp path="/onboarding/:businessEntityId" />

      <PublicApp default />
    </Router>
  );
}

export default RootApp;
