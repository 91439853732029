import React, { forwardRef, TextareaHTMLAttributes } from "react";
import { inputStateClasses, Label } from "./Input";
import ctl from "@netlify/classnames-template-literals";
import { useId } from "@reach/auto-id";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, error, ...elementProps }, ref) => {
    const textareaId = useId();

    return (
      <div>
        {label && <Label label={label} htmlFor={textareaId} className="mb-1" />}
        <div>
          <textarea
            {...elementProps}
            ref={ref}
            id={textareaId}
            className={textareaClasses({ hasError: Boolean(error) })}
          />
        </div>
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

type TextareaClassesOptions = {
  hasError?: boolean;
  readOnly?: boolean;
};

const textareaClasses = ({
  hasError = false,
  readOnly = false,
}: TextareaClassesOptions) =>
  ctl(`
    shadow-sm
    focus:ring-indigo-500
    focus:border-indigo-500
    block
    w-full
    tablet:text-sm
    border
    border-gray-300
    rounded-sm
    ${inputStateClasses({ hasError })}
    ${readOnly && "bg-gray-50"}
  `);
