import React, { useEffect, useRef } from "react";
import { Calendar as PFCalendar, CalendarProps } from "primereact/calendar";
import "./calendar.css";

/**
 * This is a wrapper over PrimeReact's Calendar component in order to style it.
 * More information in https://primefaces.org/primereact/showcase/#/calendar
 * @param _props - All props from React Prime Calendar component
 * @returns
 */
export const Calendar = (_props: CalendarProps) => {
  const el = useRef<PFCalendar>();
  const htmlInputRef = (el.current as unknown) as {
    inputRef: { current: HTMLInputElement } | null;
  } | null;

  const htmlInputElement = htmlInputRef?.inputRef?.current;
  useEffect(() => {
    if (htmlInputElement) {
      htmlInputElement.className = `${htmlInputElement.className.replace(
        "p-inputtext",
        ""
      )} block w-full tablet:text-sm rounded-sm focus:border-blue-500 focus:ring-blue-500 border-gray-300`;
    }
  }, [htmlInputElement, htmlInputElement?.className]);
  const CalendarWithRef = (PFCalendar as unknown) as React.FC<
    CalendarProps & { ref: React.MutableRefObject<PFCalendar | undefined> }
  >;
  return <CalendarWithRef {..._props} ref={el} />;
};
