import React from "react";
import {
  AdminProducersDocument,
  AdminProducersQuery,
  AdminProducersQueryVariables,
  ProducersFilterData,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { AddBreweryModal } from "@components/Brewery/AddBreweryModal";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { AdminProducersFilter } from "@admin/AdminProducersContainer/AdminProducersPage/AdminProducersFilter";
import { AdminProducersTable } from "@admin/AdminProducersContainer/AdminProducersPage/AdminProducersTable";
import { RouteComponentProps } from "@reach/router";

type FilterData = ProducersFilterData;

export function AdminProducersPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    producerId: NumberParam,
    name: StringParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    AdminProducersQuery,
    AdminProducersQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        filters,
      },
    },
    query: AdminProducersDocument,
    mapDataToTotal({ paginatedProducers }) {
      return paginatedProducers.total;
    },
  });

  const { data } = queryResult;

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Breweries"
          actions={<AddBreweryModal onAdd={() => queryResult.refetch()} />}
        />
      }
    >
      <div className="mb-3">
        <AdminProducersFilter
          onFilter={updateFilters}
          defaultValues={filters}
        />
      </div>
      {data && (
        <AdminProducersTable
          data={data.paginatedProducers.results}
          pagination={pagination}
        />
      )}
    </Page>
  );
}
