import React, { ReactNode, useEffect, useState } from "react";
import { CheckIcon, SelectorIcon, XCircleIcon } from "@heroicons/react/solid";
import {
  GetInputPropsOptions,
  useCombobox,
  UseComboboxProps,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from "downshift";
import { matchSorter, MatchSorterOptions } from "match-sorter";
import classnames from "classnames";
import { inputStateClasses } from "./Input";

export type ItemState = {
  highlighted: boolean;
  selected: boolean;
};

export type AutocompleteProps<TItem> = {
  label?: string;
  placeholder?: string;
  error?: string;
  hideToggleButton?: boolean;
  selectedItem?: TItem | null;
  items: TItem[];
  onSelect: (item?: TItem | null) => void;
  inputProps?: GetInputPropsOptions;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  filterItems: (items: TItem[], inputValue?: string) => TItem[];
  renderItem?: (item: TItem, state: ItemState) => ReactNode;
  itemToString: (item: TItem | null) => string;
  comboboxProps?: Partial<UseComboboxProps<TItem>>;
  onInputValueChange?: (inputValue?: string) => any;
  onClickDelete?: (selectedItem?: TItem | null) => void;
  backendFilter?: boolean;
  disabled?: boolean;
};

function getItemState<TItem>(
  item: TItem,
  selectedItem: TItem,
  itemIndex: number,
  highlightedIndex: number
): ItemState {
  return {
    selected: item === selectedItem,
    highlighted: itemIndex === highlightedIndex,
  };
}

export function Autocomplete<TItem>({
  onBlur,
  onSelect,
  label,
  error,
  placeholder,
  itemToString,
  items,
  renderItem = itemToString,
  hideToggleButton = false,
  filterItems,
  inputProps,
  comboboxProps,
  selectedItem,
  onInputValueChange,
  onClickDelete,
  backendFilter = false,
  disabled = false,
}: AutocompleteProps<TItem>) {
  const [inputItems, setInputItems] = useState(() =>
    backendFilter ? items : filterItems(items)
  );
  useEffect(() => {
    setInputItems(items);
  }, [items, setInputItems]);

  const {
    isOpen,
    inputValue,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem: uncontrolledSelectedItem,
  } = useCombobox({
    selectedItem,
    items: inputItems,
    itemToString,
    onSelectedItemChange: (changes) => {
      onSelect(changes.selectedItem);
      setInputItems(items);
    },
    onInputValueChange: ({ inputValue }) => {
      if (onInputValueChange) {
        onInputValueChange(inputValue);
      }
      if (!backendFilter) {
        setInputItems(filterItems(items, inputValue));
      }
    },
    ...comboboxProps,
  });

  useEffect(() => {
    setInputItems(filterItems(items, inputValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  /**
   * If selectedItem is undefined, render user the uncontrolled value, otherwise we
   * are functioning as a controlled component and should use that value passed in.
   */
  selectedItem =
    selectedItem === undefined ? uncontrolledSelectedItem : selectedItem;

  const hasError = Boolean(error);

  return (
    <div>
      {label && (
        <label
          className="block text-sm font-medium text-gray-700"
          {...getLabelProps()}
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative" {...getComboboxProps()}>
        <div className="flex rounded-sm shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              type="text"
              className={classnames(
                "block w-full rounded-none tablet:text-sm",
                hideToggleButton ? "rounded-sm" : "rounded-l-sm",
                inputStateClasses({ hasError, readOnly: disabled })
              )}
              disabled={disabled}
              {...getInputProps({
                onBlur,
                placeholder,
                ...inputProps,
              })}
            />
          </div>
          {selectedItem && onClickDelete && !disabled && (
            <button
              className={classnames(
                "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-sm focus:outline-none focus:ring-1",
                inputStateClasses({ hasError })
              )}
              onClick={() => onClickDelete(selectedItem)}
              aria-label={"remove item"}
            >
              <XCircleIcon className="h-5 w-5 text-gray-400" />
            </button>
          )}
          {!hideToggleButton && (
            <button
              className={classnames(
                "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-sm focus:outline-none focus:ring-1",
                error
                  ? "bg-red-50 hover:bg-red-100"
                  : "bg-gray-50 hover:bg-gray-100",
                inputStateClasses({ hasError, readOnly: disabled })
              )}
              disabled={disabled}
              {...getToggleButtonProps()}
              aria-label={"toggle menu"}
            >
              <SelectorIcon className="h-5 w-5 text-gray-400" />
            </button>
          )}
        </div>
        <div
          className={classnames(
            "absolute mt-1 w-full rounded-sm bg-white shadow-lg z-30",
            isOpen ? "visible" : "hidden"
          )}
        >
          <ul
            className={classnames(
              "max-h-60 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none tablet:text-sm"
            )}
            {...getMenuProps()}
          >
            {inputItems.length > 0 ? (
              inputItems.map((item, index) => {
                const itemState = getItemState(
                  item,
                  selectedItem,
                  index,
                  highlightedIndex
                );

                return (
                  <li
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    <Item state={itemState}>{renderItem(item, itemState)}</Item>
                  </li>
                );
              })
            ) : (
              <li className="cursor-default select-none relative py-2 pl-3 pr-9 text-gray-500">
                No results found
              </li>
            )}
          </ul>
        </div>
        {error && (
          <div className="mt-1">
            <p role="alert" className="text-sm text-red-600">
              {error}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * This reducer clears the inputValue any time an item is selected.
 */
export function clearOnSelectReducer<Item>(
  _: UseComboboxState<Item>,
  actionAndChanges: UseComboboxStateChangeOptions<Item>
) {
  const { type, changes } = actionAndChanges;

  switch (type) {
    case useCombobox.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.FunctionSelectItem:
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
      return {
        ...changes,
        inputValue: "",
      };
    default:
      return changes;
  }
}

export type ItemProps = {
  state: ItemState;
  children: ReactNode;
};

export function Item({ state, children }: ItemProps) {
  return (
    <div
      className={classnames(
        "cursor-default select-none relative py-2 pl-3 pr-10",
        state.highlighted && "bg-blue-500"
      )}
    >
      <div className="flex items-center">{children}</div>

      {state.selected && (
        <span
          className={classnames(
            "absolute inset-y-0 right-0 flex items-center pr-4",
            state.highlighted ? "text-white" : "text-blue-600"
          )}
        >
          <CheckIcon className="h-5 w-5" />
        </span>
      )}
    </div>
  );
}

/**
 * Build a matchSorter that can function on multiple terms and gracefully
 * handles undefined and empty filter values.
 */
export function buildMatchSorterFilter<TItem>(
  options: MatchSorterOptions<TItem>
) {
  return (items: Array<TItem>, filterValue?: string) => {
    if (!filterValue || !filterValue.length) {
      return items;
    }

    const terms = filterValue.split(" ");
    if (!terms) {
      return items;
    }

    return terms.reduceRight(
      (results, term) => matchSorter(results, term, options),
      items
    );
  };
}
