import React from "react";
import { ProducerPurchaseOrderSummaryFragment } from "@apollo/ops";
import { Card } from "@ui/Card/Card";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";
import { formatAmountInCents, formatToDollars } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";
import { RouteComponentProps } from "@reach/router";
import { ProducerPurchaseOrderPageHeading } from "./ProducerPurchaseOrderPageHeading";
import { Button } from "@ui/Button";
import { Page } from "@ui/Page";
import { DIRECT_BEER_API_URI } from "@config";
import { onExportFileClick } from "@util/files";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";

interface AdminOrderSummaryCardProps extends RouteComponentProps {
  order: ProducerPurchaseOrderSummaryFragment;
}

export function ProducerPurchaseOrderSummaryCard({
  order,
}: AdminOrderSummaryCardProps) {
  const externalTotal = order.invoice?.externalAmountInCents
    ? formatAmountInCents(order.invoice?.externalAmountInCents)
    : "";

  const breadCrumbs = {
    items: buildBreadcrumbs("Purchase Orders", `#${order.number}`),
  };

  const onGenerateOrderPdfClick = () => {
    const pdfUrl = `${DIRECT_BEER_API_URI}producer/order/${order.id}/pdf`;
    const fileName = `order-${order.number}.pdf`;
    onExportFileClick({ url: pdfUrl, fileName });
  };

  return (
    <Page
      heading={
        <ProducerPurchaseOrderPageHeading
          order={order}
          breadCrumbs={breadCrumbs}
          actions={
            order.enabledFeatures.canCreatePDF && (
              <Button onClick={() => onGenerateOrderPdfClick()} size="sm">
                Download PDF
              </Button>
            )
          }
        />
      }
    >
      <Card title="Order Summary">
        <DescriptionListInCard>
          <div className="grid laptop:grid-cols-2 gap-10">
            <div>
              <LeftAlignedTerm
                term="Date"
                definition={formatAsDate(new Date(order.createdAt))}
              />
              <LeftAlignedTerm
                term="Seller"
                definition={order.seller.displayName}
              />
              <LeftAlignedTerm term="Buyer" definition={order.buyerName} />
              <LeftAlignedTerm
                term="Order Total"
                definition={formatAmountInCents(order.amountInCents)}
              />
            </div>
            <div>
              <LeftAlignedTerm
                term="Invoice #"
                definition={order.invoice?.externalInvoiceId}
              />
              <LeftAlignedTerm
                term="Invoice Total"
                definition={externalTotal}
              />
              {/*
               * We don't need to check the type since this will always be a producer purchase order
               * but typescript yells if we don't
               */}
              {order.__typename === "ProducerPurchaseOrder" && (
                <LeftAlignedTerm
                  term="Platform Fee"
                  definition={formatToDollars({
                    cents: Number(order.platformFee),
                  })}
                />
              )}
            </div>
          </div>
        </DescriptionListInCard>
      </Card>
    </Page>
  );
}
