import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps } from "@reach/router";
import {
  ProducerLicenseFieldsFragment,
  useProducerLicensesQuery,
} from "@apollo/ops";
import { LoadingIcon } from "@components/ui/Spinner";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { Page, PageHeading } from "@components/ui/Page";
import { ProducerLicensesTable } from "./ProducerLicensesTable";
import { useGeographicalStates } from "@hooks/useStates/useGeographicalStates";

type ProducerProps = {
  businessEntityId: number;
} & RouteComponentProps;

export function ProducerLicenses({ businessEntityId }: ProducerProps) {
  const { data: results, loading, error } = useProducerLicensesQuery({
    variables: {
      producerId: businessEntityId,
    },
  });

  useErrorHandler(error);

  const states = useGeographicalStates();
  const licenses = results?.producer?.licenses ?? [];

  const licenseTables = states.map((state) => {
    const licensesForState = licenses.filter(
      (license: ProducerLicenseFieldsFragment) =>
        license.stateV2!.id === state.id
    );

    return licensesForState.length > 0 ? (
      <div className="pb-8" key={state.id}>
        <SectionHeading title={state.name} />
        <ProducerLicensesTable licenses={licensesForState} />
      </div>
    ) : null;
  });

  return (
    <Page heading={<PageHeading title="Your Licenses" />}>
      <div className="mt-10">
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="mt-8 card">
            {!licenses ||
              (licenses.length === 0 && (
                <>
                  <p>No licenses</p>
                  <br />
                </>
              ))}

            {licenseTables}
          </div>
        )}
      </div>
    </Page>
  );
}
