import React from "react";
import { PreSaleImportJobDetailsFragment } from "@apollo/gen/generatedOperations";
import { formatAsDateWithTimeFromString } from "@utilities/formatDate";
import { capitalize } from "lodash";
import { Column, useTable, useSortBy } from "react-table";
import { Table } from "@ui/Table";
import { Link } from "@ui/Link";

export type AdminPreSaleImportJobsTableProps = {
  preSaleImportJobs: PreSaleImportJobDetailsFragment[];
};

const columns: Column<PreSaleImportJobDetailsFragment>[] = [
  {
    Header: "ID",
    Cell: function PreSaleImportDetailsLink({ value }) {
      return <Link to={value.toString()}>{value}</Link>;
    },
    accessor: "id",
  },
  {
    Header: "Created At",
    Cell: ({ value }) => formatAsDateWithTimeFromString(value),
    accessor: "createdAt",
  },
  {
    Header: "Region",
    Cell: ({ value }) => value.friendlyName,
    accessor: "regionV2",
  },
  {
    Header: "Start",
    Cell: ({ value }) => formatAsDateWithTimeFromString(value),
    accessor: "start",
  },
  {
    Header: "End",
    Cell: ({ value }) => formatAsDateWithTimeFromString(value),
    accessor: "end",
  },
  {
    Header: "Status",
    Cell: ({ value }) => capitalize(value),
    accessor: "status",
  },
];

export function AdminPreSaleImportJobsTable({
  preSaleImportJobs,
}: AdminPreSaleImportJobsTableProps) {
  const tableInstance = useTable(
    {
      columns,
      data: preSaleImportJobs,
    },
    useSortBy
  );

  return <Table instance={tableInstance} />;
}
