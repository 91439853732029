import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../ui/Button";
import { Input } from "../../ui/Input";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";

export type RetailerFormData = {
  name: string;
};

type RetailerFormProps = {
  onSubmit: (data: RetailerFormData) => void | Promise<void>;
  defaultValues?: Partial<RetailerFormData>;
  submitText?: string;
};

export function RetailerForm({
  onSubmit,
  defaultValues = {},
  submitText = "Submit",
}: RetailerFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<RetailerFormData>({
    defaultValues,
  });

  const wrappedOnSubmit = async (data: RetailerFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Organization Name
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              })}
              id="name"
              placeholder="Name"
              error={errors.name?.message}
            />
          </div>
        </div>
        <div>
          <Button size="lg" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  );
}
