import React from "react";
import { ValidationResultFragment } from "@apollo/ops";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { Card, Footer } from "@components/ui/Card/Card";

type ValidationResultProps = {
  validationResult: ValidationResultFragment;
  footer?: JSX.Element;
};

export function ValidationResult({
  validationResult,
  footer,
}: ValidationResultProps) {
  const { isValid, name, description, checks } = validationResult;
  const nonHiddenChecks = checks.filter(
    ({ hideOnPass, passed }) => !(passed && hideOnPass)
  );

  return (
    <Card>
      <div className="bg-white px-4 py-3 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <div className="text-lg leading-6 font-medium text-gray-900">
              {name}
            </div>
            {description && (
              <p className="mt-1 text-sm text-gray-500">{description}</p>
            )}
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            {isValid ? (
              <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center">
                <CheckIcon className="w-5 h-5 text-white" />
              </span>
            ) : (
              <span className="h-8 w-8 rounded-full bg-red-500 flex items-center justify-center">
                <XIcon className="w-5 h-5 text-white" />
              </span>
            )}
          </div>
        </div>
      </div>
      <ChecksStackedList checks={nonHiddenChecks} />
      {footer && <Footer>{footer}</Footer>}
    </Card>
  );
}

type ValidatedActionProps = {
  actionName?: string;
  validationResult: ValidationResultFragment;
  renderAction: (props: { isValid: boolean }) => JSX.Element;
};

export function ValidatedAction({
  actionName,
  renderAction,
  validationResult,
}: ValidatedActionProps) {
  const { isValid, checks } = validationResult;

  return (
    <Card>
      <div className="bg-white px-4 py-3 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <div className="text-lg leading-6 font-medium text-gray-900">
              {actionName}
            </div>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            {renderAction({ isValid })}
          </div>
        </div>
      </div>
      <ChecksStackedList checks={checks} />
    </Card>
  );
}

type ChecksStackedListProps = {
  checks: ValidationResultFragment["checks"];
};

function ChecksStackedList({ checks }: ChecksStackedListProps) {
  return (
    <ul className="divide-y divide-gray-200 text-sm">
      {checks.map((check, index) => (
        <li key={index} className="py-2">
          <div className="flex items-center">
            <div className="mx-3">
              {check.passed ? (
                <CheckIcon className="w-5 h-5 text-green-500" />
              ) : (
                <XIcon className="w-5 h-5 text-red-500" />
              )}
            </div>
            <div>{check.description}</div>
          </div>
        </li>
      ))}
    </ul>
  );
}
