import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  AdminProducerDetailsDocument,
  AdminProducerDetailsQuery,
  AdminProducerDetailsQueryVariables,
} from "@apollo/ops";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { AdminDwollaCustomerCard } from "@admin/components/AdminDwollaCustomerCard";
import { AdminBankAccountDetailsCard } from "@components/BankAccount/BankAccount";
import { AdminBreweryDetailsCard } from "@components/Brewery/BreweryDetailsCard";
import { AdminBusinessDetailsCard } from "@components/Business/BusinessDetailsCard";

interface AdminProducerDetailsProps extends RouteComponentProps {
  parsedProducerId: number;
}

type WrappedProps = WithQueryComponentProps<
  AdminProducerDetailsProps,
  AdminProducerDetailsQuery,
  AdminProducerDetailsQueryVariables
>;

export function AdminProducerDetailsWithQuery({ query }: WrappedProps) {
  const { producer } = query.data;

  return (
    <div className="mt-8">
      <div className="space-y-4">
        <AdminBreweryDetailsCard
          globalBreweryId={producer.globalBreweryId}
          rating={producer.rating}
        />
        <AdminBusinessDetailsCard
          businessEntityId={producer.id}
          fein={producer.fein}
          address={producer.address}
        />
        <AdminDwollaCustomerCard customer={producer.dwollaCustomer} />
        <AdminBankAccountDetailsCard bankAccount={producer.bankAccount} />
      </div>
    </div>
  );
}

export const AdminProducerDetails = withQuery<
  AdminProducerDetailsProps,
  AdminProducerDetailsQuery,
  AdminProducerDetailsQueryVariables
>(AdminProducerDetailsDocument, {
  mapPropsToOptions: (props) => ({
    variables: { producerId: props.parsedProducerId },
  }),
})(AdminProducerDetailsWithQuery);
