import React, { useState } from "react";
import {
  PaginatedProducerSalesByRegionDocument,
  PaginatedProducerSalesByRegionQuery,
  PaginatedProducerSalesByRegionQueryVariables,
} from "@apollo/gen/generatedOperations";
import { LoadingIcon } from "@ui/Spinner";
import { ProducerSalesByRegionTable } from "./ProducerSalesByRegion/ProducerSalesByRegionTable";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";
import { isNil } from "lodash";

export function ProducerSalesByRegion({ producerId }: { producerId: number }) {
  // setting the initial state to NaN satisfies TypeScript and ensures our refetch query behaves appropriately
  const [regionId, setRegionId] = useState(Number.NaN);

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    PaginatedProducerSalesByRegionQuery,
    PaginatedProducerSalesByRegionQueryVariables
  >({
    options: {
      fetchPolicy: "network-only",
      variables: {
        filters: {
          producerId: producerId,
          regionId,
        },
      },
    },
    query: PaginatedProducerSalesByRegionDocument,
    mapDataToTotal({ paginatedProducerSalesByRegion }) {
      return paginatedProducerSalesByRegion?.total || 0;
    },
  });

  const { data, refetch } = queryResult;

  if (data) {
    return (
      <>
        <div className="mb-4 mt-2">
          <RegionsDropdown
            value={regionId}
            onChange={(newRegionId) => {
              if (!isNil(newRegionId)) {
                pagination.reset();
                setRegionId(newRegionId);
                refetch({
                  pagination: {
                    take: pagination.paginationParams.take,
                    skip: pagination.paginationParams.skip,
                  },
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  filters: {
                    producerId,
                    regionId,
                  },
                });
              }
            }}
          />
        </div>
        <ProducerSalesByRegionTable
          tableData={data?.paginatedProducerSalesByRegion?.results ?? []}
          pagination={pagination}
        />
      </>
    );
  }
  return <LoadingIcon />;
}
