import { Redirect } from "@reach/router";
import React, { ComponentType, FunctionComponent } from "react";
import { useBusinessOnboardingStatusQuery } from "@apollo/ops";

type WithOnboardingCompleteOptions<TProps> = {
  getBusinessIdFromProps: (props: TProps) => number;
};

export function withOnboardingComplete<TProps>({
  getBusinessIdFromProps,
}: WithOnboardingCompleteOptions<TProps>) {
  return (WrappedCmp: ComponentType<TProps>): FunctionComponent<TProps> => {
    const WithOnboadingComplete = (props: TProps) => {
      const businessId = getBusinessIdFromProps(props);

      /**
       * This is expected to hit the cache, as we fetch a superset of this data
       * in the WhoAmI query.
       */
      const { data, loading } = useBusinessOnboardingStatusQuery({
        variables: {
          businessId,
        },
        fetchPolicy: "cache-first",
      });

      if (loading) {
        return null;
      }

      /**
       * If we are not loading and don't have any data, something has gone wrong.
       */
      if (!data) {
        console.error("Missing data in WithOnboardingComplete");
        throw new Error("An unknown error occurred");
      }

      if (data.business.completedOnboardingAt) {
        return <WrappedCmp {...props} />;
      }

      return <Redirect to={`/onboarding/${businessId}`} />;
    };

    return WithOnboadingComplete;
  };
}
