import React from "react";
import { AdminAddFirstRetailerLocationMutation } from "@apollo/ops";
import { Dialog, DialogProps } from "primereact/dialog";
import { AddRetailer } from "./AddRetailer";

type AddRetailerDialogProps = {
  onAdd?: (data: AdminAddFirstRetailerLocationMutation) => void;
  dialogProps: DialogProps;
};

/**
 * This dialog is used to add a new retailer organization.
 */
export function AddRetailerDialog({
  onAdd,
  dialogProps,
}: AddRetailerDialogProps) {
  return (
    <Dialog
      {...dialogProps}
      header="Add retailer"
      className="p-fluid tablet:max-w-xl tablet:w-full"
      modal
    >
      <AddRetailer
        onAdd={(args) => {
          onAdd?.(args);
          dialogProps.onHide();
        }}
      />
    </Dialog>
  );
}
