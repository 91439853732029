import React, { useState } from "react";
import { AddBrewery } from "./AddBrewery";
import { Button } from "@ui/Button";
import { Dialog } from "primereact/dialog";
import { AdminAddProducerMutation } from "@apollo/gen/generatedOperations";

type AddProducerModalProps = {
  onAdd?: (data: AdminAddProducerMutation) => void;
};

export function AddBreweryModal({ onAdd }: AddProducerModalProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)} size="sm">
        Add Brewery
      </Button>
      <Dialog
        visible={visible}
        header="Add brewery"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <AddBrewery
          onAdd={(args) => {
            setVisible(false);
            onAdd?.(args);
          }}
        />
      </Dialog>
    </>
  );
}
