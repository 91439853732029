import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import { ButtonLink } from "@components/ui/Button";
import { adminPaths } from "@routes";
import { AdminPreSalesTable } from "./AdminPreSalesTable";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";

export function AdminPreSalesPage(_props: RouteComponentProps) {
  const breadcrumbProps = {
    items: buildBreadcrumbs("Pre-Sales"),
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Pre-Sales"
          breadcrumbProps={breadcrumbProps}
          actions={
            <ButtonLink
              to={adminPaths.preSales.importJobs.root}
              kind="secondary"
              size="sm"
            >
              Go to import jobs
            </ButtonLink>
          }
        />
      }
    >
      <div className="mt-10">
        <div className="mt-8 card">
          <AdminPreSalesTable />
        </div>
      </div>
    </Page>
  );
}
