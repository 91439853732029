import React, { FC, useCallback, useContext, useMemo } from "react";
import {
  usePlaidLinkToken,
  withPlaidLinkToken,
  WithPlaidLinkTokenInjectedProps,
} from "@components/plaid/withPlaidLinkToken";
import { BankAccountReVerifyContext } from "./BankAccountReVerify/BankAccountReVerifyContext";
import {
  BankAccount as BankAccountType,
  PlaidVerificationStatus,
} from "@apollo/gen/generatedOperations";
import { BankAccount } from "@components/BankAccount/BankAccount";
import { UpdatePlaidItem } from "@components/plaid/UpdatePlaidItem/UpdatePlaidItem";
import { Button } from "@ui/Button";
import { NewAuthorizeOnDemandTransferDialog } from "@components/BankAccount/AuthorizeOnDemandTransfers/AuthorizeOnDemandTransfersDialog";
import { Tag } from "primereact/tag";
import { VerificationStatusTag } from "@components/BankAccount/VerificationStatusTag";
import { BankAccountsViewProps } from "./BankAccountsView";
import { useDialog } from "@ui/Dialog";
import { Column, useTable } from "react-table";
import { Table } from "@ui/Table";
interface Props {
  data: Array<BankAccountType>;
  loading: boolean;
  onRemove: BankAccountsViewProps["onRemove"];
}
type BankAccountsTableCmpProps = Props & WithPlaidLinkTokenInjectedProps;
export const BankAccountsTableCmp: FC<BankAccountsTableCmpProps> = ({
  data,
  onRemove,
}) => {
  const dialog = useDialog();
  const { reverifyBankAccount } = useContext(BankAccountReVerifyContext);

  const bankAccountStatus = useCallback((bankAccount: BankAccountType) => {
    const status = bankAccount.plaidItem
      ? bankAccount.plaidItem.verificationStatus
      : new Error("Unknown error, please contact support.");
    if (status instanceof Error) {
      return (
        <Tag
          severity={"danger"}
          value={"Unknown error, please contact support"}
        />
      );
    }

    return <VerificationStatusTag bankAccount={bankAccount} />;
  }, []);

  const actionBodyTemplate = useCallback(
    (bankAccount: BankAccountType) => {
      const onRemoveHandler = () => {
        onRemove(bankAccount, false);
      };
      const plaidItem = bankAccount.plaidItem;

      const PlaidVerification = () => {
        if (
          plaidItem?.verificationStatus ===
          PlaidVerificationStatus.PendingManualVerification
        ) {
          return (
            <span className="flex-shrink-0">
              <UpdatePlaidItem plaidItemId={plaidItem.id} />
            </span>
          );
        }
        if (
          plaidItem?.verificationStatus ===
            PlaidVerificationStatus.VerificationExpired ||
          plaidItem?.verificationStatus ===
            PlaidVerificationStatus.VerificationFailed
        ) {
          return (
            <span className="flex-shrink-0">
              <Button
                onClick={() => reverifyBankAccount(bankAccount.id)}
                kind="secondary"
                destructive
              >
                Reverify
              </Button>
            </span>
          );
        }
        return null;
      };

      return (
        <div className="mt-4 flex flex-row laptop:mt-0 laptop:ml-4">
          <div className="items-center mr-4">
            <PlaidVerification />
          </div>
          <div
            className="inline-flex items-center"
            style={{ marginLeft: "auto" }}
          >
            <Button onClick={onRemoveHandler} kind="secondary" destructive>
              Remove
            </Button>
          </div>
        </div>
      );
    },
    [onRemove, reverifyBankAccount]
  );

  const authorizeBodyTemplate = useCallback(
    (bankAccount: BankAccountType) => {
      const yesOrNoText = bankAccount.hasOnDemandAuthorization ? "Yes" : "No";
      const authorizeLink = !bankAccount.hasOnDemandAuthorization ? (
        <div className="mt-2">
          <Button onClick={dialog.open} kind="secondary">
            View on-demand authorization
          </Button>
          <div className="mt-1 text-sm text-yellow-700">
            You must agree to the on-demand transfer authorization for us to
            send and receive payments with your bank account.
          </div>
        </div>
      ) : null;
      return (
        <>
          <span>
            {yesOrNoText}
            {authorizeLink}
          </span>
          <NewAuthorizeOnDemandTransferDialog
            bankAccountId={bankAccount.id}
            dialogProps={dialog.getDialogProps()}
          />
        </>
      );
    },
    [dialog]
  );

  const columns: Array<Column<BankAccountType>> = useMemo(
    () => [
      {
        Header: "Account info",
        width: 140,
        Cell: ({ row }: { row: { original: BankAccountType } }) => (
          <BankAccount bankAccount={row.original} />
        ),
      },
      {
        Header: "Status",
        width: 140,
        Cell: ({ row }: { row: { original: BankAccountType } }) =>
          bankAccountStatus(row.original),
      },
      {
        Header: "Authorized",
        width: 140,
        Cell: ({ row }: { row: { original: BankAccountType } }) =>
          authorizeBodyTemplate(row.original),
      },
      {
        Header: " ",
        width: 140,
        Cell: ({ row }: { row: { original: BankAccountType } }) =>
          actionBodyTemplate(row.original),
      },
    ],
    [actionBodyTemplate, authorizeBodyTemplate, bankAccountStatus]
  );
  const table = useTable({ columns, data });
  return <Table instance={table} />;
};

export const BankAccountsTable = withPlaidLinkToken<Props>({
  useHook: usePlaidLinkToken,
})(BankAccountsTableCmp);
