import React, { ReactNode } from "react";
import ctl from "@netlify/classnames-template-literals";

export type StatsProps = {
  stats: Array<{ name: string; value: ReactNode }>;
  colClasses?: string;
};

/**
 * Component to show many statistics.
 */
export const Stats = ({
  stats,
  colClasses = "laptop:grid-cols-4",
}: StatsProps) => {
  return (
    <div>
      <dl className={ctl(`mt-5 grid grid-cols-1 gap-5 ${colClasses}`)}>
        {stats.map((item) => (
          <div
            key={item.name}
            className="px-2 py-3 bg-white shadow rounded-sm overflow-hidden tablet:px-4 tablet:py-3"
          >
            <dt className="text-xs font-medium text-gray-500 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 text-2xl leading-tight font-semibold text-gray-900">
              {item.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
