import React, { useMemo } from "react";
import { Table } from "@ui/Table";
import {
  CellProps,
  Column,
  TableInstance,
  useFlexLayout,
  useTable,
} from "react-table";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { AdminRetailerSalesOrdersTableRowData } from "@admin/AdminRetailersContainer/AdminRetailerPage/RetailerSalesOrders/AdminRetailerSalesOrdersPage";
import { AdminProducerLink } from "@components/Producer/Producer";
import { RetailerOrderStatusTable } from "@admin/components/RetailerSalesOrder/RetailerOrderStatusTable";
import { AdminOrderLink } from "@components/Order/Order";

type AdminRetailerSalesOrdersTableProps = {
  data: Array<AdminRetailerSalesOrdersTableRowData>;
  pagination?: UseOffsetPaginationResult;
};

export function AdminRetailerSalesOrdersTable({
  data,
  pagination,
}: AdminRetailerSalesOrdersTableProps) {
  const columns: Array<Column<AdminRetailerSalesOrdersTableRowData>> = useMemo(
    () => [
      {
        Header: "Id",
        Cell: ({ row }) => <AdminOrderLink order={row.original} />,
        accessor: "number",
      },
      {
        Header: "Created On",
        accessor: "createdAt",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Producer",
        Cell: ({
          row,
        }: CellProps<AdminRetailerSalesOrdersTableRowData, "string">) => (
          <AdminProducerLink producer={row.original.producer} />
        ),
        accessor: "producer",
        width: 150,
      },
      {
        Header: "Status",
        Cell: ({ row }) => <RetailerOrderStatusTable order={row.original} />,
        accessor: "paymentStatus",
        width: 190,
      },
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  ) as TableInstance<AdminRetailerSalesOrdersTableRowData>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
