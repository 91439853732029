import React, { createContext, ReactNode, useContext } from "react";

type PageContextValue = {
  onView?: () => void;
};

const PageContext = createContext<PageContextValue>({});

export const usePage = () => useContext(PageContext);

type PageProviderProps = {
  onView: () => void;
  children: ReactNode;
};

export function PageProvider({ children, onView }: PageProviderProps) {
  return (
    <PageContext.Provider value={{ onView }}>{children}</PageContext.Provider>
  );
}
