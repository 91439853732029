import React from "react";
import { Page } from "@ui/Page";
import { ErrorAlert } from "@ui/Alert";

type ErrorPageProps = {
  title?: string;
  description?: string;
};

export function ErrorPage(props: ErrorPageProps) {
  return (
    <Page>
      <div className="flex items-center align-middle h-24">
        <ErrorAlert {...props} />
      </div>
    </Page>
  );
}
