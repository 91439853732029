import { Badge, BadgeProps, Color } from "@ui/Badge";

type CancelledStatusBadgeProps = Partial<BadgeProps> & {
  cancelledAt: Date | null;
};

export function CancelledStatusBadge({
  cancelledAt,
  ...rest
}: CancelledStatusBadgeProps) {
  return Badge({ ...getCancelledStatusBadgeProps(cancelledAt), ...rest });
}

function getCancelledStatusBadgeProps(
  cancelledAt: Date | null
): { label: string; color: Color } {
  if (cancelledAt !== null) {
    return { label: "cancelled", color: "red" as Color };
  } else {
    return { label: "not cancelled", color: "green" as Color };
  }
}
