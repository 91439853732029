import { MessageSeverity } from "@shared/types/Severity";
import { getAuthHeader } from "src/amplify";
import { toast } from "./toast";

export const onExportFileClick = async ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  try {
    const authHeader = await getAuthHeader();
    const response = await fetch(url, {
      headers: {
        Authorization: authHeader,
      },
    }).then(async (res) => {
      if (res.status !== 200) {
        throw Error(await res.text());
      }

      return res.blob();
    });

    const transformBlobToBase64 = (blob: Blob): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64String = reader.result;
          resolve(base64String as string);
        };
        reader.onerror = () => {
          console.error(reader.error);
          reject(reader.error);
        };
      });
    };

    const pdfInBase64 = await transformBlobToBase64(response);
    const downloadLink = document.createElement("a");

    downloadLink.href = pdfInBase64;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (error) {
    toast.current?.show({
      severity: MessageSeverity.error,
      summary: error.message,
    });
  }
};
