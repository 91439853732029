import React, { ReactNode } from "react";
import { AdminOfferWindowOffersFragment } from "@apollo/ops";
import { PageHeading } from "@ui/Page";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";
import { CalendarIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { formatAsDate } from "@utilities/formatDate";

type AdminOfferWindowPageHeadingProps = {
  offerWindow: AdminOfferWindowOffersFragment;
  page: string;
  actions?: ReactNode;
};

export function AdminOfferWindowPageHeading({
  page,
  offerWindow,
  actions,
}: AdminOfferWindowPageHeadingProps) {
  const breadcrumbProps = {
    items: buildBreadcrumbs("Offer Windows", `#${offerWindow.id}`, page),
  };

  const metaProps = {
    items: [
      {
        icon: LocationMarkerIcon,
        text: offerWindow.regionV2.friendlyName,
      },
      {
        icon: CalendarIcon,
        text:
          formatAsDate(offerWindow.start) +
          " to " +
          formatAsDate(offerWindow.end),
      },
    ],
  };

  return (
    <PageHeading
      title={"Offer Window " + page}
      breadcrumbProps={breadcrumbProps}
      metaProps={metaProps}
      actions={actions}
    />
  );
}
