import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import {
  AdminProducerDocument,
  AdminProducerQuery,
  AdminProducerQueryVariables,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { UpdateBreweryModal } from "@components/Brewery/UpdateBreweryModal";
import { InviteUserToOrganizationModal } from "../../components/InviteUserToOrganizationModal/InviteUserToOrganizationModal";
import { organizationPath } from "@routes";
import { Tab, Tabs } from "@components/ui/Tabs";
import { ButtonLink } from "@components/ui/Button";
import { AdminProducerDetails } from "./AdminProducerDetails";
import { AdminProducerLicenses } from "./AdminProducerLicenses";
import { AdminProducerDistribution } from "./AdminProducerDistribution";
import { DeleteProducerButton } from "./DeleteProducerButton";
import { AdminProducerRegionsPage } from "./AdminProducerRegions/AdminProducerRegionsPage/AdminProducerRegionsPage";
import { AdminProducerRegionPage } from "./AdminProducerRegions/AdminProducerRegionPage/AdminProducerRegionPage";

/**
 * Use a relative path in the `path` property
 */
const tabs: Tab[] = [
  {
    name: "Details",
    path: ".",
  },
  {
    name: "Licenses",
    path: "./licenses",
  },
  {
    name: "Distribution",
    path: "./distribution",
  },
  {
    name: "Regional Fees",
    path: "./regions",
  },
];

interface ProducerProps extends RouteComponentProps {
  producerId?: string;
}

type WrappedProps = WithQueryComponentProps<
  ProducerProps,
  AdminProducerQuery,
  AdminProducerQueryVariables
>;

function AdminProducer({ query }: WrappedProps) {
  const { producer } = query?.data || null;

  return (
    producer && (
      <Page
        heading={
          <PageHeading
            title={producer.displayName}
            actions={
              <>
                {producer.canDelete && (
                  <DeleteProducerButton producerId={producer.id} />
                )}
                <ButtonLink
                  to={"/" + organizationPath(producer.organization)}
                  kind="secondary"
                  size="sm"
                >
                  View as customer
                </ButtonLink>
                <InviteUserToOrganizationModal
                  organization={producer.organization}
                  buttonProps={{ kind: "secondary" }}
                />
                <UpdateBreweryModal breweryId={producer.id} />
              </>
            }
          />
        }
      >
        <Tabs tabs={tabs} />
        <Router>
          <AdminProducerDetails path="/" parsedProducerId={producer.id} />
          <AdminProducerLicenses
            path="/licenses"
            parsedProducerId={producer.id}
          />
          <AdminProducerDistribution
            path="/distribution"
            parsedProducerId={producer.id}
          />
          <AdminProducerRegionsPage
            path="/regions"
            parsedProducerId={producer.id}
          />
          <AdminProducerRegionPage
            path="/regions/:regionId"
            parsedProducerId={producer.id}
          />
          <Redirect from="*" to="/not-found" />
        </Router>
      </Page>
    )
  );
}

export const AdminProducerPage = withQuery<
  ProducerProps,
  AdminProducerQuery,
  AdminProducerQueryVariables
>(AdminProducerDocument, {
  mapPropsToOptions: (props) => ({
    variables: { producerId: parseInt(props.producerId!) },
  }),
})(AdminProducer);
