import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useErrorHandler } from "react-error-boundary";

import {
  AdminProducerRegionPlatformFeesDocument,
  AdminProducerRegionPlatformFeesQuery,
  AdminProducerRegionPlatformFeesQueryVariables,
  useAdminAddProducerPlatformFeeMutation,
  useAdminUpdateProducerPlatformFeeMutation,
} from "@apollo/ops";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { Card } from "@components/ui/Card/Card";
import { DescriptionListInCard } from "@components/ui/DescriptionList";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { toDollars } from "@utilities/currency";
import {
  AdminProducerPlatformFeeRowData,
  AdminProducerPlatformFeesRows,
} from "./AdminProducerPlatformFeesRows";

interface AdminProducerPlatformFeesProps extends RouteComponentProps {
  producerId: string;
  regionId: string;
  title: string;
}

type WrappedProps = WithQueryComponentProps<
  AdminProducerPlatformFeesProps,
  AdminProducerRegionPlatformFeesQuery,
  AdminProducerRegionPlatformFeesQueryVariables
>;

export function AdminProducerPlatformFeesWithQuery({
  query,
  producerId,
  regionId,
  title,
}: WrappedProps) {
  const { data: results, error, refetch } = query;
  useErrorHandler(error);

  const parsedRegionId = parseInt(regionId);
  const parsedProducerId = parseInt(producerId);
  const [addPlatformFee] = useAdminAddProducerPlatformFeeMutation();
  const [updatePlatformFee] = useAdminUpdateProducerPlatformFeeMutation();

  const onBlur = async (data: AdminProducerPlatformFeeRowData) => {
    try {
      if (data.platformFeeId) {
        await updatePlatformFee({
          variables: {
            id: data.platformFeeId,
            data: {
              amount: data.amount,
            },
          },
        });
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Updated platform fee to $${toDollars(data.amount)}`,
        });
      } else {
        await addPlatformFee({
          variables: {
            data: {
              amount: data.amount,
              packageTypeId: data.packageTypeId,
              regionId: parsedRegionId,
              producerId: parsedProducerId,
            },
          },
        });
        await refetch();
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Added platform fee of $${toDollars(data.amount)}`,
        });
      }
    } catch (error) {
      console.error(error);
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <div className="mt-8">
      <div className="mt-10">
        {results && (
          <div className="mt-8 card">
            <Card title={`${title} Platform Fees`} className="overflow-visible">
              <DescriptionListInCard>
                <AdminProducerPlatformFeesRows
                  results={results}
                  onBlur={onBlur}
                />
              </DescriptionListInCard>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}

export const AdminProducerPlatformFees = withQuery<
  AdminProducerPlatformFeesProps,
  AdminProducerRegionPlatformFeesQuery,
  AdminProducerRegionPlatformFeesQueryVariables
>(AdminProducerRegionPlatformFeesDocument, {
  mapPropsToOptions: (props) => ({
    variables: {
      producerId: parseInt(props.producerId),
      regionId: parseInt(props.regionId),
    },
    fetchPolicy: "cache-and-network",
  }),
})(AdminProducerPlatformFeesWithQuery);
