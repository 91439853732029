import React from "react";
import { SidebarLayout, SidebarLayoutProps } from "@ui/Layouts/SidebarLayout";
import { LogoutButton } from "@ui/Sidebar/LogoutButton";

export function SidebarLayoutWithLogout(props: SidebarLayoutProps) {
  return (
    <SidebarLayout bottom={<LogoutButton />} {...props}>
      {props.children}
    </SidebarLayout>
  );
}
