import React from "react";
import { useAuthorizeOnDemandTransfersMutation } from "@apollo/ops";
import { FormButton } from "../../FormButton";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";

type NewAuthorizeOnDemandTransferProps = {
  bankAccountId: number;
  onAuthorize?: () => void;
};

export function NewAuthorizeOnDemandTransfer({
  bankAccountId,
  onAuthorize,
}: NewAuthorizeOnDemandTransferProps) {
  const [authorize, result] = useAuthorizeOnDemandTransfersMutation({
    variables: {
      bankAccountId,
    },
  });

  const handleSubmit = async () => {
    try {
      await authorize();

      if (onAuthorize) {
        onAuthorize();
      }
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.messge,
        });
      }
    }
  };

  return (
    <FormButton onSubmit={handleSubmit} disabled={result.loading} size="xl">
      Accept & Continue
    </FormButton>
  );
}
