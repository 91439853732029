import React, { useState } from "react";
import {
  DwollaCustomerBeneficialOwnerCertificationStatus,
  useSkipBeneficialOwnerStepMutation,
  OnboardingBeneficialOwnersStepFieldsFragment,
  OnboardingDocument,
  useOnboardingSyncDwollaCustomerMutation,
} from "@apollo/ops";
import { CreateBeneficialOwners } from "@components/dwolla/CreateBeneficialOwners/CreateBeneficialOwners";
import { Button } from "@components/ui/Button";
import { toastError } from "@utilities/toast";
import Bugsnag from "@bugsnag/browser";

interface BeneficialOwnerStepProps {
  step: OnboardingBeneficialOwnersStepFieldsFragment;
  next: () => void;
}

function BeneficialOwnerStep({ step }: BeneficialOwnerStepProps) {
  /**
   * Show instructions to users who haven't yet completed this step. A user may
   * have completed the step previously but need to re-certify, in which case we
   * don't show them the instructions.
   */
  const [showInstructions, setShowInstrutions] = useState<boolean>(
    step.dwollaCustomer.beneficialOwnerCertificationStatus ===
      DwollaCustomerBeneficialOwnerCertificationStatus.Uncertified
  );

  /**
   * Allows the user to skip ahead in the onboarding without completing this step
   */
  const [skipBeneficialOwnerStep] = useSkipBeneficialOwnerStepMutation();

  /**
   * After the beneficial owners drop-in component completes, we need to have our
   * backend sync the Dwolla Customer record so the beneficial ownership status is
   * updated and the user is moved on to the next onboarding step.
   */
  const [
    syncDwollaCustomer,
    syncMutationState,
  ] = useOnboardingSyncDwollaCustomerMutation({
    variables: { dwollaCustomerId: step.dwollaCustomer.id },
  });

  if (showInstructions) {
    return (
      <div className="mt-12 flex-row justify-center">
        <div className="text-3xl text-center font-bold p-6">
          Beneficial Owner Verification
        </div>
        <dt className="font-medium text-gray-500 text-center">
          Our third-party payment provider requires us to verify the identity of
          any owner of the business that has a stake of 25% or greater. To
          verify the identity of the ownership, it will require you to provide
          an owner&apos;s personal information. Direct Beer does not store this
          information, and Dwolla only uses the data provided for identity
          verification.
        </dt>
        <div className="mt-12 flex justify-center">
          <Button onClick={() => setShowInstrutions(false)} size="xl">
            Enter beneficial ownership information
          </Button>
        </div>
      </div>
    );
  }

  const handleSkip = async () => {
    try {
      await skipBeneficialOwnerStep({
        variables: { businessId: step.dwollaCustomer.businessEntityId },
        refetchQueries: [
          {
            query: OnboardingDocument,
            variables: { id: step.dwollaCustomer.businessEntityId },
          },
        ],
      });
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        Bugsnag.notify(error);
        toastError(error);
      }
    }
  };

  const handleSuccess = async () => {
    await syncDwollaCustomer();
  };

  return (
    <div className="mt-12 mx-auto">
      <div className="max-w-md mx-auto">
        <CreateBeneficialOwners
          dwollaCustomerId={step.dwollaCustomer.id}
          onSuccess={handleSuccess}
        />
      </div>

      <hr className="m-10" />

      <div className="text-center">
        <p className="text-sm">
          You are required to certify beneficial owner information before we can
          process payments against your account. If you&apos;ve already uploaded
          documentation and are waiting for approval you can continue with
          onboarding and complete beneficial owner certification later.
        </p>
        <Button
          onClick={handleSkip}
          kind="secondary"
          className="mt-4"
          disabled={syncMutationState.loading}
        >
          Continue &amp; complete ceritification later
        </Button>
      </div>
    </div>
  );
}

export default BeneficialOwnerStep;
