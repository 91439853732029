import React from "react";
import {
  AdminProducerDistributionDocument,
  AdminProducerDistributionQuery,
  AdminProducerDistributionQueryVariables,
  AssignDistributorToProducerMutation,
  AutocompleteDistributorFragment,
  DeleteDistributorProducerAssignmentMutation,
  DistributorProducerAssignmentFragment,
  Maybe,
  UpdateDistributorProducerAssignmentMutation,
  useAssignDistributorToProducerMutation,
  useDeleteDistributorProducerAssignmentMutation,
  useUpdateDistributorProducerAssignmentMutation,
} from "@apollo/ops";
import { AllDistributorsAutocomplete } from "@components/Distributor/DistributorAutocomplete/DistributorAutocomplete";
import { MutationUpdaterFn } from "@apollo/client";

type UpdateCacheType = (args: {
  producerId: number;
  action: "create" | "update" | "delete";
}) => MutationUpdaterFn<
  | AssignDistributorToProducerMutation
  | UpdateDistributorProducerAssignmentMutation
  | DeleteDistributorProducerAssignmentMutation
>;

const updateCache: UpdateCacheType = ({ producerId, action }) => (
  cache,
  { data }
) => {
  let newProducerDistributorAssignment: Maybe<DistributorProducerAssignmentFragment>;

  switch (action) {
    case "create":
      newProducerDistributorAssignment = (data as AssignDistributorToProducerMutation)
        ?.assignDistributorToProducer;
      break;
    case "update":
      newProducerDistributorAssignment = (data as UpdateDistributorProducerAssignmentMutation)
        ?.updateDistributorProducerAssignment;
      break;
    case "delete":
      newProducerDistributorAssignment = (data as DeleteDistributorProducerAssignmentMutation)
        ?.deleteDistributorProducerAssignment;
      break;
  }

  const oldVals = cache.readQuery<
    AdminProducerDistributionQuery,
    AdminProducerDistributionQueryVariables
  >({
    query: AdminProducerDistributionDocument,
    variables: { producerId },
  });

  const distributorAssignmentsWithoutMatchingAssignment = (
    oldVals?.producer?.distributorAssignments ?? []
  ).filter(
    (a) => a.regionV2.id !== newProducerDistributorAssignment?.regionV2.id
  );

  const distributorAssignments = distributorAssignmentsWithoutMatchingAssignment;

  if (action !== "delete" && newProducerDistributorAssignment) {
    distributorAssignments.push(newProducerDistributorAssignment);
  }

  cache.writeQuery({
    query: AdminProducerDistributionDocument,
    variables: { producerId },
    data: {
      producer: {
        ...oldVals?.producer,
        distributorAssignments,
      },
    },
  });
};

export const AdminProducerDistributorAutocomplete: React.FC<{
  regionId: number;
  selectedDistributorId?: number | null;
  producerId: number;
}> = ({ regionId, selectedDistributorId, producerId }) => {
  const [assignDistributor] = useAssignDistributorToProducerMutation();
  const [updateDistributor] = useUpdateDistributorProducerAssignmentMutation();
  const [deleteDistributor] = useDeleteDistributorProducerAssignmentMutation();

  const onSelect = (distributorId: number | null) => {
    if (!distributorId) return;

    if (selectedDistributorId) {
      return updateDistributor({
        variables: {
          data: {
            newDistributorId: distributorId,
            distributorId: selectedDistributorId,
            producerId,
            regionId,
          },
        },
        update: updateCache({ producerId, action: "update" }),
      });
    }

    return assignDistributor({
      variables: {
        data: { producerId, distributorId, regionId },
      },
      update: updateCache({ producerId, action: "create" }),
    });
  };

  const onDelete = (selectedItem?: AutocompleteDistributorFragment | null) => {
    if (!selectedItem) return;

    return deleteDistributor({
      variables: {
        data: {
          distributorId: selectedItem.id,
          producerId,
          regionId,
        },
      },
      update: updateCache({ producerId, action: "delete" }),
    });
  };

  return (
    <AllDistributorsAutocomplete
      onSelect={onSelect}
      onDelete={onDelete}
      selectedId={selectedDistributorId}
    />
  );
};
