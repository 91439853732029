import React, { ComponentType, FunctionComponent } from "react";
import { useBreweryQuery, BreweryFieldsFragment } from "@apollo/ops";

type WithBreweryProps = {
  breweryId?: string;
};

export type WithBreweryInjectedProps = {
  brewery: BreweryFieldsFragment;
};

export function withBrewery<TProps extends WithBreweryProps>(
  WrappedCmp: ComponentType<TProps & WithBreweryInjectedProps>
): FunctionComponent<TProps> {
  const WithBrewery = (props: TProps) => {
    // This component should always be rendered under a path with a :breweryId param
    if (props.breweryId === undefined) {
      throw new Error("Missing breweryId");
    }

    const breweryId = parseInt(props.breweryId);

    /**
     * This is expected to hit the cache.
     */
    const { data, error } = useBreweryQuery({
      variables: {
        breweryId,
      },
    });

    // TODO handle elegantly
    if (error) {
      throw new Error("Couldn't load brewery");
    }

    // TODO what should be shown here?
    if (!data) {
      return null;
    }

    return <WrappedCmp {...props} brewery={data.organization} />;
  };

  return WithBrewery;
}
