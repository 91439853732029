import React, { useState } from "react";
import { DialogProps, useDialog } from "@ui/Dialog";
import { NewAuthorizeOnDemandTransferDialog } from "../AuthorizeOnDemandTransfers/AuthorizeOnDemandTransfersDialog";
import { AddBankAccountDialog, HandleAdd } from "../AddBankAccountDialog";

type AddAndAuthorizeBankAccountDialogProps = {
  businessId: number;
  dialogProps: Omit<DialogProps, "children">;
};

/**
 * This dialog is used to add a bank account when you already have completed the onboarding process
 * and deleted your back account afterwards.
 */
export function AddAndAuthorizeBankAccountDialog({
  businessId,
  dialogProps,
}: AddAndAuthorizeBankAccountDialogProps) {
  const [bankAccountId, setBankAccountId] = useState<number | null>(null);
  const authorizeOnDemandTransfersDialog = useDialog();
  const handleAdd: HandleAdd = (bankAccount) => {
    setBankAccountId(bankAccount.id);
    dialogProps.onClose();
    authorizeOnDemandTransfersDialog.open();
  };

  return (
    <>
      <AddBankAccountDialog
        businessId={businessId}
        onAdd={handleAdd}
        dialogProps={dialogProps}
      />

      {bankAccountId && (
        <NewAuthorizeOnDemandTransferDialog
          bankAccountId={bankAccountId}
          dialogProps={authorizeOnDemandTransfersDialog.getDialogProps()}
        />
      )}
    </>
  );
}
