import React from "react";
import { AdminProducerRegionPlatformFeesQuery } from "@apollo/ops";
import { LeftAlignedTerm } from "@components/ui/DescriptionList";
import { Input } from "@components/ui/Input";
import { toDollars, toCents } from "@utilities/currency";

export type AdminProducerPlatformFeeRowData = {
  platformFeeId?: number | null;
  amount: number;
  packageTypeId: number;
};

export const AdminProducerPlatformFeesRows: React.FC<{
  results: AdminProducerRegionPlatformFeesQuery;
  onBlur: (data: AdminProducerPlatformFeeRowData) => void;
}> = ({ results, onBlur }) => {
  return (
    <>
      {results.producerRegionPlatformFees.map((regionPlatformFee) => {
        const { packageType, platformFee } = regionPlatformFee;

        return (
          <LeftAlignedTerm
            term={packageType.displayName}
            definition={
              <Input
                key={packageType.id}
                type="number"
                inlineLead="$"
                step={0.01}
                defaultValue={toDollars(platformFee?.amount || 0)}
                onBlur={(e) =>
                  onBlur({
                    platformFeeId: platformFee?.id,
                    amount: toCents(e.target.value),
                    packageTypeId: packageType.id,
                  })
                }
              />
            }
            key={packageType.id}
          />
        );
      })}
    </>
  );
};
