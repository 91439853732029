import React from "react";
import { Link } from "@ui/Link";
import { adminProductPath } from "@routes";
import { formatAsDate } from "@utilities/formatDate";

type AdminProductLinkProps = {
  product: {
    id: number;
    name: string;
    packageType?: {
      displayName: string;
    };
    producer?: {
      displayName: string;
    };
    packageDate?: string;
    productImage?: {
      rawUrl: string;
      thumbnailUrl: string;
    } | null;
  };
};

export function AdminProductLink({ product }: AdminProductLinkProps) {
  return (
    <div className="inline-flex">
      <div className="h-[60px] w-[60px] mr-4">
        {product.productImage?.thumbnailUrl ? (
          <img
            src={product.productImage.thumbnailUrl}
            className="h-full w-full object-contain"
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center text-xs muted"></div>
        )}
      </div>
      <div>
        <span>
          <Link to={adminProductPath(product.id)}>{product.name}</Link>
          &nbsp;(ID: {product.id})
        </span>
        <div className="font-bold text-sm">{product.producer?.displayName}</div>
        <div className="text-sm">
          {product.packageType?.displayName}{" "}
          {product.packageType && product.packageDate
            ? "packaged on " + formatAsDate(product.packageDate)
            : ""}
        </div>
      </div>
    </div>
  );
}
