import { FC } from "react";
import { TransactionsView } from "./TransactionsView";
import { RouteComponentProps } from "@reach/router";
import { compose } from "lodash/fp";
import { withHook } from "@hoc/withHook";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { NoProps } from "@shared/types/NoProps";
import {
  useTransactionsEnhancer,
  TransactionsEnhancerResult,
} from "../TransactionsEnhancer";
type TransactionsProps = NoProps;
export const AdminTransactions: FC<
  RouteComponentProps<TransactionsProps>
> = compose(
  withErrorBoundary,
  withHook<TransactionsProps, TransactionsEnhancerResult>({
    useHook: useTransactionsEnhancer,
  })
)(TransactionsView);
