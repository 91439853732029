import { DwollaCustomerType } from "@apollo/ops";

export * from "./BeneficialOwnershipCertificationStatusBadge";
export * from "./DwollaCustomerVerificationStatusBadge";

export const displayCustomerType = (type: DwollaCustomerType) => {
  switch (type) {
    case DwollaCustomerType.Business:
      return "Business";
    case DwollaCustomerType.Personal:
      return "Personal";
    case DwollaCustomerType.ReceiveOnly:
      return "Receive Only";
    case DwollaCustomerType.Unverified:
      return "Unverified";
  }
};
