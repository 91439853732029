import React, { FC } from "react";
import { Dialog } from "@components/ui/Dialog";
import { OrderTransferForm, OrderTransferFormData } from "./OrderTransferForm";
import { toDollars } from "@utilities/currency";

export enum TransferSource {
  Seller = "SELLER",
  BA = "BA",
  Buyer = "BUYER",
}

export enum TransferMode {
  Payment = "PAYMENT",
  Balance = "BALANCE",
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: OrderTransferFormData) => void | Promise<void>;
  orderId: number;
  orderNumber: string;
  transferSource: TransferSource;
  paymentToBa?: boolean;
  transferMode: TransferMode;
  defaultTransferAmount?: number;
}

export const OrderTransferModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  orderId,
  orderNumber,
  transferSource,
  paymentToBa = false,
  transferMode,
  defaultTransferAmount,
}) => {
  const wrappedOnClose = () => {
    onClose();
  };

  const dialogTitle = `New ${
    transferMode === TransferMode.Payment ? "Payment" : "Balance Transfer"
  } for Order ${orderNumber}`;

  return (
    <Dialog
      title={dialogTitle}
      width="2xl"
      isOpen={isOpen}
      onClose={wrappedOnClose}
    >
      <OrderTransferForm
        onSubmit={onSubmit}
        orderId={orderId}
        transferSource={transferSource}
        paymentToBa={paymentToBa}
        transferMode={transferMode}
        defaultValues={{
          amount: defaultTransferAmount
            ? toDollars(defaultTransferAmount)
            : undefined,
        }}
      />
    </Dialog>
  );
};
