import React, { useMemo } from "react";
import { Column, TableInstance, useSortBy, useTable } from "react-table";
import { Table } from "@ui/Table";
import {
  AdminRegionsDocument,
  AdminRegionsQuery,
  AdminRegionsTableRowDataFragment,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { RouteComponentProps } from "@reach/router";
import { useErrorHandler } from "react-error-boundary";
import { AdminRegionLink } from "@admin/components/AdminRegionLink";
import { LoadingPage } from "@components/LoadingPage";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";

type WrappedProps = WithQueryComponentProps<
  RouteComponentProps,
  AdminRegionsQuery
>;

function AdminRegionsPageBase({ query }: WrappedProps) {
  const { data, loading, error } = query;

  useErrorHandler(error);

  if (loading && !data) {
    return <LoadingPage />;
  }

  const breadcrumbProps = {
    items: buildBreadcrumbs("Regions"),
  };

  return (
    <Page
      heading={
        <PageHeading title="Regions" breadcrumbProps={breadcrumbProps} />
      }
    >
      {data && <RegionsTable regions={data.regions} />}
    </Page>
  );
}

type RegionsTableProps = {
  regions: Array<AdminRegionsTableRowDataFragment>;
};

function RegionsTable({ regions }: RegionsTableProps) {
  const columns: Array<Column<AdminRegionsTableRowDataFragment>> = useMemo(
    () => [
      {
        Header: "Name",
        Cell: ({ row }) => (
          <>
            <AdminRegionLink region={row.original} />{" "}
            <span>(ID: {row.original.id})</span>
          </>
        ),
        accessor: "friendlyName",
      },
      {
        Header: "State",
        accessor: (row) => row.state.name,
        id: "state",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: regions,
    },
    useSortBy
  ) as TableInstance<AdminRegionsTableRowDataFragment>;

  return <Table instance={tableInstance} />;
}

export const AdminRegionsPage = withQuery<
  RouteComponentProps,
  AdminRegionsQuery
>(AdminRegionsDocument)(AdminRegionsPageBase);
