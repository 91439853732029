import React, { ReactElement } from "react";
import { CheckIcon } from "@heroicons/react/solid";

export interface StepProps {
  name: string;
  description?: string;
  index?: number;
  isLast?: boolean;
}

export type StepStatus = "upcoming" | "current" | "completed";

export interface StepsProps {
  currentIndex: number;
  children: ReactElement<StepProps & { status?: StepStatus }>[];
}

export function Steps({ currentIndex, children }: StepsProps) {
  const childrenWithStatus = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      status: getStepStatus(index, currentIndex),
      index: index + 1,
      isLast: index === children.length - 1,
    })
  );

  return (
    <nav aria-label="Progress">
      <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 laptop:flex laptop:divide-y-0">
        {childrenWithStatus.map((child, index) => (
          <li key={index} className="relative laptop:flex-1 laptop:flex">
            {child}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export function PanelStep({
  status,
  ...rest
}: StepProps & { status?: StepStatus }) {
  switch (status) {
    case "upcoming":
      return <UpcomingPanelStep {...rest} />;
    case "current":
      return <CurrentPanelStep {...rest} />;
    case "completed":
      return <CompletedPanelStep {...rest} />;
    default:
      throw new Error(
        "Step must be given a status. Was this rendered inside a <Steps /> component?"
      );
  }
}

export function UpcomingPanelStep({ name, index, isLast }: StepProps) {
  return (
    <>
      <div className="group flex items-center w-full">
        <span className="px-6 py-4 flex items-center text-sm font-medium w-full">
          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
            <span className="text-gray-500">0{index}</span>
          </span>
          <span className="ml-4 text-sm font-medium text-gray-500">{name}</span>
        </span>
      </div>
      {!isLast && <StepSeparator />}
    </>
  );
}

export function CurrentPanelStep({ name, index, isLast }: StepProps) {
  return (
    <>
      <div className="px-6 py-4 flex items-center text-sm font-medium w-full">
        <span
          className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-600 rounded-full"
          aria-current="step"
        >
          <span className="text-primary-600">0{index}</span>
        </span>
        <span className="ml-4 text-sm font-medium text-primary-600">
          {name}
        </span>
      </div>

      {!isLast && <StepSeparator />}
    </>
  );
}

export function CompletedPanelStep({ name, isLast }: StepProps) {
  return (
    <>
      <div className="group flex items-center w-full">
        <span className="px-6 py-4 flex items-center text-sm font-medium">
          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full">
            <CheckIcon className="w-6 h-6 text-white" />
          </span>
          <span className="ml-4 text-sm font-medium text-gray-900">{name}</span>
        </span>
      </div>
      {!isLast && <StepSeparator />}
    </>
  );
}

function StepSeparator() {
  return (
    <div
      className="hidden laptop:block absolute top-0 right-0 h-full w-5"
      aria-hidden="true"
    >
      <svg
        className="h-full w-full text-gray-300"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M0 -2L20 40L0 82"
          vectorEffect="non-scaling-stroke"
          stroke="currentcolor"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

const getStepStatus = (stepIndex: number, currentIndex: number) =>
  stepIndex < currentIndex
    ? "completed"
    : stepIndex > currentIndex
    ? "upcoming"
    : "current";
