import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import {
  AdminRetailerDocument,
  AdminRetailerQuery,
  AdminRetailerQueryVariables,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { UpdateRetailerModal } from "@components/Retailer/UpdateRetailer/UpdateRetailerModal";
import { organizationPath } from "@routes";
import { ButtonLink } from "@components/ui/Button";
import { Tab, Tabs } from "@components/ui/Tabs";
import { InviteUserToOrganizationModal } from "../../components/InviteUserToOrganizationModal/InviteUserToOrganizationModal";
import { AdminRetailerDetails } from "./AdminRetailerDetails";
import { DeleteRetailerButton } from "./DeleteRetailerButton";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { AdminRetailerSalesOrders } from "@admin/AdminRetailersContainer/AdminRetailerPage/RetailerSalesOrders/AdminRetailerSalesOrdersPage";

/**
 * Use a relative path in the `path` property
 */
const tabs: Tab[] = [
  {
    name: "Details",
    path: ".",
  },
  {
    name: "Order History",
    path: "./orders",
  },
];

interface RetailerProps extends RouteComponentProps {
  retailerId?: string;
}

type WrappedProps = WithQueryComponentProps<
  RetailerProps,
  AdminRetailerQuery,
  AdminRetailerQueryVariables
>;

function AdminRetailer({ query }: WrappedProps) {
  const { retailer } = query.data || null;

  return (
    retailer && (
      <Page
        heading={
          <PageHeading
            title={retailer.displayName}
            actions={
              <>
                {retailer.canDelete && (
                  <DeleteRetailerButton retailerId={retailer.id} />
                )}
                <ButtonLink
                  to={"/" + organizationPath(retailer.organization)}
                  kind="secondary"
                >
                  View as customer
                </ButtonLink>
                <InviteUserToOrganizationModal
                  organization={retailer.organization}
                  buttonProps={{ kind: "secondary" }}
                />
                <UpdateRetailerModal retailerId={retailer.organization.id} />
              </>
            }
          />
        }
      >
        <Tabs tabs={tabs} />
        <Router>
          <AdminRetailerDetails path="/" parsedRetailerId={retailer.id} />
          <AdminRetailerSalesOrders
            path="/orders"
            parsedRetailerId={retailer.id}
          />
        </Router>
      </Page>
    )
  );
}

export const AdminRetailerPage = withQuery<
  RetailerProps,
  AdminRetailerQuery,
  AdminRetailerQueryVariables
>(AdminRetailerDocument, {
  mapPropsToOptions: (props) => ({
    variables: { retailerId: parseInt(props.retailerId!) },
  }),
})(AdminRetailer);
