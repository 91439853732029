import React from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { AdminPackagesPage } from "./AdminPackagesPage/AdminPackagesPage";

function AdminPackagesContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminPackagesPage path="/" />
      <Redirect from="*" to="/not-found" />
    </Router>
  );
}

export default AdminPackagesContainer;
