import React from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "@ui/Dialog";
import { LabeledInput } from "@ui/Input";
import { Button } from "@ui/Button";
import { toCents, toDollars } from "@utilities/currency";
import { useAdminUpdateDistributorDetailsMutation } from "@apollo/gen/generatedOperations";

export type UpdateDistributorDetailsCardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  feePerPackageInCents: number;
  distributorId: number;
};

type DistributorDetailsFormData = {
  feePerPackageInDollars: number;
};

const requiredRule = {
  value: true,
  message: "This field is required.",
};

export function UpdateDistributorDetailsCardModal({
  isOpen,
  onClose,
  feePerPackageInCents,
  distributorId,
}: UpdateDistributorDetailsCardModalProps) {
  const defaultValues = {
    feePerPackageInDollars: toDollars(feePerPackageInCents),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isValidating },
    reset,
  } = useForm<DistributorDetailsFormData>({
    defaultValues,
  });

  const wrappedOnClose = () => {
    reset(defaultValues);
    onClose();
  };

  const [
    adminUpdateDistributorDetailsMutation,
  ] = useAdminUpdateDistributorDetailsMutation();

  const onSubmit = async (data: DistributorDetailsFormData) => {
    await adminUpdateDistributorDetailsMutation({
      variables: {
        id: distributorId,
        data: {
          feePerPackageInCents: toCents(data.feePerPackageInDollars),
        },
      },
    });
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={wrappedOnClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabeledInput
          label="Fee Per Package"
          inputProps={{
            inlineLead: "$",
            error: errors.feePerPackageInDollars?.message,
            type: "number",
            step: 0.01,
            ...register("feePerPackageInDollars", {
              required: requiredRule,
              min: {
                value: 0.0,
                message: "Must be greater than or equal to $0",
              },
            }),
          }}
        />
        <div className="mt-5">
          <Button type="submit" disabled={isSubmitting || isValidating}>
            Submit
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
