import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { Button } from "../../ui/Button";
import classNames from "classnames";

export type RenewalFormData = {
  startDate: Date;
  endDate: Date;
};

type RenewalFormProps = {
  onSubmit: (data: RenewalFormData) => void | Promise<void>;
  submitText?: string;
};

export function RenewalForm({
  onSubmit,
  submitText = "Save",
}: RenewalFormProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<RenewalFormData>();

  const wrappedOnSubmit = async (data: RenewalFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <div className="mb-4">
          <label
            htmlFor="startDate"
            className="block text-sm font-medium text-gray-700"
          >
            New Issue Date
          </label>
          <div className="mt-1">
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Issue Date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  value={value}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  monthNavigator
                  placeholder="Pick the Issue Date"
                  className={classNames({
                    "border-red-300": errors.startDate,
                  })}
                />
              )}
            />
            {errors.startDate && (
              <small className="text-sm text-red-600">
                Issue Date is required
              </small>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="endDate"
            className="block text-sm font-medium text-gray-700"
          >
            New Expiration Date
          </label>
          <div className="mt-1">
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Expiration Date is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  value={value}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  monthNavigator
                  placeholder="Pick the Expiration Date"
                  className={classNames({
                    "border-red-300": errors.endDate,
                  })}
                />
              )}
            />
            {errors.endDate && (
              <small className="text-sm text-red-600">
                Expiration Date is required
              </small>
            )}
          </div>
        </div>
        <div className="mt-2">
          <Button size="lg" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  );
}
