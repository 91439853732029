import React from "react";
import {
  AdminBankAccountDetailsFragment,
  useRemoveBankAccountMutation,
} from "@apollo/ops";
import { Card, CardBody } from "@components/ui/Card/Card";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";
import { VerificationStatusTag } from "./PlaidItem";
import { Button } from "@components/ui/Button";

interface MaskProps {
  mask: string;
}

export function Mask({ mask }: MaskProps) {
  return <span>•••••••{mask}</span>;
}

interface BankAccountProps {
  className?: string;
  bankAccount: {
    mask: string;
    institutionName: string | null;
    name: string | null;
  };
}

export function BankAccount({ bankAccount, className }: BankAccountProps) {
  return (
    <div className={className}>
      {bankAccount.name && bankAccount.name + " - "}
      <Mask mask={bankAccount.mask} />
    </div>
  );
}

type AdminBankAccountDetailsCardProps = {
  bankAccount: AdminBankAccountDetailsFragment | null;
};

export function AdminBankAccountDetailsCard({
  bankAccount,
}: AdminBankAccountDetailsCardProps) {
  const [removeBankAccount, { loading }] = useRemoveBankAccountMutation();

  const handleRemoveBankAccount = async () => {
    if (bankAccount) {
      await removeBankAccount({
        variables: {
          id: bankAccount.id,
          force: true,
        },
      });
    }
  };

  return (
    <Card
      headerProps={{
        title: "Bank Account",
        actions: bankAccount ? (
          <Button
            destructive
            kind="secondary"
            onClick={handleRemoveBankAccount}
            disabled={loading}
          >
            Remove
          </Button>
        ) : undefined,
      }}
    >
      {bankAccount ? (
        <DescriptionListInCard>
          <LeftAlignedTerm term="Name" definition={bankAccount.name} />
          <LeftAlignedTerm
            term="Plaid Item ID"
            definition={
              bankAccount.plaidItem
                ? bankAccount.plaidItem.itemId
                : "No Plaid Item"
            }
          />
          <LeftAlignedTerm
            term="Plaid Verification Status"
            definition={
              bankAccount.plaidItem ? (
                <VerificationStatusTag
                  verificationStatus={bankAccount.plaidItem?.verificationStatus}
                />
              ) : (
                "No Plaid Item"
              )
            }
          />
          <LeftAlignedTerm
            term="Dwolla Funding Source"
            definition={
              bankAccount.dwollaFundingSource ? (
                <span className="font-mono">
                  {bankAccount.dwollaFundingSource.id}
                </span>
              ) : (
                "No Dwolla Funding Source"
              )
            }
          />
        </DescriptionListInCard>
      ) : (
        <CardBody>
          <span className="text-muted">No account</span>
        </CardBody>
      )}
    </Card>
  );
}
