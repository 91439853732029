import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "@ui/Button";
import { Input } from "@ui/Input";
import { Checkbox } from "@ui/Checkbox";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { RegisterRetailerOrganizationData } from "@apollo/ops";
import { StoreNameInput } from "@components/Store/StoreNameInput";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";

export type RegisterRetailerFormData = RegisterRetailerOrganizationData & {
  agreeToToSAndPP: boolean;
  agreeToLicensed: boolean;
};

type RegisterRetailerFormProps = {
  onSubmit: (data: RegisterRetailerFormData) => void | Promise<void>;
  defaultValues?: Partial<RegisterRetailerFormData>;
};

export function RegisterRetailerForm({
  onSubmit,
  defaultValues = {
    businessName: "Main Location",
    forceDwollaBusinessCustomer: false,
    organizationName: "",
  },
}: RegisterRetailerFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
    watch,
  } = useForm<RegisterRetailerFormData>({
    defaultValues,
  });

  const wrappedOnSubmit = async (data: RegisterRetailerFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  const organizationName = watch("organizationName");

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label
            htmlFor="orgName"
            className="block text-sm font-medium text-gray-700"
          >
            Organization Name
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("organizationName", {
                required: {
                  value: true,
                  message: "Organization name is required",
                },
              })}
              error={errors.organizationName?.message}
            />
          </div>
        </div>
        <div>
          <StoreNameInput
            {...register("businessName", {
              required: {
                value: true,
                message: "Location name is required",
              },
            })}
            error={errors.businessName?.message}
            organizationName={organizationName}
          />
        </div>
        <div>
          <label
            htmlFor="region"
            className="block text-sm font-medium text-gray-700"
          >
            Region
          </label>
          <div className="mt-1">
            <Controller
              name="regionId"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: "Region is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <RegionsDropdown value={value} onChange={onChange} />
              )}
            />
            {errors.regionId && (
              <small className="text-sm text-red-600">Region is required</small>
            )}
          </div>
        </div>
        <div>
          <Checkbox
            {...register("agreeToToSAndPP", {
              validate: (value) =>
                value ||
                "You must agree to the Terms of Service and Privacy Policy",
            })}
            error={errors.agreeToToSAndPP?.message}
            label={
              <>
                I agree to the{" "}
                <a href="/terms-of-service" target="_blank" className="link">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="/privacy-policy" target="_blank" className="link">
                  Privacy Policy
                </a>{" "}
                of Beverage Advisors (Direct.Beer)
              </>
            }
          />
        </div>
        <div>
          <Checkbox
            {...register("agreeToLicensed", {
              validate: (value) =>
                value || "You must agree to license statement",
            })}
            error={errors.agreeToLicensed?.message}
            label={
              <>
                I understand that Direct.Beer only accepts orders placed for
                sale in licensed retailer locations. I also understand that I
                must be willing and able to provide proof of my business valid
                liquor license before placing my first order with Direct.Beer
              </>
            }
          />
        </div>
        <div className="mt-3">
          <Button size="xl" type="submit" disabled={isSubmitting}>
            Register
          </Button>
        </div>
      </div>
    </form>
  );
}
