import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { StoreRouterParams } from "../StoreRouterParam";
import { StoreSalesOrderPage } from "./StoreSalesOrderPage/StoreSalesOrderPage";
import { StoreSalesOrdersPage } from "./StoreSalesOrdersPage/StoreSalesOrdersPage";

export function StoreSalesOrdersRouter(
  _: RouteComponentProps<StoreRouterParams>
) {
  return (
    <Router>
      <StoreSalesOrdersPage path="/" />
      <StoreSalesOrderPage path=":orderIdParam" />
    </Router>
  );
}
