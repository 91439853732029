import React from "react";
import styled from "styled-components";

export const OverlayCmp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  color: white;
  justify-items: center;
  align-items: center;
  ${(props: { visible: boolean }) =>
    props.visible ? "display: grid" : "display: none"}
`;
export const Overlay: React.FC<{ visible: boolean }> = ({
  visible,
  children,
}) => {
  return <OverlayCmp visible={visible}>{children}</OverlayCmp>;
};
