import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "../../ui/Button";
import { Input } from "../../ui/Input";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { Checkbox } from "@components/ui/Checkbox";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";

export type RetailerFormData = {
  orgName: string;
  locationName: string;
  region: number;
  forceDwollaBusinessCustomer: boolean;
};

type RetailerFormProps = {
  onSubmit: (data: RetailerFormData) => void | Promise<void>;
  defaultValues?: Partial<RetailerFormData>;
  submitText?: string;
};

export function RetailerForm({
  onSubmit,
  defaultValues = {},
  submitText = "Submit",
}: RetailerFormProps) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<RetailerFormData>({
    defaultValues: {
      ...defaultValues,
      forceDwollaBusinessCustomer: false,
    },
  });

  const wrappedOnSubmit = async (data: RetailerFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label
            htmlFor="orgName"
            className="block text-sm font-medium text-gray-700"
          >
            Organization Name
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("orgName", {
                required: {
                  value: true,
                  message: "Organization Name is required",
                },
              })}
              id="orgName"
              placeholder="Organization Name"
              error={errors.orgName?.message}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="locationName"
            className="block text-sm font-medium text-gray-700"
          >
            Location Name
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("locationName", {
                required: {
                  value: true,
                  message: "Location Name is required",
                },
              })}
              id="locationName"
              placeholder="Location Name"
              error={errors.locationName?.message}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="region"
            className="block text-sm font-medium text-gray-700"
          >
            Region
          </label>
          <div className="mt-1">
            <Controller
              name="region"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: "Region is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <RegionsDropdown onChange={onChange} value={value} />
              )}
            />
            {errors.region && (
              <small className="text-sm text-red-600">Region is required</small>
            )}
          </div>
        </div>
        <div>
          <Checkbox
            {...register("forceDwollaBusinessCustomer")}
            label="Register as Business Verified Dwolla customer"
            description="Enables retailers to purchase more than $5k beer per week"
          />
        </div>
        <div className="mt-2">
          <Button size="lg" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  );
}
