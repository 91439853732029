import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  PreSaleImportJobQuery,
  PreSaleImportJobStatus,
  usePreSaleImportJobQuery,
} from "@apollo/gen/generatedOperations";
import {
  formatAsDate,
  formatAsDateWithTimeFromString as formatDate,
} from "@utilities/formatDate";
import { capitalize } from "lodash";
import { Page, PageHeading } from "@ui/Page";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";
import { Panel } from "primereact/panel";
import { Card } from "@ui/Card/Card";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";
import { CalendarIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { LoadingPage } from "@components/LoadingPage";

interface PreSaleImportJobProps extends RouteComponentProps {
  preSaleImportJobId?: string;
}

export function AdminPreSaleImportJobPage({
  preSaleImportJobId,
}: PreSaleImportJobProps) {
  const { data } = usePreSaleImportJobQuery({
    variables: { id: parseInt(preSaleImportJobId as string) },
    pollInterval: 5 * 1000,
  });

  if (data) {
    const { preSaleImportJob } = data;

    const breadcrumbProps = {
      items: buildBreadcrumbs(
        "Pre-Sales",
        "Import Jobs",
        `Import Job #${preSaleImportJobId}`
      ),
    };

    const metaProps = {
      items: [
        {
          icon: LocationMarkerIcon,
          text: preSaleImportJob.regionV2.friendlyName,
        },
        {
          icon: CalendarIcon,
          text:
            formatAsDate(preSaleImportJob.start) +
            " to " +
            formatAsDate(preSaleImportJob.end),
        },
      ],
    };

    return (
      <Page
        heading={
          <PageHeading
            title={"Import Job #" + preSaleImportJobId}
            breadcrumbProps={breadcrumbProps}
            metaProps={metaProps}
          />
        }
      >
        {data && <PreSaleJobImportDetails data={data} />}
      </Page>
    );
  }

  return <LoadingPage />;
}

function PreSaleJobImportDetails({
  data: { preSaleImportJob },
}: {
  data: PreSaleImportJobQuery;
}) {
  return (
    <>
      <Card title="Details">
        <DescriptionListInCard>
          <LeftAlignedTerm term="ID" definition={preSaleImportJob.id} />
          <LeftAlignedTerm
            term="Region"
            definition={preSaleImportJob.regionV2.friendlyName}
          />
          <LeftAlignedTerm
            term="Created At"
            definition={formatDate(preSaleImportJob.createdAt)}
          />
          <LeftAlignedTerm
            term="Start"
            definition={formatDate(preSaleImportJob.start)}
          />
          <LeftAlignedTerm
            term="End"
            definition={formatDate(preSaleImportJob.end)}
          />
          <LeftAlignedTerm
            term="Status"
            definition={capitalize(preSaleImportJob.status)}
          />
        </DescriptionListInCard>
      </Card>

      {preSaleImportJob.status === PreSaleImportJobStatus.Failed && (
        <ErrorDetails failureReason={preSaleImportJob.failureReason} />
      )}
    </>
  );
}

function ErrorDetails({
  failureReason,
}: {
  failureReason: Record<string, unknown> | Array<unknown>;
}) {
  return (
    <Panel header="Error Details" className="mt-10">
      <pre>{JSON.stringify(failureReason, null, 2)}</pre>
    </Panel>
  );
}
