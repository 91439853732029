import { RouteComponentProps } from "@reach/router";
import React from "react";
import {
  useAdminPreSaleProductsQuery,
  useProductFulfillmentExclusionReasonsQuery,
} from "@apollo/ops";
import { PreSaleProductsTable } from "@components/PreSaleProductsTable/PreSaleProductsTable";
import { Page } from "@components/ui/Page";
import { AdminPreSalePageHeading } from "../AdminPreSalePageHeading";

type AdminPreSaleProductsPageProps = RouteComponentProps<{
  preSaleIdParam: string;
}>;

/**
 *
 * Component to show the Products and quantities bought in a PreSale.
 */
export const AdminPreSaleProductsPage = ({
  preSaleIdParam,
}: AdminPreSaleProductsPageProps) => {
  const { data, error } = useAdminPreSaleProductsQuery({
    variables: {
      id: Number(preSaleIdParam),
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    throw new Error();
  }

  //Force refresh exclusion reasons in local cache when viewing this tab
  useProductFulfillmentExclusionReasonsQuery({ fetchPolicy: "network-only" });

  return (
    <div>
      {data && (
        <Page
          heading={
            <AdminPreSalePageHeading preSale={data.preSale} page="Products" />
          }
        >
          <PreSaleProductsTable
            path="products"
            data={data?.preSale?.preSaleProducts || []}
          ></PreSaleProductsTable>
        </Page>
      )}
    </div>
  );
};
