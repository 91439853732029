import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { defaultErrorHandler } from "@utilities/toast";
import { Button } from "@ui/Button";
import { OfferQuantityInput } from "@components/Offer/OfferInputs";
import { Label } from "@ui/Input";
import { Calendar } from "primereact/calendar";
import { DateTime } from "luxon";

export type UpdateOfferSubmitData = {
  quantity: number;
  earliestPickup?: Date;
};

type UpdateOfferFormProps = {
  onSubmit: (data: UpdateOfferSubmitData) => void | Promise<void>;
  offer: {
    quantity: number;
    earliestPickup?: string;
  };
};

type OfferFormData = {
  quantity: string;
  earliestPickup?: Date;
};

export function UpdateOfferForm({ onSubmit, offer }: UpdateOfferFormProps) {
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<OfferFormData>({
    defaultValues: toFormData(offer),
  });

  const wrappedOnSubmit: SubmitHandler<OfferFormData> = async (data) => {
    try {
      await onSubmit(toSubmitData(data));
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-2 gap-4">
        <OfferQuantityInput
          register={register}
          fieldName="quantity"
          error={errors.quantity}
        />
        <div>
          <Label>Earliest Pickup Date</Label>
          <Controller
            name="earliestPickup"
            defaultValue={offer.earliestPickup}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Calendar
                value={value}
                appendTo="self"
                onChange={({ value }) => onChange(value)}
                monthNavigator
                minDate={new Date()}
                showIcon={true}
              />
            )}
          />
        </div>
      </div>

      <Button size="lg" type="submit" disabled={isSubmitting} className="mt-5">
        Update offer
      </Button>
    </form>
  );
}

function toSubmitData(formData: OfferFormData): UpdateOfferSubmitData {
  return {
    ...formData,
    quantity: parseInt(formData.quantity),
  };
}

function toFormData(formData: {
  quantity: number;
  earliestPickup?: string;
}): OfferFormData {
  return {
    quantity: formData.quantity.toString(),
    earliestPickup: formData.earliestPickup
      ? DateTime.fromISO(formData.earliestPickup).toJSDate()
      : undefined,
  };
}
