import React from "react";
import {
  ProducerPurchaseOrderOverviewQuery,
  useProducerPurchaseOrderDocumentsQuery,
} from "@apollo/ops";
import { OrderDocumentsTable } from "@components/OrderDocument/OrderDocumentsTable";
import { ErrorAlert } from "@components/ui/Alert";
import { Page } from "@components/ui/Page";
import { LoadingPage } from "@components/LoadingPage";
import { ProducerPurchaseOrderPageHeading } from "../ProducerPurchaseOrderPageHeading";
import { RouteComponentProps } from "@reach/router";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";

interface ProducerPurchaseOrderDocumentsProps extends RouteComponentProps {
  orderId: number;
  orderSummary: ProducerPurchaseOrderOverviewQuery["order"];
}

export function ProducerPurchaseOrderDocumentsPage({
  orderId,
  orderSummary,
}: ProducerPurchaseOrderDocumentsProps) {
  const { data, error } = useProducerPurchaseOrderDocumentsQuery({
    variables: {
      orderId,
    },
  });

  if (error) {
    return <ErrorAlert title="An error occurred" />;
  }

  const breadCrumbs = {
    items: buildBreadcrumbs("Purchase Orders", `#${orderSummary.number}`),
  };

  if (data && orderSummary) {
    const { order } = data;

    return (
      <Page
        heading={
          <ProducerPurchaseOrderPageHeading
            page="Documents"
            order={orderSummary}
            breadCrumbs={breadCrumbs}
          />
        }
      >
        {order.documents.length > 0 && (
          <OrderDocumentsTable
            documents={data.order.documents}
            disableDelete={true}
          />
        )}
        {order.documents.length === 0 && (
          <h3 className="mt-6 text-sm text-center font-medium text-gray-900">
            No documents
          </h3>
        )}
      </Page>
    );
  }

  return <LoadingPage />;
}
