import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Dropdown } from "@ui/Dropdown";
import { Label, LabeledInput } from "@ui/Input";
import { Button } from "@components/ui/Button";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { ContainerLabel, ContainerVolumeUnit } from "@apollo/ops";
import classNames from "classnames";

type AddPackageTypeFormProps = {
  onSubmit: (data: AddPackageTypeFormData) => unknown | Promise<unknown>;
};

export type AddPackageTypeFormData = {
  units: string;
  containerLabel: ContainerLabel;
  containerVolume: string;
  containerVolumeUnit: ContainerVolumeUnit;
  containerStreetName?: string;
  weight: string;
};

/**
 * This form is loaded inside a Dialog and thus always renders a focusable
 * element.
 */
export function AddPackageTypeForm({ onSubmit }: AddPackageTypeFormProps) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<AddPackageTypeFormData>();

  const wrappedOnSubmit = async (data: AddPackageTypeFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <form
      className="grid grid-cols-1 gap-4"
      onSubmit={handleSubmit(wrappedOnSubmit)}
    >
      <div>
        <LabeledInput
          label="# of Units"
          inputProps={{
            type: "number",
            ...register("units", {
              required: {
                value: true,
                message: "# of Units is required",
              },
              min: {
                value: 1,
                message: "# of Units must be greater than 0",
              },
            }),
            error: errors.units?.message,
            autoFocus: true,
            step: "1",
          }}
        />
      </div>
      <div>
        <Label htmlFor="containerLabel">Unit Label</Label>
        <div className="mt-1">
          <Controller
            name="containerLabel"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Unit Label is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                options={Object.values(ContainerLabel).map((x) => ({
                  label: x,
                  value: x,
                }))}
                name={"containerLabel"}
                onChange={onChange}
                optionLabel={"label"}
                optionValue={"value"}
                value={value}
                dataKey={"value"}
                placeholder="Select a unit label"
                error={errors.containerLabel}
                className={classNames({
                  "border-red-300": errors.containerLabel,
                  "border-1": errors.containerLabel,
                  "text-red-600": errors.containerLabel,
                })}
              />
            )}
          />
          {errors.containerLabel && (
            <small className="text-sm text-red-600">
              Unit Label is required
            </small>
          )}
        </div>
      </div>
      <div>
        <LabeledInput
          label="Unit Volume"
          inputProps={{
            type: "number",
            ...register("containerVolume", {
              required: {
                value: true,
                message: "Unit Volume is required",
              },
              min: {
                value: 0.01,
                message: "Unit Volume must be greater than 0",
              },
            }),
            error: errors.containerVolume?.message,
            step: "0.01",
          }}
        />
      </div>
      <div>
        <Label htmlFor="containerVolumeUnit">Volume Unit</Label>
        <div className="mt-1">
          <Controller
            name="containerVolumeUnit"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Volume Unit is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                options={Object.values(ContainerVolumeUnit).map((x) => ({
                  label: x,
                  value: x,
                }))}
                name={"containerVolumeUnit"}
                onChange={onChange}
                optionLabel={"label"}
                optionValue={"value"}
                value={value}
                dataKey={"value"}
                placeholder="Select a volume unit"
                error={errors.containerVolumeUnit}
                className={classNames({
                  "border-red-300": errors.containerVolumeUnit,
                  "border-1": errors.containerVolumeUnit,
                  "text-red-600": errors.containerVolumeUnit,
                })}
              />
            )}
          />
          {errors.containerVolumeUnit && (
            <small className="text-sm text-red-600">
              Volume Unit is required
            </small>
          )}
        </div>
      </div>
      <div>
        <LabeledInput
          label="Container Street Name (Pony, Full Keg, etc.)"
          inputProps={{
            ...register("containerStreetName"),
            error: errors.containerStreetName?.message,
          }}
        />
      </div>
      <div>
        <LabeledInput
          label="Weight"
          inputProps={{
            type: "number",
            ...register("weight", {
              required: {
                value: true,
                message: "Weight is required",
              },
              min: {
                value: 0.01,
                message: "Weight must be greater than 0",
              },
            }),
            error: errors.weight?.message,
            step: "0.01",
          }}
        />
      </div>
      <div>
        <Button>Submit</Button>
      </div>
    </form>
  );
}
