import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useStoreSettingsQuery } from "@apollo/ops";
import { useStore } from "../StoreRouterProvider";
import { LoadingPage } from "@components/LoadingPage";
import { Page, PageHeading } from "@components/ui/Page";
import { WarningAlert } from "@components/ui";
import { Button, ButtonLink } from "@components/ui/Button";
import { onboardingPath } from "@routes";
import { MissingBankAccountAlert } from "./MissingBankAccountAlert";
import { Card, CardBody, Footer } from "@components/ui/Card/Card";
import { PlaidItemStatusAlert } from "@components/BankAccount/PlaidItem";
import { useRemoveBankAccount } from "src/business/hooks/BankAccountHooks";
import { AddAndAuthorizeBankAccountDialog } from "@components/BankAccount/AddAndAuthorizeBankAccount/AddAndAuthorizeBankAccountDialog";
import { useDialog } from "@components/ui/Dialog";
import { UpdateDwollaCustomerDialog } from "@components/UpdateDwollaCustomer/UpdateDwollaCustomerDialog";
import { NewAuthorizeOnDemandTransferDialog } from "@components/BankAccount/AuthorizeOnDemandTransfers/AuthorizeOnDemandTransfersDialog";
import { BankAccountReVerifyProvider } from "src/OrganizationApp/BankAccounts/BankAccountReVerify/BankAccountReVerifyProvider";
import { BankAccountReVerify } from "src/OrganizationApp/BankAccounts/BankAccountReVerify/BankAccountReVerify";

type StoreSettingsProps = RouteComponentProps;

export function StoreSettingsPage(_: StoreSettingsProps) {
  const { storeId } = useStore();
  const { data, loading, error } = useStoreSettingsQuery({
    variables: {
      storeId,
    },
    //We are querying every second for several reasons:
    //One: When user fails 3 times to enter the right amount of the micro-deposit, the PlaidItem goes to unverified, and
    //it doesn't provide callback for us to detect the error, and change the UI to reflect the new unverified status.
    //Two: Plaid takes time to reflect the changes, so you could fail the 3 deposits, query they api several times, and
    //the old status will be shown, maybe at the third request it shows the new status.
    //Three: We haven't implemented Plaid webhooks which could be a solution to the previous problems.
    //We (as a team) decided that is the quickest way to solve this right now, remove this if we emplement Plaid's webhooks.
    pollInterval: 3 * 1000,
  });

  const addBankAccountDialog = useDialog();
  const updateDwollaCustomerDialog = useDialog();

  const { onRemove, loading: loadingRemove } = useRemoveBankAccount({});

  if (data) {
    const { retailer } = data;
    const { bankAccount, dwollaCustomer } = retailer;

    return (
      <Page
        heading={
          <PageHeading title="Store Settings" subtitle={retailer.name} />
        }
      >
        {!retailer.completedOnboardingAt && (
          <div className="mb-5">
            <IncompleteOnboardingAlert businessId={retailer.id} />
          </div>
        )}
        <div className="grid gap-6 desktop:grid-cols-2">
          <Card title="Bank Account">
            {(!bankAccount || bankAccount.removedAt !== null) && (
              <CardBody>
                <MissingBankAccountAlert
                  businessId={retailer.id}
                  hasCompletedOnboarding={Boolean(
                    retailer.completedOnboardingAt
                  )}
                  add={addBankAccountDialog.open}
                />
              </CardBody>
            )}
            {bankAccount && bankAccount.removedAt === null && (
              <>
                <CardBody>
                  <div className="space-y-3">
                    {bankAccount.plaidItem && (
                      <PlaidItemStatusAlert plaidItem={bankAccount.plaidItem} />
                    )}
                    {!bankAccount.hasOnDemandAuthorization && (
                      <MissingOnDemandAuthorizationAlert
                        bankAccountId={bankAccount.id}
                      />
                    )}
                    {bankAccount.needsReverification && (
                      <BankAccountReVerifyProvider
                        businessEntityId={retailer.id}
                      >
                        <BankAccountReVerify bankAccountId={bankAccount.id} />
                      </BankAccountReVerifyProvider>
                    )}
                    <dl className="grid grid-cols-1 gap-4 laptop:grid-cols-2">
                      {bankAccount.name && (
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Account
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {bankAccount.name}
                          </dd>
                        </div>
                      )}
                      {bankAccount.institutionName && (
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Institution
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {bankAccount.institutionName}
                          </dd>
                        </div>
                      )}
                      <div>
                        <dt className="text-sm font-medium text-gray-500">
                          Ending in
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {bankAccount.mask}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </CardBody>
                <Footer className="flex justify-end">
                  <Button
                    destructive
                    size="sm"
                    kind="secondary"
                    onClick={() => onRemove(bankAccount)}
                    disabled={loadingRemove}
                  >
                    Remove bank account
                  </Button>
                </Footer>
              </>
            )}
          </Card>
          <div>
            {dwollaCustomer && (
              <Card title="Business Information">
                <CardBody>
                  <dl>
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dwollaCustomer.email}
                    </dd>
                  </dl>
                </CardBody>
                <Footer className="flex justify-end">
                  <Button
                    kind="secondary"
                    onClick={updateDwollaCustomerDialog.open}
                    size="sm"
                  >
                    Edit business information
                  </Button>
                  <UpdateDwollaCustomerDialog
                    dwollaCustomer={dwollaCustomer}
                    dialogProps={updateDwollaCustomerDialog.getDialogProps()}
                  />
                </Footer>
              </Card>
            )}
          </div>
        </div>
        <AddAndAuthorizeBankAccountDialog
          businessId={retailer.id}
          dialogProps={addBankAccountDialog.getDialogProps()}
        />
      </Page>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  throw error;
}

type MissingOnDemandAuthorizationAlertProps = {
  bankAccountId: number;
};

function MissingOnDemandAuthorizationAlert({
  bankAccountId,
}: MissingOnDemandAuthorizationAlertProps) {
  const dialog = useDialog();

  return (
    <>
      <WarningAlert
        title="Missing on-demand authorization"
        description="In order to use this bank account you must agree to the on-demand authorization."
      >
        <Button onClick={dialog.open} kind="secondary">
          View on-demand authorization agreement
        </Button>
      </WarningAlert>
      <NewAuthorizeOnDemandTransferDialog
        bankAccountId={bankAccountId}
        dialogProps={dialog.getDialogProps()}
      />
    </>
  );
}

type IncompleteOnboardingAlertProps = {
  businessId: number;
};

function IncompleteOnboardingAlert({
  businessId,
}: IncompleteOnboardingAlertProps) {
  return (
    <WarningAlert
      title="Incomplete onboarding"
      description="We need some additional information from you before you can make purchases for this store."
    >
      <ButtonLink to={onboardingPath(businessId)} kind="secondary">
        Complete onboarding
      </ButtonLink>
    </WarningAlert>
  );
}
