import React from "react";
import { Button } from "@ui/Button";
import { Dialog, useDialog, UseDialogResult } from "@ui/Dialog";
import { AddOfferWindowMutation, useAddOfferWindowMutation } from "@apollo/ops";
import { defaultErrorHandler } from "@utilities/toast";
import {
  AddOfferWindowForm,
  AddOfferWindowSubmitData,
} from "./AddOfferWindowForm";

type AddOfferWindowButtonProps = {
  onAdd?: (data: AddOfferWindowMutation) => void;
};

export function AddOfferWindowButton({ onAdd }: AddOfferWindowButtonProps) {
  const dialog = useDialog();

  return (
    <>
      <Button onClick={dialog.open}>Add an offer window</Button>
      <AddOfferWindowDialog dialog={dialog} onAdd={onAdd} />
    </>
  );
}

type AddOfferWindowDialogProps = {
  dialog: UseDialogResult;
  onAdd?: (data: AddOfferWindowMutation) => void;
};

export function AddOfferWindowDialog({
  dialog,
  onAdd,
}: AddOfferWindowDialogProps) {
  const { getDialogProps } = dialog;
  const [addOfferWindow] = useAddOfferWindowMutation();

  const handleSubmit = async (formData: AddOfferWindowSubmitData) => {
    try {
      const result = await addOfferWindow({
        variables: {
          data: formData,
        },
      });

      if (result.data) {
        dialog.close();
        onAdd && onAdd(result.data);
      }
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <Dialog {...getDialogProps()} title="New Offer Window" width="xl">
      <AddOfferWindowForm onSubmit={handleSubmit} />
    </Dialog>
  );
}
