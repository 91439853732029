import React, { useState } from "react";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { Button } from "@components/ui/Button";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { AdminOrderItemsDisplayTable } from "./AdminOrderItemsDisplayTable";
import { AdminOrderItemsEditTable } from "./AdminOrderItemsEditTable";
import { AdminOrderItemFragment, OrderType } from "@apollo/ops";
import { formatAmountInCents } from "@utilities/currency";
import { AdminOrderItemsTableRowData } from "./AdminOrderItemsDisplayTable";

export interface OrderItemsEditableTableProp {
  orderId: number;
  orderItems: Array<AdminOrderItemFragment>;
  displayWholesalePricing: boolean;
  className?: string;
  editable?: boolean;
  canDelete?: boolean;
  orderType?: OrderType;
  filterProducerId?: number;
}

/**
 * Component to that allows to view and edit in a tabular way the order items of an order.
 *
 * @returns
 */
function AdminOrderItemsTableCmp({
  orderId,
  orderItems,
  displayWholesalePricing,
  orderType,
  className = "",
  editable = true,
  canDelete = false,
  filterProducerId = undefined,
}: OrderItemsEditableTableProp) {
  //When editing == false, we see a read only version of the order items,
  //and when editing == true, input fields appear and allow the user to edit
  const [editing, setEditing] = useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  return (
    <>
      <SectionHeading
        title="Items"
        actions={
          editable && (
            <Button onClick={toggleEditing} size="sm">
              {editing ? "Cancel" : "Edit"}
            </Button>
          )
        }
      />
      <div className={`clear-both ${className}`}>
        {editing ? (
          <AdminOrderItemsEditTable
            orderItems={orderItems}
            orderId={orderId}
            filterProducerId={filterProducerId}
            onFinish={toggleEditing}
          />
        ) : (
          <AdminOrderItemsDisplayTable
            orderItems={mapOrderItemsToTableData({
              orderItems,
              displayWholesalePricing,
            })}
            canDelete={canDelete}
            orderType={orderType}
          />
        )}
      </div>
    </>
  );
}

export const AdminOrderItemsTable = withErrorBoundary(AdminOrderItemsTableCmp);

function mapOrderItemsToTableData({
  orderItems,
  displayWholesalePricing: useWholesalePricing,
}: {
  orderItems: Array<AdminOrderItemFragment>;
  displayWholesalePricing: boolean;
}): Array<AdminOrderItemsTableRowData> {
  return orderItems.map((orderItem) => ({
    finalQuantity: orderItem.finalQuantityDisplay,
    price: formatAmountInCents(
      useWholesalePricing ? orderItem.wholesalePrice : orderItem.price
    ),
    finalTotal: formatAmountInCents(
      useWholesalePricing ? orderItem.finalWholesaleTotal : orderItem.finalTotal
    ),
    product: orderItem.product,
    quantity: orderItem.quantity,
    total: formatAmountInCents(
      useWholesalePricing ? orderItem.wholesaleTotal : orderItem.total
    ),
    orderId: orderItem.orderId,
    hasAdjustmentItems: orderItem.hasAdjustmentItems,
  }));
}
