import React, { ElementType } from "react";
import { Link, LinkGetProps, useMatch, useNavigate } from "@reach/router";
import ctl from "@netlify/classnames-template-literals";

export type Tab = {
  name: string;
  path: string;
  icon?: ElementType;
};

export type TabsProps = {
  tabs: Tab[];
};

/**
 * Warning! The number of tabs cannot change or you'll break a rule of React
 * hooks :o
 */
export function Tabs({ tabs }: TabsProps) {
  const currentTab = tabs.find((tab) => {
    /**
     * The @reach/router doesn't have an API for dynamically checking multiple
     * match paths, so we break this hook rule to take advantage of useMatch.
     *
     * See the warning above - this means the number of tabs cannot change
     * dynamically
     */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMatch(tab.path);
  });

  const navigate = useNavigate();

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    navigate(e.target.value);
  };

  return (
    <div>
      <div className="tablet:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          className="block w-full focus:ring-brand-500 focus:border-brand-500 border-gray-300 rounded-md"
          onChange={handleSelectChange}
          defaultValue={currentTab?.path}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.path}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden tablet:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, i) => (
              <TabLink key={i} tab={tab} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

type TabLinkOptions = {
  tab: Tab;
};

function TabLink({ tab }: TabLinkOptions) {
  const isCurrent = !!useMatch(tab.path);
  const getProps = ({ isCurrent }: LinkGetProps) => ({
    className: tabClasses({ isCurrent }),
  });

  return (
    <Link
      to={tab.path}
      getProps={getProps}
      aria-current={isCurrent ? "page" : undefined}
    >
      {tab.icon && (
        <tab.icon
          className={tabIconClasses({ isCurrent })}
          aria-hidden="true"
        />
      )}
      <span>{tab.name}</span>
    </Link>
  );
}

type TabClassesOptions = {
  isCurrent: boolean;
};

const tabClasses = ({ isCurrent }: TabClassesOptions) =>
  ctl(`
    group
    inline-flex
    items-center
    py-4
    px-1
    border-b-2
    font-medium
    text-sm
    ${
      isCurrent
        ? ctl(`
        border-brand-500
        text-brand-600
      `)
        : ctl(`
        border-transparent
        text-gray-500
        hover:text-gray-700
        hover:border-gray-300
      `)
    }
  `);

type TabIconsClassesOptions = {
  isCurrent: boolean;
};

const tabIconClasses = ({ isCurrent }: TabIconsClassesOptions) =>
  ctl(`
    mr-3
    h-6
    w-6
    ${
      isCurrent
        ? "text-brand-500"
        : ctl(`
        text-gray-400
        group-hover:text-gray-500
      `)
    }
  `);
