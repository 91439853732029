import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import Client from "../prismic-config";
import Prismic from "@prismicio/client";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { SimplePage, PageHeading } from "@components/ui/Page";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "../shared/types/Severity";

export const TermsOfServicePage: FC<RouteComponentProps> = () => {
  const [termsOfService, setTermsOfService] = useState<RichTextBlock[]>([]);
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "directbeerterms")
      );

      if (response?.results[0]?.data) {
        const { tos_title, tos_content } = response.results[0].data;

        setPageTitle(tos_title[0].text);
        setTermsOfService(tos_content);
      } else {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "Oh no!",
          detail:
            "We were unable to retrieve our terms of service at this time. Try back later.",
        });
      }
    };

    fetchData();
  }, []);

  return (
    <SimplePage heading={<PageHeading title={pageTitle} />}>
      <RichText render={termsOfService} />
    </SimplePage>
  );
};
