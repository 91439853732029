import React from "react";
import { Dialog, UseDialogResult } from "@ui/Dialog";
import { Button } from "@ui/Button";

export type ConfirmDialogProps = {
  dialog: UseDialogResult;
  onConfirm?: () => void;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  destructive?: boolean;
  hideCancel?: boolean;
};

export function ConfirmDialog({
  dialog,
  onConfirm,
  title,
  description,
  confirmText = "Confirm",
  cancelText = "Cancel",
  hideCancel = false,
  destructive = false,
}: ConfirmDialogProps) {
  const { close, getDialogProps } = dialog;
  const handleConfirm = () => {
    onConfirm && onConfirm();
    close();
  };

  return (
    <Dialog {...getDialogProps()} title={title} description={description}>
      <div className="flex space-x-2">
        <Button
          kind="primary"
          onClick={handleConfirm}
          destructive={destructive}
          autoFocus
        >
          {confirmText}
        </Button>
        {hideCancel === false && (
          <Button kind="secondary" onClick={close}>
            {cancelText}
          </Button>
        )}
      </div>
    </Dialog>
  );
}
