import React, { useMemo } from "react";
import { RouteComponentProps, Router } from "@reach/router";
import {
  AdminOrderRouterQuery,
  OrderType,
  useAdminOrderRouterQuery,
} from "@apollo/ops";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { SidenavLayout } from "@ui/Layouts/SidenavLayout";
import { SecondaryNav } from "@components/SecondaryNav/SecondaryNav";
import { OrderRouteParams } from "./AdminOrderRouteProps";
import { adminOrderPath, adminPaths } from "@routes";
import { AdminOrderDocumentsPage } from "./AdminOrderDocumentsPage/AdminOrderDocumentsPage";
import { AdminOrderItemsPage } from "./AdminOrderItemsPage/AdminOrderItemsPage";
import { AdminOrderHistoryPage } from "./AdminOrderHistoryPage/AdminOrderHistoryPage";
import { AdminOrderBalanceTransfersPage } from "./AdminOrderBalanceTransfersPage/AdminOrderBalanceTransfersPage";
import { AdminOrderPaymentsPage } from "./AdminOrderPaymentsPage/AdminOrderPaymentsPage";
import { AdminOrderOverviewPage } from "./AdminOrderOverviewPage/AdminOrderOverviewPage";
import { AdminOrderRetailerOrdersPage } from "./AdminOrderRetailerOrdersPage/AdminOrderRetailerOrdersPage";
import { AdminOrderShippingPage } from "./AdminOrderShippingPage/AdminOrderShippingPage";
import { AdminOrderPlatformFeesPage } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminPlatformFeesPage/AdminOrderPlatformFeesPage";
import { AdminPreSaleOrderItemsPage } from "@admin/AdminPreSalesContainer/AdminPreSaleRouter/AdminPreSalePage/AdminPreSaleOrders/AdminPreSaleOrderItemsPage/AdminPreSaleOrderItemsPage";
import { isNil } from "lodash";

const orderPaths = adminPaths.orders;
const preSalePaths = adminPaths.preSales;

export function AdminOrderRouter({
  orderIdParam,
  preSaleIdParam,
}: RouteComponentProps<OrderRouteParams>) {
  if (orderIdParam === undefined) {
    return <RedirectToNotFound />;
  }

  const parsedOrderId = parseInt(orderIdParam);
  if (isNaN(parsedOrderId)) {
    return <RedirectToNotFound />;
  }

  const parsedPreSaleId = !isNil(preSaleIdParam)
    ? parseInt(preSaleIdParam)
    : null;

  return (
    <SanitizedAdminOrderRouter
      orderId={parsedOrderId}
      preSaleId={parsedPreSaleId}
    />
  );
}

type SanitizedAdminOrderRouterProps = {
  orderId: number;
  preSaleId?: number | null;
};

function SanitizedAdminOrderRouter({
  orderId,
  preSaleId,
}: SanitizedAdminOrderRouterProps) {
  const { data, loading } = useAdminOrderRouterQuery({
    variables: {
      orderId,
    },
  });

  /**
   * Use a relative path in the `path` property
   */
  const navItems = useMemo(
    () => [
      {
        name: "Overview",
        path: ".",
      },
      {
        name: "Items",
        path:
          data?.order.__typename === "PreSaleOrder"
            ? adminPaths.preSales.items
            : adminPaths.orders.items,
      },
      {
        name: "Shipping",
        path: "./" + orderPaths.shipping,
      },
      {
        name: "Payments",
        path: "./" + orderPaths.payments,
      },
      {
        name: "Documents",
        path: "./" + orderPaths.documents,
      },
      {
        name: "Audit History",
        path: "./" + orderPaths.history,
      },
      ...getOptionalOrderNavItems(data?.order),
    ],
    [data?.order]
  );

  return (
    <SidenavLayout
      nav={
        <SecondaryNav
          loading={loading}
          resourceType={orderTypeToResourceName(data?.order.type)}
          resourceIdentifier={"#" + data?.order.number}
          items={navItems}
        />
      }
    >
      <Router>
        <AdminOrderOverviewPage
          orderId={orderId}
          orderSummary={data?.order}
          path="/"
        />
        {data?.order.type === "retailer_pre_sale_order" ? (
          <AdminPreSaleOrderItemsPage
            orderId={orderId}
            preSaleId={preSaleId}
            orderSummary={data?.order}
            path={preSalePaths.items}
          />
        ) : (
          <AdminOrderItemsPage
            orderId={orderId}
            orderSummary={data?.order}
            path={orderPaths.items}
          />
        )}

        <AdminOrderDocumentsPage
          orderId={orderId}
          orderSummary={data?.order}
          path={orderPaths.documents}
        />
        <AdminOrderHistoryPage
          orderId={orderId}
          orderSummary={data?.order}
          path={orderPaths.history}
        />
        <AdminOrderBalanceTransfersPage
          orderId={orderId}
          orderSummary={data?.order}
          path={orderPaths.transfers}
        />
        <AdminOrderPlatformFeesPage
          orderId={orderId}
          orderSummary={data?.order}
          path={orderPaths.platformFees}
        />
        <AdminOrderPaymentsPage
          orderId={orderId}
          orderSummary={data?.order}
          path={orderPaths.payments}
        />
        <AdminOrderRetailerOrdersPage
          orderId={orderId}
          orderSummary={data?.order}
          path={orderPaths.orders}
        />
        <AdminOrderShippingPage
          orderId={orderId}
          orderSummary={data?.order}
          path={orderPaths.shipping}
        />
      </Router>
    </SidenavLayout>
  );
}

function orderTypeToResourceName(type?: OrderType) {
  switch (type) {
    case OrderType.ProducerPurchaseOrder:
      return "Brewery Purchase Order";
    case OrderType.DistributorPurchaseOrder:
      return "Distributor Purchase Order";
    case OrderType.RetailerPreSaleOrder:
      return "Pre-Sale Order";
    case OrderType.RetailerSalesOrder:
      return "Retailer Sales Order";
    default:
      return "";
  }
}

function getOptionalOrderNavItems(order?: AdminOrderRouterQuery["order"]) {
  if (!order) {
    return [];
  }
  const navItems = [];

  if (
    order.__typename !== "RetailerSalesOrder" &&
    order.__typename !== "PreSaleOrder"
  ) {
    navItems.push({
      name: "Balance Transfers",
      path: "./" + orderPaths.transfers,
    });
    if (order.__typename === "ProducerPurchaseOrder") {
      return [
        ...navItems,
        {
          name: "Platform Fees",
          path: `./${orderPaths.platformFees}`,
        },
        order.distributorPurchaseOrder
          ? {
              name: "Distributor Purchase Order",
              path: adminOrderPath(order.distributorPurchaseOrder.id),
            }
          : {
              name: "Retailer Orders",
              path: "./" + orderPaths.orders,
            },
      ];
    }

    if (order.__typename === "DistributorPurchaseOrder") {
      return [
        ...navItems,
        {
          name: "Retailer Orders",
          path: "./" + orderPaths.orders,
        },
      ];
    }
  }

  return navItems;
}
