import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import {
  LedgerStatus,
  StoreSalesOrdersDocument,
  StoreSalesOrdersQuery,
  StoreSalesOrdersQueryVariables,
  StoreSalesOrderSummaryFragment,
} from "@apollo/ops";
import { StoreRouterParams } from "../../StoreRouterParam";
import { useStore } from "../../StoreRouterProvider";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";

import { Table } from "@ui/Table";
import { CellProps, Column, TableInstance, useTable } from "react-table";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { formatAsDate } from "@utilities/formatDate";
import { formatAmountInCents } from "@utilities/currency";
import { Link } from "@components/ui/Link";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { LoadingPage } from "@components/LoadingPage";
import { DeliveryStatusBadge } from "@components/Order/DeliveryStatusBadge";
import { LedgerStatusBadge } from "@components/LedgerStatusBadge";

type StoreSettingsProps = RouteComponentProps<StoreRouterParams>;

export function StoreSalesOrdersPage(_: StoreSettingsProps) {
  const breadcrumbProps = {
    items: buildBreadcrumbs("Sales Orders"),
  };
  const { storeId } = useStore();

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    StoreSalesOrdersQuery,
    StoreSalesOrdersQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        storeId,
      },
    },
    query: StoreSalesOrdersDocument,
    mapDataToTotal({ retailer }): number {
      return retailer.salesOrders?.total ?? 0;
    },
  });

  const { data, loading, error } = queryResult;

  if (data) {
    const { retailer } = data;

    return (
      <Page
        heading={
          <PageHeading title="Sales Orders" breadcrumbProps={breadcrumbProps} />
        }
      >
        {data.retailer.salesOrders.results.length > 0 ? (
          <OrdersTable
            data={retailer.salesOrders.results}
            pagination={pagination}
          />
        ) : (
          <div>No pre-sale orders</div>
        )}
      </Page>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  throw error || new Error("Failed to load page");
}

type TableProps = {
  data: Array<StoreSalesOrderSummaryFragment>;
  pagination?: UseOffsetPaginationResult;
};

function OrdersTable({ data, pagination }: TableProps) {
  const columns: Array<Column<StoreSalesOrderSummaryFragment>> = useMemo(
    () => [
      {
        Header: "Placed on",
        Cell: ({
          value,
        }: CellProps<StoreSalesOrderSummaryFragment, "string">) =>
          formatAsDate(value),
        accessor: "placedOn",
      },
      {
        Header: "Number",
        Cell: ({
          value,
          row,
        }: CellProps<StoreSalesOrderSummaryFragment, "string">) => (
          <Link to={`./${row.original.id}`}>{value}</Link>
        ),
        accessor: "number",
      },
      {
        Header: "Producer",
        Cell: ({ row }: CellProps<StoreSalesOrderSummaryFragment, "string">) =>
          row.original.producerPurchaseOrder.seller.displayName,
        accessor: "producerPurchaseOrder",
      },
      {
        Header: "Est. Arrival @ Delivery Partner",
        Cell: ({ value }: { value: string | null }) =>
          value ? formatAsDate(value) : null,
        accessor: ({ producerPurchaseOrder }) =>
          producerPurchaseOrder.trackingInformation?.estimatedArrivalDate,
        width: 60,
      },
      {
        Header: "Delivery Status",
        Cell: DeliveryStatusCell,
      },
      {
        Header: "Total",
        Cell: ({ value }: CellProps<StoreSalesOrderSummaryFragment, number>) =>
          formatAmountInCents(value),
        accessor: "finalOrderTotalInCents",
      },
      {
        Header: "Payment Status",
        Cell: ({ value }: { value: LedgerStatus }) => (
          <LedgerStatusBadge status={value} />
        ),
        accessor: ({ paymentStatus }) => paymentStatus,
      },
    ],
    []
  );
  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<StoreSalesOrderSummaryFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}

function DeliveryStatusCell({
  row,
}: CellProps<StoreSalesOrderSummaryFragment, number>) {
  const { deliveredAt, deliveryStatus } = row.original;
  return (
    <>
      <DeliveryStatusBadge status={deliveryStatus ?? null} />
      {deliveredAt && (
        <div className="text-gray-500 mt-1.5 ml-2 text-sm">
          Delivered {formatAsDate(deliveredAt)}
        </div>
      )}
    </>
  );
}
