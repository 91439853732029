import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { AdminOfferWindowsPage } from "./AdminOfferWindowsPage/AdminOfferWindowsPage";
import { AdminOfferWindowRouter } from "./AdminOfferWindowRouter/AdminOfferWindowRouter";

export function AdminOfferWindowsRouter(_: RouteComponentProps) {
  return (
    <Router>
      <AdminOfferWindowsPage path="/" />
      <AdminOfferWindowRouter path="/:offerWindowIdParam" />
    </Router>
  );
}
