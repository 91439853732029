import React, { useState } from "react";
import { defaultErrorHandler } from "@util/toast";
import { nanoid } from "nanoid";
import { LabeledInput } from "@ui/Input";
import { Controller, useForm } from "react-hook-form";
import { AllRetailersAutocomplete } from "@components/Retailer/RetailerAutocomplete/RetailerAutocomplete";
import { Button } from "@ui/Button";
export interface CreateChildRetailerOrderData {
  number: string;
  buyerId: number;
}
interface CreateChildRetailerOrderFromProps {
  onSubmit: (data: CreateChildRetailerOrderData) => void;
  sellerId: number;
}
/**
 * Component that renders a form to create a Retailer Sales Order.
 */
export const CreateChildRetailerOrderForm = ({
  onSubmit,
  sellerId,
}: CreateChildRetailerOrderFromProps) => {
  const [number] = useState(`O${nanoid()}-${sellerId}`);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateChildRetailerOrderData>({
    defaultValues: {
      number,
    },
  });
  const wrappedOnSubmit = async (data: CreateChildRetailerOrderData) => {
    try {
      await onSubmit(data);
    } catch (e) {
      defaultErrorHandler(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <LabeledInput
        label={"Sales Order #"}
        inputProps={{
          readOnly: true,
          ...register("number"),
          value: number,
        }}
      />
      <div className="mt-5">
        <Controller
          name="buyerId"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <AllRetailersAutocomplete
              label="Select Buyer"
              onSelect={onChange}
              onBlur={onBlur}
              selectedId={value}
              error={errors.buyerId?.message}
            />
          )}
        />
      </div>
      <div className={"mt-5"}>
        <Button type="submit" disabled={isSubmitting} kind="secondary">
          Save
        </Button>
      </div>
    </form>
  );
};
