import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useForm } from "react-hook-form";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";
import { Card, CardBody } from "@components/ui/Card/Card";

export type ConfirmSignUpFormData = {
  email: string;
  code: string;
};

type ConfirmSignUpProps = {
  email?: string;
  onConfirmSignUp: (data: ConfirmSignUpFormData) => Promise<void>;
} & RouteComponentProps;

export function ConfirmSignUp({ email, onConfirmSignUp }: ConfirmSignUpProps) {
  const [error, setError] = useState<string | null>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConfirmSignUpFormData>({
    mode: "onBlur",
    defaultValues: {
      email,
    },
  });

  const onSubmit = async (data: ConfirmSignUpFormData) => {
    setError(null);

    try {
      await onConfirmSignUp(data);
      reset();
    } catch (error) {
      if (error.message) {
        setError(error.message);
      } else {
        // TODO report this error somewhere
        setError("An unknown error occurred, please reach out to support.");
      }
    }
  };

  return (
    <Card title="Verify your account">
      <CardBody>
        {email && (
          <div className="mb-6 text-green-700">
            A verification code has been sent to {email}.
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <Input
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                  })}
                  error={errors.email?.message}
                  readOnly={email !== undefined}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="code"
                className="block text-sm font-medium text-gray-700"
              >
                Verification code
              </label>
              <div className="mt-1">
                <Input
                  type="text"
                  {...register("code", {
                    required: {
                      value: true,
                      message: "Verification code is required",
                    },
                  })}
                  error={errors.code?.message}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <div>
              <Button size="lg" type="submit" disabled={false}>
                Submit
              </Button>
            </div>
          </div>
        </form>
        {error && <div className="mt-6 text-red-600">{error}</div>}
      </CardBody>
    </Card>
  );
}
