import React, { useMemo } from "react";
import { Table } from "@ui/Table";
import { Column, useTable } from "react-table";
import { ImportReport } from "@apollo/gen/generatedOperations";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { Badge } from "@ui/Badge";

interface FormattedImportReport {
  rowId: string;
  status: "success" | "fail";
}

interface ImportResultTableProps {
  data: ImportReport | null;
}
export function ImportResultTableCmp({ data }: ImportResultTableProps) {
  const formattedData: Array<FormattedImportReport> = useMemo(() => {
    if (!data) {
      return [];
    }
    const successfulRows = data.successfulRowIds || [];
    const failedRows = data.failedRowIds || [];
    return [
      ...successfulRows.map((rowId) => {
        return { rowId, status: "success" } as FormattedImportReport;
      }),
      ...failedRows.map((rowId) => {
        return { rowId, status: "fail" } as FormattedImportReport;
      }),
    ];
  }, [data?.successfulRowIds.length, data?.failedRowIds.length]);
  const columns: Array<Column<FormattedImportReport>> = useMemo(
    () => [
      {
        Header: "Row",
        accessor: "rowId",
        width: 140,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 140,
        Cell: ({ value }) => (
          <Badge label={value} color={value === "success" ? "green" : "red"} />
        ),
      },
    ],
    []
  );
  const table = useTable({ columns, data: formattedData });
  return <Table instance={table} />;
}

export const ImportResultTable = withErrorBoundary(ImportResultTableCmp);
