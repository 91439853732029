import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "@ui/Button";
import { Calendar } from "@ui";
import { defaultErrorHandler } from "@utilities/toast";

interface AdminTransactionsFilterFormData {
  date: Date;
}
type AdminOfferWindowsFilterProps = {
  onFilter: (isoDate: string) => void | Promise<void>;
  defaultValues?: Partial<AdminTransactionsFilterFormData>;
};

export function AdminTransactionsFilters({
  onFilter,
  defaultValues,
}: AdminOfferWindowsFilterProps) {
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<AdminTransactionsFilterFormData>({ defaultValues });

  const wrappedOnSubmit = async (data: AdminTransactionsFilterFormData) => {
    try {
      await onFilter(data.date.toISOString());
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)} autoComplete="off">
      <div className="grid laptop:grid-cols-2 gap-3 items-end">
        <div>
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Calendar
                value={value}
                onChange={(e) => {
                  const date = e.value as Date;
                  onChange(date);
                }}
                view="month"
                dateFormat="mm/yy"
                yearNavigator
                yearRange="2020:2030"
                className="p-column-filter"
                placeholder="Filter by date"
              />
            )}
          />
        </div>
        <div>
          <Button type="submit" disabled={isSubmitting} kind="secondary">
            Filter
          </Button>
        </div>
      </div>
    </form>
  );
}
