import React from "react";
import {
  useAdminOrderPlatformFeesQuery,
  useAdminRefreshPlatformFeeMutation,
} from "@apollo/ops";
import { FundsTransferTable } from "@components/FundsTransfer/FundsTransferTable";
import { formatAmountInCents } from "@utilities/currency";
import { Button } from "@components/ui/Button";
import { toast, toastSuccess } from "@utilities/toast";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { Page } from "@components/ui/Page";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { LoadingPage } from "@components/LoadingPage";
import { ValidationResult } from "@components/ValidationResult/ValidationResult";
import { WarningAlert } from "@components/ui/Alert";
import { AdminRegionLink } from "@admin/components/AdminRegionLink";
import { UpdatePlatformFee } from "./UpdatePlatformFee/UpdatePlatformFeeDialog";
import { MessageSeverity } from "@shared/types/Severity";
import { CollectPlatformFee } from "./CollectPlatformFee/CollectPlatformFee";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { Card, CardBody } from "@components/ui/Card/Card";
import { LedgerDetails } from "@admin/components/LedgerDetails";
import { LedgerStatusBadge } from "@components/LedgerStatusBadge";
import { AdminPlatformFeesBreakdown } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminPlatformFeesPage/AdminPlatformFeesBreakdown/AdminPlatformFeesBreakdown";

export function AdminOrderPlatformFeesPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const { data, error } = useAdminOrderPlatformFeesQuery({
    variables: {
      orderId,
    },
    pollInterval: 12 * 1000,
  });

  if (error) {
    return null;
  }

  if (data && orderSummary) {
    const { order } = data;

    if (order.__typename !== "ProducerPurchaseOrder") {
      throw new Error("Cannot view platform fees for non producer PO");
    }

    return (
      <Page
        heading={
          <AdminOrderPageHeading
            page="Platform Fees"
            order={orderSummary}
            actions={
              <>
                <RefreshPlatformFee orderId={order.id} />
                <UpdatePlatformFee order={order} />
              </>
            }
          />
        }
      >
        <div className="space-y-6">
          <div className="space-y-3">
            {order.missingPlatformFeeConfiguration && (
              <WarningAlert
                title="Missing platform fee configuration"
                description="One or more package type do not have platform fees configured in this region. The platform fee may not be accurate."
              >
                <div className="pt-1 text-sm">
                  <AdminRegionLink region={order.regionV2}>
                    Configure {order.regionV2.friendlyName} platform fees
                  </AdminRegionLink>
                </div>
              </WarningAlert>
            )}
            {order.platformFeeOverride && (
              <WarningAlert
                title="Platform fee override"
                description={`The calculated platform fee of ${formatAmountInCents(
                  order.platformFeeCalculated
                )} has been manually overridden.`}
              />
            )}
          </div>
          <div>
            {order.__typename === "ProducerPurchaseOrder" && (
              <div className="mb-8">
                <Card
                  headerProps={{
                    title: "Platform fees by package type",
                    actions: [],
                  }}
                >
                  <AdminPlatformFeesBreakdown
                    data={order.platformFeesBreakdown}
                  />
                </Card>
              </div>
            )}
            <Card
              headerProps={{
                title: "Platform fee balances",
                actions: (
                  <LedgerStatusBadge
                    status={order.platformFeeLedger.status}
                    size="lg"
                  />
                ),
              }}
            >
              <CardBody>
                <LedgerDetails ledger={order.platformFeeLedger} />
              </CardBody>
            </Card>
          </div>
          <div>
            <ValidationResult
              validationResult={order.canCollectPlatformFee}
              footer={
                <div className="flex justify-end">
                  <CollectPlatformFee orderId={order.id} />
                </div>
              }
            />
          </div>
          <div className="mt-10">
            <SectionHeading title="Transfers" />
            <FundsTransferTable fundsTransfers={order.feesPayments} />
          </div>
        </div>
      </Page>
    );
  }

  return <LoadingPage />;
}

type RefreshPlatformFeeProps = {
  orderId: number;
};

function RefreshPlatformFee({ orderId }: RefreshPlatformFeeProps) {
  const [refreshPlatformFee, { loading }] = useAdminRefreshPlatformFeeMutation({
    variables: {
      orderId,
    },
    onCompleted() {
      toastSuccess({
        summary: "Refreshed platform fee",
      });
    },
    onError() {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: "Error",
        detail: "Failed to refresh platform fee",
      });
    },
  });

  return (
    <Button
      kind="secondary"
      onClick={() => refreshPlatformFee()}
      disabled={loading}
      size="sm"
    >
      Refresh platform fee
    </Button>
  );
}
