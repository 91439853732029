import React from "react";
import { useUpdatePlatformFeeMutation } from "@apollo/ops";
import { Button } from "@ui/Button";
import { Dialog, useDialog } from "@ui/Dialog";
import {
  UpdatePlatformFeeForm,
  UpdatePlatformFeeSubmitData,
} from "./UpdatePlatformFeeForm";

type UpdateOrderDialogProps = {
  order: {
    id: number;
    platformFee: number;
    platformFeeCalculated: number;
    platformFeeOverride: number | null;
  };
};

export function UpdatePlatformFee({ order }: UpdateOrderDialogProps) {
  const { open, close, getDialogProps } = useDialog();
  const [updatePlatformFee] = useUpdatePlatformFeeMutation();

  const handleSubmit = async (formData: UpdatePlatformFeeSubmitData) => {
    const result = await updatePlatformFee({
      variables: {
        id: order.id,
        platformFeeOverride: formData.platformFeeOverride,
      },
    });

    if (result.data) {
      close();
    }
  };

  return (
    <>
      <Button kind="secondary" onClick={open} size="sm">
        Edit platform fee
      </Button>
      <Dialog {...getDialogProps()} title="Update Platform Fee">
        <UpdatePlatformFeeForm
          onSubmit={handleSubmit}
          defaultValues={{ platformFeeOverride: order.platformFeeOverride }}
          platformFeeCalculated={order.platformFeeCalculated}
          platformFeeOverride={order.platformFeeOverride}
        />
      </Dialog>
    </>
  );
}
