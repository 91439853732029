import React from "react";
import { FormHookResult } from "@hooks/useFormHook";
import SpinnerOverlay from "@components/SpinnerOverlay/SpinnerOverlay";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import FormFooter from "@components/FormFooter";

export interface InviteGlobalAdminFormViewProps extends FormHookResult {
  onCancel: () => any;
}
export const InviteGlobalAdminFormView = ({
  onChange,
  formValues,
  loading,
  validationResults,
  onSubmit,
  onCancel,
}: InviteGlobalAdminFormViewProps) => {
  return (
    <SpinnerOverlay spin={loading}>
      <div className="p-field">
        <label htmlFor="fullName">Full Name</label>
        <InputText
          id="fullName"
          name={"fullName"}
          value={formValues?.fullName}
          onChange={onChange}
          required
          autoFocus
          className={classNames({
            "p-invalid": validationResults?.fullName,
          })}
        />
        {validationResults?.fullName && (
          <small className="p-invalid">Full Name is required.</small>
        )}
      </div>

      <div className="p-field">
        <label htmlFor="email">Email</label>
        <InputText
          id="email"
          name={"email"}
          value={formValues?.email}
          onChange={onChange}
          required
          autoFocus
          className={classNames({
            "p-invalid": validationResults?.email,
          })}
        />
        {validationResults?.email && (
          <small className="p-invalid">A valid email is required.</small>
        )}
      </div>
      <FormFooter onSubmit={onSubmit} onCancel={onCancel} />
    </SpinnerOverlay>
  );
};
