import {
  usePlaidLinkToken,
  withPlaidLinkToken,
  WithPlaidLinkTokenInjectedProps,
} from "@components/plaid/withPlaidLinkToken";
import { BankAccountReVerifyContext } from "./BankAccountReVerifyContext";
import React, { FC, useState } from "react";
import { useRemoveBankAccountMutation } from "@apollo/gen/generatedOperations";
import { useAddBankAccountDialog } from "../../OnboardingPage/BankAccountStep/useAddBankAccountDialog";
import { defaultErrorHandler } from "@util/toast";
import { NewAuthorizeOnDemandTransferDialog } from "@components/BankAccount/AuthorizeOnDemandTransfers/AuthorizeOnDemandTransfersDialog";
import { useDialog } from "@ui/Dialog";
export interface BankAccountReVerifyProviderProps {
  businessEntityId: number;
  children: React.ReactNode;
}
type BankAccountReVerifyProviderCmpProps = BankAccountReVerifyProviderProps &
  WithPlaidLinkTokenInjectedProps;
/**
 * This component is meant to be placed in a place where is never unmounted and it wraps it's children with a
 * BankAccountReVerifyContext. BankAccountReVerify component needs this component as one of it's ancestor.
 *
 */
const BankAccountReVerifyProviderCmp: FC<BankAccountReVerifyProviderCmpProps> = ({
  businessEntityId,
  plaidLinkToken,
  children,
}) => {
  const [
    removeBankAccount,
    { loading: removingBankAccount },
  ] = useRemoveBankAccountMutation();
  const [bankAccountId, setBankAccountId] = useState<number | null>(null);
  const authorizeOnDemandTransfersDialog = useDialog();

  const { addBankAccount, ready, result } = useAddBankAccountDialog({
    businessId: businessEntityId,
    plaidLinkToken,
    onBankAccountAdded: (bankAccountId) => {
      setBankAccountId(bankAccountId);
      authorizeOnDemandTransfersDialog.open();
    },
  });

  const onReverify = async (bankAccountId: number) => {
    try {
      setBankAccountId(null);
      await removeBankAccount({
        variables: {
          id: bankAccountId,
          force: false,
        },
      });
      addBankAccount();
    } catch (e) {
      defaultErrorHandler(e);
    }
  };

  return (
    <>
      <BankAccountReVerifyContext.Provider
        value={{
          reverifyBankAccount: onReverify,
          removingBankAccount,
          plaidDialogReady: ready,
          loading: result.loading,
        }}
      >
        {children}
      </BankAccountReVerifyContext.Provider>
      {bankAccountId && (
        <NewAuthorizeOnDemandTransferDialog
          bankAccountId={bankAccountId}
          dialogProps={authorizeOnDemandTransfersDialog.getDialogProps()}
        />
      )}
    </>
  );
};

export const BankAccountReVerifyProvider = withPlaidLinkToken<BankAccountReVerifyProviderProps>(
  {
    useHook: usePlaidLinkToken,
  }
)(BankAccountReVerifyProviderCmp);
