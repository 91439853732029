import React from "react";
import {
  DeliveryStatus,
  FreightShipmentStatus,
  LedgerStatus,
  ProducerInvoiceStatus,
} from "@apollo/ops";
import { LedgerStatusBadge } from "@components/LedgerStatusBadge";
import { FreightShipmentStatusBadge } from "@components/TrackingInformation/FreightShipmentStatusBadge";
import { DeliveryStatusBadge } from "@components/Order/DeliveryStatusBadge";
import { CancelledStatusBadge } from "@components/Order/CancelledStatusBadge";
import { ProducerInvoiceStatusBadge } from "./ProducerInvoiceStatusBadge";

type ProducerOrderStatusTable = {
  order: {
    cancelledAt: Date | null;
    producerInvoiceStatus: ProducerInvoiceStatus;
    trackingInformation: {
      status: FreightShipmentStatus | null;
    } | null;
    deliveryStatus: DeliveryStatus;
    balanceTransferStatus: LedgerStatus;
    paymentStatus: LedgerStatus;
    feeStatus: LedgerStatus;
    distributorPurchaseOrder: null | {
      balanceTransferStatus: LedgerStatus;
      paymentStatus: LedgerStatus;
    };
  };
};

const termClasses = "text-sm pr-2 pt-1 text-gray-500 font-medium w-36";

export function ProducerOrderStatusTable({ order }: ProducerOrderStatusTable) {
  return (
    <table>
      <tbody>
        {order.cancelledAt ? (
          <tr>
            <td className={termClasses}>Status</td>
            <td className="pt-1">
              <CancelledStatusBadge cancelledAt={order.cancelledAt} />
            </td>
          </tr>
        ) : (
          <>
            <tr>
              <td className={termClasses}>Brewery invoice</td>
              <td className="pt-1">
                <ProducerInvoiceStatusBadge
                  status={order.producerInvoiceStatus}
                />
              </td>
            </tr>
            <tr>
              <td className={termClasses}>Freight</td>
              <td className="pt-1">
                <FreightShipmentStatusBadge
                  status={order.trackingInformation?.status ?? null}
                />
              </td>
            </tr>
            <tr>
              <td className={termClasses}>Delivery</td>
              <td className="pt-1">
                <DeliveryStatusBadge status={order.deliveryStatus} />
              </td>
            </tr>
            {order.distributorPurchaseOrder && (
              <>
                <tr>
                  <td className={termClasses}>Distributor payment</td>
                  <td className="pt-1">
                    <LedgerStatusBadge
                      status={order.distributorPurchaseOrder.paymentStatus}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={termClasses}>Distributor deposit</td>
                  <td className="pt-1">
                    <LedgerStatusBadge
                      status={
                        order.distributorPurchaseOrder.balanceTransferStatus
                      }
                    />
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td className={termClasses}>Brewery payment</td>
              <td className="pt-1">
                <td>
                  <LedgerStatusBadge status={order.paymentStatus} />
                </td>
              </td>
            </tr>
            <tr>
              <td className={termClasses}>Brewery deposit</td>
              <td className="pt-1">
                <LedgerStatusBadge status={order.balanceTransferStatus} />
              </td>
            </tr>
            <tr>
              <td className={termClasses}>Platform fee</td>
              <td className="pt-1">
                <LedgerStatusBadge status={order.feeStatus} />
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
}
