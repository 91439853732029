import React from "react";
import { Switch } from "@headlessui/react";
import ctl from "@netlify/classnames-template-literals";

type ToggleProps = {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
};

export function Toggle({ enabled, onChange, disabled = false }: ToggleProps) {
  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      disabled={disabled}
      className={switchClassnames({ enabled })}
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className={spanClassnames({ enabled })} />
    </Switch>
  );
}

type SwitchClassnameOptions = {
  enabled: boolean;
};

const switchClassnames = ({ enabled }: SwitchClassnameOptions) =>
  ctl(`
  ${enabled ? "bg-brand-600" : "bg-gray-200"}
  relative
  inline-flex
  flex-shrink-0
  h-6 w-11
  border-2 border-transparent
  rounded-full
  cursor-pointer
  transition-colors ease-in-out duration-200
  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
`);

const spanClassnames = ({ enabled }: SwitchClassnameOptions) =>
  ctl(`
  ${enabled ? "translate-x-5" : "translate-x-0"}
  pointer-events-none
  inline-block
  h-5 w-5
  rounded-full
  bg-white
  shadow
  transform
  ring-0
  transition ease-in-out duration-200'
`);
