import React from "react";
import {
  ProducerPurchaseOrderItemFragment,
  ProducerPurchaseOrderOverviewQuery,
  useProducerPurchaseOrderItemsQuery,
} from "@apollo/ops";
import { LoadingPage } from "@components/LoadingPage";
import {
  ProducerPurchaseOrderItemsDisplayTable,
  ProducerPurchaseOrderItemsTableRowData,
} from "./ProducerPurchaseOrderItemsDisplayTable";
import { formatAmountInCents } from "@util/currency";
import { RouteComponentProps } from "@reach/router";
import { ProducerPurchaseOrderPageHeading } from "../ProducerPurchaseOrderPageHeading";
import { Page } from "@ui/Page";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";
import { ProducerPurchaseOrderItemsTotals } from "./ProducerPurchaseOrderItemsTotals";

interface ProducerPurchaseOrderItemsProps extends RouteComponentProps {
  orderId: number;
  orderSummary: ProducerPurchaseOrderOverviewQuery["order"];
}

export function ProducerPurchaseOrderItemsPage({
  orderId,
  orderSummary,
}: ProducerPurchaseOrderItemsProps) {
  const { data, error } = useProducerPurchaseOrderItemsQuery({
    variables: {
      orderId: orderId,
    },
  });

  if (error) {
    return null;
  }

  const breadCrumbs = {
    items: buildBreadcrumbs("Purchase Orders", `#${orderSummary.number}`),
  };

  if (data) {
    const { order } = data;
    const { items } = order;

    return (
      <Page
        heading={
          <ProducerPurchaseOrderPageHeading
            page="Shipping"
            order={orderSummary}
            breadCrumbs={breadCrumbs}
          />
        }
      >
        <ProducerPurchaseOrderItemsDisplayTable
          orderItems={mapOrderItemsToTableData({ orderItems: items })}
        />
        <div className="mt-8 flex justify-end px-6">
          <ProducerPurchaseOrderItemsTotals order={order} />
        </div>
      </Page>
    );
  }

  return <LoadingPage />;
}

function mapOrderItemsToTableData({
  orderItems,
}: {
  orderItems: Array<ProducerPurchaseOrderItemFragment>;
}): Array<ProducerPurchaseOrderItemsTableRowData> {
  return orderItems.map((orderItem) => ({
    price: formatAmountInCents(orderItem.price),
    product: orderItem.product,
    quantity: orderItem.quantity,
    total: formatAmountInCents(orderItem.total),
    orderId: orderItem.orderId,
  }));
}
