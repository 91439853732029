import React from "react";
import { getUserFriendlyDwollaStatusMessages } from "@components/dwolla/dwolla";
import {
  BusinessType,
  DwollaCustomerType,
  OnboardingCompleteStepFieldsFragment,
  PlaidVerificationStatus,
} from "@apollo/ops";
import FinancialAccountStatus from "../FinancialAccountStatus/FinancialAccountStatus";
import { Button } from "@components/ui/Button";
import { Dialog, useDialog } from "@components/ui/Dialog";
import { Well } from "@ui/Well";
import { Welcome } from "../../Welcome";
import { PlaidItemStatusAlert } from "@components/BankAccount/PlaidItem";
import { IconTag } from "@components/IconTag";
import { Severity } from "@shared/types/Severity";
import { BeneficialOwnerSummary } from "./BeneficialOwnerSummary";
import { useRemoveBankAccount } from "../../../business/hooks/BankAccountHooks";
import { AddStoreDialog } from "@components/Store/AddStoreDialog";

interface CompleteOnboardingStepProps {
  step: OnboardingCompleteStepFieldsFragment;
  businessEntityId: number;
  organizationId: number;
  businessType: BusinessType;
  refetchOnboarding: () => void;
}

export function CompleteStep({
  step,
  businessEntityId,
  organizationId,
  refetchOnboarding,
  businessType,
}: CompleteOnboardingStepProps) {
  const {
    completeStepBankAccount: bankAccount,
    canCompleteOnboarding,
    dwollaCustomer,
    dwollaCustomerType,
  } = step;

  const financialStatusProps = getUserFriendlyDwollaStatusMessages(
    dwollaCustomer.status
  );

  const { onRemove } = useRemoveBankAccount({});

  const retryBankAccountStep = () => {
    onRemove(bankAccount, true, refetchOnboarding);
  };

  const welcomeDialog = useDialog();

  return (
    <div>
      <div className="text-3xl text-center">
        {canCompleteOnboarding
          ? "Your account is set up!"
          : "You're almost there!"}
      </div>
      <div className="mt-8 mx-auto max-w-3xl space-y-8">
        <dl>
          <div className="py-4 tablet:py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4">
            <dt className="font-medium text-gray-500">Business Identity</dt>
            <dd className="mt-1 text-gray-500 tablet:mt-0 tablet:col-span-2">
              {dwollaCustomerType === DwollaCustomerType.Unverified ? (
                <IconTag severity={Severity.success} value="Verified" />
              ) : (
                <FinancialAccountStatus
                  message={financialStatusProps.message}
                  messageSeverity={financialStatusProps.messageSeverity}
                  status={financialStatusProps.status}
                  statusSeverity={financialStatusProps.severity}
                />
              )}
            </dd>
          </div>
          <BeneficialOwnerSummary
            dwollaCustomer={dwollaCustomer}
            businessId={businessEntityId}
          />
          <div className="py-4 tablet:py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4">
            <dt className="font-medium text-gray-500">Bank Account</dt>
            <dd className="mt-1 text-gray-500 tablet:mt-0 tablet:col-span-2">
              {bankAccount.plaidItem && (
                <div className="space-y-3">
                  <PlaidItemStatusAlert plaidItem={bankAccount.plaidItem} />
                  <div>
                    {bankAccount.name} {bankAccount.mask}
                  </div>
                  {bankAccount.plaidItem.verificationStatus ===
                    PlaidVerificationStatus.VerificationFailed && (
                    <div>
                      <Button onClick={retryBankAccountStep} size="xl">
                        Retry Bank Verification
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </dd>
          </div>
          <div className="mt-5 py-4 tablet:py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4">
            <dt className="font-medium text-gray-500"></dt>
            <dd className="mt-1 text-gray-500 tablet:mt-0 tablet:col-span-2">
              <div className="space-y-3">
                <div>
                  <Button
                    onClick={welcomeDialog.open}
                    size="xl"
                    disabled={!canCompleteOnboarding}
                  >
                    Complete Onboarding
                  </Button>
                </div>
                {!canCompleteOnboarding && (
                  <div className="text-gray-500 text-sm">
                    Finish the items above to complete onboarding.
                  </div>
                )}
              </div>
            </dd>
          </div>
          <div className="mt-5 py-4 tablet:py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4">
            <dt className="font-medium text-gray-500"></dt>
            <dd className="mt-1 text-gray-500 tablet:mt-0 tablet:col-span-2">
              {!canCompleteOnboarding &&
                businessType === BusinessType.Retailer && (
                  <Well>
                    <div className="text-gray-900">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Have another location?
                      </h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-500">
                        Start another onboarding while you wait to complete this
                        one.
                      </div>
                      <div className="mt-5">
                        <AddStoreDialog
                          organizationId={organizationId}
                          render={({ open }) => (
                            <Button kind="secondary" onClick={open}>
                              Add another location
                            </Button>
                          )}
                        />
                      </div>
                    </div>
                  </Well>
                )}
            </dd>
          </div>
        </dl>
      </div>

      <Dialog {...welcomeDialog.getDialogProps()} width="lg" noBody>
        <Welcome businessEntityId={businessEntityId} />
      </Dialog>
    </div>
  );
}
