import React from "react";
import {
  AutocompleteProducerFragment,
  useAutocompleteProducersQuery,
} from "@apollo/ops";
import { Autocomplete, buildMatchSorterFilter } from "@ui/Autocomplete";

export type AllProducerAutocompleteProps = {
  onSelect: (producerId: number | null) => void;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
  disabled?: boolean;
};

export function AllProducersAutocomplete(props: AllProducerAutocompleteProps) {
  const { data } = useAutocompleteProducersQuery();

  if (data) {
    return <ProducersAutocomplete {...props} producers={data.producers} />;
  }

  return null;
}

export type ProducersAutocompleteProps = {
  onSelect: (producerId: number | null) => void;
  producers: Array<AutocompleteProducerFragment>;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
  disabled?: boolean;
};

export function ProducersAutocomplete({
  label,
  error,
  selectedId,
  producers,
  onSelect,
  onBlur,
  disabled,
}: ProducersAutocompleteProps) {
  const handleSelect = (
    selectedProducer?: AutocompleteProducerFragment | null
  ) => {
    const id = selectedProducer ? selectedProducer.id : null;
    onSelect(id);
  };

  const selectedProducer =
    producers.find((item) => item.id === selectedId) ?? null;

  return (
    <Autocomplete
      label={label}
      error={error}
      hideToggleButton={true}
      placeholder="Search producers"
      onSelect={handleSelect}
      disabled={disabled}
      onClickDelete={() => handleSelect(null)}
      onBlur={onBlur}
      items={producers}
      selectedItem={selectedProducer}
      itemToString={producerToString}
      filterItems={filterProducers}
    />
  );
}

const filterProducers = buildMatchSorterFilter<AutocompleteProducerFragment>({
  keys: [(producer) => producer.displayName],
});

const producerToString = (
  producer: AutocompleteProducerFragment | null
): string => (producer ? producer.displayName : "");
