import React from "react";
import { Dialog, UseDialogResult } from "@ui/Dialog";
import { useAddOrderDocumentMutation } from "@apollo/ops";
import {
  OrderDocumentForm,
  OrderDocumentSubmitData,
} from "@components/OrderDocument/OrderDocumentForm";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";

type AddOrderDocumentDialogProps = {
  orderId: number;
  dialog: UseDialogResult;
};

export function AddOrderDocumentDialog({
  orderId,
  dialog,
}: AddOrderDocumentDialogProps) {
  const { getDialogProps } = dialog;
  const [addOrderDocument] = useAddOrderDocumentMutation();

  const handleSubmit = async (formData: OrderDocumentSubmitData) => {
    try {
      const result = await addOrderDocument({
        variables: {
          data: {
            ...formData,
            orderId,
          },
        },
      });

      if (result.data) {
        dialog.close();
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <Dialog {...getDialogProps()} title="New Document">
      <OrderDocumentForm onSubmit={handleSubmit} />
    </Dialog>
  );
}
