import { RouteComponentProps } from "@reach/router";
import React from "react";
import { Page, PageHeading } from "@components/ui/Page";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";
import { AdminPreSaleProducerSummaryTable } from "@admin/AdminPreSalesContainer/AdminPreSaleRouter/AdminPreSaleProducersPage/AdminPreSaleProducerSummaryTable";

type AdminPreSaleProducersPageProps = RouteComponentProps<{
  preSaleIdParam: string;
}>;

/**
 *
 * Component to show the Producers' package types and quantities bought in a PreSale.
 */
export const AdminPreSaleProducersPage = ({
  preSaleIdParam,
}: AdminPreSaleProducersPageProps) => {
  const preSaleId = Number(preSaleIdParam);

  const breadcrumbProps = {
    items: buildBreadcrumbs(
      "Pre-Sales",
      `Pre-Sale #${preSaleId}`,
      "Producer Summary"
    ),
  };

  return (
    <div>
      <Page
        heading={
          <PageHeading
            title={"Producer Summary"}
            breadcrumbProps={breadcrumbProps}
          />
        }
      >
        <AdminPreSaleProducerSummaryTable preSaleId={preSaleId} />
      </Page>
    </div>
  );
};
