import { DeliveryPartnerCard } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderShippingPage/DeliveryPartnerCard";
import { OrderDocumentActionPanel } from "@admin/components/OrderDocumentActionPanel/OrderDocumentActionPanel";
import {
  DocumentType,
  useAdminOrderShippingQuery,
  useCreateKuebixShipmentMutation,
} from "@apollo/ops";
import { LoadingPage } from "@components/LoadingPage";
import { Card } from "@components/ui/Card/Card";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { DIRECT_BEER_API_URI } from "@config";
import { Button } from "@ui/Button";
import { Page } from "@ui/Page";
import { onExportFileClick } from "@util/files";
import { toastError, toastSuccess } from "@utilities/toast";
import React, { useState } from "react";
import { AddTrackingInformation } from "../../TrackingInformation/AddTrackingInformation";
import { AddTrackingInformationModal } from "../../TrackingInformation/AddTrackingInformationModal";
import { ShowTrackingInformation } from "../../TrackingInformation/ShowTrackingInformation";
import { UpdateTrackingInformationModal } from "../../TrackingInformation/UpdateTrackingInformationModal";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { AdminPackageSummaryTable } from "./AdminPackageSummaryTable/AdminPackageSummaryTable";
import { ShippingAddressCard } from "./ShippingAddressCard";

export function AdminOrderShippingPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const [
    isAddTrackingInformationModalOpen,
    setIsAddTrackingInformationModalOpen,
  ] = useState(false);

  const [
    isUpdateTrackingInformationModalOpen,
    setIsUpdateTrackingInformationModalOpen,
  ] = useState(false);

  const { data, refetch } = useAdminOrderShippingQuery({
    variables: {
      orderId,
    },
  });

  const [createShipment] = useCreateKuebixShipmentMutation({
    variables: {
      orderId,
    },
    onError(error) {
      toastError(error);
    },
    onCompleted(data) {
      toastSuccess({
        summary: `Shipment ${data.createKuebixShipment.trackingInformation?.kuebixShipmentId} has been created in kuebix`,
      });
      refetch();
    },
  });

  if (data && orderSummary) {
    const { order } = data;
    const { trackingInformation, shippingAddress, seller, buyer } = order;

    // Display "primary" email for the organization pulled from their first user
    const sellerEmail = seller.organization.email;
    const buyerEmail = buyer.organization.email;

    const openAddTrackingInformationModal = () => {
      setIsAddTrackingInformationModalOpen(true);
    };

    const openUpdateTrackingInformationModal = () => {
      setIsUpdateTrackingInformationModalOpen(true);
    };

    const onCloseAddTrackingModal = async () => {
      setIsAddTrackingInformationModalOpen(false);
    };

    const onCloseUpdateTrackingModal = () => {
      setIsUpdateTrackingInformationModalOpen(false);
    };

    const onGenerateOrderPdfClick = async (isBundle = false) => {
      const pdfUrl = `${DIRECT_BEER_API_URI}order/${orderId}/pdf${
        isBundle ? "?bundle=true" : ""
      }`;
      const fileName = `order-${order.number}.pdf`;
      onExportFileClick({ url: pdfUrl, fileName });
    };

    const onGenerateLabelsPdfClick = () => {
      const pdfUrl = `${DIRECT_BEER_API_URI}order/${orderId}/pdf?labels=true`;
      const fileName = `${data.order.number}-Labels.pdf`;

      onExportFileClick({ url: pdfUrl, fileName });
    };

    const shipmentTrackingHeaderProps = {
      title: "Shipment Tracking",
      actions: trackingInformation ? (
        <Button onClick={openUpdateTrackingInformationModal} kind="tertiary">
          Edit tracking
        </Button>
      ) : null,
    };

    return (
      <div key={order.id}>
        <Page
          heading={
            <AdminOrderPageHeading
              page="Shipping"
              order={orderSummary}
              actions={
                <>
                  {order.__typename === "ProducerPurchaseOrder" &&
                    !trackingInformation?.kuebixShipmentId && (
                      <Button onClick={() => createShipment()} size="sm">
                        Create Kuebix Shipment
                      </Button>
                    )}
                  {order.__typename === "ProducerPurchaseOrder" && (
                    <Button
                      onClick={() => onGenerateLabelsPdfClick()}
                      size="sm"
                    >
                      Download Labels
                    </Button>
                  )}
                  {order.enabledFeatures.canCreatePDF && (
                    <Button onClick={() => onGenerateOrderPdfClick()} size="sm">
                      Download PDF
                    </Button>
                  )}
                </>
              }
            />
          }
        >
          <div className="space-y-5">
            <div>
              <DeliveryPartnerCard
                region={orderSummary.regionV2}
                deliveryPartner={orderSummary.regionV2.deliveryPartner}
              />
            </div>
            <div className="grid laptop:grid-cols-2 gap-5">
              <div>
                <ShippingAddressCard
                  shippingAddress={seller.address}
                  organizationEmail={sellerEmail}
                  orderId={order.id}
                  disabled={true}
                  cardTitle={"Origin Address"}
                />
              </div>
              <div>
                <ShippingAddressCard
                  shippingAddress={shippingAddress}
                  organizationEmail={buyerEmail}
                  orderId={order.id}
                  cardTitle={"Destination Address"}
                />
              </div>
            </div>

            <div className="grid laptop:grid-cols-2 gap-5">
              <div>
                <Card headerProps={shipmentTrackingHeaderProps}>
                  {trackingInformation ? (
                    <ShowTrackingInformation
                      trackingInformation={trackingInformation}
                    />
                  ) : (
                    <div>
                      <AddTrackingInformation
                        onShippingInformationClick={
                          openAddTrackingInformationModal
                        }
                      />
                    </div>
                  )}
                </Card>
              </div>
              <div>
                <OrderDocumentActionPanel
                  title={"Bill of Lading"}
                  orderId={orderId}
                  documentType={DocumentType.BillOfLading}
                  uploadName={"Bill of Lading"}
                />
              </div>
            </div>
          </div>
          <div className="mt-8">
            <SectionHeading title="Package Summary" />
            <AdminPackageSummaryTable packageSummary={order.packageSummary} />
          </div>
        </Page>
        {trackingInformation ? (
          <UpdateTrackingInformationModal
            trackingInformation={trackingInformation}
            onClose={onCloseUpdateTrackingModal}
            isOpen={isUpdateTrackingInformationModalOpen}
          />
        ) : (
          <AddTrackingInformationModal
            orderId={orderId}
            onClose={onCloseAddTrackingModal}
            isOpen={isAddTrackingInformationModalOpen}
          />
        )}
      </div>
    );
  }

  return <LoadingPage />;
}
