import React, { ReactElement } from "react";
import { useNavigate } from "@reach/router";
import { onboardingPath } from "@routes";
import { AddStoreForm, OnAddHandler } from "./AddLocation/AddStoreForm";
import { Dialog, useDialog } from "@components/ui/Dialog";

type AddStoreRenderProps = {
  open: () => void;
};

type AddStoreProps = {
  // Retailer's Organization ID
  organizationId: number;
  render: (props: AddStoreRenderProps) => ReactElement;
  title?: string;
};

export function AddStoreDialog({
  organizationId,
  title = "Add a store",
  render,
}: AddStoreProps) {
  const { open, getDialogProps } = useDialog();
  const navigate = useNavigate();

  const handleAdd: OnAddHandler = (data) => {
    return navigate(onboardingPath(data.addLocation.id));
  };

  return (
    <>
      {render({ open })}
      <Dialog {...getDialogProps()} title={title}>
        <AddStoreForm organizationId={organizationId} onAdd={handleAdd} />
      </Dialog>
    </>
  );
}
