import React from "react";
import {
  useSendRetailerPaymentDataQuery,
  useSendRetailerPaymentMutation,
} from "@apollo/ops";
import { LoadingIcon } from "@components/ui/Spinner";
import { ConfirmTransfer } from "@admin/components/ConfirmTransfer";

type SendRetailerPaymentProps = {
  orderId: number;
};

export function SendRetailerPayment({ orderId }: SendRetailerPaymentProps) {
  const { data, loading, error } = useSendRetailerPaymentDataQuery({
    variables: {
      orderId,
    },
  });

  const [sendPayment] = useSendRetailerPaymentMutation({
    variables: {
      orderId,
    },
  });

  if (loading && !data) {
    return <LoadingIcon />;
  }

  if (data) {
    const { order } = data;

    if (order.__typename === "RetailerSalesOrder") {
      return (
        <ConfirmTransfer
          ledger={order.paymentsLedger}
          transferProposal={order.sendPaymentProposal}
          validationResult={order.canSendPayment}
          label="Send payment"
          onSubmit={sendPayment}
        />
      );
    } else {
      throw Error(`Can use SendRetailerPayment with ${order.__typename} order`);
    }
  }

  if (error) {
    return <div>Failed to load payment data.</div>;
  }

  return null;
}
