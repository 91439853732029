import React from "react";
import ReactDOM from "react-dom";
import AppWrapper from "./AppWrapper";
import { LocationProvider } from "@reach/router";
import { configure } from "./amplify";
import { toast } from "./utilities/toast";
import { Toast } from "primereact/toast";

import "@aws-amplify/ui/dist/style.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./App.scss";
import "./index.css";

// Configure Amplify.Auth
configure();

ReactDOM.render(
  <LocationProvider>
    <AppWrapper />
    <Toast ref={toast} baseZIndex={999997} position="top-right" />
  </LocationProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
