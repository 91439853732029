import {
  RetailerSalesOrderDeliveryAgingReportResultFragment,
  ServiceLevelTarget,
} from "@apollo/ops";
import { AdminOrderLink } from "@components/Order/Order";
import { AdminProducerLink } from "@components/Producer/Producer";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { Table } from "@ui/Table";
import { formatAsDate } from "@utilities/formatDate";
import React, { useMemo } from "react";
import { CellProps, Column, TableInstance, useTable } from "react-table";
import {
  DeliveryAge,
  DeliveryAgeFromFreight,
} from "@components/ProducerPurchaseOrder/DeliveryAge";
import { AdminRetailerLink } from "@components/Retailer/Retailer";

type BuildColumnsOptions = {
  deliverySLT: ServiceLevelTarget;
  deliveryStepSLT: ServiceLevelTarget;
};

export const buildColumns = ({
  deliverySLT,
  deliveryStepSLT,
}: BuildColumnsOptions): Array<
  Column<RetailerSalesOrderDeliveryAgingReportResultFragment>
> => [
  {
    Header: "Placed on",
    Cell: ({
      value,
    }: CellProps<
      RetailerSalesOrderDeliveryAgingReportResultFragment,
      "string"
    >) => formatAsDate(new Date(value)),
    accessor: "placedOn",
    width: 50,
  },
  {
    Header: "Order",
    Cell: ({
      row,
    }: CellProps<
      RetailerSalesOrderDeliveryAgingReportResultFragment,
      "string"
    >) => (
      <>
        <AdminOrderLink order={row.original} />
        <div className="text-sm text-muted">
          {row.original.regionV2.friendlyName}
        </div>
      </>
    ),
    accessor: "number",
    id: "number",
    width: 120,
  },
  {
    Header: "Brewery",
    Cell: ({
      row,
    }: CellProps<RetailerSalesOrderDeliveryAgingReportResultFragment>) => (
      <AdminProducerLink producer={row.original.producerPurchaseOrder.seller} />
    ),
    accessor: (order) => order.producerPurchaseOrder.seller.displayName,
    id: "brewery",
  },
  {
    Header: "Retailer",
    Cell: ({
      row,
    }: CellProps<RetailerSalesOrderDeliveryAgingReportResultFragment>) => (
      <AdminRetailerLink retailer={row.original.buyer} />
    ),
    accessor: (order) => order.buyer.displayName,
    id: "retailer",
  },
  {
    Header: "Age from freight",
    Cell: ({
      row,
    }: CellProps<
      RetailerSalesOrderDeliveryAgingReportResultFragment,
      number
    >) => (
      <div className="w-20">
        <DeliveryAgeFromFreight
          freightDeliveredAt={
            row.original.producerPurchaseOrder.trackingInformation
              ?.deliveredAt ?? null
          }
          serviceLevelTarget={deliveryStepSLT}
        />
      </div>
    ),
    id: "ageFromFreight",
    width: 90,
  },
  {
    Header: "Age",
    Cell: ({
      row,
    }: CellProps<
      RetailerSalesOrderDeliveryAgingReportResultFragment,
      number
    >) => (
      <div className="w-20">
        <DeliveryAge
          placedOn={row.original.placedOn}
          serviceLevelTarget={deliverySLT}
        />
      </div>
    ),
    id: "age",
    width: 90,
  },
];

type DeliveryAgingReportTableProps = {
  data: Array<RetailerSalesOrderDeliveryAgingReportResultFragment>;
  pagination?: UseOffsetPaginationResult;
  deliverySLT: ServiceLevelTarget;
  deliveryStepSLT: ServiceLevelTarget;
};

export function DeliveryAgingReportTable({
  data,
  pagination,
  deliverySLT,
  deliveryStepSLT,
}: DeliveryAgingReportTableProps) {
  const columns = useMemo(
    () => buildColumns({ deliverySLT, deliveryStepSLT }),
    [deliverySLT, deliveryStepSLT]
  );

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<RetailerSalesOrderDeliveryAgingReportResultFragment>;

  return <Table instance={tableInstance} pagination={pagination} align="top" />;
}
