import React from "react";
import { Link } from "@ui/Link";
import { adminProducerPath } from "@routes";

type AdminProducerLinkProps = {
  producer: {
    id: number;
    displayName: string;
  };
};

export function AdminProducerLink({ producer }: AdminProducerLinkProps) {
  return (
    <Link to={adminProducerPath(producer.id)}>{producer.displayName}</Link>
  );
}
