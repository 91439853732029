import React, { FC } from "react";
import { Stats } from "@components/ui/Stats";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import { Spinner } from "@shared/components/Spinner";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { useAdminDashboardStatisticsQuery } from "@apollo/ops";
/**
 * This component shows admin statistics of interest like : Total breweries onboarded, Total retailers onboarded, etc.

 */
const AdminDashboardPageCmp: FC<RouteComponentProps> = () => {
  const { data, loading, error } = useAdminDashboardStatisticsQuery();
  if (error) {
    // Let upper error boundary handle this
    throw error;
  }
  const statisticsData = data?.adminDashboardStatistics
    ? [
        {
          name: "Breweries Onboarded",
          value: data.adminDashboardStatistics.breweriesOnboarded,
        },
        {
          name: "Retailers Onboarded",
          value: data.adminDashboardStatistics.retailersOnboarded,
        },
        {
          name: "Distributors Onboarded",
          value: data.adminDashboardStatistics.distributorsOnboarded,
        },
        {
          name: "Total Payments",
          value: data.adminDashboardStatistics.totalPayments,
        },
      ]
    : [];
  return (
    <Page heading={<PageHeading title="Dashboard" />}>
      {loading && <Spinner />}
      {statisticsData && <Stats stats={statisticsData} />}
    </Page>
  );
};
export const AdminDashboardPage = withErrorBoundary(AdminDashboardPageCmp);
