import React, { FC } from "react";
import { useAddTrackingMutation } from "@apollo/ops";
import {
  TrackingInfoFormData,
  TrackingInformationModal,
  buildMutationDataFromForm,
} from "@components/TrackingInformation/TrackingInformationModal";

interface Props {
  orderId: number;
  onClose: () => void;
  isOpen: boolean;
}

export const AddTrackingInformationModal: FC<Props> = ({
  orderId,
  onClose,
  isOpen,
}) => {
  const [addTrackingInformation, { loading }] = useAddTrackingMutation();

  const onSubmit = async (data: TrackingInfoFormData) => {
    await addTrackingInformation({
      variables: {
        orderId,
        data: buildMutationDataFromForm(data),
      },
    });

    onClose();
  };

  return (
    <TrackingInformationModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={loading}
      onSubmit={onSubmit}
    />
  );
};
