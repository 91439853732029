import { Address } from "@apollo/ops";
import { convertNullToUndefined } from "@utilities/convertNullToUndefined";
import { AddressFormData } from "../components/Address/AddressModal";

// Takes an address object and formats it as a multi-line string
export const addressToDisplayString = (address: Address): string => {
  const addressFields = [
    address.name,
    address.company,
    address.address1,
    address.address2,
    `${address.city}, ${address.state} ${address.zip}`,
    address.phone,
  ];
  return addressFields.filter((field) => field).join("\n");
};

export const addressToShippingAddressForm = (
  address: Address | null
): AddressFormData | undefined => {
  const undefinedInsteadOfNull = convertNullToUndefined(address);

  if (undefinedInsteadOfNull) {
    return {
      name: undefinedInsteadOfNull.name,
      company: undefinedInsteadOfNull.company,
      address1: undefinedInsteadOfNull.address1,
      address2: undefinedInsteadOfNull.address2,
      city: undefinedInsteadOfNull.city,
      state: undefinedInsteadOfNull.state,
      zip: undefinedInsteadOfNull.zip,
      phone: undefinedInsteadOfNull.phone,
    };
  }

  return undefined;
};
