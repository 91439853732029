import React, { useMemo } from "react";
import {
  useTable,
  Column,
  useFilters,
  TableInstance,
  useSortBy,
} from "react-table";
import { ProducerLicenseFieldsFragment } from "@apollo/ops";
import { Table } from "@components/ui/Table";
import { formatAsDate } from "@utilities/formatDate";

type ProducerLicensesTableProps = {
  licenses: ProducerLicenseFieldsFragment[];
};

export function ProducerLicensesTable({
  licenses,
}: ProducerLicensesTableProps) {
  const columns = useMemo<Column<ProducerLicenseFieldsFragment>[]>(
    () => [
      {
        Header: "Created Date",
        Cell: ({ value }) => formatAsDate(new Date(value)),
        accessor: "createdAt",
      },
      {
        Header: "State",
        Cell: ({ value }) => value.name,
        accessor: "stateV2",
      },
      {
        Header: "License",
        accessor: "title",
      },
      {
        Header: "Issue Date",
        id: "startDate",
        Cell: ({ value }) => formatAsDate(new Date(value.startDate)),
        accessor: "currentLicensePeriod",
      },
      {
        Header: "Expiration Date",
        id: "endDate",
        Cell: ({ value }) => formatAsDate(new Date(value.endDate)),
        accessor: "currentLicensePeriod",
      },
      {
        Header: "Document View",
        Cell: ({ value }) => createLink(value),
        accessor: "documentUrl",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: licenses,
    },
    useFilters,
    useSortBy
  ) as TableInstance<ProducerLicenseFieldsFragment>;

  return <Table instance={tableInstance} />;
}

const createLink = (link: string | null) => {
  if (link) {
    return (
      <a href={link} className="text-indigo-600 hover:text-indigo-900">
        Link
      </a>
    );
  }
  return link;
};
