import { RouteComponentProps, Router } from "@reach/router";
import React, { FC } from "react";
import ProductPage from "./AdminProductPage/AdminProductPage";
import { AdminProductsPage } from "./AdminProductsPage/AdminProductsPage";

const ProductsContainer: FC<RouteComponentProps> = () => {
  return (
    <Router>
      <AdminProductsPage path="/" />
      <ProductPage path=":productIdParam/*" />
    </Router>
  );
};

export default ProductsContainer;
