import React from "react";
import { Button } from "@ui/Button";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { useCancelPreSaleOrderMutation } from "@apollo/gen/generatedOperations";
import { toast } from "@util/toast";
import { MessageSeverity } from "@shared/types/Severity";

type CancelPreSaleOrderButtonProps = {
  orderId: number;
  canCancel: boolean;
};

export function CancelPreSaleOrderButton({
  orderId,
  canCancel,
}: CancelPreSaleOrderButtonProps) {
  const [cancelOrder, { loading }] = useCancelPreSaleOrderMutation();
  const dialog = useDialog();

  const onConfirm = async () => {
    try {
      await cancelOrder({
        variables: {
          orderId,
        },
      });

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: `Successfully canceled order ${orderId}`,
        detail: "",
      });
    } catch (e) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: "",
        detail: e.message,
      });
    }
  };

  return (
    <>
      <Button
        destructive
        kind="secondary"
        size="sm"
        onClick={dialog.open}
        disabled={!canCancel || loading}
      >
        Cancel
      </Button>
      <ConfirmDialog
        title="Are you sure you want to cancel this order?"
        onConfirm={onConfirm}
        confirmText="Yes, cancel this order"
        dialog={dialog}
      />
    </>
  );
}
