import React from "react";
import {
  AutocompleteDistributorFragment,
  useAutocompleteDistributorsQuery,
} from "@apollo/ops";
import { Autocomplete, buildMatchSorterFilter } from "@ui/Autocomplete";

export type AllDistributorAutocompleteProps = {
  onSelect: (distributorId: number | null) => void;
  onDelete?: (selectedItem?: AutocompleteDistributorFragment | null) => void;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
};

export function AllDistributorsAutocomplete(
  props: AllDistributorAutocompleteProps
) {
  const { data } = useAutocompleteDistributorsQuery();

  if (data) {
    return (
      <DistributorsAutocomplete {...props} distributors={data.distributors} />
    );
  }

  return null;
}

export type DistributorsAutocompleteProps = {
  onSelect: (distributorId: number | null) => void;
  distributors: Array<AutocompleteDistributorFragment>;
  selectedId?: number | null;
  onBlur?: () => void;
  onDelete?: (selectedItem?: AutocompleteDistributorFragment | null) => void;
  label?: string;
  error?: string;
};

export function DistributorsAutocomplete({
  label,
  error,
  selectedId,
  distributors,
  onSelect,
  onBlur,
  onDelete,
}: DistributorsAutocompleteProps) {
  const handleSelect = (
    selectedDistributor?: AutocompleteDistributorFragment | null
  ) => {
    onSelect(selectedDistributor?.id ?? null);
  };

  const selectedDistributor =
    distributors.find((item) => item.id === selectedId) ?? null;

  return (
    <Autocomplete
      label={label}
      error={error}
      hideToggleButton={true}
      placeholder="Search distributors"
      onSelect={handleSelect}
      onBlur={onBlur}
      items={distributors}
      selectedItem={selectedDistributor}
      itemToString={distributorToString}
      filterItems={filterDistributors}
      onClickDelete={onDelete}
    />
  );
}

const filterDistributors = buildMatchSorterFilter<AutocompleteDistributorFragment>(
  {
    keys: [(distributor) => distributor.displayName],
  }
);

const distributorToString = (
  distributor: AutocompleteDistributorFragment | null
): string => distributor?.displayName ?? "";
