import { FC } from "react";
import { compose } from "lodash/fp";
import { BankAccountsView } from "./BankAccountsView";
import { RouteComponentProps } from "@reach/router";
import { withHook } from "@hoc/withHook";
import {
  useBankAccountsEnhancer,
  BankAccountsEnhancerResult,
} from "./BankAccountsEnhancer";
import { withErrorBoundary } from "@hoc/withErrorBoundary";

type BankAccountsProps = {
  businessEntityId: number;
};

export const BankAccounts: FC<RouteComponentProps<BankAccountsProps>> = compose(
  withErrorBoundary,
  withHook<BankAccountsProps, BankAccountsEnhancerResult>({
    useHook: useBankAccountsEnhancer,
  })
)(BankAccountsView);
