import React from "react";
import { Controller, NestedValue, useForm } from "react-hook-form";
import { omit } from "lodash";
import { Button } from "@ui/Button";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { LabeledInput } from "@components/ui/Input";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { AllProducersAutocomplete } from "@components/Producer/ProducerAutocomplete/ProducerAutocomplete";
import { Calendar } from "@ui";
import classNames from "classnames";
import { PackageTypesDropdown } from "@components/PackageType/PackageTypesDropdown";
import { FileGenerator, ImageInput } from "@components/FileUpload/ImageInput";
import { AdminProductQuery } from "@apollo/gen/generatedOperations";
import { Textarea } from "@ui/Textarea";
import { formatToDollars } from "@util/currency";
import { AmountInput } from "@components/Input/AmountInput";
import { AllBeerStylesAutocomplete } from "@admin/components/BeerStyle/BeerStylesAutoComplete/BeerStylesAutocomplete";

export type ProductFormData = {
  images?: NestedValue<Array<FileGenerator>>;
  name: string;
  producerId: number;
  packageTypeId: number;
  packageDate?: Date;
  abv?: string;
  description?: string;
  rating?: string;
  beerStyleId?: number;
  breweryNetPrice?: number | string;
};

export type ProductFormSubmitData = {
  cloudinaryImageId?: number;
  name: string;
  producerId: number;
  packageTypeId: number;
  packageDate?: string;
  abv?: string;
  description?: string;
  beerStyleId?: number;
  rating?: string;
  breweryNetPrice?: number;
};

export type UpdateProductFormData = {
  name: string;
  packageDate?: string;
  abv?: string;
  beerStyleId?: number;
  rating?: string;
  description?: string;
  breweryNetPrice?: number;
  cloudinaryImageId?: number | null;
};

export type CloneProductFormData = {
  name: string;
  producerId: number;
  packageTypeId: number;
  packageDate?: string;
  abv?: string;
  description?: string;
  beerStyleId?: number;
  rating?: string;
  breweryNetPrice?: number;
};

type ProductFormProps<T extends AdminProductQuery["product"] | undefined> = {
  product?: T;
  onSubmit: (
    data: T extends number ? UpdateProductFormData : ProductFormSubmitData
  ) => void | Promise<void>;
  isEditing?: boolean;
  isCloning?: boolean;
};

export function ProductForm<
  T extends AdminProductQuery["product"] | undefined
>({
  onSubmit,
  product,
  isEditing = false,
  isCloning = false,
}: ProductFormProps<T>) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ProductFormData>({
    defaultValues: {
      name: product?.name,
      producerId: product?.producer.id,
      packageTypeId: product?.packageType.id,
      abv: product?.abv?.toString() || undefined,
      packageDate: product?.packageDate ? new Date(product?.packageDate) : "",
      description: product?.description || "",
      rating: product?.rating?.toString() || "",
      beerStyleId: product?.beerStyle?.id,
      breweryNetPrice: product
        ? Number(
            formatToDollars({
              cents: product.breweryNetPrice ?? 0,
            }).replace("$", "")
          )
        : undefined,
    },
  });

  const wrappedOnSubmit = handleSubmit(async (data) => {
    try {
      const cloudinaryImageId = isCloning
        ? null
        : await data?.images?.[0]?.getFileId();
      /**
       * TypeScript doesn't yet support narrowing generics so we need to make
       * the any assertion.
       *
       * See https://github.com/microsoft/TypeScript/issues/24929
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await onSubmit({
        ...(cloudinaryImageId && { cloudinaryImageId }),
        ...omit(data, "images"),
      } as any);

      // If we are creating a new resource, reset the form. Otherwise we can
      // leave it with the update values.
      if (!isEditing) {
        reset();
      }
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: getMessageToUserFromBackendError(error),
        });
      }
    }
  });

  return (
    <form onSubmit={wrappedOnSubmit} autoComplete="off">
      <div className="grid laptop:grid-cols-2 gap-4">
        {!isCloning && (
          <div className="laptop:col-span-2">
            <Controller
              name="images"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <ImageInput
                  label="Product Image"
                  maxFiles={1}
                  maxSize={20971520}
                  accept="image/jpeg, image/png"
                  error={errors.images?.message}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            />
          </div>
        )}
        <div className="laptop:col-span-2">
          <LabeledInput
            label="Name"
            inputProps={{
              type: "text",
              error: errors.name?.message,
              ...register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              }),
            }}
          />
        </div>
        <div className="laptop:col-span-2">
          <Controller
            name="producerId"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Producer is required",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <AllProducersAutocomplete
                disabled={isEditing}
                label="Producer"
                onSelect={onChange}
                onBlur={onBlur}
                selectedId={value}
                error={errors.producerId?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="beerStyleId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <AllBeerStylesAutocomplete
                label="Beer Style"
                onSelect={onChange}
                onBlur={onBlur}
                selectedId={value}
                error={errors.beerStyleId?.message}
              />
            )}
          />
        </div>
        <div>
          <LabeledInput
            label="ABV"
            inputProps={{
              type: "number",
              step: 0.01,
              ...register("abv", {
                // https://www.grubstreet.com/2013/10/snake-venom-ale.html#:~:text=Scottish%20brewery%20Brewmeister%20has%20made,of%20beer%20and%20Champagne%20yeasts.
                max: {
                  value: 67.5,
                  message: "ABV can only go up to 67.5",
                },
              }),
              error: errors.abv?.message,
            }}
          />
        </div>
        <div>
          <LabeledInput
            label="Beer Rating"
            inputProps={{
              type: "number",
              error: errors.rating?.message,
              step: 0.01,
              ...register("rating", {
                max: {
                  value: 5,
                  message: "Ratings can only go up to 5",
                },
              }),
            }}
          />
        </div>
        <div>
          <AmountInput
            name={"breweryNetPrice"}
            label={"Brewery Net Price"}
            register={register}
            error={errors.breweryNetPrice?.message}
            className="min-w-full h-10"
            customValidation={{
              min: 0,
            }}
            required={false}
          />
        </div>
        <div>
          <label
            htmlFor="packageTypeId"
            className="block text-sm font-medium text-gray-700"
          >
            Package
          </label>
          <div className="mt-1 ">
            <Controller
              name="packageTypeId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Package is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <PackageTypesDropdown
                  onChange={onChange}
                  value={value}
                  error={errors.packageTypeId}
                  disabled={isEditing}
                />
              )}
            />
            {errors.packageTypeId && (
              <small className="text-sm text-red-600">
                Package type is required
              </small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="packageDate"
            className="block text-sm font-medium text-gray-700"
          >
            Package Date
          </label>
          <div className="mt-1">
            <Controller
              name="packageDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Calendar
                  style={{ width: "100%" }}
                  value={value}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  monthNavigator
                  appendTo="self"
                  placeholder="Pick the package date"
                  className={classNames({
                    "border-red-300": errors.packageDate,
                  })}
                />
              )}
            />
          </div>
        </div>
        <div className="laptop:col-span-2 mb-4">
          <Textarea label="Description" {...register("description")} />
        </div>
      </div>
      <div>
        <Button size="lg" type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
}
