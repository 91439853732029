import React, { FC, useContext } from "react";
import { WarningAlert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { BankAccountReVerifyContext } from "./BankAccountReVerifyContext";
import { noop } from "lodash/fp";

interface BankAccountReVerifyProps {
  bankAccountId: number;
  className?: string;
}

/**
 * This component shows an alert to the user when the bankAccount received in the params needs to be re-verified.
 * This components needs to be used with a BankAccountReVerifyProvider as one of it's ancestors. This is because this
 * component is meant to be used in a row of a table showing many bank accounts, and when we click on the button of
 * this component to reverify the account, the reverify process deletes the bank account and then shows the Plaid dialog
 * to add a bank account. Since this component will be unmounted (since it's bank account will be deleted) we need to
 * keep the Plaid dialog in a component at a higher level which is never unmount, that component is BankAccountReVerifyProvider.
 *
 *
 * @constructor
 */
export const BankAccountReVerify: FC<BankAccountReVerifyProps> = ({
  bankAccountId,
}) => {
  const {
    reverifyBankAccount,
    removingBankAccount,
    plaidDialogReady,
    loading,
  } = useContext(BankAccountReVerifyContext);

  const handleReVerify = () => {
    reverifyBankAccount(bankAccountId).then(() => noop);
  };

  return (
    <div>
      <WarningAlert
        title="Your bank account needs to be re-verified"
        description="We need you to re-verify your bank account in order to continue making purchases on Direct Beer."
      >
        <Button
          onClick={handleReVerify}
          kind="secondary"
          disabled={removingBankAccount || !plaidDialogReady || loading}
        >
          Re-verify bank account
        </Button>
      </WarningAlert>
    </div>
  );
};
