import React from "react";
import { Link as ReachLink, LinkProps as ReachLinkProps } from "@reach/router";
import classnames from "classnames";

export function Link<TState>({
  className,
  ref: _ref,
  ...rest
}: ReachLinkProps<TState>) {
  const classes = classnames(className, "underline hover:text-brand-500");

  return <ReachLink className={classes} {...rest} />;
}
