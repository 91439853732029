import React from "react";
import {
  InviteUserToOrganizationMutation,
  useInviteUserToOrganizationMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { InviteUserForm, InviteUserData } from "./InviteUserForm";

type InviteUserProps = {
  organizationId: number;
  onInvite?: (user: InviteUserToOrganizationMutation) => void;
};

export function InviteUser({ organizationId, onInvite }: InviteUserProps) {
  const [inviteUserToOrganization] = useInviteUserToOrganizationMutation();

  const handleSubmit = async ({ email, fullName }: InviteUserData) => {
    try {
      const { data } = await inviteUserToOrganization({
        variables: {
          organizationId,
          fullName,
          email,
        },
      });

      if (data && onInvite) {
        onInvite(data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: "Success! User invitation sent.",
      });
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <InviteUserForm onSubmit={handleSubmit} organizationId={organizationId} />
  );
}
