import { BankAccount, Business } from "@apollo/gen/generatedOperations";
import {
  VerificationStatusTag as PlaidItemVerificationStatusTag,
  VerificationStatusTagProps as PlaidItemVerificationStatusTagProps,
} from "@components/BankAccount/PlaidItem";
import React, { FC } from "react";
import { IconTag } from "@components/IconTag";
import { Severity } from "@shared/types/Severity";
import { BankAccountReVerify } from "../../OrganizationApp/BankAccounts/BankAccountReVerify/BankAccountReVerify";

type VerificationStatusTagProps = {
  bankAccount: Pick<BankAccount, "needsReverification" | "id"> & {
    business: Pick<Business, "id">;
    plaidItem: PlaidItemVerificationStatusTagProps | null;
  };
};

export const VerificationStatusTag: FC<VerificationStatusTagProps> = ({
  bankAccount,
}) => {
  if (bankAccount.needsReverification) {
    return (
      <>
        <IconTag severity={Severity.warn} value={"Unverified"} />
        <BankAccountReVerify
          className={"mt-4"}
          bankAccountId={bankAccount.id}
        />
      </>
    );
  }
  return (
    <PlaidItemVerificationStatusTag
      verificationStatus={bankAccount.plaidItem?.verificationStatus}
    />
  );
};
