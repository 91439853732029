import React from "react";
import { Button } from "@ui/Button";
import { Dialog, useDialog, UseDialogResult } from "@ui/Dialog";
import { toast } from "@util/toast";
import { Well } from "@ui/Well";
import { LedgerDetails } from "@admin/components/LedgerDetails";
import { Input } from "@ui/Input";
import { useForm } from "react-hook-form";
import { MessageSeverity } from "@shared/types/Severity";
import {
  LedgerDetailsFragment,
  MarkPlatformFeePaidMutationVariables,
  ValidationResultFragment,
} from "@apollo/gen/generatedOperations";

type ManuallyCollectPlatformFeeFormData = {
  orderId: number;
  invoiceNumber: string;
  paymentDate: string;
};

type ManuallyCollectPlatformFeeMutationVars = {
  variables: MarkPlatformFeePaidMutationVariables;
};

type ManuallyCollectPlatformFeeFormProps = {
  onSubmit: (vars: ManuallyCollectPlatformFeeMutationVars) => void;
  onSuccess?: () => void;
  orderId: number;
  ledger: LedgerDetailsFragment;
  validationResult: ValidationResultFragment;
};

const ManuallyCollectPlatformFeeForm = ({
  ledger,
  orderId,
  onSubmit,
  onSuccess,
}: ManuallyCollectPlatformFeeFormProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ManuallyCollectPlatformFeeFormData>({
    defaultValues: { orderId },
  });

  const wrappedOnSubmit = async (
    data: MarkPlatformFeePaidMutationVariables
  ) => {
    try {
      await onSubmit({ variables: data });
      onSuccess?.();
      reset();
    } catch (e) {
      if (e.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: e.message,
        });
      }
    }
  };

  return (
    <div>
      <div className="space-y-5">
        <Well>
          <LedgerDetails ledger={ledger} />
        </Well>
        <form onSubmit={handleSubmit(wrappedOnSubmit)} className="space-y-2">
          <div className="grid grid-cols-2">
            <label
              htmlFor="invoiceNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Invoice Number
            </label>
            <Input
              title="Invoice Number"
              type="text"
              {...register("invoiceNumber", {
                required: {
                  value: true,
                  message: "Invoice number is required",
                },
              })}
              name="invoiceNumber"
              placeholder="Invoice Number"
              error={errors.invoiceNumber?.message}
            />
          </div>
          <div className="grid grid-cols-2">
            <label
              htmlFor="paymentDate"
              className="block text-sm font-medium text-gray-700"
            >
              Date of payment
            </label>
            <Input
              type="date"
              title="Payment Date"
              {...register("paymentDate", {
                required: {
                  value: true,
                  message: "Payment date is required",
                },
              })}
              name="paymentDate"
              error={errors.paymentDate?.message}
            />
          </div>
          <Button size="xl" type="submit" disabled={isSubmitting}>
            Mark as paid
          </Button>
        </form>
      </div>
    </div>
  );
};

const ManuallyCollectPlatformFeeDialog = ({
  orderId,
  dialog,
  label,
  ledger,
  onSubmit,
  onSuccess,
  validationResult,
}: ManuallyCollectPlatformFeeFormProps & {
  label: string;
  dialog: UseDialogResult;
}) => {
  const { getDialogProps } = dialog;

  return (
    <Dialog {...getDialogProps()} title={label} width="2xl">
      <ManuallyCollectPlatformFeeForm
        validationResult={validationResult}
        onSuccess={onSuccess}
        ledger={ledger}
        onSubmit={onSubmit}
        orderId={orderId}
      />
    </Dialog>
  );
};

export const ManuallyCollectPlatformFee = ({
  ledger,
  validationResult,
  orderId,
  label,
  onSubmit,
  onSuccess,
}: ManuallyCollectPlatformFeeFormProps & { label: string }) => {
  const dialog = useDialog();
  const wrappedOnSuccess = () => {
    dialog.close();
    onSuccess?.();
  };
  return (
    <>
      <ManuallyCollectPlatformFeeDialog
        onSubmit={onSubmit}
        dialog={dialog}
        label={label}
        orderId={orderId}
        ledger={ledger}
        validationResult={validationResult}
        onSuccess={wrappedOnSuccess}
      />

      <Button
        onClick={dialog.open}
        kind="secondary"
        disabled={!validationResult.isValid}
      >
        {label}
      </Button>
    </>
  );
};
