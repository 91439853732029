import React from "react";
import { OrderType } from "@apollo/ops";
import { AmountInput } from "@components/Input/AmountInput";
import { LabeledInput } from "@components/ui/Input";
import { MessageSeverity } from "@shared/types/Severity";
import { Button } from "@ui/Button";
import { toast } from "@utilities/toast";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";

export type UpdateOrderFormData = {
  number: string;
  placedOn: Date | null;
  deliveredAt: Date | null;
  cancelledAt: Date | null;
  invoice: {
    externalAmount?: number;
    externalInvoiceId?: string;
  };
};

type UpdateOrderFormProps = {
  onSubmit: (data: UpdateOrderFormData) => void | Promise<void>;
  type: OrderType;
  defaultValues: UpdateOrderFormData;
};

/**
 * Need to prevent bubbling of the onMouseDown event of the calendar panel
 * However, this container should exist outside of the current document flow so
 * the overlay isn't clipped by a parent element.
 *
 * If we don't stop propagation, the Dialog component will detect the event,
 * see that the target is not a child of the Dialog, and close the modal.
 */
const calendarPortal = document.createElement("div");
calendarPortal.addEventListener("mousedown", (e) => e.stopPropagation());
document.body.append(calendarPortal);

export function UpdateOrderForm({
  onSubmit,
  type,
  defaultValues,
}: UpdateOrderFormProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<UpdateOrderFormData>({
    defaultValues,
  });

  const wrappedOnSubmit = async (data: UpdateOrderFormData) => {
    try {
      await onSubmit(data);
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <LabeledInput
          label="Order #"
          inputProps={{
            type: "text",
            error: errors.number?.message,
            ...register("number"),
          }}
        />
        <LabeledInput
          label="Invoice #"
          inputProps={{
            type: "text",
            error: errors.invoice?.externalInvoiceId?.message,
            ...register("invoice.externalInvoiceId"),
          }}
        />
        <AmountInput
          register={register}
          name="invoice.externalAmount"
          required={false}
          label="Invoice total"
        />
        <div>
          <label
            htmlFor="placedOn"
            className="block text-sm font-medium text-gray-700"
          >
            Placed on
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Calendar
                    value={value ? new Date(value) : undefined}
                    onChange={(e) => onChange(e.value)}
                    monthNavigator
                    placeholder="Select placed on date"
                    className="flex-1 w-full min-w-0 h-10"
                    appendTo={calendarPortal}
                  />
                );
              }}
              name="placedOn"
            />
            {errors.placedOn?.message && (
              <small className="text-sm text-red-600">
                {errors.placedOn.message}
              </small>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="deliveredAt"
            className="block text-sm font-medium text-gray-700"
          >
            Delivered at
          </label>
          <div className="mt-1">
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Calendar
                    value={value ? new Date(value) : undefined}
                    onChange={(e) => onChange(e.value)}
                    monthNavigator
                    placeholder="Select an delivery date"
                    className="flex-1 w-full min-w-0 h-10"
                    appendTo={calendarPortal}
                  />
                );
              }}
              name="deliveredAt"
            />
            {errors.deliveredAt?.message && (
              <small className="text-sm text-red-600">
                {errors.deliveredAt.message}
              </small>
            )}
          </div>
        </div>
        {/*  We are moving away from exposing this cancelledAt timestamp
        directly in favor of mutations that execute additional business logic in
        addition to updating this timestamp. At this point retailer sales
        orders, presales, and producer purchase orders support the dedicated mutation,
        thus we are hiding hte input for these types.*/}
        {![
          OrderType.RetailerSalesOrder,
          OrderType.RetailerPreSaleOrder,
          OrderType.ProducerPurchaseOrder,
        ].includes(type) && (
          <div>
            <label
              htmlFor="cancelledAt"
              className="block text-sm font-medium text-gray-700"
            >
              Cancelled at
            </label>
            <div className="mt-1">
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Calendar
                      value={value ? new Date(value) : undefined}
                      onChange={(e) => onChange(e.value)}
                      monthNavigator
                      placeholder="Select a cancellation date"
                      className="flex-1 w-full min-w-0 h-10"
                      appendTo={calendarPortal}
                    />
                  );
                }}
                name="cancelledAt"
              />
              {errors.cancelledAt?.message && (
                <small className="text-sm text-red-600">
                  {errors.cancelledAt.message}
                </small>
              )}
            </div>
          </div>
        )}
        <div>
          <Button size="lg" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
}
