import Bugsnag from "@bugsnag/browser";
import { BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE } from "@config";

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  releaseStage: BUGSNAG_RELEASE_STAGE,
  enabledReleaseStages: ["production", "staging"],
});

export { Bugsnag };
