import React, { useEffect, useState, useMemo } from "react";
import { compose } from "lodash/fp";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { formatAsDate } from "@utilities/formatDate";
import { Link } from "@ui/Link";
import { Column, TableInstance, useTable } from "react-table";
import { Table } from "@components/ui/Table";
import { Calendar } from "@ui";
import {
  PreSalesDocument,
  PreSalesQuery,
  PreSalesQueryVariables,
  PreSalesDisplayFragment,
} from "@apollo/ops";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";

const linkToPreSale = (preSale: PreSalesDisplayFragment) => {
  return <Link to={`${preSale.id}`}>{preSale.number}</Link>;
};

export const AdminPreSalesTableCmp = () => {
  const [dates, setDates] = useState<Date | Date[] | undefined | null>(
    undefined
  );
  const [region, setRegion] = useState<number>();
  const [filters, setFilters] = useState({});
  useEffect(() => {
    const dateVals = dates as Date[];
    const bothDatePresent =
      dateVals &&
      dateVals.length == 2 &&
      Boolean(dateVals[0]) &&
      Boolean(dateVals[1]);
    if (bothDatePresent || !dateVals) {
      setFilters((filters) => ({
        ...filters,
        preSaleDateRange: dateVals,
      }));
    }
  }, [dates]);

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      regionV2Id: region,
    }));
  }, [region]);

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    PreSalesQuery,
    PreSalesQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        filters,
      },
    },
    query: PreSalesDocument,
    mapDataToTotal({ paginatedPreSales }): number {
      return paginatedPreSales?.total ?? 0;
    },
  });
  const { data, error } = queryResult;

  const tableData = useMemo(() => {
    return data?.paginatedPreSales?.results || [];
  }, [data?.paginatedPreSales]);

  if (error) {
    throw error;
  }

  const columns: Array<Column<PreSalesDisplayFragment>> = useMemo(
    () => [
      {
        Header: "ID",
        Cell: ({ row }) => linkToPreSale(row.original),
        accessor: "number",
        width: 80,
      },
      {
        Header: "Region",
        accessor: "regionV2",
        Cell: ({ value }) => value.friendlyName,
        width: 120,
      },
      {
        Header: "Start",
        Cell: ({ value }) => formatAsDate(value),
        accessor: "start",
        width: 140,
      },
      {
        Header: "End",
        Cell: ({ value }) => formatAsDate(value),
        accessor: "end",
        width: 100,
      },
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data: tableData,
  }) as TableInstance<PreSalesDisplayFragment>;

  function handleRegionChange(regionId: number | null) {
    setRegion(regionId ?? undefined);
  }

  return (
    <div>
      {data && (
        <>
          <div className="flex mb-3">
            <div className="mr-8 w-56">
              <Calendar
                style={{ width: "100%" }}
                selectionMode="range"
                placeholder="Filter by date range"
                value={dates === null ? undefined : dates}
                onChange={(e) => setDates(e.value)}
              />
            </div>

            <div className="w-56">
              <RegionsDropdown onChange={handleRegionChange} value={region} />
            </div>
          </div>

          <Table instance={tableInstance} pagination={pagination} />
        </>
      )}
    </div>
  );
};

export const AdminPreSalesTable = compose(withErrorBoundary)(
  AdminPreSalesTableCmp
);
