import React, { useState } from "react";
import { AddLicense } from "./AddLicense";
import { Button } from "../../ui/Button";
import { Dialog } from "primereact/dialog";
import { AdminAddLicenseMutation } from "@apollo/ops";

type AddLicenseModalProps = {
  onAdd: (data: AdminAddLicenseMutation) => void;
  producerId: number;
};

export function AddLicenseModal({ producerId, onAdd }: AddLicenseModalProps) {
  const [visible, setVisible] = useState(false);

  const handleOnAdd = (data: AdminAddLicenseMutation) => {
    onAdd(data);
    setVisible(false);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} size="sm">
        Add License
      </Button>
      <Dialog
        visible={visible}
        header="Add License"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <AddLicense onAdd={handleOnAdd} producerId={producerId} />
      </Dialog>
    </>
  );
}
