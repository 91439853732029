import { get } from "lodash";
import React from "react";

export enum BackendErrorCodes {
  Unknown = "Unknown",
  Authorization = "Authorization",
  Authentication = "Authentication",
  UsernameExists = "UsernameExists",
  Validation = "Validation",
  NotFound = "NotFound",
  Forbidden = "Forbidden",
  BusinessRule = "BusinessRule",
  NoSandboxInProduction = "NoSandboxInProduction",
  UnsupportedTransfer = "UnsupportedTransfer",
}

export const UNKOWN_ERROR =
  "Unkown error. Please contact Direct.Beer at support@direct.beer";

const defaultErrors = {
  [BackendErrorCodes.Unknown]: UNKOWN_ERROR,
  [BackendErrorCodes.BusinessRule]: (backendError?: Error) => {
    const defaultMsg = "This action is not permitted in this state";
    if (!backendError) {
      return defaultMsg;
    }
    const rawErrorsFromBackend =
      getBackendErrorExtraAdditionalDetails(backendError).errors;
    if (rawErrorsFromBackend) {
      return rawErrorsFromBackend.length > 1
        ? rawErrorsFromBackend.map((e: string) => (
            <div className={"mb-3"} key={e}>
              {e}
            </div>
          ))
        : rawErrorsFromBackend[0];
    }
    return defaultMsg;
  },

  [BackendErrorCodes.UnsupportedTransfer]:
    "This type of transfer is not supported yet",
};

export const getMessageToUserFromBackendError = (
  error: Error,
  errorCodeToMsgMap: Record<
    string,
    string | ((error?: Error) => string)
  > = defaultErrors
): string => {
  const errorCode = getBackendErrorCode(error);

  if (!errorCode) {
    return error.message;
  }

  const msgOrMsgConstructor: string | ((error?: Error) => string) =
    errorCodeToMsgMap[errorCode];
  let msg = defaultErrors.Unknown;
  if (typeof msgOrMsgConstructor === "string") {
    msg = msgOrMsgConstructor;
  } else if (typeof msgOrMsgConstructor === "function") {
    msg = msgOrMsgConstructor(error);
  }

  return msg;
};

export const getBackendErrorCode = (backendError: Error) => {
  return get(backendError, [
    "graphQLErrors",
    0,
    "extensions",
    "exception",
    "code",
  ]);
};

export const getBackendErrorExtraAdditionalDetails = (backendError: Error) => {
  return get(backendError, ["graphQLErrors", 0, "extensions"]);
};
