import React from "react";
import {
  useAdminUpdateOrganizationMutation,
  AdminUpdateOrganizationMutation,
  useAdminOrganizationQuery,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { RetailerForm, RetailerFormData } from "./UpdateRetailerForm";

type UpdateRetailerProps = {
  retailerId: number;
  onUpdate?: (data: AdminUpdateOrganizationMutation) => void;
};

export function UpdateRetailer({ retailerId, onUpdate }: UpdateRetailerProps) {
  const { data } = useAdminOrganizationQuery({
    variables: {
      id: retailerId,
    },
  });

  const [updateOrganization] = useAdminUpdateOrganizationMutation();

  if (!data) {
    return null;
  }

  const handleSubmit = async ({ name }: RetailerFormData) => {
    try {
      const { data } = await updateOrganization({
        variables: {
          id: retailerId,
          name: name,
        },
      });

      if (data && onUpdate) {
        onUpdate(data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Updated retailer ${name}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <RetailerForm
      submitText="Update"
      onSubmit={handleSubmit}
      defaultValues={data.organization}
    />
  );
}
