import React from "react";
import { Button } from "@components/ui/Button";
import { useSyncBeverageAdvisorsMutation } from "@apollo/ops";
import { ActionPanel } from "@components/ui/ActionPanel";

export function SyncBeverageAdvisors() {
  const [syncBeverageAdvisors, { loading }] = useSyncBeverageAdvisorsMutation();

  return (
    <ActionPanel
      title="Sync Dwolla master account"
      description="Synchronizes the Dwolla master account funding sources to the Beverage Advisors business"
      action={
        <Button onClick={() => syncBeverageAdvisors()} disabled={loading}>
          Sync
        </Button>
      }
    />
  );
}
