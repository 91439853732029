import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { StackedLayout } from "@components/ui/Layouts/StackedLayout";
import { registerRetailerPath, onboardingRegisterRetailerPath } from "@routes";
import { RegisterRetailerPage } from "./RegisterRetailerPage/RegisterRetailerPage";
import { PrivacyPolicyPage } from "./PrivacyPolicyPage";
import { TermsOfServicePage } from "./TermsOfServicePage";
import { FAQPage } from "./FAQPage";
import { Footer } from "./PublicFooter";
import { NotFoundPage } from "./NotFoundPage";
import { UnauthorizedPage } from "./UnauthorizedPage";

export function PublicApp(_: RouteComponentProps) {
  return (
    <StackedLayout footer={<Footer />}>
      <Router>
        <FAQPage path="/faq" />
        <PrivacyPolicyPage path="/privacy-policy" />
        <RegisterRetailerPage path={registerRetailerPath} />
        {/* Special registration page that redirects to the onboarding */}
        <RegisterRetailerPage
          path={onboardingRegisterRetailerPath}
          redirectPath="/?redirectToOnboarding"
        />
        <TermsOfServicePage path="/terms-of-service" />
        <UnauthorizedPage path="/unauthorized" />
        <NotFoundPage default />
      </Router>
    </StackedLayout>
  );
}
