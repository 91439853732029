import React, { ButtonHTMLAttributes } from "react";
import classnames from "classnames";
import { Link, LinkProps, RouteComponentProps } from "@reach/router";
import ctl from "@netlify/classnames-template-literals";
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  kind?: ButtonKind;
  destructive?: boolean;
}

type ButtonSize = "xs" | "sm" | "base" | "lg" | "xl";
type ButtonKind = "primary" | "secondary" | "tertiary";

export function Button({
  size = "base",
  kind = "primary",
  destructive = false,
  className,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={classnames(
        buttonClasses({
          kind,
          size,
          destructive,
        }),
        className
      )}
      {...rest}
    />
  );
}

export interface ButtonLinkProps<TState>
  extends Omit<LinkProps<TState>, "ref">,
    RouteComponentProps {
  size?: ButtonSize;
  kind?: ButtonKind;
}

export function ButtonLink<TState>({
  size = "base",
  kind = "primary",
  className,
  ...rest
}: ButtonLinkProps<TState>) {
  return (
    <Link
      className={classnames(
        buttonClasses({
          kind,
          size,
          destructive: false,
        }),
        className
      )}
      {...rest}
    />
  );
}

type ButtonClassesOptions = {
  kind: ButtonKind;
  size: ButtonSize;
  destructive: boolean;
};

const buttonClasses = ({ kind, size, destructive }: ButtonClassesOptions) =>
  ctl(`
    inline-flex
    items-center
    border
    font-medium
    rounded-sm
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    disabled:opacity-50
    ${buttonKindClasses({ kind, destructive })}
    ${buttonSizeClasses[size]}
  `);

type ButtonKindClassesOptions = {
  kind: ButtonKind;
  destructive: boolean;
};

const buttonKindClasses = ({
  kind,
  destructive,
}: ButtonKindClassesOptions): string => {
  switch (kind) {
    case "primary":
      return destructive ? btnPrimaryDestructiveClasses : btnPrimaryClasses;
    case "secondary":
      return destructive ? btnSecondaryDestructiveClasses : btnSecondaryClasses;
    case "tertiary":
      return destructive ? btnTertiaryDestructiveClasses : btnTertiaryClasses;
  }
};

const buttonSizeClasses: Record<ButtonSize, string> = {
  xs: "px-2.5 py-1 text-xs",
  sm: "px-3 py-1.5 text-sm",
  base: "px-4 py-2 text-sm",
  lg: "px-4 py-2 text-base",
  xl: "px-4 py-2 text-base",
};

const btnPrimaryClasses = ctl(`
  text-white
  bg-primary-600
  hover:bg-primary-700
  focus:ring-primary-500
  border-transparent
  shadow-sm
`);

const btnPrimaryDestructiveClasses = ctl(`
  text-white
  bg-red-600
  hover:bg-red-700
  focus:ring-red-500
  border-transparent
  shadow-sm
`);

const btnSecondaryClasses = ctl(`
  text-gray-700
  bg-white
  hover:bg-gray-50
  border-gray-300
  focus:ring-primary-500
  shadow-sm
`);

const btnSecondaryDestructiveClasses = ctl(`
  text-red-700
  bg-white
  hover:bg-gray-50
  border-gray-300
  focus:ring-red-500
  shadow-sm
`);

const btnTertiaryClasses = ctl(`
text-gray-700
hover:bg-gray-50
focus:ring-primary-500
border-transparent
`);

const btnTertiaryDestructiveClasses = ctl(`
text-gray-900
hover:bg-gray-50
focus:ring-red-500
border-transparent
`);
