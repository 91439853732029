import React from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { formatAsDateWithTime } from "@utilities/formatDate";
import { LoadingPage } from "@components/LoadingPage";
import { useProducerPurchaseOrderOverviewQuery } from "@apollo/gen/generatedOperations";
import { ProducerPurchaseOrderSummaryCard } from "./ProducerPurchaseOrderSummaryCard";
import { ProducerPurchaseOrderShippingPage } from "./ProducerPurchaseOrderShipping/ProducerPurchaseOrderShippingPage";
import { Router } from "@reach/router";
import { producerPaths } from "@routes";
import { SidenavLayout } from "@ui/Layouts/SidenavLayout";
import { SecondaryNav } from "@components/SecondaryNav/SecondaryNav";
import { ProducerPurchaseOrderItemsPage } from "./ProducerPurchaseOrderItems/ProducerPurchaseOrderItemsPage";
import { ProducerPurchaseOrderDocumentsPage } from "./ProducerPurchaseOrderDocumentsPage/ProducerPurchaseOrderDocumentsPage";

const paths = producerPaths.order;

const navItems = [
  {
    path: ".",
    name: "Overview",
  },
  {
    path: paths.shipping,
    name: "Shipping",
  },
  {
    path: paths.items,
    name: "Items",
  },
  {
    path: paths.documents,
    name: "Documents",
  },
];

export function ProducerPurchaseOrderContainer({
  orderId,
}: {
  orderId: number;
}) {
  const { data } = useProducerPurchaseOrderOverviewQuery({
    variables: {
      orderId,
    },
  });

  if (data && data.order) {
    const { order } = data;

    return (
      <div key={order.id}>
        {order.deletedAt && (
          <div className="mb-3">
            <DeletedAlert deletedAt={order.deletedAt} />
          </div>
        )}
        <SidenavLayout
          nav={
            <SecondaryNav
              items={navItems}
              resourceType={"Order"}
              resourceIdentifier={"#" + data.order.number}
            />
          }
        >
          <Router>
            <ProducerPurchaseOrderSummaryCard path={"/"} order={order} />
            <ProducerPurchaseOrderShippingPage
              path="/shipping"
              orderSummary={order}
              orderId={order.id}
            />
            <ProducerPurchaseOrderItemsPage
              path="/items"
              orderSummary={order}
              orderId={order.id}
            />
            <ProducerPurchaseOrderDocumentsPage
              path="/documents"
              orderSummary={order}
              orderId={order.id}
            />
          </Router>
        </SidenavLayout>
      </div>
    );
  }

  return <LoadingPage />;
}

type DeletedAlertProps = {
  deletedAt: Date;
};

function DeletedAlert({ deletedAt }: DeletedAlertProps) {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            This order was deleted at{" "}
            {formatAsDateWithTime(new Date(deletedAt))}
          </h3>
        </div>
      </div>
    </div>
  );
}
