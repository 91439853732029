import React, { useMemo } from "react";
import { AdminProductDetailsFragment } from "@apollo/ops";
import { Table } from "@ui/Table";
import {
  Column,
  TableInstance,
  useTable,
  useFlexLayout,
  CellProps,
} from "react-table";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { AdminProductLink } from "@components/Product/Product";

type AdminProductsTableProps = {
  data: Array<AdminProductDetailsFragment>;
  pagination?: UseOffsetPaginationResult;
};

export function AdminProductsTable({
  data,
  pagination,
}: AdminProductsTableProps) {
  const columns: Array<Column<AdminProductDetailsFragment>> = useMemo(
    () => [
      {
        Header: "Name",
        Cell: ({ row }: CellProps<AdminProductDetailsFragment, "string">) => (
          <AdminProductLink product={row.original} />
        ),
        accessor: "name",
        width: 140,
      },
      {
        Header: "ABV",
        accessor: ({ abv }) => `${abv}%`,
        width: 75,
      },
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  ) as TableInstance<AdminProductDetailsFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
