import React from "react";
import {
  FundsAccountAutocomplete,
  FundsAccountAutocompleteProps,
} from "@components/FundsAccount/FundsAccountAutocomplete/FundsAccountAutocomplete";
import { useAutocompleteFromFundsAccountsQuery } from "@apollo/ops";

export function FromFundsAccountAutocomplete(
  props: Omit<FundsAccountAutocompleteProps, "fundsAccounts">
) {
  const { data } = useAutocompleteFromFundsAccountsQuery();

  if (!data) {
    return <div className="h-36" />;
  }

  return (
    <FundsAccountAutocomplete
      fundsAccounts={data.fundsAccountsEligibleToSend}
      {...props}
    />
  );
}
