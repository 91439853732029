import React from "react";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Label, LabeledInput } from "@ui/Input";
import {
  AddLocationFormDataQuery,
  AddLocationMutation,
  useAddLocationFormDataQuery,
  useAddLocationMutation,
} from "@apollo/ops";
import Joi from "joi";
import { Button } from "@components/ui/Button";
import { LoadingIcon } from "@components/ui/Spinner";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";

export type OnAddHandler = (data: AddLocationMutation) => Promise<void>;

type AddStoreFormProps = {
  onAdd?: OnAddHandler;
  organizationId: number;
};

/**
 * This form is loaded inside a Dialog and thus always renders a focusable
 * element.
 */
export function AddStoreForm({ onAdd, organizationId }: AddStoreFormProps) {
  const { data, error, loading } = useAddLocationFormDataQuery({
    variables: {
      organizationId,
    },
  });

  const [addLocation] = useAddLocationMutation();
  const handleSubmit = async (formData: AddStoreFormData) => {
    const { data: resultData } = await addLocation({
      variables: {
        data: {
          ...formData,
          organizationId,
        },
      },
    });

    if (resultData && onAdd) {
      onAdd(resultData);
    }
  };

  if (error) {
    return <span tabIndex={0}>Unable to initialize form.</span>;
  }

  if (loading && !data) {
    return (
      <div tabIndex={0}>
        <LoadingIcon />
      </div>
    );
  }

  return <AddLocationFormWithData data={data} onSubmit={handleSubmit} />;
}

type AddStoreFormData = {
  name: string;
  regionId: number;
};

type AddStoreFormWithDataProps = {
  onSubmit: (data: AddStoreFormData) => unknown | Promise<unknown>;
  data?: AddLocationFormDataQuery;
};

function AddLocationFormWithData({
  onSubmit,
  data,
}: AddStoreFormWithDataProps) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<AddStoreFormData>({
    resolver: joiResolver(formSchema),
  });

  const wrappedOnSubmit = async (data: AddStoreFormData) => {
    await onSubmit(data);
    reset();
  };

  const inlineLead = data?.organization ? data.organization.name + " - " : "";

  return (
    <form
      className="grid grid-cols-1 gap-4"
      onSubmit={handleSubmit(wrappedOnSubmit)}
    >
      <LabeledInput
        label="Store name"
        inputProps={{
          type: "text",
          error: errors.name?.message,
          ...register("name"),
          inlineLead,
          autoFocus: true,
        }}
      />
      <div>
        <Label htmlFor="region">Region</Label>
        <div className="mt-1">
          <Controller
            name="regionId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RegionsDropdown
                onChange={onChange}
                value={value}
                error={errors.regionId}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Button>Submit</Button>
      </div>
    </form>
  );
}

const formSchema = Joi.object().keys({
  name: Joi.string().required(),
  regionId: Joi.number().required(),
});
