import React, { ReactNode } from "react";

export interface DescriptionListProps {
  children: ReactNode;
}

type DescriptionListItemProps = {
  term: ReactNode;
  description: ReactNode;
};

export function DescriptionListItem({
  term,
  description,
}: DescriptionListItemProps) {
  return (
    <>
      <dt className="text-sm font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-sm text-gray-900">{description}</dd>
    </>
  );
}

export function DescriptionListInCard({ children }: DescriptionListProps) {
  return (
    <div className="px-4 py-5 tablet:p-0">
      <dl className="tablet:divide-y tablet:divide-gray-200">{children}</dl>
    </div>
  );
}
export interface TermProps {
  term: ReactNode;
  definition: ReactNode;
  hidden?: boolean;
}

export function StackedTerm({ term, definition, hidden = false }: TermProps) {
  if (hidden) {
    return null;
  }

  return (
    <div className="tablet:col-span-1">
      <dt className="text-sm font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-sm text-gray-900">{definition}</dd>
    </div>
  );
}

export function LeftAlignedTerm({
  term,
  definition,
  hidden = false,
}: TermProps) {
  return !hidden ? (
    <div className="py-3 tablet:py-4 tablet:grid tablet:grid-cols-3 gap-4 tablet:px-6">
      <dt className="text-sm font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-sm text-gray-900 tablet:mt-0 tablet:col-span-2 whitespace-pre-wrap">
        {definition}
      </dd>
    </div>
  ) : null;
}
