import React from "react";
import { BankAccountFieldsFragment } from "@apollo/ops";
import { Dialog, DialogProps } from "@ui/Dialog";
import { AddBankAccountCopy } from "./BankAccountConstants";
import { NewAddBankAccount } from "./NewAddBankAccount/NewAddBankAccount";

export type HandleAdd = (bankAccount: BankAccountFieldsFragment) => void;

type AddBankAccountDialogProps = {
  dialogProps: Omit<DialogProps, "children">;
  businessId: number;
  onAdd?: HandleAdd;
};

export function AddBankAccountDialog({
  businessId,
  dialogProps,
  onAdd,
}: AddBankAccountDialogProps) {
  const wrappedOnAdd = (bankAccount: BankAccountFieldsFragment) => {
    onAdd && onAdd(bankAccount);
  };

  return (
    <Dialog {...dialogProps} title="Add bank account">
      <p className="mb-3">{AddBankAccountCopy}</p>
      <NewAddBankAccount businessId={businessId} onAdd={wrappedOnAdd} />
    </Dialog>
  );
}
