import React from "react";
import { Badge, Color } from "@ui/Badge";
import { DwollaCustomerBeneficialOwnerCertificationStatus } from "@apollo/ops";

type BeneficialOwnershipCertificationStatusBadgeProps = {
  status: DwollaCustomerBeneficialOwnerCertificationStatus;
};

export function BeneficialOwnershipCertificationStatusBadge({
  status,
}: BeneficialOwnershipCertificationStatusBadgeProps) {
  const color = certificationStatusToBadgeColor(status);

  return <Badge color={color} label={status} />;
}

function certificationStatusToBadgeColor(
  status: DwollaCustomerBeneficialOwnerCertificationStatus
): Color {
  switch (status) {
    case DwollaCustomerBeneficialOwnerCertificationStatus.Certified:
      return "green";
    case DwollaCustomerBeneficialOwnerCertificationStatus.Recertify:
      return "yellow";
    case DwollaCustomerBeneficialOwnerCertificationStatus.Uncertified:
      return "yellow";
  }
}
