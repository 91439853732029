import React, { Fragment } from "react";
import { useRetailerApp } from "../RetailerAppProvider";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";

type StoreSwitcherProps = {
  storeId: number | null;
};

export function StoreSwitcher({ storeId }: StoreSwitcherProps) {
  const { organization, setCurrentStore } = useRetailerApp();
  const selectedStore = organization.locations.find(
    (location) => location.id === storeId
  );

  return (
    <Listbox
      value={storeId ? storeId.toString() : ""}
      onChange={(val) => {
        if (val) {
          setCurrentStore(parseInt(val));
        }
      }}
    >
      {({ open }) => (
        <div className="mt-1 relative">
          <Listbox.Button className="bg-skin-primary hover:bg-amber-700 text-skin-base relative w-full rounded-md pl-3 pr-10 py-2 text-left cursor-default hover:cursor-pointer focus:outline-none focus:ring-1 focus:ring-white focus:border-white sm:text-sm">
            <div className="truncate leading-snug text-skin-base">
              <div className="text-xs truncate">Store</div>
              <div className="truncate font-semibold">
                {selectedStore ? selectedStore.name : "Select a store"}
              </div>
            </div>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="h-5 w-5 text-skin-base"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {organization.locations.map((location) => (
                <Listbox.Option
                  key={location.id}
                  className={({ active }) =>
                    classNames(
                      active && "bg-gray-100",
                      "cursor-pointer select-none relative py-2 pl-3 pr-9 text-gray-900"
                    )
                  }
                  value={location.id.toString()}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {location.name}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4 text-gray-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
