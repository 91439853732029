import {
  FreightAgingReportResultFragment,
  ServiceLevelTarget,
} from "@apollo/ops";
import { AdminOrderLink } from "@components/Order/Order";
import { AdminProducerLink } from "@components/Producer/Producer";
import { FreightAge } from "@components/ProducerPurchaseOrder/FreightAge";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { Table } from "@ui/Table";
import { formatAsDate } from "@utilities/formatDate";
import React, { useMemo } from "react";
import { CellProps, Column, TableInstance, useTable } from "react-table";

export const buildColumns = (
  freightSLT: ServiceLevelTarget
): Array<Column<FreightAgingReportResultFragment>> => [
  {
    Header: "Placed on",
    Cell: ({ value }: CellProps<FreightAgingReportResultFragment, "string">) =>
      formatAsDate(new Date(value)),
    accessor: "placedOn",
  },
  {
    Header: "Order Number",
    Cell: ({ row }: CellProps<FreightAgingReportResultFragment, "string">) => (
      <>
        <AdminOrderLink order={row.original} />
        {row.original.invoice?.externalInvoiceId && (
          <div className="text-muted">
            {row.original.invoice?.externalInvoiceId}
          </div>
        )}
        <div className="text-sm text-muted">
          {row.original.regionV2.friendlyName}
        </div>
      </>
    ),
    accessor: "number",
    disableSortBy: true,
    id: "number",
  },
  {
    Header: "Brewery",
    Cell: ({ row }: CellProps<FreightAgingReportResultFragment>) => (
      <AdminProducerLink producer={row.original.seller} />
    ),
    accessor: (order) => order.seller.displayName,
    id: "seller",
  },
  {
    Header: "Est. Arrival",
    Cell: ({
      value,
    }: CellProps<FreightAgingReportResultFragment, "string">) => {
      return value ? formatAsDate(new Date(value)) : "N/A";
    },
    accessor: (order) => order.trackingInformation?.estimatedArrivalDate,
    id: "estimatedArrivalDate",
  },
  {
    Header: "Age",
    Cell: ({ row }: CellProps<FreightAgingReportResultFragment, number>) => (
      <div className="w-20">
        <FreightAge
          placedOn={row.original.placedOn}
          serviceLevelTarget={freightSLT}
        />
      </div>
    ),
    id: "age",
  },
];

type FreightAgingReportTableProps = {
  data: Array<FreightAgingReportResultFragment>;
  freightSLT: ServiceLevelTarget;
  pagination?: UseOffsetPaginationResult;
};

export function FreightAgingReportTable({
  data,
  pagination,
  freightSLT,
}: FreightAgingReportTableProps) {
  const columns = useMemo(() => buildColumns(freightSLT), [freightSLT]);

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<FreightAgingReportResultFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
