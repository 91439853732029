import React from "react";
import { compose } from "lodash/fp";
import { Link, Redirect, RouteComponentProps } from "@reach/router";
import { useAuth, withAuthentication } from "../shared/auth";
import { StackedLayout as StackedLayout } from "@components/ui/Layouts/StackedLayout";
import { SimplePage, PageHeading } from "@components/ui/Page";
import { RedirectToShopify } from "./RedirectToShopify";
import { organizationPath, registerRetailerPath } from "@routes";
import { BusinessType, useWhoAmIQuery } from "@apollo/ops";

/*
 * There are four different sub-applications within Direct Beer: Brewery,
 * Distributor, Retailer, and Admin.
 *
 * AppSwitcher let's the user navigate to their relevant sub-applications.
 */
function AppLauncher(_: RouteComponentProps) {
  const { data } = useWhoAmIQuery({
    fetchPolicy: "cache-and-network",
  });

  /*
   * whoAmI should never be null since this component is wrapped by the
   * withAuthentication HOC.
   *
   * TODO consider loading strategy and error handling
   */
  if (!data || data?.whoAmI === null) {
    return null;
  }

  const user = data.whoAmI;
  const links = [];

  if (user.isGlobalAdmin) {
    links.push({
      path: "/admin",
      label: "Admin",
      type: "Global",
    });
  }

  if (user.organizations) {
    links.push(
      ...user.organizations.map((organization) => ({
        path: organizationPath(organization),
        label: organization.name,
        type: organization.businessType,
      }))
    );
  }

  // If the user doesn't have any apps, send them to retailer registration
  if (links.length === 0) {
    return <Redirect to={registerRetailerPath} />;
  }

  /**
   * Retailers should be redirect to Shopify so they can start shopping. A
   * search params is used as a signal that we want to skip the redirect.
   */
  if (
    user.isGlobalAdmin === false &&
    user.organization?.businessType === BusinessType.Retailer &&
    window.location.search.length === 0
  ) {
    return <RedirectToShopify organizationId={user.organization.id} />;
  }

  // If the user only has one relevant app, take them there
  if (links.length === 1) {
    return <Redirect to={links[0].path} />;
  }

  return (
    <StackedLayout navRight={<SignOutButton />} gray>
      <SimplePage heading={<PageHeading title="Select an application" />}>
        <ul className="grid grid-cols-1 gap-6 tablet:grid-cols-2 laptop:grid-cols-3">
          {links.map(({ label, type, path }, index) => (
            <Link key={index} to={path}>
              <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <div className="text-sm font-bold text-gray-500">{type}</div>
                <div className="text-lg">{label}</div>
              </div>
            </Link>
          ))}
        </ul>
      </SimplePage>
    </StackedLayout>
  );
}

function SignOutButton() {
  const { logout } = useAuth();

  return (
    <button type="button" className="link-gray" onClick={logout}>
      Sign out
    </button>
  );
}

export default compose(withAuthentication())(AppLauncher);
