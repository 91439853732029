import {
  TrackingInformationDetailsFragment,
  useUpdateTrackingMutation,
} from "@apollo/ops";
import {
  buildMutationDataFromForm,
  TrackingInfoFormData,
  TrackingInformationModal,
} from "@components/TrackingInformation/TrackingInformationModal";
import React, { FC } from "react";

interface Props {
  trackingInformation: TrackingInformationDetailsFragment;
  onClose: () => void;
  isOpen: boolean;
}

export const UpdateTrackingInformationModal: FC<Props> = ({
  trackingInformation,
  onClose,
  isOpen,
}) => {
  const [updateTrackingMutation, { loading }] = useUpdateTrackingMutation();

  const onSubmit = async (data: TrackingInfoFormData) => {
    await updateTrackingMutation({
      variables: {
        trackingId: trackingInformation.id,
        data: buildMutationDataFromForm(data),
      },
    });

    onClose();
  };

  return (
    <TrackingInformationModal
      trackingInformation={trackingInformation}
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={onSubmit}
      isLoading={loading}
    />
  );
};
