import React from "react";
import { LoadingPage } from "@components/LoadingPage";
import { AdminOrderSummaryCard } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderOverviewPage/AdminOrderSummaryCard";
import { ProducerOrderStatusTable } from "@admin/components/ProducerPurchaseOrder/ProducerOrderStatusTable";
import { useAdminOrderOverviewQuery } from "@apollo/ops";
import { Card, CardBody } from "@components/ui/Card/Card";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import { Button } from "@ui/Button";
import { Page } from "@ui/Page";
import { DeliveryProgressBar } from "@components/ProducerPurchaseOrder/DeliveryProgressBar";
import { PaymentProgressBar } from "@components/ProducerPurchaseOrder/PaymentProgressBar";
import { onExportFileClick } from "@util/files";
import { formatAsDateWithTime } from "@utilities/formatDate";
import { DIRECT_BEER_API_URI } from "src/config";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { CancelSalesOrderButton } from "../CancelSalesOrder/CancelSalesOrderButton";
import { UpdateOrderDialog } from "../UpdateOrder/UpdateOrderDialog";
import { OrderPODDocumentForm } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderRetailerOrdersPage/OrderPODDocumentForm";
import { CancelledAlert } from "@components/Order/CancelledAlert";
import { RetailerOrderStatusTable } from "@admin/components/RetailerSalesOrder/RetailerOrderStatusTable";
import { CancelPreSaleOrderButton } from "@admin/AdminOrdersContainer/AdminOrderRouter/CancelPreSaleOrder/CancelPreSaleOrderButton";
import { CancelProducerPurchaseOrderButton } from "@admin/AdminOrdersContainer/AdminOrderRouter/CancelProducerPurchaseOrder/CancelProducerPurchaseOrderButton";

export function AdminOrderOverviewPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const { data } = useAdminOrderOverviewQuery({
    variables: {
      orderId,
    },
  });

  if (data && orderSummary) {
    const { order } = data;

    const onGenerateOrderPdfClick = async (isBundle = false) => {
      const pdfUrl = `${DIRECT_BEER_API_URI}order/${orderId}/pdf${
        isBundle ? "?bundle=true" : ""
      }`;
      const fileName = `order-${order.number}.pdf`;
      onExportFileClick({ url: pdfUrl, fileName });
    };

    return (
      <div key={order.id}>
        <Page
          heading={
            <AdminOrderPageHeading
              order={orderSummary}
              actions={
                <>
                  {order.__typename === "RetailerSalesOrder" && (
                    <CancelSalesOrderButton
                      orderId={orderId}
                      canCancel={order.canCancel.isValid}
                    />
                  )}
                  {order.__typename === "ProducerPurchaseOrder" && (
                    <CancelProducerPurchaseOrderButton
                      orderId={orderId}
                      canCancel={order.canCancel.isValid}
                    />
                  )}
                  {order.__typename === "PreSaleOrder" && (
                    <CancelPreSaleOrderButton
                      orderId={orderId}
                      canCancel={order.canCancel.isValid}
                    />
                  )}
                  {order.enabledFeatures.canEditOrder && (
                    <UpdateOrderDialog orderId={orderId} />
                  )}
                  {order.enabledFeatures.canCreatePDF && (
                    <Button
                      onClick={() => onGenerateOrderPdfClick(false)}
                      size="sm"
                    >
                      Download PDF
                    </Button>
                  )}
                  {order.enabledFeatures.canCreatePDFBundle && (
                    <Button
                      onClick={() => onGenerateOrderPdfClick(true)}
                      size="sm"
                    >
                      Download PDF Bundle
                    </Button>
                  )}
                </>
              }
            />
          }
        >
          {order.cancelledAt && (
            <div className="mb-3">
              <CancelledAlert cancelledAt={order.cancelledAt} />
            </div>
          )}
          {order.deletedAt && (
            <div className="mb-3">
              <DeletedAlert deletedAt={order.deletedAt} />
            </div>
          )}
          <div className="grid gap-6 grid-flow-col-dense laptop:grid-cols-3">
            <div className="space-y-6 laptop:col-start-1 laptop:col-span-2">
              <AdminOrderSummaryCard order={order} />
            </div>
            <div className="laptop:col-start-3 laptop:col-span-1 space-y-6">
              {order.__typename === "ProducerPurchaseOrder" && (
                <Card title="Status">
                  <CardBody>
                    <ProducerOrderStatusTable order={order} />
                  </CardBody>
                </Card>
              )}
              {order.__typename === "RetailerSalesOrder" && (
                <Card title="Status">
                  <CardBody>
                    <RetailerOrderStatusTable order={order} />
                  </CardBody>
                </Card>
              )}
              {order.__typename === "ProducerPurchaseOrder" && (
                <>
                  <div className="px-3">
                    <h3 className="text-sm mb-2 text-gray-500 font-medium">
                      Delivery progress
                    </h3>
                    <DeliveryProgressBar order={order} />
                  </div>
                  <div className="px-3">
                    <h3 className="text-sm mb-2 text-gray-500 font-medium">
                      Retailer payment progress
                    </h3>
                    <PaymentProgressBar order={order} />
                  </div>
                </>
              )}
              {order.__typename === "RetailerSalesOrder" &&
                (!order.documents?.some(
                  (doc) => doc.documentType === "proof_of_delivery"
                ) ? (
                  <OrderPODDocumentForm
                    orderId={data.order.id}
                    number={data.order.number}
                  />
                ) : (
                  <div className="flex items-center">
                    <CheckIcon className="w-10 h-10 text-green-600 mr-3" />
                    <div className="text-gray-600">
                      Proof of Delivery uploaded
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Page>
      </div>
    );
  }

  return <LoadingPage />;
}

type DeletedAlertProps = {
  deletedAt: Date;
};

function DeletedAlert({ deletedAt }: DeletedAlertProps) {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            This order was deleted at{" "}
            {formatAsDateWithTime(new Date(deletedAt))}
          </h3>
        </div>
      </div>
    </div>
  );
}
