import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  AdminDistributorQuery,
  AdminDistributorDocument,
  AdminDistributorQueryVariables,
} from "@apollo/ops";
import { AdminDwollaCustomerCard } from "@admin/components/AdminDwollaCustomerCard";
import { AdminBankAccountDetailsCard } from "@components/BankAccount/BankAccount";
import { AdminBusinessDetailsCard } from "@components/Business/BusinessDetailsCard";
import { Page, PageHeading } from "@components/ui/Page";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { UpdateDistributorModal } from "@components/Distributor/UpdateDistributorModal";
import { InviteUserToOrganizationModal } from "../../components/InviteUserToOrganizationModal/InviteUserToOrganizationModal";
import { organizationPath } from "@routes";
import { ButtonLink } from "@components/ui/Button";
import { DeleteDistributorButton } from "./DeleteDistributorButton";
import { DistributorDetailsCard } from "@components/Distributor/DistributorDetailsCard";

interface DistributorProps extends RouteComponentProps {
  distributorId?: string;
}

type WrappedProps = WithQueryComponentProps<
  DistributorProps,
  AdminDistributorQuery,
  AdminDistributorQueryVariables
>;

function AdminDistributor({ query }: WrappedProps) {
  const { distributor } = query?.data || null;

  return (
    distributor && (
      <Page
        heading={
          <PageHeading
            title={distributor.displayName}
            actions={
              <>
                {distributor.canDelete && (
                  <DeleteDistributorButton distributorId={distributor.id} />
                )}
                <ButtonLink
                  to={"/" + organizationPath(distributor.organization)}
                  kind="secondary"
                  size="sm"
                >
                  View as customer
                </ButtonLink>
                <InviteUserToOrganizationModal
                  organization={distributor.organization}
                  buttonProps={{ kind: "secondary" }}
                />
                <UpdateDistributorModal
                  distributorId={distributor.organization.id}
                />
              </>
            }
          />
        }
      >
        <div className="mt-8">
          <div className="space-y-4">
            <AdminBusinessDetailsCard
              businessEntityId={distributor.id}
              fein={null}
              address={distributor.address}
            />
            <DistributorDetailsCard
              feePerPackageInCents={distributor.feePerPackageInCents}
              distributorId={distributor.id}
            />
            <AdminDwollaCustomerCard customer={distributor.dwollaCustomer} />
            <AdminBankAccountDetailsCard
              bankAccount={distributor.bankAccount}
            />
          </div>
        </div>
      </Page>
    )
  );
}

export const AdminDistributorPage = withQuery<
  DistributorProps,
  AdminDistributorQuery,
  AdminDistributorQueryVariables
>(AdminDistributorDocument, {
  mapPropsToOptions: (props) => ({
    variables: { distributorId: parseInt(props.distributorId!) },
  }),
})(AdminDistributor);
