import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Bugsnag } from "./bugsnag";
import { globalHistory, LocationProvider } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./App.scss";
import "./index.css";

import { client } from "./apollo";
import { AuthProvider } from "./shared/auth";
import { WithQueryConfig } from "./shared/withQuery";
import { Spinner } from "./shared/components/Spinner";
import RootApp from "./RootApp";
import { NotFoundPage } from "./PublicApp/NotFoundPage";
import { FallbackProps, withErrorBoundary } from "react-error-boundary";
import { Page } from "./components/ui/Page";
import { Button } from "./components/ui/Button";
import { StackedLayout } from "./components/ui/Layouts/StackedLayout";
import { IUser } from "@shared/auth/AuthContext";
import { DialoggerProvider } from "@components/Dialogger";
import { PageProvider } from "./components/ui/Page/PageProvider";

function onLogIn(user: IUser) {
  Bugsnag.setUser(user.id.toString());
  Bugsnag.addMetadata("user", {
    organizationId: user.organization?.id,
    organizationName: user.organization?.name,
  });
}

function onLogOut() {
  Bugsnag.setUser();
  Bugsnag.clearMetadata("user");
}

function onPageView() {
  if (window.gtag !== undefined) {
    gtag("event", "page_view");
  }
}

/**
 * @remarks
 * Rendering LocationProvider underneath the AuthProvider prevents the errors
 * thrown during Redirect from causing the AuthProvider to re-mount (and thus
 * clearing the user context and causing the login component to flicker onto
 * the screen before the context is reset.)
 */
const AppWrapper = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider onLogIn={onLogIn} onLogOut={onLogOut}>
        <LocationProvider>
          <QueryParamProvider reachHistory={globalHistory}>
            <WithQueryConfig
              value={{
                ErrorComponent: ErrorFallback,
                LoadingComponent: Spinner,
                NotFoundComponent: NotFoundPage,
              }}
            >
              <PageProvider onView={onPageView}>
                <DialoggerProvider>
                  <RootApp />
                  <div id="dialog-portal"></div>
                </DialoggerProvider>
              </PageProvider>
            </WithQueryConfig>
          </QueryParamProvider>
        </LocationProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

function ErrorFallback() {
  return <div>Something went wrong</div>;
}

const FallbackComponent = ({ error, resetErrorBoundary }: FallbackProps) => {
  const handleClick = () => {
    resetErrorBoundary();
  };

  return (
    <StackedLayout>
      <Page>
        <h1 className="text-2xl mb-2">Something went wrong :(</h1>
        <div>{error.message}</div>
        <div className="mt-6">
          <Button onClick={handleClick}>Go Home</Button>
        </div>
      </Page>
    </StackedLayout>
  );
};

export default withErrorBoundary(AppWrapper, {
  FallbackComponent,
});
