import React, { ReactNode } from "react";
export interface SimpleProps {
  title: string;
  description?: string;
  actions: ReactNode;
}

export function Simple({ title, description, actions }: SimpleProps) {
  return (
    <div className="bg-white px-3 py-4 border-b border-gray-200 tablet:px-5">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap tablet:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="leading-5 font-medium text-gray-900">{title}</h3>
          {description && (
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          )}
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">{actions}</div>
      </div>
    </div>
  );
}
