import React from "react";
import {
  useSendBalanceTransferDataQuery,
  useSendBalanceTransferMutation,
  useSendDistributorBalanceTransferMutation,
} from "@apollo/ops";
import { LoadingIcon } from "@ui/Spinner";
import { ConfirmTransfer } from "@admin/components/ConfirmTransfer";

type SendBalanceTransferProps = {
  orderId: number;
};

export function SendBalanceTransfer({ orderId }: SendBalanceTransferProps) {
  const { data, loading, error } = useSendBalanceTransferDataQuery({
    variables: {
      orderId,
    },
    errorPolicy: "all",
  });

  const [sendBalanceTransfer] = useSendBalanceTransferMutation({
    variables: {
      orderId,
    },
  });
  const [
    sendDistributorBalanceTransfer,
  ] = useSendDistributorBalanceTransferMutation({
    variables: {
      orderId,
    },
  });

  if (loading && !data) {
    return <LoadingIcon />;
  }

  if (data) {
    const { order } = data;

    if (order.__typename === "ProducerPurchaseOrder") {
      return (
        <ConfirmTransfer
          ledger={order.bankDepositsLedger}
          transferProposal={order.balanceTransferProposal}
          validationResult={order.canSendBalanceTransfer}
          label="Send producer balance transfer"
          onSubmit={sendBalanceTransfer}
        />
      );
    } else if (order.__typename === "DistributorPurchaseOrder") {
      return (
        <ConfirmTransfer
          ledger={order.bankDepositsLedger}
          transferProposal={order.balanceTransferProposal}
          validationResult={order.canSendBalanceTransfer}
          label="Send distributor balance transfer"
          onSubmit={sendDistributorBalanceTransfer}
        />
      );
    } else {
      throw Error(`Can use SendBalanceTransfer with ${order.__typename} order`);
    }
  }

  if (error) {
    return <div>Failed to load payment data.</div>;
  }

  return null;
}
