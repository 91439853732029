import React, {
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useId } from "@reach/auto-id";
import ctl from "@netlify/classnames-template-literals";

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  label?: string;
  children?: ReactNode;
}

export function Label({ label, children, className, ...rest }: LabelProps) {
  return (
    <label
      className={ctl(`${className} block text-sm font-medium text-gray-700`)}
      {...rest}
    >
      {label || children}
    </label>
  );
}
export type LabeledInputProps = {
  label: string;
  inputProps: InputProps;
};

export function LabeledInput({ label, inputProps }: LabeledInputProps) {
  const htmlForKey = useId();

  return (
    <div>
      <Label label={label} htmlFor={htmlForKey} />
      <div className="mt-1">
        <Input
          {...inputProps}
          id={htmlForKey}
          onWheel={(e) => {
            if (
              e.target instanceof HTMLElement &&
              inputProps.type === "number"
            ) {
              e.target.blur();
            }
          }}
        />
      </div>
    </div>
  );
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inlineLead?: ReactNode;
  error?: string;
  "data-testid"?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ inlineLead, error, ...inputProps }, ref) => {
    // This measures the width of the inlineLead element passed in,
    //adds the right amount of space for it in front of the actual text,
    //and rerenders the whole Input.
    //It's a bit of a complex way to handling this styling but it was
    //not straightforward to handle this dynamic spacing in CSS.
    const [inlineWidth, setInlineWidth] = useState(0);
    const inlineLeadRef = useRef<HTMLDivElement>(null);

    //This effect intentionally has no dependencies and is run after every render since it's measuring the
    //size of the actual inlineLeadRef DOM element
    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (inlineLeadRef.current) {
        setInlineWidth(inlineLeadRef.current.getBoundingClientRect().width);
      }
    }, [setInlineWidth, inlineLead]);

    return (
      <div>
        <div className="relative rounded-md shadow-sm">
          {inlineLead && (
            <div className={inlineLeadClasses} ref={inlineLeadRef}>
              <span className={inlineLeadSpanClasses(Boolean(error))}>
                {inlineLead}
              </span>
            </div>
          )}
          <input
            type="text"
            aria-invalid={!!error}
            className={inputClasses({
              hasError: Boolean(error),
              readOnly: Boolean(inputProps.readOnly || inputProps.disabled),
            })}
            ref={ref}
            style={inlineLead ? { paddingLeft: inlineWidth + 2 } : undefined}
            {...inputProps}
          />
        </div>
        {error && (
          <div className="mt-1">
            <p role="alert" className="text-sm text-red-600">
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

type InputClassesOptions = {
  hasError?: boolean;
  readOnly?: boolean;
};

const inputClasses = ({
  hasError = false,
  readOnly = false,
}: InputClassesOptions) =>
  ctl(`
    block
    w-full
    tablet:text-sm
    rounded-sm
    ${inputStateClasses({ hasError })}
    ${readOnly && "bg-gray-50"}
  `);

type InputStateClassesOptions = {
  hasError: boolean;
  readOnly?: boolean;
};

export const inputStateClasses = ({
  hasError,
  readOnly = false,
}: InputStateClassesOptions) => {
  let classes = ctl(`
    focus:border-blue-500
    focus:ring-blue-500
    border-gray-300
    `);

  if (hasError)
    classes = ctl(`
    border-red-300
    text-red-900
    placeholder-red-300
    focus:outline-none
    focus:ring-red-500
    focus:border-red-500
    `);
  else if (readOnly)
    classes = ctl(`
    block
    w-full
    tablet:text-sm
    rounded-sm
    bg-gray-50
    `);

  return classes;
};

const inlineLeadClasses = ctl(`
  absolute
  inset-y-0
  left-0
  pl-3
  flex
  items-center
  pointer-events-none
  whitespace-pre
`);

const inlineLeadSpanClasses = (error: boolean) =>
  ctl(`
    ${error ? "text-red-500" : "text-gray-500"}
    tablet:text-sm
  `);
