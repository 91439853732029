import React from "react";
import { DateTime } from "luxon";
import { isNil } from "lodash";
import { Age } from "@ui";
import { ServiceLevelTarget } from "@apollo/ops";

type FreightAgeProps = {
  placedOn: string;
  deliveredAt?: string | null;
  serviceLevelTarget: ServiceLevelTarget;
};

export function FreightAge({
  placedOn,
  deliveredAt,
  serviceLevelTarget,
}: FreightAgeProps) {
  const start = DateTime.fromISO(placedOn);
  const end = isNil(deliveredAt) ? undefined : DateTime.fromISO(deliveredAt);

  return (
    <Age start={start} end={end} serviceLevelTarget={serviceLevelTarget} />
  );
}
