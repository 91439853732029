import React from "react";
import { DateTime } from "luxon";
import { isNil } from "lodash";
import { Age } from "@ui";
import { ServiceLevelTarget } from "@apollo/ops";

type DeliveryAgeProps = {
  placedOn: string;
  deliveredAt?: string | null;
  serviceLevelTarget: ServiceLevelTarget;
};

export function DeliveryAge({
  placedOn,
  deliveredAt,
  serviceLevelTarget,
}: DeliveryAgeProps) {
  const start = DateTime.fromISO(placedOn);
  const end = isNil(deliveredAt) ? undefined : DateTime.fromISO(deliveredAt);

  return (
    <Age start={start} end={end} serviceLevelTarget={serviceLevelTarget} />
  );
}

type DeliveryAgeFromFreightProps = {
  freightDeliveredAt?: string | null;
  deliveredAt?: string;
  serviceLevelTarget: ServiceLevelTarget;
};

export function DeliveryAgeFromFreight({
  freightDeliveredAt,
  deliveredAt,
  serviceLevelTarget,
}: DeliveryAgeFromFreightProps) {
  if (isNil(freightDeliveredAt)) {
    return <span>N/A</span>;
  }

  const start = DateTime.fromISO(freightDeliveredAt);
  const end = deliveredAt ? DateTime.fromISO(deliveredAt) : undefined;

  return (
    <Age start={start} end={end} serviceLevelTarget={serviceLevelTarget} />
  );
}
