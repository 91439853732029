import React, { ReactNode } from "react";
import {
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

type AlertProps = {
  title?: string;
  description?: string;
  children?: ReactNode;
};

export function WarningAlert({ children, title, description }: AlertProps) {
  return (
    <div className="bg-yellow-50 p-4 border-l-4 border-yellow-400">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 text-yellow-700 space-y-3">
          {(title || description) && (
            <div>
              {title && (
                <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
              )}
              {description && <div className="mt-2 text-sm">{description}</div>}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export function ErrorAlert({ children, title, description }: AlertProps) {
  return (
    <div className="rounded-md bg-red-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {(title || description) && (
            <div>
              {title && (
                <h3 className="text-sm font-medium text-red-800">{title}</h3>
              )}
              {description && (
                <div className="mt-2 text-sm text-red-700">{description}</div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export function InfoAlert({ children, title, description }: AlertProps) {
  return (
    <div className="rounded-md bg-blue-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-500"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {(title || description) && (
            <div>
              {title && (
                <h3 className="text-sm font-medium text-blue-800">{title}</h3>
              )}
              {description && (
                <div className="mt-2 text-sm text-blue-700">{description}</div>
              )}
            </div>
          )}
          <div className="text-blue-700">{children}</div>
        </div>
      </div>
    </div>
  );
}
