import { AdminFundsTransferFieldsFragment } from "@apollo/gen/generatedOperations";
import { Card } from "@ui/Card/Card";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";
import React from "react";
import { formatAsDate } from "@utilities/formatDate";

type TransferDetailsCardProps = {
  transfer: AdminFundsTransferFieldsFragment;
};

export function AdminTransferDetailsCard({
  transfer,
}: TransferDetailsCardProps) {
  const {
    id: transferID,
    dwollaTransfers,
    createdAt,
    statementDescriptor,
    settlementStrategy,
    settlementMetadata,
    settlementRejectionResponse,
    status,
    source: {
      business: { id: sourceID, displayName: sourceDisplayName },
    },
    destination: {
      business: { id: destID, displayName: destDisplayName },
    },
  } = transfer;

  const dwollaTransferComponents = dwollaTransfers.map(
    (
      {
        id,
        sourceId,
        destinationId,
        links,
        failureCode,
        failureExplanation,
        failureDescription,
        status,
      },
      i
    ) => {
      const destLink = links?.["destination"]?.href || "";
      return (
        <span key={i}>
          <LeftAlignedTerm
            term="Created At"
            definition={formatAsDate(createdAt)}
          />
          <LeftAlignedTerm term="Dwolla ID" definition={id} />
          <LeftAlignedTerm term="Source ID" definition={sourceId} />
          <LeftAlignedTerm term="Destination ID" definition={destinationId} />
          <LeftAlignedTerm term="Link" definition={destLink} />
          <LeftAlignedTerm
            term="Failure Description"
            definition={failureDescription}
          />
          <LeftAlignedTerm
            term="Failure Explanation"
            definition={failureExplanation}
          />
          <LeftAlignedTerm term="Failure Code" definition={failureCode} />
          <LeftAlignedTerm term="Status" definition={status} />
        </span>
      );
    }
  );
  return (
    <>
      <Card title="Transfers Information">
        <DescriptionListInCard>
          <LeftAlignedTerm term="Transfer ID " definition={transferID} />
          <LeftAlignedTerm
            term="Created Date"
            definition={formatAsDate(createdAt)}
          />
          <LeftAlignedTerm
            term="From"
            definition={`${sourceDisplayName} ID: ${sourceID}`}
          />
          <LeftAlignedTerm
            term="To"
            definition={`${destDisplayName} ID: ${destID}`}
          />
          <LeftAlignedTerm
            term="Statement Descriptor"
            definition={statementDescriptor}
          />
          <LeftAlignedTerm term="Status" definition={status} />
          <LeftAlignedTerm
            term={"Settlement Strategy"}
            definition={settlementStrategy}
          />
          {settlementMetadata ? (
            <LeftAlignedTerm
              term={"Settlement Metadata"}
              definition={JSON.stringify(settlementMetadata, null, 2)}
            />
          ) : null}

          {settlementRejectionResponse ? (
            <LeftAlignedTerm
              term="Settlement Rejection Response"
              definition={JSON.stringify(settlementRejectionResponse, null, 2)}
            />
          ) : null}
        </DescriptionListInCard>
      </Card>
      <br />
      <Card title="Dwolla Transfers Information">
        <DescriptionListInCard>
          {dwollaTransferComponents}
        </DescriptionListInCard>
      </Card>
    </>
  );
}
