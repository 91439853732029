import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps } from "@reach/router";
import { useDistributorTransactionsQuery } from "@apollo/ops";
import { LoadingIcon } from "@components/ui/Spinner";
import { Page, PageHeading } from "@components/ui/Page";
import { DistributorTransactionsTable } from "./DistributorTransactionsTable";

type DistributorProps = {
  businessEntityId: number;
} & RouteComponentProps;

export function DistributorTransactions({
  businessEntityId,
}: DistributorProps) {
  const { data: results, loading, error } = useDistributorTransactionsQuery({
    variables: {
      businessId: businessEntityId,
    },
  });

  useErrorHandler(error);

  const data = results?.business?.fundsTransfers
    ? results.business.fundsTransfers
    : [];

  return (
    <Page>
      <PageHeading title="Your Transactions" />
      <div className="mt-10">
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="mt-8 card">
            {!loading && (!data || data.length === 0) ? (
              <>
                <p>No transactions</p>
                <br />
              </>
            ) : null}

            {data.length > 0 && (
              <DistributorTransactionsTable fundsTransfers={data} />
            )}
          </div>
        )}
      </div>
    </Page>
  );
}
