import { from } from "env-var";
/**
 * Vite requires you to prefix with VITE_ "for security reasons".
 */
const processEnv = {
  NODE_ENV: import.meta.env.NODE_ENV,
  APP_ENV: import.meta.env.VITE_APP_ENV,
  DWOLLA_ENVIRONMENT: import.meta.env.VITE_DWOLLA_ENVIRONMENT,
  DIRECT_BEER_GRAPHQL_URI: import.meta.env.VITE_DIRECT_BEER_GRAPHQL_URI,
  BUGSNAG_API_KEY: import.meta.env.VITE_BUGSNAG_API_KEY,
  BUGSNAG_RELEASE_STAGE: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
  VITE_AWS_REGION: import.meta.env.VITE_AWS_REGION,
  VITE_AWS_USER_POOL_ID: import.meta.env.VITE_AWS_USER_POOL_ID,
  VITE_AWS_USER_POOL_WEB_CLIENT_ID: import.meta.env
    .VITE_AWS_USER_POOL_WEB_CLIENT_ID,
  VITE_COOKIE_DOMAIN: import.meta.env.VITE_COOKIE_DOMAIN,
  VITE_PLAID_ENVIRONMENT: import.meta.env.VITE_PLAID_ENVIRONMENT,
  VITE_PRISMIC_ENDPOINT: import.meta.env.VITE_PRISMIC_ENDPOINT,
  VITE_PRISMIC_TOKEN: import.meta.env.VITE_PRISMIC_TOKEN,
  DIRECT_BEER_API_URI: import.meta.env.VITE_DIRECT_BEER_API_URI,
};

const env = from(processEnv);

export const APP_ENV = env.get("APP_ENV").default("development").asString();

export const AWS_REGION = env.get("VITE_AWS_REGION").required().asString();

export const AWS_USER_POOL_ID = env
  .get("VITE_AWS_USER_POOL_ID")
  .required()
  .asString();

export const AWS_USER_POOL_WEB_CLIENT_ID = env
  .get("VITE_AWS_USER_POOL_WEB_CLIENT_ID")
  .required()
  .asString();

export const BUGSNAG_API_KEY = env.get("BUGSNAG_API_KEY").required().asString();

export const BUGSNAG_RELEASE_STAGE = env
  .get("BUGSNAG_RELEASE_STAGE")
  .required()
  .asString();

export const COOKIE_DOMAIN = env
  .get("VITE_COOKIE_DOMAIN")
  .default("")
  .asString();

export const DIRECT_BEER_GRAPHQL_URI = env
  .get("DIRECT_BEER_GRAPHQL_URI")
  .required()
  .asString();

export const DIRECT_BEER_API_URI = env
  .get("DIRECT_BEER_API_URI")
  .required()
  .asString();

export const DWOLLA_ENVIRONMENT = env
  .get("DWOLLA_ENVIRONMENT")
  .default("production")
  .asEnum(["sandbox", "production"]);

export const PLAID_CLIENT_NAME = "Direct Beer";

export const PLAID_ENVIRONMENT = env
  .get("VITE_PLAID_ENVIRONMENT")
  .default("sandbox")
  .asEnum(["sandbox", "development", "production"]);

export const PLAID_PRODUCTS = ["auth"];

export const PRISMIC_ENDPOINT = env.get("VITE_PRISMIC_ENDPOINT").asString();
export const PRISMIC_TOKEN = env.get("VITE_PRISMIC_TOKEN").asString();
