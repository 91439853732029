import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "@reach/router";
import ctl from "@netlify/classnames-template-literals";
import { isNil } from "lodash";

export type BreadcrumbItem = {
  label: string;
  path: string;
};

export type BreadcrumbProps = {
  items: Array<BreadcrumbItem>;
};

export function Breadcrumbs({ items }: BreadcrumbProps) {
  /**
   * The last item is assumed to be the current page, making the second to
   * last item the back item.
   */
  const backItem = items.length > 1 ? items[items.length - 2] : undefined;

  return (
    <div>
      {backItem && (
        <nav className="tablet:hidden" aria-label="Back">
          <Link
            to={backItem.path}
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            <ChevronLeftIcon
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Back
          </Link>
        </nav>
      )}
      <nav className="hidden tablet:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {items.map((item, index) => (
            <BreadcrumbListItem
              key={item.path}
              item={item}
              isFirst={index === 0}
            />
          ))}
        </ol>
      </nav>
    </div>
  );
}

type BreadcrumbListItemProps = {
  isFirst: boolean;
  item: BreadcrumbItem;
};

function BreadcrumbListItem({ isFirst, item }: BreadcrumbListItemProps) {
  return (
    <li>
      <div className="flex items-center">
        {!isFirst && (
          <ChevronRightIcon
            className="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        )}
        <Link className={breadcrumbItemsClasses({ isFirst })} to={item.path}>
          {item.label}
        </Link>
      </div>
    </li>
  );
}

type BreadcrumbItemClassesOptions = {
  isFirst: boolean;
};

const breadcrumbItemsClasses = ({ isFirst }: BreadcrumbItemClassesOptions) =>
  ctl(`
    ${!isFirst && "ml-3"}
    text-sm
    font-medium
    text-gray-500
    max-w-[120px]
    truncate
    hover:text-gray-700
  `);

/**
 * This function takes advantage of @reach/routers relative path syntax to
 * generate breadcrumb items.
 */
export function buildBreadcrumbs(
  ...labels: Array<string>
): Array<BreadcrumbItem> {
  return labels
    .reverse()
    .filter((i) => !isNil(i))
    .map((label, index) => ({
      label,
      path: index === 0 ? "." : "../".repeat(index),
    }))
    .reverse();
}
