import React from "react";
import { DwollaCustomerBillingInfoFragment } from "@apollo/ops";
import { Button } from "@components/ui/Button";
import { Card } from "@components/ui/Card/Card";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";
import { PencilAltIcon } from "@heroicons/react/outline";
import { UpdateDwollaCustomerDialog } from "@components/UpdateDwollaCustomer/UpdateDwollaCustomerDialog";
import { useDialog } from "@components/ui/Dialog";

type DwollaCustomerCardProps = {
  dwollaCustomer: DwollaCustomerBillingInfoFragment;
};

export function DwollaCustomerCard({
  dwollaCustomer,
}: DwollaCustomerCardProps) {
  const { open: openUpdateDwollaCustomerDialog, getDialogProps } = useDialog();
  return (
    <>
      <Card
        headerProps={{
          title: "Billing Information",
          actions: (
            <Button kind="tertiary" onClick={openUpdateDwollaCustomerDialog}>
              <PencilAltIcon className="h-6 w-6" />
            </Button>
          ),
        }}
      >
        <DescriptionListInCard>
          <LeftAlignedTerm term="Email" definition={dwollaCustomer.email} />
        </DescriptionListInCard>
      </Card>
      <UpdateDwollaCustomerDialog
        dwollaCustomer={dwollaCustomer}
        dialogProps={getDialogProps()}
      />
    </>
  );
}
