import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { AdminPreSaleImportJobsPage } from "./AdminPreSaleImportJobsPage/AdminPreSaleImportJobsPage";
import { AdminPreSaleImportJobPage } from "./AdminPreSaleImportJobPage/AdminPreSaleImportJobPage";

export function AdminPreSaleImportJobsContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminPreSaleImportJobsPage path="/" />
      <AdminPreSaleImportJobPage path=":preSaleImportJobId" />
      <Redirect from="*" to="/not-found" />
    </Router>
  );
}
