import React, { useState } from "react";
import {
  useAddTransferForOrderMutation,
  useAdminOrderBalanceTransfersQuery,
} from "@apollo/ops";
import { FundsTransferTable } from "@components/FundsTransfer/FundsTransferTable";
import { Page } from "@components/ui/Page";
import { LoadingPage } from "@components/LoadingPage";
import { toastError } from "@utilities/toast";
import {
  OrderTransferModal,
  TransferMode,
  TransferSource,
} from "../OrderTransfer/OrderTransferModal";
import { OrderTransferFormData } from "../OrderTransfer/OrderTransferForm";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { toCents } from "@utilities/currency";
import { LedgerStatusBadge } from "@components/LedgerStatusBadge";
import { Card, CardBody } from "@ui/Card/Card";
import { LedgerDetails } from "@admin/components/LedgerDetails";
import { ValidationResult } from "@components/ValidationResult/ValidationResult";
import { SectionHeading } from "@ui/SectionHeadings";
import { SendBalanceTransfer } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderBalanceTransfersPage/sendBalanceTransfer/SendBalanceTransfer";

export function AdminOrderBalanceTransfersPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const closeTransferModal = () => setIsTransferModalOpen(false);

  const [addBalanceTransfer] = useAddTransferForOrderMutation();

  const onTransferSubmit = async (data: OrderTransferFormData) => {
    try {
      await addBalanceTransfer({
        variables: {
          data: {
            sourceId: data.sourceFundsAccountId,
            destinationId: data.destinationFundsAccountId,
            amountInCents: toCents(data.amount),
            statementDescriptor:
              data.statementDescriptorPrefix + " " + data.statementDescriptor,
            breweryInvoiceNumber: data.breweryInvoiceNumber,
            orderId,
          },
        },
      });

      closeTransferModal();
      await refetch();
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  const { data, error, refetch } = useAdminOrderBalanceTransfersQuery({
    variables: {
      orderId,
    },
  });

  if (error) {
    return null;
  }

  if (data && orderSummary) {
    const { order } = data;

    return (
      <Page
        heading={
          <AdminOrderPageHeading
            page="Balance Transfers"
            order={orderSummary}
          />
        }
      >
        <OrderTransferModal
          isOpen={isTransferModalOpen}
          onClose={closeTransferModal}
          onSubmit={onTransferSubmit}
          orderId={data.order.id}
          orderNumber={data.order.number}
          transferSource={TransferSource.Buyer}
          transferMode={TransferMode.Balance}
        />
        <div>
          {(order.__typename === "ProducerPurchaseOrder" ||
            order.__typename === "DistributorPurchaseOrder") && (
            <div className="mt-12 space-y-6">
              <SectionHeading title="Bank Deposits" />
              <Card
                headerProps={{
                  title: "Bank deposit balances",
                  actions: (
                    <LedgerStatusBadge
                      status={order.bankDepositsLedger.status}
                      size="lg"
                    />
                  ),
                }}
              >
                <CardBody>
                  <LedgerDetails
                    transferType="deposit"
                    ledger={order.bankDepositsLedger}
                  />
                </CardBody>
              </Card>
              <ValidationResult
                validationResult={order.canSendBalanceTransfer}
                footer={
                  <div className="flex justify-end">
                    <SendBalanceTransfer orderId={order.id} />
                  </div>
                }
              />
            </div>
          )}
          <div className="mt-12">
            <SectionHeading title="All Bank Deposits" />
            <FundsTransferTable
              fundsTransfers={order.bankDepositsLedger.transfers}
            />
          </div>
        </div>
      </Page>
    );
  }

  return <LoadingPage />;
}
