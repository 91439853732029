import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { AdminTransactions } from "./AdminTransactions";
import AdminTransferPage from "./AdminTransferPage/AdminTransferPage";

function AdminTransfersContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminTransactions path="/" />
      <AdminTransferPage path="/:transferIdParam" />
    </Router>
  );
}

export default AdminTransfersContainer;
