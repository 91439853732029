import { SandboxProducerType, SandboxRetailerType } from "@apollo/ops";
import { SandboxAddCardProps } from "../SandboxAddCard";
import { makeAddSandboxProducerHook } from "./useAddSandboxProducer";
import { makeAddSandboxRetailerHook } from "./useAddSandboxRetailer";

const items: SandboxAddCardProps[] = [
  {
    title: "Onboarding Retailer",
    description: "New organization + retailer ready to go through on-boarding",
    addHook: makeAddSandboxRetailerHook(SandboxRetailerType.NewRetailer),
  },
  {
    title: "Onboarding Brewery",
    description: "New organization + brewery ready to go through on-boarding",
    addHook: makeAddSandboxProducerHook(SandboxProducerType.NewBrewery),
  },
  {
    title: "Onboarding Brewery w/ bank account",
    description:
      "New organization + brewery on the bank accout step of on-boarding",
    addHook: makeAddSandboxProducerHook(
      SandboxProducerType.OnboardingBankAccountBrewery
    ),
  },
  {
    title: "Brewery With Funding Source",
    description: "New organization + brewerery with Dwolla funding source",
    addHook: makeAddSandboxProducerHook(
      SandboxProducerType.BreweryWithFundingSource
    ),
  },
  {
    title: "Brewery With Post-Settlement Failable Funding Source",
    description:
      "Same as `Brewery With Funding Source`, but with a funding source that can simulate funds failing post-settlement",
    addHook: makeAddSandboxProducerHook(
      SandboxProducerType.BreweryWithPostSettlementFailableFundingSource
    ),
  },
  {
    title: "Retailer With Funding Source",
    description: "New organization + retailer with Dwolla funding source",
    addHook: makeAddSandboxRetailerHook(
      SandboxRetailerType.NewRetailerWithFundingSource
    ),
  },
];

export default items;
