import React from "react";

type NoDataFoundProps = {
  resource: string;
};

export function NoDataFound({ resource = "data" }: NoDataFoundProps) {
  return (
    <div className="w-full h-24 flex items-center justify-center text-gray-500">
      No {resource} found
    </div>
  );
}
