import React, { FC, useCallback, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button } from "@components/ui/Button";
import { Page, PageHeading } from "@components/ui/Page";
import { Dialog } from "primereact/dialog";
import { InviteGlobalAdminForm } from "./InviteGlobalAdminForm";
import { SyncBeverageAdvisors } from "./SyncBeverageAdvisors/SyncBeverageAdvisors";
import { SectionHeading } from "@components/ui/SectionHeadings";

const GlobalAdminView: FC<RouteComponentProps> = () => {
  const [
    isShowingInviteGlobalAdminModal,
    setIsShowingInviteGlobalAdminModal,
  ] = useState(false);
  const openShowingInviteGlobalAdminModal = useCallback(() => {
    setIsShowingInviteGlobalAdminModal(true);
  }, [setIsShowingInviteGlobalAdminModal]);

  const hideShowingInviteGlobalAdminModal = useCallback(() => {
    setIsShowingInviteGlobalAdminModal(false);
  }, [setIsShowingInviteGlobalAdminModal]);

  return (
    <Page
      heading={
        <PageHeading
          title="Global Admins"
          actions={
            <Button onClick={openShowingInviteGlobalAdminModal} size="sm">
              <i className="pi pi-plus mr-3"></i>
              Invite Global Admin
            </Button>
          }
        />
      }
    >
      <Dialog
        visible={isShowingInviteGlobalAdminModal}
        onHide={hideShowingInviteGlobalAdminModal}
        style={{ width: "450px" }}
        header="Invite Global Admin"
        modal
        className="p-fluid"
      >
        <InviteGlobalAdminForm
          onSuccess={hideShowingInviteGlobalAdminModal}
          onCancel={hideShowingInviteGlobalAdminModal}
        />
      </Dialog>

      <SectionHeading title="Actions" className="mt-24" />
      <ul className="grid grid-cols-1 gap-6 laptop:grid-cols-2">
        <SyncBeverageAdvisors />
      </ul>
    </Page>
  );
};

export default GlobalAdminView;
