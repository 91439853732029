import React from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { compose } from "lodash/fp";
import {
  CashIcon,
  CreditCardIcon,
  LibraryIcon,
} from "@heroicons/react/outline";
import { withOnboardingComplete } from "@hoc/withOnboardingComplete";
import {
  withDistributor,
  WithDistributorInjectedProps,
} from "@hoc/WithDistributor/WithDistributor";
import { withAuthentication } from "../shared/auth";
import { SidebarLayoutWithLogout } from "@ui/Layouts/SidebarLayoutWithLogout";
import { DistributorTransactions } from "./DistributorTransactions/DistributorTransactions";
import { BankAccounts } from "../OrganizationApp/BankAccounts";
import { SidebarNavigationElement } from "@components/ui/SidebarNavigation/SidebarNavigation";
import { BillingPage } from "@components/BillingPage/BillingPage";

type DistributorAppProps = RouteComponentProps<{ distributorId: string }> &
  WithDistributorInjectedProps;

const getNavElements = (basePath: string): SidebarNavigationElement[] => [
  {
    path: `${basePath}/transactions`,
    name: "Transactions",
    icon: CashIcon,
  },
  {
    path: `${basePath}/bank-accounts`,
    name: "Bank Accounts",
    icon: LibraryIcon,
  },
  {
    path: `${basePath}/billing`,
    name: "Billing",
    icon: CreditCardIcon,
  },
];

export function DistributorAppBase({ distributor }: DistributorAppProps) {
  const businessEntityId = distributor.businesses[0].id;
  return (
    <SidebarLayoutWithLogout
      sidebarNavigationProps={{
        elements: getNavElements(`/distributor/${distributor.id}`),
      }}
      gray
      kind="indigo"
    >
      <Router>
        <Redirect from="/" to="transactions" />
        <DistributorTransactions
          path="transactions"
          businessEntityId={businessEntityId}
        />
        <BankAccounts
          path="bank-accounts"
          businessEntityId={businessEntityId}
        />
        <BillingPage path="billing" organizationId={distributor.id} />
        <Redirect from="*" to="/not-found" />
      </Router>
    </SidebarLayoutWithLogout>
  );
}

export const DistributorApp = compose(
  withAuthentication(),
  withDistributor,
  withOnboardingComplete<DistributorAppProps>({
    getBusinessIdFromProps: (props) => props.distributor.businesses[0].id,
  })
)(DistributorAppBase);

export default DistributorApp;
