import { ProducerInvoiceStatus } from "@apollo/ops";
import { Badge, BadgeProps, Color } from "@ui/Badge";

type ProducerInvoiceStatusBadgeProps = Partial<BadgeProps> & {
  status: ProducerInvoiceStatus;
};

export function ProducerInvoiceStatusBadge({
  status,
  ...rest
}: ProducerInvoiceStatusBadgeProps) {
  return Badge({ ...getProducerStatusBadgeProps(status), ...rest });
}

function getProducerStatusBadgeProps(status: ProducerInvoiceStatus) {
  switch (status) {
    case ProducerInvoiceStatus.Missing:
      return { label: "MISSING", color: "blue" as Color };
    case ProducerInvoiceStatus.Reconciled:
      return { label: "RECONCILED", color: "green" as Color };
    case ProducerInvoiceStatus.Unreconciled:
      return { label: "UNRECONCILED", color: "red" as Color };
  }
}
