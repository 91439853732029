import React from "react";
import { Button, ButtonProps } from "@ui/Button";
import { Dialog, useDialog, UseDialogResult } from "@ui/Dialog";
import {
  UpdateOfferWindowMutation,
  useUpdateOfferWindowMutation,
} from "@apollo/ops";
import { defaultErrorHandler } from "@utilities/toast";
import {
  UpdateOfferWindowForm,
  UpdateOfferWindowSubmitData,
} from "./UpdateOfferWindowForm";

type OfferWindowUpdateData = {
  id: number;
  start: Date;
  end: Date;
};

type UpdateOfferWindowButtonProps = {
  offerWindow: OfferWindowUpdateData;
  onUpdate?: (data: UpdateOfferWindowMutation) => void;
} & ButtonProps;

export function UpdateOfferWindowButton({
  offerWindow,
  onUpdate,
  ...buttonProps
}: UpdateOfferWindowButtonProps) {
  const dialog = useDialog();

  return (
    <>
      <Button onClick={dialog.open} {...buttonProps}>
        Update offer window
      </Button>
      <UpdateOfferWindowDialog
        dialog={dialog}
        onUpdate={onUpdate}
        offerWindow={offerWindow}
      />
    </>
  );
}

type UpdateOfferWindowDialogProps = {
  offerWindow: OfferWindowUpdateData;
  dialog: UseDialogResult;
  onUpdate?: (data: UpdateOfferWindowMutation) => void;
};

export function UpdateOfferWindowDialog({
  dialog,
  offerWindow,
  onUpdate,
}: UpdateOfferWindowDialogProps) {
  const { getDialogProps } = dialog;
  const [updateOfferWindow] = useUpdateOfferWindowMutation();

  const handleSubmit = async (formData: UpdateOfferWindowSubmitData) => {
    try {
      const result = await updateOfferWindow({
        variables: {
          id: offerWindow.id,
          data: formData,
        },
      });

      if (result.data) {
        dialog.close();
        onUpdate && onUpdate(result.data);
      }
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <Dialog {...getDialogProps()} title="New Offer Window" width="xl">
      <UpdateOfferWindowForm
        onSubmit={handleSubmit}
        defaultValues={mapOfferWindowToDefaultValues(offerWindow)}
      />
    </Dialog>
  );
}

function mapOfferWindowToDefaultValues({ start, end }: OfferWindowUpdateData) {
  return {
    start: new Date(start),
    end: new Date(end),
  };
}
