import React from "react";
import {
  OfferSearchFilters,
  ProductDisplayFragment,
  useOfferAutocompleteLazyQuery,
} from "@apollo/gen/generatedOperations";
import {
  BackendAutcompleteProps,
  BackendAutocomplete,
} from "@ui/Autocomplete/BackendAutocomplete";
import { AdminProductLink } from "@components/Product/Product";
import { trim } from "lodash";
import { ProductDisplayFragmentWithOfferId } from "@admin/AdminPreSalesContainer/AdminPreSaleRouter/AdminPreSalePage/AdminPreSaleOrders/AdminPreSaleOrderItemsPage/AdminPreSaleOrderItemsTable/AdminPreSaleOrderItemsEditTable";

interface OfferBackendAutoCompleteProps
  extends Omit<
    BackendAutcompleteProps<ProductDisplayFragmentWithOfferId>,
    "data" | "error" | "refetch" | "renderItem"
  > {
  filters?: Partial<OfferSearchFilters>;
}

export function OfferBackendAutocomplete({
  filters,
  ...props
}: OfferBackendAutoCompleteProps) {
  const [search, { data, error, previousData }] = useOfferAutocompleteLazyQuery(
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleQueryChange = ({ query }: { query: string }) => {
    search({
      variables: {
        filters: {
          ...filters,
          productName: trim(query),
        },
      },
    });
  };

  const results =
    (data ?? previousData)?.searchOffers.results.map((a) => ({
      ...a.product,
      offerId: a.id,
    })) ?? [];

  return (
    <BackendAutocomplete
      data={results}
      error={error}
      refetch={handleQueryChange}
      {...props}
      renderItem={renderItem}
    />
  );
}

const renderItem = (product: ProductDisplayFragment) => {
  return <AdminProductLink product={product} />;
};
