import React, { useState } from "react";
import {
  useAddTransferForOrderMutation,
  useAdminOrderPaymentsQuery,
} from "@apollo/ops";
import { FundsTransferTable } from "@components/FundsTransfer/FundsTransferTable";
import { toCents } from "@utilities/currency";
import { toastError } from "@utilities/toast";
import {
  OrderTransferModal,
  TransferMode,
  TransferSource,
} from "../OrderTransfer/OrderTransferModal";
import { OrderTransferFormData } from "../OrderTransfer/OrderTransferForm";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { Page } from "@components/ui/Page";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { LoadingPage } from "@components/LoadingPage";
import { ValidationResult } from "@components/ValidationResult/ValidationResult";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { Card, CardBody } from "@ui/Card/Card";
import { LedgerDetails } from "@admin/components/LedgerDetails";
import { LedgerStatusBadge } from "@components/LedgerStatusBadge";
import { SendAdjustmentsPayment } from "./SendAdjustmentsPayment/SendAdjustmentsPayment";
import { SendRetailerPayment } from "./SendRetailerPayment/SendRetailerPayment";
import { SendDistributorPayment } from "./SendDistributorPayment/SendDistributorPayment";

export function AdminOrderPaymentsPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [transferSource] = useState(TransferSource.BA);
  const [addPayment] = useAddTransferForOrderMutation();

  const { data, error, refetch } = useAdminOrderPaymentsQuery({
    variables: {
      orderId,
    },
  });

  const closePaymentModal = () => setIsPaymentModalOpen(false);

  const onPaymentSubmit = async (data: OrderTransferFormData) => {
    try {
      await addPayment({
        variables: {
          data: {
            sourceId: data.sourceFundsAccountId,
            destinationId: data.destinationFundsAccountId,
            amountInCents: toCents(data.amount),
            statementDescriptor:
              data.statementDescriptorPrefix + " " + data.statementDescriptor,
            breweryInvoiceNumber: data.breweryInvoiceNumber,
            orderId,
          },
        },
      });

      closePaymentModal();
      await refetch();
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  if (error) {
    return null;
  }

  if (data && orderSummary) {
    const { order } = data;

    return (
      <Page
        heading={<AdminOrderPageHeading page="Payments" order={orderSummary} />}
      >
        <OrderTransferModal
          isOpen={isPaymentModalOpen}
          onClose={closePaymentModal}
          onSubmit={onPaymentSubmit}
          orderId={order.id}
          orderNumber={data.order.number}
          transferSource={transferSource}
          transferMode={TransferMode.Payment}
        />
        <div>
          <Card
            headerProps={{
              title: "Payment balances",
              actions: (
                <LedgerStatusBadge
                  status={order.paymentsLedger.status}
                  size="lg"
                />
              ),
            }}
          >
            <CardBody>
              <LedgerDetails ledger={order.paymentsLedger} />
            </CardBody>
          </Card>
          {order.__typename === "RetailerSalesOrder" && (
            <div className="mt-6">
              <ValidationResult
                validationResult={order.canSendPayment}
                footer={
                  <div className="flex justify-end">
                    <SendRetailerPayment orderId={order.id} />
                  </div>
                }
              />
            </div>
          )}
          {order.__typename === "ProducerPurchaseOrder" &&
            order.distributorPaymentsLedger && (
              <div className="mt-12 space-y-6">
                <SectionHeading title="Distributor payments" />
                <Card
                  headerProps={{
                    title: "Distributor payment balances",
                    actions: (
                      <LedgerStatusBadge
                        status={order.distributorPaymentsLedger.status}
                        size="lg"
                      />
                    ),
                  }}
                >
                  <CardBody>
                    <LedgerDetails ledger={order.distributorPaymentsLedger} />
                  </CardBody>
                </Card>
                <ValidationResult
                  validationResult={order.canSendDistributorPayment}
                  footer={
                    <div className="flex justify-end">
                      <SendDistributorPayment orderId={order.id} />
                    </div>
                  }
                />
              </div>
            )}
          {order.__typename === "ProducerPurchaseOrder" && (
            <div className="mt-12 space-y-6">
              <SectionHeading title="Adjustment payments" />
              <Card
                headerProps={{
                  title: "Adjustment payment balances",
                  actions: (
                    <LedgerStatusBadge
                      status={order.adjustmentPaymentsLedger.status}
                      size="lg"
                    />
                  ),
                }}
              >
                <CardBody>
                  <LedgerDetails ledger={order.adjustmentPaymentsLedger} />
                </CardBody>
              </Card>
              <ValidationResult
                validationResult={order.canSendAdjustmentsPayment}
                footer={
                  <div className="flex justify-end">
                    <SendAdjustmentsPayment orderId={order.id} />
                  </div>
                }
              />
            </div>
          )}
          <div className="mt-12">
            <SectionHeading title="All payments" />
            <FundsTransferTable
              fundsTransfers={order.paymentsLedger.transfers}
            />
          </div>
        </div>
      </Page>
    );
  }

  return <LoadingPage />;
}
