import React from "react";
import { Link } from "@ui/Link";
import { adminRetailerPath } from "@routes";

type AdminRetailerLinkProps = {
  retailer: {
    id: number;
    displayName: string;
  };
};

export function AdminRetailerLink({ retailer }: AdminRetailerLinkProps) {
  return (
    <Link to={adminRetailerPath(retailer.id)}>{retailer.displayName}</Link>
  );
}
