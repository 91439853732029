import React, { useState } from "react";
import { UpdateLicense } from "./UpdateLicense";
import { Dialog } from "primereact/dialog";
import { AdminUpdateLicenseMutation } from "@apollo/ops";
import { PencilAltIcon } from "@heroicons/react/outline";

type UpdateLicenseModalProps = {
  onUpdate: (data: AdminUpdateLicenseMutation) => void;
  licenseId: number;
};

export function UpdateLicenseModal({
  licenseId,
  onUpdate,
}: UpdateLicenseModalProps) {
  const [visible, setVisible] = useState(false);

  const handleOnUpdate = (data: AdminUpdateLicenseMutation) => {
    onUpdate(data);
    setVisible(false);
  };

  return (
    <>
      <PencilAltIcon
        onClick={() => setVisible(true)}
        className="link"
        width={20}
      />
      <Dialog
        visible={visible}
        header="Edit License"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <UpdateLicense onUpdate={handleOnUpdate} licenseId={licenseId} />
      </Dialog>
    </>
  );
}
