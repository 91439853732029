import React, { useMemo } from "react";
import { Table } from "@components/ui/Table";
import { Column, TableInstance, useTable, useSortBy } from "react-table";
import { formatAsDateWithTimeFromString } from "@utilities/formatDate";
import { AuditLogDetailsFragment } from "@apollo/ops";

type AuditLogsTableProps = {
  logs: Array<AuditLogDetailsFragment>;
};

export function AuditLogsTable({ logs }: AuditLogsTableProps) {
  const columns = useMemo<Column<AuditLogDetailsFragment>[]>(
    () => [
      {
        Header: "Date",
        Cell: ({ value }) => formatAsDateWithTimeFromString(value),
        accessor: "occurredAt",
      },
      {
        Header: "Who",
        accessor: (auditLog) => auditLog.user?.email,
      },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Changes",
        Cell: ({ value }) => <pre>{JSON.stringify(value, null, 2)}</pre>,
        accessor: "changes",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: logs,
    },
    useSortBy
  ) as TableInstance<AuditLogDetailsFragment>;

  return <Table instance={tableInstance} />;
}
