import React, { ReactNode } from "react";

type PageContentProps = {
  children: ReactNode;
};

function PageContent({ children }: PageContentProps) {
  return (
    <div className="max-w-screen-2xl  mx-auto px-4 tablet:px-6 laptop:px-8">
      {children}
    </div>
  );
}

export interface SimplePageProps {
  children: ReactNode;
  heading?: ReactNode;
}

export function SimplePage({ heading, children }: SimplePageProps) {
  return (
    <div className="pb-6">
      {heading && (
        <header className="w-full py-4 mb-4">
          <PageContent>{heading}</PageContent>
        </header>
      )}
      <main>
        <PageContent>{children}</PageContent>
      </main>
    </div>
  );
}
