import currency from "currency.js";

export const formatAmountInCents = (
  amountInCents: number,
  options: { oppositeSign: boolean } = { oppositeSign: false }
) => {
  return (
    (options.oppositeSign ? "- " : "") +
    "$" +
    (amountInCents / 100)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

export const toCents = (dollars: string | number): number =>
  currency(dollars).intValue;

export const toDollars = (cents: string | number): number =>
  currency(cents, { fromCents: true }).value;

export const formatToDollars = ({
  cents,
  negative = false,
}: {
  cents: string | number;
  negative?: boolean;
}): string => {
  let amount = cents;
  if (negative) {
    if (typeof amount == "string") amount = `-${amount}`;
    if (typeof amount == "number") amount = Math.abs(amount) * -1;
  }
  return currency(amount, {
    fromCents: true,
    pattern: "!#",
    negativePattern: "!-(#)",
  }).format();
};
