import { useCallback } from "react";
import {
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  usePlaidLink,
} from "react-plaid-link";
import {
  PLAID_CLIENT_NAME,
  PLAID_ENVIRONMENT,
  PLAID_PRODUCTS,
} from "../../../config";
import {
  useRefreshPlaidItemMutation,
  RefreshPlaidItemMutation,
} from "@apollo/ops";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import Bugsnag from "@bugsnag/browser";

type PlaidItem = RefreshPlaidItemMutation["refreshPlaidItem"];

export type HandleUpdate = (plaidItem: PlaidItem) => void;

export interface UseUpdatePlaidItemOptions {
  plaidItemId: number;
  token: string;
  onUpdate?: (plaidItem: PlaidItem) => void;
}

export interface UseUpdatePlaidItem {
  isPlaidReady: boolean;
  isSaving: boolean;
  token: string;
}

export const useUpdatePlaidItem = ({
  plaidItemId,
  token,
  onUpdate,
}: UseUpdatePlaidItemOptions) => {
  const [refreshPlaidItem, { loading }] = useRefreshPlaidItemMutation({
    onCompleted: ({ refreshPlaidItem }) => {
      onUpdate && onUpdate(refreshPlaidItem);
    },
  });

  const handleSuccess = useCallback<PlaidLinkOnSuccess>(() => {
    refreshPlaidItem({
      variables: {
        id: plaidItemId,
      },
    }).catch((error) => {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: "",
        detail: error.message,
      });
    });
  }, [refreshPlaidItem, plaidItemId]);

  const handleExit = useCallback<PlaidLinkOnExit>((plaidError, metadata) => {
    if (plaidError) {
      Bugsnag.notify(
        new Error("Error encountered in onExit from Plaid Link"),
        (event) => {
          event.addMetadata("plaidError", plaidError);
          event.addMetadata("plaidMetadata", metadata);
        }
      );

      toast.current?.show({
        severity: MessageSeverity.error,
        summary: "",
        detail: plaidError.error_message,
      });
    }
  }, []);

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: handleSuccess,
    onExit: handleExit,
    env: PLAID_ENVIRONMENT,
    product: PLAID_PRODUCTS,
    clientName: PLAID_CLIENT_NAME,
  });

  return {
    isPlaidReady: ready,
    isSaving: loading,
    openPlaid: open,
  };
};
