import React from "react";
import { Controller, NestedValue, useForm } from "react-hook-form";
import { omit } from "lodash";
import { FileGenerator, FileInput } from "@components/FileUpload/FileInput";
import { Button } from "@components/ui/Button";
import { LabeledInput } from "@components/ui/Input";
import { Textarea } from "@components/ui/Textarea";
import { Dropdown } from "@ui/Dropdown";
import { DocumentType } from "@apollo/gen/generatedOperations";
import classNames from "classnames";
import startCase from "lodash/startCase";

type OrderDocumentFormData = {
  remoteFiles: NestedValue<Array<FileGenerator>>;
  name: string;
  notes: string | null;
  documentType: DocumentType;
};

export type OrderDocumentSubmitData = {
  remoteFileId: number;
  name: string;
  notes: string | null;
  documentType: DocumentType;
};

type OrderDocumentFormProps = {
  onSubmit: (data: OrderDocumentSubmitData) => unknown;
};

export function OrderDocumentForm({ onSubmit }: OrderDocumentFormProps) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<OrderDocumentFormData>({
    mode: "onSubmit",
    defaultValues: {
      remoteFiles: [],
      name: "",
    },
  });

  const wrappedOnSubmit = handleSubmit(async (formData) => {
    const remoteFileId = await formData.remoteFiles[0].getFileId();
    await onSubmit({
      remoteFileId,
      ...omit(formData, "remoteFiles"),
    });
  });

  return (
    <form onSubmit={wrappedOnSubmit}>
      <div className="grid grid-cols-1 gap-3">
        <div>
          <Controller
            name="remoteFiles"
            control={control}
            defaultValue={false}
            rules={{
              validate: {
                exactlyOneFile: (files) =>
                  files.length === 1 || "One file is required",
              },
            }}
            render={({ field }) => (
              <FileInput
                label="Document"
                error={errors.remoteFiles?.message}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
        </div>
        <div>
          <LabeledInput
            label="Name"
            inputProps={{
              type: "text",
              error: errors.name?.message,
              ...register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              }),
            }}
          />
        </div>
        <div>
          <Controller
            name="documentType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Document Type is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                options={Object.values(
                  omit(DocumentType, "ProofOfDelivery") // we have a specific POD input
                ).map((documentTypeValue) => ({
                  label: startCase(documentTypeValue),
                  value: documentTypeValue,
                }))}
                name={"containerVolumeUnit"}
                onChange={onChange}
                optionLabel={"label"}
                optionValue={"value"}
                value={value}
                fieldLabel={"Document Type"}
                dataKey={"value"}
                error={errors.documentType}
                className={classNames({
                  "border-red-300": errors.documentType,
                  "border-1": errors.documentType,
                  "text-red-600": errors.documentType,
                })}
              />
            )}
          />
        </div>
        <div>
          <Textarea
            label="Notes"
            error={errors.notes?.message}
            {...register("notes")}
          />
        </div>
        <div>
          <Button type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
}
