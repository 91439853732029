import { DeliveryStatus } from "@apollo/ops";
import { Badge, BadgeProps, Color } from "@ui/Badge";

type DeliveryStatusBadgeProps = Partial<BadgeProps> & {
  status: DeliveryStatus;
};

export function DeliveryStatusBadge({
  status,
  ...rest
}: DeliveryStatusBadgeProps) {
  return Badge({ ...getDeliveryStatusBadgeProps(status), ...rest });
}

function getDeliveryStatusBadgeProps(
  status: DeliveryStatus
): { label: string; color: Color } {
  switch (status) {
    case DeliveryStatus.NotDelivered:
      return { label: "NOT DELIVERED", color: "gray" as Color };
    case DeliveryStatus.Delivered:
      return { label: "DELIVERED", color: "green" as Color };
  }
}
