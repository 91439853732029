import React from "react";
import { RouteComponentProps } from "@reach/router";
import { SimplePage, PageHeading } from "@components/ui/Page";

export function NotFoundPage(_: RouteComponentProps) {
  return (
    <SimplePage heading={<PageHeading title="Not Found" />}>
      <div className="-mt-4">There&apos;s no page at this URL.</div>
    </SimplePage>
  );
}
