type Nullish<V extends unknown> = V extends null
  ? NonNullable<V> | undefined
  : V;

type NullToUndefinedObj<T extends Record<string, unknown>> = {
  [K in keyof T]: Nullish<T[K]>;
};

export function convertNullToUndefined<Orig extends Record<string, unknown>>(
  obj: Orig | null
): NullToUndefinedObj<Orig> | undefined {
  if (!obj) {
    return undefined;
  }

  const undefinedEntries = Object.entries(obj).map(([key, value]) => {
    if (value === null) {
      return [key, undefined];
    }

    return [key, value];
  });

  return Object.fromEntries(undefinedEntries);
}
