import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useAdminPreSaleOrdersQuery } from "@apollo/ops";
import { Page } from "@ui/Page";
import { PreSaleOrdersTable } from "./PreSaleOrdersTable";
import { AdminPreSalePageHeading } from "../../AdminPreSalePageHeading";
import { LoadingPage } from "@components/LoadingPage";
import { AddPreSaleOrderButton } from "./AddPreSaleOrder/AddPreSaleOrderButton";

type AdminPreSaleOrdersPageProps = RouteComponentProps<{
  preSaleIdParam: string;
}>;

/**
 *
 * Component to show the orders made in a PreSale.
 */
export function AdminPreSaleOrdersPage({
  preSaleIdParam,
}: AdminPreSaleOrdersPageProps) {
  const { data, error } = useAdminPreSaleOrdersQuery({
    variables: {
      id: Number(preSaleIdParam),
    },
  });

  if (error) {
    throw new Error();
  }

  if (data) {
    const { preSale } = data;

    return (
      <Page
        heading={
          <AdminPreSalePageHeading
            preSale={preSale}
            page="Orders"
            actions={<AddPreSaleOrderButton preSaleId={preSale.id} />}
          />
        }
      >
        <PreSaleOrdersTable data={preSale.orders} preSaleId={preSale.id} />
      </Page>
    );
  }

  return <LoadingPage />;
}
