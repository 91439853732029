import React from "react";
import { OrderType, useAdminOrderItemsQuery } from "@apollo/ops";
import { AdminOrderItemsTable } from "./AdminOrderItemsTable/AdminOrderItemsTable";
import { AdminAdjustmentItemsTable } from "./AdminAdjustmentItemsTable/AdminAdjustmentItemsTable";
import { Page } from "@components/ui/Page";
import { LoadingPage } from "@components/LoadingPage";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { AdminOrderItemsTotals } from "./AdminOrderItemsTotals";
import { DIRECT_BEER_API_URI } from "src/config";
import { onExportFileClick } from "@util/files";
import { Button } from "@components/ui/Button";

export function AdminOrderItemsPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const { data, error } = useAdminOrderItemsQuery({
    variables: {
      orderId: orderId,
    },
  });

  if (error) {
    return null;
  }

  if (data && orderSummary) {
    const { order } = data;
    const { items, type, id, adjustmentItems } = order;

    const onGenerateLabelsPdfClick = () => {
      const pdfUrl = `${DIRECT_BEER_API_URI}order/${orderId}/pdf?labels=true`;
      const fileName = `${data.order.number}-Labels.pdf`;

      onExportFileClick({ url: pdfUrl, fileName });
    };

    /**
     * We want to show wholesale pricing and totals for producer purchase orders
     */
    const displayWholesalePricing = type == OrderType.ProducerPurchaseOrder;

    let filterProducerId = undefined;
    switch (orderSummary.__typename) {
      case "ProducerPurchaseOrder":
        filterProducerId = orderSummary.seller.id;
        break;
      case "DistributorPurchaseOrder":
      case "RetailerSalesOrder":
        filterProducerId = orderSummary.producerPurchaseOrder.seller.id;
        break;
    }
    return (
      <Page
        heading={
          <AdminOrderPageHeading
            page="Items"
            order={orderSummary}
            actions={
              order.__typename === "ProducerPurchaseOrder" && (
                <Button onClick={() => onGenerateLabelsPdfClick()} size="sm">
                  Download Labels
                </Button>
              )
            }
          />
        }
      >
        <div>
          <AdminOrderItemsTable
            orderId={id}
            orderItems={items}
            editable={order.enabledFeatures.canEditOrderItems}
            displayWholesalePricing={displayWholesalePricing}
            canDelete={
              order.enabledFeatures.canDeleteOrderItems &&
              order.type === OrderType.ProducerPurchaseOrder
            }
            orderType={order.type}
            filterProducerId={filterProducerId}
          />

          <div className="mt-8 flex justify-end px-6">
            <AdminOrderItemsTotals order={order} />
          </div>
        </div>

        {order.enabledFeatures.canViewAdjustments && (
          <div className="mt-8">
            <AdminAdjustmentItemsTable
              orderId={order.id}
              adjustmentItems={adjustmentItems}
              orderItems={items}
              displayWholesalePricing={displayWholesalePricing}
              canEdit={order.enabledFeatures.canEditAdjustmentItems}
            />
          </div>
        )}
      </Page>
    );
  }

  return <LoadingPage />;
}
