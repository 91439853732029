import React from "react";
import {
  DwollaCustomerBillingInfoFragment,
  useUpdateDwollaCustomerMutation,
} from "@apollo/ops";
import { Dialog, DialogProps } from "@ui/Dialog";
import {
  DwollaCustomerForm,
  UpdateDwollaCustomerFormData,
} from "../DwollaCustomerForm";
import { toast } from "@util/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@util/i18n";

type UpdateDwollaCustomerDialogProps = {
  dwollaCustomer: DwollaCustomerBillingInfoFragment;
  dialogProps: Omit<DialogProps, "children">;
};

export function UpdateDwollaCustomerDialog({
  dwollaCustomer,
  dialogProps,
}: UpdateDwollaCustomerDialogProps) {
  const [updateDwollaCustomerMutation] = useUpdateDwollaCustomerMutation();

  const handleSubmit = async ({ id, email }: UpdateDwollaCustomerFormData) => {
    try {
      const result = await updateDwollaCustomerMutation({
        variables: {
          data: {
            dwollaCustomerId: id,
            email,
          },
        },
      });
      if (result.data) {
        dialogProps.onClose();

        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Updated Customer ${email}`,
        });
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <Dialog {...dialogProps} title={`Update Billing Info`} width="lg">
      <DwollaCustomerForm
        onSubmit={handleSubmit}
        dwollaCustomer={dwollaCustomer}
      />
    </Dialog>
  );
}
