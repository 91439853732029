import React from "react";
import { Button } from "@ui/Button";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { useCancelProducerPurchaseOrderMutation } from "@apollo/gen/generatedOperations";

type CancelSalesOrderButtonProps = {
  orderId: number;
  canCancel: boolean;
};

export function CancelProducerPurchaseOrderButton({
  orderId,
  canCancel,
}: CancelSalesOrderButtonProps) {
  const [cancelOrder, { loading }] = useCancelProducerPurchaseOrderMutation();
  const dialog = useDialog();

  const onConfirm = async () => {
    await cancelOrder({
      variables: {
        orderId,
      },
    });
  };

  return (
    <>
      <Button
        destructive
        kind="secondary"
        size="sm"
        onClick={dialog.open}
        disabled={!canCancel || loading}
      >
        Cancel
      </Button>
      <ConfirmDialog
        title="Are you sure you want to cancel this order?"
        onConfirm={onConfirm}
        confirmText="Yes, cancel this order"
        dialog={dialog}
      />
    </>
  );
}
