import React from "react";
import {
  AdminDistributorsDocument,
  useAdminAddOrganizationAndDistributorMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { DistributorForm, DistributorFormData } from "./DistributorForm";
import { toCents } from "@utilities/currency";

type AddDistributorProps = {
  onAdd?: () => void;
};

export function AddDistributor({ onAdd }: AddDistributorProps) {
  const [addDistributor] = useAdminAddOrganizationAndDistributorMutation({
    refetchQueries: [
      {
        query: AdminDistributorsDocument,
      },
    ],
  });

  const handleSubmit = async ({
    name,
    feePerPackageInDollars,
  }: DistributorFormData) => {
    try {
      const result = await addDistributor({
        variables: {
          data: {
            name,
            feePerPackageInCents: toCents(feePerPackageInDollars),
          },
        },
      });

      if (result.data && onAdd) {
        onAdd();
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Add distributor ${name}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <DistributorForm
      submitText="Add"
      onSubmit={handleSubmit}
      enableSetFee={true}
    />
  );
}
