import React, { useMemo } from "react";
import { AdminRetailerDetailsFragment } from "@apollo/ops";
import { Table } from "@ui/Table";
import {
  Column,
  TableInstance,
  useTable,
  useFlexLayout,
  CellProps,
} from "react-table";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { AdminRetailerLink } from "@components/Retailer/Retailer";

type AdminRetailersTableProps = {
  data: Array<AdminRetailerDetailsFragment>;
  pagination?: UseOffsetPaginationResult;
};

export function AdminRetailersTable({
  data,
  pagination,
}: AdminRetailersTableProps) {
  const columns: Array<Column<AdminRetailerDetailsFragment>> = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 20,
      },
      {
        Header: "Name",
        Cell: ({ row }: CellProps<AdminRetailerDetailsFragment, "string">) => (
          <AdminRetailerLink retailer={row.original} />
        ),
        accessor: "displayName",
        width: 150,
      },
      {
        Header: "Region",
        Cell: ({ value }) => value.friendlyName,
        accessor: "regionV2",
        width: 60,
      },
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  ) as TableInstance<AdminRetailerDetailsFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
