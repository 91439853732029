import React from "react";
import { useAdminOrderAuditLogsQuery } from "@apollo/ops";
import { AuditLogsTable } from "@components/AuditLog/AuditLogTable";
import { LoadingPage } from "@components/LoadingPage";
import { Page } from "@ui/Page";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";

export function AdminOrderHistoryPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const { data, error } = useAdminOrderAuditLogsQuery({
    variables: {
      orderId,
    },
  });

  if (error) {
    return null;
  }

  if (data && orderSummary) {
    return (
      <Page
        heading={
          <AdminOrderPageHeading page="Audit History" order={orderSummary} />
        }
      >
        <AuditLogsTable logs={data.order.auditLogs} />
      </Page>
    );
  }

  return <LoadingPage />;
}
