import React from "react";
import { useAdminOrderRetailerOrdersQuery } from "@apollo/ops";
import { Page } from "@ui/Page";
import { AdminOrderPageHeading } from "../AdminOrderPageHeading";
import { AdminOrderRouteProps } from "../AdminOrderRouteProps";
import { AdminRetailerOrdersTable } from "./AdminOrderRetailerOrdersTable";
import { LoadingPage } from "@components/LoadingPage";
import { CreateChildRetailerOrderButton } from "@admin/AdminOrdersContainer/AdminOrderRouter/AdminOrderRetailerOrdersPage/CreateChildRetailerOrder/CreateChildRetailerOrder";

export function AdminOrderRetailerOrdersPage({
  orderId,
  orderSummary,
}: AdminOrderRouteProps) {
  const { data, error } = useAdminOrderRetailerOrdersQuery({
    variables: {
      orderId,
    },
  });

  if (error) {
    return null;
  }

  if (data && orderSummary) {
    if (
      data.order.__typename === "DistributorPurchaseOrder" ||
      data.order.__typename === "ProducerPurchaseOrder"
    ) {
      let sellerId = 0;
      switch (orderSummary.__typename) {
        case "DistributorPurchaseOrder":
          sellerId = orderSummary.producerPurchaseOrder.seller.id;
          break;
        case "ProducerPurchaseOrder":
          sellerId = orderSummary.seller.id;
          break;
      }
      const actions = orderSummary.enabledFeatures
        .canCreateChildRetailSaleOrder && (
        <CreateChildRetailerOrderButton
          purchaseOrderId={data.order.id}
          sellerId={sellerId}
        />
      );
      return (
        <Page
          heading={
            <AdminOrderPageHeading
              page="Retailer Orders"
              order={orderSummary}
              actions={actions}
            />
          }
        >
          <AdminRetailerOrdersTable data={data.order.retailerSalesOrders} />
        </Page>
      );
    } else {
      throw new Error("Invalid order type");
    }
  }

  return <LoadingPage />;
}
