import React, {
  useRef,
  InputHTMLAttributes,
  forwardRef,
  ReactNode,
} from "react";
import { uniqueId } from "lodash";
import ctl from "@netlify/classnames-template-literals";
import { inputStateClasses } from "./Input";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  description?: string;
  error?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, description, error, ...inputProps }, ref) => {
    const id = useRef(uniqueId()).current;

    return (
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            ref={ref}
            type="checkbox"
            className={checkboxClasses({
              hasError: Boolean(error),
              readOnly: Boolean(inputProps.readOnly),
            })}
            {...inputProps}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {label}
          </label>
          {description && <p className="text-gray-500">{description}</p>}
          <div className="mt-1">
            {error && (
              <p role="alert" className="text-sm text-red-600">
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

type CheckboxClassesOptions = {
  hasError?: boolean;
  readOnly?: boolean;
};

const checkboxClasses = ({
  hasError = false,
  readOnly = false,
}: CheckboxClassesOptions) =>
  ctl(`
    focus:ring-brand-500
    h-4
    w-4
    text-brand-600
    border-gray-300
    rounded
    ${inputStateClasses({ hasError })}
    ${readOnly && "bg-gray-50"}
  `);
