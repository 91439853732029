import React from "react";
import {
  TransferProposalDetailsFragment,
  LedgerDetailsFragment,
  ValidationResultFragment,
} from "@apollo/ops";
import { Button } from "@ui/Button";
import { formatToDollars } from "@util/currency";
import { defaultErrorHandler } from "@utilities/toast";
import { Dialog, useDialog, UseDialogResult } from "@components/ui/Dialog";
import { Well } from "@components/ui/Well";
import { LedgerDetails } from "@admin/components/LedgerDetails";

type ConfirmTransferFormProps = {
  onSubmit: () => void;
  onSuccess?: () => void;
  transferProposal: TransferProposalDetailsFragment;
  ledger: LedgerDetailsFragment;
  validationResult: ValidationResultFragment;
};

function ConfirmTransferForm({
  onSubmit,
  onSuccess,
  transferProposal,
  ledger,
}: ConfirmTransferFormProps) {
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      await onSubmit();
      onSuccess && onSuccess();
    } catch (e) {
      defaultErrorHandler(e);
    }
  };

  return (
    <div>
      <div className="space-y-5">
        <Well>
          <LedgerDetails ledger={ledger} />
        </Well>
        <div className="grid grid-cols-2">
          <div>
            <div className="font-bold text-xs mb-1">From</div>
            <div>{transferProposal.source.business.displayName}</div>
            <div>{transferProposal.source.label}</div>
          </div>
          <div>
            <div className="font-bold text-xs">To</div>
            <div>{transferProposal.destination.business.displayName}</div>
            <div>{transferProposal.destination.label}</div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mt-5">
        <Button size="xl">
          Send{" "}
          {formatToDollars({
            cents: transferProposal.amount,
          })}
        </Button>
      </form>
    </div>
  );
}

type ConfirmTransferDialogProps = ConfirmTransferFormProps & {
  label: string;
  dialog: UseDialogResult;
};

export function ConfirmTransferDialog({
  dialog,
  label,
  ...rest
}: ConfirmTransferDialogProps) {
  const { getDialogProps } = dialog;

  return (
    <Dialog {...getDialogProps()} title={label} width="2xl">
      <ConfirmTransferForm {...rest} />
    </Dialog>
  );
}

type ConfirmTransferProps = Omit<
  ConfirmTransferFormProps,
  "transferProposal"
> & {
  label: string;
  transferProposal: TransferProposalDetailsFragment | null;
};

export function ConfirmTransfer({
  label,
  onSuccess,
  transferProposal,
  ...rest
}: ConfirmTransferProps) {
  const dialog = useDialog();

  const wrappedOnSuccess = () => {
    dialog.close();
    onSuccess && onSuccess();
  };

  return (
    <>
      {transferProposal !== null && (
        <ConfirmTransferDialog
          {...rest}
          transferProposal={transferProposal}
          dialog={dialog}
          label={label}
          onSuccess={wrappedOnSuccess}
        />
      )}
      <Button onClick={dialog.open} disabled={!rest.validationResult.isValid}>
        {label}
      </Button>
    </>
  );
}
