import React from "react";
import { useProducerSalesOrderUnitsFulfilledQuery } from "@apollo/gen/generatedOperations";
import { LoadingIcon } from "@ui/Spinner";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { reduce, uniq } from "lodash";
import { formatAsDate } from "@util/formatDate";
import { chartBarColors } from "../ProducerPreSaleUnitsSold";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function ProducerSalesOrderUnitsFulfilled({
  producerId,
}: {
  producerId: number;
}) {
  const { data, error } = useProducerSalesOrderUnitsFulfilledQuery({
    variables: { producerId },
  });

  if (error) throw error;

  if (data) {
    const formattedData = reduce(
      data.producerSalesOrderUnitsFulfilled,
      (
        acc: {
          label: string;
          backgroundColor: string;
          data: { x: string; y: number }[];
        }[],
        value
      ) => {
        const currentRegionIndex = acc.findIndex(
          (d) => d.label === value.friendlyName
        );
        const exists = currentRegionIndex > -1;

        const dataToAdd = { x: formatAsDate(value.date), y: value.units };
        if (exists) {
          acc[currentRegionIndex].data.push(dataToAdd);
        } else {
          acc = [
            ...acc,
            {
              label: value.friendlyName,
              backgroundColor: chartBarColors[acc.length],
              data: [dataToAdd],
            },
          ];
        }

        return acc;
      },
      []
    );

    const labels = uniq(
      data.producerSalesOrderUnitsFulfilled.map((a) => formatAsDate(a.date))
    );

    const options = {
      plugins: {
        title: {
          display: true,
          text: "Sales Order Units Fulfilled",
        },
        tooltip: {
          callbacks: {
            label: function (context: TooltipItem<"bar">) {
              return `${context.dataset.label}: ${context.formattedValue} units`;
            },
          },
        },
      },
      responsive: true,
      interaction: {
        intersect: false,
      },
      maintainAspectRatio: false,
    };

    return formattedData.length ? (
      <Bar
        datasetIdKey="label"
        options={options}
        data={{
          labels,
          datasets: formattedData,
        }}
      />
    ) : (
      <>No Sales Order data yet</>
    );
  }
  return <LoadingIcon />;
}
