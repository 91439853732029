import React from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from "react-error-boundary";
import Bugsnag from "@bugsnag/browser";
import { isRedirect } from "@reach/router";
import { ErrorAlert } from "@ui/Alert";
import { getMessageToUserFromBackendError } from "@utilities/i18n";

export const ErrorFallback = ({ error }: FallbackProps) => {
  const backendErrorMessage = getMessageToUserFromBackendError(error);

  return (
    <ErrorAlert title="An error occurred" description={backendErrorMessage} />
  );
};

type ErrorBoundaryProps = {
  children: JSX.Element;
  fallbackComponent?: (props: FallbackProps) => JSX.Element;
};

export function ErrorBoundary({
  children,
  fallbackComponent = ErrorFallback,
}: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary
      FallbackComponent={fallbackComponent}
      onError={(error: Error) => {
        // @reach/router users errors to redirect, if it is a redirect error
        // rethrow it as it'll be handled by a parent Router
        if (isRedirect(error)) {
          throw error;
        }

        Bugsnag.notify(error);
        console.error(error);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
