import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  FundsAccountType,
  Maybe,
  useAdminOrderTransferQuery,
  useBeverageAdvisorsQuery,
} from "@apollo/ops";
import { LoadingIcon } from "@ui/Spinner";
import { LabeledInput } from "@components/ui/Input";
import { AmountInput } from "@components/Input/AmountInput";
import { Button } from "@components/ui/Button";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { formatToDollars, toDollars } from "@utilities/currency";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";

const requiredRule = {
  value: true,
  message: "This field is required.",
};

export interface OrderTransferFormData {
  sourceFundsAccountId: number;
  destinationFundsAccountId: number;
  destinationAccountType: FundsAccountType;
  amount: number;
  statementDescriptorPrefix: string;
  statementDescriptor: string;
  breweryInvoiceNumber: string;
  producerMarketingFee?: number;
}

export enum TransferSource {
  Seller = "SELLER",
  BA = "BA",
  Buyer = "BUYER",
}

export enum TransferMode {
  Payment = "PAYMENT",
  Balance = "BALANCE",
}

interface Props {
  onSubmit: (data: OrderTransferFormData) => void | Promise<void>;
  orderId: number;
  transferSource: TransferSource;
  transferDestination?: TransferSource;
  transferMode: TransferMode;
  paymentToBa?: boolean;
  defaultValues?: Partial<OrderTransferFormData>;
}

const formSchema = Joi.object<OrderTransferFormData>({
  sourceFundsAccountId: Joi.number().required(),
  destinationFundsAccountId: Joi.number().required(),
  destinationAccountType: Joi.string().required(),
  amount: Joi.number().required(),
  statementDescriptorPrefix: Joi.string().allow(""),
  statementDescriptor: Joi.string().allow(""),
  breweryInvoiceNumber: Joi.string().required(),
});

export const OrderTransferForm: FC<Props> = ({
  onSubmit,
  orderId,
  transferSource,
  transferMode,
  paymentToBa = false,
  defaultValues = {
    amount: 0,
    statementDescriptor: "",
  },
}) => {
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, errors, isValidating },
    register,
    reset,
    control,
  } = useForm<OrderTransferFormData>({
    mode: "onSubmit",
    resolver: joiResolver(formSchema),
    defaultValues,
  });

  const wrappedOnSubmit = async (data: OrderTransferFormData) => {
    try {
      await onSubmit(data);
      reset(defaultValues);
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: getMessageToUserFromBackendError(error),
        });
      }
    }
  };

  const setFormattedAmountInput = (amountInCents: number) => {
    setValue("amount", toDollars(amountInCents));
  };

  const { data: beverageAdvisorsData } = useBeverageAdvisorsQuery();
  const { data, loading: loadingFetchOrder } = useAdminOrderTransferQuery({
    variables: {
      orderId,
    },
  });

  if (!data && !beverageAdvisorsData && loadingFetchOrder) {
    return <LoadingIcon />;
  }

  if (data && beverageAdvisorsData) {
    const { order } = data;
    const { beverageAdvisors } = beverageAdvisorsData;

    const payer =
      paymentToBa || transferMode === TransferMode.Balance
        ? order.seller
        : transferSource === TransferSource.BA
        ? beverageAdvisors
        : order.buyer;

    const payee = paymentToBa ? beverageAdvisors : order.seller;

    // Sometimes we need to charge the producer for a fee.
    // In this case, paymentToBa will be true and the "from" field will be the seller.
    const fromName =
      paymentToBa || transferSource !== TransferSource.BA
        ? payer.displayName
        : "Beverage Advisors";

    // Sometimes we need to charge the producer for a fee.
    // In this case, paymentToBa will be true and the "to" field will be us.
    const toName = paymentToBa
      ? "Beverage Advisors"
      : transferMode === TransferMode.Payment
      ? payee.displayName
      : fromName;

    let statementDescriptorPrefix = "";
    let purchaseOrderInvoice: {
      externalInvoiceId: string;
      externalAmountInCents: Maybe<number>;
    } | null = null;

    // The logic for determining what the statement descriptor prefix should
    // be moved to the backend.
    switch (order.__typename) {
      case "ProducerPurchaseOrder": {
        assertOrderHasExternalInvoiceId(order);
        purchaseOrderInvoice = order.invoice;
        setValue(
          "breweryInvoiceNumber",
          purchaseOrderInvoice.externalInvoiceId
        );
        statementDescriptorPrefix = purchaseOrderInvoice.externalInvoiceId;
        break;
      }
      case "RetailerSalesOrder":
      case "DistributorPurchaseOrder": {
        assertOrderHasExternalInvoiceId(order.producerPurchaseOrder);
        purchaseOrderInvoice = order.producerPurchaseOrder.invoice;
        setValue(
          "breweryInvoiceNumber",
          purchaseOrderInvoice.externalInvoiceId
        );
        statementDescriptorPrefix = `${purchaseOrderInvoice.externalInvoiceId}::${order.number}`;
        break;
      }
      default:
        break;
    }

    setValue("statementDescriptorPrefix", statementDescriptorPrefix);

    // Balance Transfers will always be from the buyer's balance account to their bank account
    if (transferMode === TransferMode.Balance) {
      setValue("destinationAccountType", FundsAccountType.Bank);
      payer.validBalanceAccount &&
        setValue("sourceFundsAccountId", payer.validBalanceAccount.id);
      payer.validBankAccount &&
        setValue("destinationFundsAccountId", payer.validBankAccount.id);
    }

    return (
      <form
        data-testid="OrderTransferForm"
        onSubmit={handleSubmit(wrappedOnSubmit)}
        className="grid grid-cols-2 grid-rows-3 gap-x-5 mt-10"
      >
        <div className="tablet:col-span-1 mb-3">
          <label
            htmlFor="transferFrom"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            From
          </label>
          <div className="mb-2">{fromName}</div>
          <Controller
            name="sourceFundsAccountId"
            control={control}
            rules={{
              required: requiredRule,
            }}
            render={({ field: { onChange } }) => (
              <div>
                {transferMode === TransferMode.Payment ? (
                  <div className="space-y-2">
                    {payer.validBankAccount ? (
                      <div>
                        <input
                          type="radio"
                          name="fromAccount"
                          value="buyerBankAccount"
                          checked={
                            getValues("sourceFundsAccountId") ===
                            payer.validBankAccount?.id
                          }
                          onChange={() => onChange(payer.validBankAccount?.id)}
                        />
                        <div className="inline-block align-middle ml-5">
                          <div className="font-bold text-sm">Bank Account</div>
                          <div>
                            {payer.validBankAccount?.name || "Account"} ending
                            in {payer.validBankAccount?.mask}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {payer.validBalanceAccount && !paymentToBa ? (
                      <div>
                        <input
                          type="radio"
                          name="fromAccount"
                          value="buyerBalanceAccount"
                          checked={
                            getValues("sourceFundsAccountId") ===
                            payer.validBalanceAccount?.id
                          }
                          onChange={() =>
                            onChange(payer.validBalanceAccount?.id)
                          }
                        />
                        <div className="inline-block align-middle ml-5">
                          <div className="font-bold text-sm">
                            Balance Account
                          </div>
                          <div>
                            Balance{" "}
                            {formatToDollars({
                              cents: payer.validBalanceAccount?.balance || 0,
                            })}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="space-y-2">
                    {payer.validBalanceAccount && !paymentToBa ? (
                      <div>
                        <div className="font-bold text-sm">Balance Account</div>
                        <div>
                          Balance{" "}
                          {formatToDollars({
                            cents: payer.validBalanceAccount.balance
                              ? payer.validBalanceAccount.balance
                              : 0,
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="font-bold text-red-600">
                        No Balance Account Found
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          />
          {errors.sourceFundsAccountId && (
            <small className="text-sm text-red-600">
              A source account is required
            </small>
          )}
        </div>

        <div className="tablet:col-span-1">
          <label
            htmlFor="orderSummary"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Order Summary
          </label>
          <div className="grid grid-cols-3 gap-1">
            <div className="col-span-2">
              Order Total
              <Button
                kind="tertiary"
                type="button"
                onClick={() => setFormattedAmountInput(order.amountInCents)}
                className="p-0 ml-1"
              >
                (fill)
              </Button>
            </div>
            <div className="text-right">
              {formatToDollars({ cents: order.amountInCents })}
            </div>
            <div className="col-span-2">
              Adjustment Total
              <Button
                kind="tertiary"
                type="button"
                onClick={() =>
                  setFormattedAmountInput(order.adjustmentTotalInCents)
                }
                className="p-0 ml-1"
              >
                (fill)
              </Button>
            </div>
            <div className="text-right">
              {formatToDollars({
                cents: order.adjustmentTotalInCents,
                negative: true,
              })}
            </div>
            <div className="border-t-2 border-gray-400 col-span-3 pb-2" />
            <div className="col-span-2">
              Final Order Total
              <Button
                kind="tertiary"
                type="button"
                onClick={() =>
                  setFormattedAmountInput(
                    order.amountInCents - order.adjustmentTotalInCents
                  )
                }
                className="p-0 ml-1"
              >
                (fill)
              </Button>
            </div>
            <div className="text-right">
              {formatToDollars({
                cents: order.amountInCents - order.adjustmentTotalInCents,
              })}
            </div>
            {order.__typename === "ProducerPurchaseOrder" ? (
              <>
                <div className="col-span-2">
                  Platform Fee
                  <Button
                    kind="tertiary"
                    type="button"
                    onClick={() =>
                      setFormattedAmountInput(order.platformFee ?? 0)
                    }
                    className="p-0 ml-1"
                  >
                    (fill)
                  </Button>
                </div>
                <div className="text-right">
                  {formatToDollars({
                    cents: order.platformFee,
                  })}
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="tablet:col-span-1 mb-3">
          <label
            htmlFor="transferTo"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            To
          </label>
          <div className="mb-2">{toName}</div>
          <Controller
            name="destinationFundsAccountId"
            control={control}
            rules={{
              required: requiredRule,
            }}
            render={({ field: { onChange } }) => (
              <div>
                {transferMode === TransferMode.Payment ? (
                  <div className="space-y-2">
                    {payee.validBankAccount ? (
                      <div>
                        <input
                          type="radio"
                          name="toAccount"
                          value="sellerBankAccount"
                          checked={
                            getValues("destinationFundsAccountId") ===
                            payee.validBankAccount?.id
                          }
                          onChange={() => {
                            setValue(
                              "destinationAccountType",
                              FundsAccountType.Bank
                            );
                            onChange(payee.validBankAccount?.id);
                          }}
                        />
                        <div className="inline-block align-middle ml-5">
                          <div className="font-bold text-sm">Bank Account</div>
                          <div>
                            {payee.validBankAccount?.name || "Account"} ending
                            in {payee.validBankAccount?.mask}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {payee.validBalanceAccount && !paymentToBa ? (
                      <div>
                        <input
                          type="radio"
                          name="toAccount"
                          value="sellerBalanceAccount"
                          checked={
                            getValues("destinationFundsAccountId") ===
                            payee.validBalanceAccount?.id
                          }
                          onChange={() => {
                            setValue(
                              "destinationAccountType",
                              FundsAccountType.Balance
                            );
                            onChange(payee.validBalanceAccount?.id);
                          }}
                        />
                        <div className="inline-block align-middle ml-5">
                          <div className="font-bold text-sm">
                            Balance Account
                          </div>
                          <div>
                            Balance{" "}
                            {formatToDollars({
                              cents: payee.validBalanceAccount?.balance | 0,
                            })}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {payer.validBankAccount ? (
                      <div>
                        <div className="font-bold text-sm">Bank Account</div>
                        <div>
                          {payer.validBankAccount?.name || "Account"} ending in{" "}
                          {payer.validBankAccount?.mask}
                        </div>
                      </div>
                    ) : (
                      <div className="font-bold text-red-600">
                        No Bank Account Found
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          />
          {errors.destinationFundsAccountId && (
            <small className="text-sm text-red-600">
              A destination account is required
            </small>
          )}
        </div>

        {purchaseOrderInvoice && (
          <div className="tablet:col-span-1">
            <label
              htmlFor="invoiceSummary"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Invoice Summary
            </label>
            <div className="grid grid-cols-3 gap-1">
              <div className="col-span-2">
                Invoice Total
                <Button
                  kind="tertiary"
                  type="button"
                  onClick={() =>
                    setFormattedAmountInput(
                      purchaseOrderInvoice?.externalAmountInCents
                        ? purchaseOrderInvoice.externalAmountInCents
                        : 0
                    )
                  }
                  className="p-0 ml-1"
                >
                  (fill)
                </Button>
              </div>
              <div className="text-right">
                {formatToDollars({
                  cents: purchaseOrderInvoice.externalAmountInCents
                    ? purchaseOrderInvoice.externalAmountInCents
                    : 0,
                })}
              </div>
              <div className="col-span-2">Invoice #</div>
              <div className="text-right">
                {purchaseOrderInvoice.externalInvoiceId}
              </div>
            </div>
          </div>
        )}

        <div className="tablet:col-span-2">
          <div className="mb-5">
            <AmountInput
              label="Amount"
              name="amount"
              register={register}
              error={errors.amount?.message}
            />
          </div>
          <div className="mb-5">
            <LabeledInput
              label="Statement Descriptor"
              inputProps={{
                inlineLead: statementDescriptorPrefix,
                error: errors.statementDescriptor?.message,
                ...register("statementDescriptor", {
                  required: requiredRule,
                }),
              }}
            />
          </div>
        </div>

        <div>
          <Button
            data-testid="OrderTransferSubmitButton"
            type="submit"
            disabled={isSubmitting || isValidating}
          >
            Send{" "}
            {transferMode === TransferMode.Payment ? "Payment" : "Transfer"}
          </Button>
        </div>
      </form>
    );
  }

  return null;
};

type MaybeOrderWithInvoice = {
  invoice: Maybe<{
    externalInvoiceId: Maybe<string>;
  }>;
};

type OrderWithInvoice = {
  invoice: {
    externalInvoiceId: string;
  };
};

function assertOrderHasExternalInvoiceId(
  order: MaybeOrderWithInvoice
): asserts order is OrderWithInvoice {
  if (!order?.invoice) {
    throw new Error("Bad data: expected order to have invoice.");
  }
}
