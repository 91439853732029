import React, { FC, useState } from "react";
import { merge } from "lodash";
import { Page, PageHeading } from "@components/ui/Page";
import { FileInput } from "@components/FileUpload/FileInput";
import { RouteComponentProps } from "@reach/router";
import { defaultErrorHandler } from "@util/toast";
import {
  ImportCsvCarrier,
  ImportReportType,
  useUpdateTrackingInfoFromCsvMutation,
  useUpdateShippingCostFromCsvMutation,
} from "@apollo/gen/generatedOperations";
import { Spinner } from "@shared/components/Spinner";
import { ImportResultTable } from "./ImportResultTable";
import { SectionHeading } from "@ui/SectionHeadings";
import { Dropdown } from "@ui/Dropdown";

export const AdminShipmentsPage: FC<RouteComponentProps> = () => {
  const errors = undefined;
  const [shippingCompany, setShippingCompany] = useState(ImportCsvCarrier.Ffe);
  const [reportType, setReportType] = useState(ImportReportType.Tracking);
  const [
    updateTrackingInfoFromCSV,
    { loading: trackingUpdateLoading, data: trackingUpdateData },
  ] = useUpdateTrackingInfoFromCsvMutation({
    onError: defaultErrorHandler,
  });
  const [
    updateShippingCostFromCSV,
    { loading: costUpdateLoading, data: costUpdateData },
  ] = useUpdateShippingCostFromCsvMutation({
    onError: defaultErrorHandler,
  });

  const onChange = async (
    files: Array<{
      getFileId: () => Promise<number>;
    }>
  ) => {
    const remoteFileId = await files[0].getFileId();
    switch (reportType) {
      case ImportReportType.Tracking:
        await updateTrackingInfoFromCSV({
          variables: {
            remoteFileId,
            company: shippingCompany,
          },
        });
        break;
      case ImportReportType.Cost:
        await updateShippingCostFromCSV({
          variables: {
            remoteFileId,
          },
        });
    }
  };

  const shippingCompaniesOptions = Object.values(
    ImportCsvCarrier
  ).map((companyName) => ({ label: companyName, value: companyName }));
  const reportTypeOptions = Object.values(
    ImportReportType
  ).map((reportType) => ({ label: reportType, value: reportType }));

  const updateShippingCompany = (selectedCompany: ImportCsvCarrier) => {
    setShippingCompany(selectedCompany);
    // Cost reports are only supported for FFE
    if (selectedCompany === ImportCsvCarrier.Fedex) {
      setReportType(ImportReportType.Tracking);
    }
  };

  return (
    <Page
      heading={
        <PageHeading
          title={"Shipments"}
          subtitle={"Upload CSV file from a shipping company"}
        />
      }
    >
      <div className={"mb-8 w-2/12"}>
        <Dropdown
          fieldLabel={"Shipping Company"}
          options={shippingCompaniesOptions}
          value={shippingCompany}
          onChange={updateShippingCompany}
          className={"mb-2"}
        />
        {shippingCompany === ImportCsvCarrier.Ffe && (
          <Dropdown
            fieldLabel={"Report Type"}
            options={reportTypeOptions}
            value={reportType}
            onChange={setReportType}
          />
        )}
      </div>

      <FileInput
        label="Upload the CSV/XLSX here to update tracking info in our system"
        maxFiles={1}
        maxSize={20971520}
        accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        error={errors}
        onChange={onChange}
        disabled={!shippingCompany}
      />
      <div className={"mt-8"}>
        {shippingCompany && (trackingUpdateLoading || costUpdateLoading) && (
          <Spinner />
        )}
        {shippingCompany && (trackingUpdateData || costUpdateData) && (
          <>
            <SectionHeading title="Import Results" className={"mb-8"} />
            <div className={"mt-8"}>
              <ImportResultTable
                data={merge(
                  trackingUpdateData?.updateTrackingInfoFromCSV,
                  costUpdateData?.updateShippingCostFromCSV
                )}
              />
            </div>
          </>
        )}
      </div>
    </Page>
  );
};
export default AdminShipmentsPage;
