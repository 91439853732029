import React from "react";
import { Dialog, DialogProps } from "primereact/dialog";
import { UpdateRetailer } from "./UpdateRetailer";

type UpdateRetailerDialogProps = {
  retailerId: number;
  dialogProps: DialogProps;
};

/**
 * This dialog is used to add a new retailer organization.
 */
export function UpdateRetailerDialog({
  retailerId,
  dialogProps,
}: UpdateRetailerDialogProps) {
  return (
    <Dialog
      {...dialogProps}
      header="Update retailer"
      className="p-fluid tablet:max-w-xl tablet:w-full"
      modal
    >
      <UpdateRetailer retailerId={retailerId} onUpdate={dialogProps.onHide} />
    </Dialog>
  );
}
