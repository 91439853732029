import React, { useMemo } from "react";
import { Table } from "@components/ui/Table";
import { Column, TableInstance, useSortBy, useTable } from "react-table";
import { formatAsDateWithTimeFromString } from "@utilities/formatDate";
import {
  OrderDocumentSummaryFragment,
  useDeleteOrderDocumentMutation,
  useDeletePodDocumentMutation,
} from "@apollo/ops";
import { DocumentType } from "@apollo/gen/generatedOperations";
import { Button } from "@components/ui/Button";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { useDialog } from "@components/ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import startCase from "lodash/startCase";

type OrderDocumentsTableProps = {
  documents: Array<OrderDocumentSummaryFragment>;
  disableDelete?: boolean;
};

export function OrderDocumentsTable({
  documents,
  disableDelete = false,
}: OrderDocumentsTableProps) {
  const columns = useMemo<Column<OrderDocumentSummaryFragment>[]>(
    () => [
      {
        Header: "Date",
        Cell: ({ value }) => formatAsDateWithTimeFromString(value),
        accessor: "createdAt",
      },
      {
        Header: "Document",
        Cell: ({ value, row }) => (
          <a href={row.original.fileUrl} className="underline">
            {value}
          </a>
        ),
        accessor: "name",
      },
      {
        Header: "Type",
        Cell: ({ value }) => startCase(value),
        accessor: "documentType",
      },
      {
        Header: "Notes",
        accessor: "notes",
      },
      {
        Header: "Created By",
        accessor: ({ creator }) => creator.email,
      },
    ],
    []
  );

  !disableDelete &&
    columns.push({
      Header: "",
      Cell: ({ value, row }) => (
        <DeleteOrderDocument
          orderDocumentId={value}
          docType={row.original.documentType}
        />
      ),
      accessor: "id",
    });

  const tableInstance = useTable(
    {
      columns,
      data: documents,
    },
    useSortBy
  ) as TableInstance<OrderDocumentSummaryFragment>;

  return <Table instance={tableInstance} />;
}

type DeleteOrderDocumentProps = {
  orderDocumentId: number;
  docType: DocumentType;
};

function DeleteOrderDocument({
  orderDocumentId,
  docType,
}: DeleteOrderDocumentProps) {
  const [deleteOrderDocument, { loading }] = useDeleteOrderDocumentMutation();
  const [deletePODDocument] = useDeletePodDocumentMutation();

  const dialog = useDialog();

  const handleConfirm = async () => {
    try {
      const deletionVars = {
        variables: {
          id: orderDocumentId,
        },
      };

      if (docType === DocumentType.ProofOfDelivery)
        await deletePODDocument(deletionVars);
      else await deleteOrderDocument(deletionVars);

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "Document deleted",
      });
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <>
      <Button
        kind="secondary"
        destructive
        disabled={loading}
        onClick={dialog.open}
      >
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this document?"
        description="Deleting this document is non-reversible."
        onConfirm={handleConfirm}
        confirmText="Yes, delete this document"
        dialog={dialog}
        destructive
      />
    </>
  );
}
