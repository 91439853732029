import React, { ReactNode } from "react";
import { PreSaleDisplayFragment } from "@apollo/ops";
import { PageHeading } from "@ui/Page";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";
import { CalendarIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { formatAsDate } from "@utilities/formatDate";

type AdminPreSalePageHeadingProps = {
  preSale: PreSaleDisplayFragment;
  page: string;
  actions?: ReactNode;
};

export function AdminPreSalePageHeading({
  page,
  preSale,
  actions,
}: AdminPreSalePageHeadingProps) {
  const breadcrumbProps = {
    items: buildBreadcrumbs("Pre-Sales", `Pre-Sale #${preSale.id}`, page),
  };

  const metaProps = {
    items: [
      {
        icon: LocationMarkerIcon,
        text: preSale.regionV2.friendlyName,
      },
      {
        icon: CalendarIcon,
        text: formatAsDate(preSale.start) + " to " + formatAsDate(preSale.end),
      },
    ],
  };

  return (
    <PageHeading
      title={"Pre-Sale " + page}
      breadcrumbProps={breadcrumbProps}
      metaProps={metaProps}
      actions={actions}
    />
  );
}
