import React, { MouseEventHandler, useMemo } from "react";
import currency from "currency.js";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { CellProps, Column, useTable } from "react-table";
import { Table } from "@ui/Table";
import { Input } from "@components/ui/Input";
import { Button } from "@components/ui/Button";
import {
  AdminOrderItemFragment,
  ProductDisplayFragment,
  useAdminOrderItemsUpdateMutation,
} from "@apollo/ops";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { Dialog, useDialog } from "@ui/Dialog";
import { AddProductDialog } from "./AddProductDialog";
import { formatAmountInCents, toCents, toDollars } from "@utilities/currency";
import { AdminProductLink } from "@components/Product/Product";

type OrderItemField = {
  productId: number;
  quantity: number;
  priceInDollars: number;
  product: {
    id: number;
    name: string;
    packageType: {
      units: number;
      displayName: string;
    };
  };
};

type OrderItemsFormData = {
  items: Array<OrderItemField>;
};

export interface OrderItemsEditableTableProps {
  orderItems: Array<AdminOrderItemFragment>;
  orderId: number;
  filterProducerId?: number;
  onFinish: () => void;
}

/**
 * Component to that allows to view and edit in a tabular way the order items of an order.
 *
 * @returns
 */
export function AdminOrderItemsEditTable({
  orderId,
  orderItems,
  onFinish,
  filterProducerId,
}: OrderItemsEditableTableProps) {
  const tableDefaultValue = {
    items: orderItems.map((orderItem) => {
      return {
        ...orderItem,
        //Transform amount to dollars since it's in cents.
        priceInDollars: toDollars(orderItem.price),
      };
    }),
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<OrderItemsFormData>({
    defaultValues: tableDefaultValue,
  });

  const [updateOrder, { loading }] = useAdminOrderItemsUpdateMutation({
    onCompleted: () => {
      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "Order items updated succesfully",
      });
      onFinish();
    },
    onError: (e) => {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(e),
      });
    },
  });

  /**
   * The useFieldArray hook helps work with an input of dynamic lists.
   *
   * See https://react-hook-form.com/api/usefieldarray/
   */
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
    keyName: "product.id",
  });

  /**
   * By default, the useFieldArray fields are uncontrolled, so changes to the
   * inputs aren't reflected in the fields array. This makes the entire field
   * array controlled so values are preserved.
   *
   * To see test the difference: add item, enter input values, click submit
   * Without this the values will appear to be cleared, with this they will persist.
   */
  const watchItemsArray = watch("items");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchItemsArray[index],
    };
  });

  const columns = useMemo<
    Array<
      Column<
        FieldArrayWithId<
          { items: Array<OrderItemField> },
          "items",
          "product.id"
        >
      >
    >
  >(
    () => [
      {
        Header: "Product",
        Cell: ({ row }: CellProps<OrderItemField, "string">) => (
          <AdminProductLink product={row.original.product} />
        ),
        accessor: (model) => model.product.name,
        width: 140,
      },
      {
        Header: "Price",
        Cell: ({ row }) => {
          return (
            <Input
              key={row.original.product.id}
              type="number"
              inlineLead="$"
              step={0.01}
              {...register(`items.${row.index}.priceInDollars` as const, {
                required: {
                  value: true,
                  message: "This field is required",
                },
                min: {
                  value: 0.01,
                  message: "Price must be greater than 0",
                },
              })}
              error={errors.items?.[row.index]?.priceInDollars?.message}
              defaultValue={row.original.priceInDollars}
            />
          );
        },
        accessor: "priceInDollars",
        width: 140,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell: ({ row }) => (
          <Input
            key={row.original.product.id}
            type="number"
            {...register(`items.${row.index}.quantity` as const, {
              required: {
                value: true,
                message: "This field is required",
              },
              min: {
                value: 1,
                message: "Quantity must be at least 1",
              },
            })}
            error={errors.items?.[row.index]?.quantity?.message}
            defaultValue={row.original.quantity}
          />
        ),
        width: 140,
      },
      {
        Header: "Total",
        Cell: ({ row }: CellProps<OrderItemField, undefined>) =>
          formatAmountInCents(
            currency(row.original.priceInDollars).multiply(
              row.original.quantity
            ).intValue
          ),
        id: "",
        width: 140,
      },
      {
        id: "actions",
        Cell: ({ row }: CellProps<OrderItemField, undefined>) => (
          <Button
            onClick={() => remove(row.index)}
            destructive
            kind="secondary"
          >
            Remove
          </Button>
        ),
      },
    ],
    [register, remove, errors]
  );

  const tableInstance = useTable({
    columns,
    data: controlledFields,
  });

  const { open, close, getDialogProps } = useDialog();

  const handleAddItem: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    open();
  };

  const addProductToOrder = (product: ProductDisplayFragment) => {
    append({
      product,
      productId: product.id,
      quantity: 0,
      priceInDollars: 0,
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          const formattedBackendData = {
            items: data.items.map((orderItem) => ({
              price: toCents(Number(orderItem.priceInDollars)),
              productId: orderItem.productId,
              quantity: Number(orderItem.quantity),
            })),
          };

          await updateOrder({
            variables: {
              id: orderId,
              data: formattedBackendData,
            },
          });
        })}
        className="clear-both"
      >
        <Table instance={tableInstance} />

        <div className="mt-3 flex justify-between">
          <Button onClick={handleAddItem} disabled={loading}>
            Add item
          </Button>
          <Button type="submit" disabled={loading}>
            Submit
          </Button>
        </div>
      </form>
      <Dialog {...getDialogProps()} title={"Product Search"}>
        <AddProductDialog
          onSelect={addProductToOrder}
          onClose={close}
          filters={{
            producerId: filterProducerId,
          }}
        />
      </Dialog>
    </>
  );
}
