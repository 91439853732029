import { useState } from "react";
import { DialogProps } from "primereact/dialog";

type UseDialogResult = {
  show: () => void;
  hide: () => void;
  isOpen: boolean;
  getDialogProps: (
    props?: Omit<DialogProps, "visible" | "onHide">
  ) => DialogProps;
};

export const useDialog = (): UseDialogResult => {
  const [isOpen, setIsOpen] = useState(false);
  const show = () => {
    setIsOpen(true);
  };
  const hide = () => {
    setIsOpen(false);
  };

  const getDialogProps = (props?: Omit<DialogProps, "visible" | "onHide">) => ({
    ...props,
    visible: isOpen,
    onHide: hide,
  });

  return {
    show,
    hide,
    isOpen,
    getDialogProps,
  };
};
