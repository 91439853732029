import React from "react";
import {
  AdminAddLicenseMutation,
  useAdminAddLicenseMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { LicenseForm, LicenseFormData } from "../LicenseForm";

type AddLicenseProps = {
  onAdd?: (data: AdminAddLicenseMutation) => void;
  producerId: number;
};

export function AddLicense({ producerId, onAdd }: AddLicenseProps) {
  const [addLicenseAndCurrentPeriod] = useAdminAddLicenseMutation();

  const handleSubmit = async ({ title, ...data }: LicenseFormData) => {
    try {
      const result = await addLicenseAndCurrentPeriod({
        variables: {
          producerId,
          data: {
            ...data,
            title,
          },
        },
      });

      if (result.data && onAdd) {
        onAdd(result.data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Added license ${title}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return <LicenseForm submitText="Save" onSubmit={handleSubmit} />;
}
