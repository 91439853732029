import React from "react";
import { FormButton } from "@components/FormButton";
import { AddBankAccountCopy } from "@components/BankAccount/BankAccountConstants";
import {
  usePlaidLinkToken,
  withPlaidLinkToken,
  WithPlaidLinkTokenInjectedProps,
} from "@components/plaid/withPlaidLinkToken";

import { useAddBankAccountDialog } from "./useAddBankAccountDialog";

type OnboardingAddBankAccountProps = {
  businessId: number;
};

type BaseOnboardingAddBankAccountProps = OnboardingAddBankAccountProps &
  WithPlaidLinkTokenInjectedProps;

function BaseOnboardingAddBankAccountProps({
  businessId,
  plaidLinkToken,
}: BaseOnboardingAddBankAccountProps) {
  const { addBankAccount, ready, result } = useAddBankAccountDialog({
    businessId,
    plaidLinkToken,
  });

  return (
    <div className="text-center">
      <div className="text-3xl mb-6">Bank Account</div>

      <p>{AddBankAccountCopy}</p>

      <div className="mt-12">
        <FormButton
          disabled={!ready || result.loading}
          onSubmit={() => addBankAccount()}
          size="xl"
        >
          Add Bank Account
        </FormButton>
      </div>
    </div>
  );
}

export const OnboardingAddBankAccount = withPlaidLinkToken<OnboardingAddBankAccountProps>(
  {
    useHook: usePlaidLinkToken,
  }
)(BaseOnboardingAddBankAccountProps);
