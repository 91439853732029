import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { AdminProducerPlatformFees } from "./AdminProducerPlatformFees/AdminProducerPlatformFees";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import {
  AdminRegionDetailsDocument,
  AdminRegionDetailsQuery,
  AdminRegionDetailsQueryVariables,
} from "@apollo/gen/generatedOperations";

interface AdminProducerRegionPageProps extends RouteComponentProps {
  parsedProducerId: number;
  regionId?: string;
}

type WrappedProps = WithQueryComponentProps<
  AdminProducerRegionPageProps,
  AdminRegionDetailsQuery,
  AdminRegionDetailsQueryVariables
>;

export function AdminProducerRegion({ query, parsedProducerId }: WrappedProps) {
  const { region } = query?.data || null;
  return (
    region && (
      <Router>
        <AdminProducerPlatformFees
          path="/"
          producerId={parsedProducerId.toString()}
          regionId={region.id.toString()}
          title={region.friendlyName}
        />
        <Redirect from="*" to="/not-found" />
      </Router>
    )
  );
}

export const AdminProducerRegionPage = withQuery<
  AdminProducerRegionPageProps,
  AdminRegionDetailsQuery,
  AdminRegionDetailsQueryVariables
>(AdminRegionDetailsDocument, {
  mapPropsToOptions: (props) => {
    return {
      variables: {
        parsedProducerId: props.parsedProducerId,
        regionId: parseInt(props.regionId!),
      },
    };
  },
})(AdminProducerRegion);
