import React from "react";
import { useAdminAddPresaleImportJobsMutation } from "@apollo/gen/generatedOperations";
import {
  PreSaleImportJobForm,
  PreSaleImportJobFormData,
} from "./AddPresaleImportJobForm";

type AddImportJobProps = {
  onAdd: () => void | Promise<void>;
};

export function AddImportJob({ onAdd }: AddImportJobProps) {
  const [addPresaleImportJobs] = useAdminAddPresaleImportJobsMutation();

  const handleSubmit = async ({
    regions,
    start,
    end,
  }: PreSaleImportJobFormData) => {
    //False values in the array indicate unchecked, so remove these.
    //Strangely enough, this is the intended way to handle lists of checkboxes in react-hook-form:
    //https://github.com/react-hook-form/react-hook-form/issues/476#issuecomment-553849830
    const checkedRegions = regions
      .filter((value) => value !== false)
      .map(Number);

    await addPresaleImportJobs({
      variables: {
        regions: checkedRegions,
        startDate: start,
        endDate: end,
      },
    });

    onAdd();
  };

  return <PreSaleImportJobForm onSubmit={handleSubmit} />;
}
