import React, { ReactNode, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { client as apolloClient, useWhoAmIQuery } from "@apollo/ops";
import { AuthContext, IUser } from "./AuthContext";

type AuthProviderProps = {
  onLogIn?: (user: IUser) => void;
  onLogOut?: () => void;
  children: ReactNode;
};

export function AuthProvider({
  onLogIn,
  onLogOut,
  children,
}: AuthProviderProps) {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [afterSignInLocation, setAfterSignInLocation] = useState<string>();
  const { refetch, data, error, loading } = useWhoAmIQuery({
    skip: !isLoggedIn,
    onCompleted() {
      if (data?.whoAmI) {
        onLogIn && onLogIn(data.whoAmI);
      }
    },
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        handleCognitoLogin();
        setIsInitialized(true);
      })
      .catch(() => {
        setIsInitialized(true);
      });
  }, []);

  // Refetch when the user changes
  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  if (error) {
    throw new Error("We were unable to sign you in.");
  }

  const handleCognitoLogin = () => setIsLoggedIn(true);
  const logout = async () => {
    await Auth.signOut();
    setIsLoggedIn(false);
    apolloClient.resetStore();
    onLogOut && onLogOut();
  };

  /*
   * This clunk conditional ensures that "signed in" component of the Authenticator
   * component is never shown.
   */
  const isAuthenticating =
    (isLoggedIn && !data?.whoAmI) || loading || !isInitialized;

  const value = {
    afterSignInLocation,
    setAfterSignInLocation,
    isAuthenticating,
    user: data?.whoAmI ?? null,
    logout,
    login: async () => handleCognitoLogin(),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
