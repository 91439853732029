import React, { useMemo } from "react";
import { CellProps, Column, useSortBy, useTable } from "react-table";
import { Table } from "@ui/Table";
import {
  AdminOrderItemFragment,
  OrderType,
  ProductDisplayFragment,
  useDeleteProductFromPurchaseOrderMutation,
} from "@apollo/ops";
import { AdminProductLink } from "@components/Product/Product";
import { toast } from "@utilities/toast";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { Button } from "@components/ui/Button";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";

export type AdminOrderItemsTableRowData = {
  product: ProductDisplayFragment;
  price: string;
  quantity: number;
  total: string;
  finalQuantity: string;
  finalTotal: string;
  hasAdjustmentItems: boolean;
  orderId: number;
  offerId?: number | null;
};

export type AdminOrderItemsDisplayTableProps = {
  orderItems: Array<AdminOrderItemsTableRowData>;
  canDelete?: boolean;
  orderType?: OrderType;
};

const SupportedOrderTypesForDelete = {
  [OrderType.ProducerPurchaseOrder]: true,
};

/**
 * Component that encapsulates delete logic of an order item.
 */
function DeleteOrderItemButton({
  orderType,
  orderItem,
}: {
  orderType: OrderType;
  orderItem: AdminOrderItemsTableRowData;
}) {
  const onClick = () => {
    if (orderItem.hasAdjustmentItems) {
      toast.current?.show({
        severity: "error",
        summary:
          "Please delete the Adjustment items for this product before deleting it",
      });
    } else {
      deleteConfirmDialog.open();
    }
  };
  const deleteConfirmDialog = useDialog();

  const [
    deleteProductFromPurchaseOrder,
  ] = useDeleteProductFromPurchaseOrderMutation({
    onError: (error: Error) => {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    },
  });
  return (
    <>
      <Button onClick={onClick} destructive kind="secondary">
        Remove
      </Button>

      <ConfirmDialog
        title={`Are you sure you want to delete ${orderItem.product.name} from this order?`}
        description="This will remove the product from all retail orders."
        onConfirm={() => {
          switch (orderType) {
            case OrderType.ProducerPurchaseOrder:
              return deleteProductFromPurchaseOrder({
                variables: {
                  orderId: orderItem.orderId,
                  productId: orderItem.product.id,
                },
              });
            default:
              toast.current?.show({
                severity: "info",
                summary: "Action Not supported yet for this type of order",
              });
          }
        }}
        confirmText="Yes, delete this product"
        dialog={deleteConfirmDialog}
      />
    </>
  );
}

export function AdminOrderItemsDisplayTable({
  orderItems,
  canDelete = false,
  orderType,
}: AdminOrderItemsDisplayTableProps) {
  const columns: Array<Column<AdminOrderItemsTableRowData>> = useMemo(() => {
    return [
      {
        Header: "Product",
        Cell: ({ row }: CellProps<AdminOrderItemFragment, "string">) => (
          <AdminProductLink product={row.original.product} />
        ),
        accessor: "product",
        width: 140,
      },
      {
        Header: "Price",
        accessor: "price",
        width: 140,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        width: 140,
      },
      {
        Header: "Total",
        accessor: "total",
        width: 140,
      },
      {
        Header: "Final Quantity",
        accessor: "finalQuantity",
        width: 140,
      },
      {
        Header: "Final Total",
        accessor: "finalTotal",
        width: 140,
      },
      ...(canDelete && orderType && orderType in SupportedOrderTypesForDelete
        ? [
            {
              id: "actions",
              Cell: ({
                row,
              }: CellProps<AdminOrderItemsTableRowData, undefined>) => (
                <DeleteOrderItemButton
                  orderType={orderType}
                  orderItem={row.original}
                />
              ),
            },
          ]
        : []),
    ];
  }, [canDelete, orderType]);
  const tableInstance = useTable(
    {
      columns,
      data: orderItems,
    },
    useSortBy
  );

  return <Table instance={tableInstance} />;
}
