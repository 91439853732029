import React, { ElementType } from "react";
import { Disclosure } from "@headlessui/react";
import classnames from "classnames";
import { Link, LinkGetProps, useMatch } from "@reach/router";

export type NavigationLinkClasses = {
  default: string;
  current: string;
  iconBase: string;
  iconDefault?: string;
  iconCurrent?: string;
  focusRing?: string;
};

export type SidebarNavigationClasses = {
  nav: string;
  navigationLink: NavigationLinkClasses;
};

export type SidebarNavigationSection = {
  name: string;
  children: SidebarNavigationItem[];
  icon?: ElementType;
};

export type SidebarNavigationItem = {
  name: string;
  path: string;
  icon?: ElementType;
};

export type SidebarNavigationElement =
  | SidebarNavigationItem
  | SidebarNavigationSection;

export type SidebarNavigationProps = {
  elements: SidebarNavigationElement[];
  classes: SidebarNavigationClasses;
};

export function SidebarNavigation({
  elements,
  classes,
}: SidebarNavigationProps) {
  return (
    <nav
      className={classnames("flex-1 px-2 space-y-1", classes.nav)}
      aria-label="Sidebar"
    >
      {elements.map((element) =>
        (element as SidebarNavigationItem).path ? (
          <div key={element.name}>
            <NavigationLink
              element={element as SidebarNavigationItem}
              classes={classes.navigationLink}
            />
          </div>
        ) : (
          <div key={element.name}>
            <NavigationSection
              element={element as SidebarNavigationSection}
              classes={classes.navigationLink}
            />
          </div>
        )
      )}
    </nav>
  );
}

type NavigationLinkClassesOptions = {
  isCurrent: boolean;
  classes: NavigationLinkClasses;
};

const navigationLinkClasses = ({
  isCurrent,
  classes,
}: NavigationLinkClassesOptions) =>
  classnames(isCurrent ? classes.current : classes.default);

type NavigationLinkOptions = {
  element: SidebarNavigationItem;
  classes: NavigationLinkClasses;
  baseClass?: string;
};

function NavigationLink({
  element,
  classes,
  baseClass = "group flex items-center px-2 py-2 text-base font-medium rounded-md",
}: NavigationLinkOptions) {
  const current = useMatch(element.path + "/*");
  const getProps = ({ isPartiallyCurrent }: LinkGetProps) => ({
    className: classnames(
      baseClass,
      classes.focusRing,
      navigationLinkClasses({ isCurrent: isPartiallyCurrent, classes })
    ),
  });

  return (
    <Link to={element.path} getProps={getProps}>
      {element.icon && (
        <element.icon
          className={classnames(
            classes.iconBase,
            current ? classes.iconCurrent : classes.iconDefault,
            "mr-3 h-6 w-6"
          )}
          aria-hidden="true"
        />
      )}
      {element.name}
    </Link>
  );
}

type NavigationSectionOptions = {
  element: SidebarNavigationSection;
  classes: NavigationLinkClasses;
};

function NavigationSection({ element, classes }: NavigationSectionOptions) {
  return (
    <Disclosure as="div" key={element.name} className="space-y-1">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classnames(
              classes.default,
              "group w-full flex items-center pl-2 pr-1 py-2 font-medium rounded-md focus:outline-none focus:ring-2"
            )}
          >
            {element.icon && (
              <element.icon
                className={classnames(classes.iconBase, "mr-3 h-6 w-6")}
                aria-hidden="true"
              />
            )}
            {element.name}
            <svg
              className={classnames(
                classes.iconBase,
                open ? `${classes.iconCurrent} rotate-90` : classes.iconDefault,
                "ml-auto h-5 w-5 transform transition-colors ease-in-out duration-150"
              )}
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
            </svg>
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1">
            {element.children.map((child) => (
              <NavigationLink
                key={child.name}
                element={child}
                classes={classes}
                baseClass="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md"
              />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export const sidebarNavigationStyles = {
  white: {
    nav: "bg-white",
    navigationLink: {
      focusRing: "focus:ring-primary-500",
      default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
      current: "bg-gray-100 text-gray-900",
      iconBase: "",
      iconDefault: "text-gray-400 group-hover:text-gray-500",
      iconCurrent: "text-gray-500",
    },
  },
  brand: {
    nav: "bg-brand-600",
    navigationLink: {
      default: "text-white hover:bg-brand-500 hover:bg-opacity-75",
      current: "text-white bg-brand-700",
      iconBase: "text-white text-opacity-60",
    },
  },
  indigo: {
    nav: "bg-indigo-700",
    navigationLink: {
      default: "text-indigo-100 hover:bg-indigo-600 hover:bg-opacity-75",
      current: "bg-indigo-800 text-white",
      iconBase: "text-indigo-300",
    },
  },
};
