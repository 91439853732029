import React, { FunctionComponent, ComponentType } from "react";
import {
  useQuery,
  QueryFunctionOptions,
  QueryResult,
  OperationVariables,
} from "@apollo/client";
import { DocumentNode } from "graphql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { ConfigInterface } from "./types";
import { useWithQueryConfig } from "./config";

export type ExtractResolvedType<TIsFoundType, TDefault> = TIsFoundType extends (
  data: any
) => data is infer TType
  ? TType
  : TDefault;

export type Query<TData, TVariables> =
  | DocumentNode
  | TypedDocumentNode<TData, TVariables>;

export type MapPropsToOptions<TProps, TData, TVariables> = (
  props: TProps
) => QueryFunctionOptions<TData, TVariables>;

export type MapErrorToProps = ({ message }: Error) => { message: string };

export type IsFoundType<TData = any, TFoundType extends TData = TData> = (
  data: TData
) => data is TFoundType;
export interface WithQueryOptions<TProps, TData, TVariables>
  extends ConfigInterface {
  mapPropsToOptions?: MapPropsToOptions<TProps, TData, TVariables>;
  mapErrorToProps?: MapErrorToProps;
}

interface ResolvedQueryResult<TData, TVariables>
  extends QueryResult<TData, TVariables> {
  data: TData;
}

export type WithQueryInjectedProps<TData, TVariables = OperationVariables> = {
  query: ResolvedQueryResult<TData, TVariables>;
};

export type WithQueryComponentProps<
  TProps,
  TFoundType,
  TVariables = OperationVariables
> = TProps & WithQueryInjectedProps<TFoundType, TVariables>;

const optionDefaults = {
  mapErrorToProps: ({ message }: Error) => ({ message }),
};

export const withQuery = <TProps, TData, TVariables = OperationVariables>(
  query: Query<TData, TVariables>,
  options: WithQueryOptions<TProps, TData, TVariables> = {}
) => (
  Component: ComponentType<WithQueryComponentProps<TProps, TData, TVariables>>
) => {
  const WithQuery: FunctionComponent<TProps> = (props) => {
    const global = useWithQueryConfig();
    const config = Object.assign({}, optionDefaults, global, options);

    const useQueryOptions = options?.mapPropsToOptions
      ? options.mapPropsToOptions(props)
      : undefined;

    const result = useQuery(query, useQueryOptions);

    if (!config.LoadingComponent) {
      throw new Error("ErrorComponent not specificied in WithQueryError");
    }

    if (!config.ErrorComponent) {
      throw new Error("ErrorComponent not specificied in WithQueryError");
    }

    if (!config.NotFoundComponent) {
      throw new Error("NotFoundComponent not specificied in WithQueryData");
    }

    if (result.loading && !result.data) {
      return <config.LoadingComponent />;
    }

    if (result.error) {
      const errorProps = config.mapErrorToProps(result.error);
      return <config.ErrorComponent {...errorProps} />;
    }

    if (result.data) {
      /*
       * Ideally we wouldn't have to use the "as" declaration to enforce the type,
       * however the control flow based analysis isn't automatically inferring
       * that the type of query has been narrowed to support this.
       */
      const resolved = result as ResolvedQueryResult<TData, TVariables>;

      return <Component {...props} query={resolved} />;
    }

    return <config.NotFoundComponent />;
  };

  return WithQuery;
};
