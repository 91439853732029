import React from "react";
import { RouteComponentProps } from "@reach/router";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { LoadingIcon } from "@components/ui/Spinner";
import { useErrorHandler } from "react-error-boundary";
import {
  AdminRegionDeliveryPartnerDetailsDocument,
  AdminRegionDeliveryPartnerDetailsQuery,
  AdminRegionDeliveryPartnerDetailsQueryVariables,
  useAdminRegionAssignDeliveryDriverMutation,
} from "@apollo/gen/generatedOperations";
import { DeliveryPartnersDropdown } from "@components/DeliveryPartner/DeliveryPartnersDropdown";
import { Card, CardBody } from "@ui/Card/Card";
import { defaultErrorHandler } from "@util/toast";

interface AdminRegionDeliveryPartnerDetailsProps extends RouteComponentProps {
  regionId: string;
}

type WrappedProps = WithQueryComponentProps<
  AdminRegionDeliveryPartnerDetailsProps,
  AdminRegionDeliveryPartnerDetailsQuery,
  AdminRegionDeliveryPartnerDetailsQueryVariables
>;

export function AdminRegionDeliveryPartnerDetailsWithQuery({
  query,
}: WrappedProps) {
  const { data: results, loading, error } = query;

  useErrorHandler(error);

  const data = results.region.deliveryPartner;
  const [assignDeliveryDriver] = useAdminRegionAssignDeliveryDriverMutation({
    onError: defaultErrorHandler,
  });

  async function onChange(deliveryPartnerId: number | null) {
    deliveryPartnerId !== null &&
      (await assignDeliveryDriver({
        variables: {
          data: {
            regionId: results.region.id,
            deliveryPartnerId,
          },
        },
      }));
  }

  return (
    <div className="mt-8">
      <div className="mt-10">
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="mt-8 card">
            <div className="mt-8 card">
              <Card
                title="Select A Delivery Partner"
                className="overflow-visible"
              >
                <CardBody>
                  <DeliveryPartnersDropdown
                    onChange={onChange}
                    value={data?.id}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const AdminRegionDeliveryPartnerDetails = withQuery<
  AdminRegionDeliveryPartnerDetailsProps,
  AdminRegionDeliveryPartnerDetailsQuery,
  AdminRegionDeliveryPartnerDetailsQueryVariables
>(AdminRegionDeliveryPartnerDetailsDocument, {
  mapPropsToOptions: (props) => ({
    variables: { regionId: parseInt(props.regionId) },
  }),
})(AdminRegionDeliveryPartnerDetailsWithQuery);
