import React, { FC, useMemo } from "react";
import { Column, TableInstance, useTable, useSortBy } from "react-table";
import { Table } from "@ui/Table";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import {
  DeletePackageTypeMutation,
  PackageTypeDetailsFragment,
} from "@apollo/ops";
import { DeletePackageTypeButton } from "./DeletePackageType/DeletePackageTypeButton";

export interface PackageTypesTableProps {
  onDelete?: (data: DeletePackageTypeMutation) => void;
  packageTypes: PackageTypeDetailsFragment[];
}

export const PackageTypesTableCmp: FC<PackageTypesTableProps> = ({
  onDelete,
  packageTypes,
}: PackageTypesTableProps) => {
  const columns: Array<Column<PackageTypeDetailsFragment>> = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
        width: 140,
      },
      {
        Header: "# of Units",
        accessor: "units",
        width: 140,
      },
      {
        Header: "Unit Label",
        accessor: "containerLabel",
        width: 140,
      },
      {
        Header: "Unit Volume",
        accessor: "containerVolume",
        width: 140,
      },
      {
        Header: "Container Street name",
        accessor: "containerStreetName",
        width: 140,
      },
      {
        Header: "Weight",
        Cell: ({ value }) => `${value}lb`,
        accessor: "weight",
        width: 140,
      },
      {
        Header: () => null,
        id: "actions",
        Cell: ({ value: canDelete, row }) =>
          canDelete ? deletePackageType(row.original.id) : "",
        accessor: "canDelete",
        width: 140,
      },
    ],
    []
  );

  const deletePackageType = (id: number) => {
    return <DeletePackageTypeButton packageTypeId={id} onDelete={onDelete} />;
  };

  const tableInstance = useTable(
    {
      columns,
      data: packageTypes,
    },
    useSortBy
  ) as TableInstance<PackageTypeDetailsFragment>;

  return <Table instance={tableInstance} />;
};
export const PackageTypesTable = withErrorBoundary(PackageTypesTableCmp);
