import React from "react";
import { Button, ButtonProps } from "@ui/Button";
import {
  DeleteOfferWindowMutation,
  useDeleteOfferWindowMutation,
} from "@apollo/ops";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { toastSuccess, defaultErrorHandler } from "@utilities/toast";

type DeleteOfferWindowButtonProps = ButtonProps & {
  onDelete?: (data: DeleteOfferWindowMutation) => void;
  offerWindowId: number;
};

export function DeleteOfferWindowButton({
  offerWindowId,
  onDelete,
  ...buttonProps
}: DeleteOfferWindowButtonProps) {
  const [deleteOfferWindow] = useDeleteOfferWindowMutation();
  const dialog = useDialog();

  const onConfirm = async () => {
    try {
      const result = await deleteOfferWindow({
        variables: {
          id: offerWindowId,
        },
      });

      if (result.data && onDelete) {
        onDelete(result.data);
      }

      toastSuccess({ summary: "Offer window deleted" });
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <>
      <Button
        destructive
        kind="secondary"
        onClick={dialog.open}
        {...buttonProps}
      >
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this offer window?"
        onConfirm={onConfirm}
        confirmText="Yes, delete this offer window"
        dialog={dialog}
      />
    </>
  );
}
