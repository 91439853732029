import React from "react";
import {
  AdminRenewLicenseMutation,
  useAdminRenewLicenseMutation,
} from "@apollo/ops";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { RenewalForm, RenewalFormData } from "./RenewalForm";

type AddLicenseProps = {
  onRenew?: (data: AdminRenewLicenseMutation) => void;
  licenseId: number;
};

export function RenewLicense({ licenseId, onRenew }: AddLicenseProps) {
  const [renewLicense] = useAdminRenewLicenseMutation();

  const handleSubmit = async (data: RenewalFormData) => {
    try {
      const result = await renewLicense({
        variables: {
          licenseId,
          data,
        },
      });

      if (result.data && onRenew) {
        onRenew(result.data);
      }

      const title = result.data?.addLicensePeriod?.license.title;

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: `Renewed license ${title}`,
      });
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return <RenewalForm submitText="Save" onSubmit={handleSubmit} />;
}
