import React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  useForm,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { defaultErrorHandler } from "@utilities/toast";
import { Button } from "@ui/Button";
import { DateTime } from "luxon";
import classNames from "classnames";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";
import { Label } from "@components/ui/Input";

type OfferWindowFormData = {
  originalOfferWindowId: number;
  start: Date;
  end: Date;
  regionId: number;
};

export type CloneOfferWindowSubmitData = {
  originalOfferWindowId: number;
  start: Date;
  end: Date;
  regionId: number;
};

type CloneOfferWindowFormProps = {
  onSubmit: (data: OfferWindowFormData) => void | Promise<void>;
};

type DateComponentProps = {
  control: Control<OfferWindowFormData>;
  setValue: UseFormSetValue<OfferWindowFormData>;
  errors: DeepMap<OfferWindowFormData, FieldError>;
  watch: UseFormWatch<OfferWindowFormData>;
  trigger: UseFormTrigger<OfferWindowFormData>;
};

export function CloneOfferWindowForm({ onSubmit }: CloneOfferWindowFormProps) {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    watch,
    setValue,
    trigger,
  } = useForm<OfferWindowFormData>();

  const wrappedOnSubmit = async (data: OfferWindowFormData) => {
    try {
      await onSubmit(data);
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  const dateComponentProps: DateComponentProps = {
    control,
    setValue,
    errors,
    watch,
    trigger,
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div>
        <Label>Region</Label>
        <div className="mt-1">
          <Controller
            name="regionId"
            control={control}
            rules={{
              required: "Region is required",
            }}
            render={({ field: { onChange, value } }) => (
              <RegionsDropdown
                onChange={onChange}
                value={value}
                error={errors.regionId}
              />
            )}
          />
        </div>
      </div>

      <div className="grid tablet:grid-cols-2 gap-4">
        <StartDate {...dateComponentProps} />
        <EndDate {...dateComponentProps} />
      </div>

      <Button size="lg" type="submit" disabled={isSubmitting} className="mt-5">
        Clone offer window
      </Button>
    </form>
  );
}

function StartDate({
  control,
  setValue,
  errors,
  watch,
  trigger,
}: DateComponentProps) {
  const start = watch("start");
  const end = watch("end");

  return (
    <div className="mt-5">
      <div className="mt-1">
        <Label>Start</Label>
        <Controller
          name="start"
          control={control}
          rules={{
            required: "Start Date is required",
          }}
          render={({ field: { onChange, value } }) => (
            <Calendar
              value={value}
              onChange={({ value }) => onChange(value)}
              appendTo="self"
              monthNavigator
              showTime
              hourFormat="12"
              showIcon={true}
              onShow={() => {
                if (!value) {
                  setValue("start", getDefaultStartDateTime().toJSDate());
                }
              }}
              onHide={() => {
                //Set the end date to automatically be 1 day later, for convenience
                if (start && !end) {
                  const newEndDate = DateTime.fromJSDate(start).plus({
                    day: 1,
                  });
                  setValue("end", newEndDate.toJSDate());

                  //Must manually trigger re-validation of the end date field, in case
                  //setting the value causes it to transition from error state to not error state
                  trigger("end");
                }
              }}
              className={classNames({
                "border-red-300": errors.start,
              })}
            />
          )}
        />
        {errors.start && (
          <small className="text-sm text-red-600">Start date is required</small>
        )}
      </div>
    </div>
  );
}

function EndDate({ control, setValue, errors }: DateComponentProps) {
  return (
    <div className="mt-5">
      <Label>End</Label>
      <div className="mt-1">
        <Controller
          name="end"
          control={control}
          rules={{
            required: "End Date is required",
          }}
          render={({ field: { onChange, value } }) => (
            <Calendar
              value={value}
              onChange={({ value }) => onChange(value)}
              appendTo="self"
              monthNavigator
              showTime
              hourFormat="12"
              showIcon={true}
              onShow={() => {
                if (!value) {
                  setValue("end", getDefaultEndDateTime().toJSDate());
                }
              }}
              className={classNames({
                "border-red-300": errors.end,
              })}
            />
          )}
        />
        {errors.end && (
          <small className="text-sm text-red-600">End date is required</small>
        )}
      </div>
    </div>
  );
}

function getDefaultStartDateTime() {
  return DateTime.now()
    .setZone("US/Pacific")
    .set({ hour: 10, minute: 0, second: 0, millisecond: 0 });
}

function getDefaultEndDateTime() {
  return getDefaultStartDateTime().plus({ day: 1 });
}
