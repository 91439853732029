import React from "react";
import { RouteComponentProps } from "@reach/router";
import { NumberParam, useQueryParams } from "use-query-params";
import {
  FreightAgingReportQuery,
  FreightAgingReportDocument,
  FreightAgingReportQueryVariables,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import {
  FreightAgingReportFilters,
  FilterData,
} from "./FreightAgingReportFilters";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { FreightAgingReportTable } from "./FreightAgingReportTable";

export function FreightAgingReportPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    regionId: NumberParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    FreightAgingReportQuery,
    FreightAgingReportQueryVariables
  >({
    options: {
      variables: {
        filters,
      },
    },
    paginationOptions: {
      pageSize: 50,
    },
    query: FreightAgingReportDocument,
    mapDataToTotal({ freightAgingReport }) {
      return freightAgingReport.total;
    },
  });

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  const { data: currentData, previousData } = queryResult;
  const data = currentData ?? previousData;

  const breadcrumbProps = {
    items: buildBreadcrumbs("Brewery Purchase Orders"),
  };

  return (
    <Page
      heading={
        <PageHeading
          title="Freight Aging Report"
          breadcrumbProps={breadcrumbProps}
        />
      }
    >
      <div className="mb-3">
        <FreightAgingReportFilters
          onFilter={updateFilters}
          defaultValues={filters}
        />
      </div>
      {data && (
        <FreightAgingReportTable
          data={data.freightAgingReport.results}
          freightSLT={data.freightSLT}
          pagination={pagination}
        />
      )}
    </Page>
  );
}
