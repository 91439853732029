import React, { useMemo } from "react";
import { AdminOfferWindowTableDataFragment } from "@apollo/ops";
import { Table } from "@ui/Table";
import { Column, TableInstance, useTable } from "react-table";
import { formatAsDate } from "@utilities/formatDate";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { AdminOfferWindowLink } from "@admin/components/AdminOfferWindowLink";
import { CloneOfferWindowButton } from "@admin/AdminMerchandisingRouter/AdminOfferWindowsRouter/AdminOfferWindowsPage/CloneOfferWindow/CloneOfferWindow";
import { navigate } from "@reach/router";
import { adminOfferWindowPath } from "@routes";

type AdminOfferWindowsTableProps = {
  data: Array<AdminOfferWindowTableDataFragment>;
  pagination?: UseOffsetPaginationResult;
};

export function AdminOfferWindowsTable({
  data,
  pagination,
}: AdminOfferWindowsTableProps) {
  const columns: Array<Column<AdminOfferWindowTableDataFragment>> = useMemo(
    () => [
      {
        Header: "ID",
        Cell: ({ row }) => <AdminOfferWindowLink offerWindow={row.original} />,
        accessor: "id",
        width: 80,
      },
      {
        Header: "Region",
        accessor: "regionV2",
        Cell: ({ value }) => value.friendlyName,
        width: 120,
      },
      {
        Header: "Start",
        Cell: ({ value }) => formatAsDate(value),
        accessor: "start",
        width: 140,
      },
      {
        Header: "End",
        Cell: ({ value }) => formatAsDate(value),
        accessor: "end",
        width: 100,
      },
      {
        Header: "Clone Offer Window",
        Cell: ({ value }) => (
          <CloneOfferWindowButton
            originalOfferWindowId={value}
            onAdd={(data) =>
              navigate(adminOfferWindowPath(data.cloneOfferWindow.id))
            }
          />
        ),
        accessor: "id",
        id: "cloneOfferWindow",
        width: 100,
      },
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<AdminOfferWindowTableDataFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
