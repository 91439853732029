import React, { ReactNode } from "react";
import { PageHeading } from "@ui/Page";
import {
  CalendarIcon,
  IdentificationIcon,
  LocationMarkerIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { formatAsDate } from "@utilities/formatDate";
import { ProducerPurchaseOrderOverviewQuery } from "@apollo/gen/generatedOperations";
import { BreadcrumbProps } from "@ui/Breadcrumbs";

type ProducerPurchaseOrderPageHeadingProps = {
  order: ProducerPurchaseOrderOverviewQuery["order"];
  page?: string;
  actions?: ReactNode;
  breadCrumbs?: BreadcrumbProps;
};

export function ProducerPurchaseOrderPageHeading({
  page,
  order,
  actions,
  breadCrumbs,
}: ProducerPurchaseOrderPageHeadingProps) {
  const businessMetaProp = (() => {
    return {
      icon: UserIcon,
      text: order.seller.displayName,
    };
  })();

  const metaProps = {
    items: [
      {
        icon: IdentificationIcon,
        text: "#" + order.number,
      },
      businessMetaProp,
      {
        icon: LocationMarkerIcon,
        text: order.regionV2.friendlyName,
      },
      {
        icon: CalendarIcon,
        text: formatAsDate(order.createdAt),
      },
    ],
  };

  const title = "Order " + (page || "Overview");

  return (
    <PageHeading
      title={title}
      metaProps={metaProps}
      breadcrumbProps={breadCrumbs}
      actions={actions}
    />
  );
}
