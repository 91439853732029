import { Button } from "@components/ui/Button";
import { CardBody } from "@components/ui/Card/Card";
import React, { FC } from "react";

export const AddTrackingInformation: FC<{
  onShippingInformationClick: () => void;
}> = ({ onShippingInformationClick }) => {
  return (
    <CardBody>
      <Button onClick={onShippingInformationClick}>
        Add Tracking Information
      </Button>
    </CardBody>
  );
};
