import { useCallback, useEffect, useState } from "react";
import { DWOLLA_ENVIRONMENT } from "../config";
/**
 * The "dwolla" variable is introduced in the global scope by Dwolla's library "dwolla-web."
 */
declare const dwolla: any;

interface UseLoadDwollaLibOptions {
  environment?: "sandbox" | "production";
  tokenUrl?: string;
  success: (props: any) => any;
  error: (error: any) => any;
}

export const useLoadDwollaLib = ({
  success,
  error,
  tokenUrl = `${import.meta.env.VITE_DIRECT_BEER_API_URI}dwolla/token`,
  environment = DWOLLA_ENVIRONMENT,
}: UseLoadDwollaLibOptions) => {
  const [isConfigured, setIsConfigured] = useState(false);
  const [errorLoadingScript, setErrorLoadingScript] = useState<Error | null>(
    null
  );
  const [dwollaScriptLoaded, setDwollaScriptLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setDwollaScriptLoaded(true);
  }, [setDwollaScriptLoaded]);
  const onErrorLoadingScript = useCallback(() => {
    setErrorLoadingScript(
      new Error("Error loading dwolla script from external cdn")
    );
  }, [setErrorLoadingScript]);

  //TODO In case we need to include other 3rd library party we can take this code and move it to it's own hook.
  const loadDwollaScript = useCallback(() => {
    const existingScript = document.getElementById("dwolla-components-lib");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "//cdn.dwolla.com/v2.1.4/dwolla-web.js";
      script.id = "dwolla-components-lib";
      script.type = "text/javascript";
      document.body.appendChild(script);
      script.onload = onLoad;
      script.onerror = onErrorLoadingScript;
    } else if (!dwolla) {
      // TODO test if this works
      existingScript.onload = onLoad;
    } else {
      // The script is already loaded
      setDwollaScriptLoaded(true);
    }
  }, [onLoad, onErrorLoadingScript]);

  useEffect(() => {
    loadDwollaScript();
  }, [loadDwollaScript]);

  useEffect(() => {
    if (!dwollaScriptLoaded) {
      return;
    }

    dwolla.configure({
      environment,
      tokenUrl,
      success,
      error,
      styles: "/assets/styles/dwollaCustomStyles.css",
    });

    setIsConfigured(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigured, setIsConfigured, dwollaScriptLoaded, success]);

  return {
    ready: dwollaScriptLoaded && isConfigured,
    error: errorLoadingScript,
  };
};
