import React, { ReactNode } from "react";
import { RouteComponentProps } from "@reach/router";
import IconLogo from "@images/directBeerIcon.png";

export type LoginLayoutProps = {
  title?: string;
  children: ReactNode;
} & RouteComponentProps;

export function LoginLayout({ title, children }: LoginLayoutProps) {
  return (
    <div className="min-h-screen relative bg-gray-50 py-16 tablet:px-6 laptop:px-8">
      <div className="tablet:mx-auto tablet:w-full tablet:max-w-md">
        <img src={IconLogo} className="w-30 mx-auto" alt="Direct Beer" />
        {title && (
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {title}
          </h2>
        )}
      </div>
      <div className="mt-8 tablet:mx-auto tablet:w-full tablet:max-w-md">
        {children}
      </div>
    </div>
  );
}
