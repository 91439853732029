import { Button } from "@ui/Button";
import React from "react";
import {
  PreSaleStatus,
  useGenerateFulfillmentOrdersMutation,
  useAdminPreSaleQuery,
} from "@apollo/gen/generatedOperations";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";

type GenerateFulfillmentOrdersButtonProps = {
  preSaleId: number;
};

export function GenerateFulfillmentOrdersButton({
  preSaleId,
}: GenerateFulfillmentOrdersButtonProps) {
  const { data: preSaleData, refetch: refetchPreSale } = useAdminPreSaleQuery({
    variables: {
      id: Number(preSaleId),
    },
  });

  const [
    generateFulfillmentOrdersMutation,
    { loading: generateFulfillmentOrdersMutationLoading },
  ] = useGenerateFulfillmentOrdersMutation({
    variables: { preSaleId },
  });

  if (
    preSaleData?.preSale?.status != PreSaleStatus.FulfillmentOrdersNotCreated
  ) {
    return null;
  }

  async function onClick() {
    try {
      await generateFulfillmentOrdersMutation();
    } catch (error) {
      // TODO handle other error case
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
    refetchPreSale();
  }

  return (
    <Button
      disabled={!preSaleData || generateFulfillmentOrdersMutationLoading}
      onClick={onClick}
      size="sm"
    >
      Generate Purchase Orders
    </Button>
  );
}
