import React from "react";
import { StoreSalesOrderQuery } from "@apollo/ops";
import { SectionHeading } from "@components/ui/SectionHeadings";
import { Card, CardBody } from "@ui/Card/Card";
import { LedgerDetails } from "@admin/components/LedgerDetails";
import { LedgerStatusBadge } from "@components/LedgerStatusBadge";
import { StoreSalesorderFundsTransferTable } from "./StoreSalesOrderFundsTransferTable";

export function StoreSalesOrderPayments({
  order,
}: {
  order: StoreSalesOrderQuery["retailerSalesOrder"];
}) {
  return (
    <>
      <Card
        headerProps={{
          title: "Payment balances",
          actions: (
            <LedgerStatusBadge status={order.paymentsLedger.status} size="lg" />
          ),
        }}
      >
        <CardBody>
          <LedgerDetails ledger={order.paymentsLedger} />
        </CardBody>
      </Card>
      <div className="mt-12">
        <SectionHeading title="All payments" />
        <StoreSalesorderFundsTransferTable
          fundsTransfers={order.paymentsLedger.transfers}
        />
      </div>
    </>
  );
}
