import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { SidenavLayout } from "@ui/Layouts/SidenavLayout";
import { SecondaryNav } from "@components/SecondaryNav/SecondaryNav";
import { AdminPreSalePage } from "./AdminPreSalePage/AdminPreSalePage";
import { AdminPreSaleProductsPage } from "./AdminPreSaleProductsPage/AdminPreSaleProductsPage";
import { AdminPreSaleOrdersPage } from "./AdminPreSaleOrdersRouter/AdminPreSaleOrdersPage/AdminPreSaleOrdersPage";
import { AdminPreSalePurchaseOrdersPage } from "./AdminPreSalePurchaseOrdersPage/AdminPreSalePurchaseOrdersPage";
import { adminPaths } from "@routes";
import { AdminPreSaleProducersPage } from "@admin/AdminPreSalesContainer/AdminPreSaleRouter/AdminPreSaleProducersPage/AdminPreSaleProducersPage";

type AdminPreSaleRouterProps = RouteComponentProps<{
  preSaleIdParam: string;
}>;

const paths = adminPaths.preSales;

export function AdminPreSaleRouter(props: AdminPreSaleRouterProps) {
  const navItems = [
    {
      path: ".",
      name: "Overview",
    },
    {
      path: paths.orders,
      name: "Orders",
    },
    {
      path: paths.products,
      name: "Products",
    },
    {
      path: paths.purchaseOrders,
      name: "Purchase Orders",
    },
    {
      path: paths.producers,
      name: "Producers",
    },
  ];

  return (
    <SidenavLayout
      nav={
        <SecondaryNav
          resourceType="Pre-Sale"
          resourceIdentifier={"#" + props.preSaleIdParam}
          items={navItems}
        />
      }
    >
      <Router>
        <AdminPreSalePage path="/" />
        <AdminPreSaleOrdersPage path={`${paths.orders}/*`} />
        <AdminPreSaleProductsPage path={`${paths.products}/*`} />
        <AdminPreSalePurchaseOrdersPage path={`${paths.purchaseOrders}/*`} />
        <AdminPreSaleProducersPage path={`${paths.producers}/*`} />
      </Router>
    </SidenavLayout>
  );
}
