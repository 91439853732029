import React, { FC } from "react";
import {
  CellProps,
  Column,
  TableInstance,
  useTable,
  useFlexLayout,
  useSortBy,
} from "react-table";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";
import { Table } from "@ui/Table";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { FundsTransferDetailsFragment, FundsAccountType } from "@apollo/ops";
import { AdminFundsTransferLink } from "@components/FundsTransfer/FundsTransfer";
import { DwollaTransferStatusBadge } from "@components/Order/OrderTransferStatusBadge";

export interface FundsTransferTableProps {
  fundsTransfers: FundsTransferDetailsFragment[];
}

const formatFundName = (
  fundsTransfer: FundsTransferDetailsFragment,
  field: string
) => {
  const fund =
    field === "source" ? fundsTransfer.source : fundsTransfer.destination;
  const accountDesignation = fund.business.displayName;
  const accountType =
    fund.type === FundsAccountType.Balance ? "Balance" : "Bank";

  return `${accountDesignation}:
          ${accountType}`;
};

const columns: Array<Column<FundsTransferDetailsFragment>> = [
  {
    Header: "Date",
    Cell: ({ value }) => formatAsDate(new Date(value)),
    accessor: "createdAt",
    width: 80,
  },
  {
    Header: "Transfer ID",
    Cell: ({ row }: CellProps<FundsTransferDetailsFragment, "string">) => (
      <AdminFundsTransferLink fundsTransfer={row.original} />
    ),
    accessor: "id",
    width: 60,
  },
  {
    Header: "Order ID",
    accessor: "orderId",
    width: 60,
  },
  {
    Header: "Statement Descriptor",
    accessor: "statementDescriptor",
    width: 140,
  },
  {
    Header: "From",
    accessor: (fundsTransfer) => formatFundName(fundsTransfer, "source"),
    width: 150,
  },
  {
    Header: "To",
    accessor: (fundsTransfer) => formatFundName(fundsTransfer, "destination"),
    width: 150,
  },
  {
    Header: "Status",
    Cell: ({ row }: CellProps<FundsTransferDetailsFragment, "string">) => (
      <DwollaTransferStatusBadge status={row.original.status} />
    ),
    accessor: "status",
    width: 100,
  },
  {
    Header: "Amount",
    Cell: ({ value }: CellProps<FundsTransferDetailsFragment, number>) =>
      formatAmountInCents(value),
    accessor: "amount",
    width: 100,
  },
];

export const FundsTransferTableCmp: FC<FundsTransferTableProps> = ({
  fundsTransfers,
}: FundsTransferTableProps) => {
  const tableInstance = useTable(
    {
      columns,
      data: fundsTransfers,
    },
    useFlexLayout,
    useSortBy
  ) as TableInstance<FundsTransferDetailsFragment>;

  return <Table instance={tableInstance} />;
};

export const FundsTransferTable = withErrorBoundary(FundsTransferTableCmp);
