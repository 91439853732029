import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useAdminPreSalePurchaseOrdersQuery } from "@apollo/ops";
import { Page } from "@ui/Page";
import { AdminPreSalePageHeading } from "../AdminPreSalePageHeading";
import { NoDataFound } from "@components/NoDataFound";
import { LoadingPage } from "@components/LoadingPage";
import { AdminPurchaseOrdersTable } from "@admin/components/AdminProducerPurchaseOrdersTable/AdminProducerPurchaseOrdersTable";
import { GenerateFulfillmentOrdersButton } from "./GenerateFulfillmentOrdersButton";

type AdminPreSalePurchaseOrdersPageProps = RouteComponentProps<{
  preSaleIdParam: string;
}>;

export function AdminPreSalePurchaseOrdersPage({
  preSaleIdParam,
}: AdminPreSalePurchaseOrdersPageProps) {
  const { data, error } = useAdminPreSalePurchaseOrdersQuery({
    variables: {
      id: Number(preSaleIdParam),
    },
  });

  if (error) {
    throw error;
  }

  if (data) {
    const { preSale } = data;

    return (
      <Page
        heading={
          <AdminPreSalePageHeading
            preSale={preSale}
            page="Purchase Orders"
            actions={<GenerateFulfillmentOrdersButton preSaleId={preSale.id} />}
          />
        }
      >
        {data.preSale.purchaseOrders.length ? (
          <AdminPurchaseOrdersTable data={data.preSale.purchaseOrders} />
        ) : (
          <NoDataFound resource="purchase orders" />
        )}
      </Page>
    );
  }

  return <LoadingPage />;
}
