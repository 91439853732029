import React from "react";
import { Button } from "@ui/Button";
import { useAdminDeleteRetailerMutation } from "@apollo/ops";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";
import { useNavigate } from "@reach/router";
import { adminRetailersPath } from "@routes";

type DeleteRetailerButtonProps = {
  retailerId: number;
};

export function DeleteRetailerButton({
  retailerId,
}: DeleteRetailerButtonProps) {
  const [deleteRetailer] = useAdminDeleteRetailerMutation();
  const dialog = useDialog();
  const navigate = useNavigate();

  const onConfirm = async () => {
    try {
      const result = await deleteRetailer({
        variables: {
          id: retailerId,
        },
      });

      if (result.data) {
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Successfully deleted ${result.data.deleteRetailer.name}`,
        });
        navigate(adminRetailersPath);
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <>
      <Button destructive kind="secondary" onClick={dialog.open} size="sm">
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this retailer?"
        description="WARNING: Deleting this retailer is non-reversible"
        onConfirm={onConfirm}
        confirmText="Yes, delete this retailer"
        dialog={dialog}
      />
    </>
  );
}
