import { useState } from "react";
import useFormHook, { FormHookOptions } from "@hooks/useFormHook";
import Joi from "joi";
import { useInviteGlobalAdminMutation } from "@apollo/ops";
export interface InviteGlobalAdminFormEnhancerProps {
  onSuccess: () => any;
}
export type InviteGlobalAdminFormEnhancerReturn = FormHookOptions;
export const useInviteGlobalAdminFormEnhancer = ({
  onSuccess,
}: InviteGlobalAdminFormEnhancerProps) => {
  const [joiValidationSchema] = useState(
    Joi.object()
      .keys({
        fullName: Joi.string().required(),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
      })
      .unknown(true)
  );
  const formProps = useFormHook({
    initialFormValues: {},
    onSuccess,
    useMutationHook: useInviteGlobalAdminMutation,
    joiValidationSchema,
  });

  return { ...formProps };
};
