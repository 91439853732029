import { compose } from "lodash/fp";
import {
  InviteGlobalAdminFormViewProps,
  InviteGlobalAdminFormView,
} from "./InviteGlobalAdminFormView";
import {
  InviteGlobalAdminFormEnhancerProps,
  useInviteGlobalAdminFormEnhancer,
} from "./InviteGlobalAdminFormEnhancer";
import { FC } from "react";
import { FormHookResult } from "@hooks/useFormHook";
import withHook from "@hoc/withHook";
import { withErrorBoundary } from "@hoc/withErrorBoundary";

type InviteGlobalAdminFormProps = Omit<
  InviteGlobalAdminFormEnhancerProps & InviteGlobalAdminFormViewProps,
  keyof FormHookResult
>;
export const InviteGlobalAdminForm: FC<InviteGlobalAdminFormProps> = compose(
  withHook(useInviteGlobalAdminFormEnhancer),
  withErrorBoundary
)(InviteGlobalAdminFormView);
