import React from "react";
import {
  AdminOrderSummaryFragment,
  useUpdateOrderDataQuery,
  useUpdateOrderMutation,
} from "@apollo/ops";
import { Button } from "@ui/Button";
import { Dialog, useDialog } from "@ui/Dialog";
import { UpdateOrderForm, UpdateOrderFormData } from "./UpdateOrderForm";

import { toCents } from "@utilities/currency";
import { pick } from "lodash";

type UpdateOrderDialogProps = {
  orderId: number;
  onUpdate?: (result: AdminOrderSummaryFragment) => void;
};

export function UpdateOrderDialog({
  onUpdate,
  orderId,
}: UpdateOrderDialogProps) {
  const { open, close, getDialogProps } = useDialog();
  const [updateOrder] = useUpdateOrderMutation();
  const { data, loading } = useUpdateOrderDataQuery({
    variables: {
      id: orderId,
    },
    fetchPolicy: "cache-first",
  });

  const handleSubmit = async (formData: UpdateOrderFormData) => {
    const { invoice, ...mappedFormData } = formData;
    const data = {
      ...mappedFormData,
      invoice: {
        externalInvoiceId: invoice.externalInvoiceId,
        externalAmountInCents: invoice.externalAmount
          ? toCents(invoice.externalAmount)
          : undefined,
      },
    };

    const result = await updateOrder({
      variables: {
        id: orderId,
        data: data,
      },
    });

    if (result.data) {
      onUpdate && onUpdate(result.data.updateOrder);
      close();
    }
  };

  const defaultValues = data && {
    ...pick(data.order, "placedOn", "deliveredAt", "cancelledAt", "number"),
    invoice: {
      externalAmount: data.order.invoice?.externalAmountInCents
        ? data.order.invoice.externalAmountInCents / 100
        : undefined,
      externalInvoiceId: data.order.invoice?.externalInvoiceId ?? "",
    },
  };

  return (
    <>
      <Button kind="secondary" onClick={open} disabled={loading} size="sm">
        Edit
      </Button>
      {defaultValues && (
        <Dialog {...getDialogProps()} title="Update Order">
          <UpdateOrderForm
            key={orderId}
            type={data.order.type}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
          />
        </Dialog>
      )}
    </>
  );
}
