import React, { useEffect } from "react";
import { compose } from "lodash/fp";
import { RouteComponentProps } from "@reach/router";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import {
  OnboardingDocument,
  OnboardingQuery,
  OnboardingStepType,
  OnboardingQueryResult,
  OnboardingCompleteStepFieldsFragment,
  OnboardingDwollaStepFieldsFragment,
  OnboardingBeneficialOwnersStepFieldsFragment,
  OnboardingBankAccountStepFieldsFragment,
} from "@apollo/ops";
import DwollaStep from "./DwollaStep/DwollaStep";
import BeneficialOwnerStep from "./BeneficialOwnerStep/BeneficialOwnerStep";
import BankAccountStep from "./BankAccountStep/BankAccountStep";
import { CompleteStep } from "./CompleteStep/CompleteStep";
import { StackedLayout } from "@components/ui/Layouts/StackedLayout";
import { Page } from "@components/ui/Page";
import { Steps, PanelStep } from "@components/ui/Steps/PanelSteps";
import { RedirectToOrganization } from "@components/Redirect/RedirectToOrganization/RedirectToOrganization";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { useAuth, withAuthentication } from "@shared/auth";
import Bugsnag from "@bugsnag/browser";

type OnboardingContainerProps = RouteComponentProps<{
  businessEntityId: string;
}>;

type WrappedOnboardingContainerProps = WithQueryComponentProps<
  OnboardingContainerProps,
  OnboardingQuery
>;

export function OnboardingContainer({
  query,
}: WrappedOnboardingContainerProps) {
  // TODO this will be removed
  if (
    !query.data.business.onboarding ||
    query.data.business.completedOnboardingAt
  ) {
    return (
      <RedirectToOrganization
        organizationId={query.data.business.organizationId}
      />
    );
  }

  return (
    <OnboardingPage
      organizationId={query.data.business.organizationId}
      onboarding={query.data.business.onboarding}
      businessType={query.data.business.businessType}
      refetch={query.refetch}
    />
  );
}

interface OnboardingPageProps {
  onboarding: NonNullable<OnboardingQuery["business"]["onboarding"]>;
  organizationId: number;
  refetch: OnboardingQueryResult["refetch"];
  businessType: OnboardingQuery["business"]["businessType"];
}

/**
 * Onboarding workflow for a business entity.
 *
 * @remarks
 * This is component leverages Apollo Client for managing state. Any component
 * in the tree that wants to affect state can do it via the Apollo Client
 * (normally by making a new request and updating the cache, though explicity
 * modifications could work as well).
 */
function OnboardingPage({
  onboarding,
  organizationId,
  businessType,
  refetch,
}: OnboardingPageProps) {
  const indexOfStep = onboarding.steps.findIndex(
    (step) => step === onboarding.currentStep.step
  );

  useEffect(() => {
    Bugsnag.leaveBreadcrumb(
      `Viewing ${onboarding.currentStep.step} onboarding step`,
      { businessEntityId: onboarding.id }
    );
  }, [onboarding.id, onboarding.currentStep.step]);

  /**
   * Ideally each mutation performed would update the relevant onboarding object
   * in the Apollo cache and we wouldn't have to manually call refetch, but this
   * is a quick and dirty way to get it working.
   */
  const nextStep = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: "An error occurred",
        detail: "",
      });
    }
  };

  const renderCurrentStep = () => {
    const currentStep = onboarding.currentStep;

    /**
     * Is there a pattern for rendering components based on AbstractTypes
     * coming back from the GraphQL API that don't rely on 'as' assertions.
     */
    switch (onboarding.currentStep.step) {
      case OnboardingStepType.Dwolla:
        return (
          <DwollaStep
            businessEntityId={onboarding.id}
            step={currentStep as OnboardingDwollaStepFieldsFragment}
            refetchOnboarding={nextStep}
          />
        );
      case OnboardingStepType.BeneficialOwner:
        return (
          <BeneficialOwnerStep
            next={nextStep}
            step={currentStep as OnboardingBeneficialOwnersStepFieldsFragment}
          />
        );
      case OnboardingStepType.BankAccount:
        return (
          <BankAccountStep
            businessEntityId={onboarding.id}
            step={
              onboarding.currentStep as OnboardingBankAccountStepFieldsFragment
            }
          />
        );
      case OnboardingStepType.Complete:
        return (
          <CompleteStep
            businessEntityId={onboarding.id}
            organizationId={organizationId}
            businessType={businessType}
            step={currentStep as OnboardingCompleteStepFieldsFragment}
            refetchOnboarding={nextStep}
          />
        );
      default:
        throw Error(
          `Invalid step '${onboarding.currentStep.step}' in OnboardingPage`
        );
    }
  };

  return (
    <>
      <Steps currentIndex={indexOfStep}>
        <PanelStep key="business" name="Business Verification" />
        <PanelStep key="beneficial" name="Beneficial Owners" />
        <PanelStep key="bank" name="Bank Verification" />
        <PanelStep key="complete" name="Complete Onboarding " />
      </Steps>
      <div className="mt-10">{renderCurrentStep()}</div>
    </>
  );
}

const OnboardingPageWithHeader = ({
  query,
}: WrappedOnboardingContainerProps) => {
  return (
    <StackedLayout gray={false} navRight={<SignOutButton />}>
      <Page>
        <div className="max-w-5xl mx-auto">
          <OnboardingContainer query={query} />
        </div>
      </Page>
    </StackedLayout>
  );
};

function SignOutButton() {
  const { logout } = useAuth();

  return (
    <button type="button" className="link-gray" onClick={logout}>
      Sign out
    </button>
  );
}

export default compose(
  withAuthentication(),
  withErrorBoundary,
  withQuery<OnboardingContainerProps, OnboardingQuery>(OnboardingDocument, {
    mapPropsToOptions: (props) => ({
      // TODO remove non-null assertion
      variables: { id: parseInt(props.businessEntityId!) },

      /**
       * There are Dwolla "drop-in" components under this tree that will lose
       * state when a re-render occurs. We use a cache-first fetchPolicy to
       * prevent this component from triggering unnecessary re-renders.
       */
      fetchPolicy: "cache-first",
    }),
  })
)(OnboardingPageWithHeader);
