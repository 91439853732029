import React from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { AdminDistributorsPage } from "./AdminDistributorsPage/AdminDistributorsPage";
import { AdminDistributorPage } from "./AdminDistributorPage/AdminDistributorPage";

function AdminDistributorsContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminDistributorsPage path="/" />
      <AdminDistributorPage path=":distributorId" />
      <Redirect from="*" to="/not-found" />
    </Router>
  );
}

export default AdminDistributorsContainer;
