import React from "react";
import { Link } from "@reach/router";

export function Footer() {
  return (
    <>
      {/* This example requires Tailwind CSS v2.0+ */}
      <footer className="bg-white">
        <div className="max-w-screen-2xl  mx-auto py-12 px-4 overflow-hidden tablet:px-6 laptop:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div className="px-5 py-2">
              <Link
                to="/faq"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                FAQ
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/privacy-policy"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Privacy Policy
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/terms-of-service"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Terms of Service
              </Link>
            </div>
          </nav>
          <p className="mt-6 text-center text-base text-gray-400">
            © 2020 Direct Beer. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
