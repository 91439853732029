import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useErrorHandler } from "react-error-boundary";

import {
  AdminProducerDistributionDocument,
  AdminProducerDistributionQuery,
  AdminProducerDistributionQueryVariables,
} from "@apollo/ops";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { LoadingIcon } from "@components/ui/Spinner";
import { Card } from "@components/ui/Card/Card";
import { DescriptionListInCard } from "@components/ui/DescriptionList";
import { AdminProducerDistributionRegionRows } from "./AdminProducerDistributionRegionRows";

interface AdminProducerDistributionProps extends RouteComponentProps {
  parsedProducerId: number;
}

type WrappedProps = WithQueryComponentProps<
  AdminProducerDistributionProps,
  AdminProducerDistributionQuery,
  AdminProducerDistributionQueryVariables
>;

export function AdminProducerDistributionWithQuery({ query }: WrappedProps) {
  const { data: results, loading, error } = query;
  useErrorHandler(error);

  return (
    <div className="mt-8">
      <div className="mt-10">
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="mt-8 card">
            <Card title="Select Distributors" className="overflow-visible">
              <DescriptionListInCard>
                <AdminProducerDistributionRegionRows results={results} />
              </DescriptionListInCard>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}

export const AdminProducerDistribution = withQuery<
  AdminProducerDistributionProps,
  AdminProducerDistributionQuery,
  AdminProducerDistributionQueryVariables
>(AdminProducerDistributionDocument, {
  mapPropsToOptions: (props) => ({
    variables: { producerId: props.parsedProducerId },
    fetchPolicy: "cache-first",
  }),
})(AdminProducerDistributionWithQuery);
