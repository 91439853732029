import React, { ReactNode, HTMLAttributes } from "react";
import classnames from "classnames";
import * as CardHeaders from "./CardHeaders";

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  className?: string;
  children?: ReactNode;
  headerProps?: CardHeaders.SimpleProps;
}

export function Card({
  title,
  description,
  headerProps,
  children,
  className,
}: CardProps) {
  const classes = classnames(
    "flex flex-col bg-white shadow overflow-hidden tablet:rounded",
    className
  );

  const assignedHeaderProps =
    (title || headerProps) &&
    ({
      title,
      description,
      ...headerProps,
    } as CardHeaders.SimpleProps);

  return (
    <div className={classes}>
      {assignedHeaderProps && <CardHeaders.Simple {...assignedHeaderProps} />}
      {children}
    </div>
  );
}

export function CardBody({ children }: { children: ReactNode }) {
  return <div className="flex-1 px-3 py-4 tablet:p-5">{children}</div>;
}

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export function Footer({ className, ...rest }: FooterProps) {
  const classes = classnames(
    "flex-none px-4 py-3 bg-gray-50 tablet:px-6",
    className
  );
  return <div className={classes} {...rest}></div>;
}
