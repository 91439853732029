import React, { FC } from "react";
import { LoadingIcon } from "@components/ui/Spinner";
import { useAdminProductQuery } from "@apollo/ops";
import { Page, PageHeading } from "@ui/Page";
import { ProductDetailsCard } from "./ProductDetailsCard";
import { ProductImageCard } from "./ProductImageCard";
import { RouteComponentProps } from "@reach/router";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { DeleteProductButton } from "./DeleteProductButton";
import { UpdateProductDialog } from "../UpdateProduct/UpdateProductDialog";
import { CloneProductDialog } from "../CloneProduct/CloneProductDialog";
import { ValidationResult } from "@components/ValidationResult/ValidationResult";

const Product: FC<{ productId: number }> = ({ productId }) => {
  const { data, loading } = useAdminProductQuery({
    variables: {
      productId,
    },
  });

  if (!data && loading) {
    return (
      <Page>
        <LoadingIcon />
      </Page>
    );
  }

  if (data) {
    const { product } = data;

    const title = `Product ${product.name}`;

    return (
      <div key={product.id}>
        <Page
          heading={
            <PageHeading
              title={title}
              actions={
                <>
                  <CloneProductDialog product={product} />
                  <UpdateProductDialog product={product} />
                  {product.canDelete && (
                    <DeleteProductButton productId={productId} />
                  )}
                </>
              }
            />
          }
        >
          <div className="grid laptop:grid-cols-2 gap-5">
            <div>
              <ProductDetailsCard product={product} />
            </div>
            <div className="laptop:grid-rows-2 space-y-5">
              {product.productImage && (
                <div>
                  <ProductImageCard product={product} />
                </div>
              )}
              <div>
                <ValidationResult validationResult={product.canOfferProduct} />
              </div>
            </div>
          </div>
        </Page>
      </div>
    );
  }

  return null;
};

const RoutedProduct: FC<RouteComponentProps<{ productIdParam: string }>> = ({
  productIdParam,
}) => {
  if (productIdParam === undefined) {
    return <RedirectToNotFound />;
  }

  const parsedProductId = parseInt(productIdParam);
  if (isNaN(parsedProductId)) {
    return <RedirectToNotFound />;
  }

  return <Product productId={parsedProductId} />;
};

export default RoutedProduct;
