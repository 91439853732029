import { PaymentAgingReportResultFragment } from "@apollo/ops";
import { AdminOrderLink } from "@components/Order/Order";
import { AdminProducerLink } from "@components/Producer/Producer";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { Table } from "@ui/Table";
import { formatAsDate } from "@utilities/formatDate";
import React from "react";
import { CellProps, Column, TableInstance, useTable } from "react-table";
import { PaymentProgressBar } from "@components/ProducerPurchaseOrder/PaymentProgressBar";
import {
  PaymentAge,
  PaymentAgeFromDelivery,
} from "@components/ProducerPurchaseOrder/PaymentAge";
import { DeliveryStatusBadge } from "@components/Order/DeliveryStatusBadge";

export const columns: Array<Column<PaymentAgingReportResultFragment>> = [
  {
    Header: "Placed on",
    Cell: ({ value }: CellProps<PaymentAgingReportResultFragment, "string">) =>
      formatAsDate(new Date(value)),
    accessor: "placedOn",
    width: 50,
  },
  {
    Header: "Order",
    Cell: ({ row }: CellProps<PaymentAgingReportResultFragment, "string">) => (
      <>
        <AdminOrderLink order={row.original} />
        {row.original.invoice?.externalInvoiceId && (
          <div className="text-muted">
            {row.original.invoice?.externalInvoiceId}
          </div>
        )}
        <div className="text-sm text-muted">
          {row.original.regionV2.friendlyName}
        </div>
      </>
    ),
    accessor: "number",
    disableSortBy: true,
    id: "number",
    width: 120,
  },
  {
    Header: "Brewery",
    Cell: ({ row }: CellProps<PaymentAgingReportResultFragment>) => (
      <AdminProducerLink producer={row.original.seller} />
    ),
    accessor: (order) => order.seller.displayName,
    id: "seller",
  },
  {
    Header: "Delivery",
    Cell: DeliveryStatusCell,
  },
  {
    Header: "Age from delivery",
    Cell: ({ row }: CellProps<PaymentAgingReportResultFragment, number>) => (
      <div className="w-20">
        <PaymentAgeFromDelivery
          freightDeliveredAt={row.original.deliveredAt ?? null}
          serviceLevelTarget={row.original.paymentStepSLT}
        />
      </div>
    ),
    id: "ageFromFreight",
    width: 90,
  },
  {
    Header: "Payment",
    Cell: ({ row }: CellProps<PaymentAgingReportResultFragment, number>) => (
      <div className="laptop:w-64">
        <PaymentProgressBar order={row.original} />
      </div>
    ),
    id: "progress",
  },
  {
    Header: "Age",
    Cell: ({ row }: CellProps<PaymentAgingReportResultFragment, number>) => (
      <div className="w-20">
        <PaymentAge
          placedOn={row.original.placedOn}
          serviceLevelTarget={row.original.paymentSLT}
        />
      </div>
    ),
    id: "age",
    width: 90,
  },
];

type PaymentAgingReportTableProps = {
  data: Array<PaymentAgingReportResultFragment>;
  pagination?: UseOffsetPaginationResult;
};

export function PaymentAgingReportTable({
  data,
  pagination,
}: PaymentAgingReportTableProps) {
  const tableInstance = useTable({
    columns,
    data,
  }) as TableInstance<PaymentAgingReportResultFragment>;

  return <Table instance={tableInstance} pagination={pagination} align="top" />;
}

function DeliveryStatusCell({
  row,
}: CellProps<PaymentAgingReportResultFragment, number>) {
  const { deliveredAt, deliveryStatus } = row.original;
  return (
    <>
      <DeliveryStatusBadge status={deliveryStatus ?? null} />
      {deliveredAt && (
        <div className="text-gray-500 mt-1.5 ml-2 text-sm">
          Delivered {formatAsDate(deliveredAt)}
        </div>
      )}
    </>
  );
}
