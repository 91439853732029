import React, { ReactNode } from "react";

type SidenavLayoutProps = {
  nav: ReactNode;
  children: ReactNode;
};

export function SidenavLayout({ children, nav }: SidenavLayoutProps) {
  return (
    <div className="h-screen flex flex-col laptop:flex-row overflow-hidden">
      <div className="flex-shrink-0 overflow-y-auto laptop:bg-white border-gray-200 laptop:border-r w-56">
        {nav}
      </div>

      <div className="min-w-0 flex-1 overflow-y-auto">{children}</div>
    </div>
  );
}
