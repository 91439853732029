import { useMemo } from "react";
import { NumberParam, useQueryParams } from "use-query-params";

export type UseOffsetPaginationOptions = {
  pageSize?: number;

  /**
   * Undefined indicates that we don't yet know the totalCount (e.g. the initial
   * request for data has not completed).
   */
  totalCount?: number;
};

export type UseOffsetPaginationResult = {
  paginationParams: {
    skip: number;
    take: number;
  };
  page: number;
  pageSize: number;
  pageCount: number;
  totalCount: number | undefined;
  canNextPage: boolean;
  canPreviousPage: boolean;
  resultsStart: number;
  resultsEnd: number;
  goToPage: (index: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  reset: () => void;
  setPageSize: (size: number) => void;
};

const OPTION_DEFAULTS = {
  pageSize: 25,
};

/**
 * The value of totalCount represents a few distinct scenarios that require
 * different handling:
 *   - undefined means we don't yet know the total count as the
 *     the initial request hasn't completed
 *   - 0 means we have no results
 *   - > 0 means we have results
 */
export function useOffsetPagination(
  options?: UseOffsetPaginationOptions
): UseOffsetPaginationResult {
  const { pageSize: defaultPageSize, totalCount } = {
    ...OPTION_DEFAULTS,
    ...options,
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
  });

  const queriedPage = getSanitizedNumber(queryParams.page, 1);
  const queriedPageSize = getSanitizedNumber(
    queryParams.pageSize,
    defaultPageSize
  );

  const pageSize =
    queriedPageSize > 0 ? queriedPageSize : OPTION_DEFAULTS.pageSize;

  const pageCount = calculatePageCount(totalCount, pageSize);
  /**
   * We use min and max to ensure the page value is within the valid bounds.
   * If totalCount is undefined, we don't yet know the valid bounds and use an
   * arbitrary large number to assuming the queriedPage will be valid until a
   * totalCount is determined.
   */
  const page = Math.min(Math.max(1, queriedPage), totalCount || 10 ** 10);

  const canPreviousPage = page > 1;
  const canNextPage = page < pageCount;

  const skip = (page - 1) * pageSize;
  const take = pageSize;

  const resultsStart = getResultsStart(skip, totalCount);
  const resultsEnd = getResultsEnd(page, pageSize, totalCount);

  const nextPage = () => {
    if (canNextPage) {
      setQueryParams({
        page: page + 1,
      });
    }
  };

  const previousPage = () => {
    if (canPreviousPage) {
      setQueryParams({
        page: page - 1,
      });
    }
  };

  const goToPage = (index: number) => {
    setQueryParams({
      page: index,
    });
  };

  const setPageSize = (size: number) => {
    setQueryParams({
      pageSize: size,
    });
  };

  const reset = () => goToPage(1);

  /**
   * The paginationParams object will be used by consumers of the
   * useOffsetPagination hook to include pagination data in requests to the
   * server.
   *
   * We return a memoized object so consumers only get a new object when the
   * values change, preventing otherwise unnecessary requests from being sent to
   * the server when the object reference changes but the values remain the
   * same.
   */
  const paginationParams = useMemo(
    () => ({
      skip,
      take,
    }),
    [skip, take]
  );

  return {
    paginationParams,
    page,
    pageSize,
    pageCount,
    totalCount,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    goToPage,
    reset,
    setPageSize,
    resultsEnd,
    resultsStart,
  };
}

function getSanitizedNumber(
  value: number | null | undefined,
  defaultValue: number
): number {
  if (value === undefined || value === null || isNaN(value)) {
    return defaultValue;
  }

  return value;
}

/**
 * Returns the total number of pages
 */
function calculatePageCount(totalCount: number | undefined, pageSize: number) {
  /**
   * An undefined totalCount implies we don't yet know how many results there
   * are and a default of one page is returned.
   */
  if (totalCount === undefined) {
    return 1;
  }

  /**
   * An totalCount of zero means there are no results and one empty page.
   */
  if (totalCount === 0) {
    return 1;
  }

  /**
   * The page count is calculated by taking the ceiling of the totalCount
   * divided by pageSize.
   */
  return Math.ceil(totalCount / pageSize);
}

/**
 * Returns the one-based index of the first result shown.
 */
function getResultsStart(skip: number, totalCount: number | undefined) {
  /**
   * If we don't yet know the totalCount or it's 0, there are no results making
   * the first result 0
   */
  if (totalCount === undefined || totalCount === 0) {
    return 0;
  }

  /**
   * Since skip is already our offset from the beginning of the results, we
   * just add one to go from a zero to one based index.
   */
  return skip + 1;
}

/**
 * Returns the one-based index of the last result shown.
 */
function getResultsEnd(
  page: number,
  pageSize: number,
  totalCount: number | undefined
) {
  /**
   * If we don't yet know the totalCount or it's 0, there are no results making
   * the last result 0
   */
  if (totalCount === undefined || totalCount === 0) {
    return 0;
  }

  /**
   * The index of the last result will be the page multiplied by page size
   * except on the last page, where we may have less than a full page of
   * results.
   */
  return Math.min(page * pageSize, totalCount);
}
