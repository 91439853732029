import React, { ReactNode } from "react";
import { AdminOrderRouterQuery } from "@apollo/ops";
import { PageHeading } from "@ui/Page";
import { BreadcrumbItem } from "@ui/Breadcrumbs";
import {
  CalendarIcon,
  IdentificationIcon,
  LocationMarkerIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { formatAsDate } from "@utilities/formatDate";
import {
  adminOrderPath,
  adminOrdersPath,
  adminPaths,
  adminPreSaleOrderPath,
  adminPreSalePath,
  adminPreSalesPath,
} from "@routes";

type AdminOrderPageHeadingProps = {
  order: AdminOrderRouterQuery["order"];
  page?: string;
  actions?: ReactNode;
};

export function AdminOrderPageHeading({
  page,
  order,
  actions,
}: AdminOrderPageHeadingProps) {
  const breadcrumbProps = {
    items: buildOrderBreadcrumbs(order, page),
  };

  const businessMetaProp = (() => {
    switch (order.__typename) {
      case "PreSaleOrder":
        return {
          icon: UserIcon,
          text: order.buyer.displayName,
        };
      case "ProducerPurchaseOrder":
        return {
          icon: UserIcon,
          text: order.seller.displayName,
        };
      case "RetailerSalesOrder":
      case "DistributorPurchaseOrder":
        return {
          icon: UserIcon,
          text: order.producerPurchaseOrder.seller.displayName,
        };
    }
  })();

  const orderIdentifier =
    order.__typename === "ProducerPurchaseOrder" &&
    order.invoice?.externalInvoiceId
      ? `#${order.number} (${order.invoice.externalInvoiceId})`
      : `#${order.number}`;

  const metaProps = {
    items: [
      {
        icon: IdentificationIcon,
        text: orderIdentifier,
      },
      businessMetaProp,
      {
        icon: LocationMarkerIcon,
        text: order.regionV2.friendlyName,
      },
      {
        icon: CalendarIcon,
        text: formatAsDate(order.createdAt),
      },
    ],
  };

  const title = "Order " + (page || "Overview");

  return (
    <PageHeading
      title={title}
      breadcrumbProps={breadcrumbProps}
      metaProps={metaProps}
      actions={actions}
    />
  );
}

function buildOrderBreadcrumbs(
  order: AdminOrderRouterQuery["order"],
  page?: string
) {
  let breadcrumbs: Array<BreadcrumbItem> = [];

  switch (order.__typename) {
    case "PreSaleOrder":
      breadcrumbs = [
        {
          label: "Pre-Sales",
          path: adminPreSalesPath,
        },
        {
          label: "Pre-Sale #" + order.preSale.id,
          path: adminPreSalePath(order.preSale.id),
        },
        {
          label: "Orders",
          path: adminPreSalePath(order.preSale.id) + "/orders",
        },
        {
          label: "#" + order.number,
          path: adminPreSaleOrderPath({
            preSaleId: order.preSale.id,
            orderId: order.id,
          }),
        },
      ];
      break;
    case "RetailerSalesOrder":
      breadcrumbs = buildRetailerSalesOrderBreadcrumbs(order);
      break;
    case "DistributorPurchaseOrder":
      breadcrumbs = buildDistributorPurchaseOrderBreadcrumbs(order);
      break;
    case "ProducerPurchaseOrder":
      breadcrumbs = buildProducerPurchaseOrderBreadcrumbs(order);
  }

  if (page) {
    breadcrumbs.push({
      label: page,
      path: ".",
    });
  }

  return breadcrumbs;
}

type OrderForBreadcrumbs = {
  id: number;
  number: string;
};

type ProducerPurchaseOrderForBreadcrumbs = OrderForBreadcrumbs;

function buildProducerPurchaseOrderBreadcrumbs({
  id,
  number,
}: ProducerPurchaseOrderForBreadcrumbs) {
  return [
    {
      label: "Brewery POs",
      path: adminOrdersPath,
    },
    {
      label: "#" + number,
      path: adminOrderPath(id),
    },
  ];
}

type DistributorPurchaseOrderForBreadcrumb = OrderForBreadcrumbs & {
  producerPurchaseOrder: OrderForBreadcrumbs;
};

function buildDistributorPurchaseOrderBreadcrumbs({
  id,
  producerPurchaseOrder,
}: DistributorPurchaseOrderForBreadcrumb) {
  return [
    ...buildProducerPurchaseOrderBreadcrumbs(producerPurchaseOrder),
    {
      label: "Distributor PO",
      path: adminOrderPath(id),
    },
  ];
}

type RetailerSalesOrderForBreadcrumb = OrderForBreadcrumbs & {
  producerPurchaseOrder: OrderForBreadcrumbs & {
    distributorPurchaseOrder: OrderForBreadcrumbs | null;
  };
};

function buildRetailerSalesOrderBreadcrumbs({
  id,
  number,
  producerPurchaseOrder,
}: RetailerSalesOrderForBreadcrumb) {
  const { distributorPurchaseOrder } = producerPurchaseOrder;

  if (distributorPurchaseOrder) {
    /**
     * Need to do this little data manipulation to be able to re-use
     * buildDistributorPOBreadcrumbs since the nesting is inverted here.
     */
    return [
      ...buildDistributorPurchaseOrderBreadcrumbs({
        ...distributorPurchaseOrder,
        producerPurchaseOrder: producerPurchaseOrder,
      }),
      {
        label: "Retailer Orders",
        path:
          adminOrderPath(distributorPurchaseOrder.id) +
          "/" +
          adminPaths.orders.orders,
      },
      {
        label: "#" + number,
        path: adminOrderPath(id),
      },
    ];
  }

  return [
    ...buildProducerPurchaseOrderBreadcrumbs(producerPurchaseOrder),
    {
      label: "Retailer Orders",
      path:
        adminOrderPath(producerPurchaseOrder.id) +
        "/" +
        adminPaths.orders.orders,
    },
    {
      label: "#" + number,
      path: adminOrderPath(id),
    },
  ];
}
