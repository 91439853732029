import React from "react";
import classNames from "classnames";
import { Dropdown } from "@ui/Dropdown";
import { FieldError } from "react-hook-form";
import { useAdminDeliveryPartnersQuery } from "@apollo/gen/generatedOperations";

type DeliveryPartnersDropdownProps = {
  onChange: (value: number | null) => unknown;
  value?: number | null;
  error?: FieldError;
};

export function DeliveryPartnersDropdown({
  onChange,
  value,
  error,
}: DeliveryPartnersDropdownProps) {
  const { data, loading } = useAdminDeliveryPartnersQuery();

  const deliveryPartners = [
    ...(data?.logisticsPartners ?? []),
    ...(data?.distributors ?? []),
  ];
  //Values in the dropdown are converted to and from strings because it doesn't support numbers
  return (
    <Dropdown
      style={{ width: "100%" }}
      value={value?.toString() || ""}
      disabled={loading}
      options={deliveryPartners.map((deliveryPartner) => ({
        value: deliveryPartner.id.toString(),
        label: deliveryPartner.displayName,
      }))}
      onChange={(e) => {
        if (typeof e === "undefined") {
          onChange(null);
        } else {
          onChange(Number(e));
        }
      }}
      className={classNames({
        "border-red-300": error,
        "border-1": error,
        "text-red-600": error,
      })}
      placeholder="Select a deliveryPartner"
    />
  );
}
