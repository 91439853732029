import { Auth } from "aws-amplify";
import {
  AWS_REGION,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEB_CLIENT_ID,
  COOKIE_DOMAIN,
} from "./config";

export const configure = () => {
  Auth.configure({
    region: AWS_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,

    /**
     * Use local storage if we don't have a COOKIE_DOMAIN specified. This
     * is useful for developing on localhost where an explicit domain can
     * not be set.
     */
    ...(COOKIE_DOMAIN
      ? {
          cookieStorage: {
            domain: COOKIE_DOMAIN,
          },
        }
      : {}),
  });
};

export const getAuthToken = async () => {
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();

  return token;
};

export const getAuthHeader = async (): Promise<string> => {
  const token = await getAuthToken();

  return token ? `Bearer ${token}` : "";
};
