import React from "react";
import { Autocomplete, buildMatchSorterFilter } from "@ui/Autocomplete";
import {
  AutoCompleteBeerStylesFragment,
  useAutoCompleteBeerStylesQuery,
} from "@apollo/gen/generatedOperations";

export type AllBeerStyleAutocompleteProps = {
  onSelect: (beerStyleId: number | null) => void;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
  disabled?: boolean;
};

export function AllBeerStylesAutocomplete(
  props: AllBeerStyleAutocompleteProps
) {
  const { data } = useAutoCompleteBeerStylesQuery();

  if (data) {
    return <BeerStylesAutocomplete {...props} beerStyles={data.beerStyles} />;
  }

  return null;
}

export type BeerStylesAutocompleteProps = {
  onSelect: (beerStyleId: number | null) => void;
  beerStyles: Array<AutoCompleteBeerStylesFragment>;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
  disabled?: boolean;
};

export function BeerStylesAutocomplete({
  label,
  error,
  selectedId,
  beerStyles,
  onSelect,
  onBlur,
  disabled,
}: BeerStylesAutocompleteProps) {
  const handleSelect = (
    selectedBeerStyle?: AutoCompleteBeerStylesFragment | null
  ) => {
    const id = selectedBeerStyle ? selectedBeerStyle.id : null;
    onSelect(id);
  };

  const selectedBeerStyle =
    beerStyles.find((style) => style.id === selectedId) ?? null;

  return (
    <Autocomplete
      label={label}
      error={error}
      hideToggleButton={true}
      placeholder="Search beer styles"
      onSelect={handleSelect}
      disabled={disabled}
      onClickDelete={() => handleSelect(null)}
      onBlur={onBlur}
      items={beerStyles}
      selectedItem={selectedBeerStyle}
      itemToString={beerStyleToString}
      filterItems={filterBeerStyles}
    />
  );
}

const filterBeerStyles = buildMatchSorterFilter<AutoCompleteBeerStylesFragment>(
  {
    keys: [(beerStyle) => beerStyle.name],
  }
);

const beerStyleToString = (
  beerStyle: AutoCompleteBeerStylesFragment | null
): string => (beerStyle ? beerStyle.name : "");
