import React from "react";
import { Button } from "@ui/Button";
import { Dialog, useDialog, UseDialogResult } from "@ui/Dialog";
import { useAddRetailerSalesOrderMutation } from "@apollo/gen/generatedOperations";
import {
  CreateChildRetailerOrderData,
  CreateChildRetailerOrderForm,
} from "./CreateChildRetailerOrderForm";
import { defaultErrorHandler } from "@util/toast";

type CreateChildRetailerOrderWindowButtonProps = Omit<
  CreateChildRetailerOrderDialogProps,
  "dialog"
>;

/**
 * Component that consists in Button that, when clicked, shows a dialog with a form to create a Retailer Sales Order.
 */
export function CreateChildRetailerOrderButton(
  props: CreateChildRetailerOrderWindowButtonProps
) {
  const dialog = useDialog();
  return (
    <>
      <Button onClick={() => dialog.open()}>Add Retailer Sales Order</Button>
      <CreateChildRetailerOrderDialog dialog={dialog} {...props} />
    </>
  );
}

interface CreateChildRetailerOrderDialogProps {
  dialog: UseDialogResult;
  purchaseOrderId: number;
  sellerId: number;
}

/**
 * Component that renders a Dialog with a form to create a Retailer Sales Order.
 */
const CreateChildRetailerOrderDialog = ({
  dialog,
  purchaseOrderId,
  ...formProps
}: CreateChildRetailerOrderDialogProps) => {
  const [addRetailSaleOrder] = useAddRetailerSalesOrderMutation();
  const onSubmit = async (data: CreateChildRetailerOrderData) => {
    try {
      const result = await addRetailSaleOrder({
        variables: {
          data: {
            ...data,
            purchaseOrderId,
          },
        },
      });

      if (result.data) {
        dialog.close();
      }
    } catch (e) {
      defaultErrorHandler(e);
    }
  };
  return (
    <Dialog {...dialog.getDialogProps()} title={"Create Retailer Sales Order"}>
      <CreateChildRetailerOrderForm onSubmit={onSubmit} {...formProps} />
    </Dialog>
  );
};
