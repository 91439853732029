import { Business, RegionV2 } from "@apollo/ops";
import React, { FC } from "react";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";
import { Card, CardBody } from "@ui/Card/Card";
import { Link } from "@reach/router";
import { adminRegionDeliveryPartnerPath } from "@routes";

interface Props {
  deliveryPartner: Pick<Business, "displayName"> | null;
  region: Pick<RegionV2, "id" | "friendlyName">;
  cardTitle?: string;
}

export const DeliveryPartnerCard: FC<Props> = ({
  deliveryPartner,
  region,
  cardTitle = "Delivery Partner",
}) => {
  return (
    <Card data-testid="DeliveryPartnerCard" title={cardTitle}>
      <CardBody>
        {deliveryPartner !== null ? (
          <DescriptionListInCard>
            <LeftAlignedTerm
              term="Name"
              definition={
                <Link
                  className="underline"
                  to={adminRegionDeliveryPartnerPath(region.id)}
                >
                  {deliveryPartner.displayName}
                </Link>
              }
            />
          </DescriptionListInCard>
        ) : (
          <div>
            No delivery partner assigned to{" "}
            <Link
              className="underline"
              to={adminRegionDeliveryPartnerPath(region.id)}
            >
              {region.friendlyName}
            </Link>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
