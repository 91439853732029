import { Address } from "@apollo/ops";
import { AddressCard } from "@components/Address/AddressCard";
import React, { FC } from "react";

interface Props {
  shippingAddress: Address | null;
  cardTitle?: string;
  disabled?: boolean;
}

export const ShippingAddressCard: FC<Props> = ({
  shippingAddress,
  disabled = false,
  cardTitle = "Shipping Address",
}) => {
  return (
    <div data-testid="ShippingAddressCard">
      <AddressCard
        existingAddress={shippingAddress}
        cardTitle={cardTitle}
        disabled={disabled}
      />
    </div>
  );
};
