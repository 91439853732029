import React from "react";
import { useProducerPreSaleUnitsSoldQuery } from "@apollo/gen/generatedOperations";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { reduce, uniq } from "lodash";
import { LoadingIcon } from "@ui/Spinner";
import { Bar } from "react-chartjs-2";
import { formatAsDate } from "@util/formatDate";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const chartBarColors = [
  "#65a30d",
  "#ca8a04",
  "#e11d48",
  "#0d9488",
  "#9333ea",
  "#c66a67",
  "#ea580c",
  "#dc2626",
  "#93e9be",
];

export function ProducerPreSaleUnitsSold({
  producerId,
}: {
  producerId: number;
}) {
  const { data, error } = useProducerPreSaleUnitsSoldQuery({
    variables: { producerId },
  });

  if (error) throw error;

  if (data) {
    const formattedData = reduce(
      data.producerPreSaleUnitsSold,
      (
        acc: {
          label: string;
          backgroundColor: string;
          data: { x: string; y: number }[];
        }[],
        value
      ) => {
        const currentRegionIndex = acc.findIndex(
          (d) => d.label === value.friendlyName
        );
        const exists = currentRegionIndex > -1;

        const dataToAdd = { x: formatAsDate(value.date), y: value.units };
        if (exists) {
          acc[currentRegionIndex].data.push(dataToAdd);
        } else {
          acc = [
            ...acc,
            {
              label: value.friendlyName,
              backgroundColor: chartBarColors[acc.length],
              data: [dataToAdd],
            },
          ];
        }

        return acc;
      },
      []
    );

    const labels = uniq(
      data.producerPreSaleUnitsSold.map((a) => formatAsDate(a.date))
    );

    const options = {
      plugins: {
        title: {
          display: true,
          text: "Pre Sale Units Sold",
        },
        tooltip: {
          callbacks: {
            label: function (context: TooltipItem<"bar">) {
              return `${context.dataset.label}: ${context.formattedValue} units`;
            },
          },
        },
      },
      responsive: true,
      interaction: {
        intersect: false,
      },
      maintainAspectRatio: false,
    };

    return formattedData.length ? (
      <Bar
        datasetIdKey="label"
        options={options}
        data={{
          labels,
          datasets: formattedData,
        }}
      />
    ) : (
      <>No Pre-Sale data yet</>
    );
  }
  return <LoadingIcon />;
}
