import React, { useMemo } from "react";
import { AdminRetailerOrderSummaryFragment } from "@apollo/ops";
import { Table } from "@ui/Table";
import { Column, TableInstance, useFlexLayout, useTable } from "react-table";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";
import { AdminOrderLink } from "@components/Order/Order";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { RetailerOrderStatusTable } from "@admin/components/RetailerSalesOrder/RetailerOrderStatusTable";

type AdminRetailerOrdersTableProps = {
  data: Array<AdminRetailerOrderSummaryFragment>;
  pagination?: UseOffsetPaginationResult;
};

export function AdminRetailerOrdersTable({
  data,
  pagination,
}: AdminRetailerOrdersTableProps) {
  const columns: Array<Column<AdminRetailerOrderSummaryFragment>> = useMemo(
    () => [
      {
        Header: "Created At",
        Cell: ({ value }) => formatAsDate(new Date(value)),
        accessor: "createdAt",
        width: 80,
      },
      {
        Header: "Number",
        Cell: ({ row }) => <AdminOrderLink order={row.original} />,
        accessor: "number",
        width: 140,
      },
      {
        Header: "Buyer",
        accessor: (order: AdminRetailerOrderSummaryFragment) => {
          return order.buyer?.displayName;
        },
        width: 140,
      },
      {
        Header: "POD Uploaded",
        accessor: (order: AdminRetailerOrderSummaryFragment) => {
          // delivered at is only set once the POD is uploaded
          return order.deliveredAt ? (
            <CheckIcon className="w-10 h-10 text-green-600 mr-3" />
          ) : (
            <XIcon className="w-10 h-10 text-red-600 mr-3" />
          );
        },
        width: 140,
      },
      {
        Header: "Status",
        Cell: ({ row }) => <RetailerOrderStatusTable order={row.original} />,
        accessor: "paymentStatus",
        width: 190,
      },
      {
        Header: "Order Total",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "amountInCents",
        width: 100,
      },
      {
        Header: "Adjustment Total",
        Cell: ({ value }) => formatAmountInCents(value, { oppositeSign: true }),
        accessor: "adjustmentTotalInCents",
        width: 100,
      },
      {
        Header: "Final Total",
        Cell: ({ value }) => formatAmountInCents(value),
        accessor: "finalOrderTotalInCents",
        width: 100,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  ) as TableInstance<AdminRetailerOrderSummaryFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
