import React from "react";
import { OnboardingDwollaStepFieldsFragment } from "@apollo/ops";
import { CreateDwollaCustomer } from "@components/dwolla/CreateDwollaCustomer";

interface DwollaStepProps {
  businessEntityId: number;
  step: OnboardingDwollaStepFieldsFragment;
  refetchOnboarding: () => Promise<void>;
}

/**
 * @remarks
 * After a customer uploads a document we refetch the onboarding query as
 * the presence of documents in the document statement will cause a change
 * in their onboarding state.
 */
function DwollaStep({
  businessEntityId,
  step,
  refetchOnboarding,
}: DwollaStepProps) {
  return (
    <div className="max-w-lg mx-auto">
      <CreateDwollaCustomer
        businessEntityId={businessEntityId}
        dwollaCustomer={step.dwollaStepDwollaCustomer}
        dwollaCustomerType={step.customerType}
        onDocumentsResource={refetchOnboarding}
      />
    </div>
  );
}

export default DwollaStep;
