import React from "react";
import { AdminRetailerDetailsFragment } from "@apollo/ops";
import { Card } from "@ui/Card/Card";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";

type RetailerDetailsCardProps = {
  retailer: AdminRetailerDetailsFragment;
};

export function AdminRetailerDetailsCard({
  retailer,
}: RetailerDetailsCardProps) {
  return (
    <Card title="Retailer Information">
      <DescriptionListInCard>
        <LeftAlignedTerm
          term="Region"
          definition={retailer.regionV2.friendlyName}
        />
      </DescriptionListInCard>
    </Card>
  );
}
