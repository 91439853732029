import React from "react";
import { Button } from "@ui/Button";
import { Dialog, useDialog, UseDialogResult } from "@ui/Dialog";
import { defaultErrorHandler } from "@util/toast";
import {
  CloneOfferWindowForm,
  CloneOfferWindowSubmitData,
} from "@admin/AdminMerchandisingRouter/AdminOfferWindowsRouter/AdminOfferWindowsPage/CloneOfferWindow/CloneOfferWindowForm";
import {
  CloneOfferWindowMutation,
  useCloneOfferWindowMutation,
} from "@apollo/gen/generatedOperations";

type CloneOfferWindowButtonProps = {
  originalOfferWindowId: number;
  onAdd: (data: CloneOfferWindowMutation) => void;
};

export function CloneOfferWindowButton({
  originalOfferWindowId,
  onAdd,
}: CloneOfferWindowButtonProps) {
  const dialog = useDialog();

  return (
    <>
      <Button onClick={dialog.open}>Clone offer window</Button>
      <CloneOfferWindowDialog
        originalOfferWindowId={originalOfferWindowId}
        onAdd={onAdd}
        dialog={dialog}
      />
    </>
  );
}

type CloneOfferWindowDialogProps = {
  dialog: UseDialogResult;
  originalOfferWindowId: number;
  onAdd: (data: CloneOfferWindowMutation) => void;
};

export function CloneOfferWindowDialog({
  dialog,
  originalOfferWindowId,
  onAdd,
}: CloneOfferWindowDialogProps) {
  const { getDialogProps } = dialog;

  const [cloneOfferWindow] = useCloneOfferWindowMutation();

  const handleSubmit = async (formData: CloneOfferWindowSubmitData) => {
    try {
      const result = await cloneOfferWindow({
        variables: {
          originalOfferWindowId,
          data: formData,
        },
      });

      if (result.data) {
        dialog.close();
        onAdd && (await onAdd(result.data));
      }
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <Dialog {...getDialogProps()} title="Clone Offer Window" width="xl">
      <CloneOfferWindowForm onSubmit={handleSubmit} />
    </Dialog>
  );
}
