import { useErrorHandler } from "react-error-boundary";
import { BankAccount as BankAccountType } from "@apollo/ops";
import { useAuth } from "@shared/auth";
import {
  useBankAccountManualConfirm,
  useReadBusinessBankAccounts,
  useRemoveOrganizationBankAccount,
} from "../../business/hooks/BankAccountHooks";
import { BankAccountStatusFragment } from "../../business/types/BankAccount";
export interface BankAccountsEnhancerResult {
  data: Array<BankAccountStatusFragment> | undefined;
  loading: boolean;
  onRemove: (
    bankAccount: BankAccountType,
    skipDialog?: boolean,
    onDone?: () => void
  ) => void;
  onAuthorize: (bankAccount: BankAccountType) => void;
}

export const useBankAccountsEnhancer = ({
  businessEntityId,
}: {
  businessEntityId: number;
}) => {
  const {
    bankAccountsData,
    error: errorQuerying,
    loading,
  } = useReadBusinessBankAccounts(businessEntityId);

  const {
    onRemove,
    loading: removingBankAccount,
  } = useRemoveOrganizationBankAccount(businessEntityId);

  const { user } = useAuth();
  if (!user) {
    throw new Error("Need to be logged to check back accounts");
  }

  useErrorHandler(errorQuerying);
  const { onAdd, bankAccount, onAuthorize } = useBankAccountManualConfirm({
    bankAccountsData,
  });

  return {
    data: bankAccountsData,
    loading: loading || removingBankAccount,
    onRemove,
    onAdd,
    bankAccount,
    onAuthorize,
  };
};
