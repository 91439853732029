import {
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import { useEffect, useState } from "react";
import {
  useOffsetPagination,
  UseOffsetPaginationOptions,
  UseOffsetPaginationResult,
} from "./useOffsetPagination";

type OffsetPaginatedVariables = {
  pagination: {
    skip: number;
    take: number;
  };
};

/**
 * The pagination key is omitted from the generic type TVariables as it will be
 * supplied from within the hook.
 */
type UseOffsetPaginatedQueryOptions<
  TData,
  TVariables extends OffsetPaginatedVariables
> = {
  /**
   * Options for pagination
   */
  paginationOptions?: UseOffsetPaginationOptions;

  /**
   * GraphQL query that takes a pagination variables of type OffsetPaginationInput
   */
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;

  /**
   * Additional options passed to useQuery
   */
  options?: QueryHookOptions<TData, Omit<TVariables, "pagination">>;

  /**
   * Used to update the totalCount value used for generating pagination data.
   */
  mapDataToTotal: (data: TData) => number;
};

type UseOffsetPaginatedQueryResult<TData, TVariables> = {
  pagination: UseOffsetPaginationResult & { loading?: boolean };
  queryResult: QueryResult<TData, TVariables>;
};

export function useOffsetPaginatedQuery<
  TData,
  TVariables extends OffsetPaginatedVariables
>({
  paginationOptions,
  query,
  options,
  mapDataToTotal,
}: UseOffsetPaginatedQueryOptions<
  TData,
  TVariables
>): UseOffsetPaginatedQueryResult<TData, OffsetPaginatedVariables> {
  const [total, setTotal] = useState<undefined | number>();

  const pagination: UseOffsetPaginationResult & {
    loading?: boolean;
  } = useOffsetPagination({
    ...paginationOptions,
    totalCount: total,
  });

  const optionsWithPagination: QueryHookOptions<TData, TVariables> = {
    fetchPolicy: "cache-and-network",
    ...options,
    variables: {
      ...options?.variables,
      pagination: pagination.paginationParams,
    },
  } as QueryHookOptions<TData, TVariables>;

  const queryResult = useQuery(query, optionsWithPagination);

  useEffect(() => {
    if (queryResult.data) {
      setTotal(mapDataToTotal(queryResult.data));
    }
  }, [setTotal, mapDataToTotal, queryResult.data]);

  pagination.loading = queryResult.loading;

  return {
    pagination,
    queryResult,
  };
}
