import React, { useState, FC } from "react";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import {
  ProductDisplayFragment,
  ProductsFilterData,
  useProductQuery,
} from "@apollo/ops";
import { Button } from "@components/ui/Button";
import { ProductBackendAutocomplete } from "@ui/Autocomplete/ProductBackendAutocomplete/ProductBackendAutocomplete";
interface AddProductDialogProps {
  onSelect: (productId: ProductDisplayFragment) => unknown;
  onClose: () => unknown;
  filters: ProductsFilterData;
}
//Let the user choose a product by providing an autocomplete
const AddProductDialogCmp: FC<AddProductDialogProps> = ({
  onSelect,
  onClose,
  filters,
}) => {
  const [
    selectedProductItem,
    setSelectedProductItem,
  ] = useState<ProductDisplayFragment>();

  const { data: productDisplayFragment, error } = useProductQuery({
    variables: {
      id: selectedProductItem?.id as number,
    },
    skip: !selectedProductItem,
  });

  if (error) {
    throw error;
  }
  return (
    <>
      <ProductBackendAutocomplete
        selectedId={selectedProductItem?.id}
        onSelect={setSelectedProductItem}
        placeholder={"Type at least 3 characters in the Product's Name"}
        filters={filters}
      />
      <Button
        className="mr-1"
        disabled={!productDisplayFragment}
        onClick={() => {
          if (productDisplayFragment) {
            onSelect(productDisplayFragment.product);
          }

          onClose();
        }}
      >
        Ok
      </Button>
    </>
  );
};

export const AddProductDialog = withErrorBoundary(AddProductDialogCmp);
