import React from "react";
import {
  VerticalNav,
  VerticalNavProps,
} from "@ui/SidebarNavigation/VerticalNav";
import { LoadingIcon } from "@ui/Spinner";

type SecondaryNavProps = VerticalNavProps & {
  loading?: boolean;
  resourceType?: string;
  resourceIdentifier?: string;
};

export function SecondaryNav({
  loading,
  resourceType,
  resourceIdentifier,
  ...rest
}: SecondaryNavProps) {
  return (
    <div className="pt-4 pb-6 laptop:pb-0">
      {loading ? (
        <div className="px-4">
          <LoadingIcon />
        </div>
      ) : (
        <>
          {resourceIdentifier && resourceType && (
            <div className="px-4 mb-3 truncate">
              <div className="text-xs font-medium text-gray-500 ">
                {resourceType}
              </div>
              <div className="font-bold text-sm">{resourceIdentifier}</div>
            </div>
          )}
          <VerticalNav {...rest} />
        </>
      )}
    </div>
  );
}
