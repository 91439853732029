import React, { FC } from "react";
import { Card, CardBody } from "@ui/Card/Card";
import {
  DocumentType,
  OrderDocumentSummaryFragment,
  useAdminOrderDocumentsQuery,
} from "@apollo/gen/generatedOperations";
import { withErrorBoundary } from "@hoc/withErrorBoundary";
import { OrderDocumentUpload } from "./OrderDocumentUpload";
import { DownloadDocument } from "@admin/components/OrderDocumentActionPanel/DownloadDocument";

interface OrderDocumentActionPanelProps {
  orderId: number;
  documentType: DocumentType;
  title: string;
  uploadName: string;
}

/**
 * This component is to link a certain document type to an Order.
 */
export const OrderDocumentActionPanelCmp: FC<OrderDocumentActionPanelProps> = ({
  orderId,
  documentType,
  title,
  uploadName,
}) => {
  const { data: orderDocs, loading, error } = useAdminOrderDocumentsQuery({
    variables: {
      orderId,
    },
  });
  let componentToRender;

  if (error) {
    componentToRender = (
      <div className={"text-center"}>Unable to load document</div>
    );
  }

  if (orderDocs) {
    const byCreationDateASC = (
      documentA: OrderDocumentSummaryFragment,
      documentB: OrderDocumentSummaryFragment
    ): number => {
      if (documentA.createdAt > documentB.createdAt) {
        return 1;
      }
      if (documentA.createdAt < documentB.createdAt) {
        return -1;
      }
      return 0;
    };

    const mostRecentlyUploaded:
      | OrderDocumentSummaryFragment
      | undefined = orderDocs.order.documents
      .filter((document) => document.documentType === documentType)
      .sort(byCreationDateASC)
      .pop();

    componentToRender = mostRecentlyUploaded ? (
      <DownloadDocument docInfo={mostRecentlyUploaded} />
    ) : (
      <OrderDocumentUpload
        orderId={orderId}
        documentType={documentType}
        uploadName={`${orderDocs.order.number} - ${uploadName}`}
      />
    );
  }

  return (
    <Card title={title}>
      <CardBody>{!loading && componentToRender}</CardBody>
    </Card>
  );
};

export const OrderDocumentActionPanel = withErrorBoundary(
  OrderDocumentActionPanelCmp
);
