import React from "react";
import { AdminProductDetailsFragment } from "@apollo/ops";
import { Card } from "@ui/Card/Card";
import { DescriptionListInCard, LeftAlignedTerm } from "@ui/DescriptionList";
import { formatAsDate } from "@utilities/formatDate";
import { formatToDollars } from "@util/currency";

type ProductDetailsCardProps = {
  product: AdminProductDetailsFragment;
};

export function ProductDetailsCard({ product }: ProductDetailsCardProps) {
  return (
    <Card title="Product Details">
      <DescriptionListInCard>
        <LeftAlignedTerm term="Name" definition={product.name} />
        <LeftAlignedTerm
          term="Producer"
          definition={product.producer.displayName}
        />
        <LeftAlignedTerm
          term="Package Type"
          definition={product.packageType.displayName}
        />
        <LeftAlignedTerm
          term="Package Date"
          definition={formatAsDate(product.packageDate)}
        />
        <LeftAlignedTerm
          term="ABV"
          definition={product.abv !== null ? `${product.abv}%` : null}
        />
        <LeftAlignedTerm term="Beer Rating" definition={product.rating} />
        <LeftAlignedTerm term="Style" definition={product.beerStyle?.name} />
        <LeftAlignedTerm term="Description" definition={product.description} />
        <LeftAlignedTerm
          term="Brewery Net Price"
          definition={
            product.breweryNetPrice &&
            formatToDollars({
              cents: product.breweryNetPrice,
            })
          }
        />
      </DescriptionListInCard>
    </Card>
  );
}
