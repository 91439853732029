import React, { useMemo } from "react";
import { CellProps, Column, useSortBy, useTable } from "react-table";
import { Table } from "@ui/Table";
import {
  AdminOrderItemFragment,
  OrderType,
  ProductDisplayFragment,
} from "@apollo/ops";
import { AdminProductLink } from "@components/Product/Product";

export type AdminOrderItemsTableRowData = {
  product: ProductDisplayFragment;
  price: string;
  quantity: number;
  total: string;
  finalQuantity: string;
  finalTotal: string;
  orderId: number;
  offerId?: number | null;
};

export type AdminOrderItemsDisplayTableProps = {
  orderItems: Array<AdminOrderItemsTableRowData>;
  canDelete?: boolean;
  orderType?: OrderType;
};

export function AdminPreSaleOrderItemsDisplayTable({
  orderItems,
}: AdminOrderItemsDisplayTableProps) {
  const columns: Array<Column<AdminOrderItemsTableRowData>> = useMemo(() => {
    return [
      {
        Header: "Product",
        Cell: ({ row }: CellProps<AdminOrderItemFragment, "string">) => (
          <AdminProductLink product={row.original.product} />
        ),
        accessor: "product",
        width: 140,
      },
      {
        Header: "Price",
        accessor: "price",
        width: 140,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        width: 140,
      },
      {
        Header: "Total",
        accessor: "total",
        width: 140,
      },
      {
        Header: "Final Quantity",
        accessor: "finalQuantity",
        width: 140,
      },
      {
        Header: "Final Total",
        accessor: "finalTotal",
        width: 140,
      },
      {
        Header: "offer id",
        accessor: "offerId",
        width: 20,
      },
    ];
  }, []);
  const tableInstance = useTable(
    {
      columns,
      data: orderItems,
    },
    useSortBy
  );

  return <Table instance={tableInstance} />;
}
