import React from "react";
import {
  AdminOfferWindowOfferFragment,
  useAdminOfferWindowOffersQuery,
} from "@apollo/ops";
import { Page } from "@components/ui/Page";
import { LoadingPage } from "@components/LoadingPage";
import { ErrorPage } from "@components/ErrorPage";
import { useDialog } from "@components/ui/Dialog";
import { Button } from "@components/ui/Button";
import { navigate } from "@reach/router";
import { adminOfferWindowsPath } from "@routes";
import { AdminProductLink } from "@components/Product/Product";
import { formatToDollars } from "@utilities/currency";
import { UpdateOfferWindowButton } from "../UpdateOfferWindow/UpdateOfferWindow";
import { AdminOfferWindowRouteProps } from "../AdminOfferWindowRouteProps";
import { AdminOfferWindowPageHeading } from "../AdminOfferWindowPageHeading";
import { DeleteOfferWindowButton } from "../DeleteOfferWindow/DeleteOfferWindowButton";
import { AddOfferDialog } from "./AddOffer/AddOffer";
import { UpdateOfferDialog } from "./UpdateOffer/UpdateOffer";
import { DeleteOfferButton } from "./DeleteOffer/DeleteOfferButton";
import { groupBy, map } from "lodash";
import { DIRECT_BEER_API_URI } from "@config";
import { onExportFileClick } from "@util/files";
import { formatAsDate } from "@util/formatDate";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

type AdminOfferWindowOffersPageProps = AdminOfferWindowRouteProps;

export function AdminOfferWindowOffersPage({
  offerWindowId,
}: AdminOfferWindowOffersPageProps) {
  const { data, loading, error } = useAdminOfferWindowOffersQuery({
    variables: {
      id: offerWindowId,
    },
  });

  const addOfferDialog = useDialog();

  if (loading && !data) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage title="Failed to load offer window" />;
  }

  async function onExportCSV() {
    const csvUrl = `${DIRECT_BEER_API_URI}offerWindow/${offerWindowId}/csv`;
    const fileName = `offerWindow-${
      data?.offerWindow.regionV2.friendlyName
    }-${formatAsDate(data?.offerWindow.start)}.csv`;
    onExportFileClick({ url: csvUrl, fileName });
  }

  if (data) {
    const { offerWindow } = data;

    return (
      <>
        <Page
          heading={
            <AdminOfferWindowPageHeading
              page="Overview"
              offerWindow={offerWindow}
              actions={
                <>
                  <DeleteOfferWindowButton
                    offerWindowId={offerWindow.id}
                    onDelete={() => navigate(adminOfferWindowsPath)}
                    disabled={!offerWindow.canDelete}
                  />
                  <UpdateOfferWindowButton
                    offerWindow={offerWindow}
                    kind="secondary"
                    disabled={!offerWindow.canUpdate}
                  />
                  <Button onClick={addOfferDialog.open}>Add offer</Button>
                  <Button onClick={onExportCSV} kind={"primary"}>
                    Export to CSV
                  </Button>
                </>
              }
            />
          }
        >
          <Offers
            offers={data.offerWindow.offers}
            addOffer={addOfferDialog.open}
          />
        </Page>
        <AddOfferDialog dialog={addOfferDialog} offerWindowId={offerWindowId} />
      </>
    );
  }

  return null;
}

type OffersProps = {
  offers: Array<AdminOfferWindowOfferFragment>;
  addOffer: () => void;
};

function Offers({ addOffer, offers }: OffersProps) {
  if (offers.length === 0) {
    return (
      <div className="muted">
        No offers yet, do you want to{" "}
        <button className="underline" onClick={addOffer}>
          add one?
        </button>
      </div>
    );
  }

  const producerOffers = groupBy(
    offers,
    ({ product }) => product.producer.displayName
  );

  return (
    <div className="space-y-6">
      {map(producerOffers, (offers, producer) => (
        <div key={producer}>
          <div className="pb-3 mb-3 border-b border-gray-200">
            <h3 className="h3">{producer}</h3>
          </div>
          <div className="bg-white shadow overflow-hidden tablet:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {offers.map((offer) => (
                <Offer key={offer.id} offer={offer} />
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}

type OfferProps = {
  offer: AdminOfferWindowOfferFragment;
};

function Offer({ offer }: OfferProps) {
  const updateDialog = useDialog();

  const buildShopifyLink = () => {
    if (offer.shopifyLink) {
      return (
        <a
          className="text-brand-600 hover:text-brand-700"
          href={`https://${offer.shopifyLink}`}
        >
          {offer.shopifyProductId}
        </a>
      );
    }
    return offer.shopifyProductId;
  };

  return (
    <>
      <div className="flex flex-col space-y-4 px-4 py-4 tablet:flex-row tablet:px-6 tablet:space-y-0">
        <div className="tablet:w-1/3">
          <div>
            <AdminProductLink product={offer.product} />
          </div>
        </div>
        <div className="flex-auto flex flex-col space-y-3 laptop:grid laptop:grid-cols-3 laptop:space-y-0 laptop:gap-y-3">
          <div>
            <div className="text-xs text-gray-400 font-bold">
              Platform Price
            </div>
            <div>{formatToDollars({ cents: offer.platformPrice })}</div>
          </div>
          <div>
            <div className="text-xs text-gray-400 font-bold">
              Wholesale Price
            </div>
            <div>{formatToDollars({ cents: offer.wholesalePrice })}</div>
          </div>
          <div>
            <div className="text-xs text-gray-400 font-bold">Quantity</div>
            <div>{offer.quantity}</div>
          </div>
          <div>
            <div className="text-xs text-gray-400 font-bold">Offerable</div>
            {offer.product.canOfferProduct.isValid ? (
              <CheckIcon className="w-7 h-7 text-green-600" />
            ) : (
              <XIcon className="w-7 h-7 text-red-600" />
            )}
          </div>
          {offer.shopifyProductId && (
            <div>
              <div className="text-xs text-gray-400 font-bold">
                Shopify Product Id
              </div>
              <div>{buildShopifyLink()}</div>
            </div>
          )}
          {offer.earliestPickup && (
            <div>
              <div className="text-xs text-gray-400 font-bold">
                Earliest Pickup date
              </div>
              <div>{formatAsDate(offer.earliestPickup)}</div>
            </div>
          )}
        </div>
        <div className="space-x-2">
          <DeleteOfferButton offerId={offer.id} disabled={!offer.canDelete} />
          <Button onClick={updateDialog.open} disabled={!offer.canUpdate}>
            Update
          </Button>
        </div>
      </div>
      <UpdateOfferDialog offer={offer} dialog={updateDialog} />
    </>
  );
}
