import React, { useState } from "react";
import { UpdateBrewery } from "./UpdateBrewery";
import { Button } from "../ui/Button";
import { Dialog } from "primereact/dialog";

type UpdateBreweryModalProps = {
  breweryId: number;
};

export function UpdateBreweryModal({ breweryId }: UpdateBreweryModalProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)} size="sm">
        Edit
      </Button>
      <Dialog
        visible={visible}
        header="Edit"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <UpdateBrewery
          breweryId={breweryId}
          onUpdate={() => setVisible(false)}
        />
      </Dialog>
    </>
  );
}
