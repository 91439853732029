import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Autocomplete, clearOnSelectReducer } from "@ui/Autocomplete";
import classnames from "classnames";
import { debounce } from "lodash";

export interface BackendAutcompleteProps<T> {
  onSelect: (model: T) => void;
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  placeholder?: string;
  data: Array<T>;
  error: Error | undefined | null;
  refetch: (params: { query: string }) => void;
  renderItem: (item: T) => ReactNode;
}

export interface AutocompleteOption {
  id: number;
  name: string;
}

export function BackendAutocomplete<T extends AutocompleteOption>({
  label,
  selectedId,
  onSelect,
  onBlur,
  placeholder = "",
  error,
  refetch,
  data,
  renderItem = defaultRenderItem,
}: BackendAutcompleteProps<T>) {
  const [debouncedUserSearch, setDebounceUserSearch] = useState<
    string | undefined
  >("");

  useEffect(() => {
    if (debouncedUserSearch && debouncedUserSearch.length >= 3) {
      refetch({
        query: debouncedUserSearch || "",
      });
    }
  }, [debouncedUserSearch]);

  const onInputValueChange = useCallback(
    debounce((inputVal?: string) => {
      setDebounceUserSearch(inputVal);
    }, 500),
    [refetch]
  );

  //Since this autocomplete filters in backend, I have to remember the last order, othrewise when you select an item, and the normal behavior removes the text
  //from the input, that triggers the fetch to backend, and now data is an empty array, whiping the data of the last selected order.
  const [lastSelectedItem, setLastSelectedOrder] = useState<
    T | null | undefined
  >(null);

  const handleSelect = (justSelectedModel?: T | null) => {
    /**
     * We don't support deselecting at this point.
     */
    if (justSelectedModel) {
      setLastSelectedOrder(justSelectedModel);
      onSelect(justSelectedModel ?? null);
    }
  };

  const selectedItem =
    selectedId === lastSelectedItem?.id ? lastSelectedItem : null;
  return (
    <div>
      <Autocomplete
        label={label}
        error={error?.message}
        hideToggleButton={true}
        placeholder={placeholder}
        onSelect={handleSelect}
        onBlur={onBlur}
        items={data}
        selectedItem={selectedItem}
        renderItem={renderItem}
        onInputValueChange={onInputValueChange}
        itemToString={autocompleteToString}
        filterItems={filterdata}
        comboboxProps={{
          stateReducer: clearOnSelectReducer,
        }}
        backendFilter={true}
      />
      <div className="mt-3">
        {selectedItem && (
          <div
            className={classnames(
              "px-3 py-2 rounded-md",
              error ? "bg-red-50" : "bg-blue-50"
            )}
          >
            <div className="text-sm">
              <p>Chosen: {selectedItem.name}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Filtering is done serverside so we show all data.
 */
const filterdata = <T extends AutocompleteOption>(data: T[]) => {
  return data;
};

const autocompleteToString = <T extends AutocompleteOption>(
  autocompleteItem: T | null
): string => (autocompleteItem ? `${autocompleteItem.name}` : "");

const defaultRenderItem = <T extends AutocompleteOption>(
  autocompleteItem: T
) => {
  return <div className="text-sm">{autocompleteItem.name}</div>;
};
