import React from "react";
import classnames from "classnames";
import { AutocompleteFundsAccountFragment } from "@apollo/ops";
import {
  Autocomplete,
  ItemState,
  clearOnSelectReducer,
  buildMatchSorterFilter,
} from "@ui/Autocomplete";
import { formatAmountInCents } from "@utilities/currency";

export interface FundsAccountAutocompleteProps {
  onSelect: (fundsAccountId: number | null) => void;
  fundsAccounts: AutocompleteFundsAccountFragment[];
  selectedId?: number | null;
  onBlur?: () => void;
  label?: string;
  error?: string;
}

export function FundsAccountAutocomplete({
  label,
  error,
  selectedId,
  fundsAccounts,
  onSelect,
  onBlur,
}: FundsAccountAutocompleteProps) {
  const handleSelect = (
    selectedFundsAccount?: AutocompleteFundsAccountFragment | null
  ) => {
    const id = selectedFundsAccount ? selectedFundsAccount.id : null;
    onSelect(id);
  };

  const selectedFundsAccount =
    fundsAccounts.find((item) => item.id === selectedId) ?? null;

  return (
    <div className="h-40">
      <Autocomplete
        label={label}
        error={error}
        hideToggleButton={true}
        placeholder="Search for an account"
        onSelect={handleSelect}
        onBlur={onBlur}
        items={fundsAccounts}
        selectedItem={selectedFundsAccount}
        renderItem={renderFundsAccount}
        itemToString={fundsAccountToString}
        filterItems={filterFundsAccounts}
        comboboxProps={{
          stateReducer: clearOnSelectReducer,
        }}
      />

      <div className="mt-3">
        {selectedFundsAccount && (
          <div
            className={classnames(
              "px-3 py-2 rounded-md",
              error ? "bg-red-50" : "bg-blue-50"
            )}
          >
            <div className="text-sm">
              <p className="font-bold text-xs">
                {getBusinessTypeLabel(selectedFundsAccount)}
              </p>
              <p className="font-bold">
                {selectedFundsAccount.business.displayName}
              </p>
              <div>
                {selectedFundsAccount.label}
                {selectedFundsAccount.__typename === "BalanceAccount" && (
                  <span>
                    {" "}
                    - {formatAmountInCents(selectedFundsAccount.balance)}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Filters funds accounts by splitting the search term and chaining calls to
 * matchSorter. The benefit is multi-word queries will return results where the
 * two words are found in different columns as well as when both words match in
 * the same column.
 */
const filterFundsAccounts = buildMatchSorterFilter<AutocompleteFundsAccountFragment>(
  {
    keys: [
      (fundsAccount) => fundsAccount.business.displayName,
      (fundsAccount) => fundsAccount.business.businessType,
      (fundsAccount) => fundsAccount.__typename, //Allows searching on the FundsAccount type like BankAccount or BalanceAccount
    ],
  }
);

const fundsAccountToString = (
  fundsAccount: AutocompleteFundsAccountFragment | null
): string =>
  fundsAccount
    ? `${fundsAccount.business.displayName} ${fundsAccount.label}`
    : "";

const renderFundsAccount = (
  fundsAccount: AutocompleteFundsAccountFragment,
  { highlighted, selected }: ItemState
) => {
  return (
    <div className="text-sm">
      <p
        className={classnames(
          "text-xs",
          selected ? "font-bold" : "font-medium",
          highlighted ? "text-white" : "text-gray-900"
        )}
      >
        {getBusinessTypeLabel(fundsAccount)}
      </p>
      <p
        className={classnames(
          selected ? "font-bold" : "font-medium",
          highlighted ? "text-white" : "text-gray-900"
        )}
      >
        {fundsAccount.business.displayName}
      </p>
      <div
        className={classnames(
          selected ? "font-semibold" : "font-medium",
          highlighted ? "text-white" : "text-gray-500"
        )}
      >
        {fundsAccount.label}{" "}
        {fundsAccount.__typename === "BalanceAccount" && (
          <span> - {formatAmountInCents(fundsAccount.balance)}</span>
        )}
      </div>
    </div>
  );
};

function getBusinessTypeLabel({ business }: AutocompleteFundsAccountFragment) {
  switch (business.__typename) {
    case "Distributor":
      return "Distributor";
    case "Producer":
      return "Producer";
    case "Retailer":
      return `Retailer - ${business.regionV2.friendlyName}`;
    case "BeverageAdvisors":
      return "Beverage Advisors";
  }
}
