import React from "react";
import { RouteComponentProps } from "@reach/router";
import { NumberParam, useQueryParams } from "use-query-params";
import {
  DeliveryAgingReportQuery,
  DeliveryAgingReportDocument,
  DeliveryAgingReportQueryVariables,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import {
  DeliveryAgingReportFilters,
  FilterData,
} from "./DeliveryAgingReportFilters";
import { buildBreadcrumbs } from "@components/ui/Breadcrumbs";
import { DeliveryAgingReportTable } from "./DeliveryAgingReportTable";

export function DeliveryAgingReportPage(_props: RouteComponentProps) {
  const [filters, setFilters] = useQueryParams({
    regionId: NumberParam,
  });

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    DeliveryAgingReportQuery,
    DeliveryAgingReportQueryVariables
  >({
    options: {
      variables: {
        filters,
      },
    },
    paginationOptions: {
      pageSize: 50,
    },
    query: DeliveryAgingReportDocument,
    mapDataToTotal({ deliveryAgingReport }) {
      return deliveryAgingReport.total;
    },
  });

  const updateFilters = (data: FilterData) => {
    pagination.reset();
    setFilters(data);
  };

  const { data: currentData, previousData } = queryResult;

  const breadcrumbProps = {
    items: buildBreadcrumbs("Brewery Purchase Orders"),
  };

  const data = currentData ?? previousData;

  return (
    <Page
      heading={
        <PageHeading
          title="Delivery Aging Report"
          breadcrumbProps={breadcrumbProps}
        />
      }
    >
      <div className="mb-3">
        <DeliveryAgingReportFilters
          onFilter={updateFilters}
          defaultValues={filters}
        />
      </div>
      {data && (
        <DeliveryAgingReportTable
          data={data.deliveryAgingReport.results}
          deliverySLT={data.deliverySLT}
          deliveryStepSLT={data.deliveryStepSLT}
          pagination={pagination}
        />
      )}
    </Page>
  );
}
