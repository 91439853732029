import {
  DocumentType,
  useAddOrderDocumentMutation,
} from "@apollo/gen/generatedOperations";
import { FileGenerator, FileInput } from "@components/FileUpload/FileInput";
import React, { FC } from "react";
import { defaultErrorHandler } from "@util/toast";
interface Props {
  orderId: number;
  documentType: DocumentType;
  uploadName: string;
}

/**
 * Upload a document and links it to an order by creating an OrderDocument model in the backend.
 * @param orderId - The order to which the document belong.
 * @param documentType - The type of document to set in the OrderDocument model.
 * @param uploadName - Name with which to upload the document.
 * @constructor
 */
export const OrderDocumentUpload: FC<Props> = ({
  orderId,
  documentType,
  uploadName,
}) => {
  const [
    addOrderDocumentMutation,
    { loading: uploadingDoc },
  ] = useAddOrderDocumentMutation({
    onError: defaultErrorHandler,
  });

  const onFileSelected = async (files: FileGenerator[]) => {
    if (files.length <= 0) {
      return;
    }
    const remoteFileId = await files[0].getFileId();
    await addOrderDocumentMutation({
      variables: {
        data: {
          documentType: documentType,
          orderId,
          name: uploadName,
          remoteFileId,
        },
      },
    });
  };

  return (
    <FileInput
      label="Document"
      onChange={onFileSelected}
      maxFiles={1}
      disabled={uploadingDoc}
    />
  );
};
