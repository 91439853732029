import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import {
  AdminLogisticsPartnerDisplayFragment,
  AdminLogisticsPartnersDocument,
  AdminLogisticsPartnersQuery,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { AddLogisticsPartnerModal } from "@components/LogisticsPartner/AddLogisticsPartnerModal";
import { Table } from "@components/ui/Table";
import { Column, useTable } from "react-table";
import { Link } from "@ui/Link";

type WrappedProps = WithQueryComponentProps<
  RouteComponentProps,
  AdminLogisticsPartnersQuery
>;

const LogisticsPartners = ({ query }: WrappedProps) => {
  const data = query.data;

  const actionBodyTemplate = ({ id }: { id: number }) => (
    <Link to={id.toString()}>View</Link>
  );

  const columns: Array<Column<AdminLogisticsPartnerDisplayFragment>> = useMemo(
    () => [
      {
        Header: "Name",
        width: 140,
        accessor: "displayName",
      },
      {
        Header: " ",
        width: 140,
        Cell: ({
          row,
        }: {
          row: { original: AdminLogisticsPartnerDisplayFragment };
        }) => actionBodyTemplate(row.original),
      },
    ],
    []
  );
  const table = useTable({ columns, data: data.logisticsPartners });

  return (
    <Page
      heading={
        <PageHeading
          title="Logistics Partners"
          actions={<AddLogisticsPartnerModal />}
        />
      }
    >
      <div className="mt-8 card">
        <Table instance={table} />
      </div>
    </Page>
  );
};

export const AdminLogisticsPartnersPage = withQuery<
  RouteComponentProps,
  AdminLogisticsPartnersQuery
>(AdminLogisticsPartnersDocument)(LogisticsPartners);
