import React, { useMemo } from "react";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { StoreRouterParams } from "./StoreRouterParam";
import { Link, Redirect, RouteComponentProps, Router } from "@reach/router";
import { SidebarLayoutV2 } from "@ui/Layouts/SidebarLayoutV2";
import { DirectBeerSvg } from "@components/DirectBeerSvg";
import {
  SidebarNavigationElement,
  SidebarNavigationV2,
} from "@components/ui/SidebarNavigation/SidebarNavigationV2";
import {
  ClipboardIcon,
  CogIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { RetailerSidebarBottomNav } from "../RetailerSidebarBottomNav";
import { StoreRouterProvider, useStore } from "./StoreRouterProvider";
import { StoreSettingsPage } from "./StoreSettingsPage/StoreSettingsPage";
import { StoreSwitcher } from "./StoreSwitcher";
import { useRetailerApp } from "../RetailerAppProvider";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useRedirectToShopify } from "@hooks/useRedirectToShopify";
import { StorePreSaleOrdersRouter } from "./StorePreSaleOrdersRouter/StorePreSaleOrdersRouter";
import { StorePaths } from "@routes";
import { StoreSalesOrdersRouter } from "./StoreSalesOrdersRouter/StoreSalesOrdersRouter";

export function StoreRouter({
  storeIdParam,
}: RouteComponentProps<StoreRouterParams>) {
  if (storeIdParam === undefined) {
    return <RedirectToNotFound />;
  }

  const parsedStoreId = parseInt(storeIdParam);
  if (isNaN(parsedStoreId)) {
    return <RedirectToNotFound />;
  }

  return <StoreRouterWithSantizedParams storeId={parsedStoreId} />;
}

type StoreRouterWithSantizedParamsProps = {
  storeId: number;
};

function StoreRouterWithSantizedParams({
  storeId,
}: StoreRouterWithSantizedParamsProps) {
  return (
    <StoreRouterProvider storeId={storeId}>
      <SidebarLayoutV2
        mobileSidebar={<Sidebar />}
        mobileLogo={
          <Link to="/" className="flex items-center">
            <DirectBeerSvg fill="#fff" width={40} height={40} />
          </Link>
        }
        desktopSidebar={<Sidebar />}
      >
        <Router>
          <Redirect from="/" to={StorePaths.preSaleOrders} />
          <StorePreSaleOrdersRouter path={`${StorePaths.preSaleOrders}/*`} />
          <StoreSalesOrdersRouter path={`${StorePaths.salesOrders}/*`} />
          <StoreSettingsPage path={StorePaths.settings} />
          <Redirect from="*" to="/not-found" />
        </Router>
      </SidebarLayoutV2>
    </StoreRouterProvider>
  );
}

function Sidebar() {
  const { storePath, storeId } = useStore();
  const {
    organizationPath: retailerPath,
    organizationId: retailerId,
  } = useRetailerApp();

  const { onClick } = useRedirectToShopify({
    path: "/",
    organizationId: retailerId,
  });

  const locationNavElements = useMemo((): SidebarNavigationElement[] => {
    return [
      {
        name: "Beer Shop",
        onClick,
        icon: ShoppingCartIcon,
      },
      {
        name: "Pre-Sale Orders",
        path: storePath(StorePaths.preSaleOrders),
        icon: ClipboardIcon,
      },
      {
        name: "Sales Orders",
        path: storePath(StorePaths.salesOrders),
        icon: ClipboardIcon,
      },
      {
        name: "Store Settings",
        path: storePath(StorePaths.settings),
        icon: CogIcon,
        exactMatch: true,
      },
      {
        path: retailerPath("/"),
        name: "Back to organization",
        icon: ArrowLeftIcon,
      },
    ];
  }, [storePath, onClick, retailerPath]);

  return (
    <div className="flex-1 flex flex-col min-h-0 bg-skin-primary overflow-y-auto">
      <div className="flex-1 flex flex-col pt-3 pb-4">
        <Link to={retailerPath("/")} className="flex items-center">
          <div className="flex items-center flex-shrink-0 px-4">
            <DirectBeerSvg fill="#fff" width={33} height={33} />

            <div className={`ml-3 font-bold text-xl tracking-wide text-white`}>
              Direct Beer
            </div>
          </div>
        </Link>
        <div className="mt-5 flex-1 px-2">
          <StoreSwitcher storeId={storeId} />
          <div className="mt-3 flex-1">
            <SidebarNavigationV2 elements={locationNavElements} />
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 border-t border-white border-opacity-20 py-4">
        <RetailerSidebarBottomNav />
      </div>
    </div>
  );
}
