import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { adminPaths } from "@routes";
import AdminRegionsRouter from "./AdminRegionsRouter/AdminRegionsRouter";
import GlobalAdmins from "./GlobalAdminsContainer/GlobalAdminView";

const { globalConfig: paths } = adminPaths;

function AdminGlobalConfigRouter(_: RouteComponentProps) {
  return (
    <Router>
      <Redirect from="/" to={paths.regions.root} />
      <AdminRegionsRouter path={paths.regions.root + "/*"} />
      <GlobalAdmins path={paths.admins.root + "/*"} />
    </Router>
  );
}

export default AdminGlobalConfigRouter;
