import React from "react";
import { RetailerOrderStatusDataFragment } from "@apollo/ops";
import { LedgerStatusBadge } from "@components/LedgerStatusBadge";
import { DeliveryStatusBadge } from "@components/Order/DeliveryStatusBadge";
import { CancelledStatusBadge } from "@components/Order/CancelledStatusBadge";

type RetailerOrderStatusTable = {
  order: RetailerOrderStatusDataFragment;
};

const termClasses = "text-sm pr-2 pt-1 text-gray-500 font-medium w-36";

export function RetailerOrderStatusTable({ order }: RetailerOrderStatusTable) {
  return (
    <table>
      <tbody>
        {order.cancelledAt ? (
          <tr>
            <td className={termClasses}>Status</td>
            <td className="pt-1">
              <CancelledStatusBadge cancelledAt={order.cancelledAt} />
            </td>
          </tr>
        ) : (
          <>
            <tr>
              <td className={termClasses}>Delivery</td>
              <td className="pt-1">
                <DeliveryStatusBadge status={order.deliveryStatus} />
              </td>
            </tr>
            <tr>
              <td className={termClasses}>Payment</td>
              <td className="pt-1">
                <td>
                  <LedgerStatusBadge status={order.paymentStatus} />
                </td>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
}
