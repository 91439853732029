import React, { useMemo } from "react";
import { Table } from "@components/ui/Table";
import {
  AdminDeleteLicenseMutation,
  AdminRenewLicenseMutation,
  AdminUpdateLicenseMutation,
  LicenseFieldsFragment,
} from "@apollo/ops";
import { Column, TableInstance, useTable, useSortBy } from "react-table";
import { formatAsDate } from "@utilities/formatDate";
import { UpdateLicenseModal } from "./UpdateLicense/UpdateLicenseModal";
/* import { DeleteLicenseModal } from "./DeleteLicense/deleteLicenseModal"; */
import { RenewLicenseModal } from "./RenewLicense/RenewLicenseModal";

type LicensesTableProps = {
  licenses: LicenseFieldsFragment[];
  onUpdate: (data: AdminUpdateLicenseMutation) => void;
  onDelete: (data: AdminDeleteLicenseMutation) => void;
  onRenew: (data: AdminRenewLicenseMutation) => void;
};

export function LicenseTable({
  licenses,
  onUpdate,
  /*   onDelete, */
  onRenew,
}: LicensesTableProps) {
  const columns = useMemo<Column<LicenseFieldsFragment>[]>(
    () => [
      {
        Header: "Created Date",
        Cell: ({ value }) => formatAsDate(new Date(value)),
        accessor: "createdAt",
      },
      {
        Header: "State",
        Cell: ({ value }) => value.name,
        accessor: "stateV2",
      },
      {
        Header: "License",
        accessor: "title",
      },
      {
        Header: "License #",
        accessor: "number",
      },
      {
        Header: "Issue Date",
        id: "startDate",
        Cell: ({ value }) => formatAsDate(new Date(value.startDate)),
        accessor: "currentLicensePeriod",
      },
      {
        Header: "Expiration Date",
        id: "endDate",
        Cell: ({ value }) => formatAsDate(new Date(value.endDate)),
        accessor: "currentLicensePeriod",
      },
      {
        Header: "Document View",
        Cell: ({ value }) => createLink(value),
        accessor: "documentUrl",
      },
      {
        Header: () => null,
        Cell: ({ value }) => getOptions(value),
        accessor: "id",
      },
      {
        Header: () => null,
        id: "renewLicense",
        Cell: ({ value }) => renewLicense(value),
        accessor: "id",
      },
    ],
    []
  );

  const getOptions = (id: number) => {
    return (
      <div className="grid grid-cols-2 space-x-1">
        <UpdateLicenseModal licenseId={id} onUpdate={onUpdate} />
        {/* <DeleteLicenseModal licenseId={id} onDelete={onDelete}/> */}
      </div>
    );
  };

  const renewLicense = (id: number) => {
    return <RenewLicenseModal licenseId={id} onRenew={onRenew} />;
  };

  const createLink = (link: string | null) => {
    if (link) {
      return (
        <a href={link} className="text-indigo-600 hover:text-indigo-900">
          Link
        </a>
      );
    }
    return link;
  };

  const tableInstance = useTable(
    {
      columns,
      data: licenses,
    },
    useSortBy
  ) as TableInstance<LicenseFieldsFragment>;

  return <Table instance={tableInstance} />;
}
