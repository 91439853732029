import React, { useMemo } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import {
  AdminDistributorsQuery,
  AdminDistributorsDocument,
  AdminDistributorDisplayFragment,
} from "@apollo/ops";
import { Page, PageHeading } from "@components/ui/Page";
import { AddDistributorModal } from "@components/Distributor/AddDistributorModal";
import { Table } from "@components/ui/Table";
import { Column, useTable } from "react-table";

type WrappedProps = WithQueryComponentProps<
  RouteComponentProps,
  AdminDistributorsQuery
>;

const Distributors = ({ query }: WrappedProps) => {
  const data = query.data.distributors;

  const actionBodyTemplate = ({ id }: { id: number }) => (
    <Link to={id.toString()}>View</Link>
  );

  const columns: Array<Column<AdminDistributorDisplayFragment>> = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
        width: 140,
      },
      {
        Header: " ",
        width: 140,
        Cell: ({
          row,
        }: {
          row: { original: AdminDistributorDisplayFragment };
        }) => actionBodyTemplate(row.original),
      },
    ],
    []
  );
  const table = useTable({ columns, data });
  return (
    <Page
      heading={
        <PageHeading title="Distributors" actions={<AddDistributorModal />} />
      }
    >
      <div className="mt-8 card">
        <Table instance={table} />
      </div>
    </Page>
  );
};

export const AdminDistributorsPage = withQuery<
  RouteComponentProps,
  AdminDistributorsQuery
>(AdminDistributorsDocument)(Distributors);
