import React, { useMemo } from "react";
import { ProducerSalesByRegionFragment } from "@apollo/gen/generatedOperations";
import { Column, useTable } from "react-table";
import { Table } from "@ui/Table";
import { formatAmountInCents } from "@util/currency";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";

export function ProducerSalesByRegionTable({
  tableData,
  pagination,
}: {
  tableData: Array<ProducerSalesByRegionFragment>;
  pagination: UseOffsetPaginationResult;
}) {
  const columns: Array<Column<ProducerSalesByRegionFragment>> = useMemo(
    () => [
      {
        Header: "Retailer Name",
        accessor: "retailer_name",
        width: 140,
      },
      {
        Header: "Retailer Region",
        accessor: "retailer_region",
        width: 140,
      },
      {
        Header: "Retailer Email",
        accessor: "retailer_email",
        width: 140,
      },
      {
        Header: "Order Count",
        accessor: "order_count",
        width: 140,
      },
      {
        Header: "Total Ordered",
        Cell: ({ value }) => formatAmountInCents(value * 100),
        accessor: "total_ordered",
        width: 140,
      },
    ],
    []
  );
  const tableInstance = useTable({
    columns,
    data: tableData,
  });

  return <Table instance={tableInstance} pagination={pagination} />;
}
