import React, { ReactNode, useLayoutEffect } from "react";
import { Breadcrumbs, BreadcrumbProps } from "../../Breadcrumbs";
import { PageHeadingMeta, PageHeadingMetaProps } from "./PageHeadingMeta";

export interface PageHeadingProps {
  /**
   * If a string is passed here then it will also be used to set the document
   * title. If any other type is provided the document title will need supplied
   * in the documentTitle prop.
   */
  title: string;
  subtitle?: ReactNode;
  actions?: ReactNode;
  breadcrumbProps?: BreadcrumbProps;
  metaProps?: PageHeadingMetaProps;
}

/**
 * Page Heading component based off Tailwind UI
 * @see https://tailwindui.com/components/application-ui/headings/page-headings
 */
export function PageHeading({
  title,
  subtitle,
  actions,
  breadcrumbProps,
  metaProps,
}: PageHeadingProps) {
  // update the document title before this component renders
  useLayoutEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div>
      {breadcrumbProps && (
        <div className="mb-0.5">
          <Breadcrumbs {...breadcrumbProps} />
        </div>
      )}
      <div className="laptop:flex laptop:items-center laptop:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 tablet:text-2xl tablet:truncate">
            {title}
          </h2>
          {subtitle && (
            <p className="text-sm font-medium text-gray-500">{subtitle}</p>
          )}
        </div>
        {actions && (
          <div className="my-3 flex flex-col space-y-2 tablet:space-y-0 tablet:flex-row tablet:space-x-3 laptop:my-0 laptop:ml-4">
            {actions}
          </div>
        )}
      </div>
      {metaProps && <PageHeadingMeta {...metaProps} />}
    </div>
  );
}
