import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useErrorHandler } from "react-error-boundary";

import {
  AdminRegionPlatformFeesDocument,
  AdminRegionPlatformFeesQuery,
  AdminRegionPlatformFeesQueryVariables,
  useAdminAddPlatformFeeMutation,
  useAdminUpdatePlatformFeeMutation,
} from "@apollo/ops";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import { Card } from "@components/ui/Card/Card";
import { DescriptionListInCard } from "@components/ui/DescriptionList";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";
import { toDollars } from "@utilities/currency";
import {
  AdminPlatformFeeRowData,
  AdminPlatformFeesRows,
} from "./AdminPlatformFeesRows";

interface AdminPlatformFeesProps extends RouteComponentProps {
  regionId: string;
}

type WrappedProps = WithQueryComponentProps<
  AdminPlatformFeesProps,
  AdminRegionPlatformFeesQuery,
  AdminRegionPlatformFeesQueryVariables
>;

export function AdminPlatformFeesWithQuery({ query, regionId }: WrappedProps) {
  const { data: results, error, refetch } = query;
  useErrorHandler(error);

  const parsedRegionId = parseInt(regionId);
  const [addPlatformFee] = useAdminAddPlatformFeeMutation();
  const [updatePlatformFee] = useAdminUpdatePlatformFeeMutation();

  const onBlur = async (data: AdminPlatformFeeRowData) => {
    try {
      if (data.platformFeeId) {
        await updatePlatformFee({
          variables: {
            id: data.platformFeeId,
            data: {
              amount: data.amount,
            },
          },
        });
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Updated platform fee to $${toDollars(data.amount)}`,
        });
      } else {
        await addPlatformFee({
          variables: {
            data: {
              amount: data.amount,
              packageTypeId: data.packageTypeId,
              regionId: parsedRegionId,
            },
          },
        });
        await refetch();
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Added platform fee of $${toDollars(data.amount)}`,
        });
      }
    } catch (error) {
      console.error(error);
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <div className="mt-8">
      <div className="mt-10">
        {results && (
          <div className="mt-8 card">
            <Card title="Platform Fees" className="overflow-visible">
              <DescriptionListInCard>
                <AdminPlatformFeesRows results={results} onBlur={onBlur} />
              </DescriptionListInCard>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}

export const AdminPlatformFees = withQuery<
  AdminPlatformFeesProps,
  AdminRegionPlatformFeesQuery,
  AdminRegionPlatformFeesQueryVariables
>(AdminRegionPlatformFeesDocument, {
  mapPropsToOptions: (props) => ({
    variables: { regionId: parseInt(props.regionId) },
    fetchPolicy: "cache-and-network",
  }),
})(AdminPlatformFeesWithQuery);
