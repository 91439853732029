import React, { FC } from "react";
import { RouteComponentProps } from "@reach/router";
import { Page, PageHeading } from "@ui/Page";
import { AdminTransferDetailsCard } from "./AdminTransferDetailsCard";
import {
  useAdminTransferQuery,
  useSyncFundsTransferMutation,
} from "@apollo/gen/generatedOperations";
import { RedirectToNotFound } from "@components/Redirect/RedirectToNotFound";
import { LoadingPage } from "@components/LoadingPage";
import { RefreshButton } from "@components/ui/Button";

const AdminTransferPage: FC<{ transferIdParam: number }> = ({
  transferIdParam,
}) => {
  const { data, loading } = useAdminTransferQuery({
    variables: {
      id: transferIdParam,
    },
  });

  const [sync, { loading: loadingSync }] = useSyncFundsTransferMutation({
    variables: {
      fundsTransferId: transferIdParam,
    },
  });

  if (loading) {
    return <LoadingPage />;
  }

  if (data) {
    return (
      <Page
        heading={
          <PageHeading
            title={`Transfer #${data.fundsTransfer.id}`}
            actions={
              <RefreshButton
                loading={loadingSync}
                onClick={() => sync()}
                kind="secondary"
              >
                sync
              </RefreshButton>
            }
          />
        }
      >
        <AdminTransferDetailsCard transfer={data.fundsTransfer} />
      </Page>
    );
  }

  return <div>Missing data</div>;
};

const RoutedAdminTransferPage: FC<
  RouteComponentProps<{ transferIdParam: string }>
> = ({ transferIdParam }) => {
  if (transferIdParam === undefined) {
    return <RedirectToNotFound />;
  }

  const parsedTransferID = parseInt(transferIdParam);
  if (isNaN(parsedTransferID)) {
    return <RedirectToNotFound />;
  }

  return <AdminTransferPage transferIdParam={parsedTransferID} />;
};

export default RoutedAdminTransferPage;
