import React from "react";
import { Button } from "@components/ui/Button";
import SpinnerOverlay from "@components/SpinnerOverlay/SpinnerOverlay";
import CheersImage from "@images/cheers.png";
import { Dialog } from "@components/ui/Dialog";
import { CheckIcon } from "@heroicons/react/outline";

export interface WelcomeViewProps {
  onContinueClick: () => void;
  loading: boolean;
}

export const WelcomeView: React.FC<WelcomeViewProps> = ({
  loading,
  onContinueClick,
}) => {
  return (
    <SpinnerOverlay spin={loading}>
      <Dialog.Body>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center tablet:mt-5">
          <Dialog.Title className="text-2xl leading-6 font-medium text-gray-900">
            Cheers!
          </Dialog.Title>

          <Dialog.Description as="div" className="mt-2">
            <p className="text-base text-gray-500">
              You have succesfully set up your account&mdash;let&apos;s sell
              some beer!
            </p>
          </Dialog.Description>

          <Button onClick={onContinueClick} size="xl" className="mt-8 mb-2">
            Continue
          </Button>
        </div>
      </Dialog.Body>
      <img src={CheersImage} alt="Cheers" />
    </SpinnerOverlay>
  );
};
