import { isNil, reject } from "lodash";
import { BusinessType } from "./apollo";

/**
 * Auth
 */

export const authAppBase = "/auth";

export const authPaths = {
  base: "/auth",
  signIn: "sign-in",
  signUp: "sign-up",
  forgotPassword: "forgot-password",
  confirmForgotPassword: "confirm-forgot-password",
  newPassword: "new-password",
  confirmSignUp: "confirm-sign-up",
  confirmSignIn: "confirm-sign-in",
  acceptInvitation: "accept-invitation",
};

const authAppPath = (path: string) => `${authAppBase}/${path}`;

export const signInPath = authAppPath(authPaths.signIn);
export const signUpPath = authAppPath(authPaths.signUp);
export const forgotPasswordPath = authAppPath(authPaths.forgotPassword);
export const confirmForgotPasswordPath = authAppPath(
  authPaths.confirmForgotPassword
);
export const newPasswordPath = authAppPath(authPaths.newPassword);
export const confirmSignUpPath = authAppPath(authPaths.confirmSignUp);
export const confirmSignInPath = authAppPath(authPaths.confirmSignIn);
export const acceptInvitationPath = authAppPath(authPaths.acceptInvitation);

/**
 * Retailer
 */

export const retailerAppBase = "/retailer";

export const retailerPaths = {
  transfers: "transfers",
};

const retailerAppPath = (path: string) => (retailerId: number) =>
  `${retailerAppBase}/${retailerId}/${path}`;

export const retailerPath = (retailerId: number) =>
  `${retailerAppBase}/${retailerId}`;

export const retailerTransfersPath = retailerAppPath(retailerPaths.transfers);

export const retailerStorePath = (retailerId: number, storeId: number) =>
  `${retailerAppBase}/${retailerId}/stores/${storeId}`;

export enum RetailerOrgPaths {
  stores = "stores",
  payments = "payments",
}

export enum StorePaths {
  preSaleOrders = "presale-orders",
  salesOrders = "sales-orders",
  settings = "settings",
}

/**
 * Producer
 */
export const producerAppBase = "/producer";

export const producerPaths = {
  orders: "orders",
  order: {
    shipping: "shipping",
    items: "items",
    documents: "documents",
  },
};

export const producerPath = (producerId: number) =>
  `${producerAppBase}/${producerId}`;

const producerAppPath = (path: string) => (producerId: number) =>
  `${producerPath(producerId)}/${path}`;

export const producerOrdersPath = producerAppPath(producerPaths.orders);

export const producerOrderPath = (orderId: number) => (producerId: number) =>
  `${producerAppPath(producerPaths.orders)(producerId)}/${orderId}`;

/**
 * Admin
 */
export const adminPaths = {
  root: "admin",
  orders: {
    root: "orders",
    documents: "documents",
    history: "history",
    orders: "orders",
    payments: "payments",
    items: "items",
    shipping: "shipping",
    transfers: "transfers",
    platformFees: "platformFees",
  },
  purchaseOrders: {
    root: "purchase-orders",
    agingReport: "aging-report",
    deliveryAgingReport: "delivery-aging-report",
    paymentAgingReport: "payment-aging-report",
    retailerDeliveryAgingReport: "retailer-delivery-aging-report",
  },
  merchandising: {
    root: "merchandising",
    offerWindows: {
      root: "offer-windows",
      offers: {
        root: "offers",
      },
    },
    products: {
      root: "products",
    },
    packages: {
      root: "packages",
    },
  },
  globalConfig: {
    root: "config",
    regions: {
      root: "regions",
    },
    admins: {
      root: "admins",
    },
  },
  preSales: {
    root: "pre-sales",
    orders: "orders",
    items: "pre-sale-items",
    products: "products",
    producers: "producers",
    purchaseOrders: "purchase-orders",
    importJobs: {
      root: "import-jobs",
    },
    preSale: (preSaleId: string | number) => ({
      products: `pre-sales/${preSaleId}/products`,
      orders: `pre-sales/${preSaleId}/orders`,
    }),
  },
  producers: {
    root: "producers",
    regions: {
      root: "regions",
    },
  },
  distributors: {
    root: "distributors",
  },
  logisticsPartners: {
    root: "logistics-partners",
  },
  retailers: {
    root: "retailers",
  },
  transfers: {
    root: "transfers",
  },
  shipments: {
    root: "shipments",
  },
};

export const adminPath = "/" + adminPaths.root;

/**
 * Admin Orders
 */
export const adminOrdersPath = [
  "",
  adminPaths.root,
  adminPaths.orders.root,
].join("/");

export const adminOrderPath = (orderId: number) =>
  join(adminOrdersPath, orderId);

export const adminOrderShippingPath = (orderId: number) =>
  join(adminOrderPath(orderId), adminPaths.orders.shipping);

/**
 * Admin -> Purchase Orders
 */
export const adminPurchaseOrdersPath = (path?: string) =>
  join("", adminPaths.root, adminPaths.purchaseOrders.root, path);

export const adminMerchandisingPath = [
  "",
  adminPaths.root,
  adminPaths.merchandising.root,
].join("/");

/**
 * Admin Merchandising -> Products
 */
export const adminProductsPath = join(
  adminMerchandisingPath,
  adminPaths.merchandising.products.root
);

export const adminProductPath = (productId: number) =>
  join(adminProductsPath, productId);

/**
 * Admin Merchandising -> Products
 */
export const adminPackagesPath = join(
  adminMerchandisingPath,
  adminPaths.merchandising.packages.root
);

/**
 * Admin -> Merchandising -> Offer Windows
 */
export const adminOfferWindowsPath = join(
  adminMerchandisingPath,
  adminPaths.merchandising.offerWindows.root
);

export const adminOfferWindowPath = (offerWindowId: number) =>
  join(adminOfferWindowsPath, offerWindowId);

/**
 * Admin Producers
 */
export const adminProducersPath = [
  "",
  adminPaths.root,
  adminPaths.producers.root,
].join("/");

/**
 * Admin Producers -> Regions
 */
export const adminProducerPath = (producerId: number) =>
  join(adminProducersPath, producerId);

export const adminProducerRegionsPath = (producerId: number) =>
  join(adminProducerPath(producerId), adminPaths.producers.regions.root);

export const adminProducerRegionPath = (producerId: number, regionId: number) =>
  join(adminProducerRegionsPath(producerId), regionId);

/**
 * Admin Retailers
 */
export const adminRetailersPath = [
  "",
  adminPaths.root,
  adminPaths.retailers.root,
].join("/");

export const adminRetailerPath = (retailerId: number) =>
  join(adminRetailersPath, retailerId);

/**
 * Admin Distributors
 */
export const adminDistributorsPath = [
  "",
  adminPaths.root,
  adminPaths.distributors.root,
].join("/");
/**
 * Admin Logistics Partners
 */
export const adminLogisticsPartnersPath = [
  "",
  adminPaths.root,
  adminPaths.logisticsPartners.root,
].join("/");

export const adminLogisticsPartnerPath = (logisticsPartnerId: number) =>
  join(adminLogisticsPartnersPath, logisticsPartnerId);

/**
 * Admin Pre-Sales
 */
export const adminPreSalesPath = [
  "",
  adminPaths.root,
  adminPaths.preSales.root,
].join("/");

export const adminPreSalePath = (preSaleId: number) =>
  join(adminPreSalesPath, preSaleId);

export const adminPreSalePurchaseOrdersPath = (preSaleId: number) =>
  join(adminPreSalePath(preSaleId), adminPaths.preSales.purchaseOrders);

type AdminPreSaleOrderPathOptions = {
  orderId: number;
  preSaleId: number;
};

export const adminPreSaleOrderPath = ({
  preSaleId,
  orderId,
}: AdminPreSaleOrderPathOptions) =>
  join(adminPreSalePath(preSaleId), "orders", orderId);

/**
 * Admin Transfers
 */
export const adminTransfersPath = [
  "",
  adminPaths.root,
  adminPaths.transfers.root,
].join("/");

export const adminTransferPath = (transferId: number) =>
  join(adminTransfersPath, transferId);

/**
 * Admin -> Global Config
 */
export const adminGlobalConfigPath = join(
  adminPath,
  adminPaths.globalConfig.root
);

/**
 * Admin -> Global Config -> Regions
 */

export const adminRegionsPath = join(
  adminGlobalConfigPath,
  adminPaths.globalConfig.regions.root
);

export const adminRegionPath = (regionId: number) =>
  join(adminRegionsPath, regionId);

export const adminRegionDeliveryPartnerPath = (regionId: number) =>
  join(adminRegionsPath, regionId, "delivery-partner");

/**
 * Admin -> Global Config -> Admins
 */
export const adminGlobalAdminsPath = join(
  adminGlobalConfigPath,
  adminPaths.globalConfig.admins.root
);

/**
 * Other
 */

export const notFoundPath = "/not-found";
export const registerRetailerPath = "/register-retailer";
export const onboardingRegisterRetailerPath = "/register/retailer";
export const onboardingPath = (businessEntityId: number | string) =>
  `/onboarding/${businessEntityId}`;

type OrganizationPathOptions = {
  businessType: BusinessType;
  id: number;
};

export const organizationPath = (organization: OrganizationPathOptions) =>
  `${organization.businessType.toLowerCase()}/${organization.id}`;

function join(...args: Array<string | number | undefined>) {
  return reject(args, isNil).join("/");
}
