import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { AdminRetailersPage } from "./AdminRetailersPage/AdminRetailersPage";
import { AdminRetailerPage } from "./AdminRetailerPage/AdminRetailerPage";

function AdminRetailersContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminRetailersPage path="/" />
      <AdminRetailerPage path=":retailerId/*" />
    </Router>
  );
}

export default AdminRetailersContainer;
