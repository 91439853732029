import React from "react";
import { Button } from "@components/ui/Button";
import { onboardingPath } from "@routes";
import { useNavigate } from "@reach/router";
import { WarningAlert } from "@components/ui/Alert";

interface MissingBankAccountAlertProps {
  businessId: number;
  hasCompletedOnboarding: boolean;
  add: () => unknown;
}
/**
 * This component shows when you don't have a bank account linked to a location, in order to allow you
 * to create a new bank account.
 * You might not have a bank account for two reasons:
 *
 * - One: You haven't gone through onboarding multiple steps form.
 * - Two: You have completed onboarding but deleted the bank account afterwards
 *
 * In case one we redirect to the onboarding.
 *
 * In case two we show a dialog to add a bank account (we achieve that by redirecting to a relative route
 * that will show the Dialog). We reuse the component to create a BankAccount used in the onboarding form.
 */
export const MissingBankAccountAlert = ({
  businessId,
  hasCompletedOnboarding,
  add,
}: MissingBankAccountAlertProps) => {
  const navigate = useNavigate();

  const onAddNewPayment = () => {
    if (hasCompletedOnboarding) {
      add();
    } else {
      navigate(onboardingPath(businessId));
    }
  };

  return (
    <WarningAlert
      title="Missing bank account"
      description="Add a bank account to purchase for this store."
    >
      <Button onClick={onAddNewPayment} kind="secondary">
        Add bank account
      </Button>
    </WarningAlert>
  );
};
