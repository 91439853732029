import React from "react";
import { Link } from "@ui/Link";
import { adminOrderPath, adminPreSaleOrderPath } from "@routes";

type AdminOrderLinkProps = {
  order: {
    id: number;
    number: string;
  };
};

export function AdminOrderLink({ order }: AdminOrderLinkProps) {
  return <Link to={adminOrderPath(order.id)}>{order.number}</Link>;
}

type AdminPreSaleOrderLinkProps = AdminOrderLinkProps & {
  preSaleId: number;
};

export function AdminPreSaleOrderLink({
  order,
  preSaleId,
}: AdminPreSaleOrderLinkProps) {
  return (
    <Link
      to={adminPreSaleOrderPath({
        preSaleId,
        orderId: order.id,
      })}
    >
      {order.number}
    </Link>
  );
}
