import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { adminPaths } from "@routes";
import { AdminOfferWindowsRouter } from "./AdminOfferWindowsRouter/AdminOfferWindowsRouter";
import AdminPackagesContainer from "./AdminPackagesContainer/AdminPackagesContainer";
import AdminProductsContainer from "./AdminProductsContainer/AdminProductsContainer";

const { merchandising: paths } = adminPaths;

export function AdminMerchandisingContainer(_: RouteComponentProps) {
  return (
    <Router>
      <Redirect from="/" to={paths.offerWindows.root} />
      <AdminOfferWindowsRouter path={paths.offerWindows.root + "/*"} />
      <AdminProductsContainer path={paths.products.root + "/*"} />
      <AdminPackagesContainer path={paths.packages.root + "/*"} />
    </Router>
  );
}
