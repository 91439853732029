import React, { useCallback, useEffect } from "react";
import { DeliveryAgingReportFilterInput } from "@apollo/ops";
import { Controller, useForm } from "react-hook-form";
import { RegionsDropdown } from "@components/Region/RegionsDropdown";

export type FilterData = DeliveryAgingReportFilterInput;

type DeliveryAgingReportFiltersProps = {
  onFilter: (data: FilterData) => void | Promise<void>;
  defaultValues?: Partial<FilterData>;
  submitText?: string;
};

export function DeliveryAgingReportFilters({
  onFilter,
  defaultValues,
}: DeliveryAgingReportFiltersProps) {
  const { handleSubmit, watch, control } = useForm<FilterData>({
    defaultValues,
  });

  const wrappedOnSubmit = useCallback(
    async (data: FilterData) => {
      try {
        await onFilter(data);
      } catch (error) {
        console.error(error);
      }
    },
    [onFilter]
  );

  useEffect(() => {
    const subscription = watch(() => handleSubmit(wrappedOnSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, wrappedOnSubmit]);

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)} autoComplete="off">
      <div className="grid laptop:grid-cols-3 gap-3 items-end">
        <div>
          <Controller
            name="regionId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RegionsDropdown onChange={onChange} value={value} />
            )}
          />
        </div>
      </div>
    </form>
  );
}
