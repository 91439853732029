import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";

export type InviteUserData = {
  fullName: string;
  email: string;
};

type InviteUserFormProps = {
  organizationId: number;
  onSubmit: (data: InviteUserData) => Promise<void>;
};

export function InviteUserForm({ onSubmit }: InviteUserFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<InviteUserData>();
  const wrappedOnSubmit = async (data: InviteUserData) => {
    await onSubmit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="space-y-3">
        <div>
          <label
            htmlFor="fullName"
            className="block text-sm font-medium text-gray-700"
          >
            Full Name
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("fullName", {
                required: {
                  value: true,
                  message: "Full name is required",
                },
              })}
              id="fullName"
              placeholder="Full name"
              error={errors.fullName?.message}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <Input
              id="email"
              type="email"
              {...register("email", {
                required: {
                  value: true,
                  message: "Email is required",
                },
              })}
              placeholder="Email"
              error={errors.email?.message}
            />
          </div>
        </div>
        <div>
          <Button size="lg" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
}
