import { TrackingInformationDetailsFragment } from "@apollo/ops";
import { FreightShipmentStatusBadge } from "@components/TrackingInformation/FreightShipmentStatusBadge";
import {
  DescriptionListInCard,
  LeftAlignedTerm,
} from "@components/ui/DescriptionList";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";
import React, { FC } from "react";

interface Props {
  trackingInformation: TrackingInformationDetailsFragment;
}

export const ShowTrackingInformation: FC<Props> = ({ trackingInformation }) => {
  return (
    <DescriptionListInCard>
      <LeftAlignedTerm
        term="Status"
        definition={
          <FreightShipmentStatusBadge status={trackingInformation.status} />
        }
      />
      <LeftAlignedTerm
        term="Carrier"
        definition={trackingInformation.carrier.displayName}
      />
      <LeftAlignedTerm
        term="Tracking Number"
        definition={trackingInformation.trackingNumber}
        hidden={!trackingInformation.trackingNumber}
      />
      <LeftAlignedTerm
        term="Tracking Link"
        definition={
          <a
            className="hover:underline"
            href={trackingInformation.trackingLink ?? ""}
          >
            View Tracking
          </a>
        }
        hidden={!trackingInformation.trackingLink}
      />
      <LeftAlignedTerm
        term="Estimated Pickup Date"
        definition={formatAsDate(
          new Date(trackingInformation.estimatedPickupDate)
        )}
        hidden={!trackingInformation.estimatedPickupDate}
      />
      <LeftAlignedTerm
        term="Estimated Arrival Date"
        definition={formatAsDate(
          new Date(trackingInformation.estimatedArrivalDate)
        )}
        hidden={!trackingInformation.estimatedArrivalDate}
      />
      <LeftAlignedTerm
        term="Estimated Shipping Cost"
        definition={formatAmountInCents(
          trackingInformation.estimatedCostInCents!
        )}
        hidden={!trackingInformation.estimatedCostInCents}
      />
      <LeftAlignedTerm
        term="Pallet Count"
        definition={trackingInformation.palletCount}
        hidden={!trackingInformation.palletCount}
      />
      <LeftAlignedTerm
        term="Delivered at"
        definition={formatAsDate(new Date(trackingInformation.deliveredAt))}
        hidden={!trackingInformation.deliveredAt}
      />
      <LeftAlignedTerm
        term="Actual Shipping Cost"
        definition={formatAmountInCents(trackingInformation.actualCostInCents!)}
        hidden={!trackingInformation.actualCostInCents}
      />
      <LeftAlignedTerm
        term="Freight Trailer #"
        definition={trackingInformation.freightTrailerNumber}
        hidden={!trackingInformation.freightTrailerNumber}
      />
      <LeftAlignedTerm
        term="Freight Invoice #"
        definition={trackingInformation.freightInvoiceNumber}
        hidden={!trackingInformation.freightInvoiceNumber}
      />
    </DescriptionListInCard>
  );
};
