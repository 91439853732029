import React from "react";
import { Dialog, UseDialogResult } from "@ui/Dialog";
import {
  AddOfferMutation,
  AdminOfferWindowOfferFragmentDoc,
  useAddOfferMutation,
} from "@apollo/ops";
import { defaultErrorHandler } from "@utilities/toast";
import { AddOfferForm, AddOfferSubmitData } from "./AddOfferForm";

type AddOfferDialogProps = {
  dialog: UseDialogResult;
  onAdd?: (data: AddOfferMutation) => void;
  offerWindowId: number;
};

export function AddOfferDialog({
  dialog,
  onAdd,
  offerWindowId,
}: AddOfferDialogProps) {
  const { getDialogProps } = dialog;
  const [addOffer] = useAddOfferMutation();

  const handleSubmit = async (formData: AddOfferSubmitData) => {
    try {
      const result = await addOffer({
        variables: {
          data: {
            offerWindowId,
            ...formData,
          },
        },
        update(cache, { data }) {
          if (data?.addOffer) {
            cache.modify({
              id: cache.identify(data.addOffer.offerWindow),
              fields: {
                offers(existingOfferRefs = []) {
                  const newOfferRef = cache.writeFragment({
                    data: data.addOffer,
                    fragment: AdminOfferWindowOfferFragmentDoc,
                    fragmentName: "AdminOfferWindowOffer",
                  });

                  return [...existingOfferRefs, newOfferRef];
                },
              },
            });
          }
        },
      });

      if (result.data) {
        dialog.close();
        onAdd && onAdd(result.data);
      }
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <Dialog {...getDialogProps()} title="New offer" width="xl">
      <AddOfferForm onSubmit={handleSubmit} offerWindowId={offerWindowId} />
    </Dialog>
  );
}
