import React, { useMemo } from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { SidenavLayout } from "@ui/Layouts/SidenavLayout";
import { SecondaryNav } from "@components/SecondaryNav/SecondaryNav";
import { adminPaths } from "@routes";
import { AdminPurchaseOrdersPage } from "./AdminPurchaseOrdersPage/AdminPurchaseOrdersPage";
import { FreightAgingReportPage } from "./FreightAgingReportPage/FreightAgingReportPage";
import { DeliveryAgingReportPage } from "./DeliveryAgingReportPage/DeliveryAgingReportPage";
import { RetailerSalesOrderDeliveryAgingReportPage } from "./RetailerSalesOrdersDeliveryAgingReportPage/DeliveryAgingReportPage";
import { PaymentAgingReportPage } from "./PaymentAgingReportPage/PaymentAgingReportPage";

export function AdminPurchaseOrdersRouter(_: RouteComponentProps) {
  /**
   * Use a relative path in the `path` property
   */
  const navItems = useMemo(
    () => [
      {
        name: "Purchase Orders",
        path: ".",
      },
      {
        title: "PO Aging Reports",
        items: [
          {
            name: "Freight",
            path: adminPaths.purchaseOrders.agingReport,
          },
          {
            name: "Delivery",
            path: adminPaths.purchaseOrders.deliveryAgingReport,
          },
          {
            name: "Payments",
            path: adminPaths.purchaseOrders.paymentAgingReport,
          },
        ],
      },
      {
        title: "Retail Aging Reports",
        items: [
          {
            name: "Delivery",
            path: adminPaths.purchaseOrders.retailerDeliveryAgingReport,
          },
        ],
      },
    ],
    []
  );

  return (
    <SidenavLayout nav={<SecondaryNav items={navItems} />}>
      <Router>
        <AdminPurchaseOrdersPage path="/" />
        <FreightAgingReportPage path={adminPaths.purchaseOrders.agingReport} />
        <DeliveryAgingReportPage
          path={adminPaths.purchaseOrders.deliveryAgingReport}
        />
        <RetailerSalesOrderDeliveryAgingReportPage
          path={adminPaths.purchaseOrders.retailerDeliveryAgingReport}
        />
        <PaymentAgingReportPage
          path={adminPaths.purchaseOrders.paymentAgingReport}
        />
      </Router>
    </SidenavLayout>
  );
}

export default AdminPurchaseOrdersRouter;
