import React, { ComponentType, FunctionComponent } from "react";
import { useDistributorQuery, DistributorFieldsFragment } from "@apollo/ops";

type WithDistributorProps = {
  distributorId?: string;
};

export type WithDistributorInjectedProps = {
  distributor: DistributorFieldsFragment;
};

export function withDistributor<TProps extends WithDistributorProps>(
  WrappedCmp: ComponentType<TProps & WithDistributorInjectedProps>
): FunctionComponent<TProps> {
  const WithDistributor = (props: TProps) => {
    // This component should always be rendered under a path with a :distributorId param
    if (props.distributorId === undefined) {
      throw new Error("Missing distributorId");
    }

    const distributorId = parseInt(props.distributorId);

    /**
     * This is expected to hit the cache.
     */
    const { data, error } = useDistributorQuery({
      variables: {
        distributorId,
      },
    });

    // TODO handle elegantly
    if (error) {
      throw new Error("Couldn't load distributor");
    }

    // TODO what should be shown here?
    if (!data) {
      return null;
    }

    return <WrappedCmp {...props} distributor={data.organization} />;
  };

  return WithDistributor;
}
