import React, { useCallback } from "react";
import { DocumentAddIcon } from "@heroicons/react/outline";
import { useDropzone } from "react-dropzone";
import { useId } from "@reach/auto-id";
import { DIRECT_BEER_API_URI } from "../../config";
import { getAuthHeader } from "../../amplify";
import ctl from "@netlify/classnames-template-literals";
import { CloudinaryImageType } from "@apollo/ops";
import { FileInputProps } from "./FileInputTypes";
export * from "./FileInputTypes";

export function ImageInput({
  onChange,
  maxFiles,
  maxSize,
  accept,
  label,
  error,
  disabled = false,
}: FileInputProps) {
  const inputId = useId();
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      onChange(
        acceptedFiles.map((acceptedFile) => ({
          getFileId: () => uploadImage(acceptedFile),
        }))
      );
    },
    [onChange]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
    accept,
    disabled,
  });

  const maxSizeInMb = maxSize ? maxSize / (1024 * 1024) : null;

  return (
    <>
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div
        {...getRootProps()}
        className={fileInputClasses({
          isDragAccept,
          isDragActive,
          isDragReject,
          hasError: Boolean(error),
        })}
      >
        <div className="space-y-1 text-center">
          <DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400" />
          <div className="text-sm text-gray-600">
            <span className="relative cursor-pointer rounded-md font-medium text-brand-700 hover:text-brand-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-600">
              <span>Upload a file</span>
              <input
                {...getInputProps({ id: inputId })}
                className="sr-only"
                disabled={disabled}
              />
            </span>
            <span className="pl-1">or drag and drop</span>
          </div>
          {maxSizeInMb && (
            <div className="text-sm text-gray-600">
              Files must be less than {maxSizeInMb} MB
            </div>
          )}
          <ul>
            {" "}
            {acceptedFiles.map((file) => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul>
        </div>
      </div>
      {error && (
        <div className="mt-1">
          <p role="alert" className="text-sm text-red-600">
            {error}
          </p>
        </div>
      )}
    </>
  );
}

async function uploadImage(file: File): Promise<number> {
  const formData = new FormData();
  formData.append("document", file);
  return uploadCloudinaryImage(file);
}

async function uploadCloudinaryImage(file: File): Promise<number> {
  const formData = new FormData();
  formData.append("document", file);

  const Authorization = await getAuthHeader();

  // This parameter lets us specify the type of the CloudinaryImage we create
  // For now, this will only be for products. In the future we may add other
  // cases such as BreweryImages which can be set here.
  const imageType = CloudinaryImageType.Product;

  const response = await fetch(
    `${DIRECT_BEER_API_URI}images?type=${imageType}`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization,
      },
      body: formData,
    }
  );

  const data = await response.json();
  return Number(data.cloudinaryImageId);
}

type fileInputClassesOptions = {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
  hasError: boolean;
};

const fileInputClasses = ({
  isDragAccept,
  isDragReject,
  hasError,
}: fileInputClassesOptions) =>
  ctl(`
    mt-1
    flex
    justify-center
    px-6
    pt-5
    pb-6
    border-2
    ${
      isDragAccept
        ? "border-green-300"
        : isDragReject || hasError
        ? "border-red-300"
        : "border-gray-300"
    }
    border-dashed
    rounded-md
  `);
