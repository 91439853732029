import React from "react";
import {
  AdminRetailerSalesOrdersDocument,
  AdminRetailerSalesOrdersQuery,
  AdminRetailerSalesOrdersQueryVariables,
  AdminRetailerSalesOrdersTableDataFragment,
  DeliveryStatus,
  LedgerStatus,
} from "@apollo/ops";
import { AdminRetailerSalesOrdersTable } from "@admin/AdminRetailersContainer/AdminRetailerPage/RetailerSalesOrders/AdminRetailerSalesOrdersTable";
import { useOffsetPaginatedQuery } from "@hooks/useOffsetPaginatedQuery";
import { ErrorAlert } from "@ui";
import { formatAsDate } from "@util/formatDate";
import { formatToDollars } from "@util/currency";
import { RouteComponentProps } from "@reach/router";
import { isNil } from "lodash";
import { Card } from "@ui/Card/Card";

function assertRetailerId(
  parsedRetailerId?: number
): asserts parsedRetailerId is number {
  if (isNil(parsedRetailerId)) throw new Error("retailer id required");
}

type AdminRetailerSalesOrdersParams = {
  parsedRetailerId: number;
};

export function AdminRetailerSalesOrders({
  parsedRetailerId,
}: RouteComponentProps<AdminRetailerSalesOrdersParams>) {
  assertRetailerId(parsedRetailerId);

  const { pagination, queryResult } = useOffsetPaginatedQuery<
    AdminRetailerSalesOrdersQuery,
    AdminRetailerSalesOrdersQueryVariables
  >({
    options: {
      nextFetchPolicy: "network-only",
      variables: {
        filters: {
          retailerId: parsedRetailerId,
        },
      },
    },
    query: AdminRetailerSalesOrdersDocument,
    mapDataToTotal({ getRetailerSalesOrders }): number {
      return getRetailerSalesOrders?.total ?? 0;
    },
  });

  const { data, error } = queryResult;

  if (error) throw error;

  return (
    <div className="mt-8">
      <div className="space-y-4">
        <Card title="Order History">
          {error && <ErrorAlert title="Failed to load orders" />}
          {data && (
            <AdminRetailerSalesOrdersTable
              data={mapOffersToTableData(data?.getRetailerSalesOrders?.results)}
              pagination={pagination}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export type AdminRetailerSalesOrdersTableRowData = {
  __typename: "RetailerSalesOrder";
  id: number;
  createdAt: string;
  cancelledAt: string;
  number: string;
  producer: AdminRetailerSalesOrdersTableDataFragment["producerPurchaseOrder"]["seller"];
  amount: string;
  paymentStatus: LedgerStatus;
  deliveryStatus: DeliveryStatus;
};

function mapOffersToTableData(
  ordersData?: Array<AdminRetailerSalesOrdersTableDataFragment> | null
): Array<AdminRetailerSalesOrdersTableRowData> {
  return (ordersData || []).map((orderData) => ({
    __typename: orderData.__typename,
    id: orderData.id,
    createdAt: formatAsDate(orderData.createdAt),
    cancelledAt: orderData.cancelledAt,
    number: orderData.number,
    producer: orderData.producerPurchaseOrder.seller,
    amount: formatToDollars({ cents: orderData.finalOrderTotalInCents }),
    paymentStatus: orderData.paymentStatus,
    deliveryStatus: orderData.deliveryStatus,
  }));
}
