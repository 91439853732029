import React from "react";
import DwollaLogo from "@images/dwolla-logo2.png";

export function PoweredByDwollaComponent() {
  return (
    <div className="pt-8">
      <p className="text-center">Powered by</p>
      <img className="h-10 w-auto m-auto" src={DwollaLogo} alt="Direct Beer" />
    </div>
  );
}
