import React from "react";
export interface BankAccountReVerifyContextValue {
  /**
   * Callback used by {@link BankAccountReVerify} component when you click it's button
   */
  reverifyBankAccount: (bankAccountId: number) => Promise<void>;
  /**
   * True when a request to remove a bank account is processing, False otherwise.
   */
  removingBankAccount: boolean;
  /**
   * True when the Plaid Link Component dialog is ready to be shown to the user. False otherwise.
   */
  plaidDialogReady: boolean;
  /**
   * True when Plaid Link Component is in the middle of a request to create the PlaidItem, or we when we are
   * creating a bank account in the backend with a PlaidItem.
   */
  loading: boolean;
}
export const bankAccountReVerifyContextDefaultVal = {
  reverifyBankAccount: () => Promise.resolve(undefined),
  removingBankAccount: false,
  plaidDialogReady: false,
  loading: false,
};
export const BankAccountReVerifyContext = React.createContext<BankAccountReVerifyContextValue>(
  bankAccountReVerifyContextDefaultVal
);
