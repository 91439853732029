import React from "react";
import { Path, UseFormRegister } from "react-hook-form";
import { LabeledInput } from "@ui/Input";

export type AmountInputPropsCustomValidation = {
  min?: number;
  max?: number;
};

export type AmountInputProps<TFieldValues> = {
  register: UseFormRegister<TFieldValues>;
  name: Path<TFieldValues>;
  error?: string;
  label?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  customValidation?: AmountInputPropsCustomValidation;
};

export function AmountInput<TFieldValues>({
  register,
  error,
  name,
  label = "Amount",
  className,
  required = true,
  customValidation = {},
  placeholder,
}: AmountInputProps<TFieldValues>) {
  return (
    <LabeledInput
      label={label}
      inputProps={{
        inlineLead: "$",
        placeholder,
        ...register(name, {
          required: {
            value: required,
            message: `${label} is required`,
          },
          min: {
            value: 0.01,
            message: "Must be at least $0.01",
          },
          ...customValidation,
        }),
        type: "number",
        step: ".01",
        error,
        className,
      }}
    />
  );
}
