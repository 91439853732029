import React, { useState } from "react";
import { AddLogisticsPartner } from "./AddLogisticsPartner";
import { Button } from "@ui/Button";
import { Dialog } from "primereact/dialog";

export function AddLogisticsPartnerModal() {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)} size="sm">
        Add Logistics Partner
      </Button>
      <Dialog
        visible={visible}
        header="Add logistics partner"
        className="p-fluid tablet:max-w-xl tablet:w-full"
        onHide={() => setVisible(false)}
        modal
      >
        <AddLogisticsPartner onAdd={() => setVisible(false)} />
      </Dialog>
    </>
  );
}
