import React from "react";
import { Label, Input, InputProps } from "@ui/Input";

interface StoreNameInputProps extends InputProps {
  organizationName?: string;
}

export function StoreNameInput({
  organizationName,
  ...rest
}: StoreNameInputProps) {
  const organizationNameOrPlaceholder =
    organizationName || "<Organization Name>";

  return (
    <div>
      <Label htmlFor="locationName">Store name</Label>
      <span className="block text-sm text-gray-400 mt-1 mb-2">
        You can use a street, neighborhood, city, or general term like South or
        Main to identify this retail location.
      </span>
      <div className="mt-1">
        <Input
          type="text"
          inlineLead={organizationNameOrPlaceholder + " - "}
          {...rest}
        />
      </div>
    </div>
  );
}
