import React, { useMemo } from "react";
import { Table } from "@ui/Table";
import {
  CellProps,
  Column,
  TableInstance,
  useFlexLayout,
  useTable,
} from "react-table";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";
import { AdminProducersTableDataFragment } from "@apollo/gen/generatedOperations";
import { AdminProducerLink } from "@components/Producer/Producer";

type AdminProducersTableProps = {
  data: Array<AdminProducersTableDataFragment>;
  pagination?: UseOffsetPaginationResult;
};

export function AdminProducersTable({
  data,
  pagination,
}: AdminProducersTableProps) {
  const columns: Array<Column<AdminProducersTableDataFragment>> = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 20,
      },
      {
        Header: "Name",
        Cell: ({
          row,
        }: CellProps<AdminProducersTableDataFragment, "string">) => (
          <AdminProducerLink producer={row.original} />
        ),
        accessor: "displayName",
        width: 150,
      },
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  ) as TableInstance<AdminProducersTableDataFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
