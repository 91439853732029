import React from "react";
import { Badge, Color } from "@ui/Badge";
import { DwollaCustomerVerificationStatus } from "@apollo/ops";

type DwollaCustomerVerificationStatusBadgeProps = {
  status: DwollaCustomerVerificationStatus;
};

export function DwollaCustomerVerificationStatusBadge({
  status,
}: DwollaCustomerVerificationStatusBadgeProps) {
  const color = verificationStatusToBadgeColor(status);

  return <Badge color={color} label={status} />;
}

function verificationStatusToBadgeColor(
  status: DwollaCustomerVerificationStatus
): Color {
  switch (status) {
    case DwollaCustomerVerificationStatus.Deactivated:
      return "gray";
    case DwollaCustomerVerificationStatus.Document:
      return "blue";
    case DwollaCustomerVerificationStatus.Retry:
      return "yellow";
    case DwollaCustomerVerificationStatus.Suspended:
      return "red";
    case DwollaCustomerVerificationStatus.Unverified:
      return "gray";
    case DwollaCustomerVerificationStatus.Verified:
      return "green";
  }
}
