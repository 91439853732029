import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { Page, PageHeading } from "@components/ui/Page";
import { AdminPlatformFees } from "./AdminPlatformFees/AdminPlatformFees";
import { Tab, Tabs } from "@ui/Tabs";
import { AdminRegionDeliveryPartnerDetails } from "@admin/AdminGlobalConfigRouter/AdminRegionsRouter/AdminRegionPage/AdminRegionDeliveryPartnerDetails";
import { AdminRegionDetails } from "@admin/AdminGlobalConfigRouter/AdminRegionsRouter/AdminRegionPage/AdminRegionDetails";
import { withQuery, WithQueryComponentProps } from "@shared/withQuery";
import {
  AdminRegionDetailsDocument,
  AdminRegionDetailsQuery,
  AdminRegionDetailsQueryVariables,
} from "@apollo/gen/generatedOperations";

interface AdminRegionPageProps extends RouteComponentProps {
  regionId?: string;
}

/**
 * Use a relative path in the `path` property
 */
const tabs: Tab[] = [
  {
    name: "Details",
    path: ".",
  },
  {
    name: "Fees",
    path: "./fees",
  },
  {
    name: "Delivery Partner",
    path: "./delivery-partner",
  },
];

type WrappedProps = WithQueryComponentProps<
  AdminRegionPageProps,
  AdminRegionDetailsQuery,
  AdminRegionDetailsQueryVariables
>;

export function AdminRegion({ query }: WrappedProps) {
  const { region } = query?.data || null;
  return (
    region && (
      <Page heading={<PageHeading title={region.friendlyName} />}>
        <Tabs tabs={tabs} />
        <Router>
          <AdminRegionDetails path="/" region={region} />
          <AdminPlatformFees path="/fees" regionId={region.id.toString()} />
          <AdminRegionDeliveryPartnerDetails
            path="/delivery-partner"
            regionId={region.id.toString()}
          />
          <Redirect from="*" to="/not-found" />
        </Router>
      </Page>
    )
  );
}

export const AdminRegionPage = withQuery<
  AdminRegionPageProps,
  AdminRegionDetailsQuery,
  AdminRegionDetailsQueryVariables
>(AdminRegionDetailsDocument, {
  mapPropsToOptions: (props) => {
    return {
      variables: { regionId: parseInt(props.regionId!) },
    };
  },
})(AdminRegion);
