import React from "react";
import { Button } from "@ui/Button";
import {
  DeletePackageTypeMutation,
  useDeletePackageTypeMutation,
} from "@apollo/ops";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";

type DeletePackageTypeButtonProps = {
  onDelete?: (data: DeletePackageTypeMutation) => void;
  packageTypeId: number;
};

export function DeletePackageTypeButton({
  packageTypeId,
  onDelete,
}: DeletePackageTypeButtonProps) {
  const [deletePackageType] = useDeletePackageTypeMutation();
  const dialog = useDialog();

  const onConfirm = async () => {
    try {
      const result = await deletePackageType({
        variables: {
          id: packageTypeId,
        },
      });

      if (result.data && onDelete) {
        onDelete(result.data);
      }

      toast.current?.show({
        severity: MessageSeverity.success,
        summary: "",
        detail: "Successfully deleted Package Type",
      });
    } catch (error) {
      // TODO handle other error case
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <>
      <Button destructive kind="secondary" onClick={dialog.open}>
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this package type?"
        onConfirm={onConfirm}
        confirmText="Yes, delete this package type"
        dialog={dialog}
      />
    </>
  );
}
