import React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { RegionV2 } from "@apollo/gen/generatedOperations";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { Button } from "@ui/Button";
import { DateTime } from "luxon";
import classNames from "classnames";
import { useRegions } from "@hooks/useRegions/useRegions";

export type PreSaleImportJobFormData = {
  start: Date;
  end: Date;
  regions: (string | false)[]; //Contains an array of of Region IDs stored as strings. React Hook Form doesn't have a way to pass these back as numbers.
};

type PreSaleImportJobFormProps = {
  onSubmit: (data: PreSaleImportJobFormData) => void | Promise<void>;
};

type DateComponentProps = {
  control: Control<PreSaleImportJobFormData>;
  setValue: UseFormSetValue<PreSaleImportJobFormData>;
  errors: DeepMap<PreSaleImportJobFormData, FieldError>;
  watch: UseFormWatch<PreSaleImportJobFormData>;
  trigger: UseFormTrigger<PreSaleImportJobFormData>;
};

export function PreSaleImportJobForm({ onSubmit }: PreSaleImportJobFormProps) {
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors },
    watch,
    setValue,
    trigger,
  } = useForm<PreSaleImportJobFormData>();

  const regions = useRegions();

  const wrappedOnSubmit = async (data: PreSaleImportJobFormData) => {
    try {
      await onSubmit(data);
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  const dateComponentProps: DateComponentProps = {
    control,
    setValue,
    errors,
    watch,
    trigger,
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div>
        <label className="block font-medium text-gray-700">
          Select Region(s)
        </label>
        {regions && renderRegionCheckboxes(register, regions.regions)}
      </div>

      <div className="grid tablet:grid-cols-2 gap-4">
        <StartDate {...dateComponentProps} />
        <EndDate {...dateComponentProps} />
      </div>

      <Button size="lg" type="submit" disabled={isSubmitting} className="mt-5">
        Import from Shopify
      </Button>
    </form>
  );
}

function renderRegionCheckboxes(
  register: UseFormRegister<PreSaleImportJobFormData>,
  regions: Pick<RegionV2, "id" | "friendlyName">[]
) {
  return regions.map((regionV2, index) => {
    return (
      <div key={regionV2.id}>
        <input
          type="checkbox"
          value={regionV2.id}
          {...register(`regions.${index}` as const)}
        />
        <label> {regionV2.friendlyName} </label>
      </div>
    );
  });
}

function StartDate({
  control,
  setValue,
  errors,
  watch,
  trigger,
}: DateComponentProps) {
  const start = watch("start");
  const end = watch("end");

  return (
    <div className="mt-5">
      <label className="block font-medium text-gray-700">Start Date</label>
      <Controller
        name="start"
        control={control}
        rules={{
          required: "Start Date is required",
        }}
        render={({ field: { onChange, value } }) => (
          <Calendar
            value={value}
            onChange={({ value }) => onChange(value)}
            monthNavigator
            showTime
            hourFormat="12"
            showIcon={true}
            onShow={() => {
              if (!value) {
                setValue("start", getDefaultStartDateTime().toJSDate());
              }
            }}
            onHide={() => {
              //Set the end date to automatically be 1 day later, for convenience
              if (start && !end) {
                const newEndDate = DateTime.fromJSDate(start).plus({ day: 1 });
                setValue("end", newEndDate.toJSDate());

                //Must manually trigger re-validation of the end date field, in case
                //setting the value causes it to transition from error state to not error state
                trigger("end");
              }
            }}
            className={classNames({
              "border-red-300": errors.start,
            })}
          />
        )}
      />
      {errors.start && (
        <small className="text-sm text-red-600">Start date is required</small>
      )}
    </div>
  );
}

function EndDate({ control, setValue, errors }: DateComponentProps) {
  return (
    <div className="mt-5">
      <label className="block font-medium text-gray-700">End Date</label>
      <Controller
        name="end"
        control={control}
        rules={{
          required: "End Date is required",
        }}
        render={({ field: { onChange, value } }) => (
          <Calendar
            value={value}
            onChange={({ value }) => onChange(value)}
            monthNavigator
            showTime
            hourFormat="12"
            showIcon={true}
            onShow={() => {
              if (!value) {
                setValue("end", getDefaultEndDateTime().toJSDate());
              }
            }}
            className={classNames({
              "border-red-300": errors.end,
            })}
          />
        )}
      />
      {errors.end && (
        <small className="text-sm text-red-600">End date is required</small>
      )}
    </div>
  );
}

function getDefaultStartDateTime() {
  return DateTime.now()
    .setZone("US/Pacific")
    .set({ hour: 10, minute: 0, second: 0, millisecond: 0 });
}

function getDefaultEndDateTime() {
  return getDefaultStartDateTime().plus({ day: 1 });
}
