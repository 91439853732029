import React from "react";
import { Button } from "@ui/Button";
import { useAdminDeleteProductMutation } from "@apollo/ops";
import { useDialog } from "@ui/Dialog";
import { useNavigate } from "@reach/router";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { adminProductsPath } from "@routes";
import { toast } from "@utilities/toast";
import { MessageSeverity } from "@shared/types/Severity";
import { getMessageToUserFromBackendError } from "@utilities/i18n";

type DeleteProductButtonProps = {
  productId: number;
};

export function DeleteProductButton({ productId }: DeleteProductButtonProps) {
  const [deleteProduct] = useAdminDeleteProductMutation();
  const dialog = useDialog();
  const navigate = useNavigate();

  const onConfirm = async () => {
    try {
      const result = await deleteProduct({
        variables: {
          productId,
        },
      });

      if (result.data) {
        toast.current?.show({
          severity: MessageSeverity.success,
          summary: "",
          detail: `Successfully deleted ${result.data.deleteProduct.name}`,
        });
        navigate(adminProductsPath);
      }
    } catch (error) {
      toast.current?.show({
        severity: MessageSeverity.error,
        summary: getMessageToUserFromBackendError(error),
      });
    }
  };

  return (
    <>
      <Button destructive kind="secondary" onClick={dialog.open} size="sm">
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this product?"
        onConfirm={onConfirm}
        confirmText="Yes, delete this product"
        dialog={dialog}
      />
    </>
  );
}
