import * as React from "react";

export function DirectBeerSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={107}
      height={107}
      viewBox="0 0 107 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M104.207 6.801c-.676-1.154-2.328-.41-2.328-.41L5.07 45.334s-1.333.486-1.411 1.448c-.109 1.351 1.188 1.79 1.188 1.79l23.078 7.176 2.763-2.763c6.765-6.765 11.06-7.401 16.155-7.562 5.093-.158 27.91-19.395 27.91-19.395s-.855-.696-.043-1.511c.818-.818 5.668-2.727 5.391-3.403-.277-.676.518-2.786 2.791-1.791 2.264.996 3.82 2.475 4.891 3.544 1.066 1.064 2.549 2.625 3.541 4.891.988 2.268-1.113 3.063-1.787 2.785-.68-.282-2.588 4.574-3.406 5.391-.816.814-1.51-.04-1.51-.04S65.385 58.711 65.223 63.802c-.156 5.095-.793 9.39-7.557 16.156l-3.349 3.347 4.269 14.828s.943 3.271 2.594 3.657c2.496.588 4.469-3.922 4.469-3.922l38.418-89.106c-.003.002.525-1.3.14-1.961z" />
    </svg>
  );
}
