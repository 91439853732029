import React, { useMemo } from "react";
import {
  PlatformFeesBreakdownResult,
  ProducerPurchaseOrder,
} from "@apollo/gen/generatedOperations";
import { Column, useTable } from "react-table";
import { formatAmountInCents } from "@util/currency";
import { isNil } from "lodash";
import { Table } from "@ui/Table";

export function AdminPlatformFeesBreakdown({
  data,
}: {
  data: ProducerPurchaseOrder["platformFeesBreakdown"];
}) {
  const columns: Array<Column<PlatformFeesBreakdownResult>> = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "productName",
      },
      {
        Header: "Package Type",
        accessor: "packageTypeName",
      },
      {
        Header: "Global Package Fee",
        accessor: "globalPlatformFee",
        Cell: ({ value }) => formatAmountInCents(value),
      },
      {
        Header: "Producer Package Fee",
        Cell: ({ value }) =>
          !isNil(value) ? formatAmountInCents(value) : "N/A",
        accessor: "producerPlatformFee",
      },
      {
        Header: "Quantity",
        accessor: "finalQuantity",
      },
      {
        Header: "Total",
        Cell: ({ row }) =>
          `${formatAmountInCents(
            row.original.platformFeesTotal
          )} (${formatAmountInCents(
            row.original.producerPlatformFee ?? row.original.globalPlatformFee
          )} * ${row.original.finalQuantity})`,
        accessor: "platformFeesTotal",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  return <Table instance={tableInstance} />;
}
