import React from "react";
import { DateTime } from "luxon";
import ctl from "@netlify/classnames-template-literals";
import { ServiceLevelTarget } from "@apollo/ops";

type AgeProps = {
  start: DateTime;
  end?: DateTime;
  serviceLevelTarget: ServiceLevelTarget;
};

/**
 * Displays the differences between two dates in days.
 */
export function Age({ start, end, serviceLevelTarget }: AgeProps) {
  const age = ageInDays(start, end);

  return (
    <span className={ageClasses({ age, serviceLevelTarget })}>{age} days</span>
  );
}

type AgeClassesOptions = {
  age: number;
  serviceLevelTarget: ServiceLevelTarget;
};

const ageClasses = ({ age, serviceLevelTarget }: AgeClassesOptions) =>
  ctl(`
    ${age > serviceLevelTarget.target && "text-red-600"}
    ${
      age > serviceLevelTarget.warn &&
      age <= serviceLevelTarget.target &&
      "text-yellow-600"
    }
    ${age <= serviceLevelTarget.warn && "text-green-600"}
  `);

function ageInDays(startDate: DateTime, endDate: DateTime = DateTime.now()) {
  return Math.round(endDate.diff(startDate).as("days"));
}
