import { formatAmountInCents } from "@util/currency";
import { TableSummary } from "@ui/TableSummary";
import React from "react";
import { StoreSalesOrderQuery } from "@apollo/gen/generatedOperations";

type StoreSalesOrderItemsTotalsProps = {
  order: StoreSalesOrderQuery["retailerSalesOrder"];
};

export function StoreSalesOrderItemsTotals({
  order,
}: StoreSalesOrderItemsTotalsProps) {
  const orderTotals: Record<string, string> = {
    "Order Item Total": formatAmountInCents(order.amountInCents),
    "Adjustments Total": formatAmountInCents(
      order.adjustmentTotalInCents ?? 0,
      {
        oppositeSign: true,
      }
    ),
    "Final Order Total": formatAmountInCents(order.finalOrderTotalInCents ?? 0),
  };

  return <TableSummary rows={orderTotals} />;
}
