import React from "react";
import { Button, ButtonProps } from "@ui/Button";
import { DeleteOfferMutation, useDeleteOfferMutation } from "@apollo/ops";
import { useDialog } from "@ui/Dialog";
import { ConfirmDialog } from "@components/Dialog/ConfirmDialog";
import { toastSuccess, defaultErrorHandler } from "@utilities/toast";

type DeleteOfferButtonProps = ButtonProps & {
  onDelete?: (data: DeleteOfferMutation) => void;
  offerId: number;
};

export function DeleteOfferButton({
  offerId,
  onDelete,
  ...buttonProps
}: DeleteOfferButtonProps) {
  const [deleteOffer] = useDeleteOfferMutation();
  const dialog = useDialog();

  const onConfirm = async () => {
    try {
      const result = await deleteOffer({
        variables: {
          id: offerId,
        },
      });

      if (result.data && onDelete) {
        onDelete(result.data);
      }

      toastSuccess({ summary: "Offer deleted" });
    } catch (error) {
      defaultErrorHandler(error);
    }
  };

  return (
    <>
      <Button
        destructive
        kind="secondary"
        onClick={dialog.open}
        {...buttonProps}
      >
        Delete
      </Button>
      <ConfirmDialog
        title="Are you sure you want to delete this offer?"
        onConfirm={onConfirm}
        confirmText="Yes, delete this offer"
        dialog={dialog}
      />
    </>
  );
}
