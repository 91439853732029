import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";

export type DistributorFormData = {
  name: string;
  feePerPackageInDollars: number;
};

type DistributorFormProps = {
  onSubmit: (data: DistributorFormData) => void | Promise<void>;
  defaultValues?: Partial<DistributorFormData>;
  submitText?: string;
  enableSetFee?: boolean;
};

export function DistributorForm({
  onSubmit,
  defaultValues = {
    feePerPackageInDollars: 0,
  },
  submitText = "Submit",
  enableSetFee = false,
}: DistributorFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<DistributorFormData>({
    defaultValues,
  });
  const wrappedOnSubmit = async (data: DistributorFormData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <div className="mt-1 mb-3">
            <Input
              type="text"
              {...register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              })}
              id="name"
              placeholder="Name"
              error={errors.name?.message}
            />
          </div>

          {enableSetFee && (
            <div>
              <label
                htmlFor="feePerPackageInDollars"
                className="block text-sm font-medium text-gray-700"
              >
                Fee Per Package
              </label>
              <div className="mt-1">
                <Input
                  type="number"
                  inlineLead="$  "
                  {...register("feePerPackageInDollars", {
                    required: {
                      value: true,
                      message: "Fee is required",
                    },
                    min: {
                      value: 0.0,
                      message: "Must be greater than or equal to $0",
                    },
                  })}
                  id="feePerPackageInDollars"
                  placeholder="Fee"
                  error={errors.feePerPackageInDollars?.message}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <Button size="lg" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  );
}
