import React, { ComponentType } from "react";
import { Redirect } from "@reach/router";
import { withAuthentication, useUserContext, User } from "./withAuthentication";

export interface WithAuthorizationOptions {
  isGlobalAdmin?: boolean;
  customAuthorizer?: (user: User) => boolean;
}

export const authorizeUser = (
  authOptions: WithAuthorizationOptions,
  user: User
) => {
  if (authOptions.isGlobalAdmin && !user.isGlobalAdmin) {
    return false;
  }

  if (authOptions.customAuthorizer && !authOptions.customAuthorizer(user)) {
    return false;
  }

  return true;
};

export function withAuthorization<TProps>(options: WithAuthorizationOptions) {
  return (Component: ComponentType<TProps>) => {
    const WithComponent = (props: TProps) => {
      const user = useUserContext();

      if (!authorizeUser(options, user)) {
        return <Redirect to="/unauthorized" noThrow />;
      }

      return <Component {...props} />;
    };

    return withAuthentication<TProps>()(WithComponent);
  };
}
