import React from "react";
import { useOnboardingAuthorizeOnDemandTransfersMutation } from "@apollo/ops";
import { FormButton } from "@components/FormButton";
import { AuthorizeOnDemandTransfersCopy } from "@components/BankAccount/BankAccountConstants";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";

type OnboardingAuthorizeOnDemandTransferProps = {
  bankAccountId: number;
};

export function OnboardingAuthorizeOnDemandTransfer({
  bankAccountId,
}: OnboardingAuthorizeOnDemandTransferProps) {
  const [authorize, result] = useOnboardingAuthorizeOnDemandTransfersMutation({
    variables: {
      bankAccountId,
    },
  });

  const handleSubmit = async () => {
    try {
      await authorize();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.messge,
        });
      }
    }
  };

  return (
    <div className="text-center">
      <div className="text-3xl mb-6">Authorize on-demand transfers</div>

      <p>{AuthorizeOnDemandTransfersCopy}</p>

      <div className="mt-12">
        <FormButton onSubmit={handleSubmit} disabled={result.loading} size="xl">
          Accept & Continue
        </FormButton>
      </div>
    </div>
  );
}
