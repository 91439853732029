import { FreightShipmentStatus } from "@apollo/ops";
import { Badge, BadgeProps, Color } from "@ui/Badge";

type FreightShipmentStatusBadgeProps = Partial<BadgeProps> & {
  status: FreightShipmentStatus | null;
};

function getFreightShipmentBadgeProps(status: FreightShipmentStatus | null) {
  switch (status) {
    case null:
      return { label: "NOT STARTED", color: "gray" as Color };
    case FreightShipmentStatus.Scheduled:
      return { label: "SCHEDULED", color: "blue" as Color };
    case FreightShipmentStatus.InTransit:
      return { label: "IN TRANSIT", color: "blue" as Color };
    case FreightShipmentStatus.Delivered:
      return { label: "DELIVERED", color: "green" as Color };
    case FreightShipmentStatus.Cancelled:
      return { label: "CANCELLED", color: "red" as Color };
  }
}

export function FreightShipmentStatusBadge({
  status,
  ...rest
}: FreightShipmentStatusBadgeProps) {
  return Badge({ ...getFreightShipmentBadgeProps(status), ...rest });
}
