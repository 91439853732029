import React from "react";
import { ErrorBoundary } from "@components/ErrorBoundary";

export const withErrorBoundary = (WrappedCmp: React.ComponentType<any>) => {
  const ErrorBoundaryWrapper = (props: any) => {
    return (
      <ErrorBoundary>
        <WrappedCmp {...props} />
      </ErrorBoundary>
    );
  };

  return ErrorBoundaryWrapper;
};
