import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "@ui/Button";
import { Input } from "@ui/Input";
import { MessageSeverity } from "@shared/types/Severity";
import { toast } from "@utilities/toast";

export type BreweryFormData = {
  name: string;
  globalBreweryId: number | null;
  rating: number | null;
  fein: string | null;
};

export type BreweryFormInputData = {
  name: string;
  globalBreweryId: string;
  fein: string;
  rating: string;
};

type BreweryFormProps = {
  onSubmit: (data: BreweryFormInputData) => void | Promise<void>;
  defaultValues?: Partial<BreweryFormData>;
  submitText?: string;
};

export function BreweryForm({
  onSubmit,
  defaultValues = {},
  submitText = "Submit",
}: BreweryFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<BreweryFormData>({
    defaultValues,
  });

  const wrappedOnSubmit = async (data: BreweryFormInputData) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      if (error.message) {
        toast.current?.show({
          severity: MessageSeverity.error,
          summary: "",
          detail: error.message,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(wrappedOnSubmit)}>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              })}
              id="name"
              placeholder="Name"
              error={errors.name?.message}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="globalBreweryId"
            className="block text-sm font-medium text-gray-700"
          >
            Global Brewery ID
          </label>
          <span className="block text-sm text-gray-400 mt-1 mb-2">
            Find a producer&apos;s global brewery ID
            <a
              href="https://beerdb.tavour.com/admin/breweries"
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              here
            </a>
          </span>
          <div className="mt-1">
            <Input
              type="number"
              {...register("globalBreweryId", {
                required: true,
                min: {
                  value: 0,
                  message: "Must be at least 0",
                },
              })}
              id="globalBreweryId"
              placeholder="Global Brewery ID"
              error={errors.globalBreweryId?.message}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="Rating"
            className="block text-sm font-medium text-gray-700"
          >
            Rating
          </label>
          <span className="block text-sm text-gray-400 mt-1 mb-2">
            Find a producer&apos;s rating
            <a
              href={`https://beerdb.tavour.com/admin/breweries${
                defaultValues.globalBreweryId
                  ? "/" + defaultValues.globalBreweryId
                  : ""
              }`}
              target="_blank"
              className="link"
              rel="noopener noreferrer"
            >
              {" "}
              here{" "}
            </a>
            (check the SEO page)
          </span>
          <div className="mt-1">
            <Input
              type="number"
              {...register("rating", {
                required: true,
                min: {
                  value: 0,
                  message: "Must be at least 0",
                },
              })}
              step={0.01}
              id="rating"
              placeholder="Rating"
              error={errors.rating?.message}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="fein"
            className="block text-sm font-medium text-gray-700"
          >
            FEIN
          </label>
          <div className="mt-1">
            <Input
              type="text"
              {...register("fein", {
                required: false,
              })}
              id="fein"
              placeholder="Federal Employer Identification Number (Optional)"
              error={errors.fein?.message}
            />
          </div>
        </div>
        <div>
          <Button size="lg" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  );
}
