import { usePreSaleImportJobsQuery } from "@apollo/gen/generatedOperations";
import { Page, PageHeading } from "@ui/Page";
import { AddImportJobModal } from "./AddImportJobModal";
import { AdminPreSaleImportJobsTable } from "./AdminPreSaleImportJobsTable";
import React from "react";
import { buildBreadcrumbs } from "@ui/Breadcrumbs";
import { RouteComponentProps } from "@reach/router";

const breadcrumbProps = {
  items: buildBreadcrumbs("Pre-Sales", "Import Jobs"),
};

export function AdminPreSaleImportJobsPage(_props: RouteComponentProps) {
  const { data, refetch } = usePreSaleImportJobsQuery({
    pollInterval: 5 * 1000,
  });

  return (
    <Page
      heading={
        <PageHeading
          title="Import Jobs"
          breadcrumbProps={breadcrumbProps}
          actions={<AddImportJobModal onAdd={refetch} />}
        />
      }
    >
      {data && (
        <AdminPreSaleImportJobsTable
          preSaleImportJobs={data.preSaleImportJobs}
        />
      )}
    </Page>
  );
}
