import React from "react";
import { ApolloError } from "@apollo/client";
import {
  usePlaidItemLinkToken,
  withPlaidLinkToken,
  WithPlaidLinkTokenInjectedProps,
} from "../withPlaidLinkToken";
import { useUpdatePlaidItem, HandleUpdate } from "./useUpdatePlaidItem";
import { Button } from "@components/ui/Button";

export type { HandleUpdate };

export interface UpdatePlaidItemProps {
  plaidItemId: number;
  onUpdate?: HandleUpdate;
  onError?: (error: ErrorEvent | ApolloError | null) => void;
}

type BaseUpdatePlaidItemProps = UpdatePlaidItemProps &
  WithPlaidLinkTokenInjectedProps;

function BaseUpdatePlaidItem({
  plaidItemId,
  plaidLinkToken,
  onUpdate,
}: BaseUpdatePlaidItemProps) {
  const { isPlaidReady, isSaving, openPlaid } = useUpdatePlaidItem({
    plaidItemId,
    token: plaidLinkToken,
    onUpdate,
  });
  return (
    <Button
      kind="secondary"
      disabled={!isPlaidReady || isSaving}
      onClick={() => openPlaid()}
    >
      Enter Deposit Amounts
    </Button>
  );
}

export const UpdatePlaidItem = withPlaidLinkToken<UpdatePlaidItemProps, number>(
  {
    useHook: usePlaidItemLinkToken,
    mapPropsToHookOptions: (props) => props.plaidItemId,
  }
)(BaseUpdatePlaidItem);
