import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { StoreRouterParams } from "../StoreRouterParam";
import { StorePreSaleOrderPage } from "./StorePreSaleOrderPage/StorePreSaleOrderPage";
import { StorePreSaleOrdersPage } from "./StorePreSaleOrdersPage/StorePreSaleOrdersPage";

export function StorePreSaleOrdersRouter(
  _: RouteComponentProps<StoreRouterParams>
) {
  return (
    <Router>
      <StorePreSaleOrdersPage path="/" />
      <StorePreSaleOrderPage path=":orderIdParam" />
    </Router>
  );
}
