import React from "react";
import {
  UseFormRegister,
  FieldError,
  FieldValues,
  Path,
} from "react-hook-form";
import { LabeledInput } from "@ui/Input";

type OfferQuantityInputProps<TArgs extends FieldValues> = {
  register: UseFormRegister<TArgs>;
  fieldName: Path<TArgs>;
  error?: FieldError;
};

export function OfferQuantityInput<TArgs>({
  register,
  fieldName,
  error,
}: OfferQuantityInputProps<TArgs>) {
  return (
    <LabeledInput
      label="Quantity"
      inputProps={{
        type: "number",
        step: 1,
        ...register(fieldName, {
          required: {
            value: true,
            message: "Quantity is required",
          },
          min: {
            value: 1,
            message: "Quantity must be great than 0",
          },
        }),
        error: error?.message,
      }}
    />
  );
}
