import React, { ReactNode } from "react";
import { Link } from "@reach/router";
import { adminRegionPath, adminProducerRegionPath } from "@routes";

type AdminRegionLinkProps = {
  region: {
    id: number;
    friendlyName: string;
  };
  children?: ReactNode;
};

type AdminProducerRegionLinkProps = {
  producerId: number;
  region: {
    id: number;
    friendlyName: string;
  };
  children?: ReactNode;
};

export function AdminRegionLink({ region, children }: AdminRegionLinkProps) {
  return (
    <Link className="underline" to={adminRegionPath(region.id)}>
      {children ?? region.friendlyName}
    </Link>
  );
}

export function AdminProducerRegionLink({
  producerId,
  region,
  children,
}: AdminProducerRegionLinkProps) {
  return (
    <Link
      className="underline"
      to={adminProducerRegionPath(producerId, region.id)}
    >
      {children ?? region.friendlyName}
    </Link>
  );
}
