import React, { useMemo } from "react";
import { PreSaleOrderTableDataFragment } from "@apollo/ops";
import { Table } from "@ui/Table";
import {
  CellProps,
  Column,
  TableInstance,
  useFlexLayout,
  useTable,
} from "react-table";
import { formatAmountInCents } from "@utilities/currency";
import { formatAsDate } from "@utilities/formatDate";
import { AdminPreSaleOrderLink } from "@components/Order/Order";
import { UseOffsetPaginationResult } from "@hooks/useOffsetPagination";

type PreSaleOrdersTableProps = {
  preSaleId: number;
  data: Array<PreSaleOrderTableDataFragment>;
  pagination?: UseOffsetPaginationResult;
};

export function PreSaleOrdersTable({
  preSaleId,
  data,
  pagination,
}: PreSaleOrdersTableProps) {
  const columns: Array<Column<PreSaleOrderTableDataFragment>> = useMemo(
    () => [
      {
        Header: "Created",
        Cell: ({ value }: CellProps<PreSaleOrderTableDataFragment, "string">) =>
          formatAsDate(new Date(value)),
        accessor: "createdAt",
        width: 80,
      },
      {
        Header: "Cancelled",
        Cell: ({ value }: CellProps<PreSaleOrderTableDataFragment, "string">) =>
          value ? formatAsDate(new Date(value)) : null,
        accessor: "cancelledAt",
        width: 80,
      },
      {
        Header: "Number",
        Cell: ({ row }: CellProps<PreSaleOrderTableDataFragment, "string">) => (
          <AdminPreSaleOrderLink order={row.original} preSaleId={preSaleId} />
        ),
        accessor: "number",
        width: 140,
      },
      {
        Header: "Shopify ID",
        accessor: "shopifyId",
        width: 140,
      },
      {
        Header: "Order Total",
        Cell: ({ value }: CellProps<PreSaleOrderTableDataFragment, number>) =>
          formatAmountInCents(value),
        accessor: "amountInCents",
        width: 100,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  ) as TableInstance<PreSaleOrderTableDataFragment>;

  return <Table instance={tableInstance} pagination={pagination} />;
}
