import React from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { AdminLogisticsPartnerPage } from "@admin/AdminLogisticsPartnersContainer/AdminLogisticsPartnerPage/AdminLogisticsPartnerPage";
import { AdminLogisticsPartnersPage } from "@admin/AdminLogisticsPartnersContainer/AdminLogisticsPartnersPage/AdminLogisticsPartnersPage";

function AdminLogisticsPartnersContainer(_props: RouteComponentProps) {
  return (
    <Router>
      <AdminLogisticsPartnersPage path="/" />
      <AdminLogisticsPartnerPage path=":logisticsPartnerId" />
      <Redirect from="*" to="/not-found" />
    </Router>
  );
}

export default AdminLogisticsPartnersContainer;
